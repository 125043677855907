import React from 'react';
import {
  Card, CardHeader, CardBody, Alert, Table, Row, Col, Input, Form, FormGroup, CardTitle, InputGroup, InputGroupAddon, Button,
  Modal, ModalHeader, ModalBody, ModalFooter, Pagination, PaginationItem, PaginationLink, Label,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Select, { components } from "react-select";
import Datetime from "react-datetime";
import jQuery from 'jquery';
import Loader from '../../components/common/Loader';

import { authenticateLoggedIn, addHeaderTitle, addBreadcrumb, getCurrentUser } from '../../services/parse';
import { getFullPageCount, getSearchResults, getFinance, hideRecords, getDomains, getDomainById, saveHiddenColumns, getHiddenColumns } from '../../services/finance';


import imgview from '../../assets/images/icons/view-white.png';
import imgnoview from '../../assets/images/icons/no-view-white.png';
import imgedit from '../../assets/images/icons/edit-white.png';
import withRouter from '../../components/common/WithRouter';

class finance extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      textClass: '',
      isSuper: '',
      datafull: '',
      totalpagecount: '',
      data: [],
      deleteIds: [],
      domaindata: [],
      deletedstat: 'd-none',
      loaderstat: true,
      searchcount: "",
      hiddenColumnArray: [],
    };
    this.toggle = this.toggle.bind(this);

  }


  async componentDidMount(evt) {
    //auth start
    authenticateLoggedIn();
    addHeaderTitle('Finance');
    addBreadcrumb('Home / Finance');

    //auth end
    var domainId = this.props.params.domainid;
    getDomainById(domainId).then((value) => {
      if (value.length) {
        var domainobjectid = value[0].id;
        var domainobjectname = value[0].get('name');
        if (domainobjectid) {
          jQuery('#domainhidden').val(domainobjectid);
          jQuery('#domainfilter label').html(domainobjectname);
          jQuery('#domainfilter').removeClass('d-none');
          this.searchInit();
        }
      }
    });


    var dataperpage = jQuery('#dataperpage').val();

    getFullPageCount().then((value) => {
      this.setState({ datafull: value, });

      var totpagecount = Math.ceil(value / dataperpage);
      this.setState({ totalpagecount: totpagecount, });

      jQuery('.pagination .page-item:first-child').nextAll().slice(3, -1).addClass('d-none');
    });

    var sortitemval = jQuery('#sortitemval').val();
    var sortitemorder = jQuery('#sortitemorder').val();
    getFinance(1, dataperpage, sortitemval, sortitemorder).then((value) => {
      this.setState({ data: value, });
      this.setState({ loaderstat: false, });
    });

    getDomains().then((value) => {
      this.setState({ domaindata: value, });
    });

    var hiddenColumnArray = await getHiddenColumns();
    if (hiddenColumnArray && hiddenColumnArray[0]) {
      this.setState({ hiddenColumnArray: hiddenColumnArray[0].get('hiddenColumns'), });
    }

  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  toggleColumns = () => {
    jQuery('#hidecheckgroup').slideToggle();
  }


  saveHiddenColumns = () => {
    jQuery('#addbutton').addClass('d-none');
    jQuery('#loadbutton').removeClass('d-none');

    var hiddenitems = [];
    jQuery.each(jQuery("input[name='hiddenitems']:checked"), function () {
      hiddenitems.push(jQuery(this).val());
    });

    var currentUser = getCurrentUser();
    var userObjectID = currentUser.id;

    var fielddatas = {
      hiddenitems: hiddenitems,
      userObjectID: userObjectID,
    }
    saveHiddenColumns(fielddatas).then((value) => {
      if (value !== false) {
        jQuery('#addbutton').removeClass('d-none');
        jQuery('#loadbutton').addClass('d-none');
      }
    });
  }

  createColumns = () => {
    let columnToHide = [];
    var thcount = 1;
    var _ = this;

    jQuery(".table-responsive .table thead tr th").each(function () {
      var thval = jQuery(this).html();

      if (jQuery.inArray(thcount.toString(), _.state.hiddenColumnArray) !== -1) {
        columnToHide.push(
          <div className="form-check form-check-inline">
            <Input type="checkbox" value={thcount} onClick={_.hideColumn} id={thval} name="hiddenitems" defaultChecked />
            <Label for={thval}>{thval}</Label>
          </div>
        );
        jQuery('.table-responsive .table thead tr th:nth-child(' + thcount + ')').hide();
        jQuery('.table-responsive .table tbody tr td:nth-child(' + thcount + ')').hide();
      } else {
        columnToHide.push(
          <div className="form-check form-check-inline">
            <Input type="checkbox" value={thcount} onClick={_.hideColumn} id={thval} name="hiddenitems" />
            <Label for={thval}>{thval}</Label>
          </div>
        );
      }

      thcount++;
    });
    return columnToHide
  }

  hideColumn = async (event) => {
    var checkval = event.target.value;
    var checkstat = event.target.checked;

    if (checkstat === true) {
      jQuery('.table-responsive .table thead tr th:nth-child(' + checkval + ')').hide();
      jQuery('.table-responsive .table tbody tr td:nth-child(' + checkval + ')').hide();
    } else {
      jQuery('.table-responsive .table thead tr th:nth-child(' + checkval + ')').show();
      jQuery('.table-responsive .table tbody tr td:nth-child(' + checkval + ')').show();
    }

  }


  setSortItem = (item) => {

    jQuery('.cursor-pointer').not(item.target).removeClass('ascending');
    jQuery('.cursor-pointer').not(item.target).removeClass('descending');
    jQuery('.cursor-pointer').not(item.target).removeAttr('data-sortorder');

    if (item.target.dataset.sortorder === "ascending") {
      item.target.dataset.sortorder = "descending";
      item.target.classList.add("descending");
      item.target.classList.remove("ascending");
    } else {
      item.target.dataset.sortorder = "ascending";
      item.target.classList.add("ascending");
      item.target.classList.remove("descending");
    }

    var sortval = item.target.dataset.sort;
    jQuery('#sortitemval').val(sortval);

    var sortitemorder = item.target.dataset.sortorder;
    jQuery('#sortitemorder').val(sortitemorder);

    this.gotoPage();
  }

  setpageNum = (event) => {
    jQuery('.table-responsive').animate({scrollTop:0,scrollLeft:0},500);
    jQuery('.tablepagination .pagination > li').removeClass('active');
    jQuery('.tablepagination .pagination > li').removeClass('d-none');
    jQuery(event.target).parent().addClass('active');

    jQuery('.paginatecounts').removeClass('active');
    jQuery(event.target).addClass('active');

    var pagenum = jQuery(event.target).data('pagenum');
    jQuery('#pagenum').val(pagenum);

    this.gotoPage();

    jQuery(event.target).parent().prevAll().slice(3, -1).addClass('d-none');
    jQuery(event.target).parent().nextAll().slice(3, -1).addClass('d-none');
  }

  gotoPage = () => {
    var dataperpage = jQuery('#dataperpage').val();
    var pagenum = jQuery('#pagenum').val();
    var sortitemval = jQuery('#sortitemval').val();
    var sortitemorder = jQuery('#sortitemorder').val();

    getFinance(pagenum, dataperpage, sortitemval, sortitemorder).then((value) => {
      this.setState({ data: value, });

      this.createColumns();

    });
  }
  showPerPage = () => {
    var dataperpage = jQuery('#dataperpage').val();
    var pagenum = jQuery('#pagenum').val();
    var sortitemval = jQuery('#sortitemval').val();
    var sortitemorder = jQuery('#sortitemorder').val();

    var totpagecount = Math.ceil(this.state.datafull / dataperpage);
    this.setState({
      totalpagecount: totpagecount
    }, () => {
      jQuery('.paginatecounts[data-pagenum = ' + pagenum + ']').trigger("click");
    });

    var pagenum = jQuery('#pagenum').val();
    if (pagenum > totpagecount) {
      jQuery('#pagenum').val(totpagecount);
      var pagenum = jQuery('#pagenum').val();

      jQuery('#paginatecounts').removeClass('active');
      jQuery('.paginatecounts[data-pagenum = ' + pagenum + ']').addClass('active');
      jQuery('.paginatecounts[data-pagenum = ' + pagenum + ']').parent().addClass('active');
    }

    getFinance(pagenum, dataperpage, sortitemval, sortitemorder).then((value) => {
      this.setState({ data: value, });
    });

  }

  hitEnter = (e) => {
    var keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      this.searchRecords();
    }
  }

  setSearchFields = () => {
    jQuery('#searchbox').val('');
    var setsrcfield = jQuery('#setsrcfield').val();
    if (setsrcfield == "domain") {

      jQuery('#searchbox').addClass('d-none');
      jQuery('#statsrc').addClass('d-none');
      jQuery('#domainsrc').removeClass('d-none');
      jQuery('#numberrange').addClass('d-none');
      jQuery('#datesouter').addClass('d-none');

    } else if (setsrcfield == "status") {

      jQuery('#domainsrc').addClass('d-none');
      jQuery('#searchbox').addClass('d-none');
      jQuery('#statsrc').removeClass('d-none');
      jQuery('#numberrange').addClass('d-none');
      jQuery('#datesouter').addClass('d-none');

    } else if (setsrcfield == "invoice_amount") {

      jQuery('#domainsrc').addClass('d-none');
      jQuery('#searchbox').addClass('d-none');
      jQuery('#statsrc').addClass('d-none');
      jQuery('#numberrange').removeClass('d-none');
      jQuery('#datesouter').addClass('d-none');

    } else if (setsrcfield == "corp_cc_state" || setsrcfield == "invoice_date") {

      jQuery('#domainsrc').addClass('d-none');
      jQuery('#searchbox').addClass('d-none');
      jQuery('#statsrc').addClass('d-none');
      jQuery('#numberrange').addClass('d-none');
      jQuery('#datesouter').removeClass('d-none');

    } else {

      jQuery('#searchbox').removeClass('d-none');
      jQuery('#domainsrc').addClass('d-none');
      jQuery('#statsrc').addClass('d-none');
      jQuery('#numberrange').addClass('d-none');
      jQuery('#datesouter').addClass('d-none');

    }
  }
  searchRecords = () => {
    var searchbox = jQuery('#searchbox').val();
    var statsrc = jQuery('#statsrc').val();
    var minrange = jQuery('#minrange').val();
    var maxrange = jQuery('#maxrange').val();
    var startdatesrc = jQuery('#startdatesrc').val();
    var enddatesrc = jQuery('#enddatesrc').val();

    var st = startdatesrc;
    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
    var startdatevalid = new Date(st.replace(pattern, '$3-$2-$1'));

    var st = enddatesrc;
    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
    var enddatevalid = new Date(st.replace(pattern, '$3-$2-$1'));

    if (startdatevalid.toString() === 'Invalid Date' && startdatesrc !== "") {
      jQuery('#startdatesrc').addClass('is-invalid');
      return false;
    } else {
      jQuery('#startdatesrc').removeClass('is-invalid');
    }
    if (enddatevalid.toString() === 'Invalid Date' && enddatesrc !== "") {
      jQuery('#enddatesrc').addClass('is-invalid');
      return false;
    } else {
      jQuery('#enddatesrc').removeClass('is-invalid');
    }

    var setsrcfield = jQuery('#setsrcfield').val();
    if (setsrcfield == "account") {
      jQuery('#accounthidden').val(searchbox);

      jQuery('#accountfilter label').html(searchbox);
      jQuery('#accountfilter').removeClass('d-none');

    } else if (setsrcfield == "invoicenumber") {
      jQuery('#invoicehidden').val(searchbox);

      jQuery('#invoicefilter label').html(searchbox);
      jQuery('#invoicefilter').removeClass('d-none');

    } else if (setsrcfield == "domain") {
      if (this.state.domainsrc) {
        jQuery('#domainfilter label').html(this.state.domainsrc.label);
      } else {
        jQuery('#domainfilter label').html();
      }
      jQuery('#domainfilter').removeClass('d-none');

    } else if (setsrcfield == "registrant_email") {
      jQuery('#registranthidden').val(searchbox);

      jQuery('#registrantfilter label').html(searchbox);
      jQuery('#registrantfilter').removeClass('d-none');

    } else if (setsrcfield == "status") {
      jQuery('#statsrchidden').val(statsrc);

      jQuery('#statfilter label').html(statsrc);
      jQuery('#statfilter').removeClass('d-none');

    } else if (setsrcfield == "transaction_type") {
      jQuery('#transactionhidden').val(searchbox);

      jQuery('#transactionfilter label').html(searchbox);
      jQuery('#transactionfilter').removeClass('d-none');

    } else if (setsrcfield == "invoice_amount") {
      jQuery('#minrangehidden').val(minrange);
      jQuery('#maxrangehidden').val(maxrange);

      jQuery('#invoiceamountfilter label').html(minrange + " - " + maxrange);
      jQuery('#invoiceamountfilter').removeClass('d-none');

    } else if (setsrcfield == "corp_cc_state") {
      jQuery('#cc_starthidden').val(startdatesrc);
      jQuery('#cc_endhidden').val(enddatesrc);

      jQuery('#ccfilter label').html(startdatesrc + " - " + enddatesrc);
      jQuery('#ccfilter').removeClass('d-none');

    }
    else if (setsrcfield == "invoice_date") {
      jQuery('#invoice_starthidden').val(startdatesrc);
      jQuery('#invoice_endhidden').val(enddatesrc);

      jQuery('#invoicedatefilter label').html(startdatesrc + " - " + enddatesrc);
      jQuery('#invoicedatefilter').removeClass('d-none');

    }

    this.searchInit();
  }

  searchInit = () => {
    var accounthidden = jQuery('#accounthidden').val();
    var invoicehidden = jQuery('#invoicehidden').val();
    var statsrchidden = jQuery('#statsrchidden').val();
    var domainhidden = jQuery('#domainhidden').val();
    var registranthidden = jQuery('#registranthidden').val();
    var transactionhidden = jQuery('#transactionhidden').val();
    var minrangehidden = jQuery('#minrangehidden').val();
    var maxrangehidden = jQuery('#maxrangehidden').val();
    var cc_starthidden = jQuery('#cc_starthidden').val();
    var cc_endhidden = jQuery('#cc_endhidden').val();
    var invoice_starthidden = jQuery('#invoice_starthidden').val();
    var invoice_endhidden = jQuery('#invoice_endhidden').val();

    var searchdatas = {
      account: accounthidden,
      invoicenumber: invoicehidden,
      domainname: domainhidden,
      registrantemail: registranthidden,
      statsrc: statsrchidden,
      transaction_type: transactionhidden,
      invoicemin: minrangehidden,
      invoicemax: maxrangehidden,
      cc_start: cc_starthidden,
      cc_end: cc_endhidden,
      invoice_start: invoice_starthidden,
      invoice_end: invoice_endhidden,
    };

    if (accounthidden !== "" || invoicehidden !== "" || domainhidden !== "" || registranthidden !== "" || statsrchidden !== "" || transactionhidden !== ""
      || minrangehidden !== "" || maxrangehidden !== "" || cc_starthidden !== "" || cc_endhidden !== "" || invoice_starthidden !== "" || invoice_endhidden !== "") {
      getSearchResults(searchdatas).then((value) => {
        this.setState({ data: value, });
        this.setState({ searchcount: value.length, });
      });
      jQuery('.tablepagination .col-auto').addClass('d-none');
    } else {
      var dataperpage = jQuery('#dataperpage').val();
      var sortitemval = jQuery('#sortitemval').val();
      var sortitemorder = jQuery('#sortitemorder').val();
      getFinance(1, dataperpage, sortitemval, sortitemorder).then((value) => {
        this.setState({ data: value, });
        this.setState({ searchcount: "", });
      });
      jQuery('.tablepagination .col-auto').removeClass('d-none');
    }

  }

  clearFilterItem = (event) => {
    var itemname = event.currentTarget.dataset.item;

    if (itemname === "account") {

      jQuery('#accountfilter label').html('');
      jQuery('#accountfilter').addClass('d-none');
      jQuery('#accounthidden').val('');

    } else if (itemname === "invoicenumber") {

      jQuery('#invoicefilter label').html('');
      jQuery('#invoicefilter').addClass('d-none');
      jQuery('#invoicehidden').val('');

    } else if (itemname === "domain") {

      jQuery('#domainfilter label').html('');
      jQuery('#domainfilter').addClass('d-none');
      jQuery('#domainhidden').val('');
      this.setState({ domainsrc: '' });

    } else if (itemname === "registrant_email") {

      jQuery('#registrantfilter label').html('');
      jQuery('#registrantfilter').addClass('d-none');
      jQuery('#registranthidden').val('');

    } else if (itemname === "status") {

      jQuery('#statfilter label').html('');
      jQuery('#statfilter').addClass('d-none');
      jQuery('#statsrchidden').val('');

    } else if (itemname === "transaction_type") {

      jQuery('#transactionfilter label').html('');
      jQuery('#transactionfilter').addClass('d-none');
      jQuery('#transactionhidden').val('');

    } else if (itemname === "invoice_amount") {

      jQuery('#invoiceamountfilter label').html('');
      jQuery('#invoiceamountfilter').addClass('d-none');
      jQuery('#minrangehidden').val('');
      jQuery('#maxrangehidden').val('');

    } else if (itemname === "corp_cc_state") {

      jQuery('#ccfilter label').html('');
      jQuery('#ccfilter').addClass('d-none');
      jQuery('#cc_starthidden').val('');
      jQuery('#cc_endhidden').val('');

    } else if (itemname === "invoice_date") {

      jQuery('#invoicedatefilter label').html('');
      jQuery('#invoicedatefilter').addClass('d-none');
      jQuery('#invoice_starthidden').val('');
      jQuery('#invoice_endhidden').val('');

    }

    this.searchInit();
  }

  hideRecord = (event) => {
    var objectid = event.currentTarget.dataset.target;
    jQuery('#hiddenidstored').val(objectid);
    this.toggle();
  }
  pushhideRecord = () => {
    var objectid = jQuery('#hiddenidstored').val();
    hideRecords(objectid).then((value) => {
      if (value === true) {
        this.gotoPage();
        this.setState({ deletedstat: 'd-block' });
      }
    });
    this.toggle();
  }




  render() {
    var domainstate = this.state.domaindata;
    var domainlist = [];
    // domainlist.push({ value: "", label: "NULL" })
    if (domainstate && domainstate.length > 0) {
      for (let i = 0; i < domainstate.length; i++) {
        domainlist.push({ value: domainstate[i].id, label: domainstate[i].attributes.name })
      }
    }



    return <div className="animated fadeIn">

      <a onClick={this.toggleColumns} className="btn btn-default">Customize / Hide Columns</a>
      <div className="customcheck greybgcheck yscroll" id="hidecheckgroup">
        {this.createColumns()}
        <Button type="button" color="primary" id="addbutton" onClick={this.saveHiddenColumns}>
          <span className="border-left pl-2"></span>
          Save
        </Button>

        {/* Loader button */}
        <div className="btn btn-primary d-none" id="loadbutton">
          <Loader type="Oval" color="#414241" height={20} width={20} />
        </div>
        {/* Loader button */}

      </div>




      <Input type="hidden" id="sortitemval" />
      <Input type="hidden" id="sortitemorder" />

      <Row className="justify-content-end">
        <Col className="col-auto mb-4">
        </Col>
      </Row>

      <Row>
        <Col className="col-12 mb-4">
          <Form className="tableserch">
            <InputGroup>
              <FormGroup className="form-group col-md-2 p-0 m-0">
                <Input type="select" className="custom-select form-control" id="setsrcfield" onChange={this.setSearchFields}>
                  <option value="account">Account</option>
                  <option value="corp_cc_state">Corp. CC statement</option>
                  <option value="invoice_date">Date of invoice</option>
                  <option value="domain">Domain</option>
                  <option value="invoice_amount">Invoice Amount</option>
                  <option value="invoicenumber">Invoice Number</option>
                  <option value="registrant_email">Registrant Email</option>
                  <option value="status">Status</option>
                  <option value="transaction_type">Transaction Type</option>
                </Input>
              </FormGroup>


              <Input type="text" placeholder="Enter your search here" id="searchbox" className="" onKeyDown={this.hitEnter} />
              <Input type="hidden" id="searchboxhidden" />


              <Input type="hidden" id="accounthidden" />
              <Input type="hidden" id="invoicehidden" />
              <Input type="hidden" id="transactionhidden" />



              <div className="datehalf d-none" id="numberrange">
                <Input type="number" onWheel={ event => event.currentTarget.blur() } placeholder="min" id="minrange" className="" onKeyDown={this.hitEnter} />
                <Input type="number" onWheel={ event => event.currentTarget.blur() } placeholder="max" id="maxrange" className="" onKeyDown={this.hitEnter} />
              </div>
              <Input type="hidden" id="minrangehidden" />
              <Input type="hidden" id="maxrangehidden" />


              {/* Date */}
              <div className="datehalf d-none" id="datesouter">
                <Datetime
                  inputProps={{ placeholder: "Start date", id: "startdatesrc", class: "form-control", autoComplete: "off" }}
                  timeFormat={false}
                  dateFormat="DD/MM/YYYY"
                  onKeyPress={this.hitEnter}
                  closeOnSelect={true}
                />
                <Datetime
                  inputProps={{ placeholder: "End date", id: "enddatesrc", class: "form-control", autoComplete: "off" }}
                  timeFormat={false}
                  dateFormat="DD/MM/YYYY"
                  onKeyPress={this.hitEnter}
                  closeOnSelect={true}
                />
              </div>
              {/* Date */}
              <Input type="hidden" id="cc_starthidden" />
              <Input type="hidden" id="cc_endhidden" />

              <Input type="hidden" id="invoice_starthidden" />
              <Input type="hidden" id="invoice_endhidden" />









              <Select
                id="domainsrc"
                onKeyDown={this.hitEnter}
                className="reactSelect reactSelectClass filterselect d-none"
                closeMenuOnSelect={true}
                options={domainlist}
                onChange={(selectedOption) => {
                  this.setState({ domainsrc: selectedOption });
                }}
                value={this.state.domainsrc}
              />
              <Input type="hidden" id="domainhidden" value={
                this.state.domainsrc ?
                  this.state.domainsrc.value
                  :
                  ""
              } />

              <Input type="hidden" id="registranthidden" />




              <select name="statsrc" id="statsrc" className="d-none" onKeyDown={this.hitEnter}>
                <option value="">Select</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
              <Input type="hidden" id="statsrchidden" />



              {/* <InputGroupAddon addonType="append"> */}
                <Button className="search" onClick={this.searchRecords}><i className="ti-search" ></i></Button>
              {/* </InputGroupAddon> */}
            </InputGroup>
          </Form>
          <div id="searchfilters">
            <div id="accountfilter" className="d-none filters">Account: <label></label> <span className="fclose" data-item="account" onClick={this.clearFilterItem}>x</span></div>
            <div id="invoicefilter" className="d-none filters">Invoice number: <label></label> <span className="fclose" data-item="invoicenumber" onClick={this.clearFilterItem}>x</span></div>
            <div id="domainfilter" className="d-none filters">Domain: <label></label> <span className="fclose" data-item="domain" onClick={this.clearFilterItem}>x</span></div>
            <div id="registrantfilter" className="d-none filters">Registrant: <label></label> <span className="fclose" data-item="registrant_email" onClick={this.clearFilterItem}>x</span></div>
            <div id="statfilter" className="d-none filters">Status: <label></label> <span className="fclose" data-item="status" onClick={this.clearFilterItem}>x</span></div>
            <div id="transactionfilter" className="d-none filters">Transaction Type: <label></label> <span className="fclose" data-item="transaction_type" onClick={this.clearFilterItem}>x</span></div>
            <div id="invoiceamountfilter" className="d-none filters">Invoice Range: <label></label> <span className="fclose" data-item="invoice_amount" onClick={this.clearFilterItem}>x</span></div>
            <div id="ccfilter" className="d-none filters">CC Statement Date: <label></label> <span className="fclose" data-item="corp_cc_state" onClick={this.clearFilterItem}>x</span></div>
            <div id="invoicedatefilter" className="d-none filters">Invoice Date: <label></label> <span className="fclose" data-item="invoice_date" onClick={this.clearFilterItem}>x</span></div>
          </div>
        </Col>
      </Row>


      <Row>
        <Col xs="12">
          <Card>

            {/* <CardTitle><i className="mdi mdi-border-all mr-2"></i>Finance</CardTitle> */}
            <CardBody>
              <Alert color="success" className={this.state.deletedstat}>
                Selected status have been changed!
              </Alert>

              <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                <ModalHeader toggle={this.toggle}>Confirm</ModalHeader>
                <ModalBody>
                  Do you want to change status?
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={this.pushhideRecord}>Yes</Button>
                  <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                </ModalFooter>
              </Modal>
              <Input type="hidden" id="hiddenidstored" />


              <Table responsive className="longtable text-center">
                <thead>
                  <tr>
                    <th className="cursor-pointer" data-sort="domain" onClick={this.setSortItem}>Domain</th>
                    <th>Currency</th>
                    <th className="cursor-pointer" data-sort="totalcost" onClick={this.setSortItem}>Total Cost</th>
                    <th className="cursor-pointer" data-sort="invoice_number" onClick={this.setSortItem}>Invoice Number </th>
                    <th className="cursor-pointer" data-sort="registrant_email" onClick={this.setSortItem}>Registrant Email </th>
                    <th className="cursor-pointer" data-sort="account" onClick={this.setSortItem}>Account</th>
                    <th className="cursor-pointer" data-sort="invoice_amount" onClick={this.setSortItem}>Invoice Amount</th>
                    <th className="cursor-pointer" data-sort="transaction_type" onClick={this.setSortItem}>Transaction Type</th>
                    <th className="cursor-pointer" data-sort="cc_statement_date" onClick={this.setSortItem}>Corp. CC Statement</th>
                    <th className="cursor-pointer" data-sort="date_of_invoice" onClick={this.setSortItem}>Date of Invoice</th>
                    <th>Domain Extension</th>
                    <th>URL Expiry</th>
                    <th>Registrar</th>
                    <th>Domain Created</th>
                    <th>Domain Updated</th>
                    <th className="cursor-pointer" data-sort="status" onClick={this.setSortItem}>Status </th>
                    <th className="text-center" width="110">Actions</th>
                  </tr>
                </thead>
                <tbody>


                  {
                    this.state.data && this.state.data.length > 0 ?
                      this.state.data.map((dynamicData, key) =>
                        <tr key={key}>
                          <td>
                            <Link to={"/finance/editfinance/" + dynamicData.id + "/default"} className="tdwrap lowercase">
                              {
                                dynamicData.get('domain') ?
                                  dynamicData.get('domain').get('name')
                                  :
                                  ""
                              }
                              {
                                dynamicData.get('domain') && dynamicData.get('domain').get('domain') ?
                                  dynamicData.get('domain').get('domain').get('name')
                                  :
                                  ""
                              }
                            </Link>
                          </td>

                          <td>
                            <Link to={"/finance/editfinance/" + dynamicData.id + "/default"} className="tdwrap">
                              {dynamicData.get('currency')}
                            </Link>
                          </td>

                          <td>
                            <Link to={"/finance/editfinance/" + dynamicData.id + "/default"} className="tdwrap">
                              {parseFloat(dynamicData.get('totalcost')).toFixed(2)}
                            </Link>
                          </td>

                          <td>
                            <Link to={"/finance/editfinance/" + dynamicData.id + "/default"} className="tdwrap">
                              {dynamicData.get('invoice_number')}
                            </Link>
                          </td>

                          <td>
                            <Link to={"/finance/editfinance/" + dynamicData.id + "/default"} className="tdwrap">
                              {dynamicData.get('registrant_email')}
                            </Link>
                          </td>
                          <td>
                            <Link to={"/finance/editfinance/" + dynamicData.id + "/default"} className="tdwrap">
                              {dynamicData.get('account')}
                            </Link>
                          </td>
                          <td>
                            <Link to={"/finance/editfinance/" + dynamicData.id + "/default"} className="tdwrap">
                              {/* {dynamicData.get('invoice_amount')} */}
                              {parseFloat(dynamicData.get('invoice_amount')).toFixed(2)}
                            </Link>
                          </td>
                          <td>
                            <Link to={"/finance/editfinance/" + dynamicData.id + "/default"} className="tdwrap">
                              {dynamicData.get('transaction_type')}
                            </Link>
                          </td>
                          <td>
                            <Link to={"/finance/editfinance/" + dynamicData.id + "/default"} className="tdwrap">
                              {
                                dynamicData.get('cc_statement_date') ?
                                  dynamicData.get('cc_statement_date').toLocaleDateString('en-GB')
                                  :
                                  ""
                              }
                            </Link>
                          </td>
                          <td>
                            <Link to={"/finance/editfinance/" + dynamicData.id + "/default"} className="tdwrap">
                              {
                                dynamicData.get('date_of_invoice') ?
                                  dynamicData.get('date_of_invoice').toLocaleDateString('en-GB')
                                  :
                                  ""
                              }
                            </Link>
                          </td>

                          <td>
                            <Link to={"/finance/editfinance/" + dynamicData.id + "/default"} className="tdwrap lowercase">
                              {
                                dynamicData.get('domain') && dynamicData.get('domain').get('domain') ?
                                  dynamicData.get('domain').get('domain').get('name')
                                  :
                                  ""
                              }
                            </Link>
                          </td>
                          <td>
                            <Link to={"/finance/editfinance/" + dynamicData.id + "/default"} className="tdwrap">
                              {
                                dynamicData.get('domain') ?
                                  dynamicData.get('domain').get('expiry_date') ?
                                    dynamicData.get('domain').get('expiry_date').toLocaleDateString('en-GB')
                                    :
                                    ""
                                  :
                                  ""
                              }
                            </Link>
                          </td>

                          <td>
                            <Link to={"/finance/editfinance/" + dynamicData.id + "/default"} className="tdwrap lowercase">
                              {
                                dynamicData.get('domain') && dynamicData.get('domain').get('registrar') ?
                                  dynamicData.get('domain').get('registrar').get('name')
                                  :
                                  ""
                              }
                            </Link>
                          </td>

                          <td>
                            <Link to={"/finance/editfinance/" + dynamicData.id + "/default"} className="tdwrap">
                              {
                                dynamicData.get('domain') ?
                                  dynamicData.get('domain').get('created_on_date') ?
                                    dynamicData.get('domain').get('created_on_date').toLocaleDateString('en-GB')
                                    :
                                    ""
                                  :
                                  ""
                              }
                            </Link>
                          </td>

                          <td>
                            <Link to={"/finance/editfinance/" + dynamicData.id + "/default"} className="tdwrap">
                              {
                                dynamicData.get('domain') ?
                                  dynamicData.get('domain').get('updated_date') ?
                                    dynamicData.get('domain').get('updated_date').toLocaleDateString('en-GB')
                                    :
                                    ""
                                  :
                                  ""
                              }
                            </Link>
                          </td>



                          <td className="status">
                            {
                              dynamicData.get('status').toString() === 'true' ?
                                <span className="active">Active</span>
                                :
                                <span className="inactive">Inactive</span>
                            }
                          </td>


                          <td className="text-center">
                            {/* <a href="javascript:" className="btnicon hideit" onClick={this.hideRecord} data-target={dynamicData.id}>
                              {
                                dynamicData.get('status').toString() === 'true' ?
                                  <img src={imgview} alt={"imgview"} width="26" />
                                  :
                                  <img src={imgnoview} alt={"imgnoview"} width="26" />
                              }
                            </a> */}

                            <Link to={"/finance/editfinance/" + dynamicData.id + "/default"} className="btnicon padding0">
                              <img src={imgedit} alt={"imgedit"} width="28" />
                            </Link>

                          </td>
                        </tr>

                      )
                      :


                      this.state.loaderstat === true ?
                        <tr>
                          <td colSpan="12">
                            <div className="recordnotfound">
                              <Loader type="Oval" color="#fff" height={20} width={20} />
                            </div>
                          </td>
                        </tr>

                        :
                        <tr>
                          <td colSpan="12">
                            <div className="recordnotfound">
                              <p>No record found </p>
                              {
                                this.props.params.domainid !== "" && this.props.params.domainid !== ":domainid" ?

                                  <Link to={"/finance/addFinance/" + this.props.params.domainid + ""} className="btn btn-primary">
                                    Add New Finance
                                  </Link>
                                  :
                                  null
                              }
                            </div>
                          </td>
                        </tr>

                  }

                </tbody>
              </Table>
            </CardBody>


          </Card>

          <div className="totalrecords">
            Total Records :&nbsp;
            {
              this.state.searchcount !== "" ?
                this.state.searchcount
                :
                this.state.datafull
            }
          </div>

          <div className="tablepagination d-flex justify-content-center">
            <div className="col-auto">
              <FormGroup className="form-group pageSizeOptions">
                <Input className='form-control' type="select" id="dataperpage" onChange={this.showPerPage}>
                  <option value="25">25 Rows</option>
                  <option value="50">50 Rows</option>
                  <option value="100">100 Rows</option>
                </Input>
              </FormGroup>
            </div>

            <div className="d-none">
              Page
              <Form className="pageJump ml-1 mr-1">
                <FormGroup className="form-group">
                  <Input type="number" onWheel={ event => event.currentTarget.blur() } defaultValue="1" id="pagenum" min="1" />
                </FormGroup>
              </Form> of {Math.ceil(this.state.totalpagecount)}
              <a href="javascript:" className="btn btn-primary btnarrowright" onClick={this.gotoPage}>Go</a>
            </div>

            <div className="col-auto">
              <Pagination aria-label="Page navigation example">
                {Array(Math.ceil(this.state.totalpagecount)).fill(1).map((key, i) =>
                  <PaginationItem key={i} className={i + 1 === 1 || i + 1 === this.state.totalpagecount ? "active" : (i < 4 ? "" : "d-none")}>
                    <PaginationLink onClick={this.setpageNum} data-pagenum={i + 1} className={i + 1 === 1 ? "paginatecounts active" : "paginatecounts"} >
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                )}
              </Pagination>
            </div>
          </div>

        </Col>
      </Row>
    </div>
  }
}

export default withRouter(finance);
