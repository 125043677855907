import React from "react";
import { Row, Col, Carousel, CarouselItem, CarouselControl, Button, Table, Input, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, ModalFooter, Label, Alert, UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem, TabContent, TabPane, CardBody, Nav, NavItem, NavLink, Card, CardTitle, CardText } from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
import {Calendar, momentLocalizer} from "react-big-calendar";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import "./calendar.css";
import jQuery from 'jquery';

// Calendar.setLocalizer(Calendar.momentLocalizer(moment));
const localizer = momentLocalizer(moment);


class Calendarview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: null,
      alert: null,
      modal: true,
    };
    this.hideAlert = this.hideAlert.bind(this);
  }

  componentWillMount = async () => {
  
  }


  async componentDidUpdate(prevProps, prevState) {
    if (this.props.viewListFull.length > 0 && prevProps.viewListFull !== this.props.viewListFull) {

      console.log("calender update")

      const events = [];
      var value = this.props.viewListFull;
      jQuery.each(value, function (key, value) {
        if (value.attributes.expiry) {
          var datelocale = value.attributes.expiry.toLocaleDateString();
          var cdata = {
            title: value.attributes.title + " Renewal",
            allDay: true,
            start: new Date(datelocale),
            end: new Date(datelocale),
            color: "default",
            id: value.id,
          }
          events.push(cdata);
        }
      });
      this.setState({ events: events });





      setTimeout(function () {
        jQuery('.rbc-btn-group button:first-child').trigger("click");
      }, 1500);
    }


    // console.log(prevProps.viewListFull)
    // console.log(this.props.viewListFull)


  }



  alertselectedEvent(event) {

    this.props.viewStatus(event.id);

    // window.location.assign('#/domain/editdomain/' + event.id + '/default');
    // alert("\n" + event.title + " \n\nStart Time: " + event.start + " \nEnd Time: " + event.end);
  }
  addNewEventAlert(slotInfo) {
    this.setState({
      alert: (
        <div><SweetAlert
          input
          showCancel
          style={{}}
          title="Create New Event"
          placeHolder="Write Event Title"
          onConfirm={e => this.addNewEvent(e, slotInfo)}
          onCancel={() => this.hideAlert()}
          cancelBtnBsStyle="danger"
          confirmBtnBsStyle="info"
        >
        </SweetAlert>

        </div>
      )
    });
  }
  addNewEvent(e, slotInfo) {

    var newEvents = this.state.events;
    newEvents.push({
      title: e,
      start: slotInfo.start,
      end: slotInfo.end
    });

    this.setState({
      alert: null,
      events: newEvents
    });

  }
  hideAlert() {
    this.setState({
      alert: null
    });
  }
  eventColors(event, start, end, isSelected) {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor
    };
  }
  render() {


    return (
      <div>


        {this.state.alert}
        <Row>
          <Col xs={12} md={12} className="ml-auto mr-auto">
            <Card className="card-calendar" id="calendercard">
              <CardBody>


                {
                  this.state.events ?
                    <Calendar
											localizer={localizer}
                      selectable
                      events={this.state.events}
                      defaultView="month"
                      scrollToTime={new Date(1970, 1, 1, 6)}
                      defaultDate={new Date()}
                      onSelectEvent={event => this.alertselectedEvent(event)}
                      eventPropGetter={this.eventColors}
                    />
                    :
                    ""
                }


              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}
export default Calendarview;