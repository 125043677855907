import React from 'react';
import {
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Input,
	CustomInput,
	Form,
	Row,
	Col,
	Label,
	Button,
	Alert,
	FormGroup
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { auth } from '../../firebase';
import validators from './validators';
import jQuery from 'jquery';

import loginBg from '../../assets/images/loginBg.jpg';
import imgloginheader from '../../assets/images/login-header.jpg';
import imglogo from '../../assets/images/logo.png';
import imgview from '../../assets/images/icons/view.png';
import imgnoview from '../../assets/images/icons/no-view.png';
import iconPassword from '../../assets/images/icons/password.png';
import iconEmail from '../../assets/images/icons/email.png';
import backgroundVideo from '../../assets/videos/alj-our-locations-in-saudi-arabia.mp4';
import backgroundVideoImg from '../../assets/images/video-bg.jpg';
import { isAdmin } from '../../services/user';
import { getCurrentUser, authenticateLoggedIn, login, isSuperAdmin, authenticateLoginpage, addBreadcrumb } from '../../services/parse';
import Loader from '../../components/common/Loader';

const sidebarBackground = {
	backgroundImage: "url(" + loginBg + ")",
	backgroundRepeat: "no-repeat",
	backgroundPosition: "center center",
	backgroundSize: "cover"
};

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			username: '',
			password: '',
			userError: '',
			passError: '',
			invalidError: 'd-none',
			passimg: imgnoview,
		};

	}

	async componentDidMount(evt) {
		authenticateLoginpage();

		var remcookie = "";
		var usercookie = "";
		var passcookie = "";

		var cookiename = 'remember';
		var value = "; " + document.cookie;
		var parts = value.split("; " + cookiename + "=");
		if (parts.length == 2) {
			remcookie = parts.pop().split(";").shift();
		}
		if (remcookie === 'true') {
			//username cookie
			var cookiename = 'username';
			var value = "; " + document.cookie;
			var parts = value.split("; " + cookiename + "=");
			if (parts.length == 2) {
				usercookie = parts.pop().split(";").shift();
			}

			//password cookie
			var cookiename = 'password';
			var value = "; " + document.cookie;
			var parts = value.split("; " + cookiename + "=");
			if (parts.length == 2) {
				passcookie = parts.pop().split(";").shift();
			}

			this.setState({ username: usercookie });
			this.setState({ password: passcookie });

		}
	}





	hitEnter = (e) => {
		var keyCode = e.keyCode || e.which;
		if (keyCode === 13) {
			this.handleSubmit();
		}
	}
	showPassword = () => {
		// this.handleSubmit();
		var x = document.getElementById("password");
		if (x.type === "password") {
			x.type = "text";
			this.setState({ passimg: imgview });
		} else {
			x.type = "password";
			this.setState({ passimg: imgnoview });
		}
	}
	handleUsernameChange = (evt) => {
		this.setState({ username: evt.target.value });
	}
	handlePassChange = (evt) => {
		this.setState({ password: evt.target.value });
	}

	handleSubmit = () => {
		const { username, password } = this.state;
		let status = false;

		if (username === '') {
			this.setState({ userError: 'is-invalid' });
			this.setState({ passError: '' });
			return
		} else if (password === '') {
			this.setState({ userError: '' });
			this.setState({ passError: 'is-invalid' });
		} else {
			jQuery('#continue').removeClass('d-block');
			jQuery('#continue').addClass('d-none');
			jQuery('#loadbutton').removeClass('d-none');
			
			var remember = false;
			if (jQuery('#remember').is(':checked')) {
				remember = true;
			} else {
				remember = false;
			}



			login({ username: username, password: password, remember: remember }).then(async (value) => {
				if (value === true) {


					var isadminval = await isAdmin();
					if (isadminval[0].get('role')) {
						var currentrole = isadminval[0].get('role').get('name');
					} else {
						var currentrole = "";
					}

					// if (currentrole === "External") {
					// 	window.location.assign('#/trademark/list');
					// } else if (currentrole === "Finance") {
					// 	window.location.assign('#/finance/finance/:domainid');
					// } else {
						window.location.assign('#/dashboard');
					// }




				} else {
					this.setState({ invalidError: 'd-block' });
					jQuery('#loadbutton').addClass('d-none');
					jQuery('#continue').removeClass('d-none');
				}
			});
		}
	}



	handleClick() {
		var elem = document.getElementById('loginform');
		elem.style.transition = "all 2s ease-in-out";
		elem.style.display = "none";
		document.getElementById('recoverform').style.display = "block";
	}
	render() {
		return <div className="auth-wrapper align-items-center d-flex">
			{/*--------------------------------------------------------------------------------*/}
			{/*Login2 Cards*/}
			{/*--------------------------------------------------------------------------------*/}

			<div className="videobg">
				<video autoPlay loop muted id="myVideo">
					<source src={backgroundVideo} type="video/mp4" />
				</video>
			</div>

			<div className="container-fluid  p-0">

				<div className="loginheader">
					<a href="https://www.alj.com/" className="logo" target="_blank"><img src={imglogo} alt={"imglogo"} /></a>
				</div>

				<Row className="no-gutters justify-content-center">

					<div className="loginbx">
						<div className="p-5">
							<h1 className="title mt-2 mb-5">Intellectual Property Management</h1>

							<Alert color="danger" className={this.state.invalidError}>
								Invalid Credentials
                       </Alert>
							<Form className="mt-4" id="loginform" action="/dashbaord">
								{/* <Label for="email" className="font-medium">Email</Label> */}
								<InputGroup className="mb-2" size="lg">
									{/* <InputGroupAddon addonType="prepend"> */}
										<InputGroupText className='prepend'>
											<img src={iconEmail} alt="iconEmail" width="20" />
										</InputGroupText>
									{/* </InputGroupAddon> */}
									<Input type="text" className={this.state.userError} id="username" placeholder="Email address" defaultValue={this.state.username} autoComplete="username" required onChange={this.handleUsernameChange} onKeyPress={this.hitEnter} />
								</InputGroup>
								{/* <Label for="password" className="mt-3 font-medium">Password</Label> */}
								<InputGroup className="mb-3" size="lg">
									{/* <InputGroupAddon addonType="prepend"> */}
										<InputGroupText className='prepend'>
											<img src={iconPassword} alt="iconPassword" width="30" />
										</InputGroupText>
									{/* </InputGroupAddon> */}
									<Input type="password" className={this.state.passError} id="password" placeholder="Password" defaultValue={this.state.password} autoComplete="current-password" required onChange={this.handlePassChange} onKeyPress={this.hitEnter} />
									{/* <InputGroupAddon addonType="append"> */}
										<InputGroupText className='append'>
											<a href="javascript:" onClick={this.showPassword}>
												<img id="showpasswordimg" src={this.state.passimg} alt="view" width="25" />
											</a>
										</InputGroupText>
									{/* </InputGroupAddon> */}
								</InputGroup>



								{/* <div className="d-flex no-block align-items-center mb-4 mt-4">
									<Input type="checkbox" id="remember" label="Remember Me" />
								</div> */}

								<FormGroup className="form-group mb-3"  check>
									<Label check>
										<Input  type="checkbox"  id="remember" />{' '}
										Remember me
									</Label>
								</FormGroup>

								<Row className="mb-3">
									<Col xs="12">
										<Button size="lg" className="btn-grey" onClick={this.handleSubmit} block id="continue">Continue</Button>


										{/* Loader button */}
										<div className="btn btn-primary d-none" id="loadbutton">
											<Loader type="Oval" color="#414241" height={20} width={292} />
										</div>
										{/* Loader button */}


									</Col>
								</Row>
								<div className="forgotpwd">
									<Link to="/authentication/recover-pwd">
										Forgot Password ?
          							</Link>
								</div>
							</Form>
						</div>

					</div>
				</Row>
				<footer className="footer">
					<Row className="justify-content-between align-items-center">
						<Col>
							<ul>
								<li><a href="http://brand.alj.com/" target="_blank">brand.alj.com</a></li>
							</ul>
						</Col>
						<Col md="auto">
							<ul>
								<li><a href="https://www.alj.com/" target="_blank">alj.com</a></li>
							</ul>
						</Col>
					</Row>
				</footer>
			</div>
		</div>;
	}
}

export default Login;
