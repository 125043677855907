import React from 'react';

import {
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    Button,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    InputGroupButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    FormFeedback,
    CustomInput,
    Tooltip,
    UncontrolledTooltip,
    Alert
} from 'reactstrap'; 
import { colourOptions } from './data.jsx';
import Select, { components } from "react-select";
import defaultuser from '../../assets/images/icons/default-user.png';

import jQuery from 'jquery';


/*------------------------------------*/
/* For Select Plugin - (3)            */
/*------------------------------------*/
const CustomClearText = () => 'clear all';
const ClearIndicator = (props) => {
  const { children = <CustomClearText />, getStyles, innerProps: { ref, ...restInnerProps } } = props;
  return (
    <div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props)}>
      <div style={{ padding: '0px 5px' }}>
        {children}
      </div>
    </div>
  );
};

const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: 'pointer',
  color: state.isFocused ? 'blue' : 'black',
});


class Basicform extends React.Component {
  constructor(props) {
    super(props);

    this.toggleDropDown = this.toggleDropDown.bind(this);
    this.toggleDropDown1 = this.toggleDropDown1.bind(this);
    this.state = {
      dropdownOpen: false,
      dropdownOpen1: false,
      files : [],
    };

    this.toggle = this.toggle.bind(this);
      this.state = {
        tooltipOpen: false
      };
    }

    toggle() {
      this.setState({
        tooltipOpen: !this.state.tooltipOpen
      });
    }
 

  toggleDropDown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  toggleDropDown1() {
    this.setState({
      dropdownOpen1: !this.state.dropdownOpen1
    });
  }

  async componentDidMount(evt) {
    jQuery(".opendefault").next('.card-body').show();
    jQuery(".opendefault").addClass('active');
  }

  h5Click = (event) => { 
  jQuery(event.target).parents('.card').find('.card-body').slideToggle('slow');
  jQuery(event.target).toggleClass('active');
  }
  
  toolTipshow = (event) => { 
    jQuery(event.target).next('.tooltipshow').show();
  }
  toolTiphide= (event) => { 
    jQuery(event.target).next('.tooltipshow').hide();
  }

    render() {
      return <div>
              {/*--------------------------------------------------------------------------------*/}
              {/* Start Inner Div*/}
              {/*--------------------------------------------------------------------------------*/}
            <Row className="justify-content-end">
            <Col className="col-auto mb-4">
            <a href="/dashboard" className="btn btn-primary btncancle mr-3"> <span className="border-left pl-2">Cancle</span></a>
            <a href="javascript:" className="btn btn-primary"> <span>Save</span></a>
            </Col>
            </Row>

              <Row>
                {/*--------------------------------------------------------------------------------*/}
                {/* Ordinary Form                                                                  */}
                {/*--------------------------------------------------------------------------------*/}
                <Col md="12">
                <Alert className="customerror">
                        <h4 className="alert-heading">Information is missing in a section to save content</h4>
                        <p className="mb-0">
                          Please <a href="" className="alert-link">choose a section</a> to fix this problem.
                        </p>
                </Alert>
                <p className="requiredtext">( * ) Required fields</p>
                  <Card className="customAcc">
                    <CardTitle onClick={this.h5Click.bind(this)} className="completed">
                    <i className="icon-circle fas fa-check"></i>
                      Trademark Details
                    </CardTitle>
                    <CardBody>
                      <Form>
                        <Row>
                        <Col md="8">
                        <Row>
                        <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="trademarkname">Trademark name * <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i> <span className="tooltipshow">Hello world!</span></Label>
                          <Input type="text" name="trademarkname" id="trademarkname" />
                        </FormGroup>
                        <FormGroup className="form-group">
                          <Label htmlFor="lauguage">Lauguage <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i> <span className="tooltipshow">Hello world!</span></Label>
                        <Form inline className="customcheck mb-3">
                        <div className="form-check form-check-inline">
                            <Input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
                            <Label for="inlineCheckbox1">English</Label>
                        </div>
                        <div className="form-check form-check-inline">
                            <Input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" />
                            <Label for="inlineCheckbox2">Arabic</Label>
                        </div>
                        <div className="form-check form-check-inline">
                            <Input className="form-check-input" type="checkbox" id="inlineCheckbox3" value="option3" />
                            <Label for="inlineCheckbox3">Both</Label>
                        </div>
                        <div className="form-check form-check-inline">
                            <Input className="form-check-input" type="checkbox" id="inlineCheckbox4" value="option3" />
                            <Label for="inlineCheckbox4">Others  (Please specify)</Label>
                        </div>
                      </Form>
                          <Input type="text" name="lauguage" id="lauguage" />
                        </FormGroup>
                      
                        <FormGroup className="form-group">
                          <Label htmlFor="exampleEmail12">Classes<i className="far fa-question-circle ml-1" href="javascript:" id="UncontrolledTooltipExample5"></i></Label>
                      <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample5">
                        Hello world!
                      </UncontrolledTooltip>
                          <Select
                closeMenuOnSelect={false}
                components={{ ClearIndicator }}
                styles={{ clearIndicator: ClearIndicatorStyles }}
                defaultValue={[colourOptions[4], colourOptions[5]]}
                isMulti
                options={colourOptions}
              />
                        </FormGroup>

                        <FormGroup className="form-group">
                          <Label htmlFor="exampleEmail12">Business Unit<i className="far fa-question-circle ml-1" href="javascript:" id="UncontrolledTooltipExample4"></i></Label>
                      <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample4">
                        Hello world!
                      </UncontrolledTooltip>
                          <Input type="select" className="custom-select">
                              <option value="">Select</option>
                              <option>Value 1</option>
                              <option>Value 2</option>
                              <option>Value 3</option>
                              <option>Value 4</option>
                              <option>Value 5</option>
                            </Input>
                        </FormGroup>
                        </Col>

                        <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="lauguage">Trademark type <i className="far fa-question-circle ml-1" href="javascript:" id="UncontrolledTooltipExample3"></i></Label>
                      <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample3">
                        Hello world!
                      </UncontrolledTooltip>
                      <Form inline className="customcheck greybgcheck">
                        <div className="form-check form-check-inline">
                            <Input className="form-check-input" type="checkbox" id="inlineCheckbox5" value="option1" />
                            <Label for="inlineCheckbox5">Wordmark</Label>
                        </div>
                        <div className="form-check form-check-inline">
                            <Input className="form-check-input" type="checkbox" id="inlineCheckbox6" value="option2" />
                            <Label for="inlineCheckbox6">Graphic Device</Label>
                        </div>
                        <div className="form-check form-check-inline">
                            <Input className="form-check-input" type="checkbox" id="inlineCheckbox7" value="option3" />
                            <Label for="inlineCheckbox7">Color</Label>
                        </div>
                        <div className="form-check form-check-inline">
                            <Input className="form-check-input" type="checkbox" id="inlineCheckbox8" value="option3" />
                            <Label for="inlineCheckbox8">Black and White</Label>
                        </div>
                      </Form>
                        </FormGroup>

                        <FormGroup className="form-group">
                          <Label htmlFor="exampleEmail12">Commerical Contact *<i className="far fa-question-circle ml-1" href="javascript:" id="commerical"></i></Label>
                      <UncontrolledTooltip placement="right" target="commerical">
                        Hello world!
                      </UncontrolledTooltip>
                          <Input type="select" className="custom-select">
                              <option value="">Select</option>
                              <option>Value 1</option>
                              <option>Value 2</option>
                              <option>Value 3</option>
                              <option>Value 4</option>
                              <option>Value 5</option>
                            </Input>
                        </FormGroup>
                      
                      <FormGroup className="form-group">
                        <Label htmlFor="exampleEmail12">Jurisdictions <i className="far fa-question-circle ml-1" href="javascript:" id="jurisdictions"></i></Label>
                    <UncontrolledTooltip placement="right" target="jurisdictions">
                      Hello world!
                    </UncontrolledTooltip>
                        <Select
              closeMenuOnSelect={false}
              components={{ ClearIndicator }}
              styles={{ clearIndicator: ClearIndicatorStyles }}
              defaultValue={[colourOptions[4], colourOptions[5]]}
              isMulti
              options={colourOptions}
            />
                      </FormGroup>

                        <FormGroup className="form-group">
                          <Label htmlFor="exampleEmail12">Countries <i className="far fa-question-circle ml-1" href="javascript:" id="countries"></i></Label>
                      <UncontrolledTooltip placement="right" target="countries">
                        Hello world!
                      </UncontrolledTooltip>
                          <Input type="select" className="custom-select">
                              <option value="">Select</option>
                              <option>Value 1</option>
                              <option>Value 2</option>
                              <option>Value 3</option>
                              <option>Value 4</option>
                              <option>Value 5</option>
                            </Input>
                        </FormGroup>
                        </Col>
                            </Row>
                            </Col>

                        <Col md="4">
                        <FormGroup className="form-group">
                          <Label htmlFor="exampleEmail12">Graphic device logotype<i className="far fa-question-circle ml-1" href="javascript:" id="graphic"></i></Label>
                      <UncontrolledTooltip placement="right" target="graphic">
                        Hello world!
                      </UncontrolledTooltip>
                      <InputGroup>
                            <div className="custom-file">
                                <Input type="file" className="custom-file-input" id="sdsdsd" />
                                <label className="custom-file-label" htmlFor="inputGroupFile01">Upload your file here</label>
                            </div>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className="form-group">
                          <InputGroup>
                            <div className="custom-file">
                                <Input type="file" className="custom-file-input" id="inputGroupFile02" />
                                <label className="custom-file-label" htmlFor="inputGroupFile01">Upload your file here</label>
                            </div>
                          </InputGroup>
                        </FormGroup>
                        </Col>
                        </Row>
                        <Button color="primary" className="float-right pl-5 pr-5" >Done</Button>
                      </Form>
                    </CardBody>
                  </Card>
                  
                 <Card className="customAcc">
                   <CardTitle onClick={this.h5Click.bind(this)} className="failed">
                    <i className="icon-circle fas fa-exclamation"></i>
                    Law Firm
                    </CardTitle>
                    <CardBody>
                      <Form>
                        <Row>
                          <Col md="4">
                        <FormGroup className="form-group">
                          <Label htmlFor="lawfirm">Law Firm <i className="far fa-question-circle ml-1" href="javascript:" id="lawfirm"></i></Label>
                      <UncontrolledTooltip placement="right" target="lawfirm">
                        Hello world!
                      </UncontrolledTooltip>
                         <Input type="select" className="law-select">
                              <option value="">Select</option>
                              <option>Value 1</option>
                              <option>Value 2</option>
                              <option>Value 3</option>
                              <option>Value 4</option>
                              <option>Value 5</option>
                            </Input>
                        </FormGroup>
                          </Col>
                          <Col md="4">
                        <FormGroup className="form-group">
                          <Label htmlFor="trademarkname">TW Reference No. <i className="far fa-question-circle ml-1" href="javascript:" id="twreference"></i></Label>
                      <UncontrolledTooltip placement="right" target="twreference">
                        Hello world!
                      </UncontrolledTooltip>
                          <Input type="text" name="trademarkname" id="twreference" />
                        </FormGroup>
                        </Col>
                          <Col md="4">
                        <FormGroup className="form-group">
                          <Label htmlFor="trademarkname">AGIP Reference No. <i className="far fa-question-circle ml-1" href="javascript:" id="agipreference"></i></Label>
                      <UncontrolledTooltip placement="right" target="agipreference">
                        Hello world!
                      </UncontrolledTooltip>
                          <Input type="text" name="trademarkname" id="agipreference" />
                        </FormGroup>
                        </Col>
                        </Row>
                        <FormGroup  className="form-group customcheck mb-0">
                        <div className="form-check form-check-inline">
                            <Input className="form-check-input" type="checkbox" id="inlineCheckbox9" value="option1" />
                            <Label for="inlineCheckbox9">Transfer to AGIP</Label>
                        </div>
                        </FormGroup>
                        <Button color="primary" className="float-right pl-5 pr-5">Done</Button>
                      </Form>
                    </CardBody>
                  </Card>

                  <Card className="customAcc">
                  <CardTitle onClick={this.h5Click.bind(this)}>
                    <i className="icon-circle icon-pencil"></i>
                    Notes / Discussion
                    </CardTitle>
                    <CardBody>
                      <Form>
                       <div className="d-flex align-items-center pb-3">
                       <img src={defaultuser} alt={"defaultuser"} className="rounded-circle mr-2" width="50" />
                       <h5 className="notes-title">Paul Stokes</h5>
                       </div>
                       <FormGroup className="form-group">
                            <Input type="textarea" rows="5" placeholder="Add your comments here..." />
                        </FormGroup>
                        <Button color="primary" className="float-right pl-5 pr-5 ml-3">Post</Button>
                        <Button color="primary" className="float-right btn-default pl-5 pr-5">Done</Button>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
                </Row>
              {/*--------------------------------------------------------------------------------*/}
              {/*End Inner Div*/}
              {/*--------------------------------------------------------------------------------*/}
            </div>
    }
}

export default Basicform;
