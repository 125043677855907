import jQuery from 'jquery';
var Parse = require('parse');

export const getFullPageCount = async () => {
    var Registrar = Parse.Object.extend("Registrar");
    var query = new Parse.Query(Registrar);
    query.equalTo("status", true);
    query.descending("createdAt");
    const count = await query.count();

    return count;
}

export const getSearchResults = async (searchdatas) => {
    var Registrar = Parse.Object.extend("Registrar");
    var query = new Parse.Query(Registrar);
    query.descending("createdAt");
    query.equalTo("status", true);

    if (searchdatas['searchbox']) {
        query.matches("name", searchdatas['searchbox'], 'i');
    }

    if (searchdatas['statsrc'] == "true") {
        query.equalTo("status", true);
    } else if (searchdatas['statsrc'] == "false") {
        query.equalTo("status", false);
    }
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });

}

export const getRegistrar = async (pagenum, dataperpage, sortitem, sortitemorder) => {
    var skipnum = pagenum - 1;
    var perpage = parseInt(dataperpage);

    var Registrar = Parse.Object.extend("Registrar");
    var query = new Parse.Query(Registrar);
    query.equalTo("status", true);

    if (sortitem !== "") {
        if (sortitemorder === "ascending") {
            query.ascending(sortitem);
        } else {
            query.descending(sortitem);
        }

    } else {
        query.descending('createdAt');
    }

    query.skip(skipnum * perpage);
    query.limit(perpage);

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}


export const hideRecords = async (objectids) => {
    var parseobject = Parse.Object.extend("Registrar");
    var query = new Parse.Query(parseobject);
    query.equalTo("objectId", objectids);

    return query.find().then(async (object) => {
        var realbject = object[0];
        realbject.set("status", false);
        return realbject.save().then((records) => {
            return true;
        }, (error) => {
            console.log(error);
        }).catch(function (error) {
        });

    }, (error) => {
    });

}


export const addRecord = async ({ name, description, status }) => {
    var registrar = new Parse.Object("Registrar");
    registrar.set("name", name);
    registrar.set("description", description);
    if (status === 'false') {
        registrar.set("status", false);
    } else {
        registrar.set("status", true);
    }
    return registrar.save().then(async (records) => {
        return records;
    }, (error) => {
        return false;
    }).catch(function (error) {
        return false;
    });
}


export const fetchRecordById = async ({ objectid }) => {
    var Registrar = Parse.Object.extend("Registrar");
    var query = new Parse.Query(Registrar);
    query.equalTo("objectId", objectid);
    return query.find().then((records) => {
        return records;
    }, (error) => {
        return false;
    }).catch(function (error) {
        return false;
    });
}


export const updateRecord = async ({ edithiddenid, name, description, status }) => {
    var Registrar = Parse.Object.extend("Registrar");
    var query = new Parse.Query(Registrar);
    query.equalTo("objectId", edithiddenid);

    return query.find().then(async (object) => {

        var realbject = object[0];

        realbject.set("name", name);
        realbject.set("description", description);
        if (status === 'false') {
            realbject.set("status", false);
        } else {
            realbject.set("status", true);
        }

        return realbject.save().then(async (records) => {
            return true;
        }, (error) => {
            return false;
        }).catch(function (error) {
            return false;
        });

    }, (error) => {
        return false;
    });

}

export const checkFieldValid = (addoredit) => {

    var name = jQuery('#bname');
    var status = jQuery('#status');

    var errstat = true;
    if (name.val() === "") {
        name.addClass("is-invalid");
        errstat = false;
    } else {
        name.removeClass("is-invalid");
    }
    if (status.val() === "") {
        status.addClass("is-invalid");
        errstat = false;
    } else {
        status.removeClass("is-invalid");
    }


    if (addoredit === "add") {

    }

    if (errstat === false) {
        return false;
    } else {
        return true;
    }

}

