import React from 'react';
import { Link } from 'react-router-dom';
import ScrollArea from 'react-scrollbar';
import { Row, Col, Carousel, CarouselItem, CarouselControl, Button, Table, Input, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, ModalFooter, Label, Alert, UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem, TabContent, TabPane, CardBody, Nav, NavItem, NavLink, Card, CardTitle, CardText } from 'reactstrap';
import Select, { components } from "react-select";
import Datetime from "react-datetime";
import DatePicker from "react-datepicker";
import 'react-datetime/css/react-datetime.css';
import jQuery from 'jquery';
import classnames from 'classnames';
import { addBreadcrumb, addHeaderTitle } from '../../../services/parse';
import { isAdmin } from '../../../services/user';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import imageDummy from '../../../assets/images/dummy.jpg';
import noImage from '../../../assets/images/no-image.jpg';
import logoLemon from '../../../assets/images/icons/logo-lemon.png';
import iconCalender from '../../../assets/images/icons/icon-calendar.png';
import { getLicenseType, getAssetType, getMediaLicenseLibrary, getGeoRestriction, getAgency, getBrand, getAssetbyLicenseName, updateFavorite, undoRecord } from '../../../services/medialicenseassets';
import iconCamera from '../../../assets/images/icons/icon-camera.png';
import iconPencil from '../../../assets/images/icons/icon-pencil.png';
import iconMedia from '../../../assets/images/icons/icon-media.png';
import iconAudio from '../../../assets/images/icons/icon-audio.png';
import Loader from '../../../components/common/Loader';

import Addasset from "./Addasset";
import Editasset from "./Editasset";
import Viewassets from "./Viewassets";
import Assetimage from "./Assetimage";

import Calendarview from "./calendarview";


function openExpiring() {
  document.getElementById("assetsbx").style.visibility = "hidden";
  document.getElementById("librarybx").style.visibility = "hidden";
  document.getElementById("agencygbx").style.visibility = "hidden";
  document.getElementById("addedonbx").style.visibility = "hidden";
  var Id = document.getElementById("expiringbx");
  if (Id.style.visibility === "visible") {
    Id.style.visibility = "hidden";
  } else {
    Id.style.visibility = "visible";
  }
}

function openAssets() {
  document.getElementById("expiringbx").style.visibility = "hidden";
  document.getElementById("librarybx").style.visibility = "hidden";
  document.getElementById("agencygbx").style.visibility = "hidden";
  document.getElementById("addedonbx").style.visibility = "hidden";
  var Id = document.getElementById("assetsbx");
  if (Id.style.visibility === "visible") {
    Id.style.visibility = "hidden";
  } else {
    Id.style.visibility = "visible";
  }
}

function openLibrary() {
  document.getElementById("expiringbx").style.visibility = "hidden";
  document.getElementById("assetsbx").style.visibility = "hidden";
  document.getElementById("agencygbx").style.visibility = "hidden";
  document.getElementById("addedonbx").style.visibility = "hidden";
  var Id = document.getElementById("librarybx");
  if (Id.style.visibility === "visible") {
    Id.style.visibility = "hidden";
  } else {
    Id.style.visibility = "visible";
  }
}

function openAgency() {
  document.getElementById("expiringbx").style.visibility = "hidden";
  document.getElementById("assetsbx").style.visibility = "hidden";
  document.getElementById("librarybx").style.visibility = "hidden";
  document.getElementById("addedonbx").style.visibility = "hidden";
  var Id = document.getElementById("agencygbx");
  if (Id.style.visibility === "visible") {
    Id.style.visibility = "hidden";
  } else {
    Id.style.visibility = "visible";
  }
}

function openAddedon() {
  document.getElementById("expiringbx").style.visibility = "hidden";
  document.getElementById("assetsbx").style.visibility = "hidden";
  document.getElementById("librarybx").style.visibility = "hidden";
  document.getElementById("agencygbx").style.visibility = "hidden";
  var Id = document.getElementById("addedonbx");
  if (Id.style.visibility === "visible") {
    Id.style.visibility = "hidden";
  } else {
    Id.style.visibility = "visible";
  }
}


class mediaAssetsFull extends React.Component {

  constructor(props) {
    super(props);
    this.state = { activeIndex: 0, visible: true };
    this.addnewpopuptoggle = this.addnewpopuptoggle.bind(this);
    this.editpopuptoggle = this.editpopuptoggle.bind(this);
    this.viewpopuptoggle = this.viewpopuptoggle.bind(this);
    this.deletepopuptoggle = this.deletepopuptoggle.bind(this);
    this.onDismiss = this.onDismiss.bind(this);


    this.state = {
      mainDataLoad: false,
      fullDataLoad: false,

      favoritefilter: false,
      keywordfilter: "",
      expiringinfilter: "",
      assettypefilter: [],
      libraryfilter: [],
      agencyfilter: [],
      addedonfilter: "",
      addedonfilterstart: null,
      addedonfilterend: null,

      updateIDforImage: "",
      popupstatus: "",
      licenseItems: [],
      medialicenselibrary: [],
      assettypes: [],
      georestrictions: [],
      agencylist: [],
      brandlist: [],
      fullAssetData: [],

      activeTab: '1',
      editObjectID: null,
      loader: false,
      nonEditorialData: null,
      editorialData: null,
      royaltyData: null,
      standardData: null,
    };
  }


  componentWillMount = async () => {
    var isadminval = await isAdmin();
    if (isadminval) {
      var currentrole = isadminval[0].get('role').get('name');
      this.setState({ currentRole: currentrole });
      addBreadcrumb('Home / Media License');
      addHeaderTitle('Assets 2');
    }

    this.setState({ mainDataLoad: true });
    this.setState({ fullDataLoad: true });


    // var fulldata = []

    // var noneditorial = await getAssetbyLicenseName("non-editorial");
    // this.setState({ nonEditorialData: noneditorial });

    // this.setState({ mainDataLoad: false });

    // var editorial = await getAssetbyLicenseName("editorial");
    // this.setState({ editorialData: editorial });

    // var royalty = await getAssetbyLicenseName("royalty-free");
    // this.setState({ royaltyData: royalty });

    // var standard = await getAssetbyLicenseName("standard");
    // this.setState({ standardData: standard });


    // fulldata = fulldata.concat(noneditorial, editorial, royalty, standard);


    var fulldata = await getAssetbyLicenseName();
  
    this.setState({ fullAssetData: fulldata });

    this.setState({ mainDataLoad: false });






    //Add Edit Datas
    var licensetypes = await getLicenseType();
    this.setState({ licenseItems: licensetypes, });

    var medialiclib = await getMediaLicenseLibrary();
    var libraryOptions = [];
    if (medialiclib && medialiclib.length > 0) {
      for (let i = 0; i < medialiclib.length; i++) {
        libraryOptions.push({ value: medialiclib[i].id, label: medialiclib[i].attributes.name })
      }
    }
    this.setState({ medialicenselibrary: libraryOptions });


    var getassettype = await getAssetType();
    this.setState({ assettypes: getassettype });

    var georestrict = await getGeoRestriction();
    this.setState({ georestrictions: georestrict });


    var agencies = await getAgency();
    var agencyOptions = [];
    if (agencies && agencies.length > 0) {
      for (let i = 0; i < agencies.length; i++) {
        agencyOptions.push({ value: agencies[i].id, label: agencies[i].attributes.name })
      }
    }
    this.setState({ agencylist: agencyOptions });

    var brands = await getBrand();
    this.setState({ brandlist: brands });

    this.setState({ fullDataLoad: false });
    //Add Edit Datas


  }


  getDataUpdate = async (licensename) => {
    this.setState({ mainDataLoad: true });
    // this.setState({ fullDataLoad: true });

    // var fulldata = []

    // if (licensename === "non-editorial") {
    //   var noneditorial = await getAssetbyLicenseName("non-editorial");
    //   this.setState({ nonEditorialData: noneditorial });
    //   if (noneditorial) {
    //     fulldata = fulldata.concat(noneditorial, this.state.editorialData, this.state.royaltyData, this.state.standardData);
    //   }
    // } else if (licensename === "editorial") {
    //   var editorial = await getAssetbyLicenseName("editorial");
    //   this.setState({ editorialData: editorial });
    //   if (editorial) {
    //     fulldata = fulldata.concat(this.state.nonEditorialData, editorial, this.state.royaltyData, this.state.standardData);
    //   }

    // } else if (licensename === "royalty-free") {
    //   var royalty = await getAssetbyLicenseName("royalty-free");
    //   this.setState({ royaltyData: royalty });
    //   if (royalty) {
    //     fulldata = fulldata.concat(this.state.nonEditorialData, this.state.editorialData, royalty, this.state.standardData);
    //   }
    // } else if (licensename === "standard") {
    //   var standard = await getAssetbyLicenseName("standard");
    //   this.setState({ standardData: standard });
    //   if (standard) {
    //     fulldata = fulldata.concat(this.state.nonEditorialData, this.state.editorialData, this.state.royaltyData, standard);
    //   }
    // }

    var fulldata = await getAssetbyLicenseName();

    this.setState({ mainDataLoad: false });
  
    this.setState({ fullAssetData: fulldata });

    // this.setState({ fullDataLoad: false });


    this.setState({ updateIDforImage: "" });
  }



  genericFilter = async () => {
    this.setState({
      popupstatus: "filter"
    });
    this.setState({ mainDataLoad: true });
    // this.setState({ fullDataLoad: true });



    var searchdatas = {
      favoritefilter: this.state.favoritefilter,
      keywordfilter: this.state.keywordfilter,
      expiringinfilter: this.state.expiringinfilter,
      assettypefilter: this.state.assettypefilter,
      libraryfilter: this.state.libraryfilter,
      agencyfilter: this.state.agencyfilter,
      addedonfilter: this.state.addedonfilter,
      addedonfilterstart: this.state.addedonfilterstart,
      addedonfilterend: this.state.addedonfilterend,
    };



    // var noneditorial = await getAssetbyLicenseName("non-editorial", searchdatas);
    // this.setState({ nonEditorialData: noneditorial });

    // this.setState({ mainDataLoad: false });

    // var editorial = await getAssetbyLicenseName("editorial", searchdatas);
    // this.setState({ editorialData: editorial });

    // var royalty = await getAssetbyLicenseName("royalty-free", searchdatas);
    // this.setState({ royaltyData: royalty });

    // var standard = await getAssetbyLicenseName("standard", searchdatas);
    // this.setState({ standardData: standard });

    var fulldata = await getAssetbyLicenseName(null, searchdatas);
    this.setState({ fullAssetData: fulldata });

    this.setState({ mainDataLoad: false });


    this.setState({
      popupstatus: ""
    });

    // this.setState({ fullDataLoad: false });
  }



  expiringFilterClick = async (event) => {
    this.setState({ expiringinfilter: event.target.value });
  }

  assettypeFilterClick = async (event) => {
    var check = event.target.checked;
    var val = jQuery(event.target).val();
    if (val === "all" && check === true) {
      jQuery('input:checkbox[name="assettypefiltercheck"]').prop('checked', true);
    }

    var assetlist = [];
    jQuery.each(jQuery("input[name='assettypefiltercheck']:checked"), function () {
      assetlist.push(jQuery(this).val());
    });
    this.setState({ assettypefilter: assetlist });
  }

  libraryFilterClick = async (event) => {
    var check = event.target.checked;
    var val = jQuery(event.target).val();
    if (val === "all" && check === true) {
      jQuery('input:checkbox[name="libraryfiltercheck"]').prop('checked', true);
    }

    var liblist = [];
    jQuery.each(jQuery("input[name='libraryfiltercheck']:checked"), function () {
      liblist.push(jQuery(this).val());
    });
    this.setState({ libraryfilter: liblist });
  }

  agencyFilterClick = async (event) => {
    var check = event.target.checked;
    var val = jQuery(event.target).val();
    if (val === "all" && check === true) {
      jQuery('input:checkbox[name="agencyfiltercheck"]').prop('checked', true);
    }

    var agencylist = [];
    jQuery.each(jQuery("input[name='agencyfiltercheck']:checked"), function () {
      agencylist.push(jQuery(this).val());
    });
    this.setState({ agencyfilter: agencylist });
  }

  addedonFilterClick = async (event) => {
    var val = jQuery(event.target).val();
    this.setState({ addedonfilter: val });

    if (val !== "range") {
      this.setState({ addedonfilterstart: null, addedonfilterend: null });
    }

  }




  addnewpopuptoggle() {
    this.setState({
      addnewpopup: !this.state.addnewpopup,
      popupstatus: "add",
      viewpopup: false
    });
  }

  viewpopuptoggle(e) {

    this.setState({
      deleteClicked: false,
      popupstatus: "view",
      viewpopup: !this.state.viewpopup
    });
    this.setObjectID(e.target.getAttribute("data-objectid"));
  }

  editpopuptoggle(e) {
    this.setState({
      deleteClicked: false,
      popupstatus: "edit",
      editpopup: !this.state.editpopup,
      viewpopup: false,
    });
    this.setObjectID(e.target.getAttribute("data-objectid"));
  }

  deletepopuptoggle(e) {
    this.setState({
      viewpopup: !this.state.viewpopup,
      popupstatus: "view",
      deleteClicked: true,
    });
    this.setObjectID(e.target.getAttribute("data-objectid"));
  }


  onDismiss() {
    this.setState({
      visible: false
    });
  }
  toggleTabs(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  setObjectID(objectid) {
    this.setState({
      editObjectID: objectid !== null ? objectid : null
    });
  }


  setFavorite = async (e) => {


    var objid = e.target.value;
    var val = "";

    var thisitem = jQuery(e.target);

    if (thisitem.hasClass('active')) {
      val = false;
      thisitem.removeClass('active');
    } else {
      val = true;
      thisitem.addClass('active');
    }

    console.log(val)


    if (val !== "") {
      var fav = await updateFavorite(objid, val);
      console.log(fav)
    }

  }



  undoRecord = async () => {
    var undo = await undoRecord(this.state.deletedID);
    if (undo) {
      this.setState({
        deletedID: null,
        deletedName: null,
      });

      this.getDataUpdate(undo.get('licensetypename'));
    }
  }

  render() {
    return (
      <div className="animated fadeIn">

        <div className="pageheader">
          <Row className="justify-content-between">
            <Col className="col-md-auto">

              {/* {
                this.state.fullDataLoad === true ?
                  <div className="btn btn-default">
                    <Loader type="Oval" color="#414241" height={20} width={20} />
                  </div>
                  :
                  <Button className="btn btn-default btnaddmew pluswhite" onClick={this.addnewpopuptoggle}>Add new</Button>
              } */}

              <Button className="btn btn-default btnaddmew pluswhite" onClick={this.addnewpopuptoggle}>Add new</Button>


              <Link to="/medialicense/bulkimport" className="btn btn-default btnbulk ml-3">Bulk Upload</Link>
            </Col>

            <Col className="col-md">














              <div className="assetsfilterrow1">
                <Row>
                  <Col className="col-12">
                    <Form>
                      <Row className="align-items-center">
                        <Col>
                          <div className="assestfilter">
                            <Row className="no-gutters">
                              <Col>
                                <div className="filbx">
                                  <label htmlFor="keyword">Enter keyword</label>
                                  <div className="mydropdown">
                                    <input type="text" id="keyword" placeholder="i.e space"
                                      onChange={
                                        async (event) => {
                                          this.setState({ keywordfilter: event.target.value });
                                        }
                                      }
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col>
                                <div className="filbx">
                                  <label htmlFor="keyword">Expiring in days</label>
                                  {/* {
                                    this.state.expiringinfilter ?
                                      <label>( {this.state.expiringinfilter} )</label>
                                      :
                                      null
                                  } */}


                                  <div className="mydropdown">
                                    <a href="javascript:" className="dropbtn" onClick={openExpiring}>Any <i className="fas fa-chevron-down"></i></a>
                                    <div className="filtermenu" id="expiringbx">
                                      <div className="formcol">
                                        <Input className="form-check-input" name="expiring" type="radio" id="radio1"
                                          value="expired"
                                          onClick={this.expiringFilterClick}
                                        />
                                        <Label htmlFor="radio1">Already Expired</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="expiring" type="radio" id="radio2"
                                          value="24h"
                                          onClick={this.expiringFilterClick}
                                        />
                                        <Label htmlFor="radio2">Next 24 hrs</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="expiring" type="radio" id="radio3"
                                          value="3d"
                                          onClick={this.expiringFilterClick}
                                        />
                                        <Label htmlFor="radio3">Next 3 days</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="expiring" type="radio" id="radio4"
                                          value="7d"
                                          onClick={this.expiringFilterClick}
                                        />
                                        <Label htmlFor="radio4">Next 7 days</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="expiring" type="radio" id="radio5"
                                          value="15d"
                                          onClick={this.expiringFilterClick}
                                        />
                                        <Label htmlFor="radio5">Next 15 days</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="expiring" type="radio" id="radio6"
                                          value="30d"
                                          onClick={this.expiringFilterClick}
                                        />
                                        <Label htmlFor="radio6">Next 30 days</Label>
                                      </div>

                                      {/* <div className="formcol w100 mb-0 mt-3">
                                        <Input className="form-check-input" name="expiring" type="radio" id="radio7" value="Wordmark" />
                                        <Label htmlFor="radio7"><Input type="range" defaultValue="1999-08" /></Label>
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col>
                                <div className="filbx">
                                  <label htmlFor="keyword">Asset Type</label>

                                  {/* {
                                    this.state.assettypefilter.length > 0 ?
                                      <label>(
                                        {
                                          this.state.assettypefilter.includes("all") ?
                                            this.state.assettypefilter.length - 1
                                            :
                                            this.state.assettypefilter.length
                                        }
                                        )</label>
                                      :
                                      null
                                  } */}


                                  <div className="mydropdown">
                                    <a href="javascript:" className="dropbtn" onClick={openAssets}>Any <i className="fas fa-chevron-down"></i></a>
                                    <div className="filtermenu" id="assetsbx">

                                      <div className="formcol">
                                        <Input className="form-check-input" name="assettypefiltercheck" type="checkbox"
                                          id="assetsshowall"
                                          value="all"
                                          onClick={this.assettypeFilterClick}
                                        />
                                        <Label htmlFor="assetsshowall">Show all</Label>
                                      </div>

                                      {
                                        this.state.assettypes && this.state.assettypes.map((dynamicData, key) =>
                                          <div className="formcol" key={key}>
                                            <Input className="form-check-input" name="assettypefiltercheck" type="checkbox"
                                              id={`ass${dynamicData.id}`}
                                              value={dynamicData.id}
                                              onClick={this.assettypeFilterClick}
                                            />
                                            <Label htmlFor={`ass${dynamicData.id}`}>{dynamicData.get('name')}</Label>
                                          </div>
                                        )
                                      }

                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col>
                                <div className="filbx">
                                  <label htmlFor="keyword">Library</label>
                                  {/* {
                                    this.state.libraryfilter.length > 0 ?
                                      <label>(
                                        {
                                          this.state.libraryfilter.includes("all") ?
                                            this.state.libraryfilter.length - 1
                                            :
                                            this.state.libraryfilter.length
                                        }
                                        )</label>
                                      :
                                      null
                                  } */}
                                  <div className="mydropdown">
                                    <a href="javascript:" className="dropbtn" onClick={openLibrary}>Any <i className="fas fa-chevron-down"></i></a>
                                    <div className="filtermenu" id="librarybx">

                                      <div className="formcol">
                                        <Input className="form-check-input" name="libraryfiltercheck" type="checkbox"
                                          id="libshowall"
                                          value="all"
                                          onClick={this.libraryFilterClick}
                                        />
                                        <Label htmlFor="libshowall">Show all</Label>
                                      </div>

                                      {
                                        this.state.medialicenselibrary && this.state.medialicenselibrary.map((dynamicData, key) =>
                                          <div className="formcol" key={key}>
                                            <Input className="form-check-input" name="libraryfiltercheck" type="checkbox"
                                              id={`lib${dynamicData.value}`}
                                              value={dynamicData.value}
                                              onClick={this.libraryFilterClick}
                                            />
                                            <Label htmlFor={`lib${dynamicData.value}`}>{dynamicData.label}</Label>
                                          </div>
                                        )
                                      }

                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col>
                                <div className="filbx">
                                  <label htmlFor="keyword">Agency</label>
                                  {/* {
                                    this.state.agencyfilter.length > 0 ?
                                      <label>(
                                        {
                                          this.state.agencyfilter.includes("all") ?
                                            this.state.agencyfilter.length - 1
                                            :
                                            this.state.agencyfilter.length
                                        }
                                        )</label>
                                      :
                                      null
                                  } */}
                                  <div className="mydropdown">
                                    <a href="javascript:" className="dropbtn" onClick={openAgency}>Any <i className="fas fa-chevron-down"></i></a>
                                    <div className="filtermenu" id="agencygbx">

                                      <div className="formcol">
                                        <Input className="form-check-input" name="agencyfiltercheck" type="checkbox"
                                          id="agenshowall"
                                          value="all"
                                          onClick={this.agencyFilterClick}
                                        />
                                        <Label htmlFor="agenshowall">Show all</Label>
                                      </div>

                                      {
                                        this.state.agencylist && this.state.agencylist.map((dynamicData, key) =>
                                          <div className="formcol" key={key}>
                                            <Input className="form-check-input" name="agencyfiltercheck" type="checkbox"
                                              id={`agen${dynamicData.value}`}
                                              value={dynamicData.value}
                                              onClick={this.agencyFilterClick}
                                            />
                                            <Label htmlFor={`agen${dynamicData.value}`}>{dynamicData.label}</Label>
                                          </div>
                                        )
                                      }

                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col>
                                <div className="filbx noborder">
                                  <label htmlFor="keyword">Added on</label>
                                  {/* {
                                    this.state.addedonfilter ?
                                      <label>( {this.state.addedonfilter} )</label>
                                      :
                                      null
                                  } */}
                                  <div className="mydropdown">
                                    <a href="javascript:" className="dropbtn" onClick={openAddedon}>Any <i className="fas fa-chevron-down"></i></a>
                                    <div className="filtermenu right" id="addedonbx">



                                      <div className="formcol">
                                        <Input className="form-check-input" name="addedon" type="radio" id="addedon1"
                                          value="all"
                                          onClick={this.addedonFilterClick}
                                        />
                                        <Label htmlFor="addedon1">Added anytime</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="addedon" type="radio" id="addedon2"
                                          value="24h"
                                          onClick={this.addedonFilterClick}
                                        />
                                        <Label htmlFor="addedon2">Last 24 hrs</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="addedon" type="radio" id="addedon3"
                                          value="3d"
                                          onClick={this.addedonFilterClick}
                                        />
                                        <Label htmlFor="addedon3">Last 3 days</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="addedon" type="radio" id="addedon4"
                                          value="7d"
                                          onClick={this.addedonFilterClick}
                                        />
                                        <Label htmlFor="addedon4">Last 7 days</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="addedon" type="radio" id="addedon5"
                                          value="15d"
                                          onClick={this.addedonFilterClick}
                                        />
                                        <Label htmlFor="addedon5">Last 15 days</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="addedon" type="radio" id="addedon6"
                                          value="30d"
                                          onClick={this.addedonFilterClick}
                                        />
                                        <Label htmlFor="addedon6">Last 30 days</Label>
                                      </div>
                                      <div className="formcol w100 mb-0 mt-3">
                                        <Input className="form-check-input" name="addedon" type="radio" id="addedon7"
                                          value="range"
                                          onClick={this.addedonFilterClick}
                                        />
                                        <Label htmlFor="addedon7">
                                          <Row className="lowgap">
                                            <Col className="col-md-6">
                                              <InputGroup>
                                                {/* <InputGroupAddon addonType="prepend"> */}
                                                  <InputGroupText className='prepend'><i className="fas fa-calendar-alt"></i></InputGroupText>
                                                {/* </InputGroupAddon> */}

                                                <Datetime
                                                  locale="en-gb"
                                                  timeFormat={false}
                                                  dateFormat="DD/MM/YYYY"
                                                  inputProps={{ placeholder: "Start Date" }}
                                                  onChange={(selectedOption) => {
                                                    this.setState({ addedonfilterstart: selectedOption.toDate() });
                                                  }}
                                                  value={this.state.addedonfilterstart}
                                                  closeOnSelect={true}

                                                />

                                              </InputGroup>
                                            </Col>
                                            <Col className="col-md-6">
                                              <InputGroup>
                                                {/* <InputGroupAddon addonType="prepend"> */}
                                                  <InputGroupText className='prepend'><i className="fas fa-calendar-alt"></i></InputGroupText>
                                                {/* </InputGroupAddon> */}

                                                <Datetime
                                                  locale="en-gb"
                                                  timeFormat={false}
                                                  dateFormat="DD/MM/YYYY"
                                                  inputProps={{ placeholder: "End Date" }}
                                                  onChange={(selectedOption) => {
                                                    this.setState({ addedonfilterend: selectedOption.toDate() });
                                                  }}
                                                  value={this.state.addedonfilterend}
                                                  closeOnSelect={true}
                                                  />

                                              </InputGroup>
                                            </Col>
                                          </Row>
                                        </Label>
                                      </div>



                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col className="col-auto">
                          <div className="filbx noborder">
                            <Button className="btn btn-default greensearch"
                              onClick={
                                async (event) => {
                                  this.genericFilter();
                                }
                              }
                            >Search</Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </div>



























              <div className="assetsfilterrow2 mt-4">
                <Row className="row justify-content-end align-items-center">
                  {/* <Col className="col-auto">
                    <div className="selectbx">
                      <Form>
                        <FormGroup className="form-group">
                          <Input type="select" className="custom-select">
                            <option value="">Most recent</option>
                            <option>Oldest</option>
                            <option>Newest</option>
                            <option>Expired</option>
                          </Input>
                        </FormGroup>
                      </Form>
                    </div>
                  </Col> */}


                  <Col className="col-auto">
                    <div className="favoritesbx">
                      <Form>
                        <FormGroup className="form-group" inline>
                          <div className="form-check form-check-inline">
                            <Input className="form-check-input" type="checkbox" id="inlineCheckbox5" value="true"
                              onClick={
                                async (event) => {
                                  this.setState({ favoritefilter: event.target.checked }, function () {
                                    this.genericFilter()
                                  });
                                }
                              }
                            />
                            <Label htmlFor="inlineCheckbox5">Favorites</Label>
                          </div>
                        </FormGroup>
                      </Form>
                    </div>
                  </Col>


                  <Col className="col-auto">

                    <div className="viewbx">
                      <a href="javascript:" className="viewbtngreen" id="gridview"
                        onClick={
                          async () => {
                            jQuery('#assettab').removeClass("d-none");
                            jQuery('#calendertab').addClass("d-none");
                          }
                        }
                      >Grid View</a>
                      <a href="javascript:" className="viewbtncalender ml-3" id="calenderview"
                        onClick={
                          async () => {
                            jQuery('#calendertab').removeClass("d-none");
                            jQuery('#assettab').addClass("d-none");
                          }
                        }
                      >Calender View</a>
                    </div>

                  </Col>
                </Row>
              </div>

            </Col>
          </Row>
        </div>
        {/* Header Div end */}

        <div className="assetslisting" id="assettab">



          {/* Loader button */}
          {
            this.state.mainDataLoad === true ?
              <div className="loaderwrap liblist"><div className="loader"></div></div>
              :
              null
          }
          {/* Loader button */}





          <Row>

            <Col className="col-md-3">


              <h3>Non-Editorial
                <span>
                  {
                    this.state.fullAssetData ? this.state.fullAssetData.filter(item => item.get('licensetypename') === "non-editorial").length : 0
                  }
                </span>
              </h3>

              <div className='assetslisting-inner-container'> 
                {
                  this.state.fullAssetData && this.state.fullAssetData.filter(item => item.get('licensetypename') === "non-editorial").length > 0 ?

                    this.state.fullAssetData.filter(item => item.get('licensetypename') === "non-editorial").map((dynamicData, key) => {

                      var date1 = new Date();
                      var date2 = new Date(dynamicData.createdAt);
                      var diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
                      diffDays = Math.abs(diffDays + 1);

                      var expiring = "";
                      var edate1 = new Date();
                      var edate2 = new Date(dynamicData.get('expiry'));
                      var ediffDays = parseInt((edate2 - edate1) / (1000 * 60 * 60 * 24), 10);
                      ediffDays = ediffDays + 1;
                      if (ediffDays <= 30 && ediffDays > 0) {
                        expiring = ediffDays;
                      }

                      return (

                        <div className="assetscard">
                          <Row className="justify-content-between mb-2">
                            <Col>
                              <h2>ID# {dynamicData.get('assetid') ? dynamicData.get('assetid') : ""}</h2>
                            </Col>
                            <Col className="col-auto  p-0">
                              <div className="favouritesassets">
                                <Input name="tdType" type="checkbox" id={`nonedit${key}`}
                                  value={dynamicData.id}
                                  onClick={this.setFavorite}
                                  className={dynamicData.get('favorite') === true ? "active" : ""}
                                />
                                <Label htmlFor={`nonedit${key}`}>Favorites</Label>
                              </div>
                            </Col>
                            <Col className="col-auto">

                              {
                                this.state.fullDataLoad === true ?
                                  <Loader type="Oval" color="#fff" height={20} width={20} />
                                  :
                                  <UncontrolledDropdown nav inNavbar className="dotsmenu">
                                    <DropdownToggle nav>
                                      <i className="fas fa-ellipsis-v"></i>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                      <DropdownItem data-objectid={dynamicData.id} onClick={this.viewpopuptoggle}>
                                        View
                                      </DropdownItem>
                                      <DropdownItem data-objectid={dynamicData.id} onClick={this.editpopuptoggle}>
                                        Edit
                                      </DropdownItem>
                                      <DropdownItem data-objectid={dynamicData.id} onClick={this.deletepopuptoggle}>
                                        Delete
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                              }


                            </Col>
                          </Row>

                          <Row>
                            <Col className="col-12 mt-2">
                              <p>{dynamicData.get('description')}</p>
                            </Col>
                          </Row>

                          <div className="imgbx">
                            <a href="javascript:" onClick={this.viewpopuptoggle}>
                              <Assetimage
                                objid={dynamicData.id}
                                imageurl={dynamicData.get('assetimage') ? dynamicData.get('assetimage')[0] : ""}
                                updateIDforImage={this.state.updateIDforImage}
                                popupstatus={this.state.popupstatus}
                              />
                            </a>
                          </div>

                          <Row className="align-items-center mt-2 mb-3">
                            <Col>
                              <div className="librarydetail">
                                {dynamicData.get('medialicenselibraryid') ? dynamicData.get('medialicenselibraryid').get('name') : ""}
                              </div>
                            </Col>
                            <Col className="col-auto">
                              <div className="timeago">Added {diffDays} days ago</div>
                            </Col>
                          </Row>

                          <Row>
                            <Col className="pr-0">
                              {
                                expiring !== "" ?
                                  <span className="expiringsoon">Expiring in {expiring} days</span>
                                  :
                                  null
                              }

                            </Col>
                            <Col className="col-auto">
                              <div className='agencydetail'>
                                {
                                  dynamicData.get('assettypetext') === "Image" ?
                                    <img src={iconCamera} alt="img" />
                                    :
                                    dynamicData.get('assettypetext') === "Video" ?
                                      <img src={iconMedia} alt="img" />
                                      :
                                      dynamicData.get('assettypetext') === "Audio" ?
                                        <img src={iconAudio} alt="img" />
                                        :
                                        dynamicData.get('assettypetext') === "Vector" ?
                                          <img src={iconPencil} alt="img" />
                                          :
                                          null
                                }
                                {/* <img src={logoLemon} alt="img" /> */}
                              </div>
                            </Col>
                          </Row>

                        </div>

                      )
                    })
                    :
                    <p>No data.</p>
                }

              </div>

            </Col>















            <Col className="col-md-3">
              <h3>Editorial
                <span>
                {
                    this.state.fullAssetData ? this.state.fullAssetData.filter(item => item.get('licensetypename') === "editorial").length : 0
                  }
                </span>
              </h3>

              <div className='assetslisting-inner-container'>
                {
                   this.state.fullAssetData && this.state.fullAssetData.filter(item => item.get('licensetypename') === "editorial").length > 0 ?

                   this.state.fullAssetData.filter(item => item.get('licensetypename') === "editorial").map((dynamicData, key) => {

                      var date1 = new Date();
                      var date2 = new Date(dynamicData.createdAt);
                      var diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
                      diffDays = Math.abs(diffDays + 1);

                      var expiring = "";
                      var edate1 = new Date();
                      var edate2 = new Date(dynamicData.get('expiry'));
                      var ediffDays = parseInt((edate2 - edate1) / (1000 * 60 * 60 * 24), 10);
                      ediffDays = ediffDays + 1;
                      if (ediffDays <= 30 && ediffDays > 0) {
                        expiring = ediffDays;
                      }

                      return (

                        <div className="assetscard">
                          <Row className="justify-content-between mb-2">
                            <Col>
                              <h2>ID# {dynamicData.get('assetid') ? dynamicData.get('assetid') : ""}</h2>
                            </Col>
                            <Col className="col-auto  p-0">
                              <div className="favouritesassets">
                                <Input name="tdType" type="checkbox" id={`editorial${dynamicData.id}`}
                                  className={dynamicData.get('favorite') === true ? "active" : ""}
                                  value={dynamicData.id}
                                  onClick={this.setFavorite}
                                />
                                <Label htmlFor={`editorial${dynamicData.id}`}>Favorites</Label>
                              </div>
                            </Col>
                            <Col className="col-auto">
                              {
                                this.state.fullDataLoad === true ?
                                  <Loader type="Oval" color="#fff" height={20} width={20} />
                                  :
                                  <UncontrolledDropdown nav inNavbar className="dotsmenu">
                                    <DropdownToggle nav>
                                      <i className="fas fa-ellipsis-v"></i>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                      <DropdownItem data-objectid={dynamicData.id} onClick={this.viewpopuptoggle}>
                                        View
                                      </DropdownItem>
                                      <DropdownItem data-objectid={dynamicData.id} onClick={this.editpopuptoggle}>
                                        Edit
                                      </DropdownItem>
                                      <DropdownItem data-objectid={dynamicData.id} onClick={this.deletepopuptoggle}>
                                        Delete
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                              }
                            </Col>
                          </Row>

                          <Row>
                            <Col className="col-12 mt-2">
                              <p>{dynamicData.get('description')}</p>
                            </Col>
                          </Row>

                          <div className="imgbx">
                            <a href="javascript:" data-objectid={dynamicData.id} onClick={this.viewpopuptoggle}>
                              <Assetimage
                                objid={dynamicData.id}
                                imageurl={dynamicData.get('assetimage') ? dynamicData.get('assetimage')[0] : ""}
                                updateIDforImage={this.state.updateIDforImage}
                                popupstatus={this.state.popupstatus}
                              />
                            </a>
                          </div>

                          <Row className="align-items-center mt-2 mb-3">
                            <Col>
                              <div className="librarydetail">
                                {dynamicData.get('medialicenselibraryid') ? dynamicData.get('medialicenselibraryid').get('name') : ""}
                              </div>
                            </Col>
                            <Col className="col-auto">
                              <div className="timeago">Added {diffDays} days ago</div>
                            </Col>
                          </Row>

                          <Row>
                            <Col className="pr-0">
                              {
                                expiring !== "" ?
                                  <span className="expiringsoon">Expiring in {expiring} days</span>
                                  :
                                  null
                              }

                            </Col>
                            <Col className="col-auto">
                              <div className='agencydetail'>
                                {
                                  dynamicData.get('assettypetext') === "Image" ?
                                    <img src={iconCamera} alt="img" />
                                    :
                                    dynamicData.get('assettypetext') === "Video" ?
                                      <img src={iconMedia} alt="img" />
                                      :
                                      dynamicData.get('assettypetext') === "Audio" ?
                                        <img src={iconAudio} alt="img" />
                                        :
                                        dynamicData.get('assettypetext') === "Vector" ?
                                          <img src={iconPencil} alt="img" />
                                          :
                                          null
                                }
                              </div>
                            </Col>
                          </Row>

                        </div>

                      )
                    })
                    :
                    <p>No data.</p>
                }
              </div>
            </Col>


            <Col className="col-md-3">
              <h3>Royalty Free
                <span>
                {
                    this.state.fullAssetData ? this.state.fullAssetData.filter(item => item.get('licensetypename') === "royalty-free").length : 0
                  }
                </span>
              </h3>

              <div className='assetslisting-inner-container'>

                {
                  this.state.fullAssetData && this.state.fullAssetData.filter(item => item.get('licensetypename') === "royalty-free").length > 0 ?

                  this.state.fullAssetData.filter(item => item.get('licensetypename') === "royalty-free").map((dynamicData, key) => {

                      var date1 = new Date();
                      var date2 = new Date(dynamicData.createdAt);
                      var diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
                      diffDays = Math.abs(diffDays + 1);

                      var expiring = "";
                      var edate1 = new Date();
                      var edate2 = new Date(dynamicData.get('expiry'));
                      var ediffDays = parseInt((edate2 - edate1) / (1000 * 60 * 60 * 24), 10);
                      ediffDays = ediffDays + 1;
                      if (ediffDays <= 30 && ediffDays > 0) {
                        expiring = ediffDays;
                      }

                      return (

                        <div className="assetscard">
                          <Row className="justify-content-between mb-2">
                            <Col>
                              <h2>ID# {dynamicData.get('assetid') ? dynamicData.get('assetid') : ""}</h2>
                            </Col>
                            <Col className="col-auto  p-0">
                              <div className="favouritesassets">
                                <Input name="tdType" type="checkbox" id={`royalty${key}`}
                                  value={dynamicData.id}
                                  onClick={this.setFavorite}
                                  className={dynamicData.get('favorite') === true ? "active" : ""}
                                />
                                <Label htmlFor={`royalty${key}`}>Favorites</Label>
                              </div>
                            </Col>
                            <Col className="col-auto">
                              {
                                this.state.fullDataLoad === true ?
                                  <Loader type="Oval" color="#fff" height={20} width={20} />
                                  :
                                  <UncontrolledDropdown nav inNavbar className="dotsmenu">
                                    <DropdownToggle nav>
                                      <i className="fas fa-ellipsis-v"></i>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                      <DropdownItem data-objectid={dynamicData.id} onClick={this.viewpopuptoggle}>
                                        View
                                      </DropdownItem>
                                      <DropdownItem data-objectid={dynamicData.id} onClick={this.editpopuptoggle}>
                                        Edit
                                      </DropdownItem>
                                      <DropdownItem data-objectid={dynamicData.id} onClick={this.deletepopuptoggle}>
                                        Delete
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                              }
                            </Col>
                          </Row>

                          <Row>
                            <Col className="col-12 mt-2">
                              <p>{dynamicData.get('description')}</p>
                            </Col>
                          </Row>

                          <div className="imgbx">
                            <a href="javascript:" data-objectid={dynamicData.id} onClick={this.viewpopuptoggle}>
                              <Assetimage
                                objid={dynamicData.id}
                                imageurl={dynamicData.get('assetimage') ? dynamicData.get('assetimage')[0] : ""}
                                updateIDforImage={this.state.updateIDforImage}
                                popupstatus={this.state.popupstatus}
                              />
                            </a>
                          </div>

                          <Row className="align-items-center mt-2 mb-3">
                            <Col>
                              <div className="librarydetail">
                                {dynamicData.get('medialicenselibraryid') ? dynamicData.get('medialicenselibraryid').get('name') : ""}
                              </div>
                            </Col>
                            <Col className="col-auto">
                              <div className="timeago">Added {diffDays} days ago</div>
                            </Col>
                          </Row>

                          <Row>
                            <Col className="pr-0">
                              {
                                expiring !== "" ?
                                  <span className="expiringsoon">Expiring in {expiring} days</span>
                                  :
                                  null
                              }

                            </Col>
                            <Col className="col-auto">
                              <div className='agencydetail'>
                                {
                                  dynamicData.get('assettypetext') === "Image" ?
                                    <img src={iconCamera} alt="img" />
                                    :
                                    dynamicData.get('assettypetext') === "Video" ?
                                      <img src={iconMedia} alt="img" />
                                      :
                                      dynamicData.get('assettypetext') === "Audio" ?
                                        <img src={iconAudio} alt="img" />
                                        :
                                        dynamicData.get('assettypetext') === "Vector" ?
                                          <img src={iconPencil} alt="img" />
                                          :
                                          null
                                }
                              </div>
                            </Col>
                          </Row>

                        </div>

                      )
                    })
                    :
                    <p>No data.</p>
                }
              </div>
            </Col>


            <Col className="col-md-3">
              <h3>Standard
                <span>
                {
                    this.state.fullAssetData ? this.state.fullAssetData.filter(item => item.get('licensetypename') === "standard").length : 0
                  }
                </span>
              </h3>

              <div className='assetslisting-inner-container'>

                {
                  this.state.fullAssetData && this.state.fullAssetData.filter(item => item.get('licensetypename') === "standard").length > 0 ?

                  this.state.fullAssetData.filter(item => item.get('licensetypename') === "standard").map((dynamicData, key) => {

                      var date1 = new Date();
                      var date2 = new Date(dynamicData.createdAt);
                      var diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
                      diffDays = Math.abs(diffDays + 1);

                      var expiring = "";
                      var edate1 = new Date();
                      var edate2 = new Date(dynamicData.get('expiry'));
                      var ediffDays = parseInt((edate2 - edate1) / (1000 * 60 * 60 * 24), 10);
                      ediffDays = ediffDays + 1;
                      if (ediffDays <= 30 && ediffDays > 0) {
                        expiring = ediffDays;
                      }

                      return (

                        <div className="assetscard">
                          <Row className="justify-content-between mb-2">
                            <Col>
                              <h2>ID# {dynamicData.get('assetid') ? dynamicData.get('assetid') : ""}</h2>
                            </Col>
                            <Col className="col-auto  p-0">
                              <div className="favouritesassets">
                                <Input name="tdType" type="checkbox" id={`stand${key}`}
                                  value={dynamicData.id}
                                  onClick={this.setFavorite}
                                  className={dynamicData.get('favorite') === true ? "active" : ""}
                                />
                                <Label htmlFor={`stand${key}`}>Favorites</Label>
                              </div>
                            </Col>
                            <Col className="col-auto">
                              {
                                this.state.fullDataLoad === true ?
                                  <Loader type="Oval" color="#fff" height={20} width={20} />
                                  :
                                  <UncontrolledDropdown nav inNavbar className="dotsmenu">
                                    <DropdownToggle nav>
                                      <i className="fas fa-ellipsis-v"></i>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                      <DropdownItem data-objectid={dynamicData.id} onClick={this.viewpopuptoggle}>
                                        View
                                      </DropdownItem>
                                      <DropdownItem data-objectid={dynamicData.id} onClick={this.editpopuptoggle}>
                                        Edit
                                      </DropdownItem>
                                      <DropdownItem data-objectid={dynamicData.id} onClick={this.deletepopuptoggle}>
                                        Delete
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                              }
                            </Col>
                          </Row>

                          <Row>
                            <Col className="col-12 mt-2">
                              <p>{dynamicData.get('description')}</p>
                            </Col>
                          </Row>

                          <div className="imgbx">
                            <a href="javascript:" data-objectid={dynamicData.id} onClick={this.viewpopuptoggle}>
                              <Assetimage
                                objid={dynamicData.id}
                                imageurl={dynamicData.get('assetimage') ? dynamicData.get('assetimage')[0] : ""}
                                updateIDforImage={this.state.updateIDforImage}
                                popupstatus={this.state.popupstatus}
                              />
                            </a>
                          </div>

                          <Row className="align-items-center mt-2 mb-3">
                            <Col>
                              <div className="librarydetail">
                                {dynamicData.get('medialicenselibraryid') ? dynamicData.get('medialicenselibraryid').get('name') : ""}
                              </div>
                            </Col>
                            <Col className="col-auto">
                              <div className="timeago">Added {diffDays} days ago</div>
                            </Col>
                          </Row>

                          <Row>
                            <Col className="pr-0">
                              {
                                expiring !== "" ?
                                  <span className="expiringsoon">Expiring in {expiring} days</span>
                                  :
                                  null
                              }

                            </Col>
                            <Col className="col-auto">
                              <div className='agencydetail'>
                                {
                                  dynamicData.get('assettypetext') === "Image" ?
                                    <img src={iconCamera} alt="img" />
                                    :
                                    dynamicData.get('assettypetext') === "Video" ?
                                      <img src={iconMedia} alt="img" />
                                      :
                                      dynamicData.get('assettypetext') === "Audio" ?
                                        <img src={iconAudio} alt="img" />
                                        :
                                        dynamicData.get('assettypetext') === "Vector" ?
                                          <img src={iconPencil} alt="img" />
                                          :
                                          null
                                }
                              </div>
                            </Col>
                          </Row>

                        </div>

                      )
                    })
                    :
                    <p>No data.</p>
                }
              </div>
            </Col>



          </Row>
        </div>


        <div id="calendertab" className="d-none">
          <Calendarview
            viewListFull={this.state.fullAssetData ? this.state.fullAssetData : null}
            viewStatus={(viewid) => {
              this.setState({
                viewpopup: !this.state.viewpopup
              });
              this.setObjectID(viewid);
            }}
          />
        </div>



        <Addasset
          isOpen={this.state.addnewpopup}
          toggle={this.addnewpopuptoggle}
          licenseItems={this.state.licenseItems}
          medialicenselibrary={this.state.medialicenselibrary}
          assettypes={this.state.assettypes}
          georestrictions={this.state.georestrictions}
          agencylist={this.state.agencylist}
          brandlist={this.state.brandlist}
          addStatus={(addstatus) => {
            if (addstatus.id) {
              this.setState({
                addnewpopup: !this.state.addnewpopup
              });
              this.setState({
                editpopup: !this.state.editpopup
              });
              this.setObjectID(addstatus.id);

              this.setState({ updateIDforImage: addstatus.id });

              this.getDataUpdate(addstatus.get('licensetypename'));

            }
          }}
        />


        <Editasset
          isOpen={this.state.editpopup}
          toggle={this.editpopuptoggle}
          licenseItems={this.state.licenseItems}
          medialicenselibrary={this.state.medialicenselibrary}
          assettypes={this.state.assettypes}
          georestrictions={this.state.georestrictions}
          agencylist={this.state.agencylist}
          brandlist={this.state.brandlist}
          editObjectID={this.state.editObjectID}
          editRecordStatus={(editstatus) => {
            if (editstatus.id) {
              this.setState({ updateIDforImage: editstatus.id });

              this.getDataUpdate(editstatus.get('licensetypename'));
            }
          }}
        />


        <Viewassets
          isOpen={this.state.viewpopup}
          toggle={this.viewpopuptoggle}
          viewObjectID={this.state.editObjectID}
          viewListFull={this.state.fullAssetData ? this.state.fullAssetData : null}
          deleteClicked={this.state.deleteClicked ? this.state.deleteClicked : false}
          editStatus={(editid) => {
            this.setState({
              editpopup: !this.state.editpopup
            });
            this.setState({
              viewpopup: false
            });
            this.setObjectID(editid);
          }}
          deletedStatus={(deleteobj) => {
            this.setState({
              deletedID: deleteobj.id,
              deletedName: deleteobj.get('title'),
            });
            this.setState({
              deleteClicked: false
            });
            this.setState({
              viewpopup: !this.state.viewpopup
            });

            this.getDataUpdate(deleteobj.get('licensetypename'));
          }}
        />


        {
          this.state.deletedID ?
            <Alert className="deleteundo" color="warning" isOpen={this.state.visible} toggle={this.onDismiss}>
              The Library '{this.state.deletedName}' has been deleted <a href="javacript:" onClick={this.undoRecord} className="alert-link"><i className="fas fa-undo"></i> Undo</a>
            </Alert>
            :
            null
        }



      </div>
    )
  }
}

export default mediaAssetsFull;
