import React from 'react';
// import Loader from 'react-loader-spinner';
import { TailSpin } from 'react-loader-spinner';
 export default class App extends React.Component {
  //other logic
    render() {
     return(
      <TailSpin  
         type={this.props.type}
         color={this.props.color}
         height={this.props.height}	
         width={this.props.width}
      />   
     );
    }
 }