import Fulllayout from '../layouts/fulllayout.jsx';
import Blanklayout from '../layouts/blanklayout.jsx';
import { Children } from 'react';
import authRoutes from './authroutes.jsx';
import ThemeRoutes from './routing.jsx';

var indexRoutes = [
    { path: "/authentication/*", name: "Athentication", component: <Blanklayout/>, child: authRoutes },
    { path: '/', name: 'Dashboard', component: <Fulllayout/>, child: ThemeRoutes }

];

export default indexRoutes;
