import jQuery from 'jquery';
import axios from 'axios';
var Parse = require('parse');

var ip = "";
axios.get('https://geolocation-db.com/json/').then((ipadd) => {
    ip = ipadd.data.IPv4;
});

export const getLicenseType = async () => {
    var LicenseType = Parse.Object.extend("LicenseType");
    var query = new Parse.Query(LicenseType);
    query.equalTo("status", true);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const getAssetType = async () => {
    var AssetType = Parse.Object.extend("AssetType");
    var query = new Parse.Query(AssetType);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}


export const getSearchResults = async (searchdatas) => {
    var MediaLicenseLibrary = Parse.Object.extend("MediaLicenseLibrary");
    var query = new Parse.Query(MediaLicenseLibrary);
    query.descending("createdAt");
    query.equalTo("status", true);

    if (searchdatas['searchbox']) {
        query.matches("name", searchdatas['searchbox'], 'i');
    }

    if (searchdatas['statsrc'] == "true") {
        query.equalTo("status", true);
    } else if (searchdatas['statsrc'] == "false") {
        query.equalTo("status", false);
    }
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });

}

export const getFullPageCount = async (searchval, filterradio) => {
    var MediaLicenseLibrary = Parse.Object.extend("MediaLicenseLibrary");
    var query = new Parse.Query(MediaLicenseLibrary);
    query.equalTo("status", true);
    query.descending("createdAt");

    if (searchval) {
        query.matches("name", searchval, 'i');
    }
    if (filterradio === "recentlyadded") {
        var dateminus = new Date();
        dateminus.setDate(dateminus.getDate() - 10);
        query.greaterThanOrEqualTo('createdAt', dateminus);
    }
    if (filterradio === "archive") {
        query.equalTo("recordstatus", "archive");
    } else {
        query.equalTo("recordstatus", "active");
    }

    const count = await query.count();

    return count;
}

export const getMediaLicenseLibraryFull = async () => {
    //var skipnum = pagenum - 1;
    //var perpage = 1;

    var MediaLicenseLibrary = Parse.Object.extend("MediaLicenseLibrary");
    var query = new Parse.Query(MediaLicenseLibrary);
    // query.equalTo("recordstatus", "active");

    query.descending('createdAt');

    //query.skip(skipnum * perpage);
    //query.limit(perpage);

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}


export const getMediaLicenseLibrary = async (postperpage, pagenum, searchval, filterradio) => {
    var skipnum = pagenum - 1;
    var perpage = postperpage;

    var MediaLicenseLibrary = Parse.Object.extend("MediaLicenseLibrary");
    var query = new Parse.Query(MediaLicenseLibrary);
    query.equalTo("recordstatus", "active");

    query.descending('createdAt');

    if (searchval) {
        // query.matches("name", searchval, 'i');
        let query1 = query.matches("name", searchval, 'i');

        let query2 = new Parse.Query(MediaLicenseLibrary);
        query2.matches("description", searchval, 'i');

        query = Parse.Query.or(query1, query2);
    }



    if (filterradio === "recentlyadded") {
        var dateminus = new Date();
        dateminus.setDate(dateminus.getDate() - 10);
        query.greaterThanOrEqualTo('createdAt', dateminus);
    }
    if (filterradio === "archive") {
        query.equalTo("recordstatus", "archive");
    } else {
        query.equalTo("recordstatus", "active");
    }


    query.skip(skipnum * perpage);
    query.limit(perpage);



    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const hideRecords = async (objectids) => {

    var parseobject = Parse.Object.extend("MediaLicenseLibrary");
    var query = new Parse.Query(parseobject);
    query.equalTo("objectId", objectids);
    return query.find().then((object) => {
        var realbject = object[0];
        realbject.set("status", false);
        return realbject.save().then((records) => {
            return true;
        }, (error) => {
            console.log(error);
        }).catch(function (error) {
        });

    }, (error) => {
    });


}


export const addRecord = async (fieldvalues) => {

    var obj = new Parse.Object("MediaLicenseLibrary");
    obj.set("name", fieldvalues.name);
    obj.set("libraryimage", fieldvalues.libraryimage);
    obj.set("terms", fieldvalues.terms);
    obj.set("website", fieldvalues.website);
    obj.set("description", fieldvalues.description);
    obj.set("status", true);


    if (fieldvalues.status === true) {
        obj.set("recordstatus", "active");
    } else {
        obj.set("recordstatus", "inactive");
    }



    var licensearray = [];
    for (var i = 0; i < fieldvalues.allowedlicense.length; ++i) {
        var LicenseType = Parse.Object.extend("LicenseType");
        var licQuery = new Parse.Query(LicenseType);
        let validjuris = await licQuery.get(fieldvalues.allowedlicense[i]);
        licensearray.push(validjuris);
    }
    obj.set("allowedlicense", licensearray);


    return obj.save().then(async (obj) => {
        await addMediaLogs(obj.id, "add");
        return obj;
    }, (error) => {
        return false;
    }).catch(function (error) {
        return false;
    });
}



export const fetchRecordById = async ({ objectid }) => {
    var MediaLicenseLibrary = Parse.Object.extend("MediaLicenseLibrary");
    var query = new Parse.Query(MediaLicenseLibrary);
    query.equalTo("objectId", objectid);
    return query.find().then((records) => {
        return records;
    }, (error) => {
        return false;
    }).catch(function (error) {
        return false;
    });
}


export const updateRecord = async (fieldvalues) => {
    var MediaLicenseLibrary = Parse.Object.extend("MediaLicenseLibrary");
    var query = new Parse.Query(MediaLicenseLibrary);
    query.equalTo("objectId", fieldvalues.objectID);

    return query.find().then(async (object) => {

        var realbject = object[0];

        realbject.set("name", fieldvalues.name);
        realbject.set("terms", fieldvalues.terms);
        realbject.set("website", fieldvalues.website);
        realbject.set("description", fieldvalues.description);
        realbject.set("status", true);


        realbject.set("recordstatus", fieldvalues.recordstatus);
        realbject.set("libraryimage", fieldvalues.libraryimage);

        // if (fieldvalues.libraryimage.length > 0) {
        //     realbject.set("libraryimage", fieldvalues.libraryimage);
        // }



        var licensearray = [];
        for (var i = 0; i < fieldvalues.allowedlicense.length; ++i) {
            var LicenseType = Parse.Object.extend("LicenseType");
            var licQuery = new Parse.Query(LicenseType);
            let validjuris = await licQuery.get(fieldvalues.allowedlicense[i]);
            licensearray.push(validjuris);
        }
        realbject.set("allowedlicense", licensearray);

        return realbject.save().then(async (records) => {
            await addMediaLogs(records.id, "edit");
            return records;
        }, (error) => {
            return false;
        }).catch(function (error) {
            return false;
        });

    }, (error) => {
        return false;
    });

}


export const deleteRecords = async (objectids) => {

    var parseobject = Parse.Object.extend("MediaLicenseLibrary");
    var query = new Parse.Query(parseobject);
    query.equalTo("objectId", objectids);
    var object = await query.find();

    var realbject = object[0];

    realbject?.set("recordstatus", "archive");
    var res = await realbject?.save()

    await addMediaLogs(res.id, "archive");
    return res;

}

export const undoRecord = async (objectids) => {

    var parseobject = Parse.Object.extend("MediaLicenseLibrary");
    var query = new Parse.Query(parseobject);
    query.equalTo("objectId", objectids);
    var object = await query.find();

    var realbject = object[0];

    realbject.set("recordstatus", "active");
    var res = await realbject.save()

    await addMediaLogs(res.id, "undo");

    return res;

}



export const duplicateRecord = async (objectids) => {

    var parseobject = Parse.Object.extend("MediaLicenseLibrary");
    var query = new Parse.Query(parseobject);
    query.equalTo("objectId", objectids);
    var object = await query.find();
    var realbject = object[0];

    var obj = new Parse.Object("MediaLicenseLibrary");
    obj.set("name", realbject.get('name') + " (copy)");
    obj.set("libraryimage", realbject.get('libraryimage'));
    obj.set("allowedlicense", realbject.get('allowedlicense'));
    obj.set("terms", realbject.get('terms'));
    obj.set("website", realbject.get('website'));
    obj.set("description", realbject.get('description'));
    obj.set("status", realbject.get('status'));
    obj.set("recordstatus", realbject.get('recordstatus'));

    var res = await obj.save();

    if (res.id) {
        await addMediaLogs(res.id, "add");
    }

    return res;

}





export const assetData = async () => {

    var parseobject = Parse.Object.extend("MediaLicenseAsset");
    var query = new Parse.Query(parseobject);
    query.equalTo("recordstatus", "active");
    query.descending('createdAt');
    query.include("licensetype");
    query.limit(1000)
    var response = await query.find();

    var datacountArray = [];


    var imagecountArray = [];
    var videocountArray = [];
    var audiocountArray = [];
    var vectorcountArray = [];

    if (response.length > 0) {
        for (let i = 0; i < response.length; i++) {

            //COUNT

            var date1 = new Date();
            var date2 = new Date(response[i].get('expiry'));
            var diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
            diffDays = diffDays + 1;

            if (diffDays <= 30 && diffDays > 0) {
                let hasKey = datacountArray.hasOwnProperty(response[i].get('medialicenselibraryid').id);

                if (hasKey) {
                    datacountArray[response[i].get('medialicenselibraryid').id] += 1;
                } else {
                    datacountArray[response[i].get('medialicenselibraryid').id] = 1;
                }
            }
            //COUNT



            //MEDIA COUNT
            if (response[i].get('assettypetext') === "Image") {
                let hasKey = imagecountArray.hasOwnProperty(response[i].get('medialicenselibraryid').id);
                if (hasKey) {
                    imagecountArray[response[i].get('medialicenselibraryid').id] += 1;
                } else {
                    imagecountArray[response[i].get('medialicenselibraryid').id] = 1;
                }
            } else if (response[i].get('assettypetext') === "Video") {
                let hasKey = videocountArray.hasOwnProperty(response[i].get('medialicenselibraryid').id);
                if (hasKey) {
                    videocountArray[response[i].get('medialicenselibraryid').id] += 1;
                } else {
                    videocountArray[response[i].get('medialicenselibraryid').id] = 1;
                }
            } else if (response[i].get('assettypetext') === "Audio") {
                let hasKey = audiocountArray.hasOwnProperty(response[i].get('medialicenselibraryid').id);
                if (hasKey) {
                    audiocountArray[response[i].get('medialicenselibraryid').id] += 1;
                } else {
                    audiocountArray[response[i].get('medialicenselibraryid').id] = 1;
                }
            } else if (response[i].get('assettypetext') === "Vector") {
                let hasKey = vectorcountArray.hasOwnProperty(response[i].get('medialicenselibraryid').id);
                if (hasKey) {
                    vectorcountArray[response[i].get('medialicenselibraryid').id] += 1;
                } else {
                    vectorcountArray[response[i].get('medialicenselibraryid').id] = 1;
                }
            }
            //MEDIA COUNT




        }
    }

    const returnvalue = [];
    returnvalue['datacountArray'] = datacountArray;
    returnvalue['imagecountArray'] = imagecountArray;
    returnvalue['videocountArray'] = videocountArray;
    returnvalue['audiocountArray'] = audiocountArray;
    returnvalue['vectorcountArray'] = vectorcountArray;
    returnvalue['dataArray'] = response;

    return returnvalue;

}


export const assetExpiryCount = async (objectids) => {

    var parseobject = Parse.Object.extend("MediaLicenseAsset");
    var query = new Parse.Query(parseobject);
    query.equalTo("objectId", objectids);

    var dateminus = new Date();
    dateminus.setDate(dateminus.getDate() + 2);
    query.lessThanOrEqualTo('expiry', dateminus);

    var res = await query.count();
    return res;

}



export const addMediaLogs = async (library, action) => {
    try {
        if(ip===""){
            ip = await axios.get('https://geolocation-db.com/json/');
            ip = ip.data.IPv4;
        }

        var curruser = Parse.User.current();

        Parse.Cloud.run('AddMediaLogs', {
            user: curruser.id,
            ipaddress: ip ? ip : "",
            library: library,
            action: action,
        }).then(function (result) {
            console.log(result);
        });
    } catch (ex) {
        console.log('log', ex);
    }
}




export const checkFieldValid = (addoredit, statevalues) => {

    var name = jQuery('#name');
    var description = jQuery('#description');

    var errstat = true;
    if (name.val() === "") {
        name.addClass("is-invalid");
        errstat = false;
    } else {
        name.removeClass("is-invalid");
    }

    if (description.val() === "") {
        description.addClass("is-invalid");
        errstat = false;
    } else {
        description.removeClass("is-invalid");
    }


    if (addoredit === "edit") {
        if (statevalues.tempImageView === null) {
            jQuery('.libraryimgwrap').addClass("is-invalid");
            errstat = false;
        } else {
            jQuery('.libraryimgwrap').removeClass("is-invalid");
        }
    } else {
        if (statevalues.mainImagefile.length === 0) {
            jQuery('.libraryimgwrap').addClass("is-invalid");
            errstat = false;
        } else {
            jQuery('.libraryimgwrap').removeClass("is-invalid");
        }
    }





    // if (statevalues.allowedlicense.length === 0) {
    //     jQuery('.allowassets').addClass("is-invalid");
    //     errstat = false;
    // } else {
    //     jQuery('.allowassets').removeClass("is-invalid");
    // }




    if (addoredit === "add") {

    }


    if (errstat === false) {
        return false;
    } else {
        return true;
    }

}

