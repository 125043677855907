import jQuery from 'jquery';
var Parse = require('parse');

export const getFullPageCount = async () => {
    var Finance = Parse.Object.extend("Finance");
    var query = new Parse.Query(Finance);
    query.equalTo("status", true);
    query.descending("createdAt");
    const count = await query.count();

    return count;
}

export const getSearchResults = async (searchdatas) => {
    var Finance = Parse.Object.extend("Finance");
    var query = new Parse.Query(Finance);
    query.descending("createdAt");
    query.equalTo("status", true);

    if (searchdatas['account'] !== "") {
        query.matches("account", searchdatas['account'], 'i');
    }
    if (searchdatas['invoicenumber'] !== "") {
        query.matches("invoice_number", searchdatas['invoicenumber'], 'i');
    }
    if (searchdatas['registrantemail'] !== "") {
        query.matches("registrant_email", searchdatas['registrantemail'], 'i');
    }
    if (searchdatas['transaction_type'] !== "") {
        query.matches("transaction_type", searchdatas['transaction_type'], 'i');
    }



    if (searchdatas['invoicemin'] !== "") {
        var invoicemin = searchdatas['invoicemin'];
        query.greaterThanOrEqualTo("invoice_amount", parseFloat(invoicemin)); 
    }
    if (searchdatas['invoicemax'] !== "") {
        var invoicemax = searchdatas['invoicemax'];
        query.lessThanOrEqualTo("invoice_amount", parseFloat(invoicemax)); 
    }


    if (searchdatas['cc_start'] !== "") {

        var st = searchdatas['cc_start'];
        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var cc_start = new Date(st.replace(pattern, '$3-$2-$1'));

        // var cc_start = new Date(searchdatas['cc_start']);
        query.greaterThanOrEqualTo("cc_statement_date", cc_start);
    }
    if (searchdatas['cc_end'] !== "") {

        var st = searchdatas['cc_end'];
        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var cc_end = new Date(st.replace(pattern, '$3-$2-$1'));

        // var cc_end = new Date(searchdatas['cc_end']);
        query.lessThanOrEqualTo("cc_statement_date", cc_end);
    }


    if (searchdatas['invoice_start'] !== "") {

        var st = searchdatas['invoice_start'];
        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var invoice_start = new Date(st.replace(pattern, '$3-$2-$1'));

        // var invoice_start = new Date(searchdatas['invoice_start']);
        query.greaterThanOrEqualTo("date_of_invoice", invoice_start);
    }
    if (searchdatas['invoice_end'] !== "") {

        var st = searchdatas['invoice_end'];
        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var invoice_end = new Date(st.replace(pattern, '$3-$2-$1'));

        // var invoice_end = new Date(searchdatas['invoice_end']);
        query.lessThanOrEqualTo("date_of_invoice", invoice_end);
    }



    if (searchdatas['domainname'] !== "") {
        query.equalTo('domain', {
            __type: 'Pointer',
            className: 'Domains',
            objectId: searchdatas['domainname']
        });
    }

    if (searchdatas['statsrc'] == "true") {
        query.equalTo("status", true);
    } else if (searchdatas['statsrc'] == "false") {
        query.equalTo("status", false);
    }

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });

}

export const getDomainById = async (domainId) => {
    var Domains = Parse.Object.extend("Domains");
    var query = new Parse.Query(Domains);
    query.equalTo("objectId", domainId);
    query.include("domain");
    query.descending("createdAt");
    var records = await query.find();
    return records;
}
export const getFinance = async (pagenum, dataperpage, sortitem, sortitemorder) => {
    var skipnum = pagenum - 1;
    var perpage = parseInt(dataperpage);

    var Finance = Parse.Object.extend("Finance");
    var query = new Parse.Query(Finance);
    query.equalTo("status", true);

    query.include("domain");
    query.include("domain.domain");
    query.include("domain.registrar");

    if (sortitem !== "") {
        if (sortitemorder === "ascending") {
            query.ascending(sortitem);
        } else {
            query.descending(sortitem);
        }

    } else {
        query.descending('createdAt');
    }

    query.skip(skipnum * perpage);
    query.limit(perpage);

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}
export const getFinanceFull = async () => {
    var Finance = Parse.Object.extend("Finance");
    var query = new Parse.Query(Finance);
    query.equalTo("status", true);
    query.descending("createdAt");

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}


export const getBusinessSectors = async () => {
    var BusinessSectors = Parse.Object.extend("BusinessSectors");
    var query = new Parse.Query(BusinessSectors);
    query.equalTo("status", true);
    query.limit(1000);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}
export const getMasterbrands = async () => {
    var Masterbrands = Parse.Object.extend("Masterbrands");
    var query = new Parse.Query(Masterbrands);
    query.equalTo("status", true);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}
export const getDomainStatus = async () => {
    var DomainStatuses = Parse.Object.extend("DomainStatuses");
    var query = new Parse.Query(DomainStatuses);
    query.equalTo("status", true);
    query.limit(1000);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}
export const getCountries = async () => {
    var Countries = Parse.Object.extend("Countries");
    var query = new Parse.Query(Countries);
    query.equalTo("status", true);
    query.limit(1000);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}
export const getALJCompanies = async () => {
    var ALJCompanies = Parse.Object.extend("ALJCompanies");
    var query = new Parse.Query(ALJCompanies);
    query.equalTo("status", true);
    query.limit(1000);
    query.ascending("name");

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}
export const getDomains = async () => {
    var Domains = Parse.Object.extend("Domains");
    var query = new Parse.Query(Domains);
    query.equalTo("status", true);
    query.ascending("name");
    query.limit(1000);
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}
export const getWebcategory = async () => {
    var Webcategory = Parse.Object.extend("Webcategory");
    var query = new Parse.Query(Webcategory);
    query.equalTo("status", true);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}
export const getRegistrar = async () => {
    var Registrar = Parse.Object.extend("Registrar");
    var query = new Parse.Query(Registrar);
    query.equalTo("status", true);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const getImagesList = async (objectid, version = null) => {

    var Finance_images = Parse.Object.extend("Finance_images");
    var query = new Parse.Query(Finance_images);
    query.equalTo("financeID", objectid);

    if (version !== null) {
        query.lessThan("version_count", parseInt(version) + 1);
    }
    query.equalTo("status", true);

    var records = await query.find();
    return records;

    // return await query.find().then((records) => {
    //     return records;
    // }, (error) => {
    //     console.log(error);
    // });
}
export const deleteImagesList = (objectid) => {
    var obj = new Parse.Object("Finance_images");
    obj.id = objectid;
    obj.destroy({});

    if (obj) {
        return true;
    }
}


export const hideRecords = async (objectids) => {
    var parseobject = Parse.Object.extend("Finance");
    var query = new Parse.Query(parseobject);
    query.equalTo("objectId", objectids);

    return query.find().then(async (object) => {
        var realbject = object[0];
        realbject.set("status", false);
        return realbject.save().then(async (records) => {

            //Log
            var currentUser = Parse.User.current();
            const Logs = Parse.Object.extend("Logs");
            const log = new Logs();
            log.set("finance", { "__type": "Pointer", "className": "Finance", "objectId": objectids });
            log.set("action", 'added');
            log.set("user", currentUser);
            let res = await log.save();
            //Log

            return true;
        }, (error) => {
            console.log(error);
        }).catch(function (error) {
        });

    }, (error) => {
    });
}


export const addRecord = async ({ domainid, currency, yearly_renew_cost, cost_general, cost_directprivacy, icann_fee, other_cost,remarks, direct_privacy, totalcost, invoice_number,
    account, invoice_amount, registrant_email, cc_statement_date, date_of_invoice, transaction_type, finance_comments, status, imagefiles }) => {

    var finance = new Parse.Object("Finance");

    if (domainid) {
        var extentObject = Parse.Object.extend("Domains");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(domainid);
        finance.set("domain", parsepoint);
    }

    finance.set("currency", currency);

    finance.set("yearly_renew_cost", yearly_renew_cost);

    finance.set("cost_general", cost_general);
    finance.set("cost_directprivacy", cost_directprivacy);

    finance.set("icann_fee", icann_fee);
    finance.set("other_cost", other_cost);
    finance.set("remarks", remarks);
    finance.set("direct_privacy", direct_privacy);
    finance.set("totalcost", totalcost);
    finance.set("invoice_number", invoice_number);
    finance.set("account", account);
    finance.set("invoice_amount", parseFloat(invoice_amount));
    finance.set("registrant_email", registrant_email);

    if (cc_statement_date) {
        finance.set("cc_statement_date", cc_statement_date);
    }
    if (date_of_invoice) {
        finance.set("date_of_invoice", date_of_invoice);
    }
    finance.set("transaction_type", transaction_type);
    finance.set("finance_comments", finance_comments);
    finance.set("logoFiles", imagefiles);

    if (status === 'false') {
        finance.set("status", false);
    } else {
        finance.set("status", true);
    }

    finance.set("version_count", 1);

    return finance.save().then(async (records) => {
        var edithiddenid = records.id;

        var version_count = 1;
        addRecordVersion({
            edithiddenid, domainid, currency, yearly_renew_cost, cost_general, cost_directprivacy, icann_fee, other_cost,remarks, direct_privacy, totalcost, invoice_number,
            account, invoice_amount, registrant_email, cc_statement_date, date_of_invoice, transaction_type, finance_comments, status, imagefiles, version_count
        });

        //Log
        var currentUser = Parse.User.current();
        const Logs = Parse.Object.extend("Logs");
        const log = new Logs();
        log.set("finance", { "__type": "Pointer", "className": "Finance", "objectId": edithiddenid });
        log.set("action", 'added');
        log.set("user", currentUser);
        let res = await log.save();
        //Log



        if(records.get('domain')){
            
            if(records.get('domain').get('domain')){
                var domainnameext = records.get('domain').get('name') + records.get('domain').get('domain').get('name');
            }else{
                var domainnameext = records.get('domain').get('name');
            }
            
            // var domainnameext = records.get('domain').get('name') + records.get('domain').get('domain').get('name');

            Parse.Cloud.run('sendNewFinanceRecordEmail', {
                domain: domainnameext,  // The domain name and extension
                id: edithiddenid, // The id of the Finance record
                referenceNumber: invoice_number // Invoice number field in the Finance record
            }).then(function (result) {
                /* SUCCESS */
                console.log(result);

            });
    }





        return records;
    }, (error) => {
        console.log(error);
        return false;
    }).catch(function (error) {
        console.log(error);
        return false;
    });
}


export const fetchRecordById = async ({ objectid }) => {

    var Finance = Parse.Object.extend("Finance");
    var query = new Parse.Query(Finance);
    query.equalTo("objectId", objectid);
    query.include("domain");
    query.include("domain.domain");
    return query.find().then((records) => {
        return records;
    }, (error) => {
        return false;
    }).catch(function (error) {
        return false;
    });
}

export const fetchRecordByIdVersion = async ({ objectid, versionselectcount }) => {
    var Finance = Parse.Object.extend("Finance_versions");
    var query = new Parse.Query(Finance);
    query.equalTo("financeId", objectid);
    query.equalTo("version_count", parseInt(versionselectcount));
    // query.include("BusinessSectors");
    return query.find().then((records) => {
        return records;
    }, (error) => {
        console.log(error);
        return false;
    }).catch(function (error) {
        console.log(error);
        return false;
    });
}





export const updateRecord = async ({ edithiddenid, domainid, currency, yearly_renew_cost, cost_general, cost_directprivacy, icann_fee, other_cost,remarks, direct_privacy,
    totalcost, invoice_number, account, invoice_amount, registrant_email, cc_statement_date, date_of_invoice, transaction_type, finance_comments, status, version_count, imagefiles }) => {

    addRecordVersion({
        edithiddenid, domainid, currency, yearly_renew_cost, cost_general, cost_directprivacy, icann_fee, other_cost,remarks, direct_privacy,
        totalcost, invoice_number, account, invoice_amount, registrant_email, cc_statement_date, date_of_invoice, transaction_type, finance_comments, status, imagefiles, version_count
    });

    var Domains = Parse.Object.extend("Finance");
    var query = new Parse.Query(Domains);
    query.equalTo("objectId", edithiddenid);

    return query.find().then(async (object) => {

        var realbject = object[0];

        realbject.set("currency", currency);

        realbject.set("yearly_renew_cost", yearly_renew_cost);
        realbject.set("cost_general", cost_general);
        realbject.set("cost_directprivacy", cost_directprivacy);
        realbject.set("icann_fee", icann_fee);
        realbject.set("other_cost", other_cost);
        realbject.set("remarks", remarks);
        realbject.set("direct_privacy", direct_privacy);
        realbject.set("totalcost", totalcost);
        realbject.set("invoice_number", invoice_number);
        realbject.set("account", account);
        realbject.set("invoice_amount", parseFloat(invoice_amount));
        realbject.set("registrant_email", registrant_email);

        if (cc_statement_date) {
            realbject.set("cc_statement_date", cc_statement_date);
        }
        if (date_of_invoice) {
            realbject.set("date_of_invoice", date_of_invoice);
        }
        realbject.set("transaction_type", transaction_type);
        realbject.set("finance_comments", finance_comments);
        realbject.set("logoFiles", imagefiles);

        if (status === 'false') {
            realbject.set("status", false);
        } else {
            realbject.set("status", true);
        }

        realbject.set("version_count", parseInt(version_count));

        return realbject.save().then(async (records) => {


            //Log
            var currentUser = Parse.User.current();
            const Logs = Parse.Object.extend("Logs");
            const log = new Logs();
            log.set("finance", { "__type": "Pointer", "className": "Finance", "objectId": edithiddenid });
            log.set("action", 'updated');
            log.set("user", currentUser);
            let res = await log.save();
            //Log



            return records;
        }, (error) => {
            console.log(error);
            return false;
        }).catch(function (error) {
            console.log(error);
            return false;
        });

    }, (error) => {
        return false;
    });

}


export const addRecordVersion = async ({ edithiddenid, domainid, currency, yearly_renew_cost, cost_general, cost_directprivacy, icann_fee, other_cost,remarks, direct_privacy, totalcost, invoice_number,
    account, invoice_amount, registrant_email, cc_statement_date, date_of_invoice, transaction_type, finance_comments, status, imagefiles, version_count }) => {

    var finance = new Parse.Object("Finance_versions");

    finance.set("financeId", edithiddenid);

    if (domainid) {
        var extentObject = Parse.Object.extend("Domains");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(domainid);
        finance.set("domain", parsepoint);
    }

    finance.set("currency", currency);

    finance.set("yearly_renew_cost", yearly_renew_cost);
    finance.set("cost_general", cost_general);
    finance.set("cost_directprivacy", cost_directprivacy);
    finance.set("icann_fee", icann_fee);
    finance.set("other_cost", other_cost);
    finance.set("remarks", remarks);
    finance.set("direct_privacy", direct_privacy);
    finance.set("totalcost", totalcost);
    finance.set("invoice_number", invoice_number);
    finance.set("account", account);
    finance.set("invoice_amount", parseFloat(invoice_amount));
    finance.set("registrant_email", registrant_email);

    if (cc_statement_date) {
        finance.set("cc_statement_date", cc_statement_date);
    }
    if (date_of_invoice) {
        finance.set("date_of_invoice", date_of_invoice);
    }
    finance.set("transaction_type", transaction_type);
    finance.set("finance_comments", finance_comments);
    finance.set("logoFiles", imagefiles);

    if (status === 'false') {
        finance.set("status", false);
    } else {
        finance.set("status", true);
    }


    finance.set("version_count", version_count);




    return finance.save().then((records) => {
        return true;
    }, (error) => {
        console.log(error);
        return false;
    }).catch(function (error) {
        console.log(error);
        return false;
    });
}

export const getHiddenColumns = async () => {
    var currentUser = Parse.User.current();

    var Finance_columns = new Parse.Object("Finance_columns");
    var query = new Parse.Query(Finance_columns);
    if (currentUser) {
        query.equalTo("userObjectID", currentUser.id);
    }

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}
export const saveHiddenColumns = async ({ hiddenitems, userObjectID }) => {

    var Finance_columnsfetch = Parse.Object.extend("Finance_columns");
    var query = new Parse.Query(Finance_columnsfetch);
    query.equalTo("userObjectID", userObjectID);
    query.descending("createdAt");
    const count = await query.count();

    if (count > 0) { 
        return query.find().then(async (object) => {
            var realbject = object[0];
            realbject.set("userObjectID", userObjectID);
            realbject.set("hiddenColumns", hiddenitems);

            return realbject.save().then(async (records) => {
                return true;
            }, (error) => {
                return false;
            }).catch(function (error) {
                return false;
            });

        }, (error) => {
            return false;
        });
    } else {
        var Finance_columns = new Parse.Object("Finance_columns");
        Finance_columns.set("userObjectID", userObjectID);
        Finance_columns.set("hiddenColumns", hiddenitems);
        return Finance_columns.save().then((records) => {
            return true;
        }, (error) => {
            return false;
        }).catch(function (error) {
            return false;
        });
    }

}


export const checkFieldValid = (addoredit) => {

    var errstat = true;
    jQuery('.customAcc.card').each(function () {
        var flag = false;
        jQuery(this).find('input, select, .reactSelect.reactSelectClass').each(function () {

            if (jQuery(this).prop('required')) {
                var currelement = jQuery(this);

                if (currelement.val() === "") {
                    currelement.addClass("is-invalid");
                    currelement.parents(".customAcc").find('.fieldsouter').addClass('failed').removeClass('completed');
                    currelement.parents(".customAcc").find('.fieldsouter i').removeClass('fas fa-check').addClass('icon-pencil');

                    errstat = false;
                    flag = true;
                } else {
                    currelement.removeClass("is-invalid");
                    if (flag === false) {
                        currelement.parents(".customAcc").find('.fieldsouter').removeClass('failed').addClass('completed');
                        currelement.parents(".customAcc").find('.fieldsouter i').removeClass('icon-pencil').addClass('fas fa-check');
                    }
                }
            }


            var dateelement = jQuery(this);

            if (dateelement.attr('id') === "cc_statement_date" || dateelement.attr('id') === "date_of_invoice") {
                // var expiry_date = new Date(dateelement.val());

                var st = dateelement.val();
                var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                var expiry_date = new Date(st.replace(pattern, '$3-$2-$1'));


                if (dateelement.val() !== "") {
                    if (expiry_date.toString() === 'Invalid Date') {
                        dateelement.addClass("is-invalid");
                        dateelement.parents(".customAcc").find('.fieldsouter').addClass('failed').removeClass('completed');
                        dateelement.parents(".customAcc").find('.fieldsouter i').removeClass('fas fa-check').addClass('icon-pencil');

                        errstat = false;
                        flag = true;
                    } else {
                        dateelement.removeClass("is-invalid");
                        if (flag === false) {
                            dateelement.parents(".customAcc").find('.fieldsouter').removeClass('failed').addClass('completed');
                            dateelement.parents(".customAcc").find('.fieldsouter i').removeClass('icon-pencil').addClass('fas fa-check');
                        }
                    }
                }
            }



        });
    });


    if (errstat === false) {
        return false;
    } else {
        return true;
    }

}


export const checkValidBySection = (states, control) => {

    var errstat = true;
    jQuery(control).each(function () {
        var flag = false;
        jQuery(this).find('input, select, .reactSelect.reactSelectClass').each(function () {
            if (jQuery(this).prop('required')) {
                var currelement = jQuery(this);
                if (currelement.val() === "") {
                    currelement.addClass("is-invalid");
                    currelement.parents(".customAcc").find('.fieldsouter').addClass('failed').removeClass('completed');
                    currelement.parents(".customAcc").find('.fieldsouter i').removeClass('fas fa-check').addClass('icon-pencil');

                    errstat = false;
                    flag = true;
                } else {
                    currelement.removeClass("is-invalid");
                    if (flag === false) {
                        currelement.parents(".customAcc").find('.fieldsouter').removeClass('failed').addClass('completed');
                        currelement.parents(".customAcc").find('.fieldsouter i').removeClass('icon-pencil').addClass('fas fa-check');
                    }
                }
            } else if (jQuery(this).hasClass('required')) {
                var currelement = jQuery(this).attr('id');

                if (states[currelement] === undefined) {
                    jQuery('#' + currelement).addClass("is-invalid");
                    jQuery('#' + currelement).parents(".customAcc").find('.fieldsouter').addClass('failed').removeClass('completed');
                    jQuery('#' + currelement).parents(".customAcc").find('.fieldsouter i').removeClass('fas fa-check').addClass('icon-pencil');

                    errstat = false;
                    flag = true;
                } else {
                    jQuery('#' + currelement).removeClass("is-invalid");
                    if (flag === false) {
                        jQuery('#' + currelement).parents(".customAcc").find('.fieldsouter').removeClass('failed').addClass('completed');
                        jQuery('#' + currelement).parents(".customAcc").find('.fieldsouter i').removeClass('icon-pencil').addClass('fas fa-check');
                    }
                }
            }


            //Date validation
            var dateelement = jQuery(this);

            if (dateelement.attr('id') === "cc_statement_date" || dateelement.attr('id') === "date_of_invoice") {
                // var expiry_date = new Date(dateelement.val());


                var st = dateelement.val();
                var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                var expiry_date = new Date(st.replace(pattern, '$3-$2-$1'));


                if (dateelement.val() !== "") {
                    if (expiry_date.toString() === 'Invalid Date') {
                        dateelement.addClass("is-invalid");
                        dateelement.parents(".customAcc").find('.fieldsouter').addClass('failed').removeClass('completed');
                        dateelement.parents(".customAcc").find('.fieldsouter i').removeClass('fas fa-check').addClass('icon-pencil');

                        errstat = false;
                        flag = true;
                    } else {
                        dateelement.removeClass("is-invalid");
                        if (flag === false) {
                            dateelement.parents(".customAcc").find('.fieldsouter').removeClass('failed').addClass('completed');
                            dateelement.parents(".customAcc").find('.fieldsouter i').removeClass('icon-pencil').addClass('fas fa-check');
                        }
                    }
                }
            }



        });
    });


    if (errstat === false) {
        return false;
    } else {
        return true;
    }

}


export const getFinanceForExport = async () => {
    var Finance = Parse.Object.extend("Finance");
    var query = new Parse.Query(Finance);
    query.descending("createdAt");
    query.limit(2000);

    query.include("domain");
    query.include("domain.domain");
    query.include("domain.registrar");
    
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}