import React from 'react';
import { Link } from 'react-router-dom';
import { Input, Button, Label, } from 'reactstrap';
import jQuery from 'jquery';
import Papa from 'papaparse';

import { authenticateLoggedIn, addHeaderTitle, addBreadcrumb } from '../../services/parse';
import { getDomainsForExport } from '../../services/domain';
import Loader from '../../components/common/Loader';


class exportDomains extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      loader: true,
      showfieldItems: [],
      fieldItems: [],
      domainData: [],
    };
  }


  async componentDidMount(evt) {

    authenticateLoggedIn();
    addHeaderTitle('Export Domains');
    addBreadcrumb('Home / Domains / Export');

    var showfieldItems = {
      name: true,
      domain: true,
      geography: true,
      region: true,
      registrar: true,
      expiry_date: true,
      domain_status: true,
      web_category: true,
      website: true,
      created_on_date: true,
      updated_date: true,
      business_sector: true,
      alj_entity: true,
      masterbrand: true,
      registrant_name: true,
      contact_email: true,
      url: true,
      redirect: true,
      registrant_email: true,
      previous_registrant: true,
      previous_registrant_email: true,
      handled_by: true,
      contact_name: true,
      action: true,
      notes: true,
      ipr_date_transferred: true,
      ipr_transfer_status: true,
      register_alj_ipr: true,
      status: true,
    }
    this.setState({ showfieldItems: showfieldItems });




    var fieldItems = [
      { label: "Name", name: "name" },
      { label: "Domain", name: "domain" },
      { label: "Country", name: "geography" },
      { label: "Region", name: "region" },
      { label: "Registrar", name: "registrar" },
      { label: "Expiry", name: "expiry_date" },
      { label: "Domain Status", name: "domain_status" },
      { label: "Web Category", name: "web_category" },
      { label: "Website", name: "website" },
      { label: "Created Date", name: "created_on_date" },
      { label: "Updated Date", name: "updated_date" },
      { label: "Business Sector", name: "business_sector" },
      { label: "ALJ Entity", name: "alj_entity" },
      { label: "Masterbrand", name: "masterbrand" },
      { label: "Registrant Name", name: "registrant_name" },
      { label: "Contact Email", name: "contact_email" },
      { label: "URL", name: "url" },
      { label: "Redirect", name: "redirect" },
      { label: "Registrant Email", name: "registrant_email" },
      { label: "Previous Registrant", name: "previous_registrant" },
      { label: "Previous Registrant Email", name: "previous_registrant_email" },
      { label: "Handled By", name: "handled_by" },
      { label: "Contact Name", name: "contact_name" },
      { label: "Action", name: "action" },
      { label: "Notes", name: "notes" },
      { label: "IPR Date Transferred", name: "ipr_date_transferred" },
      { label: "IPR Transfer Status", name: "ipr_transfer_status" },
      { label: "Register ALJ IPR", name: "register_alj_ipr" },
      { label: "Status", name: "status" }
    ];
    this.setState({ fieldItems: fieldItems });


    var alldomains = await getDomainsForExport();
    this.setState({ domainData: alldomains });

    this.setState({ loader: false });

  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }



  manageColumnsToShow = async (event) => {
    var checkval = event.target.value;
    var checkstat = event.target.checked;


    console.log(this.state.showfieldItems)

    this.setState({
      showfieldItems: {
        ...this.state.showfieldItems,
        [checkval]: checkstat,
      },
    });

  }


  exportCSVData = async (event) => {

    const fields = [
      this.state.showfieldItems.name === true ? "Name" : "***",
      this.state.showfieldItems.domain === true ? "Domain" : "***",
      this.state.showfieldItems.geography === true ? "Country" : "***",
      this.state.showfieldItems.region === true ? "Region" : "***",
      this.state.showfieldItems.registrar === true ? "Registrar" : "***",
      this.state.showfieldItems.expiry_date === true ? "Expiry" : "***",
      this.state.showfieldItems.domain_status === true ? "Domain Status" : "***",
      this.state.showfieldItems.web_category === true ? "Web Category" : "***",
      this.state.showfieldItems.website === true ? "Website" : "***",
      this.state.showfieldItems.created_on_date === true ? "Created Date" : "***",
      this.state.showfieldItems.updated_date === true ? "Updated Date" : "***",
      this.state.showfieldItems.business_sector === true ? "Business Sector" : "***",
      this.state.showfieldItems.alj_entity === true ? "ALJ Entity" : "***",
      this.state.showfieldItems.masterbrand === true ? "Masterbrand" : "***",
      this.state.showfieldItems.registrant_name === true ? "Registrant Name" : "***",
      this.state.showfieldItems.contact_email === true ? "Contact Email" : "***",
      this.state.showfieldItems.url === true ? "URL" : "***",
      this.state.showfieldItems.redirect === true ? "Redirect" : "***",
      this.state.showfieldItems.registrant_email === true ? "Registrant Email" : "***",
      this.state.showfieldItems.previous_registrant === true ? "Previous Registrant" : "***",
      this.state.showfieldItems.previous_registrant_email === true ? "Previous Registrant Email" : "***",
      this.state.showfieldItems.handled_by === true ? "Handled By" : "***",
      this.state.showfieldItems.contact_name === true ? "Contact Name" : "***",
      this.state.showfieldItems.action === true ? "Action" : "***",
      this.state.showfieldItems.notes === true ? "Notes" : "***",
      this.state.showfieldItems.ipr_date_transferred === true ? "IPR Date Transferred" : "***",
      this.state.showfieldItems.ipr_transfer_status === true ? "IPR Transfer Status" : "***",
      this.state.showfieldItems.register_alj_ipr === true ? "Register ALJ IPR" : "***",
      this.state.showfieldItems.status === true ? "Status" : "***"
    ];

    const data = this.state.domainData.map(obj => [
      this.state.showfieldItems.name === true ?
        obj.get('name')
        :
        "***",
      this.state.showfieldItems.domain === true ?
        obj.get('domain') ? obj.get('domain').get('name') : ""
        :
        "***",
      this.state.showfieldItems.geography === true ?
        obj.get('geography') ? obj.get('geography').get('name') : ""
        :
        "***",
      this.state.showfieldItems.region === true ?
        obj.get('region') ? obj.get('region').get('name') : ""
        :
        "***",
      this.state.showfieldItems.registrar === true ?
        obj.get('registrar') ? obj.get('registrar').get('name') : ""
        :
        "***",
      this.state.showfieldItems.expiry_date === true ?
        obj.get('expiry_date') ? obj.get('expiry_date').toLocaleDateString('en-GB') : ""
        :
        "***",
      this.state.showfieldItems.domain_status === true ?
        obj.get('domain_status') ? obj.get('domain_status').get('name') : ""
        :
        "***",
      this.state.showfieldItems.web_category === true ?
        obj.get('web_category') ? obj.get('web_category').get('name') : ""
        :
        "***",
      this.state.showfieldItems.website === true ?
        obj.get('website')
        :
        "***",
      this.state.showfieldItems.created_on_date === true ?
        obj.get('created_on_date') ? obj.get('created_on_date').toLocaleDateString('en-GB') : ""
        :
        "***",
      this.state.showfieldItems.updated_date === true ?
        obj.get('updated_date') ? obj.get('updated_date').toLocaleDateString('en-GB') : ""
        :
        "***",
      this.state.showfieldItems.business_sector === true ?
        obj.get('business_sector') ? obj.get('business_sector').get('name') : ""
        :
        "***",
      this.state.showfieldItems.alj_entity === true ?
        obj.get('alj_entity') ? obj.get('alj_entity').get('name') : ""
        :
        "***",
      this.state.showfieldItems.masterbrand === true ?
        obj.get('masterbrand') ? obj.get('masterbrand').get('name') : ""
        :
        "***",
      this.state.showfieldItems.registrant_name === true ?
        obj.get('registrant_name')
        :
        "***",
      this.state.showfieldItems.contact_email === true ?
        obj.get('contact_email')
        :
        "***",
      this.state.showfieldItems.url === true ?
        obj.get('url')
        :
        "***",
      this.state.showfieldItems.redirect === true ?
        obj.get('redirect')
        :
        "***",
      this.state.showfieldItems.registrant_email === true ?
        obj.get('registrant_email')
        :
        "***",
      this.state.showfieldItems.previous_registrant === true ?
        obj.get('previous_registrant')
        :
        "***",
      this.state.showfieldItems.previous_registrant_email === true ?
        obj.get('previous_registrant_email')
        :
        "***",
      this.state.showfieldItems.handled_by === true ?
        obj.get('handled_by')
        :
        "***",
      this.state.showfieldItems.contact_name === true ?
        obj.get('contact_name')
        :
        "***",
      this.state.showfieldItems.action === true ?
        obj.get('action')
        :
        "***",
      this.state.showfieldItems.notes === true ?
        obj.get('notes')
        :
        "***",
      this.state.showfieldItems.ipr_date_transferred === true ?
        obj.get('ipr_date_transferred') ? obj.get('ipr_date_transferred').toLocaleDateString('en-GB') : ""
        :
        "***",
      this.state.showfieldItems.ipr_transfer_status === true ?
        obj.get('ipr_transfer_status')
        :
        "***",
      this.state.showfieldItems.register_alj_ipr === true ?
        obj.get('register_alj_ipr')
        :
        "***",
      this.state.showfieldItems.status === true ?
        obj.get('status')
        :
        "***"
    ]);



    const filteredData = data.map(item => {
      return item.filter(val => val !== '***')
    });
    const filteredFields = fields.filter(field => field !== '***');

    const csv = Papa.unparse({
      data: filteredData,
      fields: filteredFields
    });

    const blob = new Blob([csv]);
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob, { type: 'text/csv' });
    a.download = 'exportdata.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }




  render() {

    return <div>

      <div className="customcheck greybgcheck" id="showexportcheckgroup">


        {
          this.state.fieldItems ?
            this.state.fieldItems.map((dynamicData, key) => {
              return (
                <div className="form-check form-check-inline">
                  <Input type="checkbox" value={dynamicData.name} id={dynamicData.name} name="showitems" onClick={this.manageColumnsToShow} defaultChecked />
                  <Label for={dynamicData.name}>{dynamicData.label}</Label>
                </div>
              )
            })
            :
            null
        }

      </div>

      <div className="text-center">
        {
          this.state.loader === true ?
            <div className="btn btn-primary" id="loadbutton">
              <Loader type="Oval" color="#414241" height={22} width={100} />
            </div>
            :
            <Button type="button" color="primary" id="addbutton" onClick={this.exportCSVData}>
              <span className="border-left pl-2"></span>
              Export CSV
            </Button>
        }
      </div>


    </div>
  }
}

export default exportDomains;
