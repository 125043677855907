import React from 'react';
import Prompt from './Prompt';
// import {Prompt} from 'react-router-dom'

 export default class PageLeavePromt extends React.Component {
  //other logic
    render() {
     return(
        <Prompt
        when={this.props.status}
        message={this.props.message}
      />
     );
    }
 }