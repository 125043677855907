import React from "react";
import ScrollArea from 'react-scrollbar';

import img1 from '../../../assets/images/users/1.jpg';
import imgglobe from '../../../assets/images/icons/icon-globe.png';
import imgmedia from '../../../assets/images/icons/icon-media.png';
import imgtrademark from '../../../assets/images/icons/icon-trademark.png';
import iconFinance from '../../../assets/images/icons/icon-finaince.png';
import imgarrowright from '../../../assets/images/icons/arrow-right.png';
import iconFinaince from '../../../assets/images/icons/icon-finaince.png';
import { isAdmin } from '../../../services/user';
import { getRecentActivityMediaLog } from '../../../services/dashboard';

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Input,
  Table,
  Tooltip
} from 'reactstrap';

class MediaActivity extends React.Component {
  constructor(props) {
    super(props);

    this.toggle10 = this.toggle10.bind(this);
    this.toggle20 = this.toggle20.bind(this);
    this.toggle30 = this.toggle30.bind(this);
    this.toggle40 = this.toggle40.bind(this);
    this.state = {
      tooltipOpen10: false,
      tooltipOpen20: false,
      tooltipOpen30: false,
      tooltipOpen40: false,
      recentActivityLog: [],
      currentRole: '',
    };
  }


  async componentDidMount(evt) {

    var isadminval = await isAdmin();
    if (isadminval) {
      var currentrole = isadminval[0].get('role').get('name');
      if (currentrole === "Legal" || currentrole === "External" || currentrole === "Finance") {
        this.setState({ currentRole: "Legal" });
      } else {
        this.setState({ currentRole: "" });
      }
    } else {
      this.setState({ currentRole: "" });
    }


    getRecentActivityMediaLog().then((value) => {
      this.setState({ recentActivityLog: value });
    });


  }




  toggle10() {
    this.setState({
      tooltipOpen10: !this.state.tooltipOpen10
    });
  }

  toggle20() {
    this.setState({
      tooltipOpen20: !this.state.tooltipOpen20
    });
  }

  toggle30() {
    this.setState({
      tooltipOpen30: !this.state.tooltipOpen30
    });
  }

  toggle40() {
    this.setState({
      tooltipOpen40: !this.state.tooltipOpen40
    });
  }

  render() {
    return (

      <Row>
        <Col lg="12">
          <Card className="recentactivity">
            <CardBody>
              <div className="d-md-flex align-items-center">
                <div>
                  <CardTitle>Recent activity</CardTitle>
                </div>
              </div>

              <ScrollArea smoothScrolling={0.8} style={{ maxHeight: '310px' }}>
                <Table className="v-middle" responsive>

                  {
                    this.state.recentActivityLog.map((item, key) =>
                      <tbody key={key}>
                        {
                          item.get('library') !== undefined ?
                            <tr key={key}>
                              <td>
                                <a href={"#/medialicense/libraries/?objectid=" + item.get('library').id} className={`tdwrap ${item.get('action') === "archive" ? "noclick" : null}`}>
                                  <h5 className="mb-0 font-16 font-medium">
                                    {
                                      item.get('user') ?
                                        item.get('user').get('firstName')
                                        :
                                        ""
                                    }
                                  </h5>
                                </a>
                              </td>
                              <td className="imgbx">
                                <a href={"#/medialicense/libraries/?objectid=" + item.get('library').id} className={`tdwrap ${item.get('action') === "archive" ? "noclick" : null}`}>
                                  <img src={imgmedia} alt="imgglobe" width="45" />
                                </a>
                              </td>
                              <td>
                                <a href={"#/medialicense/libraries/?objectid=" + item.get('library').id} className={`tdwrap ${item.get('action') === "archive" ? "noclick" : null}`}>
                                  {item.get('action')} record for {item.get('library').get('name')} in Media Library
                                </a>
                              </td>
                              <td>
                                <a href={"#/medialicense/libraries/?objectid=" + item.get('library').id} className={`tdwrap ${item.get('action') === "archive" ? "noclick" : null}`}>
                                  {
                                    item.createdAt ?
                                      item.createdAt.toUTCString()
                                      :
                                      ""
                                  }
                                </a>
                              </td>
                              <td className="arrowimg font-medium">
                                <a href={"#/medialicense/libraries/?objectid=" + item.get('library').id} className={`tdwrap ${item.get('action') === "archive" ? "noclick" : null}`}>
                                  <img src={imgarrowright} alt={"imgarrowright"} width="24" />
                                </a>
                              </td>
                            </tr>
                            :
                            null
                        }
                        {
                          item.get('asset') !== undefined ?
                            <tr key={key}>
                              <td>
                                <a href={"#/medialicense/assets/?objectid=" + item.get('asset').id} className={`tdwrap ${item.get('action') === "archive" ? "noclick" : null}`}>
                                  <h5 className="mb-0 font-16 font-medium">
                                    {
                                      item.get('user') ?
                                        item.get('user').get('firstName')
                                        :
                                        ""
                                    }
                                  </h5>
                                </a>
                              </td>
                              <td className="imgbx">
                                <a href={"#/medialicense/assets/?objectid=" + item.get('asset').id} className={`tdwrap ${item.get('action') === "archive" ? "noclick" : null}`}>
                                  <img src={imgmedia} alt="imgglobe" width="45" />
                                </a>
                              </td>
                              <td>
                                <a href={"#/medialicense/assets/?objectid=" + item.get('asset').id} className={`tdwrap ${item.get('action') === "archive" ? "noclick" : null}`}>
                                  {item.get('action')} record for {item.get('asset').get('title')} in Media Asset
                                </a>
                              </td>
                              <td>
                                <a href={"#/medialicense/assets/?objectid=" + item.get('asset').id} className={`tdwrap ${item.get('action') === "archive" ? "noclick" : null}`}>
                                  {
                                    item.createdAt ?
                                      item.createdAt.toUTCString()
                                      :
                                      ""
                                  }
                                </a>
                              </td>
                              <td className="arrowimg font-medium">
                                <a href={"#/medialicense/assets/?objectid=" + item.get('asset').id} className={`tdwrap ${item.get('action') === "archive" ? "noclick" : null}`}>
                                  <img src={imgarrowright} alt={"imgarrowright"} width="24" />
                                </a>
                              </td>
                            </tr>
                            :
                            null
                        }
                        {
                          item.get('agencyasset') !== undefined ?
                            <tr key={key}>
                              <td>
                                <a href={"#/medialicense/agencysubmissions/?objectid=" + item.get('agencyasset').id} className={`tdwrap ${item.get('action') === "archive" ? "noclick" : null}`}>
                                  <h5 className="mb-0 font-16 font-medium">
                                    {
                                      item.get('user') ?
                                        item.get('user').get('firstName')
                                        :
                                        ""
                                    }
                                  </h5>
                                </a>
                              </td>
                              <td className="imgbx">
                                <a href={"#/medialicense/agencysubmissions/?objectid=" + item.get('agencyasset').id} className={`tdwrap ${item.get('action') === "archive" ? "noclick" : null}`}>
                                  <img src={imgmedia} alt="imgglobe" width="45" />
                                </a>
                              </td>
                              <td>
                                <a href={"#/medialicense/agencysubmissions/?objectid=" + item.get('agencyasset').id} className={`tdwrap ${item.get('action') === "archive" ? "noclick" : null}`}>
                                  {item.get('action')} record for {item.get('agencyasset').get('title')} in Media Asset Agency
                                </a>
                              </td>
                              <td>
                                <a href={"#/medialicense/agencysubmissions/?objectid=" + item.get('agencyasset').id} className={`tdwrap ${item.get('action') === "archive" ? "noclick" : null}`}>
                                  {
                                    item.createdAt ?
                                      item.createdAt.toUTCString()
                                      :
                                      ""
                                  }
                                </a>
                              </td>
                              <td className="arrowimg font-medium">
                                <a href={"#/medialicense/agencysubmissions/?objectid=" + item.get('agencyasset').id} className={`tdwrap ${item.get('action') === "archive" ? "noclick" : null}`}>
                                  <img src={imgarrowright} alt={"imgarrowright"} width="24" />
                                </a>
                              </td>
                            </tr>
                            :
                            null
                        }
                      </tbody>

                    )
                  }






                  {/* {
                    this.state.recentActivityLog.map((item, key) =>
                      <tbody key={key}>
                        {
                          item.get('domain') !== undefined && this.state.currentRole !== "Legal" ?
                            <tr key={key}>
                              <td>
                                <a href={"#/domain/editdomain/" + item.id + "/default"} className={`tdwrap ${item.get('action') ==="archive" ? "noclick" : null}`}>
                                  <h5 className="mb-0 font-16 font-medium">
                                    {
                                      item.get('user') ?
                                        item.get('user').get('firstName')
                                        :
                                        ""
                                    }
                                  </h5>
                                </a>
                              </td>
                              <td className="imgbx">
                                <a href={"#/domain/editdomain/" + item.get('domain').id + "/default"} className={`tdwrap ${item.get('action') ==="archive" ? "noclick" : null}`}>
                                  <img src={imgglobe} alt="imgglobe" width="45" />
                                </a>
                              </td>
                              <td>
                                <a href={"#/domain/editdomain/" + item.get('domain').id + "/default"} className={`tdwrap ${item.get('action') ==="archive" ? "noclick" : null}`}>
                                  {item.get('action')} record for {item.get('domain').get('name')} in Domain
                            </a>
                              </td>
                              <td>
                                <a href={"#/domain/editdomain/" + item.get('domain').id + "/default"} className={`tdwrap ${item.get('action') ==="archive" ? "noclick" : null}`}>
                                  {
                                    item.createdAt ?
                                      item.createdAt.toUTCString()
                                      :
                                      ""
                                  }
                                </a>
                              </td>
                              <td className="arrowimg font-medium">
                                <a href={"#/domain/editdomain/" + item.get('domain').id + "/default"} className={`tdwrap ${item.get('action') ==="archive" ? "noclick" : null}`}>
                                  <img src={imgarrowright} alt={"imgarrowright"} width="24" />
                                </a>
                              </td>
                            </tr>
                            :
                            null
                        }
                        {

                          item.get('finance') !== undefined && this.state.currentRole !== "Legal" ?
                            <tr key={key}>
                              <td>
                                <a href={"#/finance/editfinance/" + item.get('finance').id + "/default"} className={`tdwrap ${item.get('action') ==="archive" ? "noclick" : null}`}>
                                  <h5 className="mb-0 font-16 font-medium">
                                    {
                                      item.get('user') ?
                                        item.get('user').get('firstName')
                                        :
                                        ""
                                    }
                                  </h5>
                                </a>
                              </td>
                              <td className="imgbx">
                                <a href={"#/finance/editfinance/" + item.get('finance').id + "/default"} className={`tdwrap ${item.get('action') ==="archive" ? "noclick" : null}`}>
                                  <img src={iconFinance} alt="imgglobe" width="45" />
                                </a>
                              </td>
                              <td>
                                <a href={"#/finance/editfinance/" + item.get('finance').id + "/default"} className={`tdwrap ${item.get('action') ==="archive" ? "noclick" : null}`}>
                                  {item.get('action')} record for in {item.get('finance').get('domain').get('name')} Finance
                            </a>
                              </td>
                              <td>
                                <a href={"#/finance/editfinance/" + item.get('finance').id + "/default"} className={`tdwrap ${item.get('action') ==="archive" ? "noclick" : null}`}>
                                  {
                                    item.createdAt ?
                                      item.createdAt.toUTCString()
                                      :
                                      ""
                                  }
                                </a>
                              </td>
                              <td className="arrowimg font-medium">
                                <a href={"#/finance/editfinance/" + item.get('finance').id + "/default"} className={`tdwrap ${item.get('action') ==="archive" ? "noclick" : null}`}>
                                  <img src={imgarrowright} alt={"imgarrowright"} width="24" />
                                </a>
                              </td>
                            </tr>
                            :
                            null
                        }
                        {
                          item.get('trademark') !== undefined ?
                            <tr key={key}>
                              <td>
                                <a href={"#/trademark/edittrademark/" + item.get('trademark').id} className={`tdwrap ${item.get('action') ==="archive" ? "noclick" : null}`}>
                                  <h5 className="mb-0 font-16 font-medium">
                                    {
                                      item.get('user') ?
                                        item.get('user').get('firstName')
                                        :
                                        ""
                                    }
                                  </h5>
                                </a>
                              </td>
                              <td className="imgbx">
                                <a href={"#/trademark/edittrademark/" + item.get('trademark').id} className={`tdwrap ${item.get('action') ==="archive" ? "noclick" : null}`}>
                                  <img src={imgtrademark} alt="imgtrademark" width="45" />
                                </a>
                              </td>
                              <td>
                                <a href={"#/trademark/edittrademark/" + item.get('trademark').id} className={`tdwrap ${item.get('action') ==="archive" ? "noclick" : null}`}>
                                  {item.get('action')} record for {item.get('trademark').get('name')} in Trademark
                            </a>
                              </td>
                              <td>
                                <a href={"#/trademark/edittrademark/" + item.get('trademark').id} className={`tdwrap ${item.get('action') ==="archive" ? "noclick" : null}`}>
                                  {
                                    item.createdAt ?
                                      item.createdAt.toUTCString()
                                      :
                                      ""
                                  }
                                </a>
                              </td>
                              <td className="arrowimg font-medium">
                                <a href={"#/trademark/edittrademark/" + item.get('trademark').id}>
                                  <img src={imgarrowright} alt={"imgarrowright"} width="24" />
                                </a>
                              </td>
                            </tr>
                            :
                            null
                        }
                      </tbody>

                    )
                  } */}

                </Table>
              </ScrollArea>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default MediaActivity;
