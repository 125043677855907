import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import { getFileUrl } from '../../../services/awsFileUpload';

const AssetImages = React.memo(({ imageurl, objid }) => {

    // const [awsImageUrl, setAwsImageUrl] = useState('');

    // useEffect(async () => {
    //     if (imageurl && imageurl !== "") {
    //         var awsurl = await getImageURL(imageurl);
    //         setAwsImageUrl(awsurl);
    //     }
    // }, [imageurl])


    // const getImageURL = async (img) => {
    //     if (img.awsFileName) {
    //         var imgurl = await getFileUrl("contentmaster", img.awsFileName);
    //         return imgurl.url;
    //     } else {
    //         return "";
    //     }
    // }

    return imageurl ?
        <LazyLoad offset={200} height={200} >
            <img data-objectid={objid} src={imageurl} alt='img'  />
        </LazyLoad>

        :
        <p>Loading...</p>;
});

export default AssetImages;