var Parse = require('parse');

export const sendEmail=async(toEmail,subject,templateName,placeholders)=>{
    var mailSent = await Parse.Cloud.run('sendEmail', {
        toEmail: toEmail, // Required
        subject:subject, // Required
        template:templateName, // Required
        values:placeholders          // Required
    });
    return mailSent;
}

export const sendTrademarkEmail=async(lawFirmId,trademarkId,tradeMarkName,referenceNumber)=>{
    var mailSent = await Parse.Cloud.run('sendNewTrademarkEmail', {
        lawFirmId: lawFirmId, // Required
        id:trademarkId, // Required
        name:tradeMarkName,
        referenceNumber:referenceNumber
    });
    return mailSent;
}

export const sendProcessStepCompletionEmail=async(trademarkId,tradeMarkName,statusTitle,referenceNumber,stepTitle,countries)=>{
    var mailSent = Parse.Cloud.run('sendTrademarkOnStatusCompleteEmail', {        
        id:trademarkId,
        trademark:tradeMarkName, 
        statusTitle:statusTitle,
        referenceNumber:referenceNumber,
        step:stepTitle,
        countries:countries        
    })
    return mailSent;
}