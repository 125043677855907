import React from 'react';
import {TabContent,TabPane,Card,CardBody,CardTitle,Row,Col,Form,FormGroup,Label,Input,FormText,Button,InputGroup,InputGroupAddon,InputGroupText,
    InputGroupButtonDropdown,DropdownToggle,DropdownMenu,DropdownItem,FormFeedback,CustomInput,Tooltip,UncontrolledTooltip,Alert} from 'reactstrap'; 
import { colourOptions } from '../form-layouts/data';
import Select, { components } from "react-select";
import jQuery from 'jquery';
import Loader from '../../components/common/Loader'; 
import AddNewTradeMark from './addNewTradeMark.jsx';
import ProgressStatusContainer from './progressStatusContainer.jsx';
import TradeMarkRenewals from './tradeMarkRenewals.jsx';
import classnames from 'classnames';
import MarketMaterial from '../../views/trademark/marketMaterial';
import {isCertificationCompleted} from '../../services/trademarkProgress';
import { Link } from 'react-router-dom';
import withRouter from '../../components/common/WithRouter.jsx';
var Parse = require('parse');


class tabContainer extends React.Component {
  constructor(props) {
    super(props);
    
   
    this.state = {
        dropdownOpen: false,
        dropdownOpen1: false,
        files : [],
        tooltipOpen: false,
        activeTab: '1',
        showMarketingTag:false,
        generalHidden:"",
    };
      
    }

    componentWillMount= async()=>{
      console.log(this.props.params, "this.props.params")
      if( this.props.params.id){
      this.isCertComp();
      }

      var cuser = await Parse.User.current();
      if (cuser) { 
        if (cuser.get('role').get('name') === "External") {  
          this.setState({ generalHidden: 'd-none' }); 
        }
      }
    }

    isCertComp = async()=>{
      var boolVal = await isCertificationCompleted( this.props.params.id);
      this.setState({showMarketingTag:boolVal})
    }
   
  async componentDidMount(evt) {
  }
  
  
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        
      });
    }
  }
    render() {

      return (
        this.props.params.id?
     <div>
      <Link to="/trademark/list" className="btn btn-default btnbacktolist"><span>Back To List</span></Link>
      <div className="tabscss">
       <a className={ "btn btn-primary "+ classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>Trademark Details</a>
       <a className={ "btn btn-primary "+ classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>Trademark Registration</a>
       
       {this.state.showMarketingTag?
       <>
       <a className={ "btn btn-primary "+ classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>Trademark Renewals</a>
        <a className={this.state.generalHidden+ " btn btn-primary "+ classnames({ active: this.state.activeTab === '4' })} onClick={() => { this.toggle('4'); }}>Marketing Materials</a>
        </>
        :null}
      </div>
 {/*--------------------------------------------------------------------------------*/}
 {/* Start Inner Div*/}
 {/*--------------------------------------------------------------------------------*/}
 <TabContent activeTab={this.state.activeTab}>
    <TabPane tabId="1">
        <AddNewTradeMark id={this.props.params.id} save={this.props.params.save}/>
    </TabPane>
    <TabPane tabId="2">
        <ProgressStatusContainer id={this.props.params.id} onSetMarketingTab={()=>{this.isCertComp()}}/>
    </TabPane>
   
    {this.state.showMarketingTag?
    <>
     <TabPane tabId="3">
     <TradeMarkRenewals id={this.props.params.id} onSetMarketingTab={()=>{this.isCertComp()}}/>
 </TabPane>
 <TabPane tabId="4">
        <MarketMaterial id={this.props.params.id} />
    </TabPane>
    </>:null}


    
    
 </TabContent>
 {/*--------------------------------------------------------------------------------*/}
 {/*End Inner Div*/}
 {/*--------------------------------------------------------------------------------*/}
</div>
        :
        <div>
           <div className="backbtnwrap"><Link to="/trademark/list" className="btn btn-default btnbacktolist"><span>Back To List</span></Link></div>        
           <AddNewTradeMark id={null}/>
        </div>
    )
    
    }
}

export default withRouter(tabContainer);
