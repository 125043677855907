// import React from 'react';
import React, { Component, useState, useEffect } from "react";
import { getFileUrl } from '../../../services/awsFileUpload';

class Libraryimage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      awsImageUrl: "",
    };

  }

  async componentDidMount(evt) {
    if (this.props.imageurl && this.props.imageurl !== "") {
      var awsurl = await this.getImageURL(this.props.imageurl);
      this.setState({ awsImageUrl: awsurl });
    }
  }

  async componentDidUpdate(prevProps, prevState) {

    if (this.props.imageurl !== prevProps.imageurl && this.props.popupstatus === "add") {  //ADD
      var awsurl = await this.getImageURL(this.props.imageurl);
      this.setState({ awsImageUrl: awsurl });
      console.log('add image refetch');
    }
    if (this.props.imageurl !== prevProps.imageurl && this.props.popupstatus === "edit") {  //EDIT
      var awsurl = await this.getImageURL(this.props.imageurl);
      this.setState({ awsImageUrl: awsurl });
      console.log('edit image refetch');
    }
    if (this.props.imageurl !== prevProps.imageurl && this.props.popupstatus === "view") {  //VIEW
      var awsurl = await this.getImageURL(this.props.imageurl);
      this.setState({ awsImageUrl: awsurl });
      console.log('edit image refetch');
    }
    if (this.props.imageurl !== prevProps.imageurl && this.props.popupstatus === "pagination") {  //VIEW
      var awsurl = await this.getImageURL(this.props.imageurl);
      this.setState({ awsImageUrl: awsurl });
      console.log('edit image refetch');
    }


    // if (this.props.imageurl !== prevProps.imageurl) {  //VIEW
    //   var awsurl = await this.getImageURL(this.props.imageurl);
    //   this.setState({ awsImageUrl: awsurl });
    //   console.log('edit image refetch');
    // }
 



  }

  getImageURL = async (img) => {
    var imgurl = await getFileUrl("contentmaster", img.awsFileName);
    return imgurl.url;
  }

  render() {
    return (

      this.state.awsImageUrl ?
        <img src={this.state.awsImageUrl} alt='img' />
        :
        <p>Loading...</p>

    )
  }
}

export default Libraryimage;