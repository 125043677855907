var Parse = require('parse');
export const getProgressById = async (progressId) => {

    var TrademarkStatuses = Parse.Object.extend("TrademarkStatuses");
    var query = new Parse.Query(TrademarkStatuses);
    query.equalTo("objectId", progressId);
    // query.include("BusinessSectors");
    var result = await query.find();
    if(result)
    return result[0];
    
    
}

export const getTrademarkById = async (trademarkId) => {

    var TrademarksMaster = Parse.Object.extend("TrademarksMaster");
    var query = new Parse.Query(TrademarksMaster);
    query.equalTo("objectId", trademarkId);
    // query.include("BusinessSectors");
    var result = await query.find();
    if(result)
    return result[0];
    
}


export const getTrademarkChatsById = async (trademarkId,progressId) => {
    var trademark = await getTrademarkById(trademarkId);
    var progress = await getProgressById(progressId);

    var TrademarkChats = Parse.Object.extend("TrademarkChats");
    var query = new Parse.Query(TrademarkChats);
    query.equalTo("trademark", trademark);
    query.equalTo("trademarkStatus", progress);
    query.include("user");
    query.descending('createdAt');
    // query.include("BusinessSectors");
    var result = await query.find();
    if(result)
    return result;
    
}

export const postTrademarkChat=async(message,trademarkId,progressId)=>{

    var trademark = await getTrademarkById(trademarkId);
    var progress = await getProgressById(progressId);
    const TrademarkChats = Parse.Object.extend('TrademarkChats');
const myNewObject = new TrademarkChats();

myNewObject.set('user', Parse.User.current());
myNewObject.set('trademark', trademark);
myNewObject.set('trademarkStatus', progress);
myNewObject.set('message', message);
myNewObject.save();
}

export const getUserFullName = async(user)=>{
    if(user)
    {
        return user.attributes.firstName+" "+user.attributes.lastName
    }
    else
    {
        var user = Parse.User.current();
        if(user){
            return user.attributes.firstName+" "+user.attributes.lastName;
        }
    }
}