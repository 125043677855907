import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Alert,
  InputGroup,
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import Select, { components } from "react-select";
import jQuery from 'jquery'
import Loader from '../../components/common/Loader';
import Autocomplete from 'react-autocomplete';
import Datetime from "react-datetime";
import 'react-datetime/css/react-datetime.css';
import FileUpload from '../../components/common/FileUpload';

import fileIcon from '../../assets/images/icons/file-icon.png';
import imageIcon from '../../assets/images/icons/image-icon.png';

import { getCurrentUser, authenticateLoggedIn, addHeaderTitle, getParameterByName, authenticateLoginpage, addBreadcrumb } from '../../services/parse';
import { uploadFile, getFileUrl } from '../../services/awsFileUpload';
import {
  updateRecord, checkFieldValid, fetchRecordById, fetchRecordByIdVersion, getFinanceFull, checkValidBySection, getDomainById
} from '../../services/finance';
import withRouter from '../../components/common/WithRouter';
import moment from 'moment';

var Parse = require('parse');


// jQuery('form').on('focus', 'input[type=number]', function (e) {
//   jQuery(this).on('wheel.disableScroll', function (e) {
//     e.preventDefault()
//   })
// })
// jQuery('form').on('blur', 'input[type=number]', function (e) {
//   jQuery(this).off('wheel.disableScroll')
// })

// jQuery(".disableScroll").click(function(){
//   jQuery('input[type=number]').on('mousewheel', function(e) {
//     jQuery(e.target).blur();
//   });
//   alert(3232);
// });



class editFinance extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      collapse: true,
      fadeIn: true,
      timeout: 300,
      isSuper: '',
      addedclass: 'd-none',
      hiddenobjectid: '',
      saveenabledisable: 'd-block',
      datafull: [],
      recordByIdData: [],
      domainbyidData: "",
      versionselectcounts: [],
      logoFileArray: [],
      registrant_email: "",
      isEdit: false,
      logofile: null,
      generalHidden: "",
    };
  }

  async componentDidMount(evt) {
    //auth start
    authenticateLoggedIn();
    addHeaderTitle('Edit Finance');
    addBreadcrumb('Home / Finance / Edit Finance');

    var editobjectid = this.props.params.objectid;
    var version = this.props.params.version;

    this.setState({ hiddenobjectid: editobjectid, });


    getFinanceFull().then((value) => {
      this.setState({ datafull: value, });
    });

    if (version !== 'default') {

      this.setState({ saveenabledisable: 'd-none' });

      fetchRecordByIdVersion({ objectid: editobjectid, versionselectcount: version }).then((value) => {

        if (value.length > 0) {
          this.setState({ recordByIdData: value[0] });
          if (value[0].get('logoFiles')) {
            this.setState({ logoFileArray: value[0].get('logoFiles') });
          }
          this.setState({ registrant_email: value[0].get('registrant_email') });
          this.setState({ isEdit: true });

        } else {
          this.setState({ isEdit: false });
        }
        jQuery(".opendefault").next('.card-body').show();
        jQuery(".opendefault").addClass('active');
      });

      // General values
      fetchRecordById({ objectid: editobjectid }).then((value) => {
        if (value.length > 0) {

          if (value[0].get('domain')) {
            var domainId = value[0].get('domain').id;
          } else { var domainId = ""; }

          if (value[0].get('domain') && value[0].get('domain').get('name')) {
            var domainName = value[0].get('domain').get('name');
          } else { var domainName = ""; }
          this.setState({ domainbyidData: domainId, });

          if (value[0].get('domain') && value[0].get('domain').get('domain')) {
            addHeaderTitle('Edit Finance (' + domainName + value[0].get('domain').get('domain').get('name') + ')');
          }

          var version_countarray = [];
          for (var i = 1; i <= value[0].attributes.version_count; i++) {
            if (i !== value[0].attributes.version_count) {
              version_countarray.push(i);
            }
          }
          this.setState({ versionselectcounts: version_countarray, });


        }
      });


    } else if (editobjectid) {
      this.setState({ saveenabledisable: 'd-inline-block' });
      fetchRecordById({ objectid: editobjectid }).then(async (value) => {

        if (value.length > 0) {

          this.setState({ recordByIdData: value[0] });
          if (value[0].get('logoFiles')) {
            this.setState({ logoFileArray: value[0].get('logoFiles') });
          }
          this.setState({ registrant_email: value[0].get('registrant_email') });
          this.setState({ isEdit: true });


          if (value[0].get('domain')) {
            var domainId = value[0].get('domain').id;
          } else { var domainId = ""; }

          if (value[0].get('domain') && value[0].get('domain').get('name')) {
            var domainName = value[0].get('domain').get('name');
          } else { var domainName = ""; }


          this.setState({ domainbyidData: domainId, });

          if (value[0].get('domain') && value[0].get('domain').get('domain')) {
            var cuser = await Parse.User.current();
            if (cuser) {
              if (cuser.get('role').get('name') === "Finance") {
                if (value[0].get('domain') && value[0].get('domain').get('domain') && value[0].get('domain').get('domain').get('name')) {
                  addHeaderTitle('View Finance (' + domainName + value[0].get('domain').get('domain').get('name') + ')');
                }
              } else {
                if (value[0].get('domain') && value[0].get('domain').get('domain') && value[0].get('domain').get('domain').get('name')) {
                  addHeaderTitle('Edit Finance (' + domainName + value[0].get('domain').get('domain').get('name') + ')');
                }
              }
            }
          }

          var version_countarray = [];
          for (var i = 1; i <= value[0].attributes.version_count; i++) {
            if (i !== value[0].attributes.version_count) {
              version_countarray.push(i);
            }
          }
          this.setState({ versionselectcounts: version_countarray, });


        } else {
          this.setState({ isEdit: false });
        }
        jQuery(".opendefault").next('.card-body').show();
        jQuery(".opendefault").addClass('active');

        // jQuery(".dollarclass").html('AED');
      });

    }


    var cuser = await Parse.User.current();
    if (cuser) {
      if (cuser.get('role').get('name') === "Finance") {
        this.setState({ saveenabledisable: 'd-none' });
        this.setState({ generalHidden: 'd-none' });
      }
    }

  }


  currentVersion = () => {
    var hiddenrecordid = jQuery('#hiddenrecordid').val();
    window.location.assign('#/finance/editfinance/' + hiddenrecordid + "/default");
    window.location.reload();
  }
  changeVersion = () => {
    var editobjectid = this.props.params.objectid;
    var versionselectcount = jQuery('#versionselectcount').val();
    if (versionselectcount === "currentversion") {
      this.currentVersion();
    } else {
      window.location.assign('#/finance/editfinance/' + editobjectid + '/' + versionselectcount);
      window.location.reload();
    }
  }




  toggle() {
    // this.setState({
    //   tooltipOpen: !this.state.tooltipOpen
    // });
    this.setState({
      modal: !this.state.modal
    });
  }

  onLogoFileSelected = async (event) => {
    console.log(event.target.files);
    var files = event.target.files
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        var fileType = files[i].type.split("/")[1];
        var fileName = files[i].name;
        console.log(fileType);
        var uploadStatus = await uploadFile('contentmaster', files[i]);
        console.log(uploadStatus);
        if (uploadStatus.success) {
          this.setState({
            logoFileArray: [
              ...this.state.logoFileArray,
              { awsFileName: uploadStatus.filename, type: fileType, name: fileName }
            ]
          });
        }
      }
    }
    //var logoUpload = await uploadFile('trademark',event.target.value);
  }

  updateRecord = () => {

    var valid = checkFieldValid('add');
    if (valid !== false) {
      jQuery('#addbutton').prop('disabled', true);
      jQuery('#addbutton').removeClass('d-inline-block');
      jQuery('#addbutton').addClass('d-none');
      jQuery('#loadbutton').removeClass('d-none');


      var hiddenrecordid = jQuery('#hiddenrecordid').val();

      var domainid = jQuery('#domainid').val();
      var currency = jQuery('#currency').val();
      var yearly_renew_cost = jQuery('#yearly_renew_cost').val();

      var cost_general = jQuery('#cost_general').val();
      var cost_directprivacy = jQuery('#cost_directprivacy').val();

      var icann_fee = jQuery('#icann_fee').val();
      var other_cost = jQuery('#other_cost').val();
      var remarks = jQuery('#remarks').val();
      var direct_privacy = jQuery('#direct_privacy').val();
      var totalcost = jQuery('#totalcost').html();
      var invoice_number = jQuery('#invoice_number').val();
      var account = jQuery('#account').val();
      var invoice_amount = jQuery('#invoice_amount').val();
      var registrant_email = jQuery('#registrant_email').val();

      if (jQuery('#cc_statement_date').val()) {

        var st = jQuery('#cc_statement_date').val();
        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var cc_statement_date = new Date(st.replace(pattern, '$3-$2-$1'));

        // var cc_statement_date = new Date(jQuery('#cc_statement_date').val());   // date
      } else {
        var cc_statement_date = "";
      }

      if (jQuery('#date_of_invoice').val()) {

        var st = jQuery('#date_of_invoice').val();
        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var date_of_invoice = new Date(st.replace(pattern, '$3-$2-$1'));

        // var date_of_invoice = new Date(jQuery('#date_of_invoice').val());  // date
      } else {
        var date_of_invoice = "";
      }

      var transaction_type = jQuery('#transaction_type').val();
      var finance_comments = jQuery('#finance_comments').val();
      var status = jQuery('#status').val();
      var version_count = parseInt(jQuery('#version_count').val()) + 1;


      var fielddatas = {
        edithiddenid: hiddenrecordid,
        domainid: domainid,
        currency: currency,
        yearly_renew_cost: yearly_renew_cost,
        cost_general: cost_general,
        cost_directprivacy: cost_directprivacy,
        icann_fee: icann_fee,
        other_cost: other_cost,
        remarks: remarks,
        direct_privacy: direct_privacy,
        totalcost: totalcost,
        invoice_number: invoice_number,
        account: account,
        invoice_amount: invoice_amount,
        registrant_email: registrant_email,
        cc_statement_date: cc_statement_date,
        date_of_invoice: date_of_invoice,
        transaction_type: transaction_type,
        finance_comments: finance_comments,
        status: status,
        version_count: version_count,
        imagefiles: this.state.logoFileArray
      };


      updateRecord(fielddatas).then((value) => {

        this.setState({ recordByIdData: value });
        var version_countarray = [];
        for (var i = 1; i <= value.attributes.version_count; i++) {
          if (i !== value.attributes.version_count) {
            version_countarray.push(i);
          }
        }
        this.setState({ versionselectcounts: version_countarray, });

        if (value) {
          this.setState({ addedclass: 'd-block' });

          setTimeout(
            function () {
              this.setState({ addedclass: 'd-none' });
            }
              .bind(this),
            3000
          );

        } else {
          this.setState({ addedclass: 'd-none' });
        }

        this.resetForm();
      });

    }
  }

  resetForm = () => {
    jQuery('#selectedFiles').html('');
    jQuery('#loadingtag').addClass('d-none');
    jQuery('#loadingtag').removeClass('d-block');


    jQuery('#addbutton').prop('disabled', false);
    jQuery('#addbutton').addClass('d-inline-block');
    jQuery('#loadbutton').addClass('d-none');
    jQuery('#addbutton').removeClass('d-none');
  }

  slidingClick = (event) => {
    jQuery(event.target).parents(".card").find('.card-body').slideToggle('slow');
    jQuery(event.target).toggleClass('active');
  }

  toolTipshow = (event) => {
    jQuery(event.target).next('.tooltipshow').show();
  }
  toolTiphide = (event) => {
    jQuery(event.target).next('.tooltipshow').hide();
  }


  calculateTotalCost = () => {

    var yearly_renew_cost = jQuery('#yearly_renew_cost').val();
    var icann_fee = jQuery('#icann_fee').val();
    var cost_general = jQuery('#cost_general').val();
    var cost_directprivacy = jQuery('#cost_directprivacy').val();
    var other_cost = jQuery('#other_cost').val();
    var direct_privacy = jQuery('#direct_privacy').val();

    if (yearly_renew_cost === "") {
      yearly_renew_cost = 0;
    } else {
      yearly_renew_cost = parseFloat(yearly_renew_cost);
    }
    if (icann_fee === "") {
      icann_fee = 0;
    } else {
      icann_fee = parseFloat(icann_fee);
    }
    if (cost_general === "") {
      cost_general = 0;
    } else {
      cost_general = parseFloat(cost_general);
    }
    if (cost_directprivacy === "") {
      cost_directprivacy = 0;
    } else {
      cost_directprivacy = parseFloat(cost_directprivacy);
    }
    if (other_cost === "") {
      other_cost = 0;
    } else {
      other_cost = parseFloat(other_cost);
    }
    if (direct_privacy === "") {
      direct_privacy = 0;
    } else {
      direct_privacy = parseFloat(direct_privacy);
    }

    var tot = yearly_renew_cost + icann_fee + other_cost + direct_privacy + cost_general + cost_directprivacy;
    jQuery('#totalcost').html(tot.toFixed(2));

  }


  checkValidBySection = (event) => {
    console.log(moment(this.state.recordByIdData.get('cc_statement_date')).format('DD/MM/YYYY'), "data")
    var validatestates = {
      domain: this.state.domain,
      web_category: this.state.web_category,
      registrar: this.state.registrar,
      business_sector: this.state.business_sector,
      alj_entity: this.state.alj_entity,
      geography: this.state.geography,
      domain_status: this.state.domain_status,
    };

    var control = jQuery(event.target).parents(".customAcc.card");
    checkValidBySection(validatestates, control);

  }

  handleDateChange = (date, name) => {
    this.setState(prevState => ({
      recordByIdData: prevState.recordByIdData.set(name, date)
    }));
  };


  render() {
    console.log(this.state.recordByIdData, "recprd")
    var fulldatastate = this.state.datafull;
    var fulldatalist = [];
    if (fulldatastate.length > 0) {
      for (let i = 0; i < fulldatastate.length; i++) {
        fulldatalist.push({ label: fulldatastate[i].get('registrant_email') });
      }
    }

    return <div>

      {this.state.isEdit === true ?
        <div>

          <Form id="addform">
            <Row className="justify-content-between topbuttonbar">


              <div className="col-md-6 col">
                <Alert color="success" className={this.state.addedclass + " mr-auto"}>
                  Record Updated
                </Alert>
              </div>

              <Col className="col-auto mb-4">

                <Link to="/finance/finance/:domainid" className="btn btn-default  mr-3">
                  <span>Back To List</span>
                </Link>
                <Button type="button" color="primary" id="addbutton" className={this.state.saveenabledisable + " mr-3"} onClick={this.updateRecord}>
                  <span className="border-left pl-2"></span>
                  Save
                </Button>


                {/* Loader button */}
                <div className="btn btn-primary mr-3 d-none" id="loadbutton">
                  <Loader type="Oval" color="#414241" height={20} width={20} />
                </div>
                {/* Loader button */}









                <Input type="hidden" id="version_count" defaultValue={this.state.recordByIdData.get('version_count')} />
                <select id="versionselectcount" className={this.state.generalHidden + " custom-select w-auto"} onChange={this.changeVersion}>
                  <option value="">Select</option>
                  <option value="currentversion">Current Version</option>
                  {
                    this.state.versionselectcounts.map((key, i) =>
                      <option key={key} value={key}>{key}</option>
                    )
                  }
                </select>
                <p className={this.state.generalHidden + " requiredtext text-right"} id="versiontext">Current version {this.state.recordByIdData.get('version_count')}</p>















              </Col>
            </Row>

            <Row>
              <Col md="8">
                <Card className="customAcc">
                  <CardTitle onClick={this.slidingClick.bind(this)} className="fieldsouter opendefault active">
                    <i className="icon-circle icon-pencil"></i>
                    Domain Costs
                  </CardTitle>

                  <CardBody style={{display:'block'}}>
                    {
                      this.state.generalHidden !== "" ?
                        <div className="disableuser">&nbsp;</div>
                        :
                        null
                    }


                    <Row>

                      <Input type="hidden" id="hiddenrecordid" defaultValue={this.state.hiddenobjectid} />
                      <Input type="hidden" id="domainid" value={this.state.domainbyidData} />

                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="name">Currency
                          <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <select id="currency" className="custom-select" required defaultValue={this.state.recordByIdData.get('currency')} >
                            <option value="USD">USD</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="name">Yearly Renewal Cost
                          <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <Input type="number" name="yearly_renew_cost" id="yearly_renew_cost" className="dollarinput" onWheel={ event => event.currentTarget.blur() } onChange={this.calculateTotalCost} defaultValue={parseFloat(this.state.recordByIdData.get('yearly_renew_cost')).toFixed(2)} />
                          <span className="dollarclass">$</span>
                        </FormGroup>
                      </Col>
                    </Row>









                    <Row>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="name">Cost *
                          <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <Input type="number" name="cost_general" id="cost_general" className="dollarinput" onWheel={ event => event.currentTarget.blur() } onChange={this.calculateTotalCost} defaultValue={parseFloat(this.state.recordByIdData.get('cost_general')).toFixed(2)} required />
                          <span className="dollarclass">$</span>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="name">Cost (Direct Privacy)
                          <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <Input type="number" name="cost_directprivacy" id="cost_directprivacy" className="dollarinput" onWheel={ event => event.currentTarget.blur() } onChange={this.calculateTotalCost} defaultValue={parseFloat(this.state.recordByIdData.get('cost_directprivacy')).toFixed(2)} />
                          <span className="dollarclass">$</span>
                        </FormGroup>
                      </Col>
                    </Row>










                    <Row>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="name">ICANN fee
                          <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <Input type="number" name="icann_fee" id="icann_fee" className="dollarinput" onWheel={ event => event.currentTarget.blur() } onChange={this.calculateTotalCost} defaultValue={parseFloat(this.state.recordByIdData.get('icann_fee')).toFixed(2)} />
                          <span className="dollarclass">$</span>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="name">Other Cost
                          <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <Input type="number" name="other_cost" id="other_cost" className="dollarinput" onWheel={ event => event.currentTarget.blur() } onChange={this.calculateTotalCost} defaultValue={parseFloat(this.state.recordByIdData.get('other_cost')).toFixed(2)} />
                          <span className="dollarclass">$</span>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="name">Direct Privacy (yearly)
                          <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <Input type="number" name="direct_privacy" id="direct_privacy" className="dollarinput" onWheel={ event => event.currentTarget.blur() } onChange={this.calculateTotalCost} defaultValue={parseFloat(this.state.recordByIdData.get('direct_privacy')).toFixed(2)} />
                          <span className="dollarclass">$</span>
                        </FormGroup>
                      </Col>
                      <Col md="6">

                        <FormGroup className="form-group">
                          <Label htmlFor="name">Other Cost Remarks
                            <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <Input type="textarea" name="remarks" id="remarks" rows="2" defaultValue={this.state.recordByIdData.get('remarks')} />
                        </FormGroup>


                        <FormGroup className="form-group">
                          <Label htmlFor="name">Total Costs: USD <span id="totalcost">
                            {/* {parseFloat(Math.round(this.state.recordByIdData.get('totalcost') * 100) / 100).toFixed(2)} */}
                            {parseFloat(this.state.recordByIdData.get('totalcost')).toFixed(2)}
                            {/* {this.state.recordByIdData.get('totalcost')} */}
                          </span></Label>
                        </FormGroup>
                      </Col>

                      <Col md="12">
                        <FormGroup className="form-group">
                          <Button type="button" color="primary" className={this.state.generalHidden + " float-right"} onClick={this.checkValidBySection.bind(this)}>Validate</Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card className="customAcc">
                  <CardTitle onClick={this.slidingClick.bind(this)} className="fieldsouter">
                    <i className="icon-circle icon-pencil"></i>
                    Finance Details
             </CardTitle>
                  <CardBody>
                    {
                      this.state.generalHidden !== "" ?
                        <div className="disableuser">&nbsp;</div>
                        :
                        null
                    }
                    <Row>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="name">Invoice Number *
                          <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <Input type="text" name="invoice_number" id="invoice_number" required defaultValue={this.state.recordByIdData.get('invoice_number')} />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="name">Account
                          <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <Input type="text" name="account" id="account" defaultValue={this.state.recordByIdData.get('account')} />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="name">Invoice Amount *
                          <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <Input type="number" name="invoice_amount" id="invoice_amount" className="dollarinput" onWheel={ event => event.currentTarget.blur() } required defaultValue={parseFloat(this.state.recordByIdData.get('invoice_amount')).toFixed(2)} />
                          <span className="dollarclass">$</span>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label>Transaction Type *
                          <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <select id="transaction_type" className="form-control custom-select" required defaultValue={this.state.recordByIdData.get('transaction_type')}>
                            <option value="">Select</option>
                            <option value="acquisition">Acquisition</option>
                            <option value="new">New</option>
                            <option value="others">Others</option>
                            <option value="renewal">Renewal</option>
                            <option value="renewal: .com domain">Renewal: .COM Domain</option>
                            <option value="renewal: domain">Renewal: Domain</option>
                            <option value="renewal: hosting plan">Renewal: Hosting Plan</option>
                            <option value="subscription to email">Subscription to Email</option>
                          </select>
                        </FormGroup>
                      </Col>
                      {/* <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="name">Registrant email
                          <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          
                          <div className="autocomwrap">
                            <Autocomplete
                              inputProps={{ className: "form-control", id: "registrant_email" }}
                              getItemValue={(item) => item.label}
                              items={fulldatalist}
                              renderItem={(item, isHighlighted) =>
                                <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                                  {item.label}
                                </div>
                              }
                              value={this.state.registrant_email}
                              onChange={(event) => {
                                this.setState({ registrant_email: event.target.value });
                              }}
                              onSelect={(val) => { this.setState({ registrant_email: val }); }}
                            />
                          </div>


                        </FormGroup>
                      </Col> */}
                    </Row>

                    <Row> 
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="name">Corp. CC Statement *
                          <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <Datetime
                            inputProps={{ placeholder: "DD/MM/YYYY", id: "cc_statement_date", required: "required" }}
                            timeFormat={false}
                            dateFormat="DD/MM/YYYY"
                            value={this.state.recordByIdData.get('cc_statement_date')}
                            onChange={(date) => this.handleDateChange(date, 'cc_statement_date')}
                            closeOnSelect={true}

                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="name">Date of Invoice *
                          <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <Datetime
                            inputProps={{ placeholder: "DD/MM/YYYY", id: "date_of_invoice", required: "required" }}
                            timeFormat={false}
                            dateFormat="DD/MM/YYYY"
                            value={this.state.recordByIdData.get('date_of_invoice')}
                            onChange={(date) => this.handleDateChange(date, 'date_of_invoice')}
                            closeOnSelect={true}

                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>

                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label>Finance Comments
                          <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <Input type="textarea" name="finance_comments" id="finance_comments" rows="2" defaultValue={this.state.recordByIdData.get('finance_comments')} />
                        </FormGroup>
                      </Col>

                      <Col md="12">
                        <FormGroup className="form-group">
                          <Button type="button" color="primary" className={this.state.generalHidden + " float-right"} onClick={this.checkValidBySection.bind(this)}>Validate</Button>
                        </FormGroup>
                      </Col>


                    </Row>

                  </CardBody>
                </Card>

              </Col>



              <Col md="4">

                <Card className="customAcc">
                  <CardTitle onClick={this.slidingClick.bind(this)} className="fieldsouter">
                    <i className="icon-circle icon-pencil"></i>
                    Save Options
              </CardTitle>
                  <CardBody>
                    {
                      this.state.generalHidden !== "" ?
                        <div className="disableuser">&nbsp;</div>
                        :
                        null
                    }
                    <Row>
                      <Col md="12">
                        <FormGroup className="form-group">
                          <Label>Status
                          <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <select id="status" className="custom-select" defaultValue={String(this.state.recordByIdData.get('status'))}>
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                          </select>
                        </FormGroup>


                      </Col>
                    </Row>

                  </CardBody>
                </Card>


                <Card className="customAcc">
                  <CardTitle onClick={this.slidingClick.bind(this)} className="fieldsouter">
                    <i className="icon-circle icon-pencil"></i>
                    Attachments
                  </CardTitle>
                  <CardBody>
                    {
                      this.state.generalHidden !== "" ?
                        <div className="disableuser">&nbsp;</div>
                        :
                        null
                    }
                    <Row>
                      <Col md="12">



                        {/* Images area */}

                        <FileUpload labelText="Upload Files" tooltipText="Hello world!" folderName="contentmaster"
                          isValid={true} inputText="Upload your file here" items={this.state.logoFileArray}
                          name="inputGroupFile01"
                          onDelete={(id) => {
                            var logoArray = this.state.logoFileArray.filter(obj => obj.id != id).map((item, index) => { return { ...item, id: index } });
                            this.setState({ logoFileArray: logoArray }, () => {
                            });
                          }}
                          addItems={(items) => {
                            console.log('addItem', items);
                            this.setState({
                              logoFileArray: [...this.state.logoFileArray, ...items]
                            }, () => {
                              console.log(this.state.logoFileArray);
                            });
                          }}
                          updateItem={(id, filename) => {
                            var objToChange = this.state.logoFileArray.find(x => x.id == id);
                            if (objToChange) {
                              this.setState({
                                logoFileArray: [...this.state.logoFileArray.filter(x => x.id != id),
                                { ...this.state.logoFileArray.find(x => x.id == id), awsFileName: filename }
                                ]

                              }, () => { console.log(this.state.logoFileArray); });
                            }
                          }}
                          showError={(message) => {
                            this.setState({ showAlertMessage: true, alertMessage: message })
                          }}
                        />

                        {/* <InputGroup>
                          <div className="custom-file">
                            <Input type="file" className="custom-file-input" id="inputGroupFile01" onChange={(event) => { this.onLogoFileSelected(event) }} multiple />
                            <label className="custom-file-label" htmlFor="inputGroupFile01">Upload your file here</label>
                          </div>

                        </InputGroup>

                        {
                          this.state.logoFileArray.length > 0 ?
                            this.state.logoFileArray.map((item, index) => {
                              console.log(item)
                              return (
                                <div className="galleryWrap">
                                  <div className="galleryitem" key={item.awsFileName}>
                                    <button type="button" className="deletebutton"
                                      onClick={() => {
                                        this.setState({
                                          logoFileArray: this.state.logoFileArray.filter(obj => obj.awsFileName != item.awsFileName)
                                        });
                                      }}>
                                      <i className="fas fa-trash-alt"></i>
                                    </button>
                                    <div className="imgbx" >
                                      <div>
                                        <a href="javascript:" target="_blank" onClick={async () => {
                                          var res = await getFileUrl('contentmaster', item.awsFileName);
                                          if (res && res.success == 1) {
                                            window.open(res.url);
                                          }
                                          console.log(res);
                                        }}>
                                          <img src={(item.type === "jpg" || item.type === "png" || item.type === "jpeg") ? imageIcon : fileIcon} alt="Gallery Image" width="50" />
                                        </a>
                                      </div>
                                    </div>
                                    <div style={{ color: 'black' }}>{item.name}</div>
                                  </div>
                                </div>
                              )
                            }
                            )
                            : null
                        } */}



                      </Col>
                    </Row>

                  </CardBody>
                </Card>


                {/* <Card className="customAcc">
                  <CardTitle onClick={this.slidingClick.bind(this)} className="fieldsouter">
                    <i className="icon-circle icon-pencil"></i>
                    Versions
                    </CardTitle>
                  <CardBody>
                    <FormGroup className="form-group">
                      <Label htmlFor="exampleEmail12">Current Version {this.state.recordByIdData.get('version_count')}
                        <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                        <span className="tooltipshow">Something about me</span>
                      </Label>

                      <Input type="hidden" id="version_count" defaultValue={this.state.recordByIdData.get('version_count')} />
                      <select id="versionselectcount" className="custom-select" onChange={this.changeVersion}>
                        <option value="">Select</option>
                        <option value="currentversion">Current Version</option>
                        {
                          this.state.versionselectcounts.map((key, i) =>
                            <option key={key} value={key}>{key}</option>
                          )
                        }
                      </select> 
                    </FormGroup>
                  </CardBody>
                </Card> */}



              </Col>







            </Row>


          </Form>


        </div>
        :
        <p></p>
      }

    </div>
  }
}

export default withRouter(editFinance);
