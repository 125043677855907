import jQuery from 'jquery';
var Parse = require('parse');

export const getFullPageCount = async () => {
    var Domains = Parse.Object.extend("Domains");
    var query = new Parse.Query(Domains);
    query.equalTo("status", true);
    query.descending("createdAt");
    const count = await query.count();

    return count;
}


export const getFinanceCountByDomain = async (domainId) => {
    var Finance = Parse.Object.extend("Finance");
    var query = new Parse.Query(Finance);
    query.equalTo("status", true);
    query.equalTo('domain', {
        __type: 'Pointer',
        className: 'Domains',
        objectId: domainId
    });
    const count = await query.count();
    return count;
}

export const getSearchResults = async (searchdatas) => {
    var Domains = Parse.Object.extend("Domains");
    var query = new Parse.Query(Domains);
    query.equalTo("status", true);

    query.include("domain");
    query.include("geography");
    query.include("region");
    query.include("registrar");
    query.include("domain_status");
    query.include("web_category");
    query.include("business_sector");
    query.include("alj_entity");
    query.include("masterbrand");

    query.descending("createdAt");
    if (searchdatas['searchbox'] !== "") {
        query.matches("name", searchdatas['searchbox'], 'i');
    }

    if (searchdatas['registrant'] !== "") {
        query.matches("registrant_name", searchdatas['registrant'], 'i');
    }
    if (searchdatas['contactname'] !== "") {
        query.matches("contact_name", searchdatas['contactname'], 'i');
    }
    if (searchdatas['contactemail'] !== "") {
        query.matches("contact_email", searchdatas['contactemail'], 'i');
    }

    if (searchdatas['domainname'] !== "") {
        query.equalTo('domain', {
            __type: 'Pointer',
            className: 'Domainname',
            objectId: searchdatas['domainname']
        });
    }
    if (searchdatas['geography'] !== "") {
        query.equalTo('geography', {
            __type: 'Pointer',
            className: 'Countries',
            objectId: searchdatas['geography']
        });
    }
    if (searchdatas['registrar'] !== "") {
        query.equalTo('registrar', {
            __type: 'Pointer',
            className: 'Registrar',
            objectId: searchdatas['registrar']
        });
    }
    if (searchdatas['business_sector'] !== "") {
        query.equalTo('business_sector', {
            __type: 'Pointer',
            className: 'BusinessSectors',
            objectId: searchdatas['business_sector']
        });
    }
    if (searchdatas['entity'] !== "") {
        query.equalTo('alj_entity', {
            __type: 'Pointer',
            className: 'ALJCompanies',
            objectId: searchdatas['entity']
        });
    }
    if (searchdatas['masterbrand'] !== "") {
        query.equalTo('masterbrand', {
            __type: 'Pointer',
            className: 'Masterbrands',
            objectId: searchdatas['masterbrand']
        });
    }
    if (searchdatas['webcategory'] !== "") {
        query.equalTo('web_category', {
            __type: 'Pointer',
            className: 'Webcategory',
            objectId: searchdatas['webcategory']
        });
    }


    if (searchdatas['expirydate'] !== "") {

        var st = searchdatas['expirydate'];
        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var expiry_date = new Date(st.replace(pattern, '$3-$2-$1'));

        // var expiry_date = new Date(searchdatas['expirydate']);
        query.greaterThanOrEqualTo("expiry_date", expiry_date);
    }
    if (searchdatas['expirydateend'] !== "") {

        var st = searchdatas['expirydateend'];
        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var expiry_dateend = new Date(st.replace(pattern, '$3-$2-$1'));
        
        // var expiry_dateend = new Date(searchdatas['expirydateend']);
        query.lessThanOrEqualTo("expiry_date", expiry_dateend);
    }


    if (searchdatas['createddate'] !== "") {

        var st = searchdatas['createddate'];
        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var createddate = new Date(st.replace(pattern, '$3-$2-$1'));

        // var createddate = new Date(searchdatas['createddate']);
        query.greaterThanOrEqualTo("created_on_date", createddate);
    }
    if (searchdatas['createddateend'] !== "") {

        var st = searchdatas['createddateend'];
        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var createddateend = new Date(st.replace(pattern, '$3-$2-$1'));

        // var createddateend = new Date(searchdatas['createddateend']);
        query.lessThanOrEqualTo("created_on_date", createddateend);
    }



    if (searchdatas['updateddate'] !== "") {

        var st = searchdatas['updateddate'];
        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var updateddate = new Date(st.replace(pattern, '$3-$2-$1'));

        // var updateddate = new Date(searchdatas['updateddate']);
        query.greaterThanOrEqualTo("updated_date", updateddate);
    }
    if (searchdatas['updateddateend'] !== "") {

        var st = searchdatas['updateddateend'];
        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var updateddateend = new Date(st.replace(pattern, '$3-$2-$1'));

        // var updateddateend = new Date(searchdatas['updateddateend']);
        query.lessThanOrEqualTo("updated_date", updateddateend);
    }



    if (searchdatas['statsrc'] == "true") {
        query.equalTo("status", true);
    } else if (searchdatas['statsrc'] == "false") {
        query.equalTo("status", false);
    }

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });

}

export const getDomain = async (pagenum, dataperpage, sortitem, sortitemorder) => {

    var skipnum = pagenum - 1;
    var perpage = parseInt(dataperpage);

    var Domains = Parse.Object.extend("Domains");
    var query = new Parse.Query(Domains);
    query.equalTo("status", true);

    query.include("domain");
    query.include("geography");
    query.include("region");
    query.include("registrar");
    query.include("domain_status");
    query.include("web_category");
    query.include("business_sector");
    query.include("alj_entity");
    query.include("masterbrand");

    if (sortitem !== "") {
        if (sortitemorder === "ascending") {
            query.ascending(sortitem);
        } else {
            query.descending(sortitem);
        }

    } else {
        query.descending('createdAt');
    }


    query.skip(skipnum * perpage);
    query.limit(perpage);

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}
export const getDomainFull = async () => {
    var Domains = Parse.Object.extend("Domains");
    var query = new Parse.Query(Domains);
    query.equalTo("status", true);
    query.limit(1000);
    query.descending("createdAt");

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}


export const getBusinessSectors = async () => {
    var BusinessSectors = Parse.Object.extend("BusinessSectors");
    var query = new Parse.Query(BusinessSectors);
    query.equalTo("status", true);
    query.limit(1000);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}
export const getMasterbrands = async () => {
    var Masterbrands = Parse.Object.extend("Masterbrands");
    var query = new Parse.Query(Masterbrands);
    query.equalTo("status", true);
    query.limit(1000);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}
export const getDomainStatus = async () => {
    var DomainStatuses = Parse.Object.extend("DomainStatuses");
    var query = new Parse.Query(DomainStatuses);
    query.equalTo("status", true);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}
export const getCountries = async () => {
    var Countries = Parse.Object.extend("Countries");
    var query = new Parse.Query(Countries);
    query.equalTo("status", true);
    query.limit(1000);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}
export const getRegions = async () => {
    var Region = Parse.Object.extend("Region");
    var query = new Parse.Query(Region);
    query.equalTo("status", true);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}
export const getALJCompanies = async () => {
    var ALJCompanies = Parse.Object.extend("ALJCompanies");
    var query = new Parse.Query(ALJCompanies);
    query.equalTo("domain", true);
    query.equalTo("status", true);
    query.limit(1000);
    query.ascending("name");

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}
export const getDomainname = async () => {
    var Domainname = Parse.Object.extend("Domainname");
    var query = new Parse.Query(Domainname);
    query.equalTo("status", true);
    query.limit(1000);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}
export const getWebcategory = async () => {
    var Webcategory = Parse.Object.extend("Webcategory");
    var query = new Parse.Query(Webcategory);
    query.equalTo("status", true);
    query.limit(1000);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}
export const getRegistrar = async () => {
    var Registrar = Parse.Object.extend("Registrar");
    var query = new Parse.Query(Registrar);
    query.equalTo("status", true);
    query.limit(1000);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const getCountriesByRegion = async (regionid) => {
    var Countries = Parse.Object.extend("Countries");
    var query = new Parse.Query(Countries);
    query.equalTo("status", true);


    query.equalTo('region', {
        __type: 'Pointer',
        className: 'Region',
        objectId: regionid
    });


    query.ascending("name");
    query.limit(1000);
    var records = query.find();
    return records;
}


export const hideRecords = async (objectids) => {
    var parseobject = Parse.Object.extend("Domains");
    var query = new Parse.Query(parseobject);
    query.equalTo("objectId", objectids);

    return query.find().then(async (object) => {
        var realbject = object[0];
        realbject.set("status", false);
        return realbject.save().then(async (records) => {


            //Log
            var currentUser = Parse.User.current();
            const Logs = Parse.Object.extend("Logs");
            const log = new Logs();
            log.set("domain", { "__type": "Pointer", "className": "Domains", "objectId": objectids });
            log.set("action", 'archived');
            log.set("user", currentUser);
            let res = await log.save();
            //Log




            return true;
        }, (error) => {
            console.log(error);
        }).catch(function (error) {
        });

    }, (error) => {
    });
}


export const addRecord = async ({ name, domain, web_category, expiry_date, registrar, url, website, created_on_date, redirect, updated_date, business_sector, alj_entity, masterbrand, registrant_name, registrant_email,
    previous_registrant, previous_reg_email, handled_by, contact_name, contact_email, geography, region, domain_status, action, notes, ipr_date_transferred, ipr_transfer_status, register_alj_ipr, transfer_alj_ipr, status }) => {
    var domains = new Parse.Object("Domains");
    domains.set("name", name);

    if (domain) {
        var extentObject = Parse.Object.extend("Domainname");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(domain);
        domains.set("domain", parsepoint);
    }
    // domains.set("domain", domain);

    if (web_category) {
        var extentObject = Parse.Object.extend("Webcategory");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(web_category);
        domains.set("web_category", parsepoint);
    }
    // domains.set("web_category", web_category);

    if (expiry_date) {
        domains.set("expiry_date", expiry_date);
    }

    if (registrar) {
        var extentObject = Parse.Object.extend("Registrar");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(registrar);
        domains.set("registrar", parsepoint);
    }
    // domains.set("registrar", registrar);

    domains.set("url", url);
    domains.set("website", website);
    if (created_on_date) {
        domains.set("created_on_date", created_on_date);
    }

    domains.set("redirect", redirect);
    if (updated_date) {
        domains.set("updated_date", updated_date);
    }

    if (business_sector) {
        var extentObject = Parse.Object.extend("BusinessSectors");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(business_sector);
        domains.set("business_sector", parsepoint);
    }

    // domains.set("alj_entity", alj_entity);
    if (alj_entity) {
        var extentObject = Parse.Object.extend("ALJCompanies");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(alj_entity);
        domains.set("alj_entity", parsepoint);
    }

    if (masterbrand) {
        var extentObject = Parse.Object.extend("Masterbrands");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(masterbrand);
        domains.set("masterbrand", parsepoint);
    }

    domains.set("registrant_name", registrant_name);
    domains.set("registrant_email", registrant_email);
    domains.set("previous_registrant", previous_registrant);
    domains.set("previous_registrant_email", previous_reg_email);
    domains.set("handled_by", handled_by);
    domains.set("contact_name", contact_name);
    domains.set("contact_email", contact_email);
    // domains.set("geography", geography);

    if (geography) {
        var extentObject = Parse.Object.extend("Countries");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(geography);
        domains.set("geography", parsepoint);
    }

    if (region) {
        var extentObject = Parse.Object.extend("Region");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(region);
        domains.set("region", parsepoint);
    }

    if (status === 'false') {
        domains.set("status", false);
    } else {
        domains.set("status", true);
    }

    if (domain_status) {
        var extentObject = Parse.Object.extend("DomainStatuses");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(domain_status);
        domains.set("domain_status", parsepoint);
    }

    domains.set("action", action);
    domains.set("notes", notes);
    if (ipr_date_transferred) {
        domains.set("ipr_date_transferred", ipr_date_transferred);
    }
    domains.set("ipr_transfer_status", ipr_transfer_status);
    domains.set("register_alj_ipr", register_alj_ipr);
    domains.set("transfer_alj_ipr", transfer_alj_ipr);

    domains.set("version_count", 1);




    return domains.save().then(async (records) => {
        var edithiddenid = records.id;

        var version_count = 1;
        addRecordVersion({
            edithiddenid, name, domain, web_category, expiry_date, registrar, url, website, created_on_date, redirect, updated_date, business_sector, alj_entity, masterbrand, registrant_name, registrant_email,
            previous_registrant, previous_reg_email, handled_by, contact_name, contact_email, geography, region, domain_status, action, notes, ipr_date_transferred, ipr_transfer_status, register_alj_ipr, transfer_alj_ipr,status, version_count
        });


        //Log
        var currentUser = Parse.User.current();
        const Logs = Parse.Object.extend("Logs");
        const log = new Logs();
        log.set("domain", { "__type": "Pointer", "className": "Domains", "objectId": edithiddenid });
        log.set("action", 'added');
        log.set("user", currentUser);
        let res = await log.save();
        //Log




        return records;
    }, (error) => {
        console.log(error);
        return false;
    }).catch(function (error) {
        console.log(error);
        return false;
    });
}


export const fetchRecordById = async ({ objectid }) => {

    var Domains = Parse.Object.extend("Domains");
    var query = new Parse.Query(Domains);
    query.equalTo("objectId", objectid);
    query.include("domain");
    query.include("web_category");
    query.include("registrar");
    query.include("business_sector");
    query.include("alj_entity");
    query.include("masterbrand");
    query.include("geography");
    query.include("region");
    query.include("domain_status");
    return query.find().then((records) => {
        return records;
    }, (error) => {
        return false;
    }).catch(function (error) {
        return false;
    });
}

export const fetchRecordByIdVersion = async ({ objectid, versionselectcount }) => {

    var Domains = Parse.Object.extend("Domains_versions");
    var query = new Parse.Query(Domains);
    query.equalTo("domainId", objectid);
    query.equalTo("version_count", parseInt(versionselectcount));
    query.include("domain");
    query.include("web_category");
    query.include("registrar");
    query.include("business_sector");
    query.include("alj_entity");
    query.include("masterbrand");
    query.include("geography");
    query.include("region");
    query.include("domain_status");
    return query.find().then((records) => {
        return records;
    }, (error) => {
        console.log(error);
        return false;
    }).catch(function (error) {
        console.log(error);
        return false;
    });
}


export const updateRecord = async ({ edithiddenid, name, domain, web_category, expiry_date, registrar, url, website, created_on_date, redirect, updated_date, business_sector, alj_entity, masterbrand, registrant_name, registrant_email,
    previous_registrant, previous_reg_email, handled_by, contact_name, contact_email, geography, region, domain_status, action, notes, ipr_date_transferred, ipr_transfer_status, register_alj_ipr, transfer_alj_ipr,status, version_count }) => {

    addRecordVersion({
        edithiddenid, name, domain, web_category, expiry_date, registrar, url, website, created_on_date, redirect, updated_date, business_sector, alj_entity, masterbrand, registrant_name, registrant_email,
        previous_registrant, previous_reg_email, handled_by, contact_name, contact_email, geography, region, domain_status, action, notes, ipr_date_transferred, ipr_transfer_status, register_alj_ipr, transfer_alj_ipr,status, version_count
    });

    var Domains = Parse.Object.extend("Domains");
    var query = new Parse.Query(Domains);
    query.equalTo("objectId", edithiddenid);

    return query.find().then(async (object) => {

        var realbject = object[0];

        realbject.set("name", name);

        if (domain) {
            var extentObject = Parse.Object.extend("Domainname");
            var parseqry = new Parse.Query(extentObject);
            let parsepoint = await parseqry.get(domain);
            realbject.set("domain", parsepoint);
        } else {
            realbject.set("domain", null);
        }
        // realbject.set("domain", domain);

        if (web_category) {
            var extentObject = Parse.Object.extend("Webcategory");
            var parseqry = new Parse.Query(extentObject);
            let parsepoint = await parseqry.get(web_category);
            realbject.set("web_category", parsepoint);
        } else {
            realbject.set("web_category", null);
        }

        if (expiry_date) {
            realbject.set("expiry_date", expiry_date);
        }

        if (registrar) {
            var extentObject = Parse.Object.extend("Registrar");
            var parseqry = new Parse.Query(extentObject);
            let parsepoint = await parseqry.get(registrar);
            realbject.set("registrar", parsepoint);
        } else {
            realbject.set("registrar", null);
        }

        realbject.set("url", url);
        realbject.set("website", website);
        if (created_on_date) {
            realbject.set("created_on_date", created_on_date);
        }

        realbject.set("redirect", redirect);
        if (updated_date) {
            realbject.set("updated_date", updated_date);
        }


        if (business_sector) {
            var extentObject = Parse.Object.extend("BusinessSectors");
            var parseqry = new Parse.Query(extentObject);
            let parsepoint = await parseqry.get(business_sector);
            realbject.set("business_sector", parsepoint);
        } else {
            realbject.set("business_sector", null);
        }

        if (alj_entity) {
            var extentObject = Parse.Object.extend("ALJCompanies");
            var parseqry = new Parse.Query(extentObject);
            let parsepoint = await parseqry.get(alj_entity);
            realbject.set("alj_entity", parsepoint);
        } else {
            realbject.set("alj_entity", null);
        }

        if (masterbrand) {
            var extentObject = Parse.Object.extend("Masterbrands");
            var parseqry = new Parse.Query(extentObject);
            let parsepoint = await parseqry.get(masterbrand);
            realbject.set("masterbrand", parsepoint);
        } else {
            realbject.set("masterbrand", null);
        }

        realbject.set("registrant_name", registrant_name);
        realbject.set("registrant_email", registrant_email);
        realbject.set("previous_registrant", previous_registrant);
        realbject.set("previous_registrant_email", previous_reg_email);
        realbject.set("handled_by", handled_by);
        realbject.set("contact_name", contact_name);
        realbject.set("contact_email", contact_email);
        // realbject.set("geography", geography);
        if (geography) {
            var extentObject = Parse.Object.extend("Countries");
            var parseqry = new Parse.Query(extentObject);
            let parsepoint = await parseqry.get(geography);
            realbject.set("geography", parsepoint);
        } else {
            realbject.set("geography", null);
        }

        if (region) {
            var extentObject = Parse.Object.extend("Region");
            var parseqry = new Parse.Query(extentObject);
            let parsepoint = await parseqry.get(region);
            realbject.set("region", parsepoint);
        } else {
            realbject.set("region", null);
        }

        if (status === 'false') {
            realbject.set("status", false);
        } else {
            realbject.set("status", true);
        }

        if (domain_status) {
            var extentObject = Parse.Object.extend("DomainStatuses");
            var parseqry = new Parse.Query(extentObject);
            let parsepoint = await parseqry.get(domain_status);
            realbject.set("domain_status", parsepoint);
        } else {
            realbject.set("domain_status", null);
        }

        realbject.set("action", action);
        realbject.set("notes", notes);
        if (ipr_date_transferred) {
            realbject.set("ipr_date_transferred", ipr_date_transferred);
        }
        realbject.set("ipr_transfer_status", ipr_transfer_status);
        realbject.set("register_alj_ipr", register_alj_ipr);
        realbject.set("transfer_alj_ipr", transfer_alj_ipr);

        realbject.set("version_count", parseInt(version_count));

        // if (status === 'false') {
        //     realbject.set("status", false);
        // } else {
        //     realbject.set("status", true);
        // }


        return realbject.save().then(async (records) => {


            //Log
            var currentUser = Parse.User.current();

            const Logs = Parse.Object.extend("Logs");
            const log = new Logs();
            log.set("domain", { "__type": "Pointer", "className": "Domains", "objectId": edithiddenid });
            log.set("action", 'updated');
            log.set("user", currentUser);
            let res = await log.save();
            //Log


            return records;
        }, (error) => {
            console.log(error);
            return false;
        }).catch(function (error) {
            console.log(error);
            return false;
        });

    }, (error) => {
        return false;
    });

}


export const addRecordVersion = async ({ edithiddenid, name, domain, web_category, expiry_date, registrar, url, website, created_on_date, redirect, updated_date, business_sector, alj_entity, masterbrand, registrant_name, registrant_email,
    previous_registrant, previous_reg_email, handled_by, contact_name, contact_email, geography, region, domain_status, action, notes, ipr_date_transferred, ipr_transfer_status, register_alj_ipr, transfer_alj_ipr, status, version_count }) => {

    var domains = new Parse.Object("Domains_versions");

    domains.set("domainId", edithiddenid);
    domains.set("name", name);

    if (domain) {
        var extentObject = Parse.Object.extend("Domainname");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(domain);
        domains.set("domain", parsepoint);
    }
    // domains.set("domain", domain);

    if (web_category) {
        var extentObject = Parse.Object.extend("Webcategory");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(web_category);
        domains.set("web_category", parsepoint);
    }
    // domains.set("web_category", web_category);

    if (expiry_date) {
        domains.set("expiry_date", expiry_date);
    }

    if (registrar) {
        var extentObject = Parse.Object.extend("Registrar");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(registrar);
        domains.set("registrar", parsepoint);
    }
    // domains.set("registrar", registrar);

    domains.set("url", url);
    domains.set("website", website);
    if (created_on_date) {
        domains.set("created_on_date", created_on_date);
    }

    domains.set("redirect", redirect);
    if (updated_date) {
        domains.set("updated_date", updated_date);
    }

    if (business_sector) {
        var extentObject = Parse.Object.extend("BusinessSectors");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(business_sector);
        domains.set("business_sector", parsepoint);
    }

    // realbject.set("alj_entity", alj_entity);
    if (alj_entity) {
        var extentObject = Parse.Object.extend("ALJCompanies");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(alj_entity);
        domains.set("alj_entity", parsepoint);
    }

    if (masterbrand) {
        var extentObject = Parse.Object.extend("Masterbrands");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(masterbrand);
        domains.set("masterbrand", parsepoint);
    }

    domains.set("registrant_name", registrant_name);
    domains.set("registrant_email", registrant_email);
    domains.set("previous_registrant", previous_registrant);
    domains.set("previous_registrant_email", previous_reg_email);
    domains.set("handled_by", handled_by);
    domains.set("contact_name", contact_name);
    domains.set("contact_email", contact_email);
    // domains.set("geography", geography);
    if (geography) {
        var extentObject = Parse.Object.extend("Countries");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(geography);
        domains.set("geography", parsepoint);
    }

    if (region) {
        var extentObject = Parse.Object.extend("Region");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(region);
        domains.set("region", parsepoint);
    }

    if (status === 'false') {
        domains.set("status", false);
    } else {
        domains.set("status", true);
    }

    if (domain_status) {
        var extentObject = Parse.Object.extend("DomainStatuses");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(domain_status);
        domains.set("domain_status", parsepoint);
    }

    domains.set("action", action);
    domains.set("notes", notes);
    if (ipr_date_transferred) {
        domains.set("ipr_date_transferred", ipr_date_transferred);
    }
    domains.set("ipr_transfer_status", ipr_transfer_status);
    domains.set("register_alj_ipr", register_alj_ipr);
    domains.set("transfer_alj_ipr", transfer_alj_ipr);

    domains.set("version_count", version_count);




    return domains.save().then((records) => {
        return true;
    }, (error) => {
        console.log(error);
        return false;
    }).catch(function (error) {
        console.log(error);
        return false;
    });
}

export const getHiddenColumns = async () => {
    var currentUser = Parse.User.current();

    var Domains_columns = new Parse.Object("Domains_columns");
    var query = new Parse.Query(Domains_columns);
    if (currentUser) {
        query.equalTo("userObjectID", currentUser.id);
    }

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}
export const saveHiddenColumns = async ({ hiddenitems, userObjectID }) => {

    var Domains_columnsfetch = Parse.Object.extend("Domains_columns");
    var query = new Parse.Query(Domains_columnsfetch);
    query.equalTo("userObjectID", userObjectID);
    query.descending("createdAt");
    const count = await query.count();

    if (count > 0) {
        return query.find().then(async (object) => {
            var realbject = object[0];
            realbject.set("userObjectID", userObjectID);
            realbject.set("hiddenColumns", hiddenitems);

            return realbject.save().then(async (records) => {
                return true;
            }, (error) => {
                return false;
            }).catch(function (error) {
                return false;
            });

        }, (error) => {
            return false;
        });
    } else {
        var Domains_columns = new Parse.Object("Domains_columns");
        Domains_columns.set("userObjectID", userObjectID);
        Domains_columns.set("hiddenColumns", hiddenitems);
        return Domains_columns.save().then((records) => {
            return true;
        }, (error) => {
            return false;
        }).catch(function (error) {
            return false;
        });
    }

}

export const checkFieldValid = (states) => {

    var errstat = true;
    jQuery('.customAcc.card').each(function () {
        var flag = false;
        jQuery(this).find('input, select, .reactSelect.reactSelectClass').each(function () {

            if (jQuery(this).prop('required')) {
                var currelement = jQuery(this);

                if (currelement.val() === "") {
                    currelement.addClass("is-invalid");
                    currelement.parents(".customAcc").find('.fieldsouter').addClass('failed').removeClass('completed');
                    currelement.parents(".customAcc").find('.fieldsouter i').removeClass('fas fa-check').addClass('icon-pencil');

                    errstat = false;
                    flag = true;
                } else {
                    currelement.removeClass("is-invalid");
                    if (flag === false) {
                        currelement.parents(".customAcc").find('.fieldsouter').removeClass('failed').addClass('completed');
                        currelement.parents(".customAcc").find('.fieldsouter i').removeClass('icon-pencil').addClass('fas fa-check');
                    }
                }
            } else if (jQuery(this).hasClass('required')) {
                var currelement = jQuery(this).attr('id');

                if (states[currelement] === undefined) {
                    jQuery('#' + currelement).addClass("is-invalid");
                    jQuery('#' + currelement).parents(".customAcc").find('.fieldsouter').addClass('failed').removeClass('completed');
                    jQuery('#' + currelement).parents(".customAcc").find('.fieldsouter i').removeClass('fas fa-check').addClass('icon-pencil');

                    errstat = false;
                    flag = true;
                } else {
                    jQuery('#' + currelement).removeClass("is-invalid");
                    if (flag === false) {
                        jQuery('#' + currelement).parents(".customAcc").find('.fieldsouter').removeClass('failed').addClass('completed');
                        jQuery('#' + currelement).parents(".customAcc").find('.fieldsouter i').removeClass('icon-pencil').addClass('fas fa-check');
                    }
                }
            }


            //Date validation
            var dateelement = jQuery(this);

            if (dateelement.attr('id') === "expiry_date" || dateelement.attr('id') === "created_on_date" || dateelement.attr('id') === "updated_date" || dateelement.attr('id') === "ipr_date_transferred") {

                var st = dateelement.val();
                var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                var expiry_date = new Date(st.replace(pattern, '$3-$2-$1'));

                if (dateelement.val() !== "") {
                    if (expiry_date.toString() === 'Invalid Date') {
                        dateelement.addClass("is-invalid");
                        dateelement.parents(".customAcc").find('.fieldsouter').addClass('failed').removeClass('completed');
                        dateelement.parents(".customAcc").find('.fieldsouter i').removeClass('fas fa-check').addClass('icon-pencil');

                        errstat = false;
                        flag = true;
                    } else {
                        dateelement.removeClass("is-invalid");
                        if (flag === false) {
                            dateelement.parents(".customAcc").find('.fieldsouter').removeClass('failed').addClass('completed');
                            dateelement.parents(".customAcc").find('.fieldsouter i').removeClass('icon-pencil').addClass('fas fa-check');
                        }
                    }
                }
            }



        });
    });


    if (errstat === false) {
        return false;
    } else {
        return true;
    }

}
export const checkValidBySection = (states, control) => {

    var errstat = true;
    jQuery(control).each(function () {
        var flag = false;
        jQuery(this).find('input, select, .reactSelect.reactSelectClass').each(function () {
            if (jQuery(this).prop('required')) {
                var currelement = jQuery(this);
                if (currelement.val() === "") {
                    currelement.addClass("is-invalid");
                    currelement.parents(".customAcc").find('.fieldsouter').addClass('failed').removeClass('completed');
                    currelement.parents(".customAcc").find('.fieldsouter i').removeClass('fas fa-check').addClass('icon-pencil');

                    errstat = false;
                    flag = true;
                } else {
                    currelement.removeClass("is-invalid");
                    if (flag === false) {
                        currelement.parents(".customAcc").find('.fieldsouter').removeClass('failed').addClass('completed');
                        currelement.parents(".customAcc").find('.fieldsouter i').removeClass('icon-pencil').addClass('fas fa-check');
                    }
                }
            } else if (jQuery(this).hasClass('required')) {
                var currelement = jQuery(this).attr('id');

                if (states[currelement] === undefined) {
                    jQuery('#' + currelement).addClass("is-invalid");
                    jQuery('#' + currelement).parents(".customAcc").find('.fieldsouter').addClass('failed').removeClass('completed');
                    jQuery('#' + currelement).parents(".customAcc").find('.fieldsouter i').removeClass('fas fa-check').addClass('icon-pencil');

                    errstat = false;
                    flag = true;
                } else {
                    jQuery('#' + currelement).removeClass("is-invalid");
                    if (flag === false) {
                        jQuery('#' + currelement).parents(".customAcc").find('.fieldsouter').removeClass('failed').addClass('completed');
                        jQuery('#' + currelement).parents(".customAcc").find('.fieldsouter i').removeClass('icon-pencil').addClass('fas fa-check');
                    }
                }
            }


            //Date validation
            var dateelement = jQuery(this);

            if (dateelement.attr('id') === "expiry_date" || dateelement.attr('id') === "created_on_date" || dateelement.attr('id') === "updated_date") {

                var st = dateelement.val();
                var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
                var expiry_date = new Date(st.replace(pattern, '$3-$2-$1'));

                if (dateelement.val() !== "") {
                    if (expiry_date.toString() === 'Invalid Date') {
                        dateelement.addClass("is-invalid");
                        dateelement.parents(".customAcc").find('.fieldsouter').addClass('failed').removeClass('completed');
                        dateelement.parents(".customAcc").find('.fieldsouter i').removeClass('fas fa-check').addClass('icon-pencil');

                        errstat = false;
                        flag = true;
                    } else {
                        dateelement.removeClass("is-invalid");
                        if (flag === false) {
                            dateelement.parents(".customAcc").find('.fieldsouter').removeClass('failed').addClass('completed');
                            dateelement.parents(".customAcc").find('.fieldsouter i').removeClass('icon-pencil').addClass('fas fa-check');
                        }
                    }
                }
            }
        });
    });


    if (errstat === false) {
        return false;
    } else {
        return true;
    }

}



export const getDomainsForExport = async () => {
    var Domains = Parse.Object.extend("Domains");
    var query = new Parse.Query(Domains);
    query.descending("createdAt");
    query.limit(1500);

    query.include("domain");
    query.include("geography");
    query.include("region");
    query.include("registrar");
    query.include("domain_status");
    query.include("web_category");
    query.include("business_sector");
    query.include("alj_entity");
    query.include("masterbrand");
    
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}