import jQuery from 'jquery';
var Parse = require('parse');

export const getCountries = async () => {
    var Countries = Parse.Object.extend("Countries");
    var query = new Parse.Query(Countries);
    query.equalTo("status", true);
    query.ascending("name");
    query.limit(500);
    const results = await query.find();
    if (results.length > 0) {
        var optionArr = [];
        for (let i = 0; i < results.length; i++) {
            optionArr[i] = { value: results[i].id, label: results[i].attributes.name }
        }
        return optionArr;
    }
    else {
        return null;
    }
}


export const getJurisdictions = async () => {
    var Jurisdictions = Parse.Object.extend("Jurisdictions");
    var query = new Parse.Query(Jurisdictions);
    query.equalTo("status", true);
    query.ascending("name");
    const results = await query.find();
    if (results.length > 0) {
        var optionArr = [];
        for (let i = 0; i < results.length; i++) {
            optionArr[i] = { value: results[i].id, label: results[i].attributes.name }
        }
        return optionArr;
    }
    else {
        return null;
    }
}

export const getMasterBrandDivisions = async () => {
    var Masterbrands = Parse.Object.extend("Masterbrands");
    var query = new Parse.Query(Masterbrands);
    query.equalTo("status", true);
    query.ascending("name");
    const results = await query.find();
    if (results.length > 0) {
        var optionArr = [];
        for (let i = 0; i < results.length; i++) {
            optionArr[i] = { value: results[i].id, label: results[i].attributes.name }
        }
        return optionArr;
    }
    else {
        return null;
    }
}

export const getLawFirms = async () => {
    var LawFirms = Parse.Object.extend("LawFirms");
    var query = new Parse.Query(LawFirms);
    query.equalTo("status", true);
    query.ascending("name");
    const results = await query.find();
    if (results.length > 0) {
        var optionArr = [];
        for (let i = 0; i < results.length; i++) {
            optionArr[i] = { value: results[i].id, label: results[i].attributes.name, email: results[i].attributes.contact_email }
        }
        return optionArr;
    }
    else {
        return null;
    }
}

export const getBusinessUnits = async () => {
    var BusinessUnits = Parse.Object.extend("BusinessUnits");
    var query = new Parse.Query(BusinessUnits);
    query.equalTo("status", true);
    query.ascending("name");
    const results = await query.find();
    if (results.length > 0) {
        var optionArr = [];
        for (let i = 0; i < results.length; i++) {
            optionArr[i] = { value: results[i].id, label: results[i].attributes.name }
        }
        return optionArr;
    }
    else {
        return null;
    }
}

export const getClassifications = async () => {
    var Classifications = Parse.Object.extend("Classifications");
    var query = new Parse.Query(Classifications);
    query.equalTo("status", true);
    query.ascending("sortOrder");
    const results = await query.find();
    if (results.length > 0) {
        var optionArr = [];
        for (let i = 0; i < results.length; i++) {
            optionArr[i] = { value: results[i].id, label: results[i].attributes.name + ' ' + results[i].attributes.category, name: results[i].attributes.name }
        }
        return optionArr;
    }
    else {
        return null;
    }

}

export const getAljCompanies = async () => {
    var ALJCompanies = Parse.Object.extend("ALJCompanies");
    var query = new Parse.Query(ALJCompanies);
    query.equalTo("status", true);
    query.equalTo("trademark", true);
    query.ascending("name");
    const results = await query.find();
    if (results.length > 0) {
        var optionArr = [];
        for (let i = 0; i < results.length; i++) {
            optionArr[i] = { value: results[i].id, label: results[i].attributes.name }
        }
        return optionArr;
    }
    else {
        return null;
    }

}

export const getCommercialContact = async () => {
    var TrademarksMaster = Parse.Object.extend("TrademarksMaster");
    var query = new Parse.Query(TrademarksMaster);
    query.ascending("contact");
    //query.distinct("contact");
    const results = await query.find();
    if (results.length > 0) {
        var optionArr = [];
        for (let i = 0; i < results.length; i++) {
            if (!optionArr.includes(results[i].attributes.contact)) {
                optionArr[i] = results[i].attributes.contact
            }
        }
        return optionArr;
    }
    else {
        return ['test', 'newtest'];
    }

}


export const getTrademarkVersions = async (id) => {

    var TrademarksMaster = Parse.Object.extend("TrademarksMaster");
    var query = new Parse.Query(TrademarksMaster);
    query.equalTo("objectId", id);
    // query.include("BusinessSectors");
    var result = await query.find();

    var TrademarkVersions = Parse.Object.extend("TrademarkVersions");
    var querynew = new Parse.Query(TrademarkVersions);
    querynew.equalTo("trademark", result[0]);
    querynew.descending("version_count");
    querynew.skip(1);
    // query.include("BusinessSectors");
    var results = await querynew.find();
    if (results.length > 0) {
        var optionArr = [];
        for (let i = 0; i < results.length; i++) {
            optionArr[i] = results[i].attributes.version_count
        }
        return optionArr;
    }
    else {
        return [];
    }

}

export const getTrademarkVersionObjectById = async (id, version) => {
    var TrademarksMaster = Parse.Object.extend("TrademarksMaster");
    var query = new Parse.Query(TrademarksMaster);
    query.equalTo("objectId", id);
    // query.include("BusinessSectors");
    var result = await query.find();

    var TrademarkVersions = Parse.Object.extend("TrademarkVersions");
    var querynew = new Parse.Query(TrademarkVersions);
    querynew.equalTo("trademark", result[0]);
    querynew.equalTo("version_count", parseInt(version));
    // query.include("BusinessSectors");
    var results = await querynew.find();
    //console.log('getTrademarkVersionObjectById', results);
    if (results) {
        return results[0];
    }
    else {
        return [];
    }

}
export const saveTrademarkRequest = async (obj) => {
    var saveObj = await obj.save();
    //var updateVersionObj = await updateTrademarkVersion(saveObj);
    return saveObj;
}

export const getTrademarkObjectById = async (id) => {

    var TrademarksMaster = Parse.Object.extend("TrademarksMaster");
    var query = new Parse.Query(TrademarksMaster);
    query.equalTo("objectId", id);
    query.include('user')
    // query.include("BusinessSectors");
    var result = await query.find();
    // console.log('getTrademarkObjectById', result);
    if (result)
        return result[0];

}

export const updateTrademarkVersion = async (obj) => {
    try {
        const TrademarkVersions = Parse.Object.extend('TrademarkVersions');
        let myNewObject = new TrademarkVersions();

        myNewObject.set('trademark', obj);
        myNewObject.set('name', obj.attributes.name);
        myNewObject.set('language', obj.attributes.language);
        myNewObject.set('type', obj.attributes.type);
        myNewObject.set('twRefNumber', obj.attributes.twRefNumber);
        myNewObject.set('AGIPRefNumber', obj.attributes.AGIPRefNumber);
        myNewObject.set('transferToAGIP', obj.attributes.transferToAGIP);
        myNewObject.set('masterbrand', obj.attributes.masterbrand);
        myNewObject.set('contact', obj.attributes.contact);
        myNewObject.set("version_count", obj.attributes.version_count);
        myNewObject.set("notes", obj.attributes.notes);
        myNewObject.set('logoFiles', obj.attributes.logoFiles);
        //myNewObject.set('assetFiles', obj.attributes.assetFiles);
        myNewObject.set('wordmarkText', obj.attributes.wordmarkText);
        myNewObject.set("recordstatus", obj.attributes.recordstatus); 

        myNewObject.set('owner', obj.attributes.owner);
        //  myNewObject.set('businessUnit', obj.attributes.businessUnit);       
        myNewObject.set("countries", obj.attributes.countries);
        myNewObject.set("trademarkClasses", obj.attributes.trademarkClasses);
        myNewObject.set('status', obj.attributes.status);
        myNewObject.set('lawFirm', obj.attributes.lawFirm);
        myNewObject.set("jurisdictions", obj.attributes.jurisdictions);
        myNewObject.set("masterBrandDivison", obj.attributes.masterBrandDivison);

        var saveObj = await myNewObject.save();
    }
    catch (ex) {
        console.log('updateTrademarkVersion', ex);
    }
}

export const convertStateObjToParseObj = async (mainData) => {

    try {
        const TrademarksMaster = Parse.Object.extend('TrademarksMaster');
        let myNewObject = new TrademarksMaster();
        if (mainData.id) {
            //var query = new Parse.Query(TrademarksMaster);
            //myNewObject = await query.get(mainData.id);
            myNewObject.set('objectId', mainData.id);
        }

        myNewObject.set('name', mainData.trademarkName);
        myNewObject.set('language', mainData.language);
        myNewObject.set('type', mainData.type);
        myNewObject.set('twRefNumber', mainData.twRefNo);
        myNewObject.set('AGIPRefNumber', mainData.agipRefNo);
        myNewObject.set('transferToAGIP', mainData.transferAGIP);
        myNewObject.set('masterbrand', mainData.masterbrand);
        myNewObject.set('contact', mainData.contact);
        myNewObject.set("version_count", mainData.versionCount + 1);
        myNewObject.set("notes", mainData.notes);
        myNewObject.set('logoFiles', mainData.logoFileArray);
        //  myNewObject.set('assetFiles', mainData.assetsFileArray);  
        myNewObject.set('wordmarkText', mainData.wordmarkText);

        if (mainData.recordstatus === 'false') {
            myNewObject.set("recordstatus", false);
        } else {
            myNewObject.set("recordstatus", true);
        }


        if (mainData.owner) {
            var extentObject = Parse.Object.extend("ALJCompanies");
            var parseqry = new Parse.Query(extentObject);
            let parsepoint = await parseqry.get(mainData.owner);
            myNewObject.set('owner', parsepoint);
        }
        if (mainData.masterBrandDivision) {
            var extentObject = Parse.Object.extend("Masterbrands");
            var parseqry = new Parse.Query(extentObject);
            let parsepoint = await parseqry.get(mainData.masterBrandDivision);
            myNewObject.set('masterBrandDivison', parsepoint);
        }

        
        // if (mainData.businessUnit) {
        //     var extentObject = Parse.Object.extend("BusinessUnits");
        //     var parseqry = new Parse.Query(extentObject);
        //     let parsepoint = await parseqry.get(mainData.businessUnit);
        //     myNewObject.set('businessUnit', parsepoint);
        // }
        if (mainData.countries.length > 0) {
            // console.log(mainData.countries.length);
            var countries = [];
            for (var i = 0; i < mainData.countries.length; ++i) {
                var Countries = Parse.Object.extend("Countries");
                var compQuery = new Parse.Query(Countries);
                let comppoint = await compQuery.get(mainData.countries[i]);
                countries.push(comppoint);
            }
            myNewObject.set("countries", countries);
        }
        if (mainData.classifications.length > 0) {
            var classifications = [];
            for (var i = 0; i < mainData.classifications.length; ++i) {
                var Countries = Parse.Object.extend("Classifications");
                var compQuery = new Parse.Query(Countries);
                let comppoint = await compQuery.get(mainData.classifications[i]);
                classifications.push(comppoint);
            }
            myNewObject.set("trademarkClasses", classifications);
        }

        var extentObject = Parse.Object.extend("TrademarkStatuses");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(mainData.status);
        myNewObject.set('status', parsepoint);

        if (mainData.lawFirm) {
            var extentObject = Parse.Object.extend("LawFirms");
            var parseqry = new Parse.Query(extentObject);
            let parsepoint = await parseqry.get(mainData.lawFirm);
            myNewObject.set('lawFirm', parsepoint);
        }
        if (mainData.jurisdictions.length > 0) {
            var jurisdictions = [];
            for (var i = 0; i < mainData.jurisdictions.length; ++i) {
                var Countries = Parse.Object.extend("Jurisdictions");
                var compQuery = new Parse.Query(Countries);
                let comppoint = await compQuery.get(mainData.jurisdictions[i]);
                jurisdictions.push(comppoint);
            }
            myNewObject.set("jurisdictions", jurisdictions);
        }
        //console.log(myNewObject);
        return myNewObject;
    }
    catch (ex) {
        return ex;
    }
}


export const convertParseObjTOStateObj = async (obj) => {

    try {
        var mainData = {};
        mainData.id = obj.id;
        mainData.trademarkName = obj.attributes.name;
        mainData.language = obj.attributes.language;
        mainData.type = obj.attributes.type;
        mainData.twRefNo = obj.attributes.twRefNumber;
        mainData.agipRefNo = obj.attributes.AGIPRefNumber;
        mainData.transferAGIP = obj.attributes.transferToAGIP;
        mainData.contact = obj.attributes.contact;
        mainData.notes = obj.attributes.notes;
        mainData.logoFileArray = obj.attributes.logoFiles;
        mainData.masterbrand = obj.attributes.masterbrand;
        //  mainData.assetsFileArray = obj.attributes.assetFiles;
        mainData.wordmarkText = obj.attributes.wordmarkText;
        mainData.recordstatus = obj.attributes.recordstatus;
        mainData.versionCount = obj.attributes.version_count;
        if (obj.attributes.owner) {
            mainData.owner = obj.attributes.owner.id;
        }
        if (obj.attributes.lawFirm) {
            mainData.lawFirm = obj.attributes.lawFirm.id;
        }
        if (obj.attributes.masterBrandDivison) {
            mainData.masterBrandDivision = obj.attributes.masterBrandDivison.id;
        }
        // if(obj.attributes.businessUnit)
        // {
        // mainData.businessUnit = obj.attributes.businessUnit.id;
        // }
        if (obj.attributes.status) {
            mainData.status = obj.attributes.status.id;
        }
        if (obj.attributes.countries && obj.attributes.countries.length > 0) {
            mainData.countries = obj.attributes.countries.map(item => item.id)
        }
        else {
            mainData.countries = [];
        }
        if (obj.attributes.jurisdictions && obj.attributes.jurisdictions.length > 0) {
            mainData.jurisdictions = obj.attributes.jurisdictions.map(item => item.id)
        }
        else {
            mainData.jurisdictions = [];
        }
        if (obj.attributes.trademarkClasses && obj.attributes.trademarkClasses.length > 0) {
            mainData.classifications = obj.attributes.trademarkClasses.map(item => item.id)
        }
        else
        {
            mainData.classifications = [];
        }
        //  console.log(mainData);

        return mainData;
    }
    catch (ex) {
        return ex;
    }
}

export const importTrademarks = async (data) => {
    var result = await Parse.Cloud.run('ImportTrademarks', { data: data });
    console.log(result);
}

export const importDomains = async (data) => {
    var result = await Parse.Cloud.run('ImportDomains', { data: data });
    console.log(result);
}

export const addLog = async (id, action) => {
    const Logs = Parse.Object.extend("Logs");
    const log = new Logs();

    log.set("trademark", { "__type": "Pointer", "className": "TrademarksMaster", "objectId": id });
    log.set("action", action);
    log.set("user", Parse.User.current());
    let res = await log.save();
}

//Trademark Listing
export const getFullPageCount = async () => {
    var TrademarksMaster = Parse.Object.extend("TrademarksMaster");
    var query = new Parse.Query(TrademarksMaster);
    query.descending("createdAt");
    const count = await query.count();

    return count;
}
export const getTrademarksList = async (pagenum, dataperpage, sortitem, sortitemorder) => {
    var skipnum = pagenum - 1;
    var perpage = parseInt(dataperpage);

    var TrademarksMaster = Parse.Object.extend("TrademarksMaster");
    var query = new Parse.Query(TrademarksMaster);
    query.include("status");
    query.include("owner");
    query.include("jurisdictions");
    query.include("countries");
    query.include("trademarkClasses");
    query.include("lawFirm");
    query.include("TrademarkStatuses");




    var cuser = await currentUser();
    if (cuser) {
        if (cuser[0].get('role').get('name') === "External") {
            if (cuser[0].get('lawfirm')) {
                var lawfirmpoint = cuser[0].get('lawfirm').id;
                query.equalTo('lawFirm', {
                    __type: 'Pointer',
                    className: 'LawFirms',
                    objectId: lawfirmpoint
                });
            } else {
                query.equalTo('lawFirm', {
                    __type: 'Pointer',
                    className: 'LawFirms',
                    objectId: '0000'
                });
            }
        }
    }





    if (sortitem !== "") {
        if (sortitemorder === "ascending") {
            query.ascending(sortitem);
        } else {
            query.descending(sortitem);
        }

    } else {
        query.descending('createdAt');
    }

    query.skip(skipnum * perpage);
    query.limit(perpage);




    var records = query.find()
    return records;
}

export const currentUser = async () => {
    var currentUser = Parse.User.current();
    if (currentUser) {
        var User = Parse.Object.extend("User");
        var query = new Parse.Query(User);
        query.include("role");
        query.include("lawfirm");
        query.equalTo("objectId", currentUser.id);

        var userdet = query.find();
        return userdet;
    }
}

export const getSearchResults = async (searchdatas) => {

    var TrademarksMaster = Parse.Object.extend("TrademarksMaster");
    var query = new Parse.Query(TrademarksMaster);
    query.include("status");
    query.include("owner");
    query.include("jurisdictions");
    query.include("countries");
    query.include("trademarkClasses");
    query.include("lawFirm");
    query.include("TrademarkStatuses");

    query.descending("createdAt");

    if (searchdatas['name'] !== "") {
        query.matches("name", searchdatas['name'], 'i');
    }
    if (searchdatas['objectid'] !== "") {
        query.matches("objectId", searchdatas['objectid'], 'i');
    }
    if (searchdatas['applicationNumber'] !== "") {
        query.matches("applicationNumber", searchdatas['applicationNumber'], 'i');
    }
    if (searchdatas['registrationNumber'] !== "") {
        query.matches("registrationNumber", searchdatas['registrationNumber'], 'i');
    }
    if (searchdatas['type'] !== "") {
        query.matches("type", searchdatas['type'], 'i');
    }
    if (searchdatas['language'] !== "") {
        query.matches("language", searchdatas['language'], 'i');
    }
    if (searchdatas['searchbox'] !== "") {
        query.matches("twRefNumber", searchdatas['searchbox'], 'i');
    }
    if (searchdatas['agipref'] !== "") {
        query.matches("AGIPRefNumber", searchdatas['agipref'], 'i');
    }
    if (searchdatas['stepstatsrc'] !== "") {
        query.matches("stepStatus", searchdatas['stepstatsrc'], 'i');
    }

    if (searchdatas['registrationdate'] !== "") {

        var st = searchdatas['registrationdate'];
        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var registrationdate = new Date(st.replace(pattern, '$3-$2-$1'));

        // var registrationdate = new Date(searchdatas['registrationdate']);
        query.greaterThanOrEqualTo("registrationDate", registrationdate);
    }
    if (searchdatas['registrationdateend'] !== "") {

        var st = searchdatas['registrationdateend'];
        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var registrationdateend = new Date(st.replace(pattern, '$3-$2-$1'));

        // var registrationdateend = new Date(searchdatas['registrationdateend']);
        query.lessThanOrEqualTo("registrationDate", registrationdateend);
    }

    if (searchdatas['applicationDate'] !== "") {

        var st = searchdatas['applicationDate'];
        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var registrationdate = new Date(st.replace(pattern, '$3-$2-$1'));

        // var registrationdate = new Date(searchdatas['registrationdate']);
        query.greaterThanOrEqualTo("applicationDate", registrationdate);
    }
    if (searchdatas['applicationDateend'] !== "") {

        var st = searchdatas['applicationDateend'];
        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var registrationdateend = new Date(st.replace(pattern, '$3-$2-$1'));

        // var registrationdateend = new Date(searchdatas['registrationdateend']);
        query.lessThanOrEqualTo("applicationDate", registrationdateend);
    }


    //tholhath's code for renewal date filter
    // if (searchdatas['renewaldate'] !== "") {
    //     var st = searchdatas['renewaldate'];
    //     var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
    //     var renewaldate = new Date(st.replace(pattern, '$3-$2-$1'));

    //     // var renewaldate = new Date(searchdatas['renewaldate']);
    //     query.lessThanOrEqualTo("nextRenewalDate", renewaldate);
    // }
    // if (searchdatas['renewaldateend'] !== "") {
    //     var st = searchdatas['renewaldateend'];
    //     var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
    //     var renewaldateend = new Date(st.replace(pattern, '$3-$2-$1'));

    //     // var renewaldateend = new Date(searchdatas['renewaldateend']);
    //     query.lessThanOrEqualTo("registrationDate", renewaldateend);
    // }

    //zohair's code for renewal date filter
    if (searchdatas['renewaldate'] !== "") {
        var st = searchdatas['renewaldate'];
        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var renewaldate = new Date(st.replace(pattern, '$3-$2-$1'));

        // var renewaldate = new Date(searchdatas['renewaldate']);
        query.greaterThanOrEqualTo("nextRenewalDate", renewaldate);
    }
    if (searchdatas['renewaldateend'] !== "") {
        var st = searchdatas['renewaldateend'];
        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var renewaldateend = new Date(st.replace(pattern, '$3-$2-$1'));

        // var renewaldateend = new Date(searchdatas['renewaldateend']);
        query.lessThanOrEqualTo("nextRenewalDate", renewaldateend);
    }

    if (searchdatas['jurisdiction'] !== "") {
        query.equalTo('jurisdictions', {
            __type: 'Pointer',
            className: 'Jurisdictions',
            objectId: searchdatas['jurisdiction']
        });
    }

    if (searchdatas['country'] !== "") {
        var cntryarr = [];
        var array = searchdatas['country'].split(",");
        for (var i = 0; i < array.length; i++) {
            cntryarr.push({
                __type: 'Pointer',
                className: 'Countries',
                objectId: array[i]
            });
        }
        query.containedIn('countries', cntryarr);
    }




    if (searchdatas['lawfirm'] !== "") {
        var lawarr = [];
        var array = searchdatas['lawfirm'].split(",");
        for (var i = 0; i < array.length; i++) {
            lawarr.push({
                __type: 'Pointer',
                className: 'LawFirms',
                objectId: array[i]
            });
        }
        query.containedIn('lawFirm', lawarr);
    }

    if (searchdatas['statsrc'] !== "") {
        var statarr = [];
        var array = searchdatas['statsrc'].split(",");
        for (var i = 0; i < array.length; i++) {
            statarr.push({
                __type: 'Pointer',
                className: 'TrademarkStatuses',
                objectId: array[i]
            });
        }
        query.containedIn('step', statarr)
    }




    if (searchdatas['owner'] !== "") {
        query.equalTo('owner', {
            __type: 'Pointer',
            className: 'ALJCompanies',
            objectId: searchdatas['owner']
        });
    }


    if (searchdatas['classsrc'] !== "") {
        var classarr = [];
        var array = searchdatas['classsrc'].split(",");
        for (var i = 0; i < array.length; i++) {
            classarr.push({
                __type: 'Pointer',
                className: 'Classifications',
                objectId: array[i]
            });
        }
        query.containedIn('trademarkClasses', classarr);
    }


    if (searchdatas['recordstatsrc'] == "true") {
        query.equalTo("recordstatus", true);
    } else if (searchdatas['recordstatsrc'] == "false") {
        query.equalTo("recordstatus", false);
    }

    var cuser = await currentUser();
    if (cuser) {
        if (cuser[0].get('role').get('name') === "External") {
            if (cuser[0].get('lawfirm')) {
                var lawfirmpoint = cuser[0].get('lawfirm').id;
                query.equalTo('lawFirm', {
                    __type: 'Pointer',
                    className: 'LawFirms',
                    objectId: lawfirmpoint
                });
            }
        }
    }

    var records = query.find()
    return records;

}
export const hideRecords = async (objectids) => {
    var parseobject = Parse.Object.extend("TrademarksMaster");
    var query = new Parse.Query(parseobject);
    query.equalTo("objectId", objectids);
    return query.find().then((object) => {
        var realbject = object[0];
        realbject.set("status", false);
        return realbject.save().then((records) => {
            return true;
        }, (error) => {
            console.log(error);
        }).catch(function (error) {
        });

    }, (error) => {
    });
}
export const getJurisdictionsList = async () => {
    var Jurisdictions = Parse.Object.extend("Jurisdictions");
    var query = new Parse.Query(Jurisdictions);
    query.equalTo("status", true);
    query.ascending("name");
    var records = query.find()
    return records;
}
export const getCountriesList = async () => {
    var Countries = Parse.Object.extend("Countries");
    var query = new Parse.Query(Countries);
    query.equalTo("status", true);
    query.ascending("name");
    query.limit(1000);
    var records = query.find();
    return records;
}
export const getCountriesByJuris = async (jurisid) => {
    var Countries = Parse.Object.extend("Countries");
    var query = new Parse.Query(Countries);
    query.equalTo("status", true);
    
 
    query.equalTo('jurisdiction', {
        __type: 'Pointer',
        className: 'Jurisdictions',
        objectId: jurisid
    });


    query.ascending("name");
    query.limit(1000);
    var records = query.find();
    return records;
}
export const getLawfirmList = async () => {
    var LawFirms = Parse.Object.extend("LawFirms");
    var query = new Parse.Query(LawFirms);
    query.equalTo("status", true);
    query.limit(1000);
    query.ascending("name");
    var records = query.find();
    return records;
}
export const getTrademarkStatuses = async () => {
    var TrademarkStatuses = Parse.Object.extend("TrademarkStatuses");
    var query = new Parse.Query(TrademarkStatuses);
    query.equalTo("status", true);
    query.limit(1000);
    query.ascending("name");
    var records = query.find()
    return records;
}
export const getALJCompaniesList = async () => {
    var ALJCompanies = Parse.Object.extend("ALJCompanies");
    var query = new Parse.Query(ALJCompanies);
    query.equalTo("status", true);
    query.limit(1000);
    query.ascending("name");
    var records = query.find()
    return records;
}
export const getClasses = async () => {
    var Classifications = Parse.Object.extend("Classifications");
    var query = new Parse.Query(Classifications);
    query.equalTo("status", true);
    query.limit(1000);
    query.ascending("sortOrder");
    var records = query.find()
    return records;
}

export const getHiddenColumns = async () => {
    var currentUser = Parse.User.current();

    var TrademarkColumns = new Parse.Object("TrademarkColumns");
    var query = new Parse.Query(TrademarkColumns);
    if (currentUser) {
        query.equalTo("userObjectID", currentUser.id);
    }

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}
export const saveHiddenColumns = async ({ hiddenitems, userObjectID }) => {

    var TrademarkColumnsfetch = Parse.Object.extend("TrademarkColumns");
    var query = new Parse.Query(TrademarkColumnsfetch);
    query.equalTo("userObjectID", userObjectID);
    query.descending("createdAt");
    const count = await query.count();

    if (count > 0) { 
        return query.find().then(async (object) => {
            var realbject = object[0];
            realbject.set("userObjectID", userObjectID);
            realbject.set("hiddenColumns", hiddenitems);

            return realbject.save().then(async (records) => {
                return true;
            }, (error) => {
                return false;
            }).catch(function (error) {
                return false;
            });

        }, (error) => {
            return false;
        });
    } else {
        var TrademarkColumns = new Parse.Object("TrademarkColumns");
        TrademarkColumns.set("userObjectID", userObjectID);
        TrademarkColumns.set("hiddenColumns", hiddenitems);
        return TrademarkColumns.save().then((records) => {
            return true;
        }, (error) => {
            return false;
        }).catch(function (error) {
            return false;
        });
    }

}

export const getStatusNameFromValue = async(val)=>{
 switch(val){
    case "abandoned":
        return "Abandoned";
        break;
    case "awaitingCertificateOfRegistration":
        return "Awaiting Certificate Of Registration";
        break;
    case "conditionalAcceptance":
        return "Conditional Acceptance";
        break;
    case "inProgress":
        return "In Progress";
        break;
    case "lapsed":
        return "Lapsed";
        break;
    case "petitionUnderExamination":
        return "Petition Under Examination";
        break;
    case "registrationFeesPaid":
        return "Registration Fees Paid";
        break;
    case "rejected":
        return "Rejected";
        break;
    case "requestRecieved":
        return "Request Received";
        break;
    case "statusUnknown":
        return "Status Unknown";
        break;
    case "withdrawn":
        return "Withdrawn";
        break;
    default:
        return "";
    
 }   
}


export const getTrademarksForExport = async () => {
    var TrademarksMaster = Parse.Object.extend("TrademarksMaster");
    var query = new Parse.Query(TrademarksMaster);
    
    query.descending("createdAt");
    query.limit(1500);

    query.include("status");
    query.include("owner");
    query.include("jurisdictions");
    query.include("countries");
    query.include("trademarkClasses");
    query.include("lawFirm");
    query.include("TrademarkStatuses");
    
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}