import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormFeedback,
  CustomInput,
  Tooltip,
  UncontrolledTooltip
} from 'reactstrap';
import Select, { components } from "react-select";
import jQuery from 'jquery';

import { getCurrentUser, authenticateLoggedIn, addHeaderTitle, isSuperAdmin, authenticateLoginpage, addBreadcrumb } from '../../services/parse';
import { addRecord, checkFieldValid, getCountries } from '../../services/domainname';

var Parse = require('parse');


class addDomainname extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      collapse: true,
      fadeIn: true,
      timeout: 300,
      isSuper: '',
      addedclass: 'd-none',
      // countryData: [],
      isEdit: false,
      logofile: null,
      rewardsfile: null,
      loginlogofile: null,
    };
  }


  async componentDidMount(evt) {
    //auth start
    authenticateLoggedIn();
    addHeaderTitle('Add Domain Name');
    addBreadcrumb('Home / Domain Name / Add Domain Name');

    // getCountries().then((value) => {
    //   this.setState({ countryData: value, });
    // });

  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  handleChange(event) {
    if (!event.target.files) return;
    var selDiv = jQuery('#selectedFiles');
    selDiv.html('');

    var files = event.target.files;
    for (var i = 0; i < files.length; i++) {
      var f = files[i];
      selDiv.append(f.name + "<br/>");
    }
  }


  addRecord = () => {

    var valid = checkFieldValid('add');
    if (valid !== false) {
      jQuery('#addbutton').prop('disabled', true);

      var name = jQuery('#bname').val();
      var description = jQuery('#bdescription').val();
      var status = jQuery('#status').val();







      // var multipleimage = jQuery('#multipleimage');
      // var files = multipleimage.prop('files');
      // var imagefiles = [];
      // if (files.length > 0) {
      //   for (var i = 0; i < files.length; i++) {
      //     var galleryfile = files[i];
      //     var parseFile = new Parse.File("assets.png", galleryfile);
      //     if (parseFile) {
      //       parseFile.save();
      //       imagefiles[i] = parseFile;
      //     }
      //   }

      //   jQuery('#loadingtag').removeClass('d-none');
      //   jQuery('#loadingtag').addClass('d-block');
      // }






      addRecord({ name: name, description: description, status: status }).then((value) => {
        if (value !== false) {
          this.setState({ addedclass: 'd-block' });

          setTimeout(
            function () {
              this.setState({ addedclass: 'd-none' });
              window.location.assign('#/domainname/domainname/' + value.id);
            }
              .bind(this),
            1000
          );

        } else {
          this.setState({ addedclass: 'd-none' });
        }

        jQuery('#addform').trigger("reset");
        jQuery('#addbutton').prop('disabled', false);
      });

    }
  }





  render() {
    return <div>

      <Row className="justify-content-between topbuttonbar">
        <Col className="col-md-5">
          <Alert color="success" className={this.state.addedclass}>
            Record Added
                  <Link to="/domainname/domainname" className="float-right">
              &nbsp; Go to list
                  </Link>
          </Alert>
        </Col>
        <Col className="col-auto mb-4">
          <Link to="/domainname/domainname" className="btn btn-primary btncancle mr-3">
            <span className="border-left pl-2">Cancel</span>
          </Link>
          <Button type="button" color="primary" id="addbutton" onClick={this.addRecord}>
            <span className="border-left pl-2"></span>
            Save
          </Button>
        </Col>
      </Row>

      <Row>

        <Col md="5">


          <Card>
            <CardTitle>
              <i className="icon-circle icon-pencil"></i>
              Add Domain Name
            </CardTitle>

            <CardBody>





              <Form id="addform">

                <FormGroup className="form-group">
                  <Label htmlFor="name">Name </Label>
                  <Input type="text" name="bname" id="bname" />
                </FormGroup>

                <FormGroup className="form-group">
                  <Label htmlFor="exampleEmail12">Status</Label>
                  <select id="status" className="custom-select">
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                  </select>
                </FormGroup>

                <FormGroup className="form-group">
                  <Label htmlFor="description">Description</Label>
                  <Input type="textarea" name="bdescription" id="bdescription" rows="5" placeholder="Add your comments here..." />
                </FormGroup>



              </Form>
            </CardBody>
          </Card>

        </Col>
      </Row>

    </div>
  }
}

export default addDomainname;
