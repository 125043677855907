var Parse = require('parse');

export const getMasterbrandOnlyCompleted = async()=>{    
    
    var TrademarkStatuses = Parse.Object.extend("TrademarkStatuses");
    var q = new Parse.Query(TrademarkStatuses);
    q.equalTo("objectId", 'wLJe98HUcP');
    var trademarkStatus = await q.find();

    var TrademarkProgress = Parse.Object.extend("TrademarkProgress");
    var query = new Parse.Query(TrademarkProgress);
    query.equalTo("trademarkStatus",trademarkStatus[0]);
    query.equalTo("processStatus","completed");
   // query.distinct("trademark");
    query.include("trademark");
    query.include("trademark.jurisdictions");
    query.include("trademark.countries");
    query.limit(1000);
    
    var TrademarksMaster = Parse.Object.extend("TrademarksMaster");
    var innerQuery = new Parse.Query(TrademarksMaster);
    innerQuery.equalTo("masterbrand",true);
    query.matchesQuery("trademark", innerQuery);
    
    const results = await query.find();
    return results;
}

export const getMasterbrandOnlyPending = async(completedTrademark)=>{
    var TrademarksMaster = Parse.Object.extend("TrademarksMaster");
    var query = new Parse.Query(TrademarksMaster);       
    query.include("jurisdictions");
    query.include("countries");
    query.equalTo("masterbrand",true);
    query.limit(1000);
    if(completedTrademark)
    {
        query.notContainedIn('objectId',completedTrademark);
    }
    const results = await query.find();
    return results;
}


export const getAllCompleted = async()=>{    
    
    var TrademarkStatuses = Parse.Object.extend("TrademarkStatuses");
    var q = new Parse.Query(TrademarkStatuses);
    q.equalTo("objectId", 'wLJe98HUcP');
    var trademarkStatus = await q.find();

    var TrademarkProgress = Parse.Object.extend("TrademarkProgress");
    var query = new Parse.Query(TrademarkProgress);
    query.equalTo("trademarkStatus",trademarkStatus[0]);
    query.equalTo("processStatus","completed");
   // query.distinct("trademark");
    query.include("trademark");
    query.include("trademark.jurisdictions");
    query.include("trademark.countries");    
    query.limit(1000);
  
    const results = await query.find();
    return results;
}

export const getAllPending = async(completedTrademark)=>{
    var TrademarksMaster = Parse.Object.extend("TrademarksMaster");
    var query = new Parse.Query(TrademarksMaster);       
    query.include("jurisdictions");
    query.include("countries");
    query.limit(1000);
    if(completedTrademark)
    {
        query.notContainedIn('objectId',completedTrademark);
    }
    const results = await query.find();
    return results;
}

export const getAllCountryCodesByJurisdictions=async()=>{
    try{
    var Jurisdictions = Parse.Object.extend("Jurisdictions");
    var query = new Parse.Query(Jurisdictions);
    query.equalTo("status",true);
    const results = await query.find();
    var mainArray = [];
    if(results.length>0)
    {
        for(let i=0;i<results.length;i++)
        {
            var jurdObj = results[i];
            var countrylist = [];
            var Countries = Parse.Object.extend("Countries");
            var query = new Parse.Query(Countries);
            query.equalTo("status",true);
            query.containedIn("jurisdiction",[jurdObj])
            query.limit(1000);
            const resultsnew = await query.find();
            ////console.log(results.id,results);
            countrylist = resultsnew.filter(obj=>obj.attributes.country_code.replace(/\s/g, "").length==2).map(x=>x.attributes.country_code.replace(/\s/g, ""))
            mainArray[results[i].id]=countrylist
        }
       return mainArray;
    }
}
catch(er)
{
    //console.log(er)
}
    
}