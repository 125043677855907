import React from 'react';
import {Card,CardBody,CardTitle,Row,Col,Form,FormGroup,Label,Input,Button,InputGroup,UncontrolledTooltip,Alert} from 'reactstrap'; 
import { colourOptions } from '../form-layouts/data';
import Select, { components } from "react-select";
import defaultuser from '../../assets/images/icons/default-user.png';
import Autosuggest from 'react-autosuggest';
import jQuery from 'jquery';
import  {addBreadcrumb,addHeaderTitle } from '../../services/parse';
import { getCountries ,getJurisdictions ,getLawFirms ,getBusinessUnits,getClassifications,currentUser,
        getCommercialContact,getAljCompanies,saveTrademarkRequest,convertStateObjToParseObj,getTrademarkObjectById,convertParseObjTOStateObj,
        getTrademarkVersions,getTrademarkVersionObjectById,updateTrademarkVersion,addLog,getMasterBrandDivisions} from '../../services/trademark';
import Loader from '../../components/common/Loader';   
import Autocomplete from 'react-autocomplete';     
import {uploadFile,getFileUrl} from '../../services/awsFileUpload';
import fileIcon from '../../assets/images/icons/file-icon.png';
import imageIcon from '../../assets/images/icons/image-icon.png';
import { toUnicode } from 'punycode';
import {LOGO_UPLOAD_SIZE} from '../../config/constant';
import { Prompt } from "react-router-dom";
import PageLeavePromt from '../../components/common/PageLeavePromt';
import {sendEmail,sendTrademarkEmail} from '../../services/email';
import FileUpload from '../../components/common/FileUpload';
import { isAdmin } from '../../services/user';
var _ = require('lodash');



/*------------------------------------*/
/* For Select Plugin - (3)            */
/*------------------------------------*/
const CustomClearText = () => 'clear all';
const ClearIndicator = (props) => {
  const { children = <CustomClearText />, getStyles, innerProps: { ref, ...restInnerProps } } = props;
  return (
    <div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props)}>
      <div style={{ padding: '0px 5px' }}>
        {children}
      </div>
    </div>
  );
};

const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: 'pointer',
  color: state.isFocused ? 'blue' : 'black',
});




class addNewTradeMark extends React.Component {
  constructor(props) {
    super(props);
    this.toggleDropDown = this.toggleDropDown.bind(this);
    this.toggleDropDown1 = this.toggleDropDown1.bind(this);
    this.toggle = this.toggle.bind(this);
    this.state = {
        mainLoading:true,
        tdLoading:false,
        lfLoading:false,
        ndLoading:false,
      dropdownOpen: false,
      dropdownOpen1: false,
      currentUserDetails: "",
      files : [],
      showErrorAlert : false,
      showAlertMessage : false,
      alertMessage:'',
      showVersionText:false,
      currentVersion:'',
      tdClassName:null,
      lfClassName:null,
      ndClassName:null,
      tdIcon:'icon-pencil',
      lfIcon:'icon-pencil',
      ndIcon:'icon-pencil',
      otherLangDisabled:true,
      showwordmarkText:false,
      classOptions:null,
      businessUnitOptions:null,
      jurisdictionsOptions:null,
      countriesOptions:null,
      lawFirmsOption:null,
      ownerOptions:null,
      masterBrandDivisionsOptions:null,
      commercialContactSuggestion:[{name:'test'}],
      value: '',
      suggestions: [],
      versionCount:[],
      showSaveButton:true,
      isBlocking:false,
      fileInputValid:true,
      showEmailSendLoader:false,
      fileUploadMandatory:false,
      mainData:{
          id:'',
          trademarkName:'',
          language:'',
          classifications:[],
          businessUnit:'',
          type:[],
          contact:'',
          jurisdictions:[],
          countries:[],
          owner:'',
          lawFirm:'',
          twRefNo:'',
          agipRefNo:'',
          transferAGIP:"false",
          notes:'',
          status:'8kFCwxwALu',
          logoFileArray:[],
         // assetsFileArray:[],
          versionCount:0,
          wordmarkText:'',
          masterbrand:false,
          masterBrandDivision:'',
          recordstatus:'',
      },
      initData:{},
      generalHidden:"",
      editmode:false
      
    };
      
    }

    componentWillMount= async()=>{
      var isadminval = await isAdmin();
      if (isadminval) {
        var currentrole = isadminval[0].get('role').get('name');
        console.log(currentrole);
        this.setState({ currentRole: currentrole });
        if ( currentrole === "Finance") {
          this.setState({ editmode: false});
          if(this.props.id)
          {  
            addBreadcrumb('Home / Trademarks ');
            addHeaderTitle('Trademarks');
          }
        }
        else
        {
          this.setState({ editmode: true});
          if(this.props.id)
      {  
        addBreadcrumb('Home / Trademarks / Edit Trademarks');
        addHeaderTitle('Edit Trademarks');
      }else{
        addBreadcrumb('Home / Trademarks / Add Trademarks');
        addHeaderTitle('Add Trademarks');
      }
        }
      }
      jQuery(".opendefault").next('.card-body').show();
      jQuery(".opendefault").addClass('active');

        var cuser = await currentUser(); 
        if (cuser) { 
            this.setState({currentUserDetails:cuser[0]}); 
            if (cuser[0].get('role').get('name') === "External") {  
               this.setState({ generalHidden: 'd-none' }); 
            }
        }

      //  //console.log('param id',this.props.id);
      if(this.props.id)
      { 
          if(this.props.save)
          {
              this.setState({showAlertMessage:true,alertMessage:'Record has been successfully saved.'},async()=>{
                await this.loadTrademarkData(this.props.id,null);
                var versionOptions = await getTrademarkVersions(this.props.id);
                this.setState({versionCount:versionOptions})
              })
          }
          else
          {
            await this.loadTrademarkData(this.props.id,null);
            var versionOptions = await getTrademarkVersions(this.props.id);
            this.setState({versionCount:versionOptions})
          } 
          
      }
        var classOptions = await getClassifications();
       // var businessUnitOptions = await getBusinessUnits();
        var commercialContactSuggestion = await getCommercialContact();
        var jurisdictionsOptions = await getJurisdictions();
        var countriesOptions = await getCountries();
        var lawFirmsOption = await getLawFirms();
        var ownerOptions = await getAljCompanies();
        var masterBrandDivisionsOptions = await getMasterBrandDivisions();
        this.setState({...this.state,classOptions:classOptions,
           // businessUnitOptions:businessUnitOptions,
            commercialContactSuggestion:commercialContactSuggestion,
            jurisdictionsOptions:jurisdictionsOptions,countriesOptions:countriesOptions,lawFirmsOption:lawFirmsOption,ownerOptions:ownerOptions,
            getTrademarkVersions,initData:this.state.mainData,mainLoading:false,masterBrandDivisionsOptions:masterBrandDivisionsOptions});

            
            
    }

    loadTrademarkData = async(id,version)=>{
        //console.log(id,version)
        if(id && version)
        {
            var obj = await getTrademarkVersionObjectById(id,version);
        }
        else
        {
            var obj = await getTrademarkObjectById(id);
        }
        var convertedObj = await convertParseObjTOStateObj(obj);
          
        console.log('loadTrademarkData',convertedObj);
        //console.log('loadTrademarkData',convertedObj.versionCount);
        
       
          this.setState({mainData:{...this.state.mainData,...convertedObj}},()=>{
              //console.log('componentWillMount',this.state.mainData);
           this.state.editmode?
              addHeaderTitle('Edit Trademarks ('+this.state.mainData.trademarkName+')'):
              addHeaderTitle('Trademarks ('+this.state.mainData.trademarkName+')');



              jQuery("input[name=tdType][value='" + this.state.mainData.type + "']").prop('checked', true);
                if(this.state.mainData.language!='English' && this.state.mainData.language != 'Arabic' && this.state.mainData.language !='Both')
                {
                    this.setState({otherLangDisabled:false});
                    jQuery("#lauguage").val(this.state.mainData.language);
                    jQuery("input[name=lang][value='Others']").prop('checked', true);
                }
                else
                {
                    jQuery("input[name=lang][value='" + this.state.mainData.language + "']").prop('checked', true);
                }
                if(this.state.mainData.transferAGIP=="true")
                {
                    jQuery("#inlineCheckbox9").prop('checked', true);
                }
                this.setState({showwordmarkText:this.state.mainData.type.includes("Wordmark"),fileUploadMandatory:this.state.mainData.type.filter(x=>x!="Wordmark").length>0})
                
                
          });
    }
    toggle() {
      this.setState({
        tooltipOpen: !this.state.tooltipOpen
      });
    }
 

  toggleDropDown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  toggleDropDown1() {
    this.setState({
      dropdownOpen1: !this.state.dropdownOpen1
    });
  }

  async componentDidMount(evt) {
    
      //console.log(this.state.mainLoading);
    jQuery(".opendefault").next('.card-body').show();
    jQuery(".opendefault").addClass('active');


    


    
  }
  


  slidingClick = (event) => {
    jQuery(event.target).parents(".card").find('.card-body').slideToggle('slow');
    jQuery(event.target).toggleClass('active');
  }

  otherLangClicked = (event)=>{
    if(event.target.value!='Others')
    {
        this.setState({mainData:{...this.state.mainData,language:event.target.value},otherLangDisabled:!jQuery("#inlineCheckbox4").is(':checked')},()=>{this.isDataChanged();})
    }
    else
    {
        this.setState({otherLangDisabled:!jQuery("#inlineCheckbox4").is(':checked')},()=>{this.isDataChanged();})
    }
  }

  onLogoFileSelected = async(event)=>{
    //console.log(event.target.files);
    var files = event.target.files
    if(files.length>0)
    {
        for(let i=0;i<files.length;i++)
        {
            if(files[i].size<LOGO_UPLOAD_SIZE)
            {            
                var fileType = files[i].type.split("/")[1];
                var fileName = files[i].name;
                //console.log(fileType);
                var uploadStatus = await uploadFile('trademarks',files[i]);
                //console.log(uploadStatus);
                if(uploadStatus.success)
                {
                    this.setState({
                        mainData:{
                            ...this.state.mainData,
                            logoFileArray:[
                                ...this.state.mainData.logoFileArray,
                                {awsFileName:uploadStatus.filename,type:fileType,name:fileName}
                            ]
                        }
                    },()=>{this.isDataChanged();});
                }
            }
            else
            {
                alert('File '+files[i].name+' exceeds maximum file size of 30MB' )
            }
        }
    }
      //var logoUpload = await uploadFile('trademark',event.target.value);
  }

//   onAssetFilesSelected = async(event)=>{
//     //console.log(event.target.files);
//     var files = event.target.files
//     if(files.length>0)
//     {
//         for(let i=0;i<files.length;i++)
//         {
//             var fileType = files[i].type.split("/")[1];
//             var fileName = files[i].name;
//             //console.log(fileType);
//             var uploadStatus = await uploadFile('trademarks',files[i]);
//             //console.log(uploadStatus);
//             if(uploadStatus.success)
//             {
//                 this.setState({
//                     mainData:{
//                         ...this.state.mainData,
//                         assetsFileArray:[
//                             ...this.state.mainData.assetsFileArray,
//                             {awsFileName:uploadStatus.filename,type:fileType,name:fileName}
//                         ]
//                     }
//                 });
//             }

            
//         }
//     }
//   }

  trademarkClicked = (event)=>{
      console.log(event.target.checked);
      var cbValue = event.target.value;
      var cbChecked = event.target.checked;
      if(cbChecked)
      {
          if(!this.state.mainData.type.includes(cbValue))
          {
            this.setState({mainData:{...this.state.mainData,type:[...this.state.mainData.type,cbValue]}},()=>{this.isDataChanged(); this.toggleWordmarkText();})
          }
      }
      else
      {
          var newArr = this.state.mainData.type.filter(x=>x!=cbValue);
          this.setState({mainData:{...this.state.mainData,type:newArr}},()=>{this.isDataChanged(); this.toggleWordmarkText();})
      }
    // if(event.target.value!='Wordmark')
    // {
    //     this.setState({mainData:{...this.state.mainData,type:event.target.value,wordmarkText:''},showwordmarkText:false},()=>{this.isDataChanged();})
    // }
    // else
    // {
    //     this.setState({mainData:{...this.state.mainData,type:event.target.value},showwordmarkText:true},()=>{this.isDataChanged();})
    // }  
  }

  toggleWordmarkText = ()=>{
        if(this.state.mainData.type.includes("Wordmark"))
        {
            this.setState({showwordmarkText:true,fileUploadMandatory:this.state.mainData.type.filter(x=>x!="Wordmark").length>0})
        }
        else
        {
            this.setState({mainData:{...this.state.mainData,wordmarkText:''},showwordmarkText:false,fileUploadMandatory:this.state.mainData.type.filter(x=>x!="Wordmark").length>0})
        }
  }
  
  
//validations code starts here

//validate trademark detail section
validateTDSection = async()=>{
    this.setState({tdLoading:true,fileInputValid:true})
    var valid = true;
    var fileValid = true;
    var mainData = this.state.mainData;
    jQuery("#tdForm input").removeClass('is-invalid');
    jQuery("#tdForm .reactSelect").removeClass('is-invalid');
    jQuery("#tdForm .greybgcheck").removeClass('is-invalid');
    if(mainData.trademarkName=='')
    {
        valid = false;
        jQuery('#trademarkname').addClass('is-invalid');
    }
    if(mainData.language=='')
    {
        valid = false;
        jQuery("#formLang").addClass('is-invalid');
        if(jQuery("#inlineCheckbox4").is(':checked'))
        {
            jQuery("#lauguage").addClass('is-invalid');
        }
    }
    if(mainData.classifications.length==0)
    {
        valid = false;
        jQuery(".reactSelectClass").addClass('is-invalid');        
    }
    // if(mainData.businessUnit=='')
    // {
    //     valid = false;
    //     jQuery(".reactSelectBU").addClass('is-invalid');        
    // }
    if(mainData.type.length==0)
    {
        valid = false;
        jQuery("#formType").addClass('is-invalid');        
    }
    if(this.state.showwordmarkText)
    {
        //console.log('wordmarkText',this.state.mainData.wordmarkText);
        if(this.state.mainData.wordmarkText=='')
        {
            valid = false;
            jQuery("#formType").addClass('is-invalid');  
            jQuery("#wordmarktext").addClass('is-invalid'); 
        }     
    }
    if(this.state.fileUploadMandatory)
    {        
        if(mainData.logoFileArray.length==0)
        {
            valid = false;
            fileValid = false;
            //jQuery("#inputGroupFile01").addClass('is-invalid');fileInputValid
        }
    }
    // if(mainData.contact=='')
    // {
    //     valid = false;
    //     jQuery(".autoComplete").addClass('is-invalid');        
    // }
    if(mainData.jurisdictions.length==0 && mainData.countries.length==0)
    {
        valid = false;
        jQuery(".reactSelectJUD").addClass('is-invalid');
        jQuery(".reactSelectCNT").addClass('is-invalid');
    }if(mainData.owner=='')
    {
        valid = false;
        jQuery(".reactSelectOWN").addClass('is-invalid');
    }
    // if(mainData.assetsFileArray.length==0)
    // {
    //     valid = false;
    //     jQuery("#inputGroupFile02").addClass('is-invalid');
    // }

    if(valid)
    {
        this.setState({tdClassName:"completed",tdIcon:"fas fa-check",tdLoading:false})        
    }
    else
    {
        this.setState({tdClassName:"failed",tdIcon:"fas fa-exclamation",tdLoading:false,fileInputValid:fileValid})
    }
    return valid;
}

//validate law firm section
validateLFSection = async()=>{
    this.setState({lfLoading:true})
    var valid = true;
    var mainData = this.state.mainData;
    jQuery("#lfForm input").removeClass('is-invalid');
    jQuery("#lfForm .reactSelect").removeClass('is-invalid');
    if(mainData.lawFirm=='')
    {
        valid = false;
        jQuery('.reactSelectLF').addClass('is-invalid');
    }
    // if(mainData.twRefNo=='' && mainData.agipRefNo=='')
    // {
    //     valid = false;
    //     console.log(jQuery("#lfForm #twreference"));
    //     jQuery("#lfForm #twreference").addClass('is-invalid');
    //     jQuery("#lfForm  #agipreference").addClass('is-invalid'); 
    // }
    // if()
    // {
    //     valid = false;
    //     jQuery("#agipreference").addClass('is-invalid');        
    // }
     

    if(valid)
    {
        this.setState({lfClassName:"completed",lfIcon:"fas fa-check",lfLoading:false})
    }
    else
    {
        this.setState({lfClassName:"failed",lfIcon:"fas fa-exclamation",lfLoading:false})
    }
    return valid;
}

//validate notes
validateNDSection = async()=>{
    this.setState({ndLoading:true})
    var valid = true;
    var mainData = this.state.mainData;
    jQuery("input").removeClass('is-invalid');
    jQuery(".reactSelect").removeClass('is-invalid');
    if(mainData.notes=='')
    {
        valid = false;
        jQuery('#msgnote').addClass('is-invalid');
    }
     

    if(valid)
    {
        this.setState({ndClassName:"completed",ndIcon:"fas fa-check",ndLoading:false})
    }
    else
    {
        this.setState({ndClassName:"failed",ndIcon:"fas fa-exclamation",ndLoading:false})
    }
    return valid;
}
//validation code ends here

submitData = async() => { 
    this.setState({mainLoading:true,showAlertMessage:false})
    var validTD = await this.validateTDSection();
    var validLF = await this.validateLFSection();
    if(validLF && validTD)
    {
        //alert('valid');
        var tempObj = await convertStateObjToParseObj(this.state.mainData);
        //console.log(tempObj);
        if(tempObj)
        {
            var subObj = await saveTrademarkRequest(tempObj);
            if(subObj.attributes.version_count==1)
            {
                var lawFirm = this.state.lawFirmsOption.filter(x=>x.value==this.state.mainData.lawFirm)[0];
                if(lawFirm && lawFirm.value)
                {
                    //sendEmail(lawFirm.email,"Subject Here",'NewTrademarkLawFirmEmailNotification',{value1:'a',value2:'b',value3:'c'})
                    sendTrademarkEmail(lawFirm.value,subObj.id,subObj.attributes.name,lawFirm.value=="hTVNSpg7ZR"?subObj.attributes.AGIPRefNumber:subObj.attributes.twRefNumber);
                }
                addLog(subObj.id,'added');
            }
            else
            {
                addLog(subObj.id,'updated');
            }
            var updateVersionObj = await updateTrademarkVersion(subObj);
            
            this.setState({mainLoading:false,showAlertMessage:true,alertMessage:'Record has been successfully updated.'},async()=>{
                if(this.props.id)
                {
                   await this.loadTrademarkData(this.props.id,null)
                    var versionOptions = await getTrademarkVersions(this.props.id);
                    this.setState({versionCount:versionOptions})
                 
                }
                else
                {
                    this.setState({isBlocking:false},()=>{

                        window.location.assign('#/trademark/edittrademark/'+subObj.id+'/saved');
                    })
                }
            })
            
            
        }
    }   
    else
    {
        this.setState({showErrorAlert:true,mainLoading:false});
    }
    
}

isDataChanged = async()=>{
if(_.isEqual(this.state.mainData,this.state.initData))
    {
        ////console.log('shouldLeave',true);
        //return true;
        this.setState({isBlocking:false})
    }
    else
    {
       // //console.log('shouldLeave',false);
        //return false;
        this.setState({isBlocking:true})
    }
}

    render() {
     
      return <div>
              {/*--------------------------------------------------------------------------------*/}
              {/* Start Inner Div*/}
              {/*--------------------------------------------------------------------------------*/}
              <PageLeavePromt status={this.state.isBlocking} message="Your changes are not saved, would you like to proceed?"/>
              
            <Row className="justify-content-between topbuttonbar">

            <Col className="col-md-6">
            {this.state.showAlertMessage?
            <Alert color="success" className="d-block">
            {this.state.alertMessage}
            <span onClick={()=>{this.setState({showAlertMessage:false})}} className="float-right">X</span>
            </Alert>:null}
            </Col>
            
            <Col className="col-auto mb-4">
            {
            this.state.editmode && this.state.showSaveButton?
            this.state.mainLoading? <div className="btn btn-primary ml-3">
            <Loader type="Oval" color="#414241" height={20} width={20} />
            </div>
            : 
              <a href="javascript:" className={this.state.generalHidden+" btn btn-primary ml-3 addbutton"} onClick={()=>{this.submitData()}}> <span className="border-left pl-2">Save</span></a>
             
            :null
            }
            
            
            
            {/* {(this.props.id && this.state.lawFirmsOption && this.state.mainData.lawFirm!='')?
                this.state.lawFirmsOption.filter(x=>(x.value==this.state.mainData.lawFirm && x.email!='')).length>0?
                    this.state.lawFirmsOption.filter(x=>x.value==this.state.mainData.lawFirm).map(item=>{return(
                        this.state.showEmailSendLoader?
                        <a href="javascript:" className="btn btn-primary ml-3"><Loader type="Oval" color="#414241" height={20} width={20} /></a>:
                            <a href="javascript:" className="btn btn-primary ml-3" onClick={async()=>{
                                this.setState({showEmailSendLoader:true},async()=>{
                                    //var mailSent = await sendEmail(item.email,"Subject Here",'NewTrademarkLawFirmEmailNotification',{value1:'a',value2:'b',value3:'c'})
                                    var mailSent = await sendTrademarkEmail(item.value,this.props.id);
                                    ////console.log('mailsent',mailSent);
                                    this.setState({showAlertMessage:true,alertMessage:mailSent.message,showEmailSendLoader:false})
                                })                                
                            }}> <span><i className="fas fa-envelope mr-1"></i> Resend Email</span></a>
                       
                    )})
                :null
            :null} */}

            {(this.state.editmode && this.props.id && this.state.lawFirmsOption && this.state.mainData.lawFirm!='' )?
                                    this.state.showEmailSendLoader?
                                    <a href="javascript:" className="btn btn-primary ml-3"><Loader type="Oval" color="#414241" height={20} width={20} /></a>:
                                        <a href="javascript:" className={this.state.generalHidden+" btn btn-primary ml-3"} onClick={async()=>{
                                            this.setState({showEmailSendLoader:true},async()=>{
                                                //var mailSent = await sendEmail(item.email,"Subject Here",'NewTrademarkLawFirmEmailNotification',{value1:'a',value2:'b',value3:'c'})
                                                 var mailSent = await sendTrademarkEmail(this.state.mainData.lawFirm,this.props.id,this.state.mainData.name,
                                                    this.state.mainData.lawFirm=="hTVNSpg7ZR"?this.state.mainData.agipRefNo:this.state.mainData.twRefNo );
                                                // ////console.log('mailsent',mailSent);
                                                 this.setState({showAlertMessage:true,alertMessage:mailSent.message,showEmailSendLoader:false})
                                            })                                
                                        }}> <span><i className="fas fa-envelope mr-1"></i> Resend Email</span></a>    
                        :null}

            {(this.props.id && this.state.versionCount.length>0)?
               <select className="custom-select w-auto ml-3" id="ddlVersionCount" name="ddlVersionCount" onChange={async(event)=>{
                   if(event.target.value=='latestVersion')
                   {
                      await this.loadTrademarkData(this.props.id,null);   
                      var classOptions = await getClassifications();
                      this.setState({showSaveButton:true,classOptions:classOptions,showVersionText:true, tdClassName:null,
                        lfClassName:null,
                        ndClassName:null,
                        tdIcon:'icon-pencil',
                        lfIcon:'icon-pencil',
                        ndIcon:'icon-pencil',},()=>{jQuery("input").removeClass('is-invalid');
                        jQuery(".reactSelect").removeClass('is-invalid');
                        jQuery(".greybgcheck").removeClass('is-invalid');})                 
                   }
                   else
                   {
                    await this.loadTrademarkData(this.props.id,event.target.value);  
                    var classOptions = await getClassifications();
                    this.setState({showSaveButton:false,classOptions:classOptions,showVersionText:true, tdClassName:null,
                        lfClassName:null,
                        ndClassName:null,
                        tdIcon:'icon-pencil',
                        lfIcon:'icon-pencil',
                        ndIcon:'icon-pencil',},()=>{jQuery("input").removeClass('is-invalid');
                        jQuery(".reactSelect").removeClass('is-invalid');
                        jQuery(".greybgcheck").removeClass('is-invalid');})   
                    }
               }}>
               <option value="latestVersion">Latest Version</option>
               {this.state.versionCount.map((item,index)=>{return(
                   <option key={item} value={item}>{item}</option>
               )}
               )}
             </select>
                :null}
            {/* <a href="/#/trademark/list" className="btn btn-primary btncancle mr-3"> <span className="border-left pl-2">Cancel</span></a> */}

            {(this.props.id && this.state.showVersionText)?
                   <p className="requiredtext text-right">
                   {jQuery('#ddlVersionCount').val()=="latestVersion"?"Currently viewing latest version":"Currently viewing version "+jQuery('#ddlVersionCount').val()}
                   </p>
            :null}
            </Col>
            </Row>

              <Row>
                {/*--------------------------------------------------------------------------------*/}
                {/* Ordinary Form                                                                  */}
                {/*--------------------------------------------------------------------------------*/}
                <Col md="12">
                
                {this.state.showErrorAlert?
                <Alert className="customerror">
                        <h4 className="alert-heading">Please fill in the required information</h4>
                        {/* <p className="mb-0">
                          Please <a href="" className="alert-link">choose a section</a> to fix this problem.
                        </p> */}
                </Alert>
                    :null}

                {/* <p className="requiredtext">( * ) Required fields</p> */}

                
                  <Card className="customAcc">
                    <CardTitle onClick={this.slidingClick.bind(this)} className={"opendefault "+this.state.tdClassName}>
                   {this.state.tdIcon?<i className={"icon-circle "+this.state.tdIcon}></i>:null} 
                      Trademark Details
                    </CardTitle>
                    <CardBody>
                    {
                      this.state.generalHidden !== "" ?
                        <div className="disableuser">&nbsp;</div>
                        :
                        null
                    }
                      <Form id="tdForm">
                        <Row>
                        <Col md="8">
                        <Row>
                        <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="trademarkname">Trademark Name * <i className="far fa-question-circle ml-1" href="javascript:" id="UncontrolledTooltipExample"></i></Label>
                      <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample">
                        Hello world!
                      </UncontrolledTooltip>
                          <Input type="text" name="trademarkname" id="trademarkname" value={this.state.mainData.trademarkName}
                          onChange={(event)=>  {
                            this.setState({mainData:{...this.state.mainData,trademarkName:event.target.value}},()=>{this.isDataChanged();})                        
                        }}
                          />
                        </FormGroup>
                        <FormGroup className="form-group">
                          <Label htmlFor="lauguage">Language * <i className="far fa-question-circle ml-1" href="javascript:" id="UncontrolledTooltipExample2"></i></Label>
                           <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample2">
                            Hello world!
                          </UncontrolledTooltip>
                        <Form inline className="customcheck mb-3 greybgcheck" id="formLang">
                        <div className="form-check form-check-inline">
                            <Input className="form-check-input" name="lang" type="radio" id="inlineCheckbox1" value="English" onClick={this.otherLangClicked.bind(this)}/>
                            <Label for="inlineCheckbox1">English</Label>
                        </div>
                        <div className="form-check form-check-inline">
                            <Input className="form-check-input" name="lang" type="radio" id="inlineCheckbox2" value="Arabic" onClick={this.otherLangClicked.bind(this)}/>
                            <Label for="inlineCheckbox2">Arabic</Label>
                        </div>
                        <div className="form-check form-check-inline">
                            <Input className="form-check-input" name="lang" type="radio" id="inlineCheckbox3" value="Both" onClick={this.otherLangClicked.bind(this)}/>
                            <Label for="inlineCheckbox3">Both</Label> 
                        </div>
                        <div className="form-check form-check-inline">
                            <Input className="form-check-input" name="lang" type="radio" id="inlineCheckbox4" value="Others" onClick={this.otherLangClicked.bind(this)}/>
                            <Label for="inlineCheckbox4">Others  (Please specify)</Label>
                        </div>
                      </Form>
                      {!this.state.otherLangDisabled?
                        <Input type="text" name="lauguage" id="lauguage"  
                        onChange={(event)=>  {
                          this.setState({mainData:{...this.state.mainData,language:event.target.value}},()=>{this.isDataChanged();})                        
                      }}/>:null}
                          
                        </FormGroup>
                      
                        <FormGroup className="form-group">
                          <Label htmlFor="exampleEmail12">Classes * <i className="far fa-question-circle ml-1" href="javascript:" id="UncontrolledTooltipExample5"></i></Label>
                      <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample5">
                        Hello world!
                      </UncontrolledTooltip>
                      {this.state.classOptions?
                      <Select
                      className="reactSelect reactSelectClass classselect"
                      closeMenuOnSelect={false}
                      components={{ ClearIndicator }}
                      styles={{ clearIndicator: ClearIndicatorStyles }}
                      defaultValue={(this.state.classOptions && this.state.mainData.classifications)?
                        this.state.classOptions.filter(obj=>this.state.mainData.classifications.includes(obj.value)):[]}
                      isMulti
                      options={this.state.classOptions}
                      onChange={(selectedOption)=>{
                          //console.log(selectedOption);
                        this.setState({mainData:{...this.state.mainData,classifications:selectedOption.map(x=>x.value)}},()=>{this.isDataChanged();});
                        }}
                        value={(this.state.classOptions && this.state.mainData.classifications)?
                            this.state.classOptions.filter(obj=>this.state.mainData.classifications.includes(obj.value)):[]}
                    />
                        :null}
                          
                        </FormGroup>

                        <FormGroup className="form-group customcheck" >
                          <Label htmlFor="trademarkname">Masterbrand <i className="far fa-question-circle ml-1" href="javascript:" id="master"></i></Label>
                      <UncontrolledTooltip placement="right" target="master">
                        Hello world!
                      </UncontrolledTooltip>
                      <div className="form-check pl-0">
                            <Input className="form-check-input" name="masterbrand" type="checkbox" id="masterbrand" checked={this.state.mainData.masterbrand}
                             onChange={(event)=>  {
                                this.setState({mainData:{...this.state.mainData,masterbrand:event.target.checked}})                        
                            }}/>     
                            <Label for="masterbrand"></Label>                       
                        </div>
                        </FormGroup>

                        <FormGroup className="form-group">
                          <Label htmlFor="exampleEmail12">Masterbrand division <i className="far fa-question-circle ml-1" href="javascript:" id="UncontrolledTooltipExample41"></i></Label>
                      <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample41">
                        Hello world!
                      </UncontrolledTooltip>
                      {this.state.masterBrandDivisionsOptions?
                      <Select
                      className="reactSelect reactSelectMaster"
                      closeMenuOnSelect={true}
                      components={{ ClearIndicator }}
                      styles={{ clearIndicator: ClearIndicatorStyles }}
                      defaultValue={(this.state.masterBrandDivisionsOptions && this.state.mainData.masterBrandDivision)?
                        this.state.masterBrandDivisionsOptions.filter(obj=>obj.value==this.state.mainData.masterBrandDivision):[]}
                        value={(this.state.masterBrandDivisionsOptions && this.state.mainData.masterBrandDivision)?
                          this.state.masterBrandDivisionsOptions.filter(obj=>obj.value==this.state.mainData.masterBrandDivision):[]}
                      
                      options={this.state.masterBrandDivisionsOptions}
                      onChange={(selectedOption)=>{
                        this.setState({mainData:{...this.state.mainData,masterBrandDivision:selectedOption.value}},()=>{this.isDataChanged();});                        
                        }}
                    />
                        :null}

                        </FormGroup>




                        <FormGroup className="form-group">
                          <Label htmlFor="statusfield">Status</Label>
                          <select name="status" id="status" className="form-control" 
                            // defaultValue={String(this.state.mainData.get('status'))}
                            value={String(this.state.mainData.recordstatus)}
                            onChange={(event)=>  {
                              this.setState({mainData:{...this.state.mainData,recordstatus:String(event.target.value)}},()=>{this.isDataChanged();})                        
                            }}
                          >
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                          </select>
                        </FormGroup>

                        {/* <FormGroup className="form-group">
                          <Label htmlFor="exampleEmail12">Business Unit<i className="far fa-question-circle ml-1" href="javascript:" id="UncontrolledTooltipExample4"></i></Label>
                      <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample4">
                        Hello world!
                      </UncontrolledTooltip>
                      {this.state.businessUnitOptions?
                      <Select
                      className="reactSelect reactSelectBU"
                      closeMenuOnSelect={true}
                      components={{ ClearIndicator }}
                      styles={{ clearIndicator: ClearIndicatorStyles }}
                      defaultValue={(this.state.businessUnitOptions && this.state.mainData.businessUnit)?
                        this.state.businessUnitOptions.filter(obj=>obj.value==this.state.mainData.businessUnit):[]}
                        value={(this.state.businessUnitOptions && this.state.mainData.businessUnit)?
                            this.state.businessUnitOptions.filter(obj=>obj.value==this.state.mainData.businessUnit):[]}
                      
                      options={this.state.businessUnitOptions}
                      onChange={(selectedOption)=>{
                        this.setState({mainData:{...this.state.mainData,businessUnit:selectedOption.value}});                        
                        }}
                    />
                        :null}
                         
                        </FormGroup> */}
                        </Col>

                        <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="lauguage">Trademark Type * <i className="far fa-question-circle ml-1" href="javascript:" id="UncontrolledTooltipExample3"></i></Label>
                      <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample3">
                        Hello world!
                      </UncontrolledTooltip>
                      {this.state.mainData.type?
                         <Form inline className="customcheck greybgcheck mb-3" id="formType">
                         <div className="form-check form-check-inline ">
                             <Input className="form-check-input" name="tdType" type="checkbox" id="inlineCheckbox5" value="Wordmark" onClick={this.trademarkClicked.bind(this)} checked={this.state.mainData.type.includes("Wordmark")}/>
                             <Label for="inlineCheckbox5">Wordmark</Label>
                         </div>
                         <div className="form-check form-check-inline">
                             <Input className="form-check-input" name="tdType" type="checkbox" id="inlineCheckbox6" value="Graphic Device" onClick={this.trademarkClicked.bind(this)} checked={this.state.mainData.type.includes("Graphic Device")}/>
                             <Label for="inlineCheckbox6">Graphic Device</Label>
                         </div>
                         <div className="form-check form-check-inline">
                             <Input className="form-check-input" name="tdType" type="checkbox" id="inlineCheckbox7" value="Color" onClick={this.trademarkClicked.bind(this)} checked={this.state.mainData.type.includes("Color")}/>
                             <Label for="inlineCheckbox7">Color</Label>
                         </div>
                         <div className="form-check form-check-inline">
                             <Input className="form-check-input" name="tdType" type="checkbox" id="inlineCheckbox8" value="Black and White" onClick={this.trademarkClicked.bind(this)} checked={this.state.mainData.type.includes("Black and White")}/>
                             <Label for="inlineCheckbox8">Black and White</Label>
                         </div>
                       </Form>
                       :null}
                     
                      {this.state.showwordmarkText?
                        <Input type="text" name="wordmarktext" id="wordmarktext"  value={this.state.mainData.wordmarkText} 
                        onChange={(event)=>  {
                          this.setState({mainData:{...this.state.mainData,wordmarkText:event.target.value}},()=>{this.isDataChanged();})                        
                      }}/>:null}
                        </FormGroup>

                        <FormGroup className="form-group">
                          <Label htmlFor="exampleEmail12">Commercial Contact <i className="far fa-question-circle ml-1" href="javascript:" id="commerical"></i></Label>
                      <UncontrolledTooltip placement="right" target="commerical">
                        Hello world!
                      </UncontrolledTooltip>
                      <div className="autocomwrap">
                      <Autocomplete
                      inputProps={{className:"form-control autoComplete"}}
                      
                        getItemValue={(item) => item}
                        items={this.state.commercialContactSuggestion}
                        shouldItemRender={(item, value) => item.toString().toLowerCase().indexOf(value.toString().toLowerCase()) > -1}
                        renderItem={(item, isHighlighted) =>
                            <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                            {item}
                            </div>
                        }
                        value={this.state.mainData.contact}
                        onChange={(event) =>{ this.setState({mainData:{...this.state.mainData,contact:event.target.value}},()=>{this.isDataChanged();});
                            }}
                        onSelect={(val) =>{this.setState({mainData:{...this.state.mainData,contact:val}});}}
                        />
                        </div>
                          {/* <Input type="select" id="contact" className="custom-select" onChange={(event)=>{
                              this.setState({mainData:{...this.state.mainData,contact:event.target.value}}); 
                             }}>
                              <option value="">Select</option>
                              <option value="1">Value 1</option>
                              <option value="2">Value 2</option>
                              <option value="3">Value 3</option>
                              <option value="4">Value 4</option>
                              <option value="5">Value 5</option>
                            </Input> */}
                        </FormGroup>
                      
                      <FormGroup className="form-group">
                        <Label htmlFor="exampleEmail12">Jurisdictions  * <i className="far fa-question-circle ml-1" href="javascript:" id="jurisdictions"></i></Label>
                    <UncontrolledTooltip placement="right" target="jurisdictions">
                      Hello world!
                    </UncontrolledTooltip>
                    {this.state.jurisdictionsOptions?
                      <Select
                      className="reactSelect reactSelectJUD jurisselect"
                      closeMenuOnSelect={false}
                      components={{ ClearIndicator }}
                      styles={{ clearIndicator: ClearIndicatorStyles }}
                      defaultValue={(this.state.jurisdictionsOptions && this.state.mainData.jurisdictions)?
                        this.state.jurisdictionsOptions.filter(obj=>this.state.mainData.jurisdictions.includes(obj.value)):[]}
                        
                      value={(this.state.jurisdictionsOptions && this.state.mainData.jurisdictions)?
                        this.state.jurisdictionsOptions.filter(obj=>this.state.mainData.jurisdictions.includes(obj.value)):[]}
                      isMulti
                      options={this.state.jurisdictionsOptions}
                      onChange={(selectedOption)=>{
                        this.setState({mainData:{...this.state.mainData,jurisdictions:selectedOption.map(x=>x.value)}},()=>{this.isDataChanged();});                        
                        }}
                    />
                        :null}
                      </FormGroup>

                        <FormGroup className="form-group">
                          <Label htmlFor="exampleEmail12">Countries  * <i className="far fa-question-circle ml-1" href="javascript:" id="countries"></i></Label>
                      <UncontrolledTooltip placement="right" target="countries">
                        Hello world!
                      </UncontrolledTooltip>
                      {this.state.countriesOptions?
                      <Select
                      className="reactSelect reactSelectCNT"
                      closeMenuOnSelect={false}
                      components={{ ClearIndicator }}
                      styles={{ clearIndicator: ClearIndicatorStyles }}
                      defaultValue={(this.state.countriesOptions && this.state.mainData.countries)?
                        this.state.countriesOptions.filter(obj=>this.state.mainData.countries.includes(obj.value)):[]}
                        value={(this.state.countriesOptions && this.state.mainData.countries)?
                          this.state.countriesOptions.filter(obj=>this.state.mainData.countries.includes(obj.value)):[]}
                      isMulti
                      options={this.state.countriesOptions}
                      onChange={(selectedOption)=>{
                        this.setState({mainData:{...this.state.mainData,countries:selectedOption.map(x=>x.value)}},()=>{this.isDataChanged();});                        
                        }}
                    />
                        :null}
                        </FormGroup>
                        </Col>
                            </Row>
                            </Col>

                        <Col md="4">
                        <FormGroup className="form-group">
                          <Label htmlFor="exampleEmail12">Owner  * <i className="far fa-question-circle ml-1" href="javascript:" id="UncontrolledTooltipExample4"></i></Label>
                      <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample4">
                        Hello world!
                      </UncontrolledTooltip>
                      {this.state.ownerOptions?
                      <Select
                      className="reactSelect reactSelectOWN"
                      closeMenuOnSelect={true}
                      components={{ ClearIndicator }}
                      styles={{ clearIndicator: ClearIndicatorStyles }}
                      defaultValue={(this.state.ownerOptions && this.state.mainData.owner)?
                        this.state.ownerOptions.filter(obj=>obj.value==this.state.mainData.owner):[]}
                        value={(this.state.ownerOptions && this.state.mainData.owner)?
                          this.state.ownerOptions.filter(obj=>obj.value==this.state.mainData.owner):[]}
                      
                      options={this.state.ownerOptions}
                      onChange={(selectedOption)=>{
                        this.setState({mainData:{...this.state.mainData,owner:selectedOption.value}},()=>{this.isDataChanged();});                        
                        }}
                    />
                        :null}

                        </FormGroup>

                        <FileUpload labelText="Graphic Device Logotype" isMandatory={this.state.fileUploadMandatory} tooltipText="Hello world!"
                                    isValid={this.state.fileInputValid} inputText="Upload your file here" items={this.state.mainData.logoFileArray}
                                    name="inputGroupFile01"
                                    folderName="trademarks"
                                    onDelete={(id)=>{
                                        var logoArray=this.state.mainData.logoFileArray.filter(obj=>obj.id!=id).map((item,index)=>{return {...item,id:index}});  
                                        this.setState({mainData:{...this.state.mainData,logoFileArray:logoArray  }},()=>{this.isDataChanged();
                                    });}}
                                    addItems={(items)=>{
                                        //console.log('addItem',items);
                                        this.setState({                                        
                                        mainData:{ ...this.state.mainData,logoFileArray:[...this.state.mainData.logoFileArray,...items]}
                                    },()=>{this.isDataChanged();
                                        //console.log(this.state.mainData.logoFileArray);
                                    });}}
                                    updateItem={(id,filename,url)=>{
                                        var objToChange = this.state.mainData.logoFileArray.find(x=>x.id==id);
                                        if(objToChange)
                                        {
                                            this.setState({                                        
                                                mainData:{ ...this.state.mainData,logoFileArray:[...this.state.mainData.logoFileArray.filter(x=>x.id!=id),
                                                    {...this.state.mainData.logoFileArray.find(x=>x.id==id),awsFileName:filename,url:url}
                                                    ]}
                                            },()=>{//console.log(this.state.mainData.logoFileArray);
                                            });
                                        }   
                                    }}     
                                    showError={(message)=>{
                                        this.setState({showAlertMessage:true,alertMessage:message})
                                    }}
                                    />

                        {/* <FormGroup className="form-group">
                          <Label htmlFor="exampleEmail12"> {!this.state.showwordmarkText?'*':null} <i className="far fa-question-circle ml-1" href="javascript:" id="graphic"></i></Label>
                      <UncontrolledTooltip placement="right" target="graphic">
                        Hello world!
                      </UncontrolledTooltip>
                      <InputGroup>
                            <div className="custom-file">
                                <Input type="file" className="custom-file-input" id="inputGroupFile01" onChange={(event)=>{this.onLogoFileSelected(event)}} multiple/>
                                <label className="custom-file-label" htmlFor="inputGroupFile01">Upload your file here</label>
                            </div>                            
                          </InputGroup>
                          </FormGroup>
                          <div className="galleryWrap">
                            {this.state.mainData.logoFileArray.length>0?
                                this.state.mainData.logoFileArray.map((item,index)=>{
                                    //console.log(item)
                                    return(                                   
                                        <div className="galleryitem"  key={item.awsFileName}>
                                            <button type="button" className="deletebutton" 
                                                 onClick={()=>{
                                                    this.setState({mainData:{...this.state.mainData,
                                                        logoFileArray:this.state.mainData.logoFileArray.filter(obj=>obj.awsFileName!=item.awsFileName)                                            
                                                    }},()=>{this.isDataChanged();}); 
                                                 }}>
                                            <i className="fas fa-trash-alt"></i>
                                            </button>
                                            <div className="imgbx" >                    
                                                
                                                <a target="_blank" onClick={async()=>{
                                                var res = await getFileUrl('trademarks',item.awsFileName);
                                                if(res && res.success==1)
                                                {
                                                    window.open(res.url);
                                                }
                                                //console.log(res);
                                            }}>
                                                    <img src={(item.type === "jpg" || item.type === "png" || item.type === "jpeg") ?imageIcon:fileIcon} alt="Gallery Image" width="50" />
                                                </a>
                                            </div>
                                            <span>{item.name}</span>
                                      </div>     
                                )}
                                )
                                :null}
                        </div> */}
                        {/* <FormGroup className="form-group">
                          <InputGroup>
                            <div className="custom-file">
                                <Input type="file" className="custom-file-input" id="inputGroupFile02" multiple onChange={(event=>{this.onAssetFilesSelected(event)})}/>
                                <label className="custom-file-label" htmlFor="inputGroupFile02">Upload your file here</label>
                                </div>
                                </InputGroup>
                                </FormGroup>
                                <div className="galleryWrap">
                                {this.state.mainData.assetsFileArray.length>0?
                                this.state.mainData.assetsFileArray.map((item,index)=>{return(
                                    <div className="galleryitem"  key={item.awsFileName}>
                                    <button type="button" className="deletebutton" 
                                         onClick={()=>{
                                            this.setState({mainData:{...this.state.mainData,
                                                assetsFileArray:this.state.mainData.assetsFileArray.filter(obj=>obj.awsFileName!=item.awsFileName)                                            
                                            }}); 
                                         }}>
                                    <i className="fas fa-trash-alt"></i>
                                    </button>
                                    <div className="imgbx"> 
                                        <a target="_blank" onClick={async()=>{
                                                   // alert('asda');
                                                   //console.log(item.awsFileName)
                                                var res = await getFileUrl('trademarks',item.awsFileName);  
                                                if(res && res.success==1)
                                                {
                                                    //console.log(res.url)
                                                    window.open(res.url);
                                                }
                                                //console.log(res);
                                            }}>
                                            <img src={(item.type === "jpg" || item.type === "png" || item.type === "jpeg") ?imageIcon:fileIcon} alt="Gallery Image" width="50" />
                                        </a>
                                    </div>
                                    <span>{item.name}</span>
                              </div> 
                                )}
                                )
                                :null}                          
                        </div> */}
                        </Col>
                        </Row>
                        {(this.state.editmode && this.state.showSaveButton)?
                        (this.state.mainLoading || this.state.tdLoading)?
                        <div className="float-right pl-5 pr-5 btn btn-primary">
                        <Loader type="Oval" color="#414241" height={20} width={20} />
                        </div>:
                        <Button color="primary" className={this.state.generalHidden+" float-right pl-5 pr-5"} onClick={()=>{this.validateTDSection()}}>Validate</Button> :null
                        }
                      </Form>
                    </CardBody>
                  </Card>
                  
                 <Card className="customAcc">
                 <CardTitle onClick={this.slidingClick.bind(this)} className={this.state.lfClassName}>
                 {this.state.lfIcon?<i className={"icon-circle "+this.state.lfIcon}></i>:null}                     
                    Law Firm
                    </CardTitle>
                    <CardBody>
                    {
                      this.state.generalHidden !== "" ?
                        <div className="disableuser">&nbsp;</div>
                        :
                        null
                    }
                      <Form id="lfForm">
                        <Row>
                          <Col md="4">
                        <FormGroup className="form-group">
                          <Label htmlFor="lawfirm">Law Firm * <i className="far fa-question-circle ml-1" href="javascript:" id="lawfirm"></i></Label>
                      <UncontrolledTooltip placement="right" target="lawfirm">
                        Hello world!
                      </UncontrolledTooltip>
                      {this.state.lawFirmsOption?
                      <Select
                      className="reactSelect reactSelectLF"
                      closeMenuOnSelect={true}
                      components={{ ClearIndicator }}
                      styles={{ clearIndicator: ClearIndicatorStyles }}
                      defaultValue={(this.state.lawFirmsOption && this.state.mainData.lawFirm)?
                        this.state.lawFirmsOption.filter(obj=>obj.value==this.state.mainData.lawFirm):[]}
                        value={(this.state.lawFirmsOption && this.state.mainData.lawFirm)?
                          this.state.lawFirmsOption.filter(obj=>obj.value==this.state.mainData.lawFirm):[]}
                      
                      options={this.state.lawFirmsOption}
                      onChange={(selectedOption)=>{
                        this.setState({mainData:{...this.state.mainData,lawFirm:selectedOption.value}},()=>{this.isDataChanged();});                        
                        }}
                    />
                        :null}

                        {/* {(this.state.lawFirmsOption && this.state.mainData.lawFirm!='')?this.state.lawFirmsOption.filter(x=>x.value==this.state.mainData.lawFirm).length>0?
                            this.state.lawFirmsOption.filter(x=>x.value==this.state.mainData.lawFirm).map(item=>{return(
                                <a href={"/#/lawfirm/editlawfirm/"+item.value} target="_blank" className="emaillink"><i className="far fa-edit"></i> {item.email}</a>
                            )})
                            :null:null} */}
                        </FormGroup>
                          </Col>
                          <Col md="4">
                        <FormGroup className="form-group">
                          <Label htmlFor="trademarkname">TW Reference No. *<i className="far fa-question-circle ml-1" href="javascript:" id="twreference"></i></Label>
                      <UncontrolledTooltip placement="right" target="twreference">
                        Hello world!
                      </UncontrolledTooltip>
                          <Input type="text" name="twreference" id="twreference" value={this.state.mainData.twRefNo}  onChange={(event)=>  {
                            this.setState({mainData:{...this.state.mainData,twRefNo:event.target.value}},()=>{this.isDataChanged();})                        
                        }}/>
                        </FormGroup>
                        </Col>
                          <Col md="4">
                        <FormGroup className="form-group">
                          <Label htmlFor="trademarkname">AGIP Reference No. *<i className="far fa-question-circle ml-1" href="javascript:" id="agipreference"></i></Label>
                      <UncontrolledTooltip placement="right" target="agipreference">
                        Hello world!
                      </UncontrolledTooltip>
                          <Input type="text" name="agipreference" id="agipreference" value={this.state.mainData.agipRefNo} onChange={(event)=>  {
                            this.setState({mainData:{...this.state.mainData,agipRefNo:event.target.value}},()=>{this.isDataChanged();})                        
                        }}/>
                        </FormGroup>
                        </Col>
                        </Row>
                        <FormGroup  className="form-group customcheck mb-0">
                        <div className="form-check form-check-inline">
                            <Input className="form-check-input" type="checkbox" id="inlineCheckbox9" value="option1" onChange={(event)=>  {
                                //console.log('asda');
                            this.setState({mainData:{...this.state.mainData,transferAGIP:jQuery("#inlineCheckbox9").is(':checked')?"true":"false"}},()=>{this.isDataChanged();})                        
                        }}/>
                            <Label for="inlineCheckbox9">Transfer To AGIP</Label>
                        </div>
                        </FormGroup>
                        {(this.state.editmode && this.state.showSaveButton)?
                        (this.state.mainLoading || this.state.lfLoading)?
                        <div className="float-right pl-5 pr-5 btn btn-primary">
                        <Loader type="Oval" color="#414241" height={20} width={20} />
                        </div>:
                        <Button color="primary" className={this.state.generalHidden+" float-right pl-5 pr-5"} onClick={()=>{this.validateLFSection()}}>Validate</Button> :null
                        
                        }
                      </Form>
                    </CardBody>
                  </Card>

                  <Card className="customAcc">
                  <CardTitle onClick={this.slidingClick.bind(this)} className={this.state.ndClassName}>
                    
                 {this.state.ndIcon?<i className={"icon-circle "+this.state.ndIcon}></i>:null} 
                    Notes
                    </CardTitle>
                    <CardBody>
                    {
                      this.state.generalHidden !== "" ?
                        <div className="disableuser">&nbsp;</div>
                        :
                        null
                    }
                      <Form>
                       
                       <FormGroup  className="form-group mb-0">
                            <Input type="textarea" rows="5" id="msgnote" name="msgnote"
                            value={this.state.mainData.notes}
                             placeholder="Add your comments here..." onChange={(event)=>  {
                            this.setState({mainData:{...this.state.mainData,notes:event.target.value}},()=>{this.isDataChanged();})                        
                        }}/>
                        </FormGroup>
                        {/* <Button color="primary" className="float-right pl-5 pr-5 ml-3">Post</Button> */}
                        {/* {(this.state.showSaveButton)?
                        (this.state.mainLoading || this.state.ndLoading)?
                        <div className="float-right pl-5 pr-5 btn btn-primary">
                        <Loader type="Oval" color="#414241" height={20} width={20} />
                        </div> :
                        <Button color="primary" className="float-right btn-default pl-5 pr-5" onClick={()=>{this.validateNDSection()}} >Validate</Button>:null
                        } */}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
                </Row>
              {/*--------------------------------------------------------------------------------*/}
              {/*End Inner Div*/}
              {/*--------------------------------------------------------------------------------*/}
            </div>
    }
}

export default addNewTradeMark;
