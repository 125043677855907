import React, { Component } from 'react';
import {Card,CardBody,CardTitle,Row,Col,Form,FormGroup,Label,Input,FormText,Button,InputGroup,UncontrolledTooltip,Alert} from 'reactstrap';  
import Select, { components } from "react-select";
import defaultuser from '../../assets/images/icons/default-user.png';

import jQuery from 'jquery';
import fileIcon from '../../assets/images/icons/file-icon.png';
import imageIcon from '../../assets/images/icons/image-icon.png';
import Loader from '../../components/common/Loader';  
import Moment from 'moment';
import {currentUser} from '../../services/trademark';
import {getTrademakMaterials,convertParseObjTOStateObj,convertStateObjToParseObj,saveTrademarkMarketing} from '../../services/trademarkMaterial';
import DatePicker from "react-datepicker";
import { isAdmin } from '../../services/user';



 class marketMaterial extends Component {
	constructor(props) {
		super(props);

		this.state = {
            currentUserDetails: "",
            mainLoading:false,             
            tdClassName:null,
            tdIcon:'icon-pencil',
            ndClassName:null,
            ndIcon:'icon-pencil',
         
          showSaveButton:true,
          showAlertMessage : false,
      alertMessage:'',
      showVersionText:false,
      currentVersion:'',
      editmode:false,
          mainId:'',
          message:'',
          chats:[],
          mainData:{
              id:'',
              logoTypeUpdated:false,
              logoTypeUrl:'',
              logoDate:null,
              logoComment:null,
              stationaryUpdated:false,
              stationaryUrl:'',
              stationaryDate:null,
              stationaryComment:'',
              buUpdated:false,
              buUrl:'',
              buDate:null,
              buComment:'',
              corporateUpdated:false,
              corporateDate:null,
              corporateComment:'',
              corporateUrl:'',
              trademark:'',
          }
          
        };

	}
    
    componentWillMount= async()=>{
      var isadminval = await isAdmin();
      if (isadminval) {
        var currentrole = isadminval[0].get('role').get('name');
        console.log(currentrole);
        this.setState({ currentRole: currentrole });
        if ( currentrole === "Finance") {
          this.setState({ editmode: false});
        }
        else
        {
          this.setState({ editmode: true});
        }
      }
      jQuery(".opendefault").next('.card-body').show();
      jQuery(".opendefault").addClass('active');
        var cuser = await currentUser(); 
        if (cuser) {  
            this.setState({currentUserDetails:cuser[0]});  
        }


        this.setState({mainData:{...this.state.mainData,trademark:this.props.id}})
        this.loadTrademarkMarketingData();
        
      }
  
      loadTrademarkMarketingData = async()=>{
          var obj= await getTrademakMaterials(this.props.id);
          if(obj)
          {
              var convertedObj = await convertParseObjTOStateObj(obj);
              this.setState({mainData:{...this.state.mainData,...convertedObj}})
          }            
      }
		
		async componentDidMount(evt) {
			jQuery(".opendefault").next('.card-body').show();
			jQuery(".opendefault").addClass('active');
		}
	
		slidingClick = (event) => {
            jQuery(event.target).parent(".card").find('.cardBodyNew').slideToggle('slow');
            jQuery(event.target).parent().parent(".card").find('.cardBodyNew').slideToggle('slow');
            jQuery(event.target).toggleClass('active');
          }

       

//validate trademark detail section
validateTDSection = async()=>{
    var valid = true;
    var mainData = this.state.mainData;
    jQuery("#mainForm input").removeClass('is-invalid');
    jQuery(".reactSelect").removeClass('is-invalid');
    
    jQuery("#textNotes").removeClass('is-invalid');
    jQuery(".reactSelect").removeClass('is-invalid');

    // if(mainData.logoTypeUrl=='' && mainData.stationaryUrl=='' && mainData.buUrl=='' && mainData.corporateUrl=='')
    // {
    //     valid = false;
    //     //jQuery('#textNotes').addClass('is-invalid');
    // }
    // if(!mainData.logoTypeUpdated && !mainData.stationaryUpdated && !mainData.buUpdated && !mainData.corporateUpdated)
    // {
    //     valid = false;
    //   //  jQuery('#ddlStatus').addClass('is-invalid');
    // }

    // if(mainData.logoDate=='' && mainData.stationaryDate=='' && mainData.buDate=='' && mainData.corporateDate=='')
    // {
    //     valid = false;
    //     //jQuery('#textNotes').addClass('is-invalid');
    // }
    // if(!mainData.logoComment && !mainData.stationaryComment && !mainData.buComment && !mainData.corporateComment)
    // {
    //     valid = false;
    //   //  jQuery('#ddlStatus').addClass('is-invalid');
    // }
    

    if(valid)
    {
        this.setState({tdClassName:"completed",tdIcon:"fas fa-check",tdLoading:false})
    }
    else
    {
        this.setState({tdClassName:"failed",tdIcon:"fas fa-exclamation",tdLoading:false,showAlertMessage:true,alertMessage:"No data entered."})
    }
    return valid;
}

submitData = async() => { 
    this.setState({mainLoading:true,showAlertMessage:false})
    var validTD = await this.validateTDSection();
    if(validTD)
    {
        //alert('valid');
        var tempObj = await convertStateObjToParseObj(this.state.mainData);
        //console.log(tempObj);
        if(tempObj)
        {
            var subObj = await saveTrademarkMarketing(tempObj);
            var alertMessage = (subObj.attributes.versionCount==1)?'Record has been successfully saved.':'Record has been successfully updated.';
            
            this.setState({mainLoading:false,mainId:subObj.id,showAlertMessage:true,alertMessage:alertMessage},async()=>{
                
            await this.loadTrademarkMarketingData();
               
            })
            
        }
    }   
    else
    {
        this.setState({showErrorAlert:true,mainLoading:false});
    }
    
}


	render() {
		return (            
			<div className="step step1 mt-5">
            <Row className="justify-content-between topbuttonbar">

            <Col className="col-md-7">
            {this.state.showAlertMessage?
            <Alert color="success" className="d-block">
            {this.state.alertMessage}
            <span onClick={()=>{this.setState({showAlertMessage:false})}} className="float-right">X</span>
            </Alert>:null}
            </Col>

            <Col className="col-auto mb-4">
            {
            this.state.editmode && this.state.showSaveButton?
            this.state.mainLoading? <div className="btn btn-primary mr-3">
            <Loader type="Oval" color="#414241" height={20} width={20} />
            </div>:
             <a href="javascript:" className="btn btn-primary mr-3" id="savebtn" onClick={()=>{this.submitData()}}> <span>Save</span></a>
            :null
            }

           
            
            </Col>
            </Row>
			<Row>
      <Col md="12">
			<Card className="customAcc">
                    <CardTitle onClick={this.slidingClick.bind(this)} className={"opendefault "+this.state.tdClassName}>
                   {this.state.tdIcon?<i className={"icon-circle "+this.state.tdIcon}></i>:null} 
                      Trademark Marketing Materials
                    </CardTitle>
                    <CardBody className="cardBodyNew">
                      <Form id="mainForm">

                      <Row>
                      <Col md="3">
                      <FormGroup className="form-group customcheck">
                          <Label htmlFor="trademarkname">Logotype Assets Updated <i className="far fa-question-circle ml-1" href="javascript:" id="logoUpdated"></i></Label>
                      <UncontrolledTooltip placement="right" target="logoUpdated">
                        Hello world!
                      </UncontrolledTooltip>
                      <div className="form-check pl-0">
                            <Input className="form-check-input" name="logoTypeUpdated" type="checkbox" id="logoTypeUpdated" checked={this.state.mainData.logoTypeUpdated}
                             onChange={(event)=>  {
                                this.setState({mainData:{...this.state.mainData,logoTypeUpdated:event.target.checked}})                        
                            }}/>     
                            <Label for="logoTypeUpdated"></Label>                       
                        </div>
                        </FormGroup>
                        </Col>
                      <Col md="3">
                      <FormGroup className="form-group">
                          <Label htmlFor="trademarkname">Url To Brand Portal Page <i className="far fa-question-circle ml-1" href="javascript:" id="logoUrl"></i></Label>
                      <UncontrolledTooltip placement="right" target="logoUrl">
                        Hello world!
                      </UncontrolledTooltip>
                                 <Input type="text" name="logoTypeUrl" id="logoTypeUrl" value={this.state.mainData.logoTypeUrl}
                            onChange={(event)=>  {
                            this.setState({mainData:{...this.state.mainData,logoTypeUrl:event.target.value}})                        
                        }}/>
                        </FormGroup>
                        </Col>
                      <Col md="3">
                      <FormGroup className="form-group">
                            <Label htmlFor="exampleEmail12">Logotype Assets Date <i className="far fa-question-circle ml-1" href="javascript:" id="logoTypeDate"></i></Label>
                        <UncontrolledTooltip placement="right" target="logoTypeDate">
                          Hello world!
                        </UncontrolledTooltip>
                        <div className="autocomwrap">
                            <DatePicker
                                id="logoDate"
                                selected={this.state.mainData.logoDate}
                                onSelect={(date)=>{this.setState({mainData:{...this.state.mainData,logoDate:date}})}}
                                onChange={(date)=>{this.setState({mainData:{...this.state.mainData,logoDate:date}})}}
                                className="form-control"
                                dateFormat="d/M/yyyy"
                                showYearDropdown
                                showMonthDropdown
                            />
                            
                            
                          </div>
                          </FormGroup>
                          </Col>
                      <Col md="3">
                      <FormGroup className="form-group">
                        <Label htmlFor="exampleEmail12">Logotype Assets Comment <i className="far fa-question-circle ml-1" href="javascript:" id="logoCmnt"></i></Label>
                        <UncontrolledTooltip placement="right" target="logoCmnt">
                          Hello world!
                        </UncontrolledTooltip>
                            <Input type="textarea" rows="5" id="logoComment" name="logoComment"
                            value={this.state.mainData.logoComment}
                             placeholder="Add your comments here..." onChange={(event)=>  {
                            this.setState({mainData:{...this.state.mainData,logoComment:event.target.value}})                        
                        }}/>
                        </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                      <Col md="3">
                      <FormGroup className="form-group customcheck">
                          <Label htmlFor="trademarkname">Stationery Assets Updated <i className="far fa-question-circle ml-1" href="javascript:" id="stnUpd"></i></Label>
                      <UncontrolledTooltip placement="right" target="stnUpd">
                        Hello world!
                      </UncontrolledTooltip>
                      <div className="form-check pl-0">
                            <Input className="form-check-input" name="stationaryUpdated" type="checkbox" id="stationaryUpdated" checked={this.state.mainData.stationaryUpdated}
                             onChange={(event)=>  {
                                this.setState({mainData:{...this.state.mainData,stationaryUpdated:event.target.checked}})                        
                            }}/>     
                            <Label for="stationaryUpdated"></Label>                       
                        </div>
                        </FormGroup>
                        </Col>
                      <Col md="3">
                      <FormGroup className="form-group">
                          <Label htmlFor="trademarkname">Url To Brand Portal Page <i className="far fa-question-circle ml-1" href="javascript:" id="stnUrl"></i></Label>
                      <UncontrolledTooltip placement="right" target="stnUrl">
                        Hello world!
                      </UncontrolledTooltip>
                                 <Input type="text" name="stationaryUrl" id="stationaryUrl" value={this.state.mainData.stationaryUrl}
                            onChange={(event)=>  {
                            this.setState({mainData:{...this.state.mainData,stationaryUrl:event.target.value}})                        
                        }}/>
                        </FormGroup>
                      </Col>
                      <Col md="3">
                      <FormGroup className="form-group">
                            <Label htmlFor="exampleEmail12">Stationery Assets Date <i className="far fa-question-circle ml-1" href="javascript:" id="stnDate"></i></Label>
                        <UncontrolledTooltip placement="right" target="stnDate">
                          Hello world!
                        </UncontrolledTooltip>
                        <div className="autocomwrap">
                            <DatePicker
                                id="stationaryDate"
                                selected={this.state.mainData.stationaryDate}
                                onSelect={(date)=>{this.setState({mainData:{...this.state.mainData,stationaryDate:date}})}}
                                onChange={(date)=>{this.setState({mainData:{...this.state.mainData,stationaryDate:date}})}}
                                className="form-control"
                                dateFormat="d/M/yyyy"
                                showYearDropdown
                                showMonthDropdown
                            />
                            
                            
                          </div>
                          </FormGroup>
                          </Col>
                      <Col md="3">
                      <FormGroup className="form-group">
                        <Label htmlFor="exampleEmail12">Stationery Assets Comment <i className="far fa-question-circle ml-1" href="javascript:" id="stnCmnt"></i></Label>
                        <UncontrolledTooltip placement="right" target="stnCmnt">
                          Hello world!
                        </UncontrolledTooltip>
                            <Input type="textarea" rows="5" id="stationaryComment" name="stationaryComment"
                            value={this.state.mainData.stationaryComment}
                             placeholder="Add your comments here..." onChange={(event)=>  {
                            this.setState({mainData:{...this.state.mainData,stationaryComment:event.target.value}})                        
                        }}/>
                        </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                      <Col md="3">
                      <FormGroup  className="form-group customcheck">
                          <Label htmlFor="trademarkname">BU Informed/Assets on Brand Portal <i className="far fa-question-circle ml-1" href="javascript:" id="buUp"></i></Label>
                      <UncontrolledTooltip placement="right" target="buUp">
                        Hello world!
                      </UncontrolledTooltip>
                      <div className="form-check pl-0">
                            <Input className="form-check-input" name="buUpdated" type="checkbox" id="buUpdated" checked={this.state.mainData.buUpdated}
                             onChange={(event)=>  {
                                this.setState({mainData:{...this.state.mainData,buUpdated:event.target.checked}})                        
                            }}/>     
                            <Label for="buUpdated"></Label>                       
                        </div>
                        </FormGroup>
                        </Col>
                      <Col md="3">
                      <FormGroup className="form-group">
                          <Label htmlFor="trademarkname">Url To Brand Portal Page <i className="far fa-question-circle ml-1" href="javascript:" id="buaUrl"></i></Label>
                      <UncontrolledTooltip placement="right" target="buaUrl">
                        Hello world!
                      </UncontrolledTooltip>
                                 <Input type="text" name="buUrl" id="buUrl" value={this.state.mainData.buUrl}
                            onChange={(event)=>  {
                            this.setState({mainData:{...this.state.mainData,buUrl:event.target.value}})                        
                        }}/>
                        </FormGroup>
                        </Col>
                      <Col md="3">
                      <FormGroup className="form-group">
                            <Label htmlFor="exampleEmail12">BU Informed/Assets Date <i className="far fa-question-circle ml-1" href="javascript:" id="buInDate"></i></Label>
                        <UncontrolledTooltip placement="right" target="buInDate">
                          Hello world!
                        </UncontrolledTooltip>
                        <div className="autocomwrap">
                            <DatePicker
                                id="buDate"
                                selected={this.state.mainData.buDate}
                                onSelect={(date)=>{this.setState({mainData:{...this.state.mainData,buDate:date}})}}
                                onChange={(date)=>{this.setState({mainData:{...this.state.mainData,buDate:date}})}}
                                className="form-control"
                                dateFormat="d/M/yyyy"
                                showYearDropdown
                                showMonthDropdown
                            />
                            
                            
                          </div>
                          </FormGroup>
                          </Col>
                      <Col md="3">
                      <FormGroup className="form-group">
                        <Label htmlFor="exampleEmail12">BU Informed/Assets Comment <i className="far fa-question-circle ml-1" href="javascript:" id="buCmnt"></i></Label>
                        <UncontrolledTooltip placement="right" target="buCmnt">
                          Hello world!
                        </UncontrolledTooltip>
                            <Input type="textarea" rows="5" id="buComment" name="buComment"
                            value={this.state.mainData.buComment}
                             placeholder="Add your comments here..." onChange={(event)=>  {
                            this.setState({mainData:{...this.state.mainData,buComment:event.target.value}})                        
                        }}/>
                        </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                      <Col md="3">
                      <FormGroup  className="form-group customcheck">
                          <Label htmlFor="trademarkname">Corporate Collaterals Changed <i className="far fa-question-circle ml-1" href="javascript:" id="corpUp"></i></Label>
                      <UncontrolledTooltip placement="right" target="corpUp">
                        Hello world!
                      </UncontrolledTooltip>
                      <div className="form-check pl-0">
                            <Input className="form-check-input" name="corporateUpdated" type="checkbox" id="corporateUpdated" checked={this.state.mainData.corporateUpdated}
                             onChange={(event)=>  {
                                this.setState({mainData:{...this.state.mainData,corporateUpdated:event.target.checked}})                        
                            }}/>     
                            <Label for="corporateUpdated"></Label>                       
                        </div>
                        </FormGroup>
                        </Col>
                      <Col md="3"><FormGroup className="form-group">
                          <Label htmlFor="trademarkname">Url To Brand Portal Page <i className="far fa-question-circle ml-1" href="javascript:" id="corpUrl"></i></Label>
                      <UncontrolledTooltip placement="right" target="corpUrl">
                        Hello world!
                      </UncontrolledTooltip>
                                 <Input type="text" name="corporateUrl" id="corporateUrl" value={this.state.mainData.corporateUrl}
                            onChange={(event)=>  {
                            this.setState({mainData:{...this.state.mainData,corporateUrl:event.target.value}})                        
                        }}/>
                        </FormGroup>
                        </Col>
                      <Col md="3">
                      <FormGroup className="form-group">
                            <Label htmlFor="exampleEmail12">Corporate Collaterals Date <i className="far fa-question-circle ml-1" href="javascript:" id="corpDate"></i></Label>
                        <UncontrolledTooltip placement="right" target="corpDate">
                          Hello world!
                        </UncontrolledTooltip>
                        <div className="autocomwrap">
                            <DatePicker
                                id="corporateDate"
                                selected={this.state.mainData.corporateDate}
                                onSelect={(date)=>{this.setState({mainData:{...this.state.mainData,corporateDate:date}})}}
                                onChange={(date)=>{this.setState({mainData:{...this.state.mainData,corporateDate:date}})}}
                                className="form-control"
                                dateFormat="d/M/yyyy"
                                showYearDropdown
                                showMonthDropdown
                            />
                            
                            
                          </div>
                          </FormGroup>
                          </Col>
                      <Col md="3">
                      <FormGroup className="form-group">
                        <Label htmlFor="exampleEmail12">Corporate Collaterals Comment <i className="far fa-question-circle ml-1" href="javascript:" id="corpCmnt"></i></Label>
                        <UncontrolledTooltip placement="right" target="corpCmnt">
                          Hello world!
                        </UncontrolledTooltip>
                            <Input type="textarea" rows="5" id="corporateComment" name="corporateComment"
                            value={this.state.mainData.corporateComment}
                             placeholder="Add your comments here..." onChange={(event)=>  {
                            this.setState({mainData:{...this.state.mainData,corporateComment:event.target.value}})                        
                        }}/>
                        </FormGroup>
                        </Col>
                      </Row>

                    
                        
                      </Form>
                    </CardBody>
                  </Card>
									</Col>

									
									</Row>
			</div>
		)
	}
}
export default  marketMaterial;