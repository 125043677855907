import jQuery from 'jquery';
var Parse = require('parse');

export const getFullPageCount = async () => {
    var Countries = Parse.Object.extend("Countries");
    var query = new Parse.Query(Countries);
    query.equalTo("status", true);
    query.limit(1000);
    query.descending("createdAt");
    const count = await query.count();

    return count;
}

export const getSearchResults = async (searchdatas) => {

    var Countries = Parse.Object.extend("Countries");
    var query = new Parse.Query(Countries);
    query.limit(1000);
    query.descending("createdAt");
    query.equalTo("status", true);

    if (searchdatas['searchbox']) {
        query.matches("name", searchdatas['searchbox'], 'i');
    }
    if (searchdatas['countrycode']) {
        query.matches("country_code", searchdatas['countrycode'], 'i');
    }

    if (searchdatas['jurisdiction'] !== "") {
        query.equalTo('jurisdiction', {
            __type: 'Pointer',
            className: 'Jurisdictions',
            objectId: searchdatas['jurisdiction']
        });
    }

    if (searchdatas['region'] !== "") {
        query.equalTo('region', {
            __type: 'Pointer',
            className: 'Region',
            objectId: searchdatas['region']
        });
    }


    if (searchdatas['statsrc'] == "true") {
        query.equalTo("status", true);
    } else if (searchdatas['statsrc'] == "false") {
        query.equalTo("status", false);
    }
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });

}

export const getCountries = async (pagenum, dataperpage, sortitem, sortitemorder) => {
    var skipnum = pagenum - 1;
    var perpage = parseInt(dataperpage);

    var Countries = Parse.Object.extend("Countries");
    var query = new Parse.Query(Countries);
    query.include("jurisdiction");
    query.include("region");
    query.equalTo("status", true);

    if (sortitem !== "") {
        if (sortitemorder === "ascending") {
            query.ascending(sortitem);
        } else {
            query.descending(sortitem);
        }

    } else {
        query.descending('createdAt');
    }

    query.skip(skipnum * perpage);
    query.limit(perpage);

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const getJurisdictions = async () => {
    var Jurisdictions = Parse.Object.extend("Jurisdictions");
    var query = new Parse.Query(Jurisdictions);
    query.equalTo("status", true);
    query.ascending("name");
    var records = query.find();
    return records;
}

export const getRegions = async () => {
    var Region = Parse.Object.extend("Region");
    var query = new Parse.Query(Region);
    query.equalTo("status", true);
    query.ascending("name");
    var records = query.find();
    return records;
}


export const hideRecords = async (objectids) => {


    var parseobject = Parse.Object.extend("Countries");
    var query = new Parse.Query(parseobject);
    query.equalTo("objectId", objectids);

    return query.find().then((object) => {
        var realbject = object[0];
        realbject.set("status", false);
        return realbject.save().then((records) => {
            return true;
        }, (error) => {
            console.log(error);
        }).catch(function (error) {
        });

    }, (error) => {
    });



}


export const addRecord = async ({ name, country_code, description, status, jurisdata, regiondata }) => {
    var countries = new Parse.Object("Countries");
    countries.set("name", name);
    countries.set("country_code", country_code);
    countries.set("description", description);
    if (status === 'false') {
        countries.set("status", false);
    } else {
        countries.set("status", true);
    }

    var jurisarray = [];
    for (var i = 0; i < jurisdata.length; ++i) {
        var Jurisdictions = Parse.Object.extend("Jurisdictions");
        var jurisQuery = new Parse.Query(Jurisdictions);
        let validjuris = await jurisQuery.get(jurisdata[i]);
        jurisarray.push(validjuris);
    }
    countries.set("jurisdiction", jurisarray);

    var regionarray = [];
    for (var i = 0; i < regiondata.length; ++i) {
        var Region = Parse.Object.extend("Region");
        var regionQuery = new Parse.Query(Region);
        let validregion = await regionQuery.get(regiondata[i]);
        regionarray.push(validregion);
    }
    countries.set("region", regionarray);


    return countries.save().then((records) => {
        return records;
    }, (error) => {
        return false;
    }).catch(function (error) {
        return false;
    });
}


export const fetchRecordById = async ({ objectid }) => {
    var Countries = Parse.Object.extend("Countries");
    var query = new Parse.Query(Countries);
    query.equalTo("objectId", objectid);
    return query.find().then((records) => {
        return records;
    }, (error) => {
        return false;
    }).catch(function (error) {
        return false;
    });
}


export const updateRecord = async ({ edithiddenid, name, country_code, description, status, jurisdata, regiondata }) => {
    var Countries = Parse.Object.extend("Countries");
    var query = new Parse.Query(Countries);
    query.equalTo("objectId", edithiddenid);

    return query.find().then(async (object) => {

        var realbject = object[0];

        realbject.set("name", name);
        realbject.set("country_code", country_code);
        realbject.set("description", description);
        if (status === 'false') {
            realbject.set("status", false);
        } else {
            realbject.set("status", true);
        }

        var jurisarray = [];
        for (var i = 0; i < jurisdata.length; ++i) {
            var Jurisdictions = Parse.Object.extend("Jurisdictions");
            var jurisQuery = new Parse.Query(Jurisdictions);
            let validjuris = await jurisQuery.get(jurisdata[i]);
            jurisarray.push(validjuris);
        }
        realbject.set("jurisdiction", jurisarray);

        var regionarray = [];
        for (var i = 0; i < regiondata.length; ++i) {
            var Region = Parse.Object.extend("Region");
            var regionQuery = new Parse.Query(Region);
            let validregion = await regionQuery.get(regiondata[i]);
            regionarray.push(validregion);
        }
        realbject.set("region", regionarray);

        return realbject.save().then((records) => {
            return true;
        }, (error) => {
            console.log(error);
            return false;
        }).catch(function (error) {
            console.log(error);
            return false;
        });

    }, (error) => {
        return false;
    });

}




export const checkFieldValid = (addoredit) => {

    var name = jQuery('#bname');
    var country_code = jQuery('#country_code');
    var status = jQuery('#status');

    var errstat = true;
    if (name.val() === "") {
        name.addClass("is-invalid");
        errstat = false;
    } else {
        name.removeClass("is-invalid");
    }
    if (country_code.val() === "") {
        country_code.addClass("is-invalid");
        errstat = false;
    } else {
        country_code.removeClass("is-invalid");
    }
    if (status.val() === "") {
        status.addClass("is-invalid");
        errstat = false;
    } else {
        status.removeClass("is-invalid");
    }


    if (addoredit === "add") {

    }

    if (errstat === false) {
        return false;
    } else {
        return true;
    }

}

