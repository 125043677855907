import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Alert,
  CardHeader,
} from 'reactstrap';
import Select, { components } from "react-select";
import jQuery from 'jquery';

import { getCurrentUser, authenticateLoggedIn, addHeaderTitle, isSuperAdmin, authenticateLoginpage, addBreadcrumb } from '../../services/parse';
import { addRecord, checkFieldValid, isAdmin, getALJCompanies, getLawfirm, checkEmailExist } from '../../services/user';
var Parse = require('parse');

class addUser extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      collapse: true,
      fadeIn: true,
      timeout: 300,
      currentRole: '',
      addedclass: 'd-none',
      userexist: 'd-none',
      emailexist: 'd-none',
      emailinvalid: 'd-none',
      recordaddedclass: 'd-none',
      recordupdatedclass: 'd-none',
      recordByIdData: [],
      isEdit: false,
      companyList: [],
      lawfirmList: [],
      logofile: null,
      currentuserdetails: '',
      lawfirm: null,
      company: null,
    };
  }


  async componentDidMount(evt) {
    //auth start
    authenticateLoggedIn();
    addHeaderTitle('Add User');
    addBreadcrumb('Home / Users / Add User');


    var currentUser = Parse.User.current();
    if (currentUser) {
      isAdmin().then((value) => {
        var currentrole = value[0].get('role').get('name');
        this.setState({ currentRole: currentrole });
      });
    }

    getALJCompanies().then((value) => {
      this.setState({ companyList: value, });
    });
    getLawfirm().then((value) => {
      this.setState({ lawfirmList: value, });
    });


  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  randomPassword = (length) => {
    var chars = "abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890";
    var pass = "";
    for (var x = 0; x < length; x++) {
      var i = Math.floor(Math.random() * chars.length);
      pass += chars.charAt(i);
    }
    return pass;
  }

  generatePassword = () => {
    var randomPass = this.randomPassword(8);
    jQuery('#password').val(randomPass);
  }

  checkFirmGroup = () => {
    var role = jQuery('#role').val();

    if (role === "gbwpXDwpm7") {
      jQuery('#lawfirmformgroup').removeClass('d-none');
      jQuery('#companyformgroup').addClass('d-none');
      this.setState({ company: null });
    } else {
      jQuery('#companyformgroup').removeClass('d-none');
      jQuery('#lawfirmformgroup').addClass('d-none');
      this.setState({ lawfirm: null });
    }

  }


  checkEmailAvail = () => {
    var username = jQuery('#username').val();
    if (username !== "") {
      checkEmailExist(username).then((value) => {
        if (value == true) {
          jQuery('#emailexistalert').removeClass('d-none');
        } else {
          jQuery('#emailexistalert').addClass('d-none');
        }
      });
    }

  }


  addRecord = () => {

    var valid = checkFieldValid('add');
    if (valid !== false) {
      jQuery('#addbutton').prop('disabled', true);

      var fname = jQuery('#cname').val();
      var lname = jQuery('#lname').val();
      var username = jQuery('#username').val();
      var password = jQuery('#password').val();
      var role = jQuery('#role').val();
      var contact = jQuery('#contact').val();
      if (this.state.company) {
        var company = this.state.company.value;
      } else {
        var company = '';
      }
      if (this.state.lawfirm) {
        var lawfirm = this.state.lawfirm.value;
      } else {
        var lawfirm = '';
      }

      var tradenotify = jQuery('#tradenotify');
      if (tradenotify.is(":checked")) {
        var tradenotify = true;
      } else {
        var tradenotify = false;
      }


      var fielddatas = {
        fname: fname,
        lname: lname,
        username: username,
        password: password,
        role: role,
        contact: contact,
        company: company,
        lawfirm: lawfirm,
        tradenotify: tradenotify,
      };

      addRecord(fielddatas).then((value) => {

        if (value === 'userexist') {
          this.setState({ userexist: 'd-block' });
        } else if (value === 'emailexist') {
          this.setState({ emailexist: 'd-block' });
        } else if (value === 'emailinvalid') {
          this.setState({ emailinvalid: 'd-block' });
        } else {
          if (value === true) {
            this.setState({ recordaddedclass: 'd-block' });
          } else {
            this.setState({ recordaddedclass: 'd-none' });
          }
          this.setState({ userexist: 'd-none' });
          this.setState({ emailexist: 'd-none' });
        }

        // jQuery('#addform').trigger("reset");
        jQuery('#addbutton').prop('disabled', false);

        setTimeout(
          function () {
            this.setState({ recordaddedclass: 'd-none' });
            this.setState({ userexist: 'd-none' });
            this.setState({ emailexist: 'd-none' });
            this.setState({ emailinvalid: 'd-none' });
          }
            .bind(this),
          3000
        );

      });

    }
  }





  render() {

    var companystate = this.state.companyList;
    var companylist = [];
    if (companystate.length > 0) {
      for (let i = 0; i < companystate.length; i++) {
        companylist[i] = { value: companystate[i].id, label: companystate[i].attributes.name }
      }
    }

    var lawfirmstate = this.state.lawfirmList;
    var lawfirmList = [];
    if (lawfirmstate.length > 0) {
      for (let i = 0; i < lawfirmstate.length; i++) {
        lawfirmList[i] = { value: lawfirmstate[i].id, label: lawfirmstate[i].attributes.name }
      }
    }




    return <div className="animated fadeIn">

      {this.state.currentRole === "Admin" ?
        <div>
          <Row className="justify-content-end">
            <Col className="col-auto mb-4">
              <Link to="/user/user" className="btn btn-primary btncancle mr-3">
                <span className="border-left pl-2">Cancel</span>
              </Link>
              <Button type="button" color="primary" id="addbutton" onClick={this.addRecord}>
                <span className="border-left pl-2"></span>
                Save
              </Button>
            </Col>
          </Row>


          <Row>
            <Col xs="12" sm="6">
              <Card>
                <CardTitle>
                  <i className="icon-circle icon-pencil"></i>
                  Add User
                </CardTitle>

                <CardBody>

                  <Alert color="success" className={this.state.recordaddedclass}>
                    Record Added
                    <Link to="/user/user" className="float-right">
                      &nbsp; Go to list
                    </Link>
                  </Alert>
                  <Alert color="danger" className={this.state.userexist}>
                    Username/Email already exist
                  </Alert>
                  <Alert color="danger" className={this.state.emailexist}>
                    Email already exist
                  </Alert>
                  <Alert color="danger" className={this.state.emailinvalid}>
                    Email format is invalid
                  </Alert>

                  <form name="addform" id="addform">

                    <FormGroup className="form-group">
                      <Label htmlFor="company">First Name *</Label>
                      <Input type="text" id="cname" placeholder="First Name" />
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Label htmlFor="company">Last Name *</Label>
                      <Input type="text" id="lname" placeholder="Last Name" />
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Label htmlFor="company">Username (Email Address) *</Label>
                      <Input type="text" id="username" placeholder="Email Address" onBlur={this.checkEmailAvail} />
                    </FormGroup>
                    <Alert color="danger" id="notvalidemail" className="d-none">
                      Not a valid email
                    </Alert>
                    <Alert color="danger" id="emailexistalert" className="d-none">
                      Username already exists.
                    </Alert>


                    <FormGroup className="form-group  position-relative">
                      <Label htmlFor="company">Password *</Label>
                      <Input type="text" id="password" placeholder="Password" />
                      <i className="fas fa-sync-alt icon-gpwd" onClick={this.generatePassword}></i>
                    </FormGroup>
                    {/* <FormGroup className="form-group">
                      <Label htmlFor="company">Email</Label>
                      <Input type="text" id="email" placeholder="Email" />
                    </FormGroup> */}

                    <FormGroup className="form-group">
                      <Label htmlFor="Role">Role *</Label>
                      <select name="role" id="role" className="form-control" onChange={this.checkFirmGroup}>
                        <option value="">Please select</option>
                        <option value="mII3gxJlLE">Admin</option>
                        <option value="OIC4hHUVYp">Finance</option>
                        <option value="osSsE7BIUp">Commercial</option>
                        <option value="PYBlOPZkuu">Legal</option>
                        <option value="gbwpXDwpm7">External</option>
                        <option value="SY7sNL8DDp">Executive</option>
                      </select>
                    </FormGroup>


                    {/* <div className="customcheck mb-3 greybgcheck form-inline"> */}

                    {/* <FormGroup className="form-group">
                      <Label htmlFor="company"> Select any firm :</Label>
                      <div className="customcheck mb-3 greybgcheck form-inline">
                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" id="inlineCheckbox1" name="firmitem" value="company" onClick={this.checkFirmGroup} />
                          <label htmlFor="inlineCheckbox1">Company</label>
                        </div>

                        <div className="form-check form-check-inline">
                          <input className="form-check-input" type="radio" id="inlineCheckbox2" name="firmitem" value="lawfirm" onClick={this.checkFirmGroup} />
                          <label htmlFor="inlineCheckbox2">Lawfirm</label>
                        </div>
                      </div>
 
                    </FormGroup> */}

                    <FormGroup  id="companyformgroup" className="form-group d-none">
                      <Label>Company</Label>
                      <Select
                        id="company"
                        className="reactSelect reactSelectClass required"
                        closeMenuOnSelect={true}
                        options={companylist}
                        onChange={(selectedOption) => {
                          this.setState({ company: selectedOption });
                        }}
                        value={(this.state.company) ?
                          this.state.company
                          : []
                        }
                      />
                    </FormGroup>

                    <FormGroup  id="lawfirmformgroup" className="form-group d-none" >
                      <Label>Law Firm</Label>
                      <Select
                        id="lawfirm"
                        className="reactSelect reactSelectClass required"
                        closeMenuOnSelect={true}
                        options={lawfirmList}
                        onChange={(selectedOption) => {
                          this.setState({ lawfirm: selectedOption });
                        }}
                        value={(this.state.lawfirm) ?
                          this.state.lawfirm
                          : []
                        }
                      />


                    </FormGroup>

                    <FormGroup className="form-group">
                      <div className="customcheck greybgcheck yscroll form-inline">
                        <div className="form-check form-check-inline">
                          <Input type="checkbox" name="tradenotify" className="tradenotify" value="yes" id="tradenotify" />
                          <Label for="tradenotify">Notifications</Label>
                        </div>
                      </div>
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Label htmlFor="company">Contact</Label>
                      <Input type="text" id="contact" placeholder="contact" />
                    </FormGroup>


                  </form>
                </CardBody>

              </Card>
            </Col>
          </Row>
        </div>
        :
        <p>Only available for super-admin</p>
      }
    </div>
  }
}

export default addUser;
