import React from "react";

import {
	Row,
	Col,
	Card,
	CardBody,
	CardTitle,
	CardSubtitle,
	Input,
	ListGroup,
	ListGroupItem
} from 'reactstrap';

import Chart from 'react-c3-component';
import 'c3/c3.css';

import { getTrademarkStatus, getTrademarkProgress, getTrademarkProcessStatus } from '../../../services/dashboard';
import imgtrademark from '../../../assets/images/icons/icon-trademark.png';

class Trademark extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			certification: 0,
			registration: 0,
			opposition: 0,
			publication: 0,
			acceptance: 0,
			examination: 0,
			filing_application: 0,
			search: 0,
		};
	}

	async componentDidMount(evt) {

		var statresult = await getTrademarkProcessStatus();
		var certcount = 0;
		var regcount = 0;
		var oppcount = 0;
		var pubcount = 0;
		var acccount = 0;
		var excount = 0;
		var fillcount = 0;
		var srccount = 0;
		for (var i = 0; i < statresult.length; i++) {
			if (statresult[i].get('step')) {

				if (statresult[i].get('step').get('name') == "Certification") {
					certcount = certcount + 1;
				}
				if (statresult[i].get('step').get('name') == "Registration") {
					regcount = regcount + 1;
				}
				if (statresult[i].get('step').get('name') == "Opposition") {
					oppcount = oppcount + 1;
				}
				if (statresult[i].get('step').get('name') == "Publication") {
					pubcount = pubcount + 1;
				}
				if (statresult[i].get('step').get('name') == "Acceptance") {
					acccount = acccount + 1;
				}
				if (statresult[i].get('step').get('name') == "Examination") {
					excount = excount + 1;
				}
				if (statresult[i].get('step').get('name') == "Filing Application") {
					fillcount = fillcount + 1;
				}
				if (statresult[i].get('step').get('name') == "Search") {
					srccount = srccount + 1;
				}
			}

		}

		this.setState({ certification: certcount });
		this.setState({ registration: regcount });
		this.setState({ opposition: oppcount });
		this.setState({ publication: pubcount });
		this.setState({ acceptance: acccount });
		this.setState({ examination: excount });
		this.setState({ filing_application: fillcount });
		this.setState({ search: srccount });


	}


	render() {
		return (
			/*--------------------------------------------------------------------------------*/
			/* Used In Dashboard-1 [Classic]                                                  */
			/*--------------------------------------------------------------------------------*/
			<Card className="card-hover tradeprocess">
				<CardBody>
					<Row className="align-items-start">
						<Col className="fixedwidth">
							<div>
								<Chart
									style={{ height: '200px', width: '100%', color: '#414241' }}
									config={{
										data: {
											columns: [
												['Search', this.state.search],
												['Application', this.state.filing_application],
												['Examination', this.state.examination],
												['Acceptance', this.state.acceptance],
												['Publications', this.state.publication],
												['Opposition', this.state.opposition],
												['Registration', this.state.registration],
												['Certificate Issued', this.state.certification],
											],

											type: 'donut',
											tooltip: {
												show: true
											}
										},
										donut: {
											label: {
												show: false
											},
											title: "",
											width: 35,
										},
										legend: {
											hide: true
										},
										color: {
											pattern: ['#3dbacf', '#fef16b', '#f9b031', '#fd9059', '#ec5354', '#e23066', '#562f5e', '#7ec056']
										}
									}}
								/>
								<img src={imgtrademark} alt={"imgtrademark"} className="fixleft" />
							</div>
						</Col>
						<Col>
							<CardTitle>Trademark Process Status</CardTitle>
							<ListGroup className="twocollist-EX">
								<ListGroupItem><i className="fas fa-circle mr-1 circle-firozi font-12"></i> Search <span>({this.state.search})</span></ListGroupItem>
								<ListGroupItem><i className="fas fa-circle mr-1 circle-yellow font-12"></i> Application <span>({this.state.filing_application})</span></ListGroupItem>
								<ListGroupItem><i className="fas fa-circle mr-1 circle-yellow-dark font-12"></i> Examination <span>({this.state.examination})</span></ListGroupItem>
								<ListGroupItem><i className="fas fa-circle mr-1 circle-orange font-12"></i> Acceptance <span>({this.state.acceptance})</span></ListGroupItem>
								<ListGroupItem><i className="fas fa-circle mr-1 circle-red font-12"></i> Publications <span>({this.state.publication})</span></ListGroupItem>
								<ListGroupItem><i className="fas fa-circle mr-1 circle-pink font-12"></i> Opposition <span>({this.state.opposition})</span></ListGroupItem>
								<ListGroupItem><i className="fas fa-circle mr-1 circle-berry font-12"></i> Registration <span>({this.state.registration})</span></ListGroupItem>
								<ListGroupItem><i className="fas fa-circle mr-1 circle-green font-12"></i> Certificate Issued <span>({this.state.certification})</span></ListGroupItem>
							</ListGroup>
						</Col>
					</Row>
				</CardBody>
			</Card>


		);
	}
}

export default Trademark;
