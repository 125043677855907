import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormFeedback,
  CustomInput,
  Tooltip,
  UncontrolledTooltip
} from 'reactstrap';
import Select, { components } from "react-select";
import jQuery from 'jquery';
import FileUpload from '../../components/common/FileUpload';
import { uploadFile, getFileUrl } from '../../services/awsFileUpload';

import { getCurrentUser, authenticateLoggedIn, addHeaderTitle, isSuperAdmin, authenticateLoginpage, addBreadcrumb } from '../../services/parse';
import { addRecord, checkFieldValid, getCountries } from '../../services/lawfirm';

var Parse = require('parse');

class addLawfirm extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      collapse: true,
      fadeIn: true,
      timeout: 300,
      isSuper: '',
      addedclass: 'd-none',
      countriesData: [],
      isEdit: false,
      logofile: null,
      logoFileArray: [],
    };
  }


  async componentDidMount(evt) {
    //auth start
    authenticateLoggedIn();
    addHeaderTitle('Add Law Firm');
    addBreadcrumb('Home / Law firms / Add Law Firm');

    getCountries().then((value) => {
      this.setState({ countriesData: value, });
    });
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  fileChangedHandler = (event) => {
    const file = event.target.files[0];
    var parseFile = new Parse.File("logo.png", file);
    this.setState({ logofile: parseFile });

    jQuery('.custom-file-label').html(file['name']);
  }


  addRecord = () => {

    console.log(this.state.logoFileArray);

    var valid = checkFieldValid('add');
    if (valid !== false) {
      jQuery('#addbutton').prop('disabled', true);

      if (this.state.logofile) {
        this.state.logofile.save().then(function (file) {
          // The file has been saved to Parse. 
        }, function (error) {
        }).then((file) => {
        });
      }

      var name = jQuery('#bname').val();
      var lawfirm_code = jQuery('#lawfirm_code').val();
      var website_url = jQuery('#website_url').val();
      var contact_person = jQuery('#contact_person').val();
      var contact_email = jQuery('#contact_email').val();
      var address = jQuery('#address').val();
      var country = this.state.country; //jQuery('#country').val();
      var status = jQuery('#status').val();

      var fielddatas = {
        name: name,
        lawfirm_code: lawfirm_code,
        website_url: website_url,
        contact_person: contact_person,
        contact_email: contact_email,
        address: address,
        country: country,
        logo: this.state.logofile,
        status: status,
      };
      addRecord(fielddatas).then((value) => {
        if (value !== false) {
          this.setState({ addedclass: 'd-block' });

          setTimeout(
            function () {
              this.setState({ addedclass: 'd-none' });
              window.location.assign('#/lawfirm/editlawfirm/' + value.id);
            }
              .bind(this),
            1000
          );

        } else {
          this.setState({ addedclass: 'd-none' });
        }

        jQuery('#addform').trigger("reset");
        jQuery('#addbutton').prop('disabled', false);
      });

    }
  }


  onLogoFileSelected = async (event) => {
    console.log(event.target.files);
    var files = event.target.files
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        var fileType = files[i].type.split("/")[1];
        var fileName = files[i].name;
        console.log(fileType);
        var uploadStatus = await uploadFile('contentmaster', files[i]);
        console.log(uploadStatus);
        if (uploadStatus.success) {

          // this.setState({
          //   logoFileArray: [
          //     ...this.state.logoFileArray,
          //     { awsFileName: uploadStatus.filename, type: fileType, name: fileName }
          //   ]
          // });
        }
      }
    }
  }


  render() {
    var countrystate = this.state.countriesData;
    var countrylist = [];
    if (countrystate.length > 0) {
      for (let i = 0; i < countrystate.length; i++) {
        countrylist[i] = { value: countrystate[i].id, label: countrystate[i].attributes.name }
      }
    }




    return <div>

      <Row className="justify-content-between topbuttonbar">
        <Col className="col-md-5">
          <Alert color="success" className={this.state.addedclass}>
            Record Added
                  <Link to="/lawfirm/lawfirm" className="float-right">
              &nbsp; Go to list
                  </Link>
          </Alert>
        </Col>
        <Col className="col-auto mb-4">
          <Link to="/lawfirm/lawfirm" className="btn btn-primary btncancle mr-3">
            <span className="border-left pl-2">Cancel</span>
          </Link>
          <Button type="button" color="primary" id="addbutton" onClick={this.addRecord}>
            <span className="border-left pl-2"></span>
            Save
          </Button>
        </Col>
      </Row>

      <Row>

        <Col md="5">


          <Card>
            <CardTitle>
              <i className="icon-circle icon-pencil"></i>
              Add Law Firm
            </CardTitle>

            <CardBody>





              <Form id="addform">
                <FormGroup className="form-group">
                  <Label htmlFor="name">Name</Label>
                  <Input type="text" name="bname" id="bname" />
                </FormGroup>

                <FormGroup className="form-group">
                  <Label htmlFor="name">Lawfirm Code</Label>
                  <Input type="text" name="lawfirm_code" id="lawfirm_code" />
                </FormGroup>

                <FormGroup className="form-group">
                  <Label>Status</Label>
                  <select id="status" className="custom-select">
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                  </select>
                </FormGroup>

                <FormGroup className="form-group">
                  <Label htmlFor="name">Website url</Label>
                  <Input type="text" name="website_url" id="website_url" />
                </FormGroup>











                <FormGroup className="form-group">
                  <Label>Logo </Label>
                  <div className="custom-file">
                    <Input type="file" id="logofile" name="logofile" className="custom-file-input" onChange={this.fileChangedHandler} />
                    <label className="custom-file-label" htmlFor="logofile">Upload your logo here</label>
                  </div>
                </FormGroup>

                {/* <InputGroup>
                  <div className="custom-file">
                    <Input type="file" className="custom-file-input" id="inputGroupFile01" onChange={(event) => { this.onLogoFileSelected(event) }} />
                    <label className="custom-file-label" htmlFor="inputGroupFile01">Upload your file here</label>
                  </div>
                </InputGroup> */}

                {/* <FileUpload labelText="Upload Logo File" tooltipText="Hello world!" folderName="contentmaster"
                      isValid={true} inputText="Upload your file here" items={this.state.logoFileArray}
                      name="inputGroupFile01"
                      onDelete={(id) => {
                        var logoArray = this.state.logoFileArray.filter(obj => obj.id != id).map((item, index) => { return { ...item, id: index } });
                        this.setState({ logoFileArray: logoArray }, () => {
                        });
                      }}
                      addItems={(items) => {
                        console.log('addItem', items);
                        this.setState({
                          logoFileArray: [...this.state.logoFileArray, ...items]
                        }, () => {
                          console.log(this.state.logoFileArray);
                        });
                      }}
                      updateItem={(id, filename,url) => {
                        var objToChange = this.state.logoFileArray.find(x => x.id == id);
                        if (objToChange) {
                          this.setState({
                            logoFileArray: [...this.state.logoFileArray.filter(x => x.id != id),
                            { ...this.state.logoFileArray.find(x => x.id == id), awsFileName: filename,url:url }
                            ] 
                          }, () => { console.log(this.state.logoFileArray); });
                        }
                      }} 
                      showError={(message) => {
                        this.setState({ showAlertMessage: true, alertMessage: message })
                      }}
                    /> */}












                <FormGroup className="form-group">
                  <Label htmlFor="name">Contact Person</Label>
                  <Input type="text" name="contact_person" id="contact_person" />
                </FormGroup>

                <FormGroup className="form-group">
                  <Label htmlFor="name">Contact email</Label>
                  <Input type="text" name="contact_email" id="contact_email" />
                </FormGroup>

                <FormGroup className="form-group">
                  <Label htmlFor="description">Address</Label>
                  <Input type="textarea" name="address" id="address" rows="5" placeholder="Address" />
                </FormGroup>

                <FormGroup className="form-group">
                  <Label htmlFor="name">Country</Label>
                  <Select
                    id="country"
                    className="reactSelect reactSelectClass"
                    closeMenuOnSelect={true}
                    options={countrylist}
                    onChange={(selectedOption) => {
                      this.setState({ country: selectedOption.value });
                    }}
                  />
                </FormGroup>








              </Form>
            </CardBody>
          </Card>

        </Col>
      </Row>

    </div>
  }
}

export default addLawfirm;
