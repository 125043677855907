import React, { Component } from 'react';
import {
    Card,CardBody,CardTitle,Row,Col,Form,FormGroup,Label,Input,FormText,Button,InputGroup,UncontrolledTooltip,Alert
} from 'reactstrap';
import jQuery from 'jquery';
import {currentUser,getStatusNameFromValue} from '../../services/trademark';
import TradeMarkRenewalsForm from './tradeMarkRenewalsForm.jsx';
import { getTrademakProgresses, getTrademakProgressStatus,getTrademarkProgressById,convertStateObjToParseObj,convertParseObjTOStateObj,saveTrademarkProgress,
    getTrademarkProgressVersions,getTrademarkProgressVersionsObjectById,updateTrademark, saveTrademarkProgressImport } from '../../services/trademarkProgress';
import { getTrademarkObjectById } from '../../services/trademark';
import fileIcon from '../../assets/images/icons/file-icon.png';
import imageIcon from '../../assets/images/icons/image-icon.png';
import Loader from '../../components/common/Loader';  
import {getTrademarkChatsById,postTrademarkChat,getUserFullName} from '../../services/trademarkChats';
import Moment from 'moment';
import FileUpload from '../../components/common/FileUpload';
import DatePicker from "react-datepicker";
import {sendEmail,sendProcessStepCompletionEmail} from '../../services/email';
import {addLog} from '../../services/trademark';
import { isAdmin } from '../../services/user';
import defaultuser from '../../assets/images/icons/default-user.png';
import {addUpdateRenewals,getTrademarkRenewalsById,deleteRenewalRecord,postTrademarkRenewalChat} from '../../services/trademarkRenewals'
import { indexBy } from 'underscore';
import {uploadFile,getFileUrl} from '../../services/awsFileUpload';

class progressStatusContainer extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            popoverOpen: false
        };

        this.state = {
            email: '',
            title: '',
            savedToCloud: false,
            tradeMarkProgress: [],
            tradeMarkProgressStatus: [],
            currentProgressId: null,
            trademark: null,
            index:-1,
            popovers: [
                {
                    placement: 'top',
                    text: 'Top'
                },
                {
                    placement: 'bottom',
                    text: 'Bottom'
                },
                {
                    placement: 'left',
                    text: 'Left'
                },
                {
                    placement: 'right',
                    text: 'Right'
                }
            ],
            currentUserDetails: "",
            user:null,
            mainLoading:false,  
            username:'',       
            tdClassName:null,
            tdIcon:'icon-pencil',
            ndClassName:null,
            ndIcon:'icon-pencil',
          versionCount:[],
          showSaveButton:true,
          showAlertMessage : false,
      alertMessage:'',
      showVersionText:false,
      currentVersion:'',
      MainRegNo:"",
      MainNextRnwlDate:null,
          mainId:'',
          message:[],
          chats:[],
          showChatLoader:false,
          fileUploadMandatory:false,
          fileUploadIsValid:true,
          editmode:false,
          mainData:[{
              id:'',
              processStatus:'',
              trademarkStatus:this.props.progressId,
              user:'',
              trademark:this.props.trademarkId,
              notes:'',
              assetsFileArray:[],
              versionCount:0,
              applicationNumber:'',
              applicationDate:null,
              registrationNumber:'',
              registrationDate:null,
              nextRenewalDate:null,
              usedDate:null,
              statusNotes:'',
              tdIcon:'icon-pencil',
              tdClassName:null,
              chatPostEnabled:false
          }]
        };
    }

    toggle() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }

    componentWillMount = async () => {
        var isadminval = await isAdmin();
      if (isadminval) {
        var currentrole = isadminval[0].get('role').get('name');
        console.log(currentrole);
        this.setState({ currentRole: currentrole });
        if ( currentrole === "Finance") {
          this.setState({ editmode: false});
        }
        else
        {
          this.setState({ editmode: true});
        }
        
        if(this.props.id)
        {

            console.log("renewal trd id",this.props.id)
            var records = await getTrademarkRenewalsById(this.props.id)
            if(records)
            {
                var mainObj = []
                records.map((item,index)=>{
                    console.log(item)
                    var tempobj = {
                        id:item.id,
                        processStatus:item.get("processStatus"),
                        trademarkStatus:this.props.progressId,
                        user:item.get('user'),
                        trademark:this.props.trademarkId,
                        notes:item.get("notes"),
                        assetsFileArray:item.get("assetFiles"),
                        versionCount:0,
                        applicationNumber:'',
                        applicationDate:null,
                        registrationNumber:'',
                        registrationDate:item.get("registrationDate"),
                        nextRenewalDate:item.get("nextRenewalDate"),
                        usedDate:null,
                        statusNotes:item.get("statusNotes"),
                        tdIcon:'icon-pencil',
                        tdClassName:null,
                        chatPostEnabled:true
                    }
                    mainObj.push(tempobj)
                })
                this.setState({mainData:mainObj})
            }
            var obj = await getTrademarkProgressById(this.props.id,"Vk3AZ1bwHv");
            if(obj)
            {
                console.log(obj.get("registrationNumber"))
                this.setState({ MainRegNo: obj.get("registrationNumber"),MainNextRnwlDate:obj.get("nextRenewalDate")});
            }
        }
      }
        jQuery(".opendefault").next('.card-body').show();
        jQuery(".opendefault").addClass('active');
        var cuser = await currentUser(); 
        if (cuser) {  
            this.setState({currentUserDetails:cuser[0]});  
        }
    }

    addRenewalItem = async()=>{
       
        this.setState({mainData:[...this.state.mainData,
            {
                id:'',
                processStatus:'',
                trademarkStatus:this.props.progressId,
                user:'',
                trademark:this.props.trademarkId,
                notes:'',
                assetsFileArray:[],
                versionCount:0,
                applicationNumber:'',
                applicationDate:null,
                registrationNumber:'',
                registrationDate:null,
                nextRenewalDate:null,
                usedDate:null,
                statusNotes:'',
                tdIcon:'icon-pencil',
                tdClassName:null,
                chatPostEnabled:false
            }
        ]})
    }

    clearRenewalItem = async(index,id)=>{
        var tempmd = this.state.mainData;
        console.log("index",index)
        tempmd[index] = {...tempmd[index],
            processStatus:'',
            trademarkStatus:this.props.progressId,
            user:'',
            trademark:this.props.trademarkId,
            notes:'',
            assetsFileArray:[],
            versionCount:0,
            applicationNumber:'',
            applicationDate:null,
            registrationNumber:'',
            registrationDate:null,
            nextRenewalDate:null,
            usedDate:null,
            statusNotes:'',
            tdIcon:'icon-pencil',
            tdClassName:null,
            chatPostEnabled:false
        }
        this.setState({mainData:tempmd})
    }

    deleteRenewalItem = async(index,id)=>{
        var tempmd = this.state.mainData
        tempmd = tempmd.slice(0,index).concat(tempmd.slice(index+1))
        this.setState({mainData:tempmd},()=>{
            if(this.state.mainData.length==0)
            {
                this.setState({mainData:[{
                    id:'',
                    processStatus:'',
                    trademarkStatus:this.props.progressId,
                    user:'',
                    trademark:this.props.trademarkId,
                    notes:'',
                    assetsFileArray:[],
                    versionCount:0,
                    applicationNumber:'',
                    applicationDate:null,
                    registrationNumber:'',
                    registrationDate:null,
                    nextRenewalDate:null,
                    usedDate:null,
                    statusNotes:'',
                    tdIcon:'icon-pencil',
                    tdClassName:null,
                    chatPostEnabled:false
                }]})
            }
        })
        if(id)
        {
            deleteRenewalRecord(id)
            console.log("deleteing from db")
        }


    }
    
    async componentDidMount(evt) {
        jQuery(".opendefault").next('.card-body').show();
        jQuery(".opendefault").addClass('active');
    }

    validateTDSection = async()=>{
        this.setState({fileUploadIsValid:true})
        var valid = true;
        var fileValid = true;
        var mainData = this.state.mainData;
        jQuery("#mainForm input").removeClass('is-invalid');
        jQuery(".reactSelect").removeClass('is-invalid');
        jQuery("errorSpan").hide();
        
        jQuery("#textNotes").removeClass('is-invalid');
        jQuery(".reactSelect").removeClass('is-invalid');
        var tempmd = this.state.mainData;
        var datalen = mainData.length;
        this.state.mainData.map((item,index)=>{
            var insValid = true;
            console.log(item,index)
            if(item.processStatus=='')
            {
                valid = false;
                insValid = false;
                jQuery('#ddlStatus'+index).addClass('is-invalid');
            }
            if(item.registrationDate==null)
            {
                valid = false;
                insValid = false;
                jQuery('#registrationDate'+index).addClass('is-invalid');
            }
            else
            {
                var dateToCheck = this.state.MainNextRnwlDate
                if(datalen>1 && index>1)
                {
                    dateToCheck = mainData[index-1].nextRenewalDate
                }
                var isbfr = Moment(item.registrationDate).isBefore(dateToCheck,"minute");
                console.log(isbfr)
                // if(!isbfr)
                // {
                //     valid = false;
                //     insValid = false;
                //     jQuery('#registrationDate'+index).addClass('is-invalid');
                //     jQuery('#registrationDateError'+index).show();
                // }
                // else
                // {
                //     jQuery('#registrationDateError'+index).hide();
                // }
                jQuery('#registrationDateError'+index).hide();
            }
            if(item.nextRenewalDate==null)
            {
                valid = false;
                insValid = false;
                jQuery('#nextRenewalDate'+index).addClass('is-invalid');
            }
            else
            {
                var dateToCheck = this.state.MainNextRnwlDate
                if(datalen>1 && index>1)
                {
                    dateToCheck = mainData[index-1].nextRenewalDate
                }
                var isafter = Moment(item.nextRenewalDate).isAfter(dateToCheck,"minute");
                console.log(isafter)
                // if(!isafter)
                // {
                //     valid = false;
                //     insValid = false;
                //     jQuery('#nextRenewalDate'+index).addClass('is-invalid');
                //     jQuery('#nextRenewalDateError'+index).show();
                // }
                // else
                // {
                //     jQuery('#nextRenewalDateError'+index).hide();
                // }
                jQuery('#nextRenewalDateError'+index).hide();
            }
            
            console.log(valid,index)
            if(insValid)
            {
                
                tempmd[index] = {...tempmd[index],tdClassName:"completed",tdIcon:"fas fa-check"}
                
            }
            else
            {
                
                tempmd[index] = {...tempmd[index],tdClassName:"failed",tdIcon:"fas fa-exclamation"}
               
            }
        })
        this.setState({mainData:tempmd})
        
       
    
        if(valid)
        {
            this.setState({tdClassName:"completed",tdIcon:"fas fa-check",tdLoading:false})
        }
        else
        {
            this.setState({tdClassName:"failed",tdIcon:"fas fa-exclamation",tdLoading:false,fileUploadIsValid:fileValid})
        }
        return valid;
    }


    submitData = async() => { 
        this.setState({mainLoading:true,showAlertMessage:false})
        var validTD = await this.validateTDSection();
        if(validTD)
        {
            //alert('valid');
            var tempObj = await addUpdateRenewals(this.state.mainData,this.props.id);
            console.log(tempObj);
            
            if(tempObj)
            {
                var alertMessage ='Record has been successfully saved.';
                
                // var tempmd = this.state.mainData
                // tempObj.map((item,index)=>{
                //     console.log(item,index)
                //     if(tempmd[index].id=="")
                //     {
                //         tempmd[index] = {...tempmd[index],id:item};
                //     }

                // })

                // tempmd.map((item,index)=>{
                //     if(item.id=="")
                //     {
                //         tempmd[index]= {...tempmd[index],id:tempObj.ids[index]};
                //     }
                
                // })
                this.setState({mainLoading:false,showAlertMessage:true,alertMessage:alertMessage,mainData:tempObj},()=>{
                    console.log(this.state.mainData)
                })
                
            }
            else{
                this.setState({showErrorAlert:true,mainLoading:false});
            }
        }   
        else
        {
            this.setState({showErrorAlert:true,mainLoading:false});
        }
        
    }

    postChat = async(id,index)=>{
        this.setState({showChatLoader:true},async()=>{
            var valid = true;
            var mainData = this.state.mainData;
            jQuery("#chatForm input").removeClass('is-invalid');
            jQuery(".reactSelect").removeClass('is-invalid');
            if(this.state.message[index]=='')
            {
                valid = false;
                jQuery('#msgnote'+index).addClass('is-invalid');
                this.setState({ndClassName:"failed",ndIcon:"fas fa-exclamation",ndLoading:false,showChatLoader:false})
            }
            else
            {
                
                this.setState({ndClassName:"completed",ndIcon:"fas fa-check",ndLoading:false},async()=>{
                    await postTrademarkRenewalChat(this.state.message[index],this.props.id,id);
                    var chats =  await getTrademarkChatsById(this.props.trademarkId,this.props.progressId);            
                    this.setState({chats:chats,message:'',showChatLoader:false}); 
                });
            }
        })
    }

    h5Click = (event) => {
        // jQuery(event.target).parent().siblings('.card').find('.card-body').slideUp('slow');
        // jQuery(event.target).parent().siblings('.card').find('h5').removeClass('active');
        jQuery(event.target).parent().find('.card-body').slideToggle('slow');
        jQuery(event.target).toggleClass('active');
    }

    render() {


        return (
            <Card>
                {/* <CardTitle><i className="icon-circle icon-pencil"></i>Trademark Registration</CardTitle> */}
                <CardBody>
                    <div className='example'>
                        
                        
                    <div className="step step1 mt-5">
            <Row className="justify-content-between topbuttonbar">
            <Col className="col-md-7">
            {this.state.showAlertMessage?
            <Alert color="success" className="d-block">
            {this.state.alertMessage}
            <span onClick={()=>{this.setState({showAlertMessage:false})}} className="float-right">X</span>
            </Alert>:null}
            </Col>
            <Col className="col-auto mb-4">
            <a href="javascript:" className="btn btn-primary ml-3" id="savebtn" onClick={()=>{this.addRenewalItem()}}> <span>Add</span></a> 
            {
            this.state.editmode && this.state.showSaveButton?
            this.state.mainLoading? <div className="btn btn-primary ml-3">
            <Loader type="Oval" color="#414241" height={20} width={20} />
            </div>
            :<a href="javascript:" className="btn btn-primary ml-3" id="savebtn" onClick={()=>{this.submitData()}}> <span>Save</span></a> 
            :null
            }


            
            
            </Col>
            </Row>
		
            {this.state.mainData.map((item,index)=>{return(
                  
               

			<Card className="customAcc">
                    <CardTitle onClick={this.h5Click.bind(this)} className={"opendefault "+this.state.mainData[index].tdClassName}>
                   {this.state.tdIcon?<i className={"icon-circle "+this.state.mainData[index].tdIcon}></i>:null} 
                      Trademark Renewal {index + 1}
                    </CardTitle>
                    <CardBody className="cardBodyNew">
                    {/* <Row className="justify-content-between topbuttonbar">
                        <Col className="col-md-7">
                        
                        </Col>
                        <Col className="col-auto mb-4">
                            
                            <a href="javascript:" className="btn btn-primary ml-3" style={{padding:0}} onClick={()=>{this.clearRenewalItem(index,item.id)}}> <span>Clear</span></a>
                            <a href="javascript:" className="btn btn-primary ml-3" style={{padding:0}} onClick={()=>{this.deleteRenewalItem(index,item.id)}}> <span>Delete</span></a> 
    
                            
                        </Col>
                    </Row> */}
                    <Form id="mainForm">
                    <Row className="renewalsForm">
                    
                    <Col md="6">
                   
                      

                    <FormGroup className="form-group">
                          <Label htmlFor="trademarkname">Registration No *<i className="far fa-question-circle ml-1" href="javascript:" id="UncontrolledTooltipExample1"></i></Label>
                      <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample1">
                        Hello world!
                      </UncontrolledTooltip>
                            <Input type="text" rows="5" id={"statusNotes"+index} name="statusNotes"  readOnly value={this.state.MainRegNo} />
                        </FormGroup>

                     
               
                        <FormGroup className="form-group">
                            <Label htmlFor="exampleEmail12">Renewal Date *<i className="far fa-question-circle ml-1" href="javascript:" id={"logoTypeDate"+index}></i></Label>
                        <UncontrolledTooltip placement="right" target="logoTypeDate">
                          Hello world!
                        </UncontrolledTooltip>
                        <div className="autocomwrap">
                            <DatePicker
                                id={"registrationDate"+index}
                                selected={this.state.mainData[index].registrationDate}
                                onSelect={(date)=>{
                                    var tempmd = this.state.mainData;
                                tempmd[index] = {...tempmd[index],registrationDate:date} 
                                this.setState({mainData:tempmd}) }}
                                onChange={(date)=>{
                                    var tempmd = this.state.mainData;
                                tempmd[index] = {...tempmd[index],registrationDate:date} 
                                this.setState({mainData:tempmd}) }}
                                className="form-control"
                                dateFormat="d/M/yyyy"
                                showYearDropdown
                                showMonthDropdown
                            />
                            
                            <span className="errorSpan" id={"registrationDateError"+index}  style={{color: "#fb6b6b" ,display:"none"}}>Renewal date is not continuous.</span>  
                          </div>
                          </FormGroup>

                          <FormGroup className="form-group">
                            <Label htmlFor="exampleEmail12">Next Expiry Date *<i className="far fa-question-circle ml-1" href="javascript:" id={"logoTypeDate"+index}></i></Label>
                        <UncontrolledTooltip placement="right" target="logoTypeDate">
                          Hello world!
                        </UncontrolledTooltip>
                        <div className="autocomwrap">
                            <DatePicker
                                id={"nextRenewalDate"+index}
                                selected={this.state.mainData[index].nextRenewalDate}
                                onSelect={(date)=>{var tempmd = this.state.mainData;
                                    tempmd[index] = {...tempmd[index],nextRenewalDate:date} 
                                    this.setState({mainData:tempmd})}}
                                onChange={(date)=>{var tempmd = this.state.mainData;
                                    tempmd[index] = {...tempmd[index],nextRenewalDate:date} 
                                    this.setState({mainData:tempmd})}}
                                className="form-control"
                                dateFormat="d/M/yyyy"
                                showYearDropdown
                                showMonthDropdown
                            />
                            <span className="errorSpan" id={"nextRenewalDateError"+index} style={{color: "#fb6b6b",display:"none"}}>Next renewal date is not continuous.</span> 
                            
                            
                          </div>
                          </FormGroup>

                       

                   

                        <FormGroup className="form-group">
                          <Label htmlFor="exampleEmail12">Status *<i className="far fa-question-circle ml-1" href="javascript:" id="UncontrolledTooltipExample4"></i></Label>
                      <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample4">
                        Hello world!
                      </UncontrolledTooltip>
                          <Input type="select" id={"ddlStatus"+index}name="ddlStatus" className="custom-select reactSelect" value={this.state.mainData[index].processStatus} onChange={(event)=>{
                              if(event.target.value!='')
                              {
                                //console.log(event.target.value);
                                var tempmd = this.state.mainData;
                                tempmd[index] = {...tempmd[index],processStatus:event.target.value};

                               this.setState({mainData:tempmd},()=>{
                                  //  if(this.state.mainData[index].processStatus=="completed" && this.props.progressId=='wLJe98HUcP')
                                  //  {
                                       
                                  //       this.setState({fileUploadMandatory:true})
                                  //  }
                                  //  else
                                  //  {
                                  //       this.setState({fileUploadMandatory:false})
                                  //  }
                               })   
                              }
                          }}>
                              <option value="">Select</option>
                              <option value="abandoned">Abandoned</option>
                              <option value="awaitingCertificateOfRegistration">Awaiting Certificate Of Registration</option>
                              <option value="completed">Completed</option>
                              <option value="conditionalAcceptance">Conditional Acceptance</option>
                              <option value="inProgress">In Progress</option>
                              <option value="lapsed">Lapsed</option>
                              <option value="notStarted">Not Started</option>
                              <option value="petitionUnderExamination">Petition Under Examination</option>
                              <option value="registrationFeesPaid">Registration Fees Paid</option>
                              <option value="rejected">Rejected</option>
                              <option value="requestRecieved">Request Received</option>
                              <option value="statusUnknown">Status Unknown</option>
                              <option value="withdrawn">Withdrawn</option>
                              {/* {this.props.progressId=="UZ2JCchSH0"?
                              <>
                              <option value="abandoned">Abandoned</option>
                              <option value="rejected">Rejected</option>
                              <option value="withdrawn">Withdrawn</option>
                              </>
                              :null} */}
                            </Input>
                        </FormGroup>

                        
                   
                        
                      
                      </Col>

                      <Col md="6">

                      <FileUpload  labelText="Select Files" isMandatory={this.state.fileUploadMandatory} tooltipText="Hello world!"
                                    isValid={this.state.fileUploadIsValid} inputText="Upload your file here" items={this.state.mainData[index].assetsFileArray}
                                    name={"inputGroupFile"+index}
                                    folderName="trademarks"
                                    onDelete={(id)=>{
                                        var logoArray=this.state.mainData[index].assetsFileArray.filter(obj=>obj.id!=id).map((item,index)=>{return {...item,id:index}});
                                        var tempmd = this.state.mainData;
                                        tempmd[index] = {...tempmd[index],assetsFileArray:logoArray}  
                                        this.setState({mainData:tempmd});}}
                                    addItems={(items)=>{
                                        console.log('addItem',items);
                                        var tempmd = this.state.mainData;
                                        tempmd[index] = {...tempmd[index],assetsFileArray:[...tempmd[index].assetsFileArray,...items]} 
                                        this.setState({                                        
                                        mainData:tempmd
                                    },()=>{
                                        console.log(this.state.mainData[index].assetsFileArray);
                                    });}}
                                    updateItem={(id,filename,url)=>{
                                        var objToChange = this.state.mainData[index].assetsFileArray.find(x=>x.id==id);
                                        if(objToChange)
                                        {
                                            var tempmd = this.state.mainData;
                                            tempmd[index] = {...tempmd[index],assetsFileArray:[...tempmd[index].assetsFileArray.filter(x=>x.id!=id),
                                                {...tempmd[index].assetsFileArray.find(x=>x.id==id),awsFileName:filename,url:url}
                                                ]}
                                            this.setState({                                        
                                                mainData:tempmd
                                            },()=>{//console.log(this.state.mainData[index].assetsFileArray);
                                            });
                                        }   
                                    }}     
                                    showError={(message)=>{
                                        this.setState({showAlertMessage:true,alertMessage:message})
                                    }}
                                    />

                      <FormGroup className="form-group">
                          <Label htmlFor="trademarkname">Notes <i className="far fa-question-circle ml-1" href="javascript:" id="UncontrolledTooltipExample"></i></Label>
                      <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample">
                        Hello world!
                      </UncontrolledTooltip>
                            <Input type="textarea" rows="5" id={"textNotes"+index} name="textNotes" placeholder="Add your notes here..." value={this.state.mainData[index].notes} onChange={(event)=>{                                
                              var tempmd = this.state.mainData;
                              tempmd[index] = {...tempmd[index],notes:event.target.value} 
                              this.setState({mainData:tempmd})  
                            }}/>
                        </FormGroup>

                      <FormGroup className="form-group">
                          <Label htmlFor="trademarkname">Status Notes <i className="far fa-question-circle ml-1" href="javascript:" id="UncontrolledTooltipExample1"></i></Label>
                      <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample1">
                        Hello world!
                      </UncontrolledTooltip>
                            <Input type="textarea" rows="5" id={"statusNotes"+index} name="statusNotes" placeholder="Add your status notes here..." value={this.state.mainData[index].statusNotes} onChange={(event)=>{                                
                                var tempmd = this.state.mainData;
                                tempmd[index] = {...tempmd[index],statusNotes:event.target.value} 
                                this.setState({mainData:tempmd}) 
                            }}/>
                        </FormGroup>


                      
                         
                          {/* <>
                          <Form id="chatForm" Style="overflow:hidden">
                          <div className="d-flex align-items-center pb-3">
                          <img src={defaultuser} alt={"defaultuser"} className="rounded-circle mr-2" width="25" />
                          <h5 className="notes-title">{this.state.username}</h5>
                          
                          </div>
                           <FormGroup className="form-group">
                               <Input type="textarea" rows="4" placeholder="Add your comments here..." id={"msgnote"+index} name="msgnote" value={this.state.message[index]} 
                            
                                   />
                           </FormGroup>
                           {
                           this.state.showChatLoader?
                           <div className="btn btn-primary float-right pl-5 pr-5">
                           <Loader type="Oval" color="#414241" height={20} width={20} />
                           </div>:
                           
                           <Button color="primary" className="float-right pl-5 pr-5" 
                           >Post</Button>
                           
                           }
   
                           
                         </Form>
                         <div className="yscroll310">
                           {this.state.chats.length>0?
                               this.state.chats.map((item,index)=>{
                                   return(
                                       <div className="postcmbx" key={item.id}>
                                           <div className="d-flex align-items-center pb-3">
                                               <img src={defaultuser} alt={"defaultuser"} className="rounded-circle mr-2" width="50" />
                                               <h5 className="notes-title">{item.attributes.user.attributes.firstName+" "+item.attributes.user.attributes.lastName} <span>{Moment(item.attributes.createdAt).format('DD-MM-YYYY H:mm')}</span></h5>
                                               <img src={defaultuser} alt={"defaultuser"} className="rounded-circle ml-auto align-self-start" width="25" />
                                           </div>
                                           <p>{item.attributes.message} </p>
                                       </div>
                                   )
                               })
                               :null}
                           </div>
                           </> */}
                         
        
</Col>
</Row>
</Form>
                

                    </CardBody>
                  </Card>

)}
               )}
                  
           
          
                  
									

									
								
			</div>
                    </div>
                </CardBody>
            </Card>

        )
    }
}

export default progressStatusContainer;
