// import React from 'react';
import React, { Component, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Row, Col, Carousel, CarouselItem, CarouselControl, Button, Table, Input, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, ModalFooter, Label, Alert, UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem, } from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
import jQuery from 'jquery';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import imageDummy from '../../../assets/images/dummy.jpg';
import noImage from '../../../assets/images/upload-paste.jpg';
import iconLink from '../../../assets/images/icons/icon-link.png';
import iconLinkBlue from '../../../assets/images/icons/icon-link-blue.png';
import iconEdit from '../../../assets/images/icons/icon-edit.png';
import iconDelete from '../../../assets/images/icons/icon-delete.png';
import iconCloseWhite from '../../../assets/images/icons/close-white.png';
import Loader from '../../../components/common/Loader';

import { uploadFile, uploadFileToAWSCustom, getFileUrl } from '../../../services/awsFileUpload';
import { fetchRecordById, updateRecord, getLicenseType, checkFieldValid } from '../../../services/medialicenselibraries';
import { LOGO_UPLOAD_SIZE } from '../../../config/constant';

function showImageLink() {
  document.getElementById("linkinput").classList.toggle("active");
}
function clearEditImage() {
  document.getElementById('imagelink').value = "";
}

class Editlibraries extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      recordByIdData: []
    }

    this.state = {
      loader: false,
      addedclass: "d-none",
      // licenseItems: null,
      imageError: "",
      tempImageView: null,
      imageFileArray: [],
      mainImagefile: [],

      name: "",
      libraryimage: [],
      allowedlicense: [],
      terms: "",
      website: "",
      description: "",
      status: true,
      recordstatus: "",
      recordstatusprev: "",
    };

  }


  async componentDidMount(evt) {

    // var licensetypes = await getLicenseType();
    // this.setState({ licenseItems: licensetypes, });

  }


  async componentDidUpdate(prevProps, prevState) {


    if (this.props.editObjectID && prevProps.editObjectID !== this.props.editObjectID) {

      console.log("obj edit" + this.props.editObjectID)

      var licensetypes = await fetchRecordById({ objectid: this.props.editObjectID });
      if (licensetypes.length > 0) {


        console.log("licensetypes" + licensetypes);




        // this.setState({ recordByIdData: [] });
        this.setState({ tempImageView: null });
        this.setState({ recordByIdData: licensetypes[0] });
        this.setState({
          tempImageView: null,
          name: licensetypes[0].get('name'),
          terms: licensetypes[0].get('terms'),
          website: licensetypes[0].get('website'),
          description: licensetypes[0].get('description'),
          status: licensetypes[0].get('status'),
          recordstatus: licensetypes[0].get('recordstatus'),
          recordstatusprev: licensetypes[0].get('recordstatus'),
        });



        var assetarrays = licensetypes[0].get('allowedlicense');
        var assetids = [];
        if (assetarrays) {
          for (var i = 0; i < assetarrays.length; i++) {
            assetids.push(assetarrays[i].id);
          }
          console.log(assetids)
          this.setState({ allowedlicense: assetids });
        }



        if (licensetypes[0].get('libraryimage') && licensetypes[0].get('libraryimage').length > 0) {
          var res = await getFileUrl("contentmaster", licensetypes[0].get('libraryimage')[0].awsFileName);
          this.setState({ tempImageView: res.url });
        }

      }


    }
  }



  // async componentWillReceiveProps() {
  //   console.log(this.props)
  //   if (this.props.isOpen && this.props.editObjectID) {
  //     this.setState({ recordByIdData: 'gggfddf' });
  //     console.log('ggg');
  //   }
  // }



  uploadFileSelected = async (event) => {
    var files = event
    var itemCounts = files.length;
    console.log('itemCounts', itemCounts);
    var itemsToAdd = [];
    var itemsAdded = [];
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].size < LOGO_UPLOAD_SIZE) {
          var fileType = files[i].type.split("/")[1];
          var fileName = files[i].name;
          var fileSize = (files[i].size / 1000000).toFixed(2);

          var obj = { awsFileName: null, type: fileType, name: fileName, id: itemCounts, size: fileSize }
          itemsToAdd.push(obj);
          var uploadeditem = await uploadFileToAWSCustom(files[i], obj, "contentmaster");
          itemsAdded.push(uploadeditem);
          itemCounts++;
        }
        else {
          console.log('File ' + files[i].name + ' exceeds maximum file size of 30MB')
        }
      }
      if (itemsAdded.length > 0) {
        this.setState({
          imageFileArray: itemsAdded
        });
      }

      return itemsAdded;

    }
  }

  fileChangedHandler = (event) => {
    this.setUpImage(event.target)
  }
  handlePaste = (e) => {
    if (e.clipboardData.files.length) {
      this.setUpImage(e.clipboardData)
    }
  };


  setUpImage = (e) => {
    if (e.files.length) {
      const file = e.files[0];
      if (file) {
        const fileSize = file.size;
        if ((file.type === "image/png" || file.type === "image/gif" || file.type === "image/jpeg" || file.type === "image/webp") && fileSize <= 1000000) {
          this.setState({ mainImagefile: e.files, imageError: "" });
          this.readFileToImage(e)
        } else {
          this.setState({ imageError: "Please upload image less than 1 mb" });
        }
      }
    }
  };

  readFileToImage = (input) => {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        this.setState({ tempImageView: e.target.result });
      }.bind(this);
      reader.readAsDataURL(input.files[0]);
    }
  }



  licensecheck = async () => {
    var licdata = [];
    jQuery.each(jQuery("input[name='licensetype']:checked"), function () {
      licdata.push(jQuery(this).val());
    });

    console.log(licdata)

    this.setState({ allowedlicense: licdata });

  }












  updateRecords = async () => {


    // this.props.editRecordStatus("edited");


    var valid = checkFieldValid('edit', this.state);
    if (valid !== false) {
      this.setState({ loader: true });

      jQuery('#savebutton').prop('disabled', true);

      let images = [];
      if (this.state.mainImagefile.length > 0) {
        images = await this.uploadFileSelected(this.state.mainImagefile)
      }

      var fielddatas = {
        objectID: this.props.editObjectID,
        name: this.state.name,
        // libraryimage: this.state.imageFileArray,
        libraryimage: images,
        allowedlicense: this.state.allowedlicense,
        terms: this.state.terms,
        website: this.state.website,
        description: this.state.description,
        status: true,
        recordstatus: this.state.recordstatus === "false" ? this.state.recordstatusprev : this.state.recordstatus,
      };

      updateRecord(fielddatas).then((value) => {
        if (value !== false) {
          this.setState({ addedclass: 'd-block' });

          setTimeout(
            function () {
              this.setState({ addedclass: 'd-none' });
            }
              .bind(this),
            3000
          );

        } else {
          this.setState({ addedclass: 'd-none' });
        }
        this.props.editRecordStatus(value);
        this.setState({ loader: false });
        jQuery('#savebutton').prop('disabled', false);
      });

    }
  }


  render() {

    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className='mediamodel addnewpopup'>
        <div className="modal-header">

          {
            this.state.loader === true ?
              <div className="loaderwrap"><div className="loader"></div></div>
              :
              null
          }

          <h5 className="modal-title">Edit Library</h5>
          <a className="closebtn" onClick={this.props.toggle}>x</a>
          <Row className="mt-3 justify-content-between">
            <Col className="mb-1">
              <Button className="btn btn-default addbuttonwhite" id="savebutton" onClick={this.updateRecords}>Save</Button>
              <Button className="btn btn-primary ml-3" onClick={this.props.toggle}>Discard</Button>
            </Col>
            <Col>
              <Alert color="success" className={`mb-0 ${this.state.addedclass}`}>
                Your changes have been saved
              </Alert>
            </Col>
          </Row>
        </div>
        <ModalBody>



          <div className="addlibrary">

            <Row className="mb-3 justify-content-between">
              <Col></Col>
              <Col className="col-auto">

              </Col>
            </Row>

            <Row>



              <Col className="col-md-5">
                {
                  this.state.imageError !== "" ?
                    <span className="error fixedtop">{this.state.imageError}</span>
                    :
                    null
                }

                <div className="imgbx libraryimgwrap">

                {/* <div className="clipboard" onPaste={this.handlePaste}></div> */}

                <div className="customupload" onPaste={this.handlePaste}>
                    <div className="dragndrop">
                      Choose or Drag and Drop image
                      <Input type="file" name="libraryimage" id='libraryimage' accept="image/png, image/gif, image/jpeg, image/webp" placeholder='upload your file' onChange={this.fileChangedHandler} />
                    </div>
                    Paste image ctrl + V
                  </div>

                  {
                    this.state.tempImageView ?
                      <img src={this.state.tempImageView} alt='img' />
                      :
                      null
                      // <Loader type="Oval" color="#fff" height={20} width={20} />
                  }

                  <div className='optionbx'>
                    {/* <a href='#' id='openlinkbx' className='link' onClick={showImageLink}>
                      <img src={iconLink} alt='img' />
                    </a> */}


                    {/* <label className='edit' htmlFor="libraryimage">
                      <img src={iconEdit} alt='img' />
                    </label> */}
                    <a href='javascript:' className='delete'
                      onClick={
                        async (event) => {
                          this.setState({ tempImageView: null, mainImagefile: [], imageFileArray: [] });
                          jQuery("#libraryimage").val(null);
                        }
                      }>
                      <img src={iconDelete} alt='img' className='delete_icon' />
                    </a>
                    {/* <div id='linkinput' className='linkinput'>
                      <Input type="text" name="imageid" id="imagelink" placeholder='Enter image Id' />
                      <button className="submitbx" onClick={clearEditImage}><img src={iconCloseWhite} alt='img' /></button>
                    </div> */}


                    {/* <div id='linkupload' className='linkupload d-none'>
                      <Input type="file" name="libraryimage" id='libraryimage' accept="image/png, image/gif, image/jpeg, image/webp" placeholder='upload your file' onChange={this.fileChangedHandler} />
                    </div> */}


                  </div>
                </div>


              </Col>



              <Col className="col-md-7">
                <div className='textbx'>
                  <h2>
                    <Input type="text" id="name" className="libraryname" placeholder="Library Name*"
                      value={this.state.name ? this.state.name : ""}
                      onChange={
                        async (event) => {
                          this.setState({ name: event.target.value });
                        }
                      }
                    />

                    <div className="activecheck float-right">
                      <Input id="status" type="checkbox" className="form-check-input form-check-input"
                        checked={this.state.recordstatus === "active" ? true : false}
                        onClick={
                          async (event) => {
                            console.log(event.target.checked)
                            if (event.target.checked === true) {
                              this.setState({ recordstatus: "active" });
                            } else {
                              this.setState({ recordstatus: "false" });
                            }

                          }
                        }
                      />
                      <label for="status" className="btn">
                        <i className="mr-1 fas fa-recycle"></i>
                        Active
                      </label>
                    </div>
                  </h2>
                  {/* <Row className="justify-content-between">
                    <Col className="col-md-10">
                      <h3>Allowed Assets Licenses *</h3>
                      <div className='allowassets d-flex flex-wrap'>

                        {
                          this.state.licenseItems && this.state.licenseItems.map((dynamicData, key) =>
                            <div className='checkbox' key={key}>
                              <Input type="checkbox" name="licensetype" id={`lice${key}`} value={dynamicData.id}
                                onChange={this.licensecheck}
                                checked={this.state.allowedlicense.indexOf(dynamicData.id) !== -1}
                              />
                              <label htmlFor={`lice${key}`}>{dynamicData.get('name')}</label>
                            </div>
                          )
                        }

                      </div>
                    </Col>
                  </Row> */}
                  <Row className="justify-content-between mt-4">
                    <Col className="col-md-12">
                      <FormGroup className="form-group d-flex align-items-center bluecolor">
                        <Label htmlFor="terms">Terms</Label>
                        <InputGroup>
                          {/* <InputGroupAddon addonType="prepend"> */}
                            <InputGroupText className='prepend dark'><img src={iconLinkBlue} alt='img' width={20} /></InputGroupText>
                          {/* </InputGroupAddon> */}
                          <Input type="text" name="terms" id="terms" placeholder="https://aljiprm.com"
                            value={this.state.terms ? this.state.terms : ""}
                            onChange={
                              async (event) => {
                                this.setState({ terms: event.target.value });
                              }
                            }
                          />
                        </InputGroup>
                      </FormGroup>

                      <FormGroup className="form-group d-flex align-items-center bluecolor">
                        <Label htmlFor="website">Website</Label>
                        <InputGroup>
                          {/* <InputGroupAddon addonType="prepend"> */}
                            <InputGroupText className='prepend dark'><img src={iconLinkBlue} alt='img' width={20} /></InputGroupText>
                          {/* </InputGroupAddon> */}
                          <Input type="text" name="terms" id="website" placeholder="https://aljiprm.com"
                            value={this.state.website ? this.state.website : ""}
                            onChange={
                              async (event) => {
                                this.setState({ website: event.target.value });
                              }
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            <FormGroup className="form-group mt-4">
              <h3>Description</h3>
              <Input type="textarea" id="description" placeholder="Enter some text" rows="3"
                value={this.state.description ? this.state.description : ""}
                onChange={
                  async (event) => {
                    this.setState({ description: event.target.value });
                  }
                }
              />
            </FormGroup>

          </div>
        </ModalBody>
      </Modal>
    )
  }
}

export default Editlibraries;