import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormFeedback,
  CustomInput,
  Tooltip,
  UncontrolledTooltip,
  UncontrolledDropdown
} from 'reactstrap';
import Select, { components } from "react-select";
import jQuery from 'jquery'
import Loader from '../../components/common/Loader';
import Autocomplete from 'react-autocomplete';
import Datetime from "react-datetime";
import 'react-datetime/css/react-datetime.css';

import { getCurrentUser, authenticateLoggedIn, addHeaderTitle, getParameterByName, authenticateLoginpage, addBreadcrumb } from '../../services/parse';
import {
  updateRecord, checkFieldValid, checkValidBySection, fetchRecordById, fetchRecordByIdVersion, getBusinessSectors, getMasterbrands, getDomainStatus, getALJCompanies, getCountries,
  getRegions, getDomainname, getWebcategory, getRegistrar, getDomainFull, getFinanceCountByDomain
} from '../../services/domain';
import { isAdmin } from '../../services/user';
import withRouter from '../../components/common/WithRouter';

var Parse = require('parse');

class editDomain extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      collapse: true,
      fadeIn: true,
      timeout: 300,
      isSuper: '',
      addedclass: 'd-none',
      hiddenobjectid: '',
      saveenabledisable: 'd-block',
      datafull: [],
      recordByIdData: [],
      businessSectorData: [],
      masterbrandData: [],
      domainstatusData: [],
      countriesData: [],
      regionData: [],
      companyData: [],
      domainnameData: [],
      webcategoryData: [],
      registrarData: [],
      relatedObjectsData: [],
      versionselectcounts: [],
      registrant_email: "",
      isEdit: false,
      logofile: null,
      financeCount: 0,
      editmode:false,
    };
  }


  async componentDidMount(evt) {

    jQuery(".opendefault").next('.card-body').show();
    jQuery(".opendefault").addClass('active');
    
    //auth start
    authenticateLoggedIn();

    var isadminval = await isAdmin();
    if (isadminval) {
      var currentrole = isadminval[0].get('role').get('name');
      console.log(currentrole);
      this.setState({ currentRole: currentrole });
      if ( currentrole === "Finance") {
        this.setState({ editmode: false});
        
    addHeaderTitle('Domain');
    addBreadcrumb('Home / Domains');
      }
      else
      {
        this.setState({ editmode: true});
        
    addHeaderTitle('Edit Domain');
    addBreadcrumb('Home / Domains / Edit Domain');
      }
    }


    var editobjectid = this.props.params.objectid;
    var version = this.props.params.version;

    this.setState({ hiddenobjectid: editobjectid, });

    getDomainFull().then((value) => {
      this.setState({ datafull: value, });
    });

    var financeCount = await getFinanceCountByDomain(editobjectid);
    this.setState({ financeCount: financeCount, });

   

    if (version !== 'default') {

      this.setState({ saveenabledisable: 'd-none' });

      fetchRecordByIdVersion({ objectid: editobjectid, versionselectcount: version }).then((value) => {
        if (value.length > 0) {
          if (value[0].get('domain') && value[0].get('domain').get('name')) {
           this.state.editmode? addHeaderTitle('Edit Domain (' + value[0].get('name') + value[0].get('domain').get('name') + ')'):
           addHeaderTitle('Domain (' + value[0].get('name') + value[0].get('domain').get('name') + ')');
          }
          this.setState({ recordByIdData: value[0] });
          this.setState({ isEdit: true });

          if (value[0].get('domain')) {
            this.setState({ domain: value[0].get('domain').id });
          }
          if (value[0].get('web_category')) {
            this.setState({ web_category: value[0].get('web_category').id });
          }
          if (value[0].get('registrar')) {
            this.setState({ registrar: value[0].get('registrar').id });
          }
          if (value[0].get('business_sector')) {
            this.setState({ business_sector: value[0].get('business_sector').id });
          }
          if (value[0].get('alj_entity')) {
            this.setState({ alj_entity: value[0].get('alj_entity').id });
          }

          if (value[0].get('geography')) {
            this.setState({ geography: value[0].get('geography').id });
          } else {
            this.setState({ geography: [] });
          }
          if (value[0].get('region')) {
            this.setState({ region: value[0].get('region').id });
          } else {
            this.setState({ region: [] });
          }

          if (value[0].get('domain_status')) {
            this.setState({ domain_status: value[0].get('domain_status').id });
          }
          if (value[0].get('registrant_email')) {
            this.setState({ registrant_email: value[0].get('registrant_email') });
          }





        } else {
          this.setState({ isEdit: false });
        }

        jQuery(".opendefault").next('.card-body').show();
        jQuery(".opendefault").addClass('active');
      });

      // General values
      fetchRecordById({ objectid: editobjectid }).then((value) => {
        if (value.length > 0) {

          getCountries().then((value) => {
            this.setState({ countriesData: value, });
          });
          getRegions().then((value) => {
            this.setState({ regionData: value, });
          });
          getBusinessSectors().then((value) => {
            this.setState({ businessSectorData: value, });
          });
          getMasterbrands().then((value) => {
            this.setState({ masterbrandData: value, });
          });
          getDomainStatus().then((value) => {
            this.setState({ domainstatusData: value, });
          });
          getALJCompanies().then((value) => {
            this.setState({ companyData: value, });
          });
          getDomainname().then((value) => {
            this.setState({ domainnameData: value, });
          });
          getWebcategory().then((value) => {
            this.setState({ webcategoryData: value, });
          });
          getRegistrar().then((value) => {
            this.setState({ registrarData: value, });
          });

          var version_countarray = [];
          for (var i = 1; i <= value[0].attributes.version_count; i++) {
            if (i !== value[0].attributes.version_count) {
              version_countarray.push(i);
            }
          }
          this.setState({ versionselectcounts: version_countarray, });


        }
      });


    } else if (editobjectid) {
      this.setState({ saveenabledisable: 'd-inline-block' });
      fetchRecordById({ objectid: editobjectid }).then((value) => {

        if (value.length > 0) {
          if (value[0].get('domain') && value[0].get('domain').get('name')) {
            this.state.editDomain?addHeaderTitle('Edit Domain (' + value[0].get('name') + value[0].get('domain').get('name') + ')'):
            addHeaderTitle('Domain (' + value[0].get('name') + value[0].get('domain').get('name') + ')');
          }
          this.setState({ recordByIdData: value[0] });
          this.setState({ isEdit: true });

          if (value[0].get('domain')) {
            this.setState({ domain: value[0].get('domain').id });
          }
          if (value[0].get('web_category')) {
            this.setState({ web_category: value[0].get('web_category').id });
          }
          if (value[0].get('registrar')) {
            this.setState({ registrar: value[0].get('registrar').id });
          }
          if (value[0].get('business_sector')) {
            this.setState({ business_sector: value[0].get('business_sector').id });
          }
          if (value[0].get('alj_entity')) {
            this.setState({ alj_entity: value[0].get('alj_entity').id });
          }






          if (value[0].get('geography')) {
            this.setState({ geography: value[0].get('geography').id });
          } else {
            this.setState({ geography: "" });
          }
          if (value[0].get('region')) {
            this.setState({ region: value[0].get('region').id });
          } else {
            this.setState({ region: "" });
          }



          if (value[0].get('domain_status')) {
            this.setState({ domain_status: value[0].get('domain_status').id });
          }
          if (value[0].get('registrant_email')) {
            this.setState({ registrant_email: value[0].get('registrant_email') });
          }


          getCountries().then((value) => {
            this.setState({ countriesData: value, });
          });
          getRegions().then((value) => {
            this.setState({ regionData: value, });
          });
          getBusinessSectors().then((value) => {
            this.setState({ businessSectorData: value, });
          });
          getMasterbrands().then((value) => {
            this.setState({ masterbrandData: value, });
          });
          getDomainStatus().then((value) => {
            this.setState({ domainstatusData: value, });
          });
          getALJCompanies().then((value) => {
            this.setState({ companyData: value, });
          });
          getDomainname().then((value) => {
            this.setState({ domainnameData: value, });
          });
          getWebcategory().then((value) => {
            this.setState({ webcategoryData: value, });
          });
          getRegistrar().then((value) => {
            this.setState({ registrarData: value, });
          });

          var version_countarray = [];
          for (var i = 1; i <= value[0].attributes.version_count; i++) {
            if (i !== value[0].attributes.version_count) {
              version_countarray.push(i);
            }
          }
          this.setState({ versionselectcounts: version_countarray, });


        } else {
          this.setState({ isEdit: false });
        }

        jQuery(".opendefault").next('.card-body').show();
        jQuery(".opendefault").addClass('active');
      });

    }



  }


  currentVersion = () => {
    var hiddenrecordid = jQuery('#hiddenrecordid').val();
    window.location.assign('#/domain/editdomain/' + hiddenrecordid + "/default");
    window.location.reload();
  }
  changeVersion = () => {
    var editobjectid = this.props.params.objectid;
    var versionselectcount = jQuery('#versionselectcount').val();

    if (versionselectcount === "currentversion") {
      this.currentVersion();
    } else {
      window.location.assign('#/domain/editdomain/' + editobjectid + '/' + versionselectcount);
      window.location.reload();
    }


  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }
  fileChangedHandler = (event) => {
    const file = event.target.files[0];
    var parseFile = new Parse.File("logo.png", file);
    this.setState({ logofile: parseFile });

  }

  handleDateChange = (date, name) => {
    this.setState(prevState => ({
      recordByIdData: prevState.recordByIdData.set(name, date)
    }));
  };


  updateRecord = () => {
    var validatestates = {
      domain: this.state.domain,
      web_category: this.state.web_category,
      registrar: this.state.registrar,
      business_sector: this.state.business_sector,
      alj_entity: this.state.alj_entity,
      geography: this.state.geography,
      domain_status: this.state.domain_status,
    };

    var valid = checkFieldValid(validatestates);
    if (valid !== false) {

      jQuery('#addbutton').prop('disabled', true);
      jQuery('#addbutton').removeClass('d-inline-block');
      jQuery('#addbutton').addClass('d-none');
      jQuery('#loadbutton').removeClass('d-none');

      var hiddenrecordid = jQuery('#hiddenrecordid').val();

      var name = jQuery('#bname').val();
      var domain = this.state.domain; //jQuery('#domain').val();
      var web_category = this.state.web_category; //jQuery('#web_category').val();
      if (jQuery('#expiry_date').val()) {

        var st = jQuery('#expiry_date').val();
        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var expiry_date = new Date(st.replace(pattern, '$3-$2-$1'));

        // var expiry_date = new Date(jQuery('#expiry_date').val());  // date
      } else {
        var expiry_date = "";
      }
      var registrar = this.state.registrar;// jQuery('#registrar').val();
      var url = jQuery('#url').val();
      var website = jQuery('#website').val();
      if (jQuery('#created_on_date').val()) {

        var st = jQuery('#created_on_date').val();
        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var created_on_date = new Date(st.replace(pattern, '$3-$2-$1'));

        // var created_on_date = new Date(jQuery('#created_on_date').val());   // date
      } else {
        var created_on_date = "";
      }
      var redirect = jQuery('#redirect').val();
      if (jQuery('#updated_date').val()) {

        var st = jQuery('#updated_date').val();
        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var updated_date = new Date(st.replace(pattern, '$3-$2-$1'));

        // var updated_date = new Date(jQuery('#updated_date').val());   // date
      } else {
        var updated_date = "";
      }
      var business_sector = this.state.business_sector; //jQuery('#business_sector').val();  //pointer
      var alj_entity = this.state.alj_entity; //jQuery('#alj_entity').val();
      var masterbrand = this.state.masterbrand; //jQuery('#masterbrand').val();   //pointer
      var registrant_name = jQuery('#registrant_name').val();
      var registrant_email = jQuery('#registrant_email').val();
      var previous_registrant = jQuery('#previous_registrant').val();
      var previous_registrant_email = jQuery('#previous_registrant_email').val();
      var handled_by = jQuery('#handled_by').val();
      var contact_name = jQuery('#contact_name').val();
      var contact_email = jQuery('#contact_email').val();

      if (this.state.geography) {
        var geography = this.state.geography;
      } else {
        var geography = "";
      }
      if (this.state.region) {
        var region = this.state.region;
      } else {
        var region = "";
      }

      var domain_status = this.state.domain_status; //jQuery('#domain_status').val();   //pointer
      var action = jQuery('#action').val();
      var notes = jQuery('#notes').val();
      var status = jQuery('#status').val();

      if (jQuery('#ipr_date_transferred').val()) {
        var st = jQuery('#ipr_date_transferred').val();
        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var ipr_date_transferred = new Date(st.replace(pattern, '$3-$2-$1'));

        // var ipr_date_transferred = new Date(jQuery('#ipr_date_transferred').val());  // date
      } else {
        var ipr_date_transferred = "";
      }
      var ipr_transfer_status = jQuery('#ipr_transfer_status').val();
      var register_alj_ipr = jQuery('#register_alj_ipr').val();
      var transfer_alj_ipr = jQuery('#transfer_alj_ipr').val();
      var version_count = parseInt(jQuery('#version_count').val()) + 1;

      var fielddatas = {
        edithiddenid: hiddenrecordid,
        name: name,
        domain: domain,
        web_category: web_category,
        expiry_date: expiry_date,
        registrar: registrar,
        url: url,
        website: website,
        created_on_date: created_on_date,
        redirect: redirect,
        updated_date: updated_date,
        business_sector: business_sector,
        alj_entity: alj_entity,
        masterbrand: masterbrand,
        registrant_name: registrant_name,
        registrant_email: registrant_email,
        previous_registrant: previous_registrant,
        previous_reg_email: previous_registrant_email,
        handled_by: handled_by,
        contact_name: contact_name,
        contact_email: contact_email,
        geography: geography,
        region: region,
        domain_status: domain_status,
        action: action,
        notes: notes,
        ipr_date_transferred: ipr_date_transferred,
        ipr_transfer_status: ipr_transfer_status,
        register_alj_ipr: register_alj_ipr,
        transfer_alj_ipr: transfer_alj_ipr,
        status: status,
        version_count: version_count,
      };


      updateRecord(fielddatas).then((value) => {

        this.setState({ recordByIdData: value });
        var version_countarray = [];
        for (var i = 1; i <= value.attributes.version_count; i++) {
          if (i !== value.attributes.version_count) {
            version_countarray.push(i);
          }
        }
        this.setState({ versionselectcounts: version_countarray, });

        if (value) {
          this.setState({ addedclass: 'd-block' });


          setTimeout(
            function () {
              this.setState({ addedclass: 'd-none' });
            }
              .bind(this),
            3000
          );

        } else {
          this.setState({ addedclass: 'd-none' });
        }

        this.resetForm();
      });

    }
  }


  slidingClick = (event) => {
    jQuery(event.target).parents(".card").find('.card-body').slideToggle('slow');
    jQuery(event.target).toggleClass('active');
  }

  toolTipshow = (event) => {
    jQuery(event.target).next('.tooltipshow').show();
  }
  toolTiphide = (event) => {
    jQuery(event.target).next('.tooltipshow').hide();
  }
  resetForm = () => {
    jQuery('#addbutton').prop('disabled', false);
    jQuery('#addbutton').addClass('d-inline-block');
    jQuery('#addbutton').removeClass('d-none');
    jQuery('#loadbutton').addClass('d-none');
  }

  checkValidBySection = (event) => {
    var validatestates = {
      domain: this.state.domain,
      web_category: this.state.web_category,
      registrar: this.state.registrar,
      business_sector: this.state.business_sector,
      alj_entity: this.state.alj_entity,
      geography: this.state.geography,
      domain_status: this.state.domain_status,
    };

    var control = jQuery(event.target).parents(".customAcc.card");
    checkValidBySection(validatestates, control);

  }

  render() {
    var countrystate = this.state.countriesData;
    var countrylist = [];
    if (countrystate.length > 0) {
      for (let i = 0; i < countrystate.length; i++) {
        countrylist[i] = { value: countrystate[i].id, label: countrystate[i].attributes.name }
      }
    }

    var regionstate = this.state.regionData;
    var regionlist = [];
    if (regionstate.length > 0) {
      for (let i = 0; i < regionstate.length; i++) {
        regionlist[i] = { value: regionstate[i].id, label: regionstate[i].attributes.name }
      }
    }

    var sectorstate = this.state.businessSectorData;
    var businesslist = [];
    if (sectorstate.length > 0) {
      for (let i = 0; i < sectorstate.length; i++) {
        businesslist[i] = { value: sectorstate[i].id, label: sectorstate[i].attributes.name }
      }
    }

    var masterbrandstate = this.state.masterbrandData;
    var masterbrandlist = [];
    if (masterbrandstate.length > 0) {
      for (let i = 0; i < masterbrandstate.length; i++) {
        masterbrandlist[i] = { value: masterbrandstate[i].id, label: masterbrandstate[i].attributes.name }
      }
    }

    var domainstatusstate = this.state.domainstatusData;
    var domainstatuslist = [];
    if (domainstatusstate.length > 0) {
      for (let i = 0; i < domainstatusstate.length; i++) {
        domainstatuslist[i] = { value: domainstatusstate[i].id, label: domainstatusstate[i].attributes.name }
      }
    }

    var companystate = this.state.companyData;
    var companylist = [];
    if (companystate.length > 0) {
      for (let i = 0; i < companystate.length; i++) {
        companylist[i] = { value: companystate[i].id, label: companystate[i].attributes.name }
      }
    }

    var domainnamestate = this.state.domainnameData;
    var domainnamelist = [];
    if (domainnamestate.length > 0) {
      for (let i = 0; i < domainnamestate.length; i++) {
        domainnamelist[i] = { value: domainnamestate[i].id, label: domainnamestate[i].attributes.name }
      }
    }

    var webcategorystate = this.state.webcategoryData;
    var webcategorylist = [];
    if (webcategorystate.length > 0) {
      for (let i = 0; i < webcategorystate.length; i++) {
        webcategorylist[i] = { value: webcategorystate[i].id, label: webcategorystate[i].attributes.name }
      }
    }

    var registrarstate = this.state.registrarData;
    var registrarlist = [];
    if (registrarstate.length > 0) {
      for (let i = 0; i < registrarstate.length; i++) {
        registrarlist[i] = { value: registrarstate[i].id, label: registrarstate[i].attributes.name }
      }
    }

    var fulldatastate = this.state.datafull;
    var fulldatalist = [];
    if (fulldatastate.length > 0) {
      for (let i = 0; i < fulldatastate.length; i++) {
        fulldatalist.push({ label: fulldatastate[i].get('registrant_email') });
      }
    }






    return <div>

      {this.state.isEdit === true ?
        <div>


          <div className="backbtnwrap">
            <Link to="/domain/domain" className="btn btn-default btnbacktolist">
              <span>Back To List</span>
            </Link>
          </div>

          <Form id="addform">
            <Input type="hidden" id="hiddenrecordid" defaultValue={this.state.hiddenobjectid} />
            <Row className="justify-content-between topbuttonbar">


              <div className="col-md-6 col">
                <Alert color="success" className={this.state.addedclass + " mr-auto"}>
                  Record Updated
                </Alert>
              </div>

              <Col className="col-auto mb-4">

 

                <Link to={"/finance/finance/" + this.state.hiddenobjectid} className="btn invoice-count mr-3"> 
                    <i className="fas fa-file-alt mr-2"></i>
                    Invoices ({this.state.financeCount})
                </Link>



                <div className="d-inline-block mr-3 finadropdown">
                  {this.state.editmode?<UncontrolledDropdown>
                    <DropdownToggle tag="span" data-toggle="dropdown" className="btn btn-default" >
                      Financials  <i className="fas fa-angle-down ml-1"></i>
                    </DropdownToggle>
                    <DropdownMenu>
                      <div>
                        <Link to={"/finance/addFinance/" + this.state.hiddenobjectid}>New record
                        <i className="fas fa-chevron-circle-right ml-1"></i>
                        </Link>

                      </div>
                      <div>
                        <Link to={"/finance/finance/" + this.state.hiddenobjectid}>View all
                        <i className="fas fa-chevron-circle-right ml-1"></i>
                        </Link>

                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>:null}
                  
                </div>
                {this.state.editmode?
                  <Button type="button" color="primary" id="addbutton" className={this.state.saveenabledisable + " mr-3"} onClick={this.updateRecord}>
                  <span className="border-left pl-2"></span>
                  Save
                  </Button>
                  :null}
                

                {/* Loader button */}
                <div className="btn btn-primary mr-3 d-none" id="loadbutton">
                  <Loader type="Oval" color="#414241" height={20} width={20} />
                </div>
                {/* Loader button */}













                <Input type="hidden" id="version_count" defaultValue={this.state.recordByIdData.get('version_count')} />
                <select id="versionselectcount" className="custom-select w-auto" onChange={this.changeVersion}>
                  <option value="">Select</option>
                  <option value="currentversion">Current Version</option>
                  {
                    this.state.versionselectcounts.map((key, i) =>
                      <option key={key} value={key}>{key}</option>
                    )
                  }
                </select>
                <p className="requiredtext text-right">Current version {this.state.recordByIdData.get('version_count')}</p>















              </Col>
            </Row>

            {/* <Row>
              <Col md="8">
                <Alert color="success" className={this.state.addedclass}>
                  Domain Updated
                      <Link to="/domain/domain" className="float-right">
                    &nbsp; Domain List
                      </Link>
                </Alert>
              </Col>
            </Row> */}


            <Row>
              <Col md="8">
                <Card className="customAcc">
                  <CardTitle onClick={this.slidingClick.bind(this)} className="fieldsouter opendefault active">
                    <i className="icon-circle icon-pencil"></i>
                    Edit Domain
                  </CardTitle>
                  <CardBody style={{display:'block'}}>


                    <Row>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="name">Name *
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <Input type="text" name="bname" id="bname" required defaultValue={this.state.recordByIdData.get('name')} />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="name">Domain *
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>

                          <Select
                            id="domain"
                            className="reactSelect reactSelectClass required"
                            closeMenuOnSelect={true}
                            defaultValue={
                              this.state.recordByIdData.get('domain') ?
                                [{ value: this.state.recordByIdData.get('domain').id, label: this.state.recordByIdData.get('domain').get('name') }]
                                :
                                ""
                            }
                            options={domainnamelist}
                            onChange={(selectedOption) => {
                              this.setState({ domain: selectedOption.value });
                            }}
                          />

                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label>Web Category *
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>

                          <Select
                            id="web_category"
                            className="reactSelect reactSelectClass required z-index-10"
                            closeMenuOnSelect={true}
                            defaultValue={
                              this.state.recordByIdData.get('web_category') ?
                                [{ value: this.state.recordByIdData.get('web_category').id, label: this.state.recordByIdData.get('web_category').get('name') }]
                                :
                                ""
                            }
                            options={webcategorylist}
                            onChange={(selectedOption) => {
                              this.setState({ web_category: selectedOption.value });
                            }}
                          />

                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="name">Expiry Date *
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <Datetime
                            inputProps={{ placeholder: "DD/MM/YYYY", id: "expiry_date", required: "required", autoComplete: "off" }}
                            timeFormat={false}
                            dateFormat="DD/MM/YYYY"
                            input={true}
                            // defaultValue={this.state.recordByIdData.get('expiry_date')}
                            value={this.state.recordByIdData.get('expiry_date')}
                            onChange={(date) => this.handleDateChange(date, 'expiry_date')}
                            closeOnSelect={true}
                            
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label>Registrar *
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>

                          <Select
                            id="registrar"
                            className="reactSelect reactSelectClass z-index-9 required "
                            closeMenuOnSelect={true}
                            defaultValue={
                              this.state.recordByIdData.get('registrar') ?
                                [{ value: this.state.recordByIdData.get('registrar').id, label: this.state.recordByIdData.get('registrar').get('name') }]
                                :
                                ""
                            }
                            options={registrarlist}
                            onChange={(selectedOption) => {
                              this.setState({ registrar: selectedOption.value });
                            }}
                          />

                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="name">URL *
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <Input type="text" name="url" id="url" defaultValue={this.state.recordByIdData.get('url')} required />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="name">Website *
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          {/* <Input type="text" name="website" id="website" defaultValue={this.state.recordByIdData.get('website')} /> */}

                          <select name="website" id="website" className="form-control" required defaultValue={this.state.recordByIdData.get('website')}>
                            <option value="">Select</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="name">Created On
                            <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <Datetime
                            inputProps={{ placeholder: "DD/MM/YYYY", id: "created_on_date", autoComplete: "off" }}
                            timeFormat={false}
                            dateFormat="DD/MM/YYYY"
                            value={this.state.recordByIdData.get('created_on_date')}
                            onChange={(date) => this.handleDateChange(date, 'created_on_date')}
                            closeOnSelect={true}

                          />
                        </FormGroup>
                      </Col>






                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="name">Redirect
                            <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <Input type="text" name="redirect" id="redirect" defaultValue={this.state.recordByIdData.get('redirect')} />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="name">Updated Date
                            <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <Datetime
                            inputProps={{ placeholder: "DD/MM/YYYY", id: "updated_date", autoComplete: "off" }}
                            timeFormat={false}
                            dateFormat="DD/MM/YYYY"
                            value={this.state.recordByIdData.get('updated_date')}
                            onChange={(date) => this.handleDateChange(date, 'updated_date')}
                            closeOnSelect={true}

                          />
                        </FormGroup>
                      </Col>








                      <Col md="12">
                        <FormGroup className="form-group">
                          {this.state.editmode?
                            <Button type="button" color="primary" className="float-right" onClick={this.checkValidBySection.bind(this)}>Validate</Button>
                            :null}
                          
                        </FormGroup>
                      </Col>


                    </Row>

                  </CardBody>
                </Card>

                <Card className="customAcc">
                  <CardTitle onClick={this.slidingClick.bind(this)} className="fieldsouter">
                    <i className="icon-circle icon-pencil"></i>
                    Organizational Details
                  </CardTitle>
                  <CardBody>

                    <Row>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label>Business Sector *
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>

                          <Select
                            id="business_sector"
                            className="reactSelect reactSelectClass z-index-10 required"
                            closeMenuOnSelect={true}
                            defaultValue={
                              this.state.recordByIdData.get('business_sector') ?
                                [{ value: this.state.recordByIdData.get('business_sector').id, label: this.state.recordByIdData.get('business_sector').get('name') }]
                                :
                                ""
                            }
                            options={businesslist}
                            onChange={(selectedOption) => {
                              this.setState({ business_sector: selectedOption.value });
                            }}
                          />

                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="name">ALJ Entity *
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>

                          <Select
                            id="alj_entity"
                            className="reactSelect reactSelectClass required"
                            closeMenuOnSelect={true}
                            defaultValue={
                              this.state.recordByIdData.get('alj_entity') ?
                                [{ value: this.state.recordByIdData.get('alj_entity').id, label: this.state.recordByIdData.get('alj_entity').get('name') }]
                                :
                                ""
                            }
                            options={companylist}
                            onChange={(selectedOption) => {
                              this.setState({ alj_entity: selectedOption.value });
                            }}
                          />


                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label>Masterbrand division
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>

                          <Select
                            id="masterbrand"
                            className="reactSelect reactSelectClass"
                            closeMenuOnSelect={true}
                            defaultValue={
                              this.state.recordByIdData.get('masterbrand') ?
                                [{ value: this.state.recordByIdData.get('masterbrand').id, label: this.state.recordByIdData.get('masterbrand').get('name') }]
                                :
                                ""
                            }
                            options={masterbrandlist}
                            onChange={(selectedOption) => {
                              this.setState({ masterbrand: selectedOption.value });
                            }}
                          />

                        </FormGroup>
                      </Col>
                      <Col md="6">

                      </Col>

                      <Col md="12">
                        <FormGroup className="form-group">
                          {this.state.editmode?
                          <Button type="button" color="primary" className="float-right" onClick={this.checkValidBySection.bind(this)}>Validate</Button>
                            :null}
                        </FormGroup>
                      </Col>


                    </Row>

                  </CardBody>
                </Card>


                <Card className="customAcc">
                  <CardTitle onClick={this.slidingClick.bind(this)} className="fieldsouter">
                    <i className="icon-circle icon-pencil"></i>
                    Contact Details
                  </CardTitle>
                  <CardBody>

                    <Row>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label>Registrant
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <Input type="text" name="registrant_name" id="registrant_name" defaultValue={this.state.recordByIdData.get('registrant_name')} />

                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label>Previous Registrant
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <Input type="text" name="previous_registrant" id="previous_registrant" defaultValue={this.state.recordByIdData.get('previous_registrant')} />

                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="name">Registrant Email
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          {/* <Input type="text" name="registrant_email" id="registrant_email" defaultValue={this.state.recordByIdData.get('registrant_email')} /> */}
                          <div className="autocomwrap">
                            <Autocomplete
                              inputProps={{ className: "form-control", id: "registrant_email" }}
                              getItemValue={(item) => item.label}
                              items={fulldatalist}
                              renderItem={(item, isHighlighted) =>
                                <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                                  {item.label}
                                </div>
                              }
                              value={this.state.registrant_email}
                              onChange={(event) => {
                                this.setState({ registrant_email: event.target.value });
                              }}
                              onSelect={(val) => { this.setState({ registrant_email: val }); }}
                            />
                          </div>

                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="name">Handled By
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <Input type="text" name="handled_by" id="handled_by" defaultValue={this.state.recordByIdData.get('handled_by')} />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="name">Contact Name
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <Input type="text" name="contact_name" id="contact_name" defaultValue={this.state.recordByIdData.get('contact_name')} />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="name">Contact Email
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <Input type="text" name="contact_email" id="contact_email" defaultValue={this.state.recordByIdData.get('contact_email')} />
                        </FormGroup>
                      </Col>
                    </Row>


                    <Row>
                      <Col md="6">
                        <FormGroup className="form-group">
                          <Label htmlFor="name">Previous Registrant Email
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <Input type="text" name="previous_registrant_email" id="previous_registrant_email" defaultValue={this.state.recordByIdData.get('previous_registrant_email')} />
                        </FormGroup>
                      </Col>
                    </Row>

                  </CardBody>
                </Card>

              </Col>



              <Col md="4">

                <Card className="customAcc">
                  <CardTitle onClick={this.slidingClick.bind(this)} className="fieldsouter">
                    <i className="icon-circle icon-pencil"></i>
                    Save Options
                  </CardTitle>
                  <CardBody>

                    <Row>
                      <Col md="12">
                        <FormGroup className="form-group">
                          <div className="greybx">
                            <Label>Geography
                            <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                              <span className="tooltipshow">Something about me</span>
                            </Label>

                            <Select
                              id="geography"
                              placeholder="Select Country"
                              className="reactSelect reactSelectClass z-index-10"
                              closeMenuOnSelect={true}
                              defaultValue={
                                this.state.recordByIdData.get('geography') ?
                                  [{ value: this.state.recordByIdData.get('geography').id, label: this.state.recordByIdData.get('geography').get('name') }]
                                  :
                                  ""
                              }
                              options={countrylist}
                              onChange={(selectedOption) => {
                                this.setState({ geography: selectedOption.value });
                              }}
                            />

                            <Select
                              id="geography"
                              placeholder="Select Region"
                              className="reactSelect reactSelectClass mt-2 z-index-9"
                              closeMenuOnSelect={true}
                              defaultValue={
                                this.state.recordByIdData.get('region') ?
                                  [{ value: this.state.recordByIdData.get('region').id, label: this.state.recordByIdData.get('region').get('name') }]
                                  :
                                  ""
                              }
                              options={regionlist}
                              onChange={(selectedOption) => {
                                this.setState({ region: selectedOption.value });
                              }}
                            />
                          </div>

                        </FormGroup>

                        <FormGroup className="form-group">
                          <Label>Domain Status *
                            <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>

                          <Select
                            id="domain_status"
                            className="reactSelect reactSelectClass required"
                            closeMenuOnSelect={true}
                            defaultValue={
                              this.state.recordByIdData.get('domain_status') ?
                                [{ value: this.state.recordByIdData.get('domain_status').id, label: this.state.recordByIdData.get('domain_status').get('name') }]
                                :
                                ""
                            }
                            options={domainstatuslist}
                            onChange={(selectedOption) => {
                              this.setState({ domain_status: selectedOption.value });
                            }}
                          />

                        </FormGroup>

                        <FormGroup className="form-group">
                          <Label>Action
                            <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <Input type="textarea" name="action" id="action" rows="2" defaultValue={this.state.recordByIdData.get('action')} />
                        </FormGroup>

                        <FormGroup className="form-group">
                          <Label>Notes
                            <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <Input type="textarea" name="notes" id="notes" rows="2" defaultValue={this.state.recordByIdData.get('notes')} />
                        </FormGroup>

                        <FormGroup className="form-group">
                          <Label htmlFor="statusfield">Status</Label>
                          <select name="status" id="status" className="form-control" defaultValue={String(this.state.recordByIdData.get('status'))}>
                            <option value="true">Active</option>
                            <option value="false">Inactive</option>
                          </select>
                        </FormGroup>


                        <FormGroup className="form-group">
                          {this.state.editmode?
                          <Button type="button" color="primary" className="float-right" onClick={this.checkValidBySection.bind(this)}>Validate</Button>:null}
                        </FormGroup>


                      </Col>
                    </Row>

                  </CardBody>
                </Card>


                <Card className="customAcc">
                  <CardTitle onClick={this.slidingClick.bind(this)} className="fieldsouter">
                    <i className="icon-circle icon-pencil"></i>
                    IPR Transfer Status
                  </CardTitle>
                  <CardBody>

                    <Row>
                      <Col md="12">
                        <FormGroup className="form-group">
                          <Label htmlFor="name">Date Transferred
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <Datetime
                            inputProps={{ placeholder: "DD/MM/YYYY", id: "ipr_date_transferred", autoComplete: "off" }}
                            timeFormat={false}
                            dateFormat="DD/MM/YYYY"
                            value={this.state.recordByIdData.get('ipr_date_transferred')}
                            onChange={(date) => this.handleDateChange(date, 'ipr_date_transferred')}
                            closeOnSelect={true}

                          />
                        </FormGroup>

                        <FormGroup className="form-group">
                          <Label>IPR Transfer Status
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <select id="ipr_transfer_status" className="custom-select" defaultValue={this.state.recordByIdData.get('ipr_transfer_status')}>
                            <option value="">Select</option>
                            <option value="done">Done</option>
                            <option value="email sent">Email Sent</option>
                            <option value="n/a">N/A</option>
                            <option value="new domain registered in ipr">New domain registered in IPR</option>
                            <option value="pending">Pending</option>
                            <option value="still with jamil pasha">Still with Jamil Pasha</option>
                            <option value="transferred to alj ipr co.">Transferred to ALJ IPR Co.</option>
                          </select>
                        </FormGroup>

                        <FormGroup className="form-group">
                          <Label>Register to ALJ IPR Co.
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <select id="register_alj_ipr" className="custom-select" defaultValue={this.state.recordByIdData.get('register_alj_ipr')}>
                            <option value="">Select</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                            <option value="not applicable">Not Applicable</option>
                          </select>
                        </FormGroup>

                        <FormGroup className="form-group">
                          <Label>Transfer to ALJ IPR Co.
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                            <span className="tooltipshow">Something about me</span>
                          </Label>
                          <select id="transfer_alj_ipr" className="custom-select" defaultValue={this.state.recordByIdData.get('transfer_alj_ipr')}>
                            <option value="">Select</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                            <option value="not applicable">Not Applicable</option>
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>

                  </CardBody>
                </Card>

                {/* <Card className="customAcc">
                  <CardTitle onClick={this.slidingClick.bind(this)} className="fieldsouter">
                    <i className="icon-circle icon-pencil"></i>
                    Versions
                    </CardTitle>
                  <CardBody>
                    <FormGroup className="form-group">
                      <Label htmlFor="exampleEmail12">Current Version {this.state.recordByIdData.get('version_count')}
                        <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                        <span className="tooltipshow">Something about me</span>
                      </Label>

                      <Input type="hidden" id="version_count" defaultValue={this.state.recordByIdData.get('version_count')} />
                      <select id="versionselectcount" className="custom-select" onChange={this.changeVersion}>
                        <option value="">Select</option>
                        <option value="currentversion">Current Version</option>
                        {
                          this.state.versionselectcounts.map((key, i) =>
                            <option key={key} value={key}>{key}</option>
                          )
                        }
                      </select> 
                    </FormGroup>
                  </CardBody>
                </Card> */}



              </Col>

            </Row>

          </Form>


        </div>
        :
        <p></p>
      }

    </div>
  }
}

export default withRouter(editDomain);
