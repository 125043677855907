import jQuery from 'jquery';
var Parse = require('parse');

export const getFullPageCount = async () => {
    var LawFirms = Parse.Object.extend("LawFirms");
    var query = new Parse.Query(LawFirms);
    query.equalTo("status", true);
    query.descending("createdAt");
    const count = await query.count();

    return count;
}

export const getSearchResults = async (searchdatas) => {
    var LawFirms = Parse.Object.extend("LawFirms");
    var query = new Parse.Query(LawFirms);
    query.descending("createdAt");
    query.equalTo("status", true);

    if (searchdatas['searchbox']) {
        query.matches("name", searchdatas['searchbox'], 'i');
    }
    if (searchdatas['lawfirmcode']) {
        query.matches("lawfirmCode", searchdatas['lawfirmcode'], 'i');
    }

    if (searchdatas['statsrc'] == "true") {
        query.equalTo("status", true);
    } else if (searchdatas['statsrc'] == "false") {
        query.equalTo("status", false);
    }

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });

}

export const getLawfirm = async (pagenum, dataperpage, sortitem, sortitemorder) => {
    var skipnum = pagenum - 1;
    var perpage = parseInt(dataperpage);

    var LawFirms = Parse.Object.extend("LawFirms");
    var query = new Parse.Query(LawFirms);
    query.equalTo("status", true);

    if (sortitem !== "") {
        if (sortitemorder === "ascending") {
            query.ascending(sortitem);
        } else {
            query.descending(sortitem);
        }

    } else {
        query.descending('createdAt');
    }

    query.skip(skipnum * perpage);
    query.limit(perpage);

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}



export const getCountries = async () => {
    var Countries = Parse.Object.extend("Countries");
    var query = new Parse.Query(Countries);
    query.equalTo("status", true);
    query.limit(1000);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}


export const hideRecords = async (objectids) => {
    var parseobject = Parse.Object.extend("LawFirms");
    var query = new Parse.Query(parseobject);
    query.equalTo("objectId", objectids);

    return query.find().then((object) => {
        var realbject = object[0];
        realbject.set("status", false);
        return realbject.save().then((records) => {
            return true;
        }, (error) => {
            console.log(error);
        }).catch(function (error) {
        });

    }, (error) => {
    });
}


export const addRecord = async ({ name, lawfirm_code, website_url, contact_person, contact_email, address, country, logo, status }) => {
    var lawfirms = new Parse.Object("LawFirms");
    lawfirms.set("name", name);
    lawfirms.set("lawfirmCode", lawfirm_code);
    lawfirms.set("websiteURL", website_url);
    lawfirms.set("logo", logo);
    lawfirms.set("contact_person", contact_person);
    lawfirms.set("contact_email", contact_email);
    lawfirms.set("address", address);

    if (country) {
        var Countries = Parse.Object.extend("Countries");
        var countryqry = new Parse.Query(Countries);
        let countrypoint = await countryqry.get(country);
        lawfirms.set("country", countrypoint);
    }

    if (status === 'false') {
        lawfirms.set("status", false);
    } else {
        lawfirms.set("status", true);
    }



    return lawfirms.save().then((records) => {
        return records;
    }, (error) => {
        console.log(error);
        return false;
    }).catch(function (error) {
        console.log(error);
        return false;
    });
}


export const fetchRecordById = async ({ objectid }) => {
    var LawFirms = Parse.Object.extend("LawFirms");
    var query = new Parse.Query(LawFirms);
    query.equalTo("objectId", objectid);
    query.include("country");
    return query.find().then((records) => {
        return records;
    }, (error) => {
        return false;
    }).catch(function (error) {
        return false;
    });
}


export const updateRecord = async ({ edithiddenid, name, lawfirm_code, website_url, contact_person, contact_email, address, country, logo, status }) => {
    var LawFirms = Parse.Object.extend("LawFirms");
    var query = new Parse.Query(LawFirms);
    query.equalTo("objectId", edithiddenid);

    return query.find().then(async (object) => {

        var realbject = object[0];

        realbject.set("name", name);
        realbject.set("lawfirmCode", lawfirm_code);
        realbject.set("websiteURL", website_url);
        realbject.set("contact_person", contact_person);
        realbject.set("contact_email", contact_email);
        realbject.set("address", address);

        if (country) {
            var Countries = Parse.Object.extend("Countries");
            var countryqry = new Parse.Query(Countries);
            let countrypoint = await countryqry.get(country);
            realbject.set("country", countrypoint);
        } else {
            realbject.set("country", null);
        }

        if (logo) {
            realbject.set("logo", logo);
        }

        if (status === 'false') {
            realbject.set("status", false);
        } else {
            realbject.set("status", true);
        }


        return realbject.save().then((records) => {
            return true;
        }, (error) => {
            return false;
        }).catch(function (error) {
            return false;
        });

    }, (error) => {
        return false;
    });

}




export const checkFieldValid = (addoredit) => {

    var name = jQuery('#bname');
    var lawfirm_code = jQuery('#lawfirm_code');
    var status = jQuery('#status');

    var errstat = true;
    if (name.val() === "") {
        name.addClass("is-invalid");
        errstat = false;
    } else {
        name.removeClass("is-invalid");
    }
    if (lawfirm_code.val() === "") {
        lawfirm_code.addClass("is-invalid");
        errstat = false;
    } else {
        lawfirm_code.removeClass("is-invalid");
    }

    if (status.val() === "") {
        status.addClass("is-invalid");
        errstat = false;
    } else {
        status.removeClass("is-invalid");
    }


    if (addoredit === "add") {

    }

    if (errstat === false) {
        return false;
    } else {
        return true;
    }

}

