import jQuery from 'jquery';
var Parse = require('parse');

export const getFullPageCount = async () => {
    var ALJCompanies = Parse.Object.extend("ALJCompanies");
    var query = new Parse.Query(ALJCompanies);
    query.equalTo("status", true);
    query.descending("createdAt");
    const count = await query.count();

    return count;
}

export const getSearchResults = async (searchdatas) => {
    var ALJCompanies = Parse.Object.extend("ALJCompanies");
    var query = new Parse.Query(ALJCompanies);
    query.descending("createdAt");
    query.equalTo("status", true);

    if (searchdatas['searchbox']) {
        query.matches("name", searchdatas['searchbox'], 'i');
    }
    if (searchdatas['companycode']) {
        query.matches("companyCode", searchdatas['companycode'], 'i');
    }

    if (searchdatas['statsrc'] == "true") {
        query.equalTo("status", true);
    } else if (searchdatas['statsrc'] == "false") {
        query.equalTo("status", false);
    }

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });

}

export const getALJCompanies = async (pagenum, dataperpage, sortitem, sortitemorder) => {
    var skipnum = pagenum - 1;
    var perpage = parseInt(dataperpage);

    var ALJCompanies = Parse.Object.extend("ALJCompanies");
    var query = new Parse.Query(ALJCompanies);
    query.equalTo("status", true);

    if (sortitem !== "") {
        if (sortitemorder === "ascending") {
            query.ascending(sortitem);
        } else {
            query.descending(sortitem);
        }

    } else {
        query.descending('createdAt');
    }

    query.skip(skipnum * perpage);
    query.limit(perpage);

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const getBusinessSectors = async () => {
    var BusinessSectors = Parse.Object.extend("BusinessSectors");
    var query = new Parse.Query(BusinessSectors);
    query.equalTo("status", true);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const getCountries = async () => {
    var Countries = Parse.Object.extend("Countries");
    var query = new Parse.Query(Countries);
    query.equalTo("status", true);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const getMasterbrands = async () => {
    var Masterbrands = Parse.Object.extend("Masterbrands");
    var query = new Parse.Query(Masterbrands);
    query.equalTo("status", true);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}


export const hideRecords = async (objectids) => {
    var parseobject = Parse.Object.extend("ALJCompanies");
    var query = new Parse.Query(parseobject);
    query.equalTo("objectId", objectids);

    return query.find().then((object) => {
        var realbject = object[0];
        realbject.set("status", false);
        return realbject.save().then((records) => {
            return true;
        }, (error) => {
            console.log(error);
        }).catch(function (error) {
        });

    }, (error) => {
    });
}


export const addRecord = async ({ name, company_code, business_sector, countrylist, masterbrand, website_url, description, logo, domaincheck, trademarkcheck, status }) => {
    var companies = new Parse.Object("ALJCompanies");
    companies.set("name", name);
    companies.set("companyCode", company_code);

    if (business_sector) {
        var BusinessSectors = Parse.Object.extend("BusinessSectors");
        var businessqry = new Parse.Query(BusinessSectors);
        let businesspoint = await businessqry.get(business_sector);
        companies.set("businessSector", businesspoint);
    }


    var comparray = [];
    for (var i = 0; i < countrylist.length; ++i) {
        var Countries = Parse.Object.extend("Countries");
        var compQuery = new Parse.Query(Countries);
        let comppoint = await compQuery.get(countrylist[i]);
        comparray.push(comppoint);
    }
    companies.set("countries", comparray);

    if (masterbrand) {
        var Masterbrands = Parse.Object.extend("Masterbrands");
        var brandqry = new Parse.Query(Masterbrands);
        let brandpoint = await brandqry.get(masterbrand);
        companies.set("masterbrand", brandpoint);
    }

    companies.set("websiteUrl", website_url);
    companies.set("description", description);
    companies.set("logo", logo);


    companies.set("domain", domaincheck);
    companies.set("trademark", trademarkcheck);





    if (status === 'false') {
        companies.set("status", false);
    } else {
        companies.set("status", true);
    }



    return companies.save().then((records) => {
        return records;
    }, (error) => {
        console.log(error);
        return false;
    }).catch(function (error) {
        console.log(error);
        return false;
    });
}


export const fetchRecordById = async ({ objectid }) => {
    var ALJCompanies = Parse.Object.extend("ALJCompanies");
    var query = new Parse.Query(ALJCompanies);
    query.equalTo("objectId", objectid);
    query.include("businessSector");
    query.include("masterbrand");
    return query.find().then((records) => {
        return records;
    }, (error) => {
        return false;
    }).catch(function (error) {
        return false;
    });
}


export const updateRecord = async ({ edithiddenid, name, company_code, business_sector, countrylist, masterbrand, website_url, description, logo, domaincheck, trademarkcheck, status }) => {
    var ALJCompanies = Parse.Object.extend("ALJCompanies");
    var query = new Parse.Query(ALJCompanies);
    query.equalTo("objectId", edithiddenid);

    return query.find().then(async (object) => {

        var realbject = object[0];

        realbject.set("name", name);
        realbject.set("companyCode", company_code);

        if (business_sector) {
            var BusinessSectors = Parse.Object.extend("BusinessSectors");
            var businessqry = new Parse.Query(BusinessSectors);
            let businesspoint = await businessqry.get(business_sector);
            realbject.set("businessSector", businesspoint);
        } else {
            realbject.set("businessSector", null);
        }


        if (countrylist.length) {
            var comparray = [];
            for (var i = 0; i < countrylist.length; ++i) {
                var Countries = Parse.Object.extend("Countries");
                var compQuery = new Parse.Query(Countries);
                let comppoint = await compQuery.get(countrylist[i]);
                comparray.push(comppoint);
            }
            realbject.set("countries", comparray);
        }

        if (masterbrand) {
            var Masterbrands = Parse.Object.extend("Masterbrands");
            var brandqry = new Parse.Query(Masterbrands);
            let brandpoint = await brandqry.get(masterbrand);
            realbject.set("masterbrand", brandpoint);
        } else {
            realbject.set("masterbrand", null);
        }

        realbject.set("websiteUrl", website_url);
        realbject.set("description", description);
        if (logo) {
            realbject.set("logo", logo);
        }


        realbject.set("domain", domaincheck);
        realbject.set("trademark", trademarkcheck);





        if (status === 'false') {
            realbject.set("status", false);
        } else {
            realbject.set("status", true);
        }


        return realbject.save().then((records) => {
            return true;
        }, (error) => {
            return false;
        }).catch(function (error) {
            return false;
        });

    }, (error) => {
        return false;
    });

}




export const checkFieldValid = (states) => {

    var name = jQuery('#bname');
    var company_code = jQuery('#company_code');
    var business_sector = jQuery('#business_sector');
    var status = jQuery('#status');

    var errstat = true;
    if (name.val() === "") {
        name.addClass("is-invalid");
        errstat = false;
    } else {
        name.removeClass("is-invalid");
    }
    // if (company_code.val() === "") {
    //     company_code.addClass("is-invalid");
    //     errstat = false;
    // } else {
    //     company_code.removeClass("is-invalid");
    // }
    // if (states['business_sector'] === undefined) {
    //     business_sector.addClass("is-invalid");
    //     errstat = false;
    // } else {
    //     business_sector.removeClass("is-invalid");
    // }
    // if (status.val() === "") {
    //     status.addClass("is-invalid");
    //     errstat = false;
    // } else {
    //     status.removeClass("is-invalid");
    // }


    if (errstat === false) {
        return false;
    } else {
        return true;
    }

}

