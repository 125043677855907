import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormFeedback,
  CustomInput,
  Tooltip,
  UncontrolledTooltip
} from 'reactstrap';
import Select, { components } from "react-select";
import jQuery from 'jquery'

import { authenticateLoggedIn, addHeaderTitle, addBreadcrumb } from '../../../services/parse';
import { addRecord, checkFieldValid } from '../../../services/medialibrary';

import { uploadFile,uploadFileToAWSCustom, getFileUrl } from '../../../services/awsFileUpload';
import { LOGO_UPLOAD_SIZE } from '../../../config/constant';
var Parse = require('parse');

class AddMediaLibrary extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      collapse: true,
      fadeIn: true,
      timeout: 300,
      isSuper: '',
      addedclass: 'd-none',
      companyByIdData: [],
      isEdit: false,
      logofile: [],
      logoFileArray: [],
    };
  }


  async componentDidMount(evt) {
    //auth start
    authenticateLoggedIn();
    addHeaderTitle('Add Media Library');
    addBreadcrumb('Home / Media Library / Add Media Library');

  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }





  uploadFileSelected = async (event) => {
    var files = event
    var itemCounts = files.length;
    console.log('itemCounts', itemCounts);
    var itemsToAdd = [];
    var itemsAdded = [];
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].size < LOGO_UPLOAD_SIZE) {
          var fileType = files[i].type.split("/")[1];
          var fileName = files[i].name;
          var fileSize = (files[i].size / 1000000).toFixed(2);

          var obj = { awsFileName: null, type: fileType, name: fileName, id: itemCounts, size: fileSize }
          itemsToAdd.push(obj);
          var uploadeditem = await uploadFileToAWSCustom(files[i], obj, "contentmaster");
          itemsAdded.push(uploadeditem);
          itemCounts++;
        }
        else {
          console.log('File ' + files[i].name + ' exceeds maximum file size of 30MB')
        }
      }
      if (itemsAdded.length > 0) {
        this.setState({
          logoFileArray: itemsAdded
        });
      }
    }
  }





  fileChangedHandler = (event) => {
    const file = event.target.files[0];
    if (file) {
      this.setState({ logofile: event.target.files });

      jQuery('.custom-file-label').html(file['name']);
    }
  }
  // fileChangedHandler = (event) => {
  //   const file = event.target.files[0];
  //   var parseFile = new Parse.File("logo.png", file);
  //   this.setState({ logofile: parseFile });

  //   jQuery('.custom-file-label').html(file['name']);
  // }

  addRecord = async () => {

    var valid = checkFieldValid('add');




    if (valid !== false) {
      jQuery('#addbutton').prop('disabled', true);



      await this.uploadFileSelected(this.state.logofile)


      var name = jQuery('#name').val();
      var description = jQuery('#description').val();
      var status = jQuery('#status').val();

      console.log(this.state.logoFileArray)

      addRecord({ name: name, icon: this.state.logoFileArray, description: description, status: status }).then((value) => {
        if (value !== false) {
          this.setState({ addedclass: 'd-block' });

          setTimeout(
            function () {
              this.setState({ addedclass: 'd-none' });
              window.location.assign('#/contentmaster/editmedialibrary/' + value.id);
            }
              .bind(this),
            1000
          );

        } else {
          this.setState({ addedclass: 'd-none' });
        }

        jQuery('#addform').trigger("reset");
        jQuery('#addbutton').prop('disabled', false);
      });

    }
  }





  render() {
    return <div>

      <Row className="justify-content-between topbuttonbar">
        <Col className="col-md-5">
          <Alert color="success" className={this.state.addedclass}>
            Record Added
            <Link to="/contentmaster/medialibrary" className="float-right">
              &nbsp; Go to list
            </Link>
          </Alert>
        </Col>
        <Col className="col-auto mb-4">
          <Link to="/contentmaster/medialibrary" className="btn btn-primary btncancle mr-3">
            <span className="border-left pl-2">Cancel</span>
          </Link>
          <Button type="button" color="primary" id="addbutton" onClick={this.addRecord}>
            <span className="border-left pl-2"></span>
            Save
          </Button>
        </Col>
      </Row>

      <Row>

        <Col xs="12" sm="6">


          <Card>
            <CardTitle>
              <i className="icon-circle icon-pencil"></i>
              Add Media Library
            </CardTitle>

            <CardBody>

              <form name="addform" id="addform">

                <FormGroup className="form-group">
                  <Label htmlFor="name">Name *</Label>
                  <Input type="text" id="name" placeholder="Name" />
                </FormGroup>

                <FormGroup className="form-group">
                  <Label htmlFor="logofile">Icon *</Label>
                  <div className="custom-file">
                    <Input type="file" id="logofile" name="logofile" className="custom-file-input" onChange={this.fileChangedHandler} />
                    <label className="custom-file-label" htmlFor="logofile">Choose file</label>
                  </div>
                </FormGroup>

                <FormGroup className="form-group">
                  <Label htmlFor="status">Status *</Label>
                  <Input type="select" className="custom-select" id="status">
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                  </Input>
                </FormGroup>

                <FormGroup className="form-group">
                  <Label htmlFor="description">Description</Label>
                  <Input type="textarea" id="description" placeholder="Description" rows="3" />
                </FormGroup>


              </form>
            </CardBody>

          </Card>

        </Col>
      </Row>

    </div>
  }
}

export default AddMediaLibrary;
