import React from 'react';
import { Link } from 'react-router-dom';
import ScrollArea from 'react-scrollbar';
import { Row, Col, Carousel, CarouselItem, CarouselControl, Button, Table, Input, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, ModalFooter, Label, Alert, UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem, TabContent, TabPane, CardBody, Nav, NavItem, NavLink, Card, CardTitle, CardText, Progress, Badge } from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
import jQuery from 'jquery';
import classnames from 'classnames';
import { addBreadcrumb, addHeaderTitle } from '../../../services/parse';
import { isAdmin } from '../../../services/user';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import imageDummy from '../../../assets/images/dummy.jpg';
import iconLink from '../../../assets/images/icons/icon-link.png';
import iconCamera from '../../../assets/images/icons/icon-camera.png';
import iconShare from '../../../assets/images/icons/icon-share.png';
import SampleAsset from './SampleAsset.csv';
import Viewimportedassets from "./Viewimportedassets";
import Papa from "papaparse";

import { uploadFile, uploadFileToAWSCustom, getFileUrl } from '../../../services/awsFileUpload';
import { duplicateRecord } from '../../../services/medialicenseassets';
import {
  getAgencybyName, getBrandbyName, getAssetTypebyName, getGeoRestrictionbyName, getLicenseTypebyName,
  getMediaLicenseLibrarybyName, getTagsbyName, addRecord, getAllMediaLicenseAssetImport, deleteRecords, deleteAllRecords
} from '../../../services/medialicenseassetsimport';
import Assetimagebulk from "./Assetimagebulk";

function setobjectid(num) {
  var elSlide = jQuery('.mediaslideitem[data-id=' + num + ']').data('obj');
  jQuery('#hiddenobjectID').val(elSlide);
}

var popSlide = {
  autoplay: false,
  autoplaySpeed: 5000,
  dots: false,
  arrows: true,
  infinite: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  afterChange: (current) => setobjectid(current),
};



class bulkImport extends React.Component {

  constructor(props) {
    super(props);
    this.state = { activeIndex: 0, visible: true };
    this.viewpopuptoggle = this.viewpopuptoggle.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
    this.state = {
      loader: false,
      activeTab: '1',
      percentage: 0,
      allImportData: null,
      editObjectID: null,
      bulkItemsToPublish: [],
      actiontext: "",
      updateIDforImage: "",
      assetwaitingcount: "",
      selectedcount: 0,
      itemsAdded: "",
      itemsDeleted: "",
      dataLoaded: false,
    };

  }


  componentWillMount = async () => {

    var isadminval = await isAdmin();
    if (isadminval) {
      var currentrole = isadminval[0].get('role').get('name');
      this.setState({ currentRole: currentrole });
      addBreadcrumb('Home / Media License');
      addHeaderTitle('Bulk Upload');
    }


    this.setState({ loader: true });

    var allData = await getAllMediaLicenseAssetImport();
    if (allData) {

      for (let i = 0; i < allData.length; i++) {
        var deleteall = await deleteRecords(allData[i].id);
      }

      this.setState({ loader: false });

      // this.setState({ allImportData: allData, assetwaitingcount: allData.length });
    }
  }




  viewpopuptoggle(e) {

    this.setState({
      viewpopup: !this.state.viewpopup
    });
    this.setObjectID(e.target.getAttribute("data-objectid"));
  }

  setObjectID(objectid) {
    this.setState({
      editObjectID: objectid !== null ? objectid : null
    });
  }




  onDismiss() {
    this.setState({
      visible: false
    });
  }
  toggleTabs(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }



  fileChangedHandler = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileExtension = file.type.split("/")[1];
      if (fileExtension === 'csv') {
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: this.addDatas,
        });
      }

      this.setState({ itemsDeleted: "" });
    }
  }

  testImage = async (url, timeoutT) => {
    var imgtest = await new Promise(function (resolve, reject) {
      var timeout = timeoutT || 500;
      var timer, img = new Image();
      img.onerror = img.onabort = function () {
        clearTimeout(timer);
        resolve("error");
      };
      img.onload = function () {
        clearTimeout(timer);
        resolve("success");
      };
      timer = setTimeout(function () {
        img.src = "//!!!!/noexist.jpg";
        resolve("timeout");
      }, timeout);
      img.src = url;
    });

    return imgtest;
  }



  // selectAll = async (e) => {
  //   var check = e.target.checked;
  //   if (check === true) {
  //     jQuery('input:checkbox[name="bulkcheck"]').prop('checked', true);
  //   } else {
  //     jQuery('input:checkbox[name="bulkcheck"]').prop('checked', false);
  //   }

  //   this.bulkcheck();
  // }

  // bulkcheck = async () => {
  //   var bkdata = [];
  //   jQuery.each(jQuery("input[name='bulkcheck']:checked"), function () {
  //     bkdata.push(jQuery(this).val());
  //   });
  //   this.setState({ bulkItemsToPublish: bkdata });

  //   console.log(bkdata)
  // }


  selectAll = async (e) => {
    var check = e.target.checked;
    if (check === true) {
      jQuery('input:checkbox[name="bulkcheck"]').addClass('active');
      jQuery(e.target).addClass('active');
    } else {
      jQuery('input:checkbox[name="bulkcheck"]').removeClass('active');
      jQuery(e.target).removeClass('active');
    }

    this.setBulkData();
  }

  bulkcheck = async (e) => {
    var objid = e.target.value;
    var val = "";
    var thisitem = jQuery(e.target);

    if (thisitem.hasClass('active')) {
      val = false;
      thisitem.removeClass('active');
    } else {
      val = true;
      thisitem.addClass('active');
    }

    this.setBulkData();
  }

  setBulkData = async () => {

    var selcount = jQuery("input[name='bulkcheck'].active").length;
    this.setState({ selectedcount: selcount });

    var bkdata = [];
    jQuery.each(jQuery("input[name='bulkcheck'].active"), function () {
      bkdata.push(jQuery(this).val());
    });
    this.setState({ bulkItemsToPublish: bkdata });


    console.log(bkdata)
  }



  addDatas = async (result) => {
    var counter = 0;

    if (result.data && result.data.length > 0) {
      var impData = result.data;

      this.setState({ loader: true });

      for (let i = 0; i < impData.length; i++) {

        // this.setState({ percentage: 1 });


        var agencyid = await getAgencybyName(impData[i].agencyname);

        var aljbrand = await getBrandbyName(impData[i].aljbrand);
        var brandIds = [];
        if (aljbrand && aljbrand.length > 0) {
          for (let i = 0; i < aljbrand.length; i++) {
            brandIds.push(aljbrand[i].id);
          }
        }

        var assettypetext = await getAssetTypebyName(impData[i].assettypetext);

        var georestrictionname = await getGeoRestrictionbyName(impData[i].georestrictionname);
        var geoIds = [];
        if (georestrictionname && georestrictionname.length > 0) {
          for (let i = 0; i < georestrictionname.length; i++) {
            geoIds.push(georestrictionname[i].id);
          }
        }

        var licensetypename = await getLicenseTypebyName(impData[i].licensetypename);

        var medialicenselibraryname = await getMediaLicenseLibrarybyName(impData[i].medialicenselibraryname);


        var expdate = "";
        var expiry = impData[i].expiry;
        if (expiry) {
          var dateArr = expiry.split(',');
          expdate = new Date(dateArr[0] + '-' + dateArr[1] + '-' + dateArr[2]);
        }


        var purdate = "";
        var purchasedon = impData[i].purchasedon;
        if (purchasedon) {
          var dateArr = purchasedon.split(',');
          purdate = new Date(dateArr[0] + '-' + dateArr[1] + '-' + dateArr[2]);
        }



        var tags = [];
        if (impData[i].tags) {
          tags = await getTagsbyName(impData[i].tags);
        }







        var imagesAdded = [];
        var assetimage = impData[i].assetimage;
        if (assetimage) {
          var imgtest = await this.testImage(assetimage);
          if (imgtest === "success") {

            try {
              const response = await fetch(assetimage);
              const blob = await response.blob();
              const file = new File([blob], 'image.jpg', { type: blob.type });

              if (file) {
                var fileType = file.type.split("/")[1];
                var fileName = file.name;
                var fileSize = (file.size / 1000000).toFixed(2);

                var obj = { awsFileName: null, type: fileType, name: fileName, id: 1, size: fileSize }
                var uploadeditem = await uploadFileToAWSCustom(file, obj, "contentmaster");
                imagesAdded.push(uploadeditem);
              }
            } catch (error) {
              console.log(error)
            }

          }
        }


        if (impData[i].title && licensetypename[0] && medialicenselibraryname[0] && assettypetext[0] && imagesAdded.length > 0) {
          var fielddatas = {
            recordstatus: "active",
            assetimage: imagesAdded,
            allowedlicense: licensetypename[0].id,
            libraryid: medialicenselibraryname[0].id,
            asseturl: impData[i].asseturl,
            assetid: impData[i].assetid,
            title: impData[i].title,
            typeid: assettypetext[0] ? assettypetext[0].id : "",
            prepetuity: impData[i].prepetuity == "TRUE" ? true : false,
            expiry: expdate ? expdate : null,
            knownrestrictions: geoIds,
            aganecyid: agencyid[0] ? agencyid[0].id : "",
            description: impData[i].description,
            purchasedon: purdate ? purdate : null,
            contributer: impData[i].contributer,
            cost: impData[i].cost,
            accountname: impData[i].accountname,
            brandpurchased: brandIds,
            tags: tags,
          };

          var addrec = await addRecord(fielddatas);
          console.log(addrec);

        }


        if (counter === 0) {
          this.setState({ loader: false });
        }

        //Progressbar
        counter++;
        var progress = Math.ceil(((counter / result.data.length) * 100).toFixed(1));
        this.setState({ percentage: progress });
        //Progressbar


        if (result.data.length === i + 1) {
          this.setState({ loader: true });

          var allData = await getAllMediaLicenseAssetImport();
          if (allData) {
            this.setState({ allImportData: allData, assetwaitingcount: allData.length, dataLoaded: true });
            this.setState({ loader: false });
          }
        }


      }

    }
  }










  confirmUpload = async () => {

    if (this.state.bulkItemsToPublish.length > 0) {
      for (let i = 0; i < this.state.bulkItemsToPublish.length; i++) {
        this.setState({ loader: true });


        var dup = await duplicateRecord(this.state.bulkItemsToPublish[i], "import");
        if (dup.id) {
          jQuery('input:checkbox[name="bulkcheck"][value=' + this.state.bulkItemsToPublish[i] + ']').addClass('nocheck').removeClass('active');
          // var deleted = await deleteRecords(this.state.bulkItemsToPublish[i]);

          jQuery(".importeditem[data-obj='" + this.state.bulkItemsToPublish[i] + "']").addClass('published');
        }

      }


      jQuery(".importeditem").each(function () {
        if (!jQuery(this).hasClass("published")) {
          jQuery(this).hide();
        }
      });


      // var allData = await getAllMediaLicenseAssetImport();
      // if (allData.length > 0) {
      //   this.setState({ actiontext: "bulkupload", allImportData: allData, assetwaitingcount: allData.length });
      // }

      this.setState({ loader: false, itemsAdded: this.state.bulkItemsToPublish.length, actiontext: "bulkupload" });
      this.setBulkData();

      this.setState({ dataLoaded: false });



    }

  }

  bulkDelete = async () => {

    if (this.state.bulkItemsToPublish.length > 0) {
      for (let i = 0; i < this.state.bulkItemsToPublish.length; i++) {
        this.setState({ loader: true });

        jQuery('input:checkbox[name="bulkcheck"][value=' + this.state.bulkItemsToPublish[i] + ']').removeClass('active');
        var deleted = await deleteRecords(this.state.bulkItemsToPublish[i]);
      }


      var allData = await getAllMediaLicenseAssetImport();
      // if (allData.length > 0) {
      this.setState({ actiontext: "delete", allImportData: allData, assetwaitingcount: allData.length });
      // }

      if (allData.length === 0) {
        this.setState({ dataLoaded: false, allImportData: null, percentage: 0 })
        document.getElementById("customFile2").value = null;
      }

      this.setState({ loader: false, itemsDeleted: this.state.bulkItemsToPublish.length });
      this.setBulkData();

    }

  }


  deleteRecord = async (e) => {
    var deleteobj = e.target.getAttribute("data-objectid");
    jQuery('input:checkbox[name="bulkcheck"][value=' + deleteobj + ']').removeClass('active');

    var deleted = await deleteRecords(deleteobj);
    if (deleted === true) {
      var allData = await getAllMediaLicenseAssetImport();
      if (allData.length > 0) {
        this.setState({ updateIDforImage: deleteobj, actiontext: "delete", allImportData: allData, assetwaitingcount: allData.length });
      }
    }


    this.setBulkData();

  }




  render() {
    return (
      <div className="animated fadeIn bulkpage">
        <div id="myImg"></div>
        <div className="pageheader">
          <Row className="justify-content-between">

            {
              this.state.dataLoaded === true ?
                <Col className="col-md-auto">
                  <Button className="btn btn-default" onClick={this.confirmUpload}>Confirm Upload</Button>
                  <Button className="btn btn-primary ml-3" onClick={this.bulkDelete}>Delete</Button>
                </Col>
                :
                null
            }

            <Col className="col-md">
              <div className="assetsfilterrow2">
                <Row className="row justify-content-end align-items-center">

                  <Col className="col-auto">
                    <a href={SampleAsset} className="btn btn-primary" download>Download sample CSV</a>
                  </Col>
                </Row>
              </div>

            </Col>
          </Row>

        </div>
        {/* Header Div end */}


        {
          this.state.dataLoaded === true ?
            <Row className="justify-content-between">

              <Col className="col-auto">
                <div className="fs18">
                  <Form>
                    <FormGroup  inline className="form-group checkwithclass whitetext">
                      <div className="form-check form-check-inline">
                        <Input className="form-check-input" name="selectall" type="checkbox" id="inlineCheckbox5" value="all"
                          onChange={this.selectAll}
                        />
                        <Label htmlFor="inlineCheckbox5">Select all</Label>
                      </div>
                    </FormGroup>
                  </Form>
                </div>
              </Col>

              <Col className="col-auto">
                <div className="btngreen">{this.state.selectedcount} selected</div>
              </Col>

              <Col className="ml-2">
                {
                  this.state.assetwaitingcount ?
                    <div className="btndark">{this.state.assetwaitingcount} assets waiting to be approved</div>
                    :
                    null
                }

              </Col>
            </Row>
            :
            null
        }




        {
          this.state.loader === true ?
            <div className="loaderwrap"><div className="loader"></div></div>
            :
            null
        }


        {
          this.state.itemsAdded ?
            <div className="mesgbox text-success">{this.state.itemsAdded} records were added</div>
            :
            null
        }
        {
          this.state.itemsDeleted ?
            <div className="mesgbox text-success">{this.state.itemsDeleted} records were deleted</div>
            :
            null
        }


        {
          this.state.allImportData === null ?
            <div className="bulkfield">
              <Form>
                <InputGroup>
                  <div className="custom-file bulkimport">
                    <Input type="file" className="custom-file-input" id="customFile2" accept=".csv" onChange={this.fileChangedHandler} />
                    <label className="custom-file-label" htmlFor="customFile2">Click here to upload your file</label>
                  </div>
                </InputGroup>
              </Form>


              {
                this.state.percentage ?
                  <div>
                    <Progress className="mt-3 mb-3" value={this.state.percentage}>{this.state.percentage}%</Progress>
                    <p className='processingtext'>
                      {

                        this.state.percentage === 0 ?
                          "Progress"
                          :
                          this.state.percentage === 100 ?
                            "Completed"
                            :
                            "Processing upload..."
                      }
                    </p>
                  </div>
                  :
                  null
              }



            </div>
            :
            null
        }







        <div className="assetslisting bulklisting">
          <Row>


            {
              this.state.allImportData && this.state.allImportData.length > 0 ?

                this.state.allImportData.map((dynamicData, key) => {
                  return (
                    <Col className="col-md-3 importeditem" data-obj={dynamicData.id} key={key}>
                      <div className="assetscard active">
                        <Row className="justify-content-between">
                          <Col>
                            <div className="fs18">
                              <Form>
                                <div className="checkwithclass">
                                  <div className="form-check form-check-inline">
                                    <Input name="bulkcheck" type="checkbox" id={`bulk${dynamicData.id}`}
                                      value={dynamicData.id}
                                      onChange={this.bulkcheck}
                                      className="form-check-input"
                                    />
                                    <Label htmlFor={`bulk${dynamicData.id}`}>ID# {dynamicData.get('assetid') ? dynamicData.get('assetid') : ""}</Label>
                                  </div>
                                </div>
                              </Form>
                            </div>
                          </Col>
                          {/* <Col className="col-auto pl-0">
                            <a href={dynamicData.get('asseturl') ? dynamicData.get('asseturl') : "javascript:"} className="externallink" target="_blank"><i className="fas fa-external-link-alt"></i></a>
                          </Col> */}
                        </Row>
                        <div className="imgbx">
                          <a href="javascript:" onClick={this.viewpopuptoggle}>
                            <Assetimagebulk
                              objid={dynamicData.id}
                              imageurl={dynamicData.get('assetimage') ? dynamicData.get('assetimage')[0] : ""}
                              action={this.state.actiontext}
                              updateIDforImage={this.state.updateIDforImage}
                            />
                          </a>
                        </div>
                        <Row>
                          <Col className="col-12">
                            <ScrollArea smoothScrolling={0.8} className="thinsscroll" style={{ maxHeight: '80px', minHeight: '80px' }}>
                              <p className="shortdetail">
                                {dynamicData.get('description')}
                              </p>
                            </ScrollArea>
                          </Col>
                        </Row>
                        <div className="row mt-3">
                          <div className="col">
                            <div className="librarydetail">{dynamicData.get('medialicenselibraryid') ? dynamicData.get('medialicenselibraryid').get('name') : ""}</div>
                          </div>
                          {/* <div className="col-auto col">
                            <a href="javascript:" className="externallink" onClick={this.deleteRecord} >
                              <i className="fas fa-trash-alt" data-objectid={dynamicData.id}></i>
                            </a>
                          </div> */}
                        </div>
                      </div>
                    </Col>
                  )
                })
                :
                null
            }



          </Row>
        </div>



        <Viewimportedassets
          isOpen={this.state.viewpopup}
          toggle={this.viewpopuptoggle}
          viewObjectID={this.state.editObjectID}
          viewListFull={this.state.allImportData ? this.state.allImportData : null}
        />



      </div>
    )
  }
}

export default bulkImport;
