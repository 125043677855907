import React from 'react';
import { TabContent, TabPane, Card, CardBody, CardTitle, Row, Col } from 'reactstrap';
import { VectorMap } from "react-jvectormap";
import './vectormap.css';
import SweetAlert from "react-bootstrap-sweetalert";
// import { events } from "./event-data.jsx";
import jQuery from 'jquery';

import { getCurrentUser, authenticateLoggedIn, addHeaderTitle, addBreadcrumb, isSuperAdmin, authenticateLoginpage } from '../../../services/parse';
import { getDomainExpiry, getDomainFull, getCountries } from '../../../services/dashboard';
import classnames from 'classnames';
import {getMasterbrandOnlyCompleted,getAllCountryCodesByJurisdictions,getMasterbrandOnlyPending,
        getAllCompleted,getAllPending} from '../../../services/dashboardMap';
import Loader from '../../../components/common/Loader';
var _ = require('lodash');


var trimvaldot = "";

class Map extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			events: [],
			alert: null,
			modal: true,
			expiryin30: 0,
			expiryin60: 0,
			expiryin90: 0,
            mapData: [],
            countryListByJurd:null,
			countrylist: null,
            activeTab: '1',
            countryListMap1:null,
            completedListMap1:null,
            pendingListMap1:null,
            countryListMap2:null,
            completedListMap2:null,
            pendingListMap2:null,
            countryListMap3:null,
            completedListMap3:null,
            pendingListMap3:null,
		};
	}

    componentWillMount=async()=>{
        var allcountcodes = await getAllCountryCodesByJurisdictions();
        this.setState({countryListByJurd:allcountcodes},async()=>{ 
            // console.log(this.state.countryListByJurd)    
            
            
            //code for map 1 starts      
            var map1Completed = await getMasterbrandOnlyCompleted();
            var map1Pending = await getMasterbrandOnlyPending(map1Completed.map(obj=>obj.attributes.trademark.id));

            var completedListMap1=await this.getCompletedLists(map1Completed,null);
            var pendingListMap1=await this.getPendingLists(map1Pending,null); 
            //console.log(completedListMap1,pendingListMap1);   
            var countryListMap1 = [...completedListMap1.countryListMap,...pendingListMap1.countryListMap];
            var tempCountArr = [...new Set(countryListMap1)];
            countryListMap1=[]
            tempCountArr.filter(x=>x.length==2).map(x=>countryListMap1[x]=100)

            var map2Completed = await getAllCompleted();
            // console.log('map2Completed',map2Completed);
            var map2Pending = await getAllPending(map2Completed.map(obj=>obj.attributes.trademark?obj.attributes.trademark.id:''));

            var completedListMap2=await this.getCompletedLists(map2Completed,null);
            var pendingListMap2=await this.getPendingLists(map2Pending,null); 
            //console.log(completedListMap1,pendingListMap1);   
            var countryListMap2 = [...completedListMap2.countryListMap,...pendingListMap2.countryListMap];
            var tempCountArr = [...new Set(countryListMap2)];
            countryListMap2=[]
            tempCountArr.filter(x=>x.length==2).map(x=>countryListMap2[x]=100)

            var completedListMap3=await this.getCompletedLists(map2Completed,"GHzti1je5V");
            var pendingListMap3=await this.getPendingLists(map2Pending,"GHzti1je5V"); 
            // console.log(completedListMap3,pendingListMap3);   
            var countryListMap3 = [...completedListMap3.countryListMap,...pendingListMap3.countryListMap];
            var tempCountArr = [...new Set(countryListMap3)];
            countryListMap3=[]
            tempCountArr.filter(x=>x.length==2).filter(x=>completedListMap3.completedListMap[x]>0 || pendingListMap3.pendingListMap[x]>0).map(x=>countryListMap3[x]=100)

            // var temp1cnt=[];
            // var temp2cnt=[];
            // temp1cnt = completedListMap3.completedListMap.map(x=>x)
            // pendingListMap3.pendingListMap.map(x=>temp2cnt[x]=100)    
            // console.log(temp1cnt,temp2cnt)        
            // var countryListMap3 = [...temp1cnt,...temp2cnt];
            
            this.setState({ countryListMap1:countryListMap1,
                            completedListMap1:completedListMap1.completedListMap,
                            pendingListMap1:pendingListMap1.pendingListMap,
                            countryListMap2:countryListMap2,
                            completedListMap2:completedListMap2.completedListMap,
                            pendingListMap2:pendingListMap2.pendingListMap,
                            countryListMap3:countryListMap3,
                            completedListMap3:completedListMap3.completedListMap,
                            pendingListMap3:pendingListMap3.pendingListMap})
            //code for map 1 ends      
           
        });


    }

    getCompletedLists = async(mapCompleted,removeJur)=>{
        var countryListMap = [];
        var completedListMap=[];
        
        mapCompleted.map(obj=>{
                var tempcompletedListMap=[]
                if( obj.attributes.trademark && obj.attributes.trademark.attributes.jurisdictions)
                {
                    
                    var tempCompArr = obj.attributes.trademark.attributes.jurisdictions.map(x=>{
                        var boolWipo = true;
                        if(removeJur && x.id==removeJur)
                        {
                            boolWipo= false;                            
                        }
                        if(boolWipo){
                        //adding complete count array
                        if(this.state.countryListByJurd[x.id])
                        {
                            this.state.countryListByJurd[x.id].map(cc=>{
                                if(completedListMap[cc])
                                {
                                    completedListMap[cc]=completedListMap[cc]+1;
                                }
                                else
                                {
                                    completedListMap[cc]=1;
                                }                            
                            });
                        }
                        countryListMap = [...countryListMap,...this.state.countryListByJurd[x.id]]   
                    }                      
                    })
                

                }
               // console.log(countryListMap,completedListMap);
                if(obj.attributes.trademark && obj.attributes.trademark.attributes.countries)
                {
                    var tempCompArr = obj.attributes.trademark.attributes.countries.map(x=>{
                        var countryCode = x.attributes.country_code.replace(/\s/g, "");
                        var boolWipo=true;
                        // if(removeJur && this.state.countryListByJurd[removeJur].includes(countryCode))
                        // {
                        //     boolWipo= false;
                            
                        // }
                        //console.log(boolWipo)
                        if(countryCode.length==2 && boolWipo)
                        {
                            if(completedListMap[countryCode])
                            {
                                completedListMap[countryCode]=completedListMap[countryCode]+1;
                            }
                            else
                            {
                                completedListMap[countryCode]=1;
                            }
                        }
                        countryListMap = [...countryListMap,countryCode]                         
                    })
                
                }
            }) 

            return{countryListMap:countryListMap,completedListMap:completedListMap}
    }

    getPendingLists = async(mapPending,removeJur)=>{
        var countryListMap = [];
        var pendingListMap=[];

        mapPending.map(obj=>{
            //console.log(this.state.countryListByJurd);
            if(obj.attributes.jurisdictions)
            {
                var tempCompArr = obj.attributes.jurisdictions.map(x=>{
                    //adding complete count array
                    var boolWipo = true;
                        if(removeJur && x.id==removeJur)
                        {
                            boolWipo= false;
                            
                        }
                        if(boolWipo){
                    if(this.state.countryListByJurd[x.id])
                    {
                        this.state.countryListByJurd[x.id].map(cc=>{
                            if(pendingListMap[cc])
                            {
                                pendingListMap[cc]=pendingListMap[cc]+1;
                            }
                            else
                            {
                                pendingListMap[cc]=1;
                            }
                        
                        });
                    }
                    countryListMap = [...countryListMap,...this.state.countryListByJurd[x.id]]     
                }                    
                })
            
            }
           // console.log(countryListMap,completedListMap);
            if(obj.attributes.countries)
            {
                var tempCompArr = obj.attributes.countries.map(x=>{
                    var countryCode = x.attributes.country_code.replace(/\s/g, "");
                    var boolWipo=true;
                        // if(removeJur && this.state.countryListByJurd[removeJur].includes(countryCode))
                        // {
                        //     boolWipo= false;
                            
                        // }
                       // console.log(boolWipo)
                    if(countryCode.length==2 && boolWipo)
                    {
                        if(pendingListMap[countryCode])
                        {
                            pendingListMap[countryCode]=pendingListMap[countryCode]+1;
                        }
                        else
                        {
                            pendingListMap[countryCode]=1;
                        }
                    }
                    countryListMap = [...countryListMap,countryCode]                         
                })
            
            }
        }) 
        return({countryListMap:countryListMap,pendingListMap:pendingListMap})
    }

	async componentDidMount(evt) {

	}


	alertselectedEvent(event) {
		if (event.type == "trademark") {
			window.location.assign('#/trademark/edittrademark/' + event.id);
		}
		if (event.type == "domain") {
			window.location.assign('#/domain/editdomain/' + event.id + '/default');
		}


		// alert("\n" + event.title + " \n\nStart Time: " + event.start + " \nEnd Time: " + event.end);

	}
	
	

	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
    }
    
    render() {




		
        return (
<Card className="card-map">
							<CardBody>
								<h5 className="card-title">Trademark Global Countries View</h5>
								<div className="maptabs d-flex">
									<a className={"btn btn-primary " + classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>Abdul Latif Jameel Masterbrand</a>
									<a className={"btn btn-primary " + classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>All Brands / Trademarks, Incl. WIPO</a>
									<a className={"btn btn-primary " + classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>All Brands / Trademarks, excl. WIPO</a>
								</div>


								<TabContent activeTab={this.state.activeTab}>

									<TabPane tabId="1">
                                    
                                  {this.state.countryListMap1?
                                  <div>
                                        <VectorMap
                                        map={"world_mill"}
                                        backgroundColor="transparent"
                                        zoomOnScroll={false}
                                        containerStyle={{
                                            width: "100%",
                                            height: "400px"
                                        }}
                                        containerClassName="map"
                                        regionStyle={{
                                            initial: {
                                                fill: "#63a8c0",
                                                "fill-opacity": 0.9,
                                                stroke: "1",
                                                "stroke-width": 1,
                                                "stroke-opacity": 0.5
                                            }
                                        }}
                                        series={{
                                            regions: [
                                                {
                                                    values: this.state.countryListMap1,
                                                    scale: ["#246b97", "#2075a9"]
                                                }
                                            ]
                                        }}
                                        onRegionTipShow={(e,el,code)=>{
                                            if(this.state.completedListMap1[code] && this.state.pendingListMap1[code]){
                                                el.html('<span>'+el.html()+':</span><br/>Completed ('+this.state.completedListMap1[code]+') <br/>Pending ('+this.state.pendingListMap1[code]+')');
                                        }
                                            else if(this.state.completedListMap1[code]){
                                                el.html('<span>'+el.html()+'</span><br/>Completed ('+this.state.completedListMap1[code]+')');
                                                }
                                            else if(this.state.pendingListMap1[code]){
                                                el.html('<span>'+el.html()+'</span><br/>Pending ('+this.state.pendingListMap1[code]+')');
                                                }   
                                            else{
                                                el.html('<span>'+el.html()+'</span>')
                                            }    
                                        }}
                                    />
                                    <Row>
									<Col md="auto">
										<CardTitle>
											<span className="mb-2"><i className="fas fa-square mr-1 circle-map2"></i> Abdul Latif Jameel Masterbrand trademarks registered </span>
											<span><i className="fas fa-square mr-1 circle-map1"></i> No trademarks registered (excl. WIPO) </span>
										</CardTitle>
									</Col>
								</Row>
                                </div>
                                    : <div className="maploader"><Loader type="Oval" color="#51859a" height={40} width={40} /></div>}

                                    
									</TabPane>

									<TabPane tabId="2">		
                                    								
                                    {this.state.countryListMap2?
                                    <div>
                                        <VectorMap
                                        map={"world_mill"}
                                        backgroundColor="transparent"
                                        zoomOnScroll={false}
                                        containerStyle={{
                                            width: "100%",
                                            height: "400px"
                                        }}
                                        containerClassName="map"
                                        regionStyle={{
                                            initial: {
                                                fill: "#63a8c0",
                                                "fill-opacity": 0.9,
                                                stroke: "1",
                                                "stroke-width": 1,
                                                "stroke-opacity": 0.5
                                            }
                                        }}
                                        series={{
                                            regions: [
                                                {
                                                    values: this.state.countryListMap2,
                                                    scale: ["#246b97", "#2075a9"]
                                                }
                                            ]
                                        }}
                                        onRegionTipShow={(e,el,code)=>{
                                            if(this.state.completedListMap2[code] && this.state.pendingListMap2[code]){
                                                el.html('<span>'+el.html()+':</span><br/>Completed ('+this.state.completedListMap2[code]+')<br/>Pending ('+this.state.pendingListMap2[code]+')');
                                            }
                                            else if(this.state.completedListMap2[code]){
                                                el.html('<span>'+el.html()+'</span><br/>Completed ('+this.state.completedListMap2[code]+')');
                                                }
                                            else if(this.state.pendingListMap2[code]){
                                                el.html('<span>'+el.html()+'</span><br/>Pending ('+this.state.pendingListMap2[code]+')');
                                                }   
                                                else{
                                                    el.html('<span>'+el.html()+'</span>')
                                                }       
                                        }}
                                    />
                                    <Row>
									<Col md="auto">
										<CardTitle>
											<span className="mb-2"><i className="fas fa-square mr-1 circle-map2"></i> One or more trademarks registered </span>
											<span><i className="fas fa-square mr-1 circle-map1"></i> Additional WIPO registrations (FRV before 2015) </span>
										</CardTitle>
									</Col>
								</Row>
                                </div>
                                    : <div className="maploader"><Loader type="Oval" color="#51859a" height={40} width={40} /></div>}


                                   
									</TabPane>

									<TabPane tabId="3">		
                                    								
                                    {this.state.countryListMap3?
                                    <div>
                                        <VectorMap
                                        map={"world_mill"}
                                        backgroundColor="transparent"
                                        zoomOnScroll={false}
                                        containerStyle={{
                                            width: "100%",
                                            height: "400px"
                                        }}
                                        containerClassName="map"
                                        regionStyle={{
                                            initial: {
                                                fill: "#63a8c0",
                                                "fill-opacity": 0.9,
                                                stroke: "1",
                                                "stroke-width": 1,
                                                "stroke-opacity": 0.5
                                            }
                                        }}
                                        series={{
                                            regions: [
                                                {
                                                    values: this.state.countryListMap3,
                                                    scale: ["#246b97", "#2075a9"]
                                                }
                                            ]
                                        }}
                                        // onRegionTipShow={(e,el,code)=>{
                                        //     el.html(el.html()+ this.state.completedListMap[code]?'<br/>Completed : '+this.state.completedListMap[code]:null+
                                        //     this.state.pendingListMap[code]?'<br/>Pending '+this.state.pendingListMap[code]:null);
                                        // }}
                                        onRegionTipShow={(e,el,code)=>{
                                            if(this.state.completedListMap3[code] && this.state.pendingListMap3[code]){
                                                el.html('<span>'+el.html()+':</span><br/>Completed ('+this.state.completedListMap3[code]+')<br/>Pending ('+this.state.pendingListMap3[code]+')');
                                            }
                                            else if(this.state.completedListMap3[code]){
                                                el.html('<span>'+el.html()+'</span><br/>Completed ('+this.state.completedListMap3[code]+')');
                                                }
                                            else if(this.state.pendingListMap3[code]){
                                                el.html('<span>'+el.html()+'</span><br/>Pending ('+this.state.pendingListMap3[code]+')');
                                                }  
                                                else{
                                                    el.html('<span>'+el.html()+'</span>')
                                                }        
                                        }}
                                    /><Row>
									<Col md="auto">
										<CardTitle>
											<span className="mb-2"><i className="fas fa-square mr-1 circle-map2"></i> One or more trademarks registered </span>
											<span><i className="fas fa-square mr-1 circle-map1"></i> No trademark registered (excl. WIPO) </span>
										</CardTitle>
									</Col>
								</Row>
                                </div>
                                    : <div className="maploader"><Loader type="Oval" color="#51859a" height={40} width={40} /></div>}


                                    
									</TabPane>
								</TabContent>
								

							</CardBody>
						</Card>	);
	}
}

export default Map;