import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, CardBody, CardTitle, Row, Col } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Projects, RecentComment, Trademark, RecentActivity, MediaActivity, DomainName, Model, Map } from '../../components/dashboard-components';
import { VectorMap } from "react-jvectormap";
import './vectormap.css';
import {BigCalendar, Calendar, momentLocalizer  } from "react-big-calendar";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { events } from "./event-data.jsx";
import "./calendar.css";
import jQuery from 'jquery';

import imgglobe from '../../assets/images/icons/icon-globe.png';
import imgtrademark from '../../assets/images/icons/icon-trademark.png';
import imgarrowright from '../../assets/images/icons/arrow-right.png';

import { Doughnut, Line, Bar, Radar, Pie, Polar } from 'react-chartjs-2';
import * as d from './chartjs-data';
import { isAdmin } from '../../services/user';
import { getCurrentUser, authenticateLoggedIn, addHeaderTitle, addBreadcrumb, isSuperAdmin, authenticateLoginpage } from '../../services/parse';
import { getDomainExpiry, getTrademarkExpiry, getTrademarkUsedExpiry, getDomainFull, getTrademarkProgressFull, getCountries } from '../../services/dashboard';
import classnames from 'classnames';



// BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));
const localizer = momentLocalizer(moment);

//Pie Chart
var pieData = {
	labels: [
		'Green',
		'Yellow',
		'Blue'
	],
	datasets: [{
		data: d.chartData.pieData.data,
		backgroundColor: [
			'#2dce89',
			'#5e72e4',
			'#23b7e5'
		],
		hoverBackgroundColor: [
			'#2dce89',
			'#5e72e4',
			'#23b7e5'
		]
	}]
}

class Dashboard extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			events: [],
			alert: null,
			modal: true,
			expiryin30: 0,
			expiryin60: 0,
			expiryin90: 0,
			trademarkin30: 0,
			trademarkin60: 0,
			trademarkin90: 0,
			trademarkusedin30: 0,
			trademarkusedin60: 0,
			trademarkusedin90: 0,
			mapData: [],
			activeTab: '1',
			currentRole: null,
		};
		this.hideAlert = this.hideAlert.bind(this);
	}

	componentWillMount = async () => {

		// setTimeout(
		// 	function() {
		// 		window.location.reload();
		// 	}
		// 	.bind(this),
		// 	5 * 60 * 1000
		// );



		// async componentDidMount(evt) {

		authenticateLoggedIn();

		addHeaderTitle('Dashboard');
		addBreadcrumb('Home');

		var isadminval = await isAdmin();
		if (isadminval) {
			var currentrole = isadminval[0].get('role').get('name');
			if (currentrole === "Legal" || currentrole === "External" || currentrole === "Finance") {
				//this.setState({ currentRole: "Legal" });
				this.setState({ currentRole: currentrole });
			} else {
				//this.setState({ currentRole: "" });
				this.setState({ currentRole: currentrole });
			}
		} else {
			this.setState({ currentRole: "" });
		}

		getDomainExpiry(30).then((value) => {
			this.setState({ expiryin30: value });
		});
		getDomainExpiry(60).then((value) => {
			this.setState({ expiryin60: value });
		});
		getDomainExpiry(90).then((value) => {
			this.setState({ expiryin90: value });
		});

		getTrademarkExpiry(30).then((value) => {
			this.setState({ trademarkin30: value });
		});
		getTrademarkExpiry(60).then((value) => {
			this.setState({ trademarkin60: value });
		});
		getTrademarkExpiry(90).then((value) => {
			this.setState({ trademarkin90: value });
		});

		getTrademarkUsedExpiry(30).then((value) => {
			this.setState({ trademarkusedin30: value });
		});
		getTrademarkUsedExpiry(60).then((value) => {
			this.setState({ trademarkusedin60: value });
		});
		getTrademarkUsedExpiry(90).then((value) => {
			this.setState({ trademarkusedin90: value });
		});



		const events = [];
		if (currentrole !== "Legal" && currentrole !== "External") {
			var value = await getDomainFull();
			jQuery.each(value, function (key, value) {
				if (value.attributes.expiry_date) {
					var datelocale = value.attributes.expiry_date.toLocaleDateString();
					var cdata = {
						title: value.get('name') + " Renewal",
						allDay: true,
						start: new Date(datelocale),
						end: new Date(datelocale),
						color: "red",
						id: value.id,
						type: "domain",
					}
					events.push(cdata);
				}
			});
		}



		var value = await getTrademarkProgressFull();
		jQuery.each(value, function (key, value) {

			if (value.attributes.nextRenewalDate) {
				var datelocale = value.attributes.nextRenewalDate.toLocaleDateString();
				if (value.get('trademark')) {
					var cdata = {
						title: value.get('trademark').get('name') + " Renewal",
						allDay: true,
						start: new Date(datelocale),
						end: new Date(datelocale),
						color: "default",
						id: value.get('trademark').id,
						type: "trademark",
					}
				}
				events.push(cdata);
			}
		});
		this.setState({ events: events });



		function explode() {
			//jQuery('.rbc-btn-group button:first-child').trigger("click");
		}
		setTimeout(explode, 1500);

	}


	alertselectedEvent(event) {

		if (event.type == "trademark") {
			window.location.assign('#/trademark/edittrademark/' + event.id);
		}
		if (event.type == "domain") {
			window.location.assign('#/domain/editdomain/' + event.id + '/default');
		}


		// alert("\n" + event.title + " \n\nStart Time: " + event.start + " \nEnd Time: " + event.end);

	}
	addNewEventAlert(slotInfo) {
		this.setState({
			alert: (
				<div><SweetAlert
					input
					showCancel
					style={{}}
					title="Create New Event"
					placeHolder="Write Event Title"
					onConfirm={e => this.addNewEvent(e, slotInfo)}
					onCancel={() => this.hideAlert()}
					cancelBtnBsStyle="danger"
					confirmBtnBsStyle="info"
				>
				</SweetAlert>

				</div>
			)
		});
	}
	addNewEvent(e, slotInfo) {

		var newEvents = this.state.events;
		newEvents.push({
			title: e,
			start: slotInfo.start,
			end: slotInfo.end
		});

		this.setState({
			alert: null,
			events: newEvents
		});

	}
	hideAlert() {
		this.setState({
			alert: null
		});
	}
	eventColors(event, start, end, isSelected) {
		var backgroundColor = "event-";
		event.color
			? (backgroundColor = backgroundColor + event.color)
			: (backgroundColor = backgroundColor + "default");
		return {
			className: backgroundColor
		};
	}

	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}

	toggleTabs(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}


	render() {



		// var cookiename = 'cookieuser';
		// var value = "; " + document.cookie;
		// var parts = value.split("; " + cookiename + "=");
		// if (parts.length == 2) {
		// 	var part2 = parts.pop().split(";").shift();
		// } else {
		// 	var part2 = "";
		// }


		return (
			<div className="dashboardpage">

				{/* {
					part2 !== 'yes' ?
						<Model />
						:
						""
				} */}





				<Row>
					{/* {
						this.state.currentRole && this.state.currentRole !== "Legal" ?
							<Col md="6" className="mb-30">
								<Trademark />
							</Col>
							:
							<Col md="6" className="mb-30">
								<Trademark />
							</Col>
					} */}
					<Col md="6" className="mb-30">
						<Trademark />
					</Col>

					{
						this.state.currentRole && this.state.currentRole !== "Legal" && this.state.currentRole !== "External" ?
							<Col md="6" className="mb-30">
								<DomainName />
							</Col>
							:
							null
					}




					{/* <Col md="3" className="mb-30 legalbox"> */}
					{this.state.currentRole !== "Executive" ?
						<Col md="6" className={
							this.state.currentRole && this.state.currentRole !== "Legal" && this.state.currentRole !== "External" && this.state.currentRole !== "Executive" ?
								"mb-30"
								:
								"mb-30 legalbox"
						}>

							<Card className={
								this.state.currentRole && this.state.currentRole !== "Legal" && this.state.currentRole !== "External" && this.state.currentRole !== "Executive" ?
									"status-card"
									:
									"status-card h100"
							}>
								<CardBody>
									<Row>
										<Col className="fixedwidth">
											<div className="imagebx">
												<img src={imgtrademark} alt={"imgtrademark"} />
											</div>
										</Col>
										<Col>
											<CardTitle>Trademark Alerts</CardTitle>
											<ul className="m-0 p-0">

												<li className="d-flex">
													{this.state.trademarkin30} expiring in 30 days
													<a href="javascript:" className="ml-auto">
													</a>
												</li>
												<li className="d-flex">
													{this.state.trademarkin60} expiring in 60 days
													<a href="javascript:" className="ml-auto">
													</a>
												</li>
												<li className="d-flex">
													{this.state.trademarkin90} expiring in 90 days
													<a href="javascript:" className="ml-auto">
													</a>
												</li>

												<li className="d-flex bordertop">
													{this.state.trademarkusedin30} to be used in 30 days
													<a href="javascript:" className="ml-auto">
													</a>
												</li>
												<li className="d-flex">
													{this.state.trademarkusedin60} to be used in 60 days
													<a href="javascript:" className="ml-auto">
													</a>
												</li>
												<li className="d-flex">
													{this.state.trademarkusedin90} to be used in 90 days
													<a href="javascript:" className="ml-auto">
													</a>
												</li>

											</ul>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
						:
						null}





					<Col md="6" className={
						this.state.currentRole && this.state.currentRole !== "Legal" && this.state.currentRole !== "Executive" ?
							"mb-30"
							:
							"mb-30 legalbox"
					}>
						{
							this.state.currentRole && this.state.currentRole !== "Legal" && this.state.currentRole !== "External" && this.state.currentRole !== "Executive" ?
								<Card className="status-card">
									<CardBody>
										<Row>
											<Col className="fixedwidth">
												<div className="imagebx">
													<img src={imgglobe} alt={"imgglobe"} />
												</div>
											</Col>
											<Col >
												<CardTitle>Domain Name Alerts</CardTitle>
												<ul className="m-0 p-0">
													<li className="d-flex">
														{this.state.expiryin30} expiring in 30 days
													</li>
													<li className="d-flex">
														{this.state.expiryin60} expiring in 60 days
													</li>
													<li className="d-flex">
														{this.state.expiryin90} expiring in 90 days
													</li>
													<li className="mb-51 d-flex">&nbsp;</li>
												</ul>
											</Col>
										</Row>
									</CardBody>
								</Card>
								:
								null
						}
					</Col>




























































				</Row>
				{/* <Stats /> */}


				{this.state.currentRole === "Executive" ?
					<Row>
						<Col lg={12} className="mb-30">
							<Map />
						</Col>


					</Row>

					:

					<Row>
						<Col lg={6} className="mb-30">
							<Map />
						</Col>
						{this.state.currentRole && this.state.currentRole !== "Legal" && this.state.currentRole !== "Executive" ?
							<Col lg={6} className="mb-30">
								{this.state.alert}
								<Card className="card-calendar">
									<CardBody>
										<Row className="mb-5">
											<Col>
												<CardTitle className="mb-4">At a Glance </CardTitle>
											</Col>
											<Col md="auto">
												<CardTitle className="text-right">
													<span className="mb-1">Trademark renewals <i className="fas fa-circle mr-1 circle-blue-dark font-12"></i></span>
													{this.state.currentRole !== "External" ?
														<span>Domain renewals <i className="fas fa-circle mr-1 circle-red-dark font-12"></i></span>
														: null}
												</CardTitle>
											</Col>
										</Row>

										{
											this.state.events ? 
												<Calendar 
													localizer={localizer}
													selectable
													events={this.state.events}
													defaultView="month"
													scrollToTime={new Date(1970, 1, 1, 6)}
													defaultDate={new Date()}
													onSelectEvent={event => this.alertselectedEvent(event)}
													eventPropGetter={this.eventColors}
												/>
												:
												""
										}

										{/* <BigCalendar
								selectable
								events={this.state.events}
								defaultView="month"
								scrollToTime={new Date(1970, 1, 1, 6)}
								defaultDate={new Date()}
								onSelectEvent={event => this.alertselectedEvent(event)}
								eventPropGetter={this.eventColors} /> */}
									</CardBody>
								</Card>
							</Col>
							: null}

					</Row>
				}


				<div className="extrainfosec mt-2">
					<Nav tabs>
						<NavItem>
							<NavLink className={classnames({ active: this.state.activeTab === '1' })}
								onClick={() => { this.toggleTabs('1'); }} >
								Domain & Trademark
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink className={classnames({ active: this.state.activeTab === '2' })}
								onClick={() => { this.toggleTabs('2'); }} >
								Media License
							</NavLink>
						</NavItem>
					</Nav>
					<TabContent activeTab={this.state.activeTab}>
						<TabPane tabId="1">
							{this.state.currentRole && this.state.currentRole !== "Legal" && this.state.currentRole !== "External" && this.state.currentRole !== "Finance" && this.state.currentRole !== "Executive" ?
								<RecentActivity />
								: null}
						</TabPane>
						<TabPane tabId="2">
							{this.state.currentRole && this.state.currentRole !== "Legal" && this.state.currentRole !== "External" && this.state.currentRole !== "Finance" && this.state.currentRole !== "Executive" ?
								<MediaActivity />
								: null}
						</TabPane>
					</TabContent>
				</div>


			</div>
		);
	}
}

export default Dashboard;
