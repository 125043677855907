import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormFeedback,
  CustomInput,
  Tooltip,
  UncontrolledTooltip
} from 'reactstrap';
import Select, { components } from "react-select";
import jQuery from 'jquery';

import { getCurrentUser, authenticateLoggedIn, addHeaderTitle, isSuperAdmin, authenticateLoginpage, addBreadcrumb } from '../../services/parse';
import { addRecord, checkFieldValid, getBusinessSectors, getCountries, getMasterbrands } from '../../services/companies';

var Parse = require('parse');

class addCompanies extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      collapse: true,
      fadeIn: true,
      timeout: 300,
      isSuper: '',
      addedclass: 'd-none',
      businessSectorData: [],
      countriesData: [],
      masterbrandData: [],
      isEdit: false,
      logofile: null,
    };
  }


  async componentDidMount(evt) {
    //auth start
    authenticateLoggedIn();
    addHeaderTitle('Add Company');
    addBreadcrumb('Home / Companies / Add Company');

    getBusinessSectors().then((value) => {
      this.setState({ businessSectorData: value, });
    });
    getCountries().then((value) => {
      this.setState({ countriesData: value, });
    });
    getMasterbrands().then((value) => {
      this.setState({ masterbrandData: value, });
    });

  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  fileChangedHandler = (event) => {
    const file = event.target.files[0];
    var parseFile = new Parse.File("logo.png", file);
    this.setState({ logofile: parseFile });

  }


  addRecord = () => {
    var validatestates = {
      business_sector: this.state.business_sector,
    };
    var valid = checkFieldValid(validatestates);
    if (valid !== false) {
      jQuery('#addbutton').prop('disabled', true);

      if (this.state.logofile) {
        this.state.logofile.save().then(function (file) {
          // The file has been saved to Parse. 
        }, function (error) {
        }).then((file) => {
        });
      }

      var name = jQuery('#bname').val();
      var company_code = jQuery('#company_code').val();
      var business_sector = this.state.business_sector;

      var countrylist = [];
      jQuery.each(jQuery("input[name='countrylist']:checked"), function () {
        countrylist.push(jQuery(this).val());
      });

      var masterbrand_division = this.state.masterbrand_division; //jQuery('#masterbrand_division').val();
      var website_url = jQuery('#website_url').val();
      var description = jQuery('#bdescription').val();
      var status = jQuery('#status').val();



      var domaincheck = jQuery('#domaincheck');
      if (domaincheck.is(":checked")) {
        var domaincheck = true;
      } else {
        var domaincheck = false;
      }

      var trademarkcheck = jQuery('#trademarkcheck');
      if (trademarkcheck.is(":checked")) {
        var trademarkcheck = true;
      } else {
        var trademarkcheck = false;
      }







      var fielddatas = {
        name: name,
        company_code: company_code,
        business_sector: business_sector,
        countrylist: countrylist,
        masterbrand: masterbrand_division,
        website_url: website_url,
        description: description,
        logo: this.state.logofile,
        domaincheck: domaincheck,
        trademarkcheck: trademarkcheck,
        status: status,
      };
      addRecord(fielddatas).then((value) => {
        if (value !== false) {
          this.setState({ addedclass: 'd-block' });

          setTimeout(
            function () {
              this.setState({ addedclass: 'd-none' });
              window.location.assign('#/companies/editcompanies/' + value.id);
            }
              .bind(this),
            1000
          );

        } else {
          this.setState({ addedclass: 'd-none' });
        }

        jQuery('#addform').trigger("reset");
        jQuery('#addbutton').prop('disabled', false);
      });

    }
  }





  render() {
    var resultstate = this.state.businessSectorData;
    var businesslist = [];
    if (resultstate.length > 0) {
      for (let i = 0; i < resultstate.length; i++) {
        businesslist[i] = { value: resultstate[i].id, label: resultstate[i].attributes.name }
      }
    }

    var masterstate = this.state.masterbrandData;
    var masterlist = [];
    if (masterstate.length > 0) {
      for (let i = 0; i < masterstate.length; i++) {
        masterlist[i] = { value: masterstate[i].id, label: masterstate[i].attributes.name }
      }
    }











    return <div>

      <Row className="justify-content-between topbuttonbar">
        <Col className="col-md-5">
          <Alert color="success" className={this.state.addedclass}>
            Record Added
                  <Link to="/companies/companies" className="float-right">
              &nbsp; Go to list
                  </Link>
          </Alert>
        </Col>
        <Col className="col-auto mb-4">
          <Link to="/companies/companies" className="btn btn-primary btncancle mr-3">
            <span className="border-left pl-2">Cancel</span>
          </Link>
          <Button type="button" color="primary" id="addbutton" onClick={this.addRecord}>
            <span className="border-left pl-2"></span>
            Save
          </Button>
        </Col>
      </Row>

      <Row>

        <Col md="5">


          <Card>
            <CardTitle>
              <i className="icon-circle icon-pencil"></i>
              Add Company
            </CardTitle>

            <CardBody>





              <Form id="addform">
                <FormGroup className="form-group">
                  <Label htmlFor="name">Name</Label>
                  <Input type="text" name="bname" id="bname" />
                </FormGroup>



                <FormGroup className="form-group">
                  <Label htmlFor="name">&nbsp;</Label>
                  <div className="customcheck greybgcheck yscroll form-inline">
                    <div className="form-check form-check-inline">
                      <Input type="checkbox" name="domaincheck" className="domaincheck" value="yes" id="domaincheck" />
                      <Label for="domaincheck">Domain</Label>
                    </div>

                    <div className="form-check form-check-inline">
                      <Input type="checkbox" name="trademarkcheck" className="trademarkcheck" value="yes" id="trademarkcheck" />
                      <Label for="trademarkcheck">Trademark</Label>
                    </div>
                  </div>
                </FormGroup>





                {/* <FormGroup className="form-group">
                  <Label htmlFor="name">Company Code</Label>
                  <Input type="text" name="company_code" id="company_code" />
                </FormGroup> */}

                {/* <FormGroup className="form-group">
                  <Label>Business Sector</Label>
 
                  <Select
                    id="business_sector"
                    className="reactSelect reactSelectClass"
                    closeMenuOnSelect={true}
                    options={businesslist}
                    onChange={(selectedOption) => {
                      this.setState({ business_sector: selectedOption.value });
                    }} 
                  /> 
                </FormGroup> */}


                {/* <FormGroup className="form-group">
                  <Label htmlFor="name">Country</Label>
                  <div className="customcheck greybgcheck yscroll form-inline">
                    {
                      this.state.countriesData.map((dynamicData, key) =>
                        <div className="form-check form-check-inline" key={key}>
                          <Input type="checkbox" name="countrylist" className="countrylist" value={dynamicData.id} id={dynamicData.id} />
                          <Label for={dynamicData.id}>{dynamicData.get('name')}</Label>
                        </div>
                      )}
                  </div>
                </FormGroup> */}


                {/* <FormGroup className="form-group">
                  <Label>Masterbrand division</Label>

                  <Select
                    id="masterbrand_division"
                    className="reactSelect reactSelectClass"
                    closeMenuOnSelect={true}
                    options={masterlist}
                    onChange={(selectedOption) => {
                      this.setState({ masterbrand_division: selectedOption.value });
                    }} 
                  />  
                </FormGroup> */}

                {/* <FormGroup className="form-group">
                  <Label htmlFor="name">Website url</Label>
                  <Input type="text" name="website_url" id="website_url" />
                </FormGroup> */}


                {/* <FormGroup className="form-group">
                  <Label htmlFor="description">Description</Label>
                  <Input type="textarea" name="bdescription" id="bdescription" rows="5" placeholder="Add your comments here..." />
                </FormGroup> */}

                {/* <FormGroup className="form-group">
                  <Label>Logo </Label>
                  <div className="custom-file">
                    <Input type="file" id="loginfile" name="loginfile" className="custom-file-input" onChange={this.fileChangedHandler} />
                    <label className="custom-file-label" htmlFor="inputGroupFile01">Upload your Logo here</label>
                  </div>
                </FormGroup> */}

                <FormGroup className="form-group">
                  <Label>Status</Label>
                  <select id="status" className="custom-select">
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                  </select>
                </FormGroup>


              </Form>
            </CardBody>
          </Card>

        </Col>
      </Row>

    </div>
  }
}

export default addCompanies;
