import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';

const LibraryImages = React.memo(({ imageurl, objid }) => {


    console.log('Rendering AssetImages component', imageurl, objid);

    return imageurl ?
        <LazyLoad offset={200} height={200} >
            <img data-objectid={objid} src={imageurl} alt='img' />
        </LazyLoad>

        :
        <p>Loading...</p>;
});

export default LibraryImages;