import Dashboard from '../views/dashboard/dashboard.jsx';
import AuthRoutes from './authroutes.jsx';
import Basicform from '../views/form-layouts/basic.jsx';
import FormSteps from '../views/steps/steps.jsx';
import Basictable from '../views/tables/tablebasic.jsx';

import user from '../views/user/user.jsx';
import addUser from '../views/user/addUser.jsx';
import editUser from '../views/user/editUser.jsx';

import businessUnit from '../views/businessUnit/businessUnit.jsx';
import addBusinessUnit from '../views/businessUnit/addBusinessUnit.jsx';
import editBusinessUnit from '../views/businessUnit/editBusinessUnit.jsx';

import emailtemplate from '../views/emailTemplate/emailTemplate.jsx';
import addEmailTemplate from '../views/emailTemplate/addEmailTemplate.jsx';
import editEmailTemplate from '../views/emailTemplate/editEmailTemplate.jsx';

import businessSector from '../views/businessSector/businessSector.jsx';
import addBusinessSector from '../views/businessSector/addBusinessSector.jsx';
import editBusinessSector from '../views/businessSector/editBusinessSector.jsx';

import jurisdiction from '../views/jurisdiction/jurisdiction.jsx';
import addJurisdiction from '../views/jurisdiction/addJurisdiction.jsx';
import editJurisdiction from '../views/jurisdiction/editJurisdiction.jsx';

import region from '../views/region/region.jsx';
import addRegion from '../views/region/addRegion.jsx';
import editRegion from '../views/region/editRegion.jsx';

import domainname from '../views/domainname/domainname.jsx';
import addDomainname from '../views/domainname/addDomainname.jsx';
import editDomainname from '../views/domainname/editDomainname.jsx';

import webcategory from '../views/webcategory/webcategory.jsx';
import addWebcategory from '../views/webcategory/addWebcategory.jsx';
import editWebcategory from '../views/webcategory/editWebcategory.jsx';

import registrar from '../views/registrar/registrar.jsx';
import addRegistrar from '../views/registrar/addRegistrar.jsx';
import editRegistrar from '../views/registrar/editRegistrar.jsx';

import countries from '../views/countries/countries.jsx';
import addCountry from '../views/countries/addCountry.jsx';
import editCountry from '../views/countries/editCountry.jsx';

import masterbranddivision from '../views/masterbranddivision/masterbrandDivision.jsx';
import addMasterbrandDivision from '../views/masterbranddivision/addMasterbrandDivision.jsx';
import editMasterbrandDivision from '../views/masterbranddivision/editMasterbrandDivision.jsx';

import classification from '../views/classification/classification.jsx';
import addClassification from '../views/classification/addClassification.jsx';
import editClassification from '../views/classification/editClassification.jsx';

import companies from '../views/companies/companies.jsx';
import addCompanies from '../views/companies/addCompanies.jsx';
import editCompanies from '../views/companies/editCompanies.jsx';

import lawfirm from '../views/lawfirm/lawfirm.jsx';
import addLawfirm from '../views/lawfirm/addLawfirm.jsx';
import editLawfirm from '../views/lawfirm/editLawfirm.jsx';

import domainStatus from '../views/domainstatus/domainStatus.jsx';
import addDomainStatus from '../views/domainstatus/addDomainStatus.jsx';
import editDomainStatus from '../views/domainstatus/editDomainStatus.jsx';

import domain from '../views/domain/domain.jsx';
import calendarview from '../views/domain/calendarview.jsx';
import addDomain from '../views/domain/addDomain.jsx';
import editDomain from '../views/domain/editDomain.jsx';

//imports for trademark pages
import viewAllTrademarks from '../views/trademark/viewAllTrademarks.jsx';
import tabContainer from '../views/trademark/tabContainer.jsx';
import finance from '../views/finance/finance.jsx';
import addFinance from '../views/finance/addFinance.jsx';
import editFinance from '../views/finance/editFinance.jsx';
import importComp from '../views/trademark/import';
 

import icnonDashboard from '../assets/images/icons/icon-dashboard.png';
import iconTrademark from '../assets/images/icons/icon-trademark.png';
import iconGlobe from '../assets/images/icons/icon-globe.png';
import iconUser from '../assets/images/icons/icon-user.png';
import iconFinaince from '../assets/images/icons/icon-finaince.png';
import iconChatKey from '../assets/images/icons/icon-chat-key.png';



var auths = [].concat(AuthRoutes);

var ThemeRouteExecutive = [ 
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'mdi mdi-account-circle',
    iconimg: icnonDashboard,
    component: Dashboard
  },
    
];
export default ThemeRouteExecutive;
