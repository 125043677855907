import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Alert,
} from 'reactstrap';
import Select, { components } from "react-select";
import jQuery from 'jquery'

import { getCurrentUser, authenticateLoggedIn, addHeaderTitle, isSuperAdmin, authenticateLoginpage, addBreadcrumb } from '../../services/parse';
import { addRecord, checkFieldValid } from '../../services/emailtemplate';

class addEmailTemplate extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      collapse: true,
      fadeIn: true,
      timeout: 300,
      isSuper: '',
      addedclass: 'd-none',
      companyByIdData: [],
      isEdit: false,
      logofile: null,
      rewardsfile: null,
      loginlogofile: null,
    };
  }


  async componentDidMount(evt) {
    //auth start
    authenticateLoggedIn();
    addHeaderTitle('Add Email Template');
    addBreadcrumb('Home / Email Template / Add Template');

  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }


  addRecord = () => {

    var valid = checkFieldValid('add');
    if (valid !== false) {
      jQuery('#addbutton').prop('disabled', true);

      var name = jQuery('#bname').val();
      var subject = jQuery('#subject').val();
      var htmldata = jQuery('#htmldata').val();
      var toemail = jQuery('#toemail').val();
      var status = jQuery('#status').val();
      var fielddatas = {
        name: name,
        subject: subject,
        htmldata: htmldata,
        toemail: toemail,
        status: status,
      };

      addRecord(fielddatas).then((value) => {
        if (value !== false) {
          this.setState({ addedclass: 'd-block' });

          setTimeout(
            function () {
              this.setState({ addedclass: 'd-none' });
              window.location.assign('#/emailtemplate/editemailtemplate/' + value.id);
            }
              .bind(this),
            1000
          );

        } else {
          this.setState({ addedclass: 'd-none' });
        }

        jQuery('#addform').trigger("reset");
        jQuery('#addbutton').prop('disabled', false);
      });

    }
  }





  render() {
    return <div>

      <Row className="justify-content-between topbuttonbar">
        <Col className="col-md-6">
          <Alert color="success" className={this.state.addedclass}>
            Record Added
                  <Link to="/emailtemplate/emailtemplate" className="float-right">
              &nbsp; Go to list
                  </Link>
          </Alert>
        </Col>
        <Col className="col-auto mb-4">
          <Link to="/emailtemplate/emailtemplate" className="btn btn-primary btncancle mr-3">
            <span className="border-left pl-2">Cancel</span>
          </Link>
          <Button type="button" color="primary" id="addbutton" onClick={this.addRecord}>
            <span className="border-left pl-2"></span>
            Save
          </Button>
        </Col>
      </Row>

      <Row>

        <Col md="6">


          <Card>
            <CardTitle>
              <i className="icon-circle icon-pencil"></i>
              Add Template
            </CardTitle>
            <CardBody>





              <Form id="addform">
                <FormGroup className="form-group">
                  <Label htmlFor="name">Name</Label>
                  <Input type="text" name="bname" id="bname" />
                </FormGroup>

                <FormGroup className="form-group">
                  <Label htmlFor="name">Subject</Label>
                  <Input type="text" name="subject" id="subject" />
                </FormGroup>

                <FormGroup className="form-group">
                  <Label htmlFor="description">HTML</Label>
                  <Input type="textarea" name="htmldata" id="htmldata" rows="8" placeholder="Add your template" />
                </FormGroup>

                <FormGroup className="form-group">
                  <Label htmlFor="name">To Email</Label>
                  <Input type="text" name="toemail" id="toemail" />
                </FormGroup>

                <FormGroup className="form-group">
                  <Label htmlFor="country">Status</Label>
                  <select name="status" id="status" className="form-control">
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                  </select>
                </FormGroup>



              </Form>
            </CardBody>
          </Card>

        </Col>
      </Row>

    </div>
  }
}

export default addEmailTemplate;
