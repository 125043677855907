import React from 'react';
import { Link } from 'react-router-dom';
import ScrollArea from 'react-scrollbar';
import { Row, Col, Carousel, CarouselItem, CarouselControl, Button, Table, Input, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, ModalFooter, Label, Alert, UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem, TabContent, TabPane, CardBody, Nav, NavItem, NavLink, Card, CardTitle, CardText, Progress, Badge } from 'reactstrap';
import Datetime from "react-datetime";
import DatePicker from "react-datepicker";
import 'react-datetime/css/react-datetime.css';
import jQuery from 'jquery';
import classnames from 'classnames';
import { addBreadcrumb, addHeaderTitle } from '../../../services/parse';
import { isAdmin } from '../../../services/user';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Viewagencyassets from "./Viewagencyassets";

import { duplicateRecord, getLicenseType, getAssetType, getMediaLicenseLibrary, getGeoRestriction, getAgency, getBrand, } from '../../../services/medialicenseassets';
import { getAllMediaLicenseAssetAgency, deleteRecords, archiveRecords } from '../../../services/medialicenseassetagency';
import Assetimageagency from "./Assetimageagency";
import { getFileUrl } from '../../../services/awsFileUpload';



function openFilter(e) {
  var thisitem = jQuery(e.target);
  jQuery('.filtermenu').not(thisitem.next()).removeClass('active');
  jQuery('.dropbtn').not(thisitem).removeClass('active');

  if (thisitem.hasClass('active')) {
    thisitem.removeClass('active');
  } else {
    thisitem.addClass('active');
  }

  if (thisitem.next().hasClass('active')) {
    thisitem.next().removeClass('active');
  } else {
    thisitem.next().addClass('active');
  }
}

jQuery(document).click(function (e) {
  var container = jQuery("#assestfilter");
  if (!container.is(e.target) && container.has(e.target).length === 0) {
    jQuery('.dropbtn').removeClass('active');
    jQuery('.filtermenu').removeClass('active');
  }
});


function setobjectid(num) {
  var elSlide = jQuery('.mediaslideitem[data-id=' + num + ']').data('obj');
  jQuery('#hiddenobjectID').val(elSlide);
}

var popSlide = {
  autoplay: false,
  autoplaySpeed: 5000,
  dots: false,
  arrows: true,
  infinite: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  afterChange: (current) => setobjectid(current),
};


var iconCamera = "";
var iconMedia = "";
var iconAudio = "";
var iconPencil = "";

class assetAgency extends React.Component {

  constructor(props) {
    super(props);
    this.state = { activeIndex: 0, visible: true };
    this.viewpopuptoggle = this.viewpopuptoggle.bind(this);
    this.deletetoggle = this.deletetoggle.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
    this.state = {
      loader: false,
      activeTab: '1',
      percentage: 0,
      allImportData: null,
      editObjectID: null,
      bulkItemsToPublish: [],
      actiontext: "",
      updateIDforImage: "",
      assetwaitingcount: "",
      selectedcount: 0,
      itemsAdded: "",
      itemsDeleted: "",


      keywordfilter: "",
      expiringinfilter: "",
      assettypefilter: [],
      libraryfilter: [],
      agencyfilter: [],
      addedonfilter: "",
      addedonfilterstart: null,
      addedonfilterend: null,


      medialicenselibrary: [],
      assettypes: [],
      agencylist: [],
    };

  }

  deletetoggle() {
    this.setState({
      deletepopup: !this.state.deletepopup
    });
  }

  componentWillMount = async () => {
    var isadminval = await isAdmin();
    if (isadminval) {
      var currentrole = isadminval[0].get('role').get('name');
      this.setState({ currentRole: currentrole });
      addBreadcrumb('Home / Agency Submissions');
      addHeaderTitle('Submissions');
    }


    this.setState({ loader: true });


    let params_arr = [];
    let urlString = window.location.href;
    let paramString = urlString.split('?')[1];
    if (paramString) {
      params_arr = paramString.split('&');
    }
    for (let i = 0; i < params_arr.length; i++) {
      let pair = params_arr[i].split('=');

      if (pair[0] === "objectid") { // this is for popup from activity
        this.setState({
          popupstatus: "view",
          viewpopup: !this.state.viewpopup
        });
        this.setObjectID(pair[1]);
        window.history.pushState({}, null, '/#/medialicense/agencysubmissions/');
      }
    }


    var allData = await getAllMediaLicenseAssetAgency();
    if (allData) {
      this.setState({ allImportData: allData, assetwaitingcount: allData.length, loader: false });
    }



    //Filter Datas
    var getassettype = await getAssetType();
    this.setState({ assettypes: getassettype });

    for (var i = 0; i < getassettype.length; i++) {
      if(getassettype[i].get('icon')){
        var img = getassettype[i].get('icon')[0].awsFileName;
        var imgurl = await getFileUrl("contentmaster", img);

        if(getassettype[i].get('name')==="Image"){
          iconCamera = imgurl.url;
        }else if(getassettype[i].get('name')==="Video"){
          iconMedia = imgurl.url;
        }else if(getassettype[i].get('name')==="Audio"){
          iconAudio = imgurl.url;
        }else if(getassettype[i].get('name')==="Vector"){
          iconPencil = imgurl.url;
        }

      }
    } 

    var medialiclib = await getMediaLicenseLibrary();
    var libraryOptions = [];
    var otherlib = "";
    if (medialiclib && medialiclib.length > 0) {
      for (let i = 0; i < medialiclib.length; i++) {
        if(medialiclib[i].attributes.name!=="Other"){
          libraryOptions.push({ value: medialiclib[i].id, label: medialiclib[i].attributes.name })
        }else{
          otherlib = { value: medialiclib[i].id, label: medialiclib[i].attributes.name };
        }
      }
      libraryOptions.sort(function (a, b) {
        a = a.label.toLowerCase();
        b = b.label.toLowerCase();
        if (a == b) return 0;
        return a < b ? -1 : 1;
      });
      libraryOptions.push(otherlib);
    }
    this.setState({ medialicenselibrary: libraryOptions });


    var agencies = await getAgency();
    var agencyOptions = [];
    if (agencies && agencies.length > 0) {
      for (let i = 0; i < agencies.length; i++) {
        agencyOptions.push({ value: agencies[i].id, label: agencies[i].attributes.name })
      }
    }
    this.setState({ agencylist: agencyOptions });
    //Filter Datas

  }



  genericFilter = async () => {
    this.setState({
      actiontext: "filter"
    });
    this.setState({ loader: true });

    var searchdatas = {
      keywordfilter: this.state.keywordfilter,
      expiringinfilter: this.state.expiringinfilter,
      assettypefilter: this.state.assettypefilter,
      libraryfilter: this.state.libraryfilter,
      agencyfilter: this.state.agencyfilter,
      addedonfilter: this.state.addedonfilter,
      addedonfilterstart: this.state.addedonfilterstart,
      addedonfilterend: this.state.addedonfilterend,
    };


    var allData = await getAllMediaLicenseAssetAgency(searchdatas);
    if (allData) {
      this.setState({ allImportData: allData, assetwaitingcount: allData.length, loader: false });
    }

    this.setState({
      actiontext: ""
    });
  }

  expiringFilterClick = async (event) => {
    this.setState({ expiringinfilter: event.target.value });
  }

  assettypeFilterClick = async (event) => {
    var check = event.target.checked;
    var val = jQuery(event.target).val();
    if (val === "all") {
      jQuery('input:checkbox[name="assettypefiltercheck"]').prop('checked', check);
    } else {
      jQuery('input:checkbox[name="assettypefiltercheck"][value="all"]').prop('checked', false);
    }

    var assetlist = [];
    jQuery.each(jQuery("input[name='assettypefiltercheck']:checked"), function () {
      assetlist.push(jQuery(this).val());
    });
    this.setState({ assettypefilter: assetlist });
  }

  libraryFilterClick = async (event) => {
    var check = event.target.checked;
    var val = jQuery(event.target).val();
    if (val === "all") {
      jQuery('input:checkbox[name="libraryfiltercheck"]').prop('checked', check);
    } else {
      jQuery('input:checkbox[name="libraryfiltercheck"][value="all"]').prop('checked', false);
    }

    var liblist = [];
    jQuery.each(jQuery("input[name='libraryfiltercheck']:checked"), function () {
      liblist.push(jQuery(this).val());
    });
    this.setState({ libraryfilter: liblist });
  }

  agencyFilterClick = async (event) => {
    var check = event.target.checked;
    var val = jQuery(event.target).val();
    if (val === "all") {
      jQuery('input:checkbox[name="agencyfiltercheck"]').prop('checked', check);
    } else {
      jQuery('input:checkbox[name="agencyfiltercheck"][value="all"]').prop('checked', false);
    }

    var agencylist = [];
    jQuery.each(jQuery("input[name='agencyfiltercheck']:checked"), function () {
      agencylist.push(jQuery(this).val());
    });
    this.setState({ agencyfilter: agencylist });
  }

  addedonFilterClick = async (event) => {
    var val = jQuery(event.target).val();
    this.setState({ addedonfilter: val });

    if (val !== "range") {
      this.setState({ addedonfilterstart: null, addedonfilterend: null });
    }

  }


  viewpopuptoggle(e) {

    this.setState({
      viewpopup: !this.state.viewpopup
    });
    this.setObjectID(e.target.getAttribute("data-objectid"));
  }



  setObjectID(objectid) {
    this.setState({
      editObjectID: objectid !== null ? objectid : null
    });
  }




  onDismiss() {
    this.setState({
      visible: false
    });
  }
  toggleTabs(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }



  selectAll = async (e) => {
    var check = e.target.checked;
    if (check === true) {
      jQuery('input:checkbox[name="bulkcheck"]').addClass('active');
      jQuery(e.target).addClass('active');
    } else {
      jQuery('input:checkbox[name="bulkcheck"]').removeClass('active');
      jQuery(e.target).removeClass('active');
    }

    this.setBulkData();
  }

  bulkcheck = async (e) => {
    var objid = e.target.value;
    var val = "";
    var thisitem = jQuery(e.target);

    if (thisitem.hasClass('active')) {
      val = false;
      thisitem.removeClass('active');
    } else {
      val = true;
      thisitem.addClass('active');
    }

    this.setBulkData();
  }

  setBulkData = async () => {

    var selcount = jQuery("input[name='bulkcheck'].active").length;
    this.setState({ selectedcount: selcount });

    var bkdata = [];
    jQuery.each(jQuery("input[name='bulkcheck'].active"), function () {
      bkdata.push(jQuery(this).val());
    });
    this.setState({ bulkItemsToPublish: bkdata });

    console.log(bkdata)
  }




  confirmUpload = async () => {

    if (this.state.bulkItemsToPublish.length > 0) {
      for (let i = 0; i < this.state.bulkItemsToPublish.length; i++) {
        this.setState({ loader: true });


        var dup = await duplicateRecord(this.state.bulkItemsToPublish[i], "agency");
        if (dup.id) {
          jQuery('input:checkbox[name="bulkcheck"][value=' + this.state.bulkItemsToPublish[i] + ']').removeClass('active');
          var deleted = await deleteRecords(this.state.bulkItemsToPublish[i]);
        }

      }


      var allData = await getAllMediaLicenseAssetAgency();
      if (allData.length > 0) {
        this.setState({ actiontext: "bulkupload", allImportData: allData, assetwaitingcount: allData.length });
      }
      this.setState({ loader: false, itemsAdded: this.state.bulkItemsToPublish.length });
      this.setBulkData();




    }

  }


  deleteRecord = async (e) => {
    var deleteobj = e.target.getAttribute("data-objectid");
    jQuery('input:checkbox[name="bulkcheck"][value=' + deleteobj + ']').removeClass('active');

    var deleted = await deleteRecords(deleteobj);
    if (deleted === true) {
      var allData = await getAllMediaLicenseAssetAgency();
      if (allData.length > 0) {
        this.setState({ updateIDforImage: deleteobj, actiontext: "delete", allImportData: allData, assetwaitingcount: allData.length });
      }
    }


    this.setBulkData();

  }



  archiveRecords = async () => {
    this.deletetoggle();

    if (this.state.bulkItemsToPublish.length > 0) {
      for (let i = 0; i < this.state.bulkItemsToPublish.length; i++) {
        this.setState({ loader: true });

        jQuery('input:checkbox[name="bulkcheck"][value=' + this.state.bulkItemsToPublish[i] + ']').removeClass('active');
        var deleted = await archiveRecords(this.state.bulkItemsToPublish[i]);
      }


      var allData = await getAllMediaLicenseAssetAgency();
      // if (allData.length > 0) {
      this.setState({ actiontext: "archive", allImportData: allData, assetwaitingcount: allData.length });
      // }
      this.setState({ loader: false, itemsDeleted: this.state.bulkItemsToPublish.length });
      this.setBulkData();


      setTimeout(function () {
        this.setState({
          itemsDeleted: "",
        });
      }.bind(this), 3000);

    }

  }




  render() {
    return (
      <div className="animated fadeIn bulkpage">
        <div id="myImg"></div>
        <div className="pageheader">
          <Row className="justify-content-between">

            <Col className="col-md-auto">
              <Button className="btn btn-default" onClick={this.confirmUpload}>Approve</Button>
              <Button className="btn btn-primary ml-3" onClick={this.deletetoggle}>Delete</Button>
            </Col>



            <Col className="col-md">


              <div className="assetsfilterrow1">
                <Row>
                  <Col className="col-12">
                    <Form>
                      <Row className="align-items-center">
                        <Col>
                          <div className="assestfilter" id="assestfilter">
                            <Row className="no-gutters">
                              <Col>
                                <div className="filbx">
                                  <label htmlFor="keyword">Enter keyword</label>
                                  <div className="mydropdown">
                                    <input type="text" id="keyword" placeholder="i.e space"
                                      value={this.state.keywordfilter}
                                      onChange={
                                        async (event) => {
                                          this.setState({ keywordfilter: event.target.value });
                                        }
                                      }
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col>
                                <div className="filbx">
                                  <label htmlFor="keyword">Expiring in days
                                    {
                                      this.state.expiringinfilter ?
                                        <span>({this.state.expiringinfilter})</span>
                                        :
                                        null
                                    }
                                  </label>


                                  <div className="mydropdown">
                                    <a href="javascript:" className="dropbtn" onClick={openFilter}>Any</a>
                                    <div className="filtermenu" id="expiringbx">
                                      <div className="formcol">
                                        <Input className="form-check-input" name="expiring" type="radio" id="radio1"
                                          value="expired"
                                          onClick={this.expiringFilterClick}
                                          checked={this.state.expiringinfilter === "expired" ? true : false}
                                        />
                                        <Label htmlFor="radio1">Already Expired</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="expiring" type="radio" id="radio2"
                                          value="24h"
                                          onClick={this.expiringFilterClick}
                                          checked={this.state.expiringinfilter === "24h" ? true : false}
                                        />
                                        <Label htmlFor="radio2">Next 24 hrs</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="expiring" type="radio" id="radio3"
                                          value="3d"
                                          onClick={this.expiringFilterClick}
                                          checked={this.state.expiringinfilter === "3d" ? true : false}
                                        />
                                        <Label htmlFor="radio3">Next 3 days</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="expiring" type="radio" id="radio4"
                                          value="7d"
                                          onClick={this.expiringFilterClick}
                                          checked={this.state.expiringinfilter === "7d" ? true : false}
                                        />
                                        <Label htmlFor="radio4">Next 7 days</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="expiring" type="radio" id="radio5"
                                          value="15d"
                                          onClick={this.expiringFilterClick}
                                          checked={this.state.expiringinfilter === "15d" ? true : false}
                                        />
                                        <Label htmlFor="radio5">Next 15 days</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="expiring" type="radio" id="radio6"
                                          value="30d"
                                          onClick={this.expiringFilterClick}
                                          checked={this.state.expiringinfilter === "30d" ? true : false}
                                        />
                                        <Label htmlFor="radio6">Next 30 days</Label>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </Col>

                              <Col>
                                <div className="filbx">
                                  <label htmlFor="keyword">Asset Type
                                    {
                                      this.state.assettypefilter.length > 0 ?
                                        <span>({this.state.assettypefilter.filter(v => v !== "all").length})</span>
                                        :
                                        null
                                    }
                                  </label>

                                  <div className="mydropdown">
                                    <a href="javascript:" className="dropbtn" onClick={openFilter}>Any</a>
                                    <div className="filtermenu" id="assetsbx">

                                      <div className="formcol">
                                        <Input className="form-check-input" name="assettypefiltercheck" type="checkbox"
                                          id="assetsshowall"
                                          value="all"
                                          onClick={this.assettypeFilterClick}
                                        />
                                        <Label htmlFor="assetsshowall">Show all</Label>
                                      </div>

                                      {
                                        this.state.assettypes && this.state.assettypes.map((dynamicData, key) =>
                                          <div className="formcol" key={key}>
                                            <Input className="form-check-input" name="assettypefiltercheck" type="checkbox"
                                              id={`ass${dynamicData.id}`}
                                              value={dynamicData.id}
                                              onClick={this.assettypeFilterClick}
                                              checked={this.state.assettypefilter.includes(dynamicData.id)}
                                            />
                                            <Label htmlFor={`ass${dynamicData.id}`}>{dynamicData.get('name')}</Label>
                                          </div>
                                        )
                                      }

                                    </div>
                                  </div>
                                </div>
                              </Col>

                              <Col>
                                <div className="filbx">
                                  <label htmlFor="keyword">Library
                                    {
                                      this.state.libraryfilter.length > 0 ?
                                        <span>({this.state.libraryfilter.filter(v => v !== "all").length})</span>
                                        :
                                        null
                                    }
                                  </label>

                                  <div className="mydropdown">
                                    <a href="javascript:" className="dropbtn" onClick={openFilter}>Any</a>
                                    <div className="filtermenu" id="librarybx">

                                      <div className="formcol">
                                        <Input className="form-check-input" name="libraryfiltercheck" type="checkbox"
                                          id="libshowall"
                                          value="all"
                                          onClick={this.libraryFilterClick}
                                        />
                                        <Label htmlFor="libshowall">Show all</Label>
                                      </div>

                                      {
                                        this.state.medialicenselibrary && this.state.medialicenselibrary.map((dynamicData, key) =>
                                          <div className="formcol" key={key}>
                                            <Input className="form-check-input" name="libraryfiltercheck" type="checkbox"
                                              id={`lib${dynamicData.value}`}
                                              value={dynamicData.value}
                                              onClick={this.libraryFilterClick}
                                              checked={this.state.libraryfilter.includes(dynamicData.value)}
                                            />
                                            <Label htmlFor={`lib${dynamicData.value}`}>{dynamicData.label}</Label>
                                          </div>
                                        )
                                      }

                                    </div>
                                  </div>
                                </div>
                              </Col>

                              <Col>
                                <div className="filbx">
                                  <label htmlFor="keyword">Agency
                                    {
                                      this.state.agencyfilter.length > 0 ?
                                        <span>({this.state.agencyfilter.filter(v => v !== "all").length})</span>
                                        :
                                        null
                                    }
                                  </label>

                                  <div className="mydropdown">
                                    <a href="javascript:" className="dropbtn" onClick={openFilter}>Any</a>
                                    <div className="filtermenu" id="agencygbx">

                                      <div className="formcol">
                                        <Input className="form-check-input" name="agencyfiltercheck" type="checkbox"
                                          id="agenshowall"
                                          value="all"
                                          onClick={this.agencyFilterClick}
                                        />
                                        <Label htmlFor="agenshowall">Show all</Label>
                                      </div>

                                      {
                                        this.state.agencylist && this.state.agencylist.map((dynamicData, key) =>
                                          <div className="formcol" key={key}>
                                            <Input className="form-check-input" name="agencyfiltercheck" type="checkbox"
                                              id={`agen${dynamicData.value}`}
                                              value={dynamicData.value}
                                              onClick={this.agencyFilterClick}
                                              checked={this.state.agencyfilter.includes(dynamicData.value)}
                                            />
                                            <Label htmlFor={`agen${dynamicData.value}`}>{dynamicData.label}</Label>
                                          </div>
                                        )
                                      }

                                    </div>
                                  </div>
                                </div>
                              </Col>

                              <Col>
                                <div className="filbx noborder">
                                  <label htmlFor="keyword">Added on
                                    {
                                      this.state.addedonfilter ?
                                        <span>({this.state.addedonfilter})</span>
                                        :
                                        null
                                    }
                                  </label>

                                  <div className="mydropdown">
                                    <a href="javascript:" className="dropbtn" onClick={openFilter}>Any</a>
                                    <div className="filtermenu right" id="addedonbx">
                                      <div className="formcol">
                                        <Input className="form-check-input" name="addedon" type="radio" id="addedon1"
                                          value="all"
                                          onClick={this.addedonFilterClick}
                                          checked={this.state.addedonfilter === "all" ? true : false}
                                        />
                                        <Label htmlFor="addedon1">Added anytime</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="addedon" type="radio" id="addedon2"
                                          value="24h"
                                          onClick={this.addedonFilterClick}
                                          checked={this.state.addedonfilter === "24h" ? true : false}
                                        />
                                        <Label htmlFor="addedon2">Last 24 hrs</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="addedon" type="radio" id="addedon3"
                                          value="3d"
                                          onClick={this.addedonFilterClick}
                                          checked={this.state.addedonfilter === "3d" ? true : false}
                                        />
                                        <Label htmlFor="addedon3">Last 3 days</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="addedon" type="radio" id="addedon4"
                                          value="7d"
                                          onClick={this.addedonFilterClick}
                                          checked={this.state.addedonfilter === "7d" ? true : false}
                                        />
                                        <Label htmlFor="addedon4">Last 7 days</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="addedon" type="radio" id="addedon5"
                                          value="15d"
                                          onClick={this.addedonFilterClick}
                                          checked={this.state.addedonfilter === "15d" ? true : false}
                                        />
                                        <Label htmlFor="addedon5">Last 15 days</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="addedon" type="radio" id="addedon6"
                                          value="30d"
                                          onClick={this.addedonFilterClick}
                                          checked={this.state.addedonfilter === "30d" ? true : false}
                                        />
                                        <Label htmlFor="addedon6">Last 30 days</Label>
                                      </div>
                                      <div className="formcol w100 mb-0 mt-3">
                                        <Input className="form-check-input" name="addedon" type="radio" id="addedon7"
                                          value="range"
                                          onClick={this.addedonFilterClick}
                                          checked={this.state.addedonfilter === "range" ? true : false}
                                        />
                                        <Label htmlFor="addedon7">
                                          <Row className="lowgap">
                                            <Col className="col-md-6">
                                              <InputGroup>
                                                {/* <InputGroupAddon addonType="prepend"> */}
                                                  <InputGroupText  className='prepend'><i className="fas fa-calendar-alt"></i></InputGroupText>
                                                {/* </InputGroupAddon> */}

                                                <DatePicker
                                                  onClickOutside={this.datePickerValue}
                                                  maxDate={new Date()}
                                                  dateFormat="dd-MM-yyyy"
                                                  yearDropdownItemNumber={100}
                                                  scrollableYearDropdown={true}
                                                  showYearDropdown
                                                  showMonthDropdown
                                                  placeholderText="Start Date"
                                                  // value={this.state.addedonfilterstart}
                                                  onChange={(selectedOption) => {
                                                    this.setState({ addedonfilterstart: selectedOption });
                                                  }}
                                                  selected={this.state.addedonfilterstart}
                                                />

                                                {/* <Datetime
                                                  locale="en-gb"
                                                  timeFormat={false}
                                                  dateFormat="DD/MM/YYYY"
                                                  inputProps={{ placeholder: "Start Date" }}
                                                  onChange={(selectedOption) => {
                                                    this.setState({ addedonfilterstart: selectedOption.toDate() });
                                                  }}
                                                  value={this.state.addedonfilterstart}
                                                /> */}

                                              </InputGroup>
                                            </Col>
                                            <Col className="col-md-6">
                                              <InputGroup>
                                                {/* <InputGroupAddon addonType="prepend"> */}
                                                  <InputGroupText className='prepend'><i className="fas fa-calendar-alt"></i></InputGroupText>
                                                {/* </InputGroupAddon> */}

                                                <DatePicker
                                                  onClickOutside={this.datePickerValue}
                                                  maxDate={new Date()}
                                                  dateFormat="dd-MM-yyyy"
                                                  yearDropdownItemNumber={100}
                                                  scrollableYearDropdown={true}
                                                  showYearDropdown
                                                  showMonthDropdown
                                                  placeholderText="End Date"
                                                  onChange={(selectedOption) => {
                                                    this.setState({ addedonfilterend: selectedOption });
                                                  }}
                                                  selected={this.state.addedonfilterend}
                                                />

                                                {/* <Datetime
                                                  locale="en-gb"
                                                  timeFormat={false}
                                                  dateFormat="DD/MM/YYYY"
                                                  inputProps={{ placeholder: "End Date" }}
                                                  onChange={(selectedOption) => {
                                                    this.setState({ addedonfilterend: selectedOption.toDate() });
                                                  }}
                                                  value={this.state.addedonfilterend}
                                                /> */}

                                              </InputGroup>
                                            </Col>
                                          </Row>
                                        </Label>
                                      </div>



                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col className="col-auto">
                          <div className="filbx noborder">
                            <Button className="btn btn-default greensearch"
                              onClick={
                                async (event) => {
                                  this.genericFilter();
                                }
                              }
                            >Search</Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </div>

            </Col>








          </Row>

        </div>
        {/* Header Div end */}



        <Row className="justify-content-between">

          <Col className="col-auto">
            <div className="fs18">
              <Form>
                <FormGroup inline className="form-group checkwithclass whitetext">
                  <div className="form-check form-check-inline">
                    <Input className="form-check-input" name="selectall" type="checkbox" id="inlineCheckbox5" value="all"
                      onChange={this.selectAll}
                    />
                    <Label htmlFor="inlineCheckbox5">Select all</Label>
                  </div>
                </FormGroup>
              </Form>
            </div>
          </Col>

          <Col className="col-auto">
            <div className="btngreen">{this.state.selectedcount} selected</div>
          </Col>

          <Col className="ml-2">
            {
              this.state.assetwaitingcount ?
                <div className="btndark">{this.state.assetwaitingcount} assets waiting to be approved</div>
                :
                null
            }

          </Col>
        </Row>



        {
          this.state.loader === true ?
            <div className="loaderwrap"><div className="loader"></div></div>
            :
            null
        }




        {
          this.state.itemsAdded ?
            <div className="mesgbox text-success">{this.state.itemsAdded} records were added</div>
            :
            null
        }
        {
          this.state.itemsDeleted ?
            <div className="mesgbox text-success">{this.state.itemsDeleted} records were deleted</div>
            :
            null
        }
        <div className="assetslisting bulklisting">
          <Row>


            {
              this.state.allImportData && this.state.allImportData.length > 0 ?

                this.state.allImportData.map((dynamicData, key) => {
                  return (
                    <Col className="col-md-3" key={key}>
                      <div className="assetscard active">
                        <Row className="justify-content-between">
                          <Col>
                            <div className="fs18">
                              <Form>
                                <div className="checkwithclass">
                                  <div className="form-check form-check-inline">
                                    <Input name="bulkcheck" type="checkbox" id={`bulk${dynamicData.id}`}
                                      value={dynamicData.id}
                                      onChange={this.bulkcheck}
                                      className="form-check-input"
                                    />
                                    <Label htmlFor={`bulk${dynamicData.id}`}>ID# {dynamicData.get('assetid') ? dynamicData.get('assetid') : ""}</Label>
                                  </div>
                                </div>
                              </Form>
                            </div>
                          </Col>
                          <Col className="col-auto pl-0">
                            <a href={dynamicData.get('asseturl') ? dynamicData.get('asseturl') : "javascript:"} className="externallink" target="_blank"><i className="fas fa-external-link-alt"></i></a>
                          </Col>
                        </Row>
                        <div className="imgbx" onClick={this.viewpopuptoggle}>
                          <Assetimageagency
                            objid={dynamicData.id}
                            imageurl={dynamicData.get('assetimage') ? dynamicData.get('assetimage')[0] : ""}
                            action={this.state.actiontext}
                            updateIDforImage={this.state.updateIDforImage}
                          />
                        </div>
                        <Row>
                          <Col className="col-12">
                            <ScrollArea smoothScrolling={0.8} className="thinsscroll mb-3" style={{ maxHeight: '80px', minHeight: '80px' }}>
                              <p className="shortdetail">{dynamicData.get('description')}</p>
                            </ScrollArea>
                          </Col>
                        </Row>
                        <div className="row mt-3">
                          <div className="col">
                            <div className="librarydetail">
                                {
                                  dynamicData.get('assettypetext') === "Image" && iconCamera ?
                                    <img src={iconCamera} alt="img" />
                                    :
                                    dynamicData.get('assettypetext') === "Video" && iconMedia ?
                                      <img src={iconMedia} alt="img" />
                                      :
                                      dynamicData.get('assettypetext') === "Audio" && iconAudio ?
                                        <img src={iconAudio} alt="img" />
                                        :
                                        dynamicData.get('assettypetext') === "Vector" && iconPencil ?
                                          <img src={iconPencil} alt="img" />
                                          :
                                          null
                                }
                                {dynamicData.get('medialicenselibraryid') ? dynamicData.get('medialicenselibraryid').get('name') : ""}

                                {
                                    dynamicData.get('medialicenselibraryid') && dynamicData.get('medialicenselibraryid').get('name') === "Other" ?
                                      <span>{" (" + dynamicData.get('otherlibraryname') + ")"}</span>
                                      :
                                      ""
                                  }
                            </div>
                          </div>
                          <div className="col-auto col">
                            <a href="javascript:" className="externallink" onClick={this.deleteRecord} >
                              <i className="fas fa-trash-alt" data-objectid={dynamicData.id}></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </Col>
                  )
                })
                :
                <p className="whitetext">No record found</p>
            }



          </Row>
        </div>



        <Viewagencyassets
          isOpen={this.state.viewpopup}
          toggle={this.viewpopuptoggle}
          viewObjectID={this.state.editObjectID}
          viewListFull={this.state.allImportData ? this.state.allImportData : null}
        />


        <Modal isOpen={this.state.deletepopup} toggle={this.deletetoggle} className={this.props.className}>
          <ModalHeader toggle={this.deletetoggle}>Confirm</ModalHeader>
          <ModalBody>
            Are you sure want to delete the records?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.archiveRecords}>Yes</Button>
            <Button color="secondary" onClick={this.deletetoggle}>Cancel</Button>
          </ModalFooter>
        </Modal>



      </div>
    )
  }
}

export default assetAgency;
