import React from 'react';
import { Row, Col } from 'reactstrap';
import { Model } from '../../components/dashboard-components';

class Footer extends React.Component {

  
  render() {

    var cookiename = 'cookieuser';
    var value = "; " + document.cookie;
    var parts = value.split("; " + cookiename + "=");
    if (parts.length == 2) {
      var part2 = parts.pop().split(";").shift();
    } else {
      var part2 = "";
    }



    return (
      <footer className="footer">

        {
          part2 !== 'yes' ?
            <Model />
            :
            ""
        }


        <Row className="justify-content-between align-items-center">
          <Col>
            <p>© 2020 Abdul Latif Jameel IPR Company Limited. Permission to use this site is granted strictly subject to the Terms of Use.
        <span className="d-block">The Abdul Latif Jameel name and the Abdul Latif Jameel logotype and pentagon-shaped graphics are trademarks or registered trademarks of Abdul Latif Jameel IPR Company Limited.</span></p>
          </Col>
          <Col>
            <ul>
              <li><a href="https://www.alj.com/en/terms-of-use/" target="_blank">Terms of Use</a></li>
              <li><a href="https://www.alj.com/en/copyright-notice-and-disclaimer/" target="_blank">Copyright Notice &amp; Disclaimer</a></li>
              <li><a href="https://www.alj.com/en/privacy-policy/" target="_blank">Privacy Policy</a></li>
              <li><a href="https://www.alj.com/en/cookie-policy/" target="_blank">Cookie Policy</a></li>
              <li><a href="https://www.alj.com/en/contact/" target="_blank">Contact Us</a></li>
            </ul>
          </Col>
          <Col md="auto">
            <ul className="social">
              <li><a href="https://www.linkedin.com/company/alj/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
              <li><a href="https://www.facebook.com/Abdul.Latif.Jameel.Official" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
              <li><a href="https://www.instagram.com/abdul_latif_jameel_official/" target="_blank"><i className="fab fa-instagram"></i></a></li>
              {/* <li><a href="https://twitter.com/aljcorporate" target="_blank"><i className="fab fa-twitter"></i></a></li>
              <li><a href="https://plus.google.com/+AbdulLatifJameel" target="_blank"><i className="fab fa-google-plus-g"></i></a></li> */}
              <li><a href="https://www.youtube.com/channel/UCM4oe4BmtP4YdKhJe0BGTbw" target="_blank"><i className="fab fa-youtube"></i></a></li>
            </ul>
          </Col>
        </Row>
      </footer>
    );
  }
}
export default Footer;
