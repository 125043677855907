import React from 'react';
import {Card,CardBody,CardTitle,Row,Col,Form,FormGroup,Label,Input,Button,InputGroup,UncontrolledTooltip,Alert} from 'reactstrap'; 
import Loader from '../../components/common/Loader';   
import {uploadFile,getFileUrl} from '../../services/awsFileUpload';
import fileIcon from '../../assets/images/icons/file-icon.png';
import imageIcon from '../../assets/images/icons/image-icon.png';
import {LOGO_UPLOAD_SIZE} from '../../config/constant';
import jQuery from 'jquery';
import ScrollArea from 'react-scrollbar';

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
  
    this.state = {
      
      mainData:{
          logoFileArray:[],
          idCount:0,
      }      
    };
      
    }
    componentDidMount=async=>{
        this.setState({idCount:this.props.items.length});
    }
   

  onLogoFileSelected = async(event)=>{
    console.log(event.target.files);
    var files = event.target.files
    var itemCounts = this.props.items.length;
    console.log('itemCounts',itemCounts);
    var itemsToAdd = [];
    if(files.length>0)
    {
        for(let i=0;i<files.length;i++)
        {
            if(files[i].size<LOGO_UPLOAD_SIZE)
            {            
                var fileType = files[i].type.split("/")[1];
                var fileName = files[i].name;
                var fileSize = (files[i].size/1000000).toFixed(2);   
                          
                var obj =    {awsFileName:null,type:fileType,name:fileName,id:itemCounts,size:fileSize}
                itemsToAdd.push(obj);    
                // this.setState({idCount:this.state.idCount+1},()=>{
                    this.uploadFileToAWS(files[i],obj);
               // }) 
               // var uploadStatus = await uploadFile('trademarks',files[i]);
               // console.log(uploadStatus);
                //if(uploadStatus.success)
               // {
                  //  this.props.updateItems( {awsFileName:uploadStatus.filename,type:fileType,name:fileName});                   
                //}
                itemCounts++;
            }
            else
            {
                this.props.showError('File '+files[i].name+' exceeds maximum file size of 30MB' )
            }
        }
        if(itemsToAdd.length>0)
        {
            this.props.addItems(itemsToAdd);
        }
    }
    jQuery('#'+this.props.name).val('')
      //var logoUpload = await uploadFile('trademark',event.target.value);
  }

  uploadFileToAWS=async(fileObj,item)=>{
      var uploadStatus = await uploadFile(this.props.folderName,fileObj);
               console.log('uploadFileToAWS',uploadStatus);
                if(uploadStatus.success)
               {
                   this.props.updateItem(item.id, uploadStatus.filename,uploadStatus.url);                   
                }
  }


    render() {
        console.log('render')
     this.props.isValid?jQuery("#"+this.props.name).removeClass('is-invalid'):jQuery("#"+this.props.name).addClass('is-invalid')
    // this.props.isValid?alert('is-valid'):alert('is-invalid')
      return <div>
                <form>
                        <FormGroup className="form-group">
                          <Label htmlFor="exampleEmail12">{this.props.labelText} {this.props.isMandatory?'*':null} 
                          <i className="far fa-question-circle ml-1" href="javascript:" id="graphic"></i></Label>
                      <UncontrolledTooltip placement="right" target="graphic">
                        {this.props.tooltipText}
                      </UncontrolledTooltip>
                      <InputGroup>
                            <div className="custom-file">
                                <Input disabled={this.props.isDisabled} type="file" className="custom-file-input" id={this.props.name}  onChange={(event)=>{this.onLogoFileSelected(event)}} multiple/>
                                <label className="custom-file-label" htmlFor={this.props.name}>{this.props.inputText}</label>
                            </div>                            
                          </InputGroup>
                          </FormGroup>
                          <div className="galleryWrap">
                          <ScrollArea smoothScrolling={0.8} style={{maxHeight:'210px'}}>
                            {this.props.items.length>0?
                                this.props.items.map((item,index)=>{
                                    return(                                   
                                        <div className="galleryitem"  key={item.id}>

                                        
                                            
                                            <div className="btnoverlay">
                                               <button type="button" className="deletebutton" 
                                                     onClick={()=>{ this.props.onDelete(item.id);}}>
                                                <i className="fas fa-window-close"></i>
                                                </button>
                                                {/* <a href="javascript:" className="viewme"><i className="far fa-edit"></i></a>  */}
                                            </div>  

                                            {item.awsFileName?
                                            
                                            
                                            <div className="imgbx">               
                                                
                                                <a target="_blank" onClick={async()=>{
                                                     var res = await getFileUrl(this.props.folderName,item.awsFileName);
                                                     if(res && res.success==1)
                                                     {
                                                         window.open(res.url);
                                                     }
                                                     console.log(res);
                                                    // if(this.props.folderName=="trademarks")
                                                    // {
                                                    //     window.open(item.url);
                                                    // }
                                                    // else
                                                    // {
                                                    //     var res = await getFileUrl(this.props.folderName,item.awsFileName);
                                                    //     if(res && res.success==1)
                                                    //     {
                                                    //         window.open(res.url);
                                                    //     }
                                                    //     console.log(res);
                                                    // }
                                            }}>
                                                    <img src={(item.type === "jpg" || item.type === "png" || item.type === "jpeg") ?imageIcon:fileIcon} alt="Gallery Image" width="50" />
                                                </a>
                                                </div>:
                                                <div className="imgbx" > 
                                             <Loader type="Oval" color="#fff" height={20} width={20} />
                                             </div>}
                                            <p>{item.name}</p>
                                           {item.size?<p><strong>File size:</strong> {item.size} MB</p>:null}
                                      </div>     
                                )}
                                )
                                :null}
                         </ScrollArea>
                        </div>
                        </form>
                        </div>
    }
}

export default FileUpload;
