import React, { Component } from 'react';
import {Card,CardBody,CardTitle,Row,Col,Form,FormGroup,Label,Input,FormText,Button,InputGroup,UncontrolledTooltip,Alert} from 'reactstrap';  
import Select, { components } from "react-select";
import defaultuser from '../../assets/images/icons/default-user.png';
import {currentUser,getStatusNameFromValue} from '../../services/trademark';
import {getTrademarkProgressById,convertStateObjToParseObj,convertParseObjTOStateObj,saveTrademarkProgress,
    getTrademarkProgressVersions,getTrademarkProgressVersionsObjectById,updateTrademark} from '../../services/trademarkProgress';
import {uploadFile,getFileUrl} from '../../services/awsFileUpload';

import jQuery from 'jquery';
import fileIcon from '../../assets/images/icons/file-icon.png';
import imageIcon from '../../assets/images/icons/image-icon.png';
import Loader from '../../components/common/Loader';  
import {getTrademarkChatsById,postTrademarkChat,getUserFullName} from '../../services/trademarkChats';
import Moment from 'moment';
import FileUpload from '../../components/common/FileUpload';
import DatePicker from "react-datepicker";
import {sendEmail,sendProcessStepCompletionEmail} from '../../services/email';
import {addLog} from '../../services/trademark';
import { isAdmin } from '../../services/user';

require('react-datepicker/dist/react-datepicker.css');
const CustomClearText = () => 'clear all';
const ClearIndicator = (props) => {
  const { children = <CustomClearText />, getStyles, innerProps: { ref, ...restInnerProps } } = props;
  return (
    <div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props)}>
      <div style={{ padding: '0px 5px' }}>
        {children}
      </div>
    </div>
  );
};
const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: 'pointer',
  color: state.isFocused ? 'blue' : 'black',
});


 class progressStatusForm extends Component {
    
	constructor(props) {
		super(props);

		this.state = {
            currentUserDetails: "",
            user:null,
            mainLoading:false,  
            username:'',       
            tdClassName:null,
            tdIcon:'icon-pencil',
            ndClassName:null,
            ndIcon:'icon-pencil',
          versionCount:[],
          showSaveButton:true,
          showAlertMessage : false,
      alertMessage:'',
      showVersionText:false,
      currentVersion:'',
          mainId:'',
          message:'',
          chats:[],
          showChatLoader:false,
          fileUploadMandatory:false,
          fileUploadIsValid:true,
          editmode:false,
          mainData:{
              id:'',
              processStatus:'',
              trademarkStatus:this.props.progressId,
              user:'',
              trademark:this.props.trademarkId,
              notes:'',
              assetsFileArray:[],
              versionCount:0,
              applicationNumber:'',
              applicationDate:null,
              registrationNumber:'',
              registrationDate:null,
              nextRenewalDate:null,
              usedDate:null,
              statusNotes:''
          }
          
        };

	}
    
    componentWillMount= async()=>{
        var isadminval = await isAdmin();
      if (isadminval) {
        var currentrole = isadminval[0].get('role').get('name');
        console.log(currentrole);
        this.setState({ currentRole: currentrole });
        if ( currentrole === "Finance") {
          this.setState({ editmode: false});
        }
        else
        {
          this.setState({ editmode: true});
        }
      }
        jQuery(".opendefault").next('.card-body').show();
        jQuery(".opendefault").addClass('active');
        var cuser = await currentUser(); 
        if (cuser) {  
            this.setState({currentUserDetails:cuser[0]});  
        }

        //  //console.log('param id',this.props.id);
        if(this.props.trademarkId && this.props.progressId)
        {
            await this.loadTrademarkPrgressData(this.props.trademarkId,this.props.progressId,null);
           var chats =  await getTrademarkChatsById(this.props.trademarkId,this.props.progressId);
           //console.log("chats",chats);
           var username = await getUserFullName(null);
           //console.log("username",username);
           
    
          // this.setState({chats:chats,username:username,fileUploadMandatory:(this.state.mainData.processStatus=="completed" && this.props.progressId=='wLJe98HUcP')});
           this.setState({chats:chats,username:username,fileUploadMandatory:false});

        }
        
      }
  
      loadTrademarkPrgressData = async(trademarkId,progressId,version)=>{
          var obj={};
          if(trademarkId && progressId && version)
          {
              obj = await getTrademarkProgressVersionsObjectById(this.state.mainId,version);
              if(obj)
                {
                    var convertedObj = await convertParseObjTOStateObj(obj);
                    this.setState({mainData:{...this.state.mainData,...convertedObj}},async()=>{
                        ////console.log('componentWillMount',this.state.mainData);
                        var versionOptions = await getTrademarkProgressVersions(this.state.mainId);
                        //console.log('loadTrademarkPrgressData',versionOptions);
                        this.setState({versionCount:versionOptions})
                    });
                }
          }
          else
          {
              obj = await getTrademarkProgressById(trademarkId,progressId);
              if(obj)
                {
                    var convertedObj = await convertParseObjTOStateObj(obj);
                    this.setState({mainData:{...this.state.mainData,...convertedObj},mainId:obj.id},async()=>{
                        ////console.log('componentWillMount',this.state.mainData);
                        var versionOptions = await getTrademarkProgressVersions(this.state.mainId);
                        //console.log('loadTrademarkPrgressData',versionOptions);
                        this.setState({versionCount:versionOptions})
                    });
                }
                else
                {
                    //code
                }
              
          }
        //   if(obj)
        //         {
        //             var convertedObj = await convertParseObjTOStateObj(obj);
        //             this.setState({mainData:{...this.state.mainData,...convertedObj},mainId:obj.id},async()=>{
        //                 ////console.log('componentWillMount',this.state.mainData);
        //                 var versionOptions = await getTrademarkProgressVersions(this.state.mainId);
        //                 //console.log('loadTrademarkPrgressData',versionOptions);
        //                 this.setState({versionCount:versionOptions})
        //             });
        //         }
          
      }
		
		async componentDidMount(evt) {
			jQuery(".opendefault").next('.card-body').show();
			jQuery(".opendefault").addClass('active');
		}
	
		slidingClick = (event) => {
            jQuery(event.target).parent(".card").find('.cardBodyNew').slideToggle('slow');
            jQuery(event.target).parent().parent(".card").find('.cardBodyNew').slideToggle('slow');
            jQuery(event.target).toggleClass('active');
          }

        onAssetFilesSelected = async(event)=>{
            //console.log(event.target.files);
            var files = event.target.files
            if(files.length>0)
            {
                for(let i=0;i<files.length;i++)
                {
                    var fileType = files[i].type.split("/")[1];
                    var fileName = files[i].name;
                    //console.log(fileType);
                    var uploadStatus = await uploadFile('trademarks',files[i]);
                    //console.log(uploadStatus);
                    if(uploadStatus.success)
                    {
                        this.setState({
                            mainData:{
                                ...this.state.mainData,
                                assetsFileArray:[
                                    ...this.state.mainData.assetsFileArray,
                                    {awsFileName:uploadStatus.filename,type:fileType,name:fileName}
                                ]
                            }
                        });
                    }
        
                    
                }
            }
          }

//validate trademark detail section
validateTDSection = async()=>{
    this.setState({fileUploadIsValid:true})
    var valid = true;
    var fileValid = true;
    var mainData = this.state.mainData;
    jQuery("#mainForm input").removeClass('is-invalid');
    jQuery(".reactSelect").removeClass('is-invalid');
    
    jQuery("#textNotes").removeClass('is-invalid');
    jQuery(".reactSelect").removeClass('is-invalid');

    // if(mainData.notes=='')
    // {
    //     valid = false;
    //     jQuery('#textNotes').addClass('is-invalid');
    // }
    // if(mainData.assetsFileArray.length==0)
    // {
    //     valid = false;
    //     jQuery("#inputGroupFile02").addClass('is-invalid');
    // }
    if(mainData.processStatus=='')
    {
        valid = false;
        jQuery('#ddlStatus').addClass('is-invalid');
    }
    if(this.props.progressId=='LqCkfGDOZ7' && mainData.processStatus=='completed')
    {
        if(mainData.applicationNumber=='')
        {
            valid = false;
            jQuery('#applicationNumber').addClass('is-invalid');
        }
        if(mainData.applicationDate==null)
        {
            valid = false;
            jQuery('#applicationDate').addClass('is-invalid');
        }
    }
    if(this.props.progressId=='Vk3AZ1bwHv' && mainData.processStatus=='completed')
    {
        if(mainData.registrationDate==null)
        {
            valid = false;
            jQuery('#registrationDate').addClass('is-invalid');
        }
        if(mainData.nextRenewalDate==null)
        {
            valid = false;
            jQuery('#nextRenewalDate').addClass('is-invalid');
        }
        if(mainData.registrationNumber=='')
        {
            valid = false;
            jQuery('#registrationNumber').addClass('is-invalid');
        }
    }
    // if(this.state.mainData.processStatus=="completed" && this.props.progressId=='wLJe98HUcP')
    // {
    //     if(this.state.mainData.assetsFileArray.length==0)
    //     {
    //         valid = false;
    //         fileValid = false;
    //         console.log('completed');
    //     }
    // }

    if(valid)
    {
        this.setState({tdClassName:"completed",tdIcon:"fas fa-check",tdLoading:false})
    }
    else
    {
        this.setState({tdClassName:"failed",tdIcon:"fas fa-exclamation",tdLoading:false,fileUploadIsValid:fileValid})
    }
    return valid;
}

submitData = async() => { 
    this.setState({mainLoading:true,showAlertMessage:false})
    var validTD = await this.validateTDSection();
    if(validTD)
    {
        //alert('valid');
        var tempObj = await convertStateObjToParseObj(this.state.mainData);
        //console.log(tempObj);
        if(tempObj)
        {
            var subObj = await saveTrademarkProgress(tempObj);
            var alertMessage = (subObj.attributes.versionCount==1)?'Record has been successfully saved.':'Record has been successfully updated.';
            var logAction = subObj.attributes.versionCount==1?"created":"updated";
             addLog(this.props.trademarkId,this.props.title+" - "+subObj.attributes.processStatus+" - "+logAction);
             updateTrademark(this.props.trademarkId,this.state.mainData.registrationDate,this.state.mainData.nextRenewalDate,this.props.progressId,
                            this.state.mainData.processStatus,this.state.mainData.applicationNumber,this.state.mainData.registrationNumber,
                            this.state.mainData.usedDate,this.state.mainData.applicationDate);
            // if(statemenet=='completed')
            // {
            //     sendEmail(lawFirm.email,"Subject Here",'NewTrademarkLawFirmEmailNotification',{value1:'a',value2:'b',value3:'c'})
            // }
            this.setState({mainLoading:false,mainId:subObj.id,showAlertMessage:true,alertMessage:alertMessage},async()=>{
                var statusName = await getStatusNameFromValue(subObj.attributes.processStatus);
                var applNumber = 'N/A';
                var countryJurd = '';
                if(this.props.trademark.attributes.applicationNumber && this.props.trademark.attributes.applicationNumber !='' )
                {
                  applNumber = this.props.trademark.attributes.applicationNumber;
                } 
                if(this.state.mainData.applicationNumber && this.state.mainData.applicationNumber !='' )
                {
                  applNumber = this.state.mainData.applicationNumber;
                } 
                if(this.props.trademark.attributes.countries && this.props.trademark.attributes.countries.length>0)
                {
                  var tempArr = this.props.trademark.attributes.countries.map(x=>x.attributes.name);
                  countryJurd = tempArr.join(", ");
                }
                if(this.props.trademark.attributes.jurisdictions && this.props.trademark.attributes.jurisdictions.length>0)
                {
                  var tempArr = this.props.trademark.attributes.jurisdictions.map(x=>x.attributes.name);
                  countryJurd = countryJurd + tempArr.join(", ");
                }
               // console.log(countryJurd);
               // console.log(applNumber);
                if(subObj.attributes.processStatus!="completed" && subObj.attributes.processStatus!="notStarted" && subObj.attributes.versionCount==1){
                    // sendProcessStepCompletionEmail(this.props.trademarkId,this.props.trademark.attributes.name,subObj.attributes.processStatus=="requestRecieved"?"Request Received":"In Progress",
                    //                             this.props.trademark.attributes.lawFirm.id=='hTVNSpg7ZR'?this.props.trademark.attributes.AGIPRefNumber:this.props.trademark.attributes.twRefNumber,
                    //                             this.props.title)
                    sendProcessStepCompletionEmail(this.props.trademarkId,this.props.trademark.attributes.name,statusName,applNumber,
                                              //  this.props.trademark.attributes.lawFirm.id=='hTVNSpg7ZR'?this.props.trademark.attributes.AGIPRefNumber:this.props.trademark.attributes.twRefNumber,
                                                this.props.title,countryJurd.trim())
                }
                
                
                if(subObj.attributes.processStatus=="completed"){
                    sendProcessStepCompletionEmail(this.props.trademarkId,this.props.trademark.attributes.name,"Completed",applNumber,
                                                //this.props.trademark.attributes.lawFirm.id=='hTVNSpg7ZR'?this.props.trademark.attributes.AGIPRefNumber:this.props.trademark.attributes.twRefNumber,
                                                this.props.title,countryJurd.trim())

                                 // console.log('mail : ',sendMail);
                }

            await this.loadTrademarkPrgressData(this.props.trademarkId,this.props.progressId,null);
            var versionOptions = await getTrademarkProgressVersions(this.state.mainId);
            this.setState({versionCount:versionOptions})
                // if(this.state.mainData.id=='')
                // {
                //     var convertedObj = await convertParseObjTOStateObj(subObj);
                    
                //     this.setState({mainData:{...this.state.mainData,...convertedObj}},async()=>{                        
                //         var versionOptions = await getTrademarkProgressVersions(this.state.mainId);
                //         this.setState({versionCount:versionOptions})
                //     })
                // }
                // else
                // {
                //     var versionOptions = await getTrademarkProgressVersions(this.state.mainId);
                //         this.setState({versionCount:versionOptions})
                // }
                
                
                this.props.updateStatus();
            })
            
        }
    }   
    else
    {
        this.setState({showErrorAlert:true,mainLoading:false});
    }
    
}



addData = () => {
    alert("ADF");
}


postChat = async()=>{
    this.setState({showChatLoader:true},async()=>{
        var valid = true;
        var mainData = this.state.mainData;
        jQuery("#chatForm input").removeClass('is-invalid');
        jQuery(".reactSelect").removeClass('is-invalid');
        if(this.state.message=='')
        {
            valid = false;
            jQuery('#msgnote').addClass('is-invalid');
            this.setState({ndClassName:"failed",ndIcon:"fas fa-exclamation",ndLoading:false,showChatLoader:false})
        }
        else
        {
            
            this.setState({ndClassName:"completed",ndIcon:"fas fa-check",ndLoading:false},async()=>{
                await postTrademarkChat(this.state.message,this.props.trademarkId,this.props.progressId);
                var chats =  await getTrademarkChatsById(this.props.trademarkId,this.props.progressId);            
                this.setState({chats:chats,message:'',showChatLoader:false}); 
            });
        }
    })
}

	render() {
		return (            
			<div className="step step1 mt-5">
            <Row className="justify-content-between topbuttonbar">
            <Col className="col-md-7">
            {this.state.showAlertMessage?
            <Alert color="success" className="d-block">
            {this.state.alertMessage}
            <span onClick={()=>{this.setState({showAlertMessage:false})}} className="float-right">X</span>
            </Alert>:null}
            </Col>
            <Col className="col-auto mb-4">
            <a href="javascript:" className="btn btn-primary ml-3" id="savebtn" onClick={()=>{this.addData()}}> <span>Add</span></a> 
            {
            this.state.editmode && this.state.showSaveButton?
            this.state.mainLoading? <div className="btn btn-primary ml-3">
            <Loader type="Oval" color="#414241" height={20} width={20} />
            </div>
            :<a href="javascript:" className="btn btn-primary ml-3" id="savebtn" onClick={()=>{this.submitData()}}> <span>Save</span></a> 
            :null
            }


            {(this.state.versionCount.length>0)?
               <select className="custom-select w-auto ml-3" id="ddlVersionCount" name="ddlVersionCount" onChange={async(event)=>{
                   var val = event.target.value;
                   if(event.target.value=='latestVersion')
                   {
                      await this.loadTrademarkPrgressData(this.props.trademarkId,this.props.progressId,null) 
                      this.setState({showSaveButton:true,showVersionText:true,currentVersion:val})                 
                   }
                   else
                   {
                    await this.loadTrademarkPrgressData(this.props.trademarkId,this.props.progressId,event.target.value); 
                    this.setState({showSaveButton:false,showVersionText:true,currentVersion:val})   
                    }
               }}>
               <option value="latestVersion">Latest Version</option>
               {this.state.versionCount.map((item,index)=>{return(
                   <option key={item} value={item}>{item}</option>
               )}
               )}
             </select>
                :null}
                
               {(this.state.showVersionText)?
                   <p className="requiredtext text-right">
                   {this.state.currentVersion=="latestVersion"?"Currently viewing latest version":"Currently viewing version "+this.state.currentVersion}
                   </p>
               :null}
            {/* <a href="/#/trademark/list" className="btn btn-primary btncancle mr-3"> <span className="border-left pl-2">Cancel</span></a> */}
            
            </Col>
            </Row>
		
   

			<Card className="customAcc">
                    <CardTitle onClick={this.slidingClick.bind(this)} className={"opendefault "+this.state.tdClassName}>
                   {this.state.tdIcon?<i className={"icon-circle "+this.state.tdIcon}></i>:null} 
                      Trademark {this.props.title} 1
                    </CardTitle>
                    <CardBody className="cardBodyNew">
                    <Row>
                    <Col md="7">
                      <Form id="mainForm">
                        <FormGroup className="form-group">
                          <Label htmlFor="trademarkname">Notes <i className="far fa-question-circle ml-1" href="javascript:" id="UncontrolledTooltipExample"></i></Label>
                      <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample">
                        Hello world!
                      </UncontrolledTooltip>
                            <Input type="textarea" rows="5" id="textNotes" name="textNotes" placeholder="Add your notes here..." value={this.state.mainData.notes} onChange={(event)=>{                                
                               this.setState({mainData:{...this.state.mainData,notes:event.target.value}})  
                            }}/>
                        </FormGroup>


                        <FileUpload labelText="Select Files" isMandatory={this.state.fileUploadMandatory} tooltipText="Hello world!"
                                    isValid={this.state.fileUploadIsValid} inputText="Upload your file here" items={this.state.mainData.assetsFileArray}
                                    name="inputGroupFile02"
                                    folderName="trademarks"
                                    onDelete={(id)=>{
                                        var logoArray=this.state.mainData.assetsFileArray.filter(obj=>obj.id!=id).map((item,index)=>{return {...item,id:index}});  
                                        this.setState({mainData:{...this.state.mainData,assetsFileArray:logoArray  }});}}
                                    addItems={(items)=>{
                                        //console.log('addItem',items);
                                        this.setState({                                        
                                        mainData:{ ...this.state.mainData,assetsFileArray:[...this.state.mainData.assetsFileArray,...items]}
                                    },()=>{
                                        //console.log(this.state.mainData.assetsFileArray);
                                    });}}
                                    updateItem={(id,filename,url)=>{
                                        var objToChange = this.state.mainData.assetsFileArray.find(x=>x.id==id);
                                        if(objToChange)
                                        {
                                            this.setState({                                        
                                                mainData:{ ...this.state.mainData,assetsFileArray:[...this.state.mainData.assetsFileArray.filter(x=>x.id!=id),
                                                    {...this.state.mainData.assetsFileArray.find(x=>x.id==id),awsFileName:filename,url:url}
                                                    ]}
                                            },()=>{//console.log(this.state.mainData.assetsFileArray);
                                            });
                                        }   
                                    }}     
                                    showError={(message)=>{
                                        this.setState({showAlertMessage:true,alertMessage:message})
                                    }}
                                    />

                   

                        <FormGroup className="form-group">
                          <Label htmlFor="exampleEmail12">Status<i className="far fa-question-circle ml-1" href="javascript:" id="UncontrolledTooltipExample4"></i></Label>
                      <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample4">
                        Hello world!
                      </UncontrolledTooltip>
                          <Input type="select" id="ddlStatus" name="ddlStatus" className="custom-select reactSelect" value={this.state.mainData.processStatus} onChange={(event)=>{
                              if(event.target.value!='')
                              {
                                //console.log(event.target.value);
                               this.setState({mainData:{...this.state.mainData,processStatus:event.target.value}},()=>{
                                  //  if(this.state.mainData.processStatus=="completed" && this.props.progressId=='wLJe98HUcP')
                                  //  {
                                       
                                  //       this.setState({fileUploadMandatory:true})
                                  //  }
                                  //  else
                                  //  {
                                  //       this.setState({fileUploadMandatory:false})
                                  //  }
                               })   
                              }
                          }}>
                              <option value="">Select</option>
                              <option value="abandoned">Abandoned</option>
                              <option value="awaitingCertificateOfRegistration">Awaiting Certificate Of Registration</option>
                              <option value="completed">Completed</option>
                              <option value="conditionalAcceptance">Conditional Acceptance</option>
                              <option value="inProgress">In Progress</option>
                              <option value="lapsed">Lapsed</option>
                              <option value="notStarted">Not Started</option>
                              <option value="petitionUnderExamination">Petition Under Examination</option>
                              <option value="registrationFeesPaid">Registration Fees Paid</option>
                              <option value="rejected">Rejected</option>
                              <option value="requestRecieved">Request Received</option>
                              <option value="statusUnknown">Status Unknown</option>
                              <option value="withdrawn">Withdrawn</option>
                              {/* {this.props.progressId=="UZ2JCchSH0"?
                              <>
                              <option value="abandoned">Abandoned</option>
                              <option value="rejected">Rejected</option>
                              <option value="withdrawn">Withdrawn</option>
                              </>
                              :null} */}
                            </Input>
                        </FormGroup>

                        <FormGroup className="form-group">
                          <Label htmlFor="trademarkname">Status Notes <i className="far fa-question-circle ml-1" href="javascript:" id="UncontrolledTooltipExample1"></i></Label>
                      <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample1">
                        Hello world!
                      </UncontrolledTooltip>
                            <Input type="textarea" rows="5" id="statusNotes" name="statusNotes" placeholder="Add your status notes here..." value={this.state.mainData.statusNotes} onChange={(event)=>{                                
                               this.setState({mainData:{...this.state.mainData,statusNotes:event.target.value}})  
                            }}/>
                        </FormGroup>


                        <FormGroup className="form-group">
                          <Label htmlFor="trademarkname">Registration No <i className="far fa-question-circle ml-1" href="javascript:" id="UncontrolledTooltipExample1"></i></Label>
                      <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample1">
                        Hello world!
                      </UncontrolledTooltip>
                            <Input type="text" rows="5" id="statusNotes" name="statusNotes"  readOnly value={this.state.mainData.statusNotes} />
                        </FormGroup>

                     
               
                        <FormGroup className="form-group">
                            <Label htmlFor="exampleEmail12">Renewal Date <i className="far fa-question-circle ml-1" href="javascript:" id="logoTypeDate"></i></Label>
                        <UncontrolledTooltip placement="right" target="logoTypeDate">
                          Hello world!
                        </UncontrolledTooltip>
                        <div className="autocomwrap">
                            <DatePicker
                                id="logoDate"
                                selected={this.state.mainData.logoDate}
                                onSelect={(date)=>{this.setState({mainData:{...this.state.mainData,logoDate:date}})}}
                                onChange={(date)=>{this.setState({mainData:{...this.state.mainData,logoDate:date}})}}
                                className="form-control"
                                dateFormat="d/M/yyyy"
                                showYearDropdown
                                showMonthDropdown
                            />
                            
                            
                          </div>
                          </FormGroup>

                          <FormGroup className="form-group">
                            <Label htmlFor="exampleEmail12">Next Renewal Date <i className="far fa-question-circle ml-1" href="javascript:" id="logoTypeDate"></i></Label>
                        <UncontrolledTooltip placement="right" target="logoTypeDate">
                          Hello world!
                        </UncontrolledTooltip>
                        <div className="autocomwrap">
                            <DatePicker
                                id="logoDate"
                                selected={this.state.mainData.logoDate}
                                onSelect={(date)=>{this.setState({mainData:{...this.state.mainData,logoDate:date}})}}
                                onChange={(date)=>{this.setState({mainData:{...this.state.mainData,logoDate:date}})}}
                                className="form-control"
                                dateFormat="d/M/yyyy"
                                showYearDropdown
                                showMonthDropdown
                            />
                            
                            
                          </div>
                          </FormGroup>
                        
                   
                        
                      </Form>
                      </Col>

                      <Col md="5">
        <Form id="chatForm" Style="overflow:hidden">
                       <div className="d-flex align-items-center pb-3">
                       <img src={defaultuser} alt={"defaultuser"} className="rounded-circle mr-2" width="25" />
                       <h5 className="notes-title">{this.state.username}</h5>
					   {/* <img src={defaultuser} alt={"defaultuser"} className="rounded-circle ml-auto align-self-start" width="25" /> */}
                       </div>
                        <FormGroup className="form-group">
                            <Input type="textarea" rows="4" placeholder="Add your comments here..." id="msgnote" name="msgnote" value={this.state.message} 
                            onChange={(event)=>{this.setState({message:event.target.value})}}/>
                        </FormGroup>
                        {
                        this.state.showChatLoader?
                        <div className="btn btn-primary float-right pl-5 pr-5">
                        <Loader type="Oval" color="#414241" height={20} width={20} />
                        </div>:
                        (this.state.editmode)?
                        <Button color="primary" className="float-right pl-5 pr-5" onClick={()=>{this.postChat()}}>Post</Button>
                        :null
                        }

                        
                      </Form>
</Col>
</Row>
                

                    </CardBody>
                  </Card>


                  
           
          
                  
									

									{/* <Col md="5">
								
									
				<Card className="customAcc">
                  <CardTitle onClick={this.slidingClick.bind(this)} className={"opendefault "+this.state.ndClassName}>                    
                 {this.state.ndIcon?<i className={"icon-circle "+this.state.ndIcon}></i>:null} 
                    Notes / Discussion
                    </CardTitle>
                    <CardBody className="cardBodyNew">
                      <Form id="chatForm" Style="overflow:hidden">
                       <div className="d-flex align-items-center pb-3">
                       <img src={defaultuser} alt={"defaultuser"} className="rounded-circle mr-2" width="50" />
                       <h5 className="notes-title">{this.state.username}</h5>
					   <img src={defaultuser} alt={"defaultuser"} className="rounded-circle ml-auto align-self-start" width="25" />
                       </div>
                        <FormGroup className="form-group">
                            <Input type="textarea" rows="5" placeholder="Add your comments here..." id="msgnote" name="msgnote" value={this.state.message} 
                            onChange={(event)=>{this.setState({message:event.target.value})}}/>
                        </FormGroup>
                        {
                        this.state.showChatLoader?
                        <div className="btn btn-primary float-right pl-5 pr-5">
                        <Loader type="Oval" color="#414241" height={20} width={20} />
                        </div>:
                        (this.state.editmode)?
                        <Button color="primary" className="float-right pl-5 pr-5" onClick={()=>{this.postChat()}}>Post</Button>
                        :null
                        }

                        
                      </Form>
                      <div className="yscroll310">
                        {this.state.chats.length>0?
                            this.state.chats.map((item,index)=>{
                                //var usrnm = await getUserFullName(item.attributes.user);
                                return(
                                    <div className="postcmbx" key={item.id}>
                                        <div className="d-flex align-items-center pb-3">
                                            <img src={defaultuser} alt={"defaultuser"} className="rounded-circle mr-2" width="50" />
                                            <h5 className="notes-title">{item.attributes.user.attributes.firstName+" "+item.attributes.user.attributes.lastName} <span>{Moment(item.attributes.createdAt).format('DD-MM-YYYY H:mm')}</span></h5>
                                            <img src={defaultuser} alt={"defaultuser"} className="rounded-circle ml-auto align-self-start" width="25" />
                                        </div>
                                        <p>{item.attributes.message} </p>
                                    </div>
                                )
                            })
                            :null}
                        </div>
                    </CardBody>
                  </Card>

									</Col> */}
								
			</div>
		)
	}
}
export default  progressStatusForm;