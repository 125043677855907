var Parse = require('parse');

export const getTrademarkById = async (trademarkId) => {
    var TrademarksMaster = Parse.Object.extend("TrademarksMaster");
    var query = new Parse.Query(TrademarksMaster);
    query.equalTo("objectId", trademarkId);
    // query.include("BusinessSectors");
    var result = await query.find();
    if(result)
    return result[0];    
}

export const getTrademakMaterials = async (id) => {
    try{
    var trademark = await getTrademarkById(id);   

    var TrademarkMarketing = Parse.Object.extend("TrademarkMarketing");
    var querynew = new Parse.Query(TrademarkMarketing);
    querynew.equalTo("trademark",trademark);
    const results = await querynew.find();
    //console.log('getTrademakMaterials',results);
    if(results.length>0)
    {
        return results[0];
    }
    else
    {
        return null;
    }
}
catch(er)
{
    //console.log('getTrademakMaterials',er)
}
}


export const saveTrademarkMarketing=async(obj)=>{
    var saveObj = await obj.save();
    return saveObj;
}

export const convertStateObjToParseObj = async (mainData)=>
{
   
    try{
    const TrademarkMarketing = Parse.Object.extend('TrademarkMarketing');
    let myNewObject = new TrademarkMarketing();
    if(mainData.id)
    {
        //var query = new Parse.Query(TrademarksMaster);
        //myNewObject = await query.get(mainData.id);
        myNewObject.set('objectId', mainData.id);
    }

    myNewObject.set('logoTypeUpdated', mainData.logoTypeUpdated);
    myNewObject.set('logoTypeUrl', mainData.logoTypeUrl);
    myNewObject.set('logoDate', mainData.logoDate);
    myNewObject.set('logoComment', mainData.logoComment);

    myNewObject.set('stationaryUpdated', mainData.stationaryUpdated);
    myNewObject.set('stationaryUrl', mainData.stationaryUrl);
    myNewObject.set('stationaryDate', mainData.stationaryDate);
    myNewObject.set('stationaryComment', mainData.stationaryComment);

    myNewObject.set('buUpdated', mainData.buUpdated);
    myNewObject.set('buUrl', mainData.buUrl);
    myNewObject.set('buDate', mainData.buDate);
    myNewObject.set('buComment', mainData.buComment);

    myNewObject.set('corporateUpdated', mainData.corporateUpdated);
    myNewObject.set('corporateUrl', mainData.corporateUrl);
    myNewObject.set('corporateDate', mainData.corporateDate);
    myNewObject.set('corporateComment', mainData.corporateComment);

    if (mainData.trademark) {
        var extentObject = Parse.Object.extend("TrademarksMaster");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(mainData.trademark);
        myNewObject.set('trademark', parsepoint);
    }
    
    
    return myNewObject;
}
catch(ex)
{
    //console.log(ex);
}
}


export const convertParseObjTOStateObj = async (obj)=>
{      
    try{
        var mainData = {};
        mainData.id = obj.id;
        mainData.logoTypeUpdated = obj.attributes.logoTypeUpdated;
        mainData.logoTypeUrl = obj.attributes.logoTypeUrl;
        mainData.logoDate = obj.attributes.logoDate;
        mainData.logoComment = obj.attributes.logoComment;

        mainData.stationaryUpdated = obj.attributes.stationaryUpdated;
        mainData.stationaryUrl = obj.attributes.stationaryUrl;
        mainData.stationaryDate = obj.attributes.stationaryDate;
        mainData.stationaryComment = obj.attributes.stationaryComment;

        mainData.buUpdated = obj.attributes.buUpdated;
        mainData.buUrl = obj.attributes.buUrl;
        mainData.buDate = obj.attributes.buDate;
        mainData.buComment = obj.attributes.buComment;

        mainData.corporateUpdated = obj.attributes.corporateUpdated;
        mainData.corporateUrl = obj.attributes.corporateUrl;
        mainData.corporateDate = obj.attributes.corporateDate;
        mainData.corporateComment = obj.attributes.corporateComment;

        
        if(obj.attributes.trademark)
        {
            mainData.trademark = obj.attributes.trademark.id;
        }
       
        //console.log(mainData);
    
    return mainData;
}
catch(ex)
{
    //console.log(ex);
}
}

export const saveTrademarkMarketingImport = async(obj)=>{

    var tempObj = await convertStateObjToParseObj(obj);
    //console.log(tempObj);
    if(tempObj)
    {
        var subObj = await saveTrademarkMarketing(tempObj);
    }
}