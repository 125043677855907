import React from 'react';
import {
    Table,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Form,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroup,
    Button,
    Alert
} from 'reactstrap';

import imgtrademark from '../../assets/images/trademark.png';
import imgview from '../../assets/images/icons/view-white.png';
import imgnoview from '../../assets/images/icons/no-view-white.png';
import imgedit from '../../assets/images/icons/edit-white.png';
import imgtoyota from '../../assets/images/toyota.png';
import imgt2 from '../../assets/images/trademark2.png';
import imgaljicon from '../../assets/images/aljicon.png';
import imgsettings from '../../assets/images/icons/settings-white.png';


class Basictable extends React.Component {
//For Dismiss Button with Alert
constructor(props) {
  super(props);

  this.state = {
      visible: true
  };

  this.onDismiss = this.onDismiss.bind(this);
}

onDismiss() {
  this.setState({
      visible: false
  });
}
  render() {
    return <div>
            {/*--------------------------------------------------------------------------------*/}
            {/* Start Inner Div*/}
            {/*--------------------------------------------------------------------------------*/}

            <Row className="justify-content-end">
            <Col className="col-auto mb-4">
            <a href="/content-form/content-form" className="btn btn-primary btnaddmew mr-3"> <span className="border-left pl-2">Add new</span></a>
            <a href="javascript:"><img src={imgsettings} alt={"imgsettings"} width="30" /></a>
            </Col>
            </Row>

            <Row>
            <Col className="col-12 mb-4">
            <Form className="tableserch">
                  <InputGroup>
                  <FormGroup className="form-group" className="col-md-2 p-0 m-0">
                  <Input type="select" className="custom-select">
                            <option value="">Owner</option>
                            <option>Value 1</option>
                            <option>Value 2</option>
                            <option>Value 3</option>
                            <option>Value 4</option>
                            <option>Value 5</option>
                    </Input>
                    </FormGroup>
                    <Input type="text" placeholder="Enter your search here" />
                    {/* <InputGroupAddon addonType="append"> */}
                      <Button className="search"><i className="ti-search"></i></Button>
                    {/* </InputGroupAddon> */}
                  </InputGroup>
                </Form>
              </Col>
              {/* <Col className="col-12 mb-4">
              <div className="d-flex">
              <Alert isOpen={this.state.visible} toggle={this.onDismiss}>
              Owner : FRV
              </Alert>
              <Alert isOpen={this.state.visible} toggle={this.onDismiss}>
              Status : Registered
              </Alert>
              </div>
              </Col> */}
            </Row>

            <Row>
              {/*--------------------------------------------------------------------------------*/}
              {/* Hoverable Rows                                                                 */}
              {/*--------------------------------------------------------------------------------*/}
              <Col md="12">
                <Card>
                  <CardTitle><i className="mdi mdi-border-all mr-2"></i>Trademarks</CardTitle>
                  <CardBody>
                  <Table responsive className="text-center">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">TW / Ref No.</th>
                            <th scope="col">AGIP Ref No.</th>
                            <th scope="col">Status</th>
                            <th scope="col">Country</th>
                            <th scope="col">Trademark</th>
                            <th scope="col">Renewal Date</th>
                            <th scope="col" width="130px">Owner</th>
                            <th scope="col">Classes</th>
                            <th scope="col">Marketing Materials</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>ABD 72UD200</td>
                            <td>4121213232</td>
                            <td className="status"><a href="javascript:" className="btn btn-green">Registered</a></td>
                            <td>Algeria</td>
                            <td><img src={imgaljicon} alt={"imgaljicon"} width="36" /></td>
                            <td>02-Jan-2018</td>
                            <td>FRV Fotowatio Renewable Ventures</td>
                            <td>02  23  23  12  23  23  12</td>
                            <td><a href="javascript:" className="btn btn-primary btnarrowright">View</a></td>
                            <td><a href="javascript:"><img src={imgview} alt={"imgview"} width="26" /></a> <a href="javascript:" className="ml-2"><img src={imgedit} alt={"imgedit"} width="28" /></a></td>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td>ABD 72UD200</td>
                            <td>4121213232</td>
                            <td className="status"><a href="javascript:" className="btn btn-green">Registered</a></td>
                            <td>Algeria</td>
                            <td><img src={imgtoyota} alt={"imgtoyota"} width="36" /></td>
                            <td>02-Jan-2018</td>
                            <td>FRV Fotowatio Renewable Ventures</td>
                            <td>02  23  23  12  23  23  12</td>
                            <td><a href="javascript:" className="btn btn-primary btnarrowright">View</a></td>
                            <td><a href="javascript:"><img src={imgnoview} alt={"imgnoview"} width="26" /></a> <a href="javascript:" className="ml-2"><img src={imgedit} alt={"imgedit"} width="28" /></a></td>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td>ABD 72UD200</td>
                            <td>4121213232</td>
                            <td className="status"><a href="javascript:" className="btn btn-blue">Underprocess</a></td>
                            <td>Algeria</td>
                            <td><img src={imgt2} alt={"imgt2"} width="36" /></td>
                            <td>02-Jan-2018</td>
                            <td>FRV Fotowatio Renewable Ventures</td>
                            <td>02  23  23  12  23  23  12</td>
                            <td><a href="javascript:" className="btn btn-primary btnarrowright">View</a></td>
                            <td><a href="javascript:"><img src={imgview} alt={"imgview"} width="26" /></a> <a href="javascript:" className="ml-2"><img src={imgedit} alt={"imgedit"} width="28" /></a></td>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td>ABD 72UD200</td>
                            <td>4121213232</td>
                            <td className="status"><a href="javascript:" className="btn btn-blue">Underprocess</a></td>
                            <td>Algeria</td>
                            <td><img src={imgtrademark} alt={"imgtrademark"} width="36" /></td>
                            <td>02-Jan-2018</td>
                            <td>FRV Fotowatio Renewable Ventures</td>
                            <td>02  23  23  12  23  23  12</td>
                            <td><a href="javascript:" className="btn btn-primary btnarrowright">View</a></td>
                            <td><a href="javascript:"><img src={imgview} alt={"imgview"} width="26" /></a> <a href="javascript:" className="ml-2"><img src={imgedit} alt={"imgedit"} width="28" /></a></td>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td>ABD 72UD200</td>
                            <td>4121213232</td>
                            <td className="status"><a href="javascript:" className="btn btn-red">Pending</a></td>
                            <td>Algeria</td>
                            <td><img src={imgaljicon} alt={"imgaljicon"} width="36" /></td>
                            <td>02-Jan-2018</td>
                            <td>FRV Fotowatio Renewable Ventures</td>
                            <td>02  23  23  12  23  23  12</td>
                            <td><a href="javascript:" className="btn btn-primary btnarrowright">View</a></td>
                            <td><a href="javascript:"><img src={imgview} alt={"imgview"} width="26" /></a> <a href="javascript:" className="ml-2"><img src={imgedit} alt={"imgedit"} width="28" /></a></td>
                        </tr>
                        <tr>
                            <th scope="row">1</th>
                            <td>ABD 72UD200</td>
                            <td>4121213232</td>
                            <td className="status"><a href="javascript:" className="btn btn-red">Pending</a></td>
                            <td>Algeria</td>
                            <td><img src={imgtrademark} alt={"imgtrademark"} width="36" /></td>
                            <td>02-Jan-2018</td>
                            <td>FRV Fotowatio Renewable Ventures</td>
                            <td>02  23  23  12  23  23  12</td>
                            <td><a href="javascript:" className="btn btn-primary btnarrowright">View</a></td>
                            <td><a href="javascript:"><img src={imgview} alt={"imgview"} width="26" /></a> <a href="javascript:" className="ml-2"><img src={imgedit} alt={"imgedit"} width="28" /></a></td>
                        </tr>
                    </tbody>
                  </Table>
                  </CardBody>
                </Card>

                <div className="tablepagination d-flex justify-content-center">
                  <div className="col-auto">Page  
                     <Form className="pageJump ml-2 mr-2">
                        <FormGroup className="form-group">
                          <Input type="number" onWheel={ event => event.currentTarget.blur() } defaultValue="1" />
                        </FormGroup>
                      </Form> of 20
                      </div>
                      <div className="col-auto">
                      <FormGroup className="form-group" className="pageSizeOptions">
                            <Input type="select">
                              <option value="">5 rows</option>
                              <option>10 rows</option>
                              <option>20 rows</option>
                              <option>30 rows</option>
                              <option>40 rows</option>
                              <option>50 rows</option>
                            </Input>
                        </FormGroup>
                        </div>
                  </div>

              </Col>
            </Row>
            {/*--------------------------------------------------------------------------------*/}
            {/*End Inner Div*/}
            {/*--------------------------------------------------------------------------------*/}
          </div>
  }
}

export default Basictable;
