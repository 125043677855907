import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormFeedback,
  CustomInput,
  Tooltip,
  UncontrolledTooltip
} from 'reactstrap';
import Select, { components } from "react-select";
import jQuery from 'jquery'

import { authenticateLoggedIn, addHeaderTitle, addBreadcrumb } from '../../../services/parse';
import { updateRecord, checkFieldValid, fetchRecordById } from '../../../services/licensetype';
import withRouter from '../../../components/common/WithRouter';

class EditLicenseType extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      collapse: true,
      fadeIn: true,
      timeout: 300,
      isSuper: '',
      addedclass: 'd-none',
      recordByIdData: [],
      isEdit: false,
      logofile: null,
      rewardsfile: null,
      loginlogofile: null,
    };
  }


  async componentDidMount(evt) {
    //auth start
    authenticateLoggedIn();
    addHeaderTitle('Edit License Type');
    addBreadcrumb('Home / License Type / Edit License Type');

    // var editobjectid = getParameterByName('objectid');
    var editobjectid = this.props.params.objectid;

    if (editobjectid) {

      fetchRecordById({ objectid: editobjectid }).then((value) => {
        if (value.length > 0) {
          this.setState({ recordByIdData: value[0] });
          this.setState({ isEdit: true });


        } else {
          this.setState({ isEdit: false });
        }
      });

    }

  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }


  updateRecord = () => {

    var valid = checkFieldValid('add');
    if (valid !== false) {
      jQuery('#addbutton').prop('disabled', true);

      var hiddenrecordid = jQuery('#hiddenrecordid').val();

      var name = jQuery('#name').val();
      var link = jQuery('#link').val();
      var description = jQuery('#description').val();
      var status = jQuery('#status').val();
      var slug = jQuery('#slug').val();

      updateRecord({ edithiddenid: hiddenrecordid, name: name, link: link, description: description,slug:slug, status: status }).then((value) => {
        if (value === true) {
          this.setState({ addedclass: 'd-block' });
        } else {
          this.setState({ addedclass: 'd-none' });
        }

        jQuery('#addform').trigger("reset");
        jQuery('#addbutton').prop('disabled', false);

        
      });

    }
  }





  render() {
    return <div>

      {this.state.isEdit === true ?
        <div>
          <Row className="justify-content-between topbuttonbar">
            <Col className="col-md-5">
              <Alert color="success" className={this.state.addedclass}>
                Record Updated
                <Link to="/contentmaster/licensetype/" className="float-right">
                  &nbsp; Go to list
                </Link>
              </Alert>
            </Col>
            <Col className="col-auto mb-4">
              <Link to="/contentmaster/licensetype/" className="btn btn-primary btncancle mr-3">
                <span className="border-left pl-2">Cancel</span>
              </Link>
              <Button type="button" color="primary" id="addbutton" onClick={this.updateRecord}>
                <span className="border-left pl-2"></span>
                Save
              </Button>
            </Col>
          </Row>
          <Row>

            <Col md="5">
              <Card>
                <CardTitle>
                  <i className="icon-circle icon-pencil"></i>
                  Edit License Type
                </CardTitle>
                <CardBody>





                  <Form id="addform">
                    <Input type="hidden" id="hiddenrecordid" defaultValue={this.state.recordByIdData.id} />
                    <FormGroup className="form-group">
                      <Label htmlFor="name">Name</Label>
                      <Input type="text" name="name" id="name" defaultValue={this.state.recordByIdData.get('name')} />
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Label htmlFor="name">Link</Label>
                      <Input type="text" name="link" id="link" defaultValue={this.state.recordByIdData.get('link')} />
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Label htmlFor="description">Description </Label>
                      <Input type="textarea" name="description" id="description" rows="5" placeholder="Add your comments here..." defaultValue={this.state.recordByIdData.get('description')} />
                    </FormGroup>



                    <FormGroup className="form-group">
                      <Label htmlFor="country">Status</Label>
                      <select name="status" id="status" className="form-control" defaultValue={String(this.state.recordByIdData.get('status'))}>
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                      </select>
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Label htmlFor="name">Slug</Label>
                      <Input type="text" name="slug" id="slug" defaultValue={this.state.recordByIdData.get('slug')} disabled/>
                    </FormGroup>




                  </Form>
                </CardBody>
              </Card>

            </Col>
          </Row>
        </div>
        :
        <p></p>
      }

    </div>
  }
}

export default withRouter(EditLicenseType);
