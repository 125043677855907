import React from 'react';
import {
  Card, CardHeader, CardBody, Alert, Table, Row, Col, Input, Form, FormGroup, CardTitle, InputGroup, InputGroupAddon, Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Select, { components } from "react-select";
import jQuery from 'jquery';

import { authenticateLoggedIn, addHeaderTitle, addBreadcrumb } from '../../services/parse';
import { getFullPageCount, getSearchResults, getBusinessUnits, hideRecords } from '../../services/businessunit';


import imgview from '../../assets/images/icons/view-white.png';
import imgnoview from '../../assets/images/icons/no-view-white.png';
import imgedit from '../../assets/images/icons/edit-white.png';


function afterDelete() {
  var checkboxes = document.getElementsByName('checklist');
  for (var i = 0, n = checkboxes.length; i < n; i++) {
    checkboxes[i].checked = false;
  }

  var lst = document.getElementsByClassName('checkclicked');
  for (var i = 0; i < lst.length; ++i) {
    lst[i].style.display = 'none';
  }

}

class businessUnit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      textClass: '',
      isSuper: '',
      datafull: '',
      totalpagecount: '',
      data: [],
      deleteIds: [],
      deletedstat: 'd-none',
    };

    this.toggle = this.toggle.bind(this);

  }


  async componentDidMount(evt) {
    //auth start
    authenticateLoggedIn();
    addHeaderTitle('Business Units');
    addBreadcrumb('Home / Business Units');

    //auth end
    var dataperpage = jQuery('#dataperpage').val();
    var sortitemval = jQuery('#sortitemval').val();
    var sortitemorder = jQuery('#sortitemorder').val();

    getFullPageCount().then((value) => {
      this.setState({ datafull: value, });

      var totpagecount = Math.ceil(value / dataperpage);
      this.setState({ totalpagecount: totpagecount, });
    });

    getBusinessUnits(1, dataperpage, sortitemval, sortitemorder).then((value) => {
      this.setState({ data: value, });
    });

    jQuery('#searchbox').on('keyup keypress', function (e) {
      var keyCode = e.keyCode || e.which;
      if (keyCode === 13) {
        e.preventDefault();
        return false;
      }
    });
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  setSortItem = (item) => {

    if (item.target.dataset.sortorder === "ascending") {
      item.target.dataset.sortorder = "descending";
      item.target.classList.add("descending");
      item.target.classList.remove("ascending");
    } else {
      item.target.dataset.sortorder = "ascending";
      item.target.classList.add("ascending");
      item.target.classList.remove("descending");
    }

    var sortval = item.target.dataset.sort;
    jQuery('#sortitemval').val(sortval);

    var sortitemorder = item.target.dataset.sortorder;
    jQuery('#sortitemorder').val(sortitemorder);

    this.gotoPage();
  }

  gotoPage = () => {
    var dataperpage = jQuery('#dataperpage').val();
    var pagenum = jQuery('#pagenum').val();
    var sortitemval = jQuery('#sortitemval').val();
    var sortitemorder = jQuery('#sortitemorder').val();

    getBusinessUnits(pagenum, dataperpage, sortitemval, sortitemorder).then((value) => {
      this.setState({ data: value, });
    });
  }
  showPerPage = () => {
    var dataperpage = jQuery('#dataperpage').val();
    var pagenum = jQuery('#pagenum').val();
    var sortitemval = jQuery('#sortitemval').val();
    var sortitemorder = jQuery('#sortitemorder').val();

    var totpagecount = Math.ceil(this.state.datafull / dataperpage);
    this.setState({ totalpagecount: totpagecount, });

    getBusinessUnits(pagenum, dataperpage, sortitemval, sortitemorder).then((value) => {
      this.setState({ data: value, });
    });

  }

  hitEnter = (e) => {
    var keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      this.searchRecords();
    }
  }

  setSearchFields = () => {
    var setsrcfield = jQuery('#setsrcfield').val();
    if (setsrcfield == "status") {
      jQuery('#searchbox').addClass('d-none');
      jQuery('#statsrc').removeClass('d-none');
    } else {
      jQuery('#searchbox').removeClass('d-none');
      jQuery('#statsrc').addClass('d-none');
    }
  }
  searchRecords = () => {
    var searchbox = jQuery('#searchbox').val();
    var statsrc = jQuery('#statsrc').val();

    var setsrcfield = jQuery('#setsrcfield').val();
    if (setsrcfield == "name") {
      jQuery('#searchboxhidden').val(searchbox);

      //Filter
      jQuery('#namefilter label').html(searchbox);
      jQuery('#namefilter').removeClass('d-none');
      //Filter
    } else if (setsrcfield == "status") {
      jQuery('#statsrchidden').val(statsrc);

      //Filter
      jQuery('#statfilter label').html(statsrc);
      jQuery('#statfilter').removeClass('d-none');
      //Filter
    }

    this.searchInit();
  }

  searchInit = () => {
    var searchboxhidden = jQuery('#searchboxhidden').val();
    var statsrchidden = jQuery('#statsrchidden').val();

    var searchdatas = {
      searchbox: searchboxhidden,
      statsrc: statsrchidden,
    };

    if (searchboxhidden !== "" || statsrchidden !== "") {
      getSearchResults(searchdatas).then((value) => {
        this.setState({ data: value, });
      });
    } else {
      var dataperpage = jQuery('#dataperpage').val();
      var sortitemval = jQuery('#sortitemval').val();
      var sortitemorder = jQuery('#sortitemorder').val();
      getBusinessUnits(1, dataperpage, sortitemval, sortitemorder).then((value) => {
        this.setState({ data: value, });
      });
    }

  }

  clearFilterItem = (event) => {
    var itemname = event.currentTarget.dataset.item;
    if (itemname === "name") {
      jQuery('#namefilter label').html('');
      jQuery('#namefilter').addClass('d-none');
      jQuery('#searchboxhidden').val('');
    } else if (itemname === "status") {
      jQuery('#statfilter label').html('');
      jQuery('#statfilter').addClass('d-none');
      jQuery('#statsrchidden').val('');
    }

    this.searchInit();
  }

  hideRecord = (event) => {
    var objectid = event.currentTarget.dataset.target;
    jQuery('#hiddenidstored').val(objectid);
    this.toggle();
  }
  pushhideRecord = () => {
    var objectid = jQuery('#hiddenidstored').val();
    hideRecords(objectid).then((value) => {
      if (value === true) { 
        this.gotoPage();

        this.setState({ deletedstat: 'd-block' });
      }
    });
    this.toggle();
  }




  render() {
    return <div className="animated fadeIn">
      <Input type="hidden" id="sortitemval" />
      <Input type="hidden" id="sortitemorder" />

      <Row className="justify-content-end">
        <Col className="col-auto mb-4">
          <Link to="/business-unit/addbusinessunit" className="btn btn-primary btnaddmew">
            <span className="border-left pl-2">Add new</span>
          </Link>
        </Col>
      </Row>

      <Row>
        <Col className="col-12 mb-4">
          <Form className="tableserch">
            <InputGroup>
              <FormGroup className="form-group col-md-2 p-0 m-0">
                <Input type="select" className="custom-select form-control" id="setsrcfield" onChange={this.setSearchFields}>
                  <option value="name">Name</option>
                  <option value="status">Status</option>
                </Input>
              </FormGroup>

              <Input type="text" placeholder="Enter your search here" id="searchbox" onKeyDown={this.hitEnter} />
              <Input type="hidden" id="searchboxhidden" />

              <select name="statsrc" id="statsrc" className="d-none" onKeyDown={this.hitEnter}>
                <option value="">Select</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
              <Input type="hidden" id="statsrchidden" />



              {/* <InputGroupAddon addonType="append"> */}
                <Button className="search" onClick={this.searchRecords}><i className="ti-search" ></i></Button>
              {/* </InputGroupAddon> */}
            </InputGroup>
          </Form>
          <div id="searchfilters">
            <div id="namefilter" className="d-none filters">Name: <label></label> <span className="fclose" data-item="name" onClick={this.clearFilterItem}>x</span></div>
            <div id="statfilter" className="d-none filters">Status: <label></label> <span className="fclose" data-item="status" onClick={this.clearFilterItem}>x</span></div>
          </div>
        </Col>






      </Row>


      <Row>
        <Col xs="12">
          <Card>

            {/* <CardTitle><i className="mdi mdi-border-all mr-2"></i>Business Units</CardTitle> */}
            <CardBody>
              <Alert color="success" className={this.state.deletedstat}>
                Selected status have been changed!
              </Alert>

              <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                <ModalHeader toggle={this.toggle}>Confirm</ModalHeader>
                <ModalBody>
                  Do you want to change status?
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={this.pushhideRecord}>Yes</Button>
                  <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                </ModalFooter>
              </Modal>
              <Input type="hidden" id="hiddenidstored" />


              <Table responsive className="text-center">
                <thead>
                  <tr>
                    <th className="cursor-pointer" data-sort="name" onClick={this.setSortItem}>Name</th>
                    <th className="cursor-pointer" data-sort="description" onClick={this.setSortItem} width="25%">Description</th>
                    <th className="cursor-pointer" data-sort="status" onClick={this.setSortItem}>Status</th>
                    <th className="text-center" width="110">Actions</th>
                  </tr>
                </thead>
                <tbody>


                  {
                    this.state.data.map((dynamicData, key) =>
                      <tr key={key}>
                        <td>
                          <Link to={"/business-unit/editbusinessunit/" + dynamicData.id} className="tdwrap">
                            {dynamicData.get('name')}
                          </Link>
                        </td>
                        <td>
                          <Link to={"/business-unit/editbusinessunit/" + dynamicData.id} className="tdwrap">
                            <div className="textscroll">{dynamicData.get('description')}</div>
                          </Link>
                        </td>

                        <td className="status">
                          {
                            dynamicData.get('status').toString() === 'true' ?
                              <span className="active">Active</span>
                              :
                              <span className="inactive">Inactive</span>
                          }
                        </td>

                        <td className="text-center">
                          <a href="javascript:" className="btnicon hideit" onClick={this.hideRecord} data-target={dynamicData.id}>
                            {
                              dynamicData.get('status').toString() === 'true' ?
                                <img src={imgview} alt={"imgview"} width="26" />
                                :
                                <img src={imgnoview} alt={"imgnoview"} width="26" />
                            }
                          </a>

                          <Link to={"/business-unit/editbusinessunit/" + dynamicData.id} className="btnicon padding0">
                            <img src={imgedit} alt={"imgedit"} width="28" />
                          </Link>

                        </td>
                      </tr>

                    )
                  }


                </tbody>
              </Table>

            </CardBody>
          </Card>

          <div className="tablepagination d-flex justify-content-center">
            <div className="col-auto">
              <FormGroup  className="form-group pageSizeOptions">
                <Input className='form-control' type="select" id="dataperpage" onClick={this.showPerPage}>
                  <option value="25">25 rows</option>
                  <option value="50">50 rows</option>
                  <option value="100">100 rows</option>
                </Input>
              </FormGroup>
            </div>


            <div className="col-auto">Page
                <Form className="pageJump ml-1 mr-1">
                <FormGroup className="form-group">
                  <Input type="number" defaultValue="1" id="pagenum" min="1" />
                </FormGroup>
              </Form> of {Math.ceil(this.state.totalpagecount)}

              <a href="javascript:" className="btn btn-primary btnarrowright" onClick={this.gotoPage}>Go</a>

            </div>

          </div>
        </Col>
      </Row>
    </div>
  }
}

export default businessUnit;
