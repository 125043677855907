import Dashboard from '../views/dashboard/dashboard.jsx';
import AuthRoutes from './authroutes.jsx';
import Basicform from '../views/form-layouts/basic.jsx';
import FormSteps from '../views/steps/steps.jsx';
import Basictable from '../views/tables/tablebasic.jsx';

import User from '../views/user/user.jsx';
import AddUser from '../views/user/addUser.jsx';
import EditUser from '../views/user/editUser.jsx';

import BusinessUnit from '../views/businessUnit/businessUnit.jsx';
import AddBusinessUnit from '../views/businessUnit/addBusinessUnit.jsx';
import EditBusinessUnit from '../views/businessUnit/editBusinessUnit.jsx';

import Emailtemplate from '../views/emailTemplate/emailTemplate.jsx';
import AddEmailTemplate from '../views/emailTemplate/addEmailTemplate.jsx';
import EditEmailTemplate from '../views/emailTemplate/editEmailTemplate.jsx';

import BusinessSector from '../views/businessSector/businessSector.jsx';
import AddBusinessSector from '../views/businessSector/addBusinessSector.jsx';
import EditBusinessSector from '../views/businessSector/editBusinessSector.jsx';

import Jurisdiction from '../views/jurisdiction/jurisdiction.jsx';
import AddJurisdiction from '../views/jurisdiction/addJurisdiction.jsx';
import EditJurisdiction from '../views/jurisdiction/editJurisdiction.jsx';

import Region from '../views/region/region.jsx';
import AddRegion from '../views/region/addRegion.jsx';
import EditRegion from '../views/region/editRegion.jsx';

import Domainname from '../views/domainname/domainname.jsx';
import AddDomainname from '../views/domainname/addDomainname.jsx';
import EditDomainname from '../views/domainname/editDomainname.jsx';

import Webcategory from '../views/webcategory/webcategory.jsx';
import AddWebcategory from '../views/webcategory/addWebcategory.jsx';
import EditWebcategory from '../views/webcategory/editWebcategory.jsx';

import Registrar from '../views/registrar/registrar.jsx';
import AddRegistrar from '../views/registrar/addRegistrar.jsx';
import EditRegistrar from '../views/registrar/editRegistrar.jsx';

import Countries from '../views/countries/countries.jsx';
import AddCountry from '../views/countries/addCountry.jsx';
import EditCountry from '../views/countries/editCountry.jsx';

import Masterbranddivision from '../views/masterbranddivision/masterbrandDivision.jsx';
import AddMasterbrandDivision from '../views/masterbranddivision/addMasterbrandDivision.jsx';
import EditMasterbrandDivision from '../views/masterbranddivision/editMasterbrandDivision.jsx';

import Classification from '../views/classification/classification.jsx';
import AddClassification from '../views/classification/addClassification.jsx';
import EditClassification from '../views/classification/editClassification.jsx';

import Companies from '../views/companies/companies.jsx';
import AddCompanies from '../views/companies/addCompanies.jsx';
import EditCompanies from '../views/companies/editCompanies.jsx';

import Lawfirm from '../views/lawfirm/lawfirm.jsx';
import AddLawfirm from '../views/lawfirm/addLawfirm.jsx';
import EditLawfirm from '../views/lawfirm/editLawfirm.jsx';

import DomainStatus from '../views/domainstatus/domainStatus.jsx';
import AddDomainStatus from '../views/domainstatus/addDomainStatus.jsx';
import EditDomainStatus from '../views/domainstatus/editDomainStatus.jsx';

import Domain from '../views/domain/domain.jsx';
import Calendarview from '../views/domain/calendarview.jsx';
import AddDomain from '../views/domain/addDomain.jsx';
import EditDomain from '../views/domain/editDomain.jsx';
import ExportDomains from '../views/domain/exportDomains.jsx';


import Finance from '../views/finance/finance.jsx';
import AddFinance from '../views/finance/addFinance.jsx';
import EditFinance from '../views/finance/editFinance.jsx';
import ExportFinance from '../views/finance/exportFinance.jsx';

//imports for trademark pages
import ViewAllTrademarks from '../views/trademark/viewAllTrademarks.jsx';
import TabContainer from '../views/trademark/tabContainer.jsx';
// import ImportComp from '../views/trademark/import';
import ExportTrademarks from '../views/trademark/exportTrademarks.jsx';


import MediaLibrary from '../views/medialicenses/libraries/libraries.jsx';
import MediaAssets from '../views/medialicenses/assets/assets.jsx';
import MediaAssetsFull from '../views/medialicenses/assets/assetsfull.jsx';
// import assetsCalenderView from '../views/medialicenses/assets/calendarview.jsx';
import BulkImport from '../views/medialicenses/bulkimport/bulkImport.jsx';
import AssetAgency from '../views/medialicenses/agencysubmission/assetAgency.jsx';


import LicenseType from '../views/mediacontentmaster/licensetype/licenseType.jsx';
import AddLicenseType from '../views/mediacontentmaster/licensetype/addLicenseType.jsx';
import EditLicenseType from '../views/mediacontentmaster/licensetype/editLicenseType.jsx';

import Agency from '../views/mediacontentmaster/agency/agency.jsx';
import AddAgency from '../views/mediacontentmaster/agency/addAgency.jsx';
import EditAgency from '../views/mediacontentmaster/agency/editAgency.jsx';

import AssetType from '../views/mediacontentmaster/assettype/assetType.jsx';
import AddAssetType from '../views/mediacontentmaster/assettype/addAssetType.jsx';
import EditAssetType from '../views/mediacontentmaster/assettype/editAssetType.jsx';

import MediaUsageLibrary from '../views/mediacontentmaster/medialibrary/mediaLibrary.jsx';
import AddMediaLibrary from '../views/mediacontentmaster/medialibrary/addMediaLibrary.jsx';
import EditMediaLibrary from '../views/mediacontentmaster/medialibrary/editMediaLibrary.jsx';

import Brand from '../views/mediacontentmaster/brand/brand.jsx';
import AddBrand from '../views/mediacontentmaster/brand/addBrand.jsx';
import EditBrand from '../views/mediacontentmaster/brand/editBrand.jsx';

import GeoRestriction from '../views/mediacontentmaster/georestriction/geoRestriction.jsx';
import AddGeoRestriction from '../views/mediacontentmaster/georestriction/addGeoRestriction.jsx';
import EditGeoRestriction from '../views/mediacontentmaster/georestriction/editGeoRestriction.jsx';

import Tags from '../views/mediacontentmaster/tags/tags.jsx';
import AddTag from '../views/mediacontentmaster/tags/addTag.jsx';
import EditTag from '../views/mediacontentmaster/tags/editTag.jsx';

 

import icnonDashboard from '../assets/images/icons/icon-dashboard.png';
import iconTrademark from '../assets/images/icons/icon-trademark.png';
import iconGlobe from '../assets/images/icons/icon-globe.png';
import iconUser from '../assets/images/icons/icon-user.png';
import iconFinaince from '../assets/images/icons/icon-finaince.png';
import iconChatKey from '../assets/images/icons/icon-chat-key.png';
import iconMedia from '../assets/images/icons/icon-media.png';


var auths = [].concat(AuthRoutes);

var ThemeRoutes = [
  // {
  //   navlabel: true,
  //   name: "",
  //   iconimg: icnonDashboard
  // },
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'mdi mdi-account-circle',
    iconimg: icnonDashboard,
    component: <Dashboard/>
  },
  {
    collapse: true,
    path: "/",
    name: "Trademarks",
    state: "dashboardpages",
    iconimg: iconTrademark,
    child: [
      {
        path: '/trademark/list',
        name: 'View All',
        component: <ViewAllTrademarks/>
      },
      {
        path: '/trademark/addtrademark',
        name: 'Add New',
        component: <TabContainer/>,
      },
      {
        path: '/trademark/edittrademark/:id/:save',
        name: 'Edit request',
        component: <TabContainer/>,
        linkcalss: 'd-none',
      },
      {
        path: '/trademark/edittrademark/:id',
        name: 'Edit request',
        component: <TabContainer/>,
        linkcalss: 'd-none',
      },
      // {
      //   path: '/trademark/import',
      //   name: ' request',
      //   component: <ImportComp/>,
      //   linkcalss: 'd-none',
      // },
      {
        path: '/trademark/exporttrademarks',
        name: 'Export Trademarks',
        component: <ExportTrademarks/>,
      }
    ]
  },




  {
    collapse: true,
    path: "/",
    name: "Domains",
    state: "domainnamepages",
    iconimg: iconGlobe,
    child: [
      {
        path: '/domain/domain',
        name: 'View All',
        component: <Domain/>
      },
      {
        path: '/domain/calendarview',
        name: 'Calendar View',
        component: <Calendarview/>,
        // linkcalss: 'd-none',
      },
      {
        path: '/domain/adddomain',
        name: 'Add Domain',
        component: <AddDomain/>,
        // linkcalss: 'd-none',
      },
      {
        path: '/domain/editdomain/:objectid/:version',
        name: 'Edit Domain',
        component: <EditDomain/>,
        linkcalss: 'd-none',
      },
      {
        path: '/domain/exportdomains',
        name: 'Export Domains',
        component: <ExportDomains/>,
      },
      
    ]
  },
  {
    collapse: true,
    path: "/",
    name: "Finance",
    state: "financepages",
    iconimg: iconFinaince,
    child: [
      {
        path: '/finance/finance/:domainid',
        name: 'View All',
        component: <Finance/>
      },
      {
        path: '/finance/addfinance/:domainid',
        name: 'Add Finance',
        component: <AddFinance/>,
        linkcalss: 'd-none',
      },
      {
        path: '/finance/editfinance/:objectid/:version',
        name: 'Edit Finance',
        component: <EditFinance/>,
        linkcalss: 'd-none',
      },
      {
        path: '/finance/exportfinance',
        name: 'Export Finance',
        component: <ExportFinance/>,
      },
    ]
  },
  {
    collapse: true,
    path: "/",
    name: "Users",
    state: "userpages",
    iconimg: iconUser,
    child: [
      {
        path: '/user/user',
        name: 'View All',
        component: <User />
      },
      {
        path: '/user/adduser',
        name: 'Add User',
        component: <AddUser />,
        // linkcalss: 'd-none',
      },
      {
        path: '/user/edituser/:objectid',
        name: 'Edit User',
        component: <EditUser/>,
        linkcalss: 'd-none',
      },

    ]
  },
  {
    collapse: true,
    path: "/",
    name: "Media Licenses",
    state: "mediapages",
    iconimg: iconMedia,
    child: [
      {
        path: '/medialicense/libraries',
        name: 'Libraries',
        component: <MediaLibrary/>
      },
      {
        path: '/medialicense/assets',
        name: 'Assets',
        component: <MediaAssets/>,
      },
      // {
      //   path: '/medialicense/assetsfull',
      //   name: 'Assets full load',
      //   component: mediaAssetsFull,
      // },
      {
        path: '/medialicense/bulkimport',
        name: 'Bulk Import',
        component: <BulkImport/>,
      },
      {
        path: '/medialicense/agencysubmissions',
        name: 'Agency Submissions',
        component: <AssetAgency/>,
      },
    ]
  },
  {
    collapse: true,
    path: "/",
    name: "Content Master",
    state: "contentmasterpages",
    iconimg: iconChatKey,
    child: [
      {
        path: 'companies/companies',
        name: 'ALJ Companies',
        component: <Companies/>
      },
      {
        path: 'companies/addcompanies',
        name: 'Add ALJ Companies',
        component: <AddCompanies/>,
        linkcalss: 'd-none',
      },
      {
        path: 'companies/editcompanies/:objectid',
        name: 'Edit ALJ Companies',
        component: <EditCompanies/>,
        linkcalss: 'd-none',
      },

      {
        path: 'business-sector/business-sector',
        name: 'Business Sectors',
        component: <BusinessSector/>
      },
      {
        path: 'business-sector/addbusinesssector',
        name: 'Add Business Sector',
        component: <AddBusinessSector/>,
        linkcalss: 'd-none',
      },
      {
        path: 'business-sector/editbusinesssector/:objectid',
        name: 'Edit Business Sector',
        component: <EditBusinessSector/>,
        linkcalss: 'd-none',
      },

      // {
      //   path: '/business-unit/business-unit',
      //   name: 'Business Units',
      //   component: businessUnit
      // },
      // {
      //   path: '/business-unit/addbusinessunit',
      //   name: 'Add Business Unit',
      //   component: addBusinessUnit,
      //   linkcalss: 'd-none',
      // },
      // {
      //   path: '/business-unit/editbusinessunit/:objectid',
      //   name: 'Edit Business Unit',
      //   component: editBusinessUnit,
      //   linkcalss: 'd-none',
      // },

      {
        path: 'classification/classification',
        name: 'Classifications',
        component: <Classification/>
      },
      {
        path: 'classification/addclassification',
        name: 'Add Classification',
        component: <AddClassification/>,
        linkcalss: 'd-none',
      },
      {
        path: 'classification/editclassification/:objectid',
        name: 'Edit Classification',
        component: <EditClassification/>,
        linkcalss: 'd-none',
      },


      {
        path: 'countries/countries',
        name: 'Countries',
        component: <Countries/>
      },
      {
        path: 'countries/addcountry',
        name: 'Add Country',
        component: <AddCountry/>,
        linkcalss: 'd-none',
      },
      {
        path: 'countries/editcountry/:objectid',
        name: 'Edit Country',
        component: <EditCountry/>,
        linkcalss: 'd-none',
      },

      {
        path: 'domainname/domainname',
        name: 'Domain Name',
        component: <Domainname/>
      },
      {
        path: 'domainname/adddomainname',
        name: 'Add Domain Name',
        component: <AddDomainname/>,
        linkcalss: 'd-none',
      },
      {
        path: 'domainname/editdomainname/:objectid',
        name: 'Edit Domain Name',
        component: <EditDomainname/>,
        linkcalss: 'd-none',
      },


      {
        path: 'domainstatus/domainstatus',
        name: 'Domain Status',
        component: <DomainStatus/>
      },
      {
        path: 'domainstatus/adddomainstatus',
        name: 'Add Domain Status',
        component: <AddDomainStatus/>,
        linkcalss: 'd-none',
      },
      {
        path: 'domainstatus/editdomainstatus/:objectid',
        name: 'Edit Domain Status',
        component: <editDomainStatus/>,
        linkcalss: 'd-none',
      },


      {
        path: 'emailtemplate/emailtemplate',
        name: 'Email Template',
        component: <Emailtemplate/>
      },
      {
        path: 'emailtemplate/addemailtemplate',
        name: 'Add Template',
        component: <AddEmailTemplate/>,
        linkcalss: 'd-none',
      },
      {
        path: 'emailtemplate/editemailtemplate/:objectid',
        name: 'Edit Template',
        component: <EditEmailTemplate/>,
        linkcalss: 'd-none',
      },


      {
        path: 'jurisdiction/jurisdiction',
        name: 'Jurisdictions',
        component: <Jurisdiction/>
      },
      {
        path: 'jurisdiction/addjurisdiction',
        name: 'Add Jurisdiction',
        component: <AddJurisdiction/>,
        linkcalss: 'd-none',
      },
      {
        path: 'jurisdiction/editjurisdiction/:objectid',
        name: 'Edit Jurisdiction',
        component: <EditJurisdiction/>,
        linkcalss: 'd-none',
      },

      {
        path: 'lawfirm/lawfirm',
        name: 'Law Firms',
        component: <Lawfirm/>
      },
      {
        path: 'lawfirm/addlawfirm',
        name: 'Add Law Firm',
        component: <AddLawfirm/>,
        linkcalss: 'd-none',
      },
      {
        path: 'lawfirm/editlawfirm/:objectid',
        name: 'Edit Law Firm',
        component: <EditLawfirm/>,
        linkcalss: 'd-none',
      },

      {
        path: 'masterbranddivision/masterbranddivision',
        name: 'Masterbrand Division',
        component: <Masterbranddivision/>
      },
      {
        path: 'masterbranddivision/addmasterbranddivision',
        name: 'Add Masterbrand Division',
        component: <AddMasterbrandDivision/>,
        linkcalss: 'd-none',
      },
      {
        path: 'masterbranddivision/editmasterbranddivision/:objectid',
        name: 'Edit Masterbrand Division',
        component: <EditMasterbrandDivision/>,
        linkcalss: 'd-none',
      },



      {
        path: 'region/region',
        name: 'Regions',
        component: <Region/>
      },
      {
        path: 'region/addregion',
        name: 'Add Region',
        component: <AddRegion/>,
        linkcalss: 'd-none',
      },
      {
        path: 'region/editregion/:objectid',
        name: 'Edit Region',
        component: <EditRegion/>,
        linkcalss: 'd-none',
      },

      {
        path: 'registrar/registrar',
        name: 'Registrar',
        component: <Registrar/>
      },
      {
        path: 'registrar/addregistrar',
        name: 'Add Registrar',
        component: <AddRegistrar/>,
        linkcalss: 'd-none',
      },
      {
        path: 'registrar/editregistrar/:objectid',
        name: 'Edit Registrar',
        component: <EditRegistrar/>,
        linkcalss: 'd-none',
      },



      {
        path: 'webcategory/webcategory',
        name: 'Web Category',
        component: <Webcategory/>
      },
      {
        path: 'webcategory/addwebcategory',
        name: 'Add Web Category',
        component: <AddWebcategory/>,
        linkcalss: 'd-none',
      },
      {
        path: 'webcategory/editwebcategory/:objectid',
        name: 'Edit Web Category',
        component: <EditWebcategory/>,
        linkcalss: 'd-none',
      },






      // {
      //   path: '/authentication/login',
      //   name: 'login',
      //   icon: 'mdi mdi-account-circle',
      //   linkcalss: 'd-none',
      // },
      // {
      //   path: '/authentication/recover-pwd',
      //   name: 'Forget Password',
      //   icon: 'mdi mdi-account-circle',
      //   linkcalss: 'd-none', 
      // },
      // {
      //   path: '/authentication/splashscreen',
      //   name: 'Splash Screen',
      //   icon: 'mdi mdi-account-circle',
      //   linkcalss: 'd-none', 
      // },
      // {
      //   path: '/authentication/get-pwd',
      //   name: 'Get Password',
      //   icon: 'mdi mdi-account-circle',
      //   linkcalss: 'd-none', 
      // },
      // {
      //   path: '/form-layouts/basic',
      //   name: 'Form Basic',
      //   icon: 'mdi mdi-pencil-box-outline',
      //   component: Basicform
      // },
      // { path: '/form-steps', 
      //   name: 'Form Steps', 
      //   icon: 'mdi mdi-cube-send', 
      //   component: FormSteps 
      // },

      // {
      //   path: '/tables/tablebasic',
      //   name: 'Table',
      //   icon: 'mdi mdi-border-inside',
      //   component: Basictable
      // },


    ]
  },
  {
    collapse: true,
    path: "/",
    name: "Media Content Master",
    state: "mediacontent",
    iconimg: iconChatKey,
    child: [
      {
        path: '/contentmaster/licensetype',
        name: 'License Type',
        component: <LicenseType/>
      },
      {
        path: '/contentmaster/addlicensetype',
        name: 'Add License Type',
        component: <AddLicenseType/>,
        linkcalss: 'd-none',
      },
      {
        path: '/contentmaster/editlicensetype/:objectid',
        name: 'Edit License Type',
        component: <EditLicenseType/>,
        linkcalss: 'd-none',
      },


      {
        path: '/contentmaster/agency',
        name: 'Agency',
        component: <Agency/>
      },
      {
        path: '/contentmaster/addagency',
        name: 'Add Agency',
        component: <AddAgency />,
        linkcalss: 'd-none'
      },
      {
        path: '/contentmaster/editagency/:objectid',
        name: 'Edit Agency',
        component: <EditAgency/>,
        linkcalss: 'd-none',
      },



      {
        path: '/contentmaster/assettype',
        name: 'Assset Type',
        component: <AssetType/>
      },
      {
        path: '/contentmaster/addassettype',
        name: 'Add Assset Type',
        component: <AddAssetType/>,
        linkcalss: 'd-none'
      },
      {
        path: '/contentmaster/editassettype/:objectid',
        name: 'Edit Assset Type',
        component: <EditAssetType/>,
        linkcalss: 'd-none',
      },



      // {
      //   path: '/contentmaster/medialibrary',
      //   name: 'Media Library',
      //   component: mediaUsageLibrary
      // },
      // {
      //   path: '/contentmaster/addmedialibrary',
      //   name: 'Add Media Library',
      //   component: addMediaLibrary,
      //   linkcalss: 'd-none',
      // },
      // {
      //   path: '/contentmaster/editmedialibrary/:objectid',
      //   name: 'Edit Media Library',
      //   component: editMediaLibrary,
      //   linkcalss: 'd-none',
      // },



      {
        path: '/contentmaster/brand',
        name: 'Brand',
        component: <Brand/>
      },
      {
        path: '/contentmaster/addbrand',
        name: 'Add Brand',
        component: <AddBrand/>,
        linkcalss: 'd-none',
      },
      {
        path: '/contentmaster/editbrand/:objectid',
        name: 'Edit Brand',
        component: <EditBrand/>,
        linkcalss: 'd-none',
      },
 


      {
        path: '/contentmaster/georestriction',
        name: 'Geo Restriction',
        component: <GeoRestriction/>
      },
      {
        path: '/contentmaster/addgeorestriction',
        name: 'Add Geo Restriction',
        component: <AddGeoRestriction/>,
        linkcalss: 'd-none',
      },
      {
        path: '/contentmaster/editgeorestriction/:objectid',
        name: 'Edit Geo Restriction',
        component: <EditGeoRestriction/>,
        linkcalss: 'd-none',
      },


      {
        path: '/contentmaster/tags',
        name: 'Tags',
        component: <Tags/>
      },
      {
        path: '/contentmaster/addtag',
        name: 'Add Tag',
        component: <AddTag/>,
        linkcalss: 'd-none',
      },
      {
        path: '/contentmaster/edittag/:objectid',
        name: 'Edit Tag',
        component: <EditTag/>,
        linkcalss: 'd-none',
      },


 
    ]
  },

];
export default ThemeRoutes;
