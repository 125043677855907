import React from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import {BigCalendar, Calendar, momentLocalizer} from "react-big-calendar";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from 'react-router-dom';
import "./calendar.css";

import jQuery from 'jquery';

import { authenticateLoggedIn, addHeaderTitle, addBreadcrumb } from '../../services/parse';
import { getFullPageCount, getSearchResults, getDomain, getDomainFull } from '../../services/domain';
import { isAdmin } from '../../services/user';

// Calendar.setLocalizer(Calendar.momentLocalizer(moment));
const localizer = momentLocalizer(moment);

class calendarview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: null,
      alert: null,
      modal: true,
      editmode:false
    };
    this.hideAlert = this.hideAlert.bind(this);
  }

  componentWillMount = async () => {
    // async componentDidMount(evt) {
    //auth start
    authenticateLoggedIn();
    addHeaderTitle('Calender View');
    addBreadcrumb('Home / Domains / Calender View');

    var isadminval = await isAdmin();
    if (isadminval) {
      var currentrole = isadminval[0].get('role').get('name');
      console.log(currentrole);
      this.setState({ currentRole: currentrole });
      if ( currentrole === "Finance") {
        this.setState({ editmode: false});
        
    
      }
      else
      {
        this.setState({ editmode: true});
        
    
      }
    }

    const events = [];
    var value = await getDomainFull();
    jQuery.each(value, function (key, value) {
      if (value.attributes.expiry_date) {
        var datelocale = value.attributes.expiry_date.toLocaleDateString();
        var cdata = {
          title: "Domain Renewal",
          allDay: true,
          start: new Date(datelocale),
          end: new Date(datelocale),
          color: "default",
          id: value.id,
        }
        events.push(cdata);
      }
    });
    this.setState({ events: events });




    function explode() {
      jQuery('.rbc-btn-group button:first-child').trigger("click");
    }
    setTimeout(explode, 1500);


  }



  alertselectedEvent(event) {
    window.location.assign('#/domain/editdomain/' + event.id + '/default');
    // alert("\n" + event.title + " \n\nStart Time: " + event.start + " \nEnd Time: " + event.end);
  }
  addNewEventAlert(slotInfo) {
    this.setState({
      alert: (
        <div><SweetAlert
          input
          showCancel
          style={{}}
          title="Create New Event"
          placeHolder="Write Event Title"
          onConfirm={e => this.addNewEvent(e, slotInfo)}
          onCancel={() => this.hideAlert()}
          cancelBtnBsStyle="danger"
          confirmBtnBsStyle="info"
        >
        </SweetAlert>

        </div>
      )
    });
  }
  addNewEvent(e, slotInfo) {

    var newEvents = this.state.events;
    newEvents.push({
      title: e,
      start: slotInfo.start,
      end: slotInfo.end
    });

    this.setState({
      alert: null,
      events: newEvents
    });

  }
  hideAlert() {
    this.setState({
      alert: null
    });
  }
  eventColors(event, start, end, isSelected) {
    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor
    };
  }
  render() {


    return (
      <div>
        <Row className="justify-content-end">
          <Col className="col-auto mb-4">
            <Link to="/domain/domain" className="btn btn-primary ">
              <i className="fas fa-list mr-2"></i> List View
            </Link>
          </Col>

          {/* <Col className="col-auto mb-4">
            <Link to="/domain/calendarview" className="btn btn-primary calenderviewclick">
              <span className=" pl-2"><i className="fas fa-calendar-alt mr-2"></i> Calender View</span>
            </Link>
          </Col> */}

         {this.state.editmode?<Col className="col-auto mb-4">
            <Link to="/domain/adddomain" className="btn btn-primary btnaddmew">
              <span className="border-left pl-2">Add new</span>
            </Link>
          </Col>:null}
          
        </Row>


        {this.state.alert}
        <Row>
          <Col xs={12} md={12} className="ml-auto mr-auto">
            <Card className="card-calendar" id="calendercard">
              <CardBody>

                {/* <BigCalendar
                      selectable
                      events={this.state.events}
                      defaultView="month"
                      scrollToTime={new Date(1970, 1, 1, 6)}
                      defaultDate={new Date()}
                      onSelectEvent={event => this.alertselectedEvent(event)}
                      eventPropGetter={this.eventColors}
                    /> */}


                {
                  this.state.events ? 
                    <Calendar
                      localizer={localizer}
                      selectable
                      events={this.state.events}
                      defaultView="month"
                      scrollToTime={new Date(1970, 1, 1, 6)}
                      defaultDate={new Date()}
                      onSelectEvent={event => this.alertselectedEvent(event)}
                      eventPropGetter={this.eventColors}
                    />
                    :
                    ""
                }


              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}
export default calendarview;