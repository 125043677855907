import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormFeedback,
  CustomInput,
  Tooltip,
  UncontrolledTooltip
} from 'reactstrap';
import Select, { components } from "react-select";
import jQuery from 'jquery'

import { getCurrentUser, authenticateLoggedIn, addHeaderTitle, getParameterByName, authenticateLoginpage, addBreadcrumb } from '../../services/parse';
import { updateRecord, checkFieldValid, fetchRecordById, getCountries } from '../../services/lawfirm';
import withRouter from '../../components/common/WithRouter';

var Parse = require('parse');

class editLawfirm extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      collapse: true,
      fadeIn: true,
      timeout: 300,
      isSuper: '',
      addedclass: 'd-none',
      recordByIdData: [],
      countriesData: [],
      relatedObjectsData: [],
      isEdit: false,
      logofile: null,
    };
  }


  async componentDidMount(evt) {
    //auth start
    authenticateLoggedIn();
    addHeaderTitle('Edit Law Firm');
    addBreadcrumb('Home / Law firms / Edit Law Firm');

    var editobjectid = this.props.params.objectid;


    if (editobjectid) {
      fetchRecordById({ objectid: editobjectid }).then((value) => {
        if (value.length > 0) {
          this.setState({ recordByIdData: value[0] });
          if (value[0].get('country')) {
            this.setState({ country: value[0].get('country').id });
          } else {
            this.setState({ country: "" });
          }

          this.setState({ isEdit: true });


          getCountries().then((value) => {
            this.setState({ countriesData: value, });
          });


        } else {
          this.setState({ isEdit: false });
        }
      });

    }



  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }
  fileChangedHandler = (event) => {
    const file = event.target.files[0];
    var parseFile = new Parse.File("logo.png", file);
    this.setState({ logofile: parseFile });

    jQuery('.custom-file-label').html(file['name']);

  }


  updateRecord = () => {

    var valid = checkFieldValid('add');
    if (valid !== false) {
      jQuery('#addbutton').prop('disabled', true);
      var hiddenrecordid = jQuery('#hiddenrecordid').val();

      var name = jQuery('#bname').val();
      var lawfirm_code = jQuery('#lawfirm_code').val();
      var website_url = jQuery('#website_url').val();
      var contact_person = jQuery('#contact_person').val();
      var contact_email = jQuery('#contact_email').val();
      var address = jQuery('#address').val();
      var country = this.state.country; //jQuery('#country').val();
      var status = jQuery('#status').val();

      var fielddatas = {
        edithiddenid: hiddenrecordid,
        name: name,
        lawfirm_code: lawfirm_code,
        website_url: website_url,
        contact_person: contact_person,
        contact_email: contact_email,
        address: address,
        country: country,
        logo: this.state.logofile,
        status: status,
      };

      updateRecord(fielddatas).then((value) => {
        if (value === true) {
          this.setState({ addedclass: 'd-block' });
        } else {
          this.setState({ addedclass: 'd-none' });
        }

        // jQuery('#addform').trigger("reset");
        jQuery('#addbutton').prop('disabled', false);
      });

    }
  }





  render() {
    var countrystate = this.state.countriesData;
    var countrylist = [];
    if (countrystate && countrystate.length > 0) {
      for (let i = 0; i < countrystate.length; i++) {
        countrylist[i] = { value: countrystate[i].id, label: countrystate[i].attributes.name }
      }
    }


    return <div>

      {this.state.isEdit === true ?
        <div>
          <Row className="justify-content-between topbuttonbar">
            <Col className="col-md-5">
              <Alert color="success" className={this.state.addedclass}>
                Record Updated
                  <Link to="/lawfirm/lawfirm" className="float-right">
                  &nbsp; Go to list
                  </Link>
              </Alert>
            </Col>
            <Col className="col-auto mb-4">
              <Link to="/lawfirm/lawfirm" className="btn btn-primary btncancle mr-3">
                <span className="border-left pl-2">Cancel</span>
              </Link>
              <Button type="button" color="primary" id="addbutton" onClick={this.updateRecord}>
                <span className="border-left pl-2"></span>
                Save
              </Button>
            </Col>
          </Row>

          <Row>

            <Col md="5">
              <Card>
                <CardTitle>
                  <i className="icon-circle icon-pencil"></i>
                  Edit Law Firm
                </CardTitle>
                <CardBody>





                  <Form id="addform">
                    <Input type="hidden" id="hiddenrecordid" defaultValue={this.state.recordByIdData.id} />
                    <FormGroup className="form-group">
                      <Label htmlFor="name">Name </Label>
                      <Input type="text" name="bname" id="bname" defaultValue={this.state.recordByIdData.get('name')} />
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Label htmlFor="name">Lawfirm Code</Label>
                      <Input type="text" name="lawfirm_code" id="lawfirm_code" defaultValue={this.state.recordByIdData.get('lawfirmCode')} />
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Label htmlFor="country">Status</Label>
                      <select name="status" id="status" className="form-control" defaultValue={String(this.state.recordByIdData.get('status'))}>
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                      </select>
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Label htmlFor="name">Website url</Label>
                      <Input type="text" name="website_url" id="website_url" defaultValue={this.state.recordByIdData.get('websiteURL')} />
                    </FormGroup>

                    {
                      this.state.recordByIdData.get('logo') !== null ?
                        <FormGroup className="form-group">
                          <Label htmlFor="street" className="mr-2">Current Logo: </Label>
                          <img src={this.state.recordByIdData.get('logo').url()} alt="logo" width="100" />
                        </FormGroup>
                        :
                        null
                    }

                    <FormGroup className="form-group">
                      <Label>Logo </Label>
                      <div className="custom-file">
                        <Input type="file" id="logofile" name="logofile" className="custom-file-input" onChange={this.fileChangedHandler} />
                        <label className="custom-file-label" htmlFor="logofile">Upload your logo here</label>
                      </div>
                    </FormGroup>


                    <FormGroup className="form-group">
                      <Label htmlFor="name">Contact Person</Label>
                      <Input type="text" name="contact_person" id="contact_person" defaultValue={this.state.recordByIdData.get('contact_person')} />
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Label htmlFor="name">Contact email</Label>
                      <Input type="text" name="contact_email" id="contact_email" defaultValue={this.state.recordByIdData.get('contact_email')} />
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Label htmlFor="description">Address</Label>
                      <Input type="textarea" name="address" id="address" rows="5" placeholder="Address" defaultValue={this.state.recordByIdData.get('address')} />
                    </FormGroup>


                    <FormGroup className="form-group">
                      <Label htmlFor="name">Country</Label>

                      <Select
                        id="country"
                        className="reactSelect reactSelectClass"
                        closeMenuOnSelect={true}
                        defaultValue={
                          this.state.recordByIdData.get('country') !== undefined && this.state.recordByIdData.get('country') !== null ?
                            [{ value: this.state.recordByIdData.get('country').id, label: this.state.recordByIdData.get('country').get('name') }]
                            :
                            ""
                        }
                        options={countrylist}
                        selectedOption
                        onChange={(selectedOption) => {
                          this.setState({ country: selectedOption.value });
                        }}
                      />


                      {/* <select id="country" className="custom-select">
                        <option value="">Select</option> 
                        {
                          this.state.recordByIdData.get('country') !== undefined && this.state.recordByIdData.get('country') !== null ?

                            this.state.countriesData.map((dynamicData, key) =>
                              <option selected={this.state.recordByIdData.get('country').id === dynamicData.id} key={key} value={dynamicData.id}>{dynamicData.get('name')}</option>
                            )
                            :
                            this.state.countriesData.map((dynamicData, key) =>
                              <option key={key} value={dynamicData.id}>{dynamicData.get('name')}</option>
                            )
                        } 
                      </select> */}
                    </FormGroup>




                  </Form>
                </CardBody>
              </Card>

            </Col>
          </Row>
        </div>
        :
        <p></p>
      }

    </div>
  }
}

export default withRouter(editLawfirm);
