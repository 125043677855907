import jQuery from 'jquery';
import axios from 'axios';
var Parse = require('parse');

var ip = "";
axios.get('https://geolocation-db.com/json/').then((ipadd) => {
    ip = ipadd.data.IPv4;
});

export const getMediaLicenseLibrary = async () => {
    var MediaLicenseLibrary = Parse.Object.extend("MediaLicenseLibrary");
    var query = new Parse.Query(MediaLicenseLibrary);
    query.equalTo("recordstatus", "active");
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const getLicenseType = async () => {
    var LicenseType = Parse.Object.extend("LicenseType");
    var query = new Parse.Query(LicenseType);
    query.equalTo("status", true);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const getAssetType = async () => {
    var AssetType = Parse.Object.extend("AssetType");
    var query = new Parse.Query(AssetType);
    query.equalTo("status", true);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const getGeoRestriction = async () => {
    var GeoRestriction = Parse.Object.extend("GeoRestriction");
    var query = new Parse.Query(GeoRestriction);
    query.equalTo("status", true);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const getAgency = async () => {
    var Agency = Parse.Object.extend("Agency");
    var query = new Parse.Query(Agency);
    query.equalTo("status", true);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const getBrand = async () => {
    var Brand = Parse.Object.extend("Brand");
    var query = new Parse.Query(Brand);
    query.equalTo("status", true);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const getTags = async () => {
    var Tags = Parse.Object.extend("Tags");
    var query = new Parse.Query(Tags);
    query.equalTo("status", true);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const addTags = async ({ name }) => {
    var obj = new Parse.Object("Tags");
    obj.set("name", name);
    obj.set("status", true);

    return obj.save().then((obj) => {
        return obj;
    }, (error) => {
        return false;
    }).catch(function (error) {
        return false;
    });
}



export const getSearchResults = async (searchdatas) => {
    var MediaLicenseAsset = Parse.Object.extend("MediaLicenseAsset");
    var query = new Parse.Query(MediaLicenseAsset);
    query.descending("createdAt");
    query.equalTo("status", true);

    if (searchdatas['searchbox']) {
        query.matches("name", searchdatas['searchbox'], 'i');
    }

    if (searchdatas['statsrc'] == "true") {
        query.equalTo("status", true);
    } else if (searchdatas['statsrc'] == "false") {
        query.equalTo("status", false);
    }
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });

}

export const getFullPageCount = async (searchval, filterradio) => {
    var MediaLicenseAsset = Parse.Object.extend("MediaLicenseAsset");
    var query = new Parse.Query(MediaLicenseAsset);
    query.equalTo("status", true);
    query.descending("createdAt");

    if (searchval) {
        query.matches("name", searchval, 'i');
    }
    if (filterradio === "recentlyadded") {
        var dateminus = new Date();
        dateminus.setDate(dateminus.getDate() - 10);
        query.greaterThanOrEqualTo('createdAt', dateminus);
    }
    if (filterradio === "archive") {
        query.equalTo("recordstatus", "archive");
    } else {
        query.equalTo("recordstatus", "active");
    }

    const count = await query.count();

    return count;
}







export const getAssetbyLicenseName = async (licensename, searchdatas = null) => {
    const libQuery = new Parse.Query('MediaLicenseLibrary');
    libQuery.equalTo('recordstatus', 'active');



    var MediaLicenseAsset = Parse.Object.extend("MediaLicenseAsset");
    var query = new Parse.Query(MediaLicenseAsset);
    query.equalTo("recordstatus", "active");
    query.include("medialicenselibraryid");
    query.descending('createdAt');
    query.limit(1000)


    if (licensename) {
        query.equalTo("licensetypename", licensename);
    }

    if ((searchdatas === null) || (searchdatas.libraryfilter === "" || searchdatas.libraryfilter.length === 0)) {
        query.matchesQuery('medialicenselibraryid', libQuery);
    }


    if (searchdatas !== null) {
        console.log(searchdatas)


        if (searchdatas.archivefilter === true) {
            query.equalTo("recordstatus", 'archive');
        }

        if (searchdatas.favoritefilter === true) {
            query.equalTo("favorite", true);
        }

        if (searchdatas.keywordfilter !== "") {

            let srcquery1 = new Parse.Query('MediaLicenseAsset');
            srcquery1.matches("title", searchdatas.keywordfilter, 'i');
            let srcquery2 = new Parse.Query('MediaLicenseAsset');
            srcquery2.matches("description", searchdatas.keywordfilter, 'i');
            let srcquery3 = new Parse.Query('MediaLicenseAsset');
            srcquery3.matches("assetid", searchdatas.keywordfilter, 'i');

            query._orQuery([srcquery1, srcquery2, srcquery3]);

        }

        if (searchdatas.expiringinfilter !== "") {
            var todayDate = new Date();
            if (searchdatas.expiringinfilter === "expired") {
                query.lessThan("expiry", todayDate);
            } else if (searchdatas.expiringinfilter === "24h") {
                var myDate = new Date(new Date().getTime() + (1 * 24 * 60 * 60 * 1000));
                query.lessThan("expiry", myDate);
                query.greaterThanOrEqualTo("expiry", todayDate);
            } else if (searchdatas.expiringinfilter === "3d") {
                var myDate = new Date(new Date().getTime() + (3 * 24 * 60 * 60 * 1000));
                query.lessThan("expiry", myDate);
                query.greaterThanOrEqualTo("expiry", todayDate);
            } else if (searchdatas.expiringinfilter === "7d") {
                var myDate = new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000));
                query.lessThan("expiry", myDate);
                query.greaterThanOrEqualTo("expiry", todayDate);
            } else if (searchdatas.expiringinfilter === "15d") {
                var myDate = new Date(new Date().getTime() + (15 * 24 * 60 * 60 * 1000));
                query.lessThan("expiry", myDate);
                query.greaterThanOrEqualTo("expiry", todayDate);
            } else if (searchdatas.expiringinfilter === "30d") {
                var myDate = new Date(new Date().getTime() + (30 * 24 * 60 * 60 * 1000));
                query.lessThan("expiry", myDate);
                query.greaterThanOrEqualTo("expiry", todayDate);
            }
        }


        if (searchdatas.assettypefilter.length > 0) {
            var assettypeidarr = [];
            for (var i = 0; i < searchdatas.assettypefilter.length; i++) {
                assettypeidarr.push({
                    __type: 'Pointer',
                    className: 'AssetType',
                    objectId: searchdatas.assettypefilter[i]
                });
            }
            query.containedIn('assettypeid', assettypeidarr);
        }

        if (searchdatas.libraryfilter.length > 0) {
            var libarr = [];
            for (var i = 0; i < searchdatas.libraryfilter.length; i++) {
                libarr.push({
                    __type: 'Pointer',
                    className: 'MediaLicenseLibrary',
                    objectId: searchdatas.libraryfilter[i]
                });
            }
            query.containedIn('medialicenselibraryid', libarr);
        }

        if (searchdatas.agencyfilter.length > 0) {
            var agencarr = [];
            for (var i = 0; i < searchdatas.agencyfilter.length; i++) {
                agencarr.push({
                    __type: 'Pointer',
                    className: 'Agency',
                    objectId: searchdatas.agencyfilter[i]
                });
            }
            query.containedIn('agencyid', agencarr);
        }




        if (searchdatas.addedonfilter !== "" && searchdatas.addedonfilter !== "all") {
            if (searchdatas.addedonfilter === "24h") {
                var myDate = new Date(new Date().getTime() - (1 * 24 * 60 * 60 * 1000));
                console.log(myDate)
                query.greaterThanOrEqualTo("createdAt", myDate);
            } else if (searchdatas.addedonfilter === "3d") {
                var myDate = new Date(new Date().getTime() - (3 * 24 * 60 * 60 * 1000));
                console.log(myDate)
                query.greaterThanOrEqualTo("createdAt", myDate);
            } else if (searchdatas.addedonfilter === "7d") {
                var myDate = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000));
                console.log(myDate)
                query.greaterThanOrEqualTo("createdAt", myDate);
            } else if (searchdatas.addedonfilter === "15d") {
                var myDate = new Date(new Date().getTime() - (15 * 24 * 60 * 60 * 1000));
                console.log(myDate)
                query.greaterThanOrEqualTo("createdAt", myDate);
            } else if (searchdatas.addedonfilter === "30d") {
                var myDate = new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000));
                console.log(myDate)
                query.greaterThanOrEqualTo("createdAt", myDate);
            }
            else if (searchdatas.addedonfilter === "range") {
                if (searchdatas.addedonfilterstart) {
                    query.greaterThanOrEqualTo("createdAt", searchdatas.addedonfilterstart);
                }
                if (searchdatas.addedonfilterend) {
                    var dateplusone = new Date(searchdatas.addedonfilterend);
                    dateplusone.setDate(dateplusone.getDate() + 1);
                    query.lessThanOrEqualTo("createdAt", dateplusone);
                    // query.lessThanOrEqualTo("createdAt", searchdatas.addedonfilterend);
                }




            }
        }



    }







    var records = await query.find();
    return records;
}










export const getMediaLicenseAssetFull = async () => {
    //var skipnum = pagenum - 1;
    //var perpage = 1;

    var MediaLicenseAsset = Parse.Object.extend("MediaLicenseAsset");
    var query = new Parse.Query(MediaLicenseAsset);
    // query.equalTo("recordstatus", "active");

    query.descending('createdAt');

    //query.skip(skipnum * perpage);
    //query.limit(perpage);

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const getMediaLicenseAsset = async (postperpage, pagenum, searchval, filterradio) => {
    var skipnum = pagenum - 1;
    var perpage = postperpage;

    var MediaLicenseAsset = Parse.Object.extend("MediaLicenseAsset");
    var query = new Parse.Query(MediaLicenseAsset);
    query.equalTo("recordstatus", "active");

    query.descending('createdAt');

    if (searchval) {
        query.matches("name", searchval, 'i');
    }

    if (filterradio === "recentlyadded") {
        var dateminus = new Date();
        dateminus.setDate(dateminus.getDate() - 10);
        query.greaterThanOrEqualTo('createdAt', dateminus);
    }
    if (filterradio === "archive") {
        query.equalTo("recordstatus", "archive");
    } else {
        query.equalTo("recordstatus", "active");
    }


    query.skip(skipnum * perpage);
    query.limit(perpage);

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const hideRecords = async (objectids) => {

    var parseobject = Parse.Object.extend("MediaLicenseAsset");
    var query = new Parse.Query(parseobject);
    query.equalTo("objectId", objectids);
    return query.find().then((object) => {
        var realbject = object[0];
        realbject.set("status", false);
        return realbject.save().then((records) => {
            return true;
        }, (error) => {
            console.log(error);
        }).catch(function (error) {
        });

    }, (error) => {
    });


}


export const addRecord = async (fieldvalues) => {

    var obj = new Parse.Object("MediaLicenseAsset");

    obj.set("version_count", 1);
    obj.set("recordstatus", fieldvalues.recordstatus);
    obj.set("assetimage", fieldvalues.assetimage);


    if (fieldvalues.allowedlicense) {
        var extentObject = Parse.Object.extend("LicenseType");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(fieldvalues.allowedlicense);
        obj.set("licensetype", parsepoint);
        obj.set("licensetypename", parsepoint.get('slug'));
    }


    if (fieldvalues.libraryid) {
        var extentObject = Parse.Object.extend("MediaLicenseLibrary");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(fieldvalues.libraryid);
        obj.set("medialicenselibraryid", parsepoint);
    }

    obj.set("asseturl", fieldvalues.asseturl);
    obj.set("assetid", fieldvalues.assetid);
    obj.set("title", fieldvalues.title);


    if (fieldvalues.typeid) {
        var extentObject = Parse.Object.extend("AssetType");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(fieldvalues.typeid);
        obj.set("assettypeid", parsepoint);
        obj.set("assettypetext", parsepoint.get('name'));
    }


    obj.set("prepetuity", fieldvalues.prepetuity);
    obj.set("expiry", fieldvalues.expiry);





    var restrearray = [];
    for (var i = 0; i < fieldvalues.knownrestrictions.length; ++i) {
        var extentObject = Parse.Object.extend("GeoRestriction");
        var licQuery = new Parse.Query(extentObject);
        let validjuris = await licQuery.get(fieldvalues.knownrestrictions[i]);
        restrearray.push(validjuris);
    }
    // obj.set("allowedlicense", restrearray);
    obj.set("georestriction", restrearray);



    if (fieldvalues.aganecyid) {
        var extentObject = Parse.Object.extend("Agency");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(fieldvalues.aganecyid);
        obj.set("agencyid", parsepoint);
    }


    obj.set("description", fieldvalues.description);
    obj.set("purchasedon", fieldvalues.purchasedon);
    obj.set("contributer", fieldvalues.contributer);
    if (fieldvalues.cost) {
        obj.set("cost", parseFloat(fieldvalues.cost));
    }
    obj.set("accountname", fieldvalues.accountname);


    var brandarray = [];
    for (var i = 0; i < fieldvalues.brandpurchased.length; ++i) {
        var extentObject = Parse.Object.extend("Brand");
        var licQuery = new Parse.Query(extentObject);
        let validjuris = await licQuery.get(fieldvalues.brandpurchased[i]);
        brandarray.push(validjuris);
    }
    obj.set("aljbrand", brandarray);


    var tagsarray = [];
    for (var i = 0; i < fieldvalues.tags.length; ++i) {
        if (fieldvalues.tags[i].__isNew__) {
            var addedTag = await addTags({ name: fieldvalues.tags[i].value })
            var extentObject = Parse.Object.extend("Tags");
            var licQuery = new Parse.Query(extentObject);
            let validjuris = await licQuery.get(addedTag.id);
            tagsarray.push(validjuris);
        } else {
            var extentObject = Parse.Object.extend("Tags");
            var licQuery = new Parse.Query(extentObject);
            let validjuris = await licQuery.get(fieldvalues.tags[i].value);
            tagsarray.push(validjuris);
        }
    }
    obj.set("tags", tagsarray);


    return obj.save().then((obj) => {

        var object = obj;
        var edithiddenid = obj.id;
        var version_count = 1;
        addRecordVersion({ edithiddenid, version_count, fieldvalues, object });
        
        return obj;

    }, (error) => {
        console.log(error)
        return false;
    }).catch(function (error) {
        return false;
    });
}


export const fetchRecordById = async ({ objectid }) => {
    var MediaLicenseAsset = Parse.Object.extend("MediaLicenseAsset");
    var query = new Parse.Query(MediaLicenseAsset);
    query.equalTo("objectId", objectid);
    return query.find().then((records) => {
        return records;
    }, (error) => {
        return false;
    }).catch(function (error) {
        return false;
    });
}


export const updateRecord = async (fieldvalues) => {
    var MediaLicenseAsset = Parse.Object.extend("MediaLicenseAsset");
    var query = new Parse.Query(MediaLicenseAsset);
    query.equalTo("objectId", fieldvalues.objectID);


    return query.find().then(async (object) => {

        var obj = object[0];

        obj.set("version_count", fieldvalues.version_count);
        obj.set("recordstatus", fieldvalues.recordstatus);

        if (fieldvalues.assetimage.length > 0) {
            obj.set("assetimage", fieldvalues.assetimage);
        }



        if (fieldvalues.allowedlicense) {
            var extentObject = Parse.Object.extend("LicenseType");
            var parseqry = new Parse.Query(extentObject);
            let parsepoint = await parseqry.get(fieldvalues.allowedlicense);
            obj.set("licensetype", parsepoint);
            obj.set("licensetypename", parsepoint.get('slug'));
        }


        if (fieldvalues.libraryid) {
            var extentObject = Parse.Object.extend("MediaLicenseLibrary");
            var parseqry = new Parse.Query(extentObject);
            let parsepoint = await parseqry.get(fieldvalues.libraryid);
            obj.set("medialicenselibraryid", parsepoint);
        }

        obj.set("asseturl", fieldvalues.asseturl);
        obj.set("assetid", fieldvalues.assetid);
        obj.set("title", fieldvalues.title);


        if (fieldvalues.typeid) {
            var extentObject = Parse.Object.extend("AssetType");
            var parseqry = new Parse.Query(extentObject);
            let parsepoint = await parseqry.get(fieldvalues.typeid);
            obj.set("assettypeid", parsepoint);
            obj.set("assettypetext", parsepoint.get('name'));
        }


        obj.set("prepetuity", fieldvalues.prepetuity);
        obj.set("expiry", fieldvalues.expiry);





        var restrearray = [];
        for (var i = 0; i < fieldvalues.knownrestrictions.length; ++i) {
            var extentObject = Parse.Object.extend("GeoRestriction");
            var licQuery = new Parse.Query(extentObject);
            let validjuris = await licQuery.get(fieldvalues.knownrestrictions[i]);
            restrearray.push(validjuris);
        }
        // obj.set("allowedlicense", restrearray);
        obj.set("georestriction", restrearray);



        if (fieldvalues.aganecyid) {
            var extentObject = Parse.Object.extend("Agency");
            var parseqry = new Parse.Query(extentObject);
            let parsepoint = await parseqry.get(fieldvalues.aganecyid);
            obj.set("agencyid", parsepoint);
        }


        obj.set("description", fieldvalues.description);
        obj.set("purchasedon", fieldvalues.purchasedon);
        obj.set("contributer", fieldvalues.contributer);
        if (fieldvalues.cost) {
            obj.set("cost", parseFloat(fieldvalues.cost));
        }
        obj.set("accountname", fieldvalues.accountname);


        var brandarray = [];
        for (var i = 0; i < fieldvalues.brandpurchased.length; ++i) {
            var extentObject = Parse.Object.extend("Brand");
            var licQuery = new Parse.Query(extentObject);
            let validjuris = await licQuery.get(fieldvalues.brandpurchased[i]);
            brandarray.push(validjuris);
        }
        obj.set("aljbrand", brandarray);


        var tagsarray = [];
        for (var i = 0; i < fieldvalues.tags.length; ++i) {
            if (fieldvalues.tags[i].__isNew__) {
                var addedTag = await addTags({ name: fieldvalues.tags[i].value })
                var extentObject = Parse.Object.extend("Tags");
                var licQuery = new Parse.Query(extentObject);
                let validjuris = await licQuery.get(addedTag.id);
                tagsarray.push(validjuris);
            } else {
                var extentObject = Parse.Object.extend("Tags");
                var licQuery = new Parse.Query(extentObject);
                let validjuris = await licQuery.get(fieldvalues.tags[i].value);
                tagsarray.push(validjuris);
            }
        }
        obj.set("tags", tagsarray);


        return obj.save().then((records) => {

            var object = records;
            var edithiddenid = fieldvalues.objectID;
            var version_count = fieldvalues.version_count;
            addRecordVersion({ edithiddenid, version_count, fieldvalues, object });

            return records;
        }, (error) => {
            return false;
        }).catch(function (error) {
            return false;
        });

    }, (error) => {
        return false;
    });

}


export const addRecordVersion = async ({ edithiddenid, version_count, fieldvalues, object }) => {

    var obj = new Parse.Object("MediaLicenseAssetVersions");

    obj.set("assetpointerId", edithiddenid);
    obj.set("version_count", version_count);

    obj.set("recordstatus", object.get('recordstatus'));
    obj.set("assetimage", object.get('assetimage'));
    obj.set("licensetype", object.get('licensetype'));
    obj.set("licensetypename", object.get('licensetypename'));
    obj.set("medialicenselibraryid", object.get('medialicenselibraryid'));
    obj.set("asseturl", object.get('asseturl'));
    obj.set("assetid", object.get('assetid'));
    obj.set("title", object.get('title'));
    obj.set("assettypeid", object.get('assettypeid'));
    obj.set("assettypetext", object.get('assettypetext'));
    obj.set("prepetuity", object.get('prepetuity'));
    obj.set("expiry", object.get('expiry'));
    obj.set("georestriction", object.get('georestriction'));
    obj.set("agencyid", object.get('agencyid'));
    obj.set("description", object.get('description'));
    obj.set("purchasedon", object.get('purchasedon'));
    obj.set("contributer", object.get('contributer'));
    obj.set("cost", object.get('cost'));
    obj.set("accountname", object.get('accountname'));
    obj.set("aljbrand", object.get('aljbrand'));
    obj.set("tags", object.get('tags'));
    obj.set("otherlibraryname", object.get('otherlibraryname'));

    return obj.save().then(async (obj) => {
        await addMediaLogs(edithiddenid, obj.id, version_count === 1 ? "add" : "edit");
        return true
    }, (error) => {
        console.log(error)
        return false;
    }).catch(function (error) {
        console.log(error)
        return false;
    });
}


export const deleteRecords = async (objectids) => {

    var parseobject = Parse.Object.extend("MediaLicenseAsset");
    var query = new Parse.Query(parseobject);
    query.equalTo("objectId", objectids);
    var object = await query.find();

    var realbject = object[0];

    realbject.set("recordstatus", "archive");
    var res = await realbject.save()

    await addMediaLogs(objectids, null, "archive");

    return res;

}

export const undoRecord = async (objectids) => {

    var parseobject = Parse.Object.extend("MediaLicenseAsset");
    var query = new Parse.Query(parseobject);
    query.equalTo("objectId", objectids);
    var object = await query.find();

    var realbject = object[0];
    realbject.set("recordstatus", "active");
    var res = await realbject.save()

    await addMediaLogs(res.id, null, "undo");

    return res;
}

export const updateFavorite = async (objectids, val) => {
    var parseobject = Parse.Object.extend("MediaLicenseAsset");
    var query = new Parse.Query(parseobject);
    query.equalTo("objectId", objectids);
    var object = await query.find();

    var realbject = object[0];
    realbject.set("favorite", val);
    var res = await realbject.save()

    return res;
}



export const duplicateRecord = async (objectids, entity) => {
    var titlecopy = "";

    var objectclass = "MediaLicenseAsset";
    if (entity === "import") {
        objectclass = "MediaLicenseAssetImport";
    } else if (entity === "agency") {
        objectclass = "MediaLicenseAssetAgency";
    }else{
        titlecopy = " (copy)";
    }

    var parseobject = Parse.Object.extend(objectclass);
    var query = new Parse.Query(parseobject);
    query.equalTo("objectId", objectids);
    var object = await query.find();
    var realbject = object[0];

    var obj = new Parse.Object("MediaLicenseAsset");
    obj.set("version_count", 1);
    obj.set("recordstatus", realbject.get('recordstatus'));
    obj.set("assetimage", realbject.get('assetimage'));
    obj.set("licensetype", realbject.get('licensetype'));
    obj.set("licensetypename", realbject.get('licensetypename'));
    obj.set("medialicenselibraryid", realbject.get('medialicenselibraryid'));
    obj.set("asseturl", realbject.get('asseturl'));
    obj.set("assetid", realbject.get('assetid'));
    obj.set("title", realbject.get('title') + titlecopy);
    obj.set("assettypeid", realbject.get('assettypeid'));
    obj.set("assettypetext", realbject.get('assettypetext'));
    obj.set("prepetuity", realbject.get('perpetuity'));
    obj.set("expiry", realbject.get('expiry'));
    obj.set("georestriction", realbject.get('georestriction'));
    obj.set("agencyid", realbject.get('agencyid'));
    obj.set("description", realbject.get('description'));
    obj.set("purchasedon", realbject.get('purchasedon'));
    obj.set("contributer", realbject.get('contributor'));
    obj.set("cost", realbject.get('cost'));
    obj.set("accountname", realbject.get('accountname'));
    obj.set("aljbrand", realbject.get('aljbrand'));
    obj.set("favorite", realbject.get('favorite'));
    obj.set("tags", realbject.get('tags'));
    obj.set("otherlibraryname", realbject.get('otherlibraryname'));

    var res = await obj.save();
    if (res) {
        var edithiddenid = res.id;
        addDuplicateVersion({ edithiddenid, res });
    }

    return res;
}


export const addDuplicateVersion = async ({ edithiddenid, res }) => {
    var obj = new Parse.Object("MediaLicenseAssetVersions");
    obj.set("assetpointerId", edithiddenid);
    obj.set("version_count", 1);
    obj.set("recordstatus", res.get('recordstatus'));
    obj.set("assetimage", res.get('assetimage'));
    obj.set("licensetype", res.get('licensetype'));
    obj.set("licensetypename", res.get('licensetypename'));
    obj.set("medialicenselibraryid", res.get('medialicenselibraryid'));
    obj.set("asseturl", res.get('asseturl'));
    obj.set("assetid", res.get('assetid'));
    obj.set("title", res.get('title'));
    obj.set("assettypeid", res.get('assettypeid'));
    obj.set("assettypetext", res.get('assettypetext'));
    obj.set("prepetuity", res.get('prepetuity'));
    obj.set("expiry", res.get('expiry'));
    obj.set("georestriction", res.get('georestriction'));
    obj.set("agencyid", res.get('agencyid'));
    obj.set("description", res.get('description'));
    obj.set("purchasedon", res.get('purchasedon'));
    obj.set("contributer", res.get('contributer'));
    obj.set("cost", res.get('cost'));
    obj.set("accountname", res.get('accountname'));
    obj.set("aljbrand", res.get('aljbrand'));
    obj.set("tags", res.get('tags'));
    obj.set("otherlibraryname", res.get('otherlibraryname'));

    var res = await obj.save();
    if (res.id) {
        await addMediaLogs(edithiddenid, res.id, "add");
    }
    return true;
}



export const addMediaLogs = async (asset, assetversion, action) => {
    try {
        if (ip === "") {
            ip = await axios.get('https://geolocation-db.com/json/');
            ip = ip.data.IPv4;
        }

        var curruser = Parse.User.current();
        Parse.Cloud.run('AddMediaLogs', {
            user: curruser.id,
            ipaddress: ip ? ip : "",
            asset: asset,
            assetversion: assetversion,
            action: action,
        }).then(function (result) {
            console.log(result);
        });
    } catch (ex) {
        console.log('log', ex);
    }
}

export const checkFieldValid = (addoredit, statevalues) => {

    var errstat = true;

    if (statevalues.libraryid === "") {
        jQuery('#libraryid').addClass("is-invalid");
        errstat = false;
    } else {
        jQuery('#libraryid').removeClass("is-invalid");
    }

    if (statevalues.title === "") {
        jQuery('#title').addClass("is-invalid");
        errstat = false;
    } else {
        jQuery('#title').removeClass("is-invalid");
    }

    if (statevalues.typeid === "") {
        jQuery('#assettype').addClass("is-invalid");
        errstat = false;
    } else {
        jQuery('#assettype').removeClass("is-invalid");
    }


    if (addoredit === "edit") {
        if (statevalues.tempImageView === null) {
            jQuery('.libraryimgwrap').addClass("is-invalid");
            errstat = false;
        } else {
            jQuery('.libraryimgwrap').removeClass("is-invalid");
        }
    } else {
        if (statevalues.mainImagefile.length === 0) {
            jQuery('.libraryimgwrap').addClass("is-invalid");
            errstat = false;
        } else {
            jQuery('.libraryimgwrap').removeClass("is-invalid");
        }
    }





    if (statevalues.allowedlicense.length === 0) {
        jQuery('.licenseitems').addClass("is-invalid");
        errstat = false;
    } else {
        jQuery('.licenseitems').removeClass("is-invalid");
    }

    if (statevalues.prepetuity === false && statevalues.expiry === null) {
        jQuery('#expiry_date').addClass("is-invalid");
        errstat = false;
    } else {
        jQuery('#expiry_date').removeClass("is-invalid");
    }




    if (addoredit === "add") {

    }


    if (errstat === false) {
        return false;
    } else {
        return true;
    }

}

