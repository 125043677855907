import Dashboard from '../views/dashboard/dashboard.jsx';
import AuthRoutes from './authroutes.jsx';
import Basicform from '../views/form-layouts/basic.jsx';
import FormSteps from '../views/steps/steps.jsx';
import Basictable from '../views/tables/tablebasic.jsx';

import user from '../views/user/user.jsx';
import addUser from '../views/user/addUser.jsx';
import editUser from '../views/user/editUser.jsx';

import businessUnit from '../views/businessUnit/businessUnit.jsx';
import addBusinessUnit from '../views/businessUnit/addBusinessUnit.jsx';
import editBusinessUnit from '../views/businessUnit/editBusinessUnit.jsx';

import emailtemplate from '../views/emailTemplate/emailTemplate.jsx';
import addEmailTemplate from '../views/emailTemplate/addEmailTemplate.jsx';
import editEmailTemplate from '../views/emailTemplate/editEmailTemplate.jsx';

import businessSector from '../views/businessSector/businessSector.jsx';
import addBusinessSector from '../views/businessSector/addBusinessSector.jsx';
import editBusinessSector from '../views/businessSector/editBusinessSector.jsx';

import jurisdiction from '../views/jurisdiction/jurisdiction.jsx';
import addJurisdiction from '../views/jurisdiction/addJurisdiction.jsx';
import editJurisdiction from '../views/jurisdiction/editJurisdiction.jsx';

import region from '../views/region/region.jsx';
import addRegion from '../views/region/addRegion.jsx';
import editRegion from '../views/region/editRegion.jsx';

import domainname from '../views/domainname/domainname.jsx';
import addDomainname from '../views/domainname/addDomainname.jsx';
import editDomainname from '../views/domainname/editDomainname.jsx';

import webcategory from '../views/webcategory/webcategory.jsx';
import addWebcategory from '../views/webcategory/addWebcategory.jsx';
import editWebcategory from '../views/webcategory/editWebcategory.jsx';

import registrar from '../views/registrar/registrar.jsx';
import addRegistrar from '../views/registrar/addRegistrar.jsx';
import editRegistrar from '../views/registrar/editRegistrar.jsx';

import countries from '../views/countries/countries.jsx';
import addCountry from '../views/countries/addCountry.jsx';
import editCountry from '../views/countries/editCountry.jsx';

import masterbranddivision from '../views/masterbranddivision/masterbrandDivision.jsx';
import addMasterbrandDivision from '../views/masterbranddivision/addMasterbrandDivision.jsx';
import editMasterbrandDivision from '../views/masterbranddivision/editMasterbrandDivision.jsx';

import classification from '../views/classification/classification.jsx';
import addClassification from '../views/classification/addClassification.jsx';
import editClassification from '../views/classification/editClassification.jsx';

import companies from '../views/companies/companies.jsx';
import addCompanies from '../views/companies/addCompanies.jsx';
import editCompanies from '../views/companies/editCompanies.jsx';

import lawfirm from '../views/lawfirm/lawfirm.jsx';
import addLawfirm from '../views/lawfirm/addLawfirm.jsx';
import editLawfirm from '../views/lawfirm/editLawfirm.jsx';

import domainStatus from '../views/domainstatus/domainStatus.jsx';
import addDomainStatus from '../views/domainstatus/addDomainStatus.jsx';
import editDomainStatus from '../views/domainstatus/editDomainStatus.jsx';

import domain from '../views/domain/domain.jsx';
import calendarview from '../views/domain/calendarview.jsx';
import addDomain from '../views/domain/addDomain.jsx';
import editDomain from '../views/domain/editDomain.jsx';

//imports for trademark pages
import viewAllTrademarks from '../views/trademark/viewAllTrademarks.jsx';
import tabContainer from '../views/trademark/tabContainer.jsx';
import finance from '../views/finance/finance.jsx';
import addFinance from '../views/finance/addFinance.jsx';
import editFinance from '../views/finance/editFinance.jsx';
import importComp from '../views/trademark/import';
 

import icnonDashboard from '../assets/images/icons/icon-dashboard.png';
import iconTrademark from '../assets/images/icons/icon-trademark.png';
import iconGlobe from '../assets/images/icons/icon-globe.png';
import iconUser from '../assets/images/icons/icon-user.png';
import iconFinaince from '../assets/images/icons/icon-finaince.png';
import iconChatKey from '../assets/images/icons/icon-chat-key.png';



var auths = [].concat(AuthRoutes);

var ThemeRouteCommercial = [
  // {
  //   navlabel: true,
  //   name: "",
  //   iconimg: icnonDashboard
  // },
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'mdi mdi-account-circle',
    iconimg: icnonDashboard,
    component: Dashboard
  },
  {
    collapse: true,
    path: "/trademark",
    name: "Trademarks",
    state: "dashboardpages",
    iconimg: iconTrademark,
    child: [
      {
        path: '/trademark/list',
        name: 'View All',
        component: viewAllTrademarks
      },
      {
        path: '/trademark/addtrademark',
        name: 'Add New',
        component: tabContainer,
      },
      {
        path: '/trademark/edittrademark/:id/:save',
        name: 'Edit request',
        component: tabContainer,
        linkcalss: 'd-none',
      },
      {
        path: '/trademark/edittrademark/:id',
        name: 'Edit request',
        component: tabContainer,
        linkcalss: 'd-none',
      },
      {
        path: '/trademark/import',
        name: ' request',
        component: importComp,
        linkcalss: 'd-none',
      }
    ]
  },




  {
    collapse: true,
    path: "/domainname",
    name: "Domains",
    state: "domainnamepages",
    iconimg: iconGlobe,
    child: [
      {
        path: '/domain/domain',
        name: 'View All',
        component: domain
      },
      {
        path: '/domain/calendarview',
        name: 'Calendar View',
        component: calendarview,
        // linkcalss: 'd-none',
      },
      {
        path: '/domain/adddomain',
        name: 'Add Domain',
        component: addDomain,
        // linkcalss: 'd-none',
      },
      {
        path: '/domain/editdomain/:objectid/:version',
        name: 'Edit Domain',
        component: editDomain,
        linkcalss: 'd-none',
      },
    ]
  },
  {
    collapse: true,
    path: "/finance",
    name: "Finance",
    state: "financepages",
    iconimg: iconFinaince,
    child: [
      {
        path: '/finance/finance/:domainid',
        name: 'View All',
        component: finance
      },
      {
        path: '/finance/addfinance/:domainid',
        name: 'Add Finance',
        component: addFinance,
        linkcalss: 'd-none',
      },
      {
        path: '/finance/editfinance/:objectid/:version',
        name: 'Edit Finance',
        component: editFinance,
        linkcalss: 'd-none',
      },
    ]
  },
  {
    collapse: true,
    path: "/contentmaster",
    name: "Content Master",
    state: "contentmasterpages",
    iconimg: iconChatKey,
    child: [
      {
        path: '/companies/companies',
        name: 'ALJ Companies',
        component: companies
      },
      {
        path: '/companies/addcompanies',
        name: 'Add ALJ Companies',
        component: addCompanies,
        linkcalss: 'd-none',
      },
      {
        path: '/companies/editcompanies/:objectid',
        name: 'Edit ALJ Companies',
        component: editCompanies,
        linkcalss: 'd-none',
      },

      {
        path: '/business-sector/business-sector',
        name: 'Business Sectors',
        component: businessSector
      },
      {
        path: '/business-sector/addbusinesssector',
        name: 'Add Business Sector',
        component: addBusinessSector,
        linkcalss: 'd-none',
      },
      {
        path: '/business-sector/editbusinesssector/:objectid',
        name: 'Edit Business Sector',
        component: editBusinessSector,
        linkcalss: 'd-none',
      },

      // {
      //   path: '/business-unit/business-unit',
      //   name: 'Business Units',
      //   component: businessUnit
      // },
      // {
      //   path: '/business-unit/addbusinessunit',
      //   name: 'Add Business Unit',
      //   component: addBusinessUnit,
      //   linkcalss: 'd-none',
      // },
      // {
      //   path: '/business-unit/editbusinessunit/:objectid',
      //   name: 'Edit Business Unit',
      //   component: editBusinessUnit,
      //   linkcalss: 'd-none',
      // },

      {
        path: '/classification/classification',
        name: 'Classifications',
        component: classification
      },
      {
        path: '/classification/addclassification',
        name: 'Add Classification',
        component: addClassification,
        linkcalss: 'd-none',
      },
      {
        path: '/classification/editclassification/:objectid',
        name: 'Edit Classification',
        component: editClassification,
        linkcalss: 'd-none',
      },
      

      {
        path: '/countries/countries',
        name: 'Countries',
        component: countries
      },
      {
        path: '/countries/addcountry',
        name: 'Add Country',
        component: addCountry,
        linkcalss: 'd-none',
      },
      {
        path: '/countries/editcountry/:objectid',
        name: 'Edit Country',
        component: editCountry,
        linkcalss: 'd-none',
      },

      {
        path: '/domainname/domainname',
        name: 'Domain Name',
        component: domainname
      },
      {
        path: '/domainname/adddomainname',
        name: 'Add Domain Name',
        component: addDomainname,
        linkcalss: 'd-none',
      },
      {
        path: '/domainname/editdomainname/:objectid',
        name: 'Edit Domain Name',
        component: editDomainname,
        linkcalss: 'd-none',
      },


      {
        path: '/domainstatus/domainstatus',
        name: 'Domain Status',
        component: domainStatus
      },
      {
        path: '/domainstatus/adddomainstatus',
        name: 'Add Domain Status',
        component: addDomainStatus,
        linkcalss: 'd-none',
      },
      {
        path: '/domainstatus/editdomainstatus/:objectid',
        name: 'Edit Domain Status',
        component: editDomainStatus,
        linkcalss: 'd-none',
      },



      {
        path: '/emailtemplate/emailtemplate',
        name: 'Email Template',
        component: emailtemplate
      },
      {
        path: '/emailtemplate/addemailtemplate',
        name: 'Add Template',
        component: addEmailTemplate,
        linkcalss: 'd-none',
      },
      {
        path: '/emailtemplate/editemailtemplate/:objectid',
        name: 'Edit Template',
        component: editEmailTemplate,
        linkcalss: 'd-none',
      },

      


      {
        path: '/jurisdiction/jurisdiction',
        name: 'Jurisdictions',
        component: jurisdiction
      },
      {
        path: '/jurisdiction/addjurisdiction',
        name: 'Add Jurisdiction',
        component: addJurisdiction,
        linkcalss: 'd-none',
      },
      {
        path: '/jurisdiction/editjurisdiction/:objectid',
        name: 'Edit Jurisdiction',
        component: editJurisdiction,
        linkcalss: 'd-none',
      },

      {
        path: '/lawfirm/lawfirm',
        name: 'Law Firms',
        component: lawfirm
      },
      {
        path: '/lawfirm/addlawfirm',
        name: 'Add Law Firm',
        component: addLawfirm,
        linkcalss: 'd-none',
      },
      {
        path: '/lawfirm/editlawfirm/:objectid',
        name: 'Edit Law Firm',
        component: editLawfirm,
        linkcalss: 'd-none',
      },

      {
        path: '/masterbranddivision/masterbranddivision',
        name: 'Masterbrand Division',
        component: masterbranddivision
      },
      {
        path: '/masterbranddivision/addmasterbranddivision',
        name: 'Add Masterbrand Division',
        component: addMasterbrandDivision,
        linkcalss: 'd-none',
      },
      {
        path: '/masterbranddivision/editmasterbranddivision/:objectid',
        name: 'Edit Masterbrand Division',
        component: editMasterbrandDivision,
        linkcalss: 'd-none',
      },

      

      {
        path: '/region/region',
        name: 'Regions',
        component: region
      },
      {
        path: '/region/addregion',
        name: 'Add Region',
        component: addRegion,
        linkcalss: 'd-none',
      },
      {
        path: '/region/editregion/:objectid',
        name: 'Edit Region',
        component: editRegion,
        linkcalss: 'd-none',
      },

      {
        path: '/registrar/registrar',
        name: 'Registrar',
        component: registrar
      },
      {
        path: '/registrar/addregistrar',
        name: 'Add Registrar',
        component: addRegistrar,
        linkcalss: 'd-none',
      },
      {
        path: '/registrar/editregistrar/:objectid',
        name: 'Edit Registrar',
        component: editRegistrar,
        linkcalss: 'd-none',
      },
 


      {
        path: '/webcategory/webcategory',
        name: 'Web Category',
        component: webcategory
      },
      {
        path: '/webcategory/addwebcategory',
        name: 'Add Web Category',
        component: addWebcategory,
        linkcalss: 'd-none',
      },
      {
        path: '/webcategory/editwebcategory/:objectid',
        name: 'Edit Web Category',
        component: editWebcategory,
        linkcalss: 'd-none',
      }, 

      
      
    ]
  },
   


];
export default ThemeRouteCommercial;
