import React from 'react';
import { Link } from 'react-router-dom';
import { Input, Button, Label, } from 'reactstrap';
import jQuery from 'jquery';
import Papa from 'papaparse';

import { authenticateLoggedIn, addHeaderTitle, addBreadcrumb } from '../../services/parse';
import { getTrademarksForExport } from '../../services/trademark';
import Loader from '../../components/common/Loader';


class exportTrademarks extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      loader: true,
      showfieldItems: [],
      fieldItems: [],
      trademarkData: [],
    };
  }


  async componentDidMount(evt) {

    authenticateLoggedIn();
    addHeaderTitle('Export Trademarks');
    addBreadcrumb('Home / Trademarks / Export');

    var showfieldItems = {
      name: true,
      type: true,
      language: true,
      jurisdictions: true,
      countries: true,
      lawFirm: true,
      transferToAGIP: true,
      twRefNumber: true,
      AGIPRefNumber: true,
      step: true,
      stepStatus: true,
      owner: true,
      trademarkClasses: true,
      applicationNumber: true,
      applicationDate: true,
      registrationNumber: true,
      registrationDate: true,
      nextRenewalDate: true,
      usedDate: true,
      masterbrand: true,
      logoFiles: true,
      contact: true,
      notes: true,
      recordstatus: true
    }
    this.setState({ showfieldItems: showfieldItems });




    var fieldItems = [
      { label: "Name", name: "name" },
      { label: "Type", name: "type" },
      { label: "Language", name: "language" },
      { label: "Jurisdictions", name: "jurisdictions" },
      { label: "Countries", name: "countries" },
      { label: "Law Firm", name: "lawFirm" },
      { label: "Transfer to AGIP", name: "transferToAGIP" },
      { label: "TW Ref #", name: "twRefNumber" },
      { label: "AGIP Ref #", name: "AGIPRefNumber" },
      { label: "Step", name: "step" },
      { label: "Step Status", name: "stepStatus" },
      { label: "Owner", name: "owner" },
      { label: "Classes", name: "trademarkClasses" },
      { label: "Application Number", name: "applicationNumber" },
      { label: "Application Date", name: "applicationDate" },
      { label: "Registration Number", name: "registrationNumber" },
      { label: "Registration Date", name: "registrationDate" },
      { label: "Expiry Date", name: "nextRenewalDate" },
      { label: "Used Date", name: "usedDate" },
      { label: "Masterbrand", name: "masterbrand" },
      { label: "Has Attachments ?", name: "logoFiles" },
      { label: "Contact", name: "contact" },
      { label: "Notes", name: "notes" },
      { label: "Status", name: "recordstatus" }
    ];
    this.setState({ fieldItems: fieldItems });


    var alltrademarks = await getTrademarksForExport();
    this.setState({ trademarkData: alltrademarks });

    this.setState({ loader: false });

  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }



  manageColumnsToShow = async (event) => {
    var checkval = event.target.value;
    var checkstat = event.target.checked;


    console.log(this.state.showfieldItems)

    this.setState({
      showfieldItems: {
        ...this.state.showfieldItems,
        [checkval]: checkstat
      },
    });

  }


  exportCSVData = async (event) => {

    const fields = [
      this.state.showfieldItems.name === true ? "Name" : "***",
      this.state.showfieldItems.type === true ? "Type" : "***",
      this.state.showfieldItems.language === true ? "Language" : "***",
      this.state.showfieldItems.jurisdictions === true ? "Jurisdictions" : "***",
      this.state.showfieldItems.countries === true ? "Countries" : "***",
      this.state.showfieldItems.lawFirm === true ? "Law Firm" : "***",
      this.state.showfieldItems.transferToAGIP === true ? "Transfer to AGIP" : "***",
      this.state.showfieldItems.twRefNumber === true ? "TW Ref #" : "***",
      this.state.showfieldItems.AGIPRefNumber === true ? "AGIP Ref #" : "***",
      this.state.showfieldItems.step === true ? "Step" : "***",
      this.state.showfieldItems.stepStatus === true ? "Step Status" : "***",
      this.state.showfieldItems.owner === true ? "Owner" : "***",
      this.state.showfieldItems.trademarkClasses === true ? "Classes" : "***",
      this.state.showfieldItems.applicationNumber === true ? "Application Number" : "***",
      this.state.showfieldItems.applicationDate === true ? "Application Date" : "***",
      this.state.showfieldItems.registrationNumber === true ? "Registration Number" : "***",
      this.state.showfieldItems.registrationDate === true ? "Registration Date" : "***",
      this.state.showfieldItems.nextRenewalDate === true ? "Expiry Date" : "***",
      this.state.showfieldItems.usedDate === true ? "Used Date" : "***",
      this.state.showfieldItems.masterbrand === true ? "Masterbrand" : "***",
      this.state.showfieldItems.logoFiles === true ? "Has Attachments ?" : "***",
      this.state.showfieldItems.contact === true ? "Contact" : "***",
      this.state.showfieldItems.notes === true ? "Notes" : "***",
      this.state.showfieldItems.recordstatus === true ? "Status" : "***"
    ];

    const data = this.state.trademarkData.map(obj => [

      this.state.showfieldItems.name === true ?
        obj.get('name')
        : "***",
      this.state.showfieldItems.type === true ?
        obj.get('type') ?
          obj.get('type').map((typeData, tkey) =>
            typeData
          )
          :
          ""
        : "***",
      this.state.showfieldItems.language === true ?
        obj.get('language')
        : "***",
      this.state.showfieldItems.jurisdictions === true ?
        obj.get('jurisdictions') ?
          obj.get('jurisdictions').map((classData, tkey) =>
            classData.get('name')
          )
          :
          ""
        : "***",
      this.state.showfieldItems.countries === true ?
        obj.get('countries') ?
          obj.get('countries').map((classData, tkey) =>
            classData.get('name')
          )
          :
          ""
        : "***",
      this.state.showfieldItems.lawFirm === true ?
        obj.get('lawFirm') ?
          obj.get('lawFirm').get('name')
          :
          ""
        : "***",
      this.state.showfieldItems.transferToAGIP === true ?
        obj.get('transferToAGIP')
        : "***",
      this.state.showfieldItems.twRefNumber === true ?
        obj.get('twRefNumber')
        : "***",
      this.state.showfieldItems.AGIPRefNumber === true ?
        obj.get('AGIPRefNumber')
        : "***",
      this.state.showfieldItems.step === true ?
        obj.get('step') ?
          obj.get('step').get('name')
          :
          ""
        : "***",
      this.state.showfieldItems.stepStatus === true ?
        obj.get('stepStatus')
        : "***",
      this.state.showfieldItems.owner === true ?
        obj.get('owner') ?
          obj.get('owner').get('name')
          :
          ""
        : "***",
      this.state.showfieldItems.trademarkClasses === true ?
        obj.get('trademarkClasses') ?
          obj.get('trademarkClasses').map((classData, classkey) =>
            classData.get('name')
          )
          :
          ""
        : "***",
      this.state.showfieldItems.applicationNumber === true ?
        obj.get('applicationNumber')
        : "***",
      this.state.showfieldItems.applicationDate === true ?
        obj.get('applicationDate') ?
          obj.get('applicationDate').toLocaleDateString("en-GB", { year: "numeric", month: "2-digit", day: "2-digit" })
          :
          ""
        : "***",
      this.state.showfieldItems.registrationNumber === true ?
        obj.get('registrationNumber')
        : "***",
      this.state.showfieldItems.registrationDate === true ?
        obj.get('registrationDate') ?
          obj.get('registrationDate').toLocaleDateString("en-GB", { year: "numeric", month: "2-digit", day: "2-digit" })
          :
          ""
        : "***",
      this.state.showfieldItems.nextRenewalDate === true ?
        obj.get('nextRenewalDate') ?
          obj.get('nextRenewalDate').toLocaleDateString("en-GB", { year: "numeric", month: "2-digit", day: "2-digit" })
          :
          ""
        : "***",
      this.state.showfieldItems.usedDate === true ?
        obj.get('usedDate') ?
          obj.get('usedDate').toLocaleDateString("en-GB", { year: "numeric", month: "2-digit", day: "2-digit" })
          :
          ""
        : "***",
      this.state.showfieldItems.masterbrand === true ?
        obj.get('masterbrand') ?
          obj.get('masterbrand').toString()
          :
          ""
        : "***",
      this.state.showfieldItems.logoFiles === true ?
        obj.get('logoFiles') && obj.get('logoFiles').length > 0 ?
          "true"
          :
          "false"
        : "***",
      this.state.showfieldItems.contact === true ?
        obj.get('contact')
        : "***",
      this.state.showfieldItems.notes === true ?
        obj.get('notes')
        : "***",
      this.state.showfieldItems.recordstatus === true ?
        obj.get('recordstatus')
        : "***"

    ]);



    const filteredData = data.map(item => {
      return item.filter(val => val !== '***')
    });
    const filteredFields = fields.filter(field => field !== '***');

    const csv = Papa.unparse({
      data: filteredData,
      fields: filteredFields
    });

    const blob = new Blob([csv]);
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob, { type: 'text/csv' });
    a.download = 'exportdata.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }




  render() {

    return <div>

      <div className="customcheck greybgcheck" id="showexportcheckgroup">


        {
          this.state.fieldItems ?
            this.state.fieldItems.map((dynamicData, key) => {
              return (
                <div className="form-check form-check-inline">
                  <Input type="checkbox" value={dynamicData.name} id={dynamicData.name} name="showitems" onClick={this.manageColumnsToShow} defaultChecked />
                  <Label for={dynamicData.name}>{dynamicData.label}</Label>
                </div>
              )
            })
            :
            null
        }
      </div>

        <div className="text-center">
        {
          this.state.loader === true ?
            <div className="btn btn-primary" id="loadbutton">
              <Loader type="Oval" color="#414241" height={22} width={100} />
            </div>
            :
            <Button type="button" color="primary" id="addbutton" onClick={this.exportCSVData}>
              <span className="border-left pl-2"></span>
              Export CSV
            </Button>
        }
      </div>


    </div>
  }
}

export default exportTrademarks;
