import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormFeedback,
  CustomInput,
  Tooltip,
  UncontrolledTooltip
} from 'reactstrap';
import Select, { components } from "react-select";
import jQuery from 'jquery'

import { authenticateLoggedIn, addHeaderTitle, addBreadcrumb } from '../../../services/parse';
import { updateRecord, checkFieldValid, fetchRecordById } from '../../../services/assettype';

import { uploadFile,uploadFileToAWSCustom, getFileUrl } from '../../../services/awsFileUpload';
import { LOGO_UPLOAD_SIZE } from '../../../config/constant';
import withRouter from '../../../components/common/WithRouter';
var Parse = require('parse');

class EditAssetType extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      collapse: true,
      fadeIn: true,
      timeout: 300,
      isSuper: '',
      addedclass: 'd-none',
      recordByIdData: [],
      isEdit: false,
      prevlogofile: null,
      logofile: [],
      logoFileArray: [],
    };
  }


  async componentDidMount(evt) {
    //auth start
    authenticateLoggedIn();
    addHeaderTitle('Edit Asset Type');
    addBreadcrumb('Home / Asset Type / Edit Asset Type');

    // var editobjectid = getParameterByName('objectid');
    var editobjectid = this.props.params.objectid;

    if (editobjectid) {

      fetchRecordById({ objectid: editobjectid }).then(async (value) => {
        if (value.length > 0) {
          this.setState({ recordByIdData: value[0] });


          if (value[0].get('icon') && value[0].get('icon').length > 0) {
            var res = await getFileUrl("contentmaster", value[0].get('icon')[0].awsFileName);
            this.setState({ prevlogofile: res.url });
          }

          this.setState({ isEdit: true });


        } else {
          this.setState({ isEdit: false });
        }
      });

    }

  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }


  uploadFileSelected = async (event) => {
    var files = event
    var itemCounts = files.length;
    console.log('itemCounts', itemCounts);
    var itemsToAdd = [];
    var itemsAdded = [];
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].size < LOGO_UPLOAD_SIZE) {
          var fileType = files[i].type.split("/")[1];
          var fileName = files[i].name;
          var fileSize = (files[i].size / 1000000).toFixed(2);

          var obj = { awsFileName: null, type: fileType, name: fileName, id: itemCounts, size: fileSize }
          itemsToAdd.push(obj);
          var uploadeditem = await uploadFileToAWSCustom(files[i], obj, "contentmaster");
          itemsAdded.push(uploadeditem);
          itemCounts++;
        }
        else {
          console.log('File ' + files[i].name + ' exceeds maximum file size of 30MB')
        }
      }
      if (itemsAdded.length > 0) {
        this.setState({
          logoFileArray: itemsAdded
        });
      }
    }
  }


  fileChangedHandler = (event) => {
    const file = event.target.files[0];
    console.log(file)
    if (file) {
      this.setState({ logofile: event.target.files });

      jQuery('.custom-file-label').html(file['name']);
    }
  }


  updateRecord = async () => {

    var valid = checkFieldValid('edit');
    if (valid !== false) {
      jQuery('#addbutton').prop('disabled', true);

      if (this.state.logofile.length > 0) {
        await this.uploadFileSelected(this.state.logofile)
      }

      var hiddenrecordid = jQuery('#hiddenrecordid').val();

      var name = jQuery('#name').val();
      var description = jQuery('#description').val();
      var status = jQuery('#status').val();

      updateRecord({ edithiddenid: hiddenrecordid, name: name, icon: this.state.logoFileArray, description: description, status: status }).then((value) => {
        if (value === true) {
          this.setState({ addedclass: 'd-block' });
        } else {
          this.setState({ addedclass: 'd-none' });
        }

        jQuery('#addform').trigger("reset");
        jQuery('#addbutton').prop('disabled', false);


      });

    }
  }





  render() {
    return <div>

      {this.state.isEdit === true ?
        <div>
          <Row className="justify-content-between topbuttonbar">
            <Col className="col-md-5">
              <Alert color="success" className={this.state.addedclass}>
                Record Updated
                <Link to="/contentmaster/assettype/" className="float-right">
                  &nbsp; Go to list
                </Link>
              </Alert>
            </Col>
            <Col className="col-auto mb-4">
              <Link to="/contentmaster/assettype/" className="btn btn-primary btncancle mr-3">
                <span className="border-left pl-2">Cancel</span>
              </Link>
              <Button type="button" color="primary" id="addbutton" onClick={this.updateRecord}>
                <span className="border-left pl-2"></span>
                Save
              </Button>
            </Col>
          </Row>
          <Row>

            <Col md="5">
              <Card>
                <CardTitle>
                  <i className="icon-circle icon-pencil"></i>
                  Edit Asset Type
                </CardTitle>
                <CardBody>





                  <Form id="addform">
                    <Input type="hidden" id="hiddenrecordid" defaultValue={this.state.recordByIdData.id} />
                    <FormGroup className="form-group">
                      <Label htmlFor="name">Name *</Label>
                      <Input type="text" name="name" id="name" defaultValue={this.state.recordByIdData.get('name')} />
                    </FormGroup>

                    {
                      this.state.recordByIdData.get('icon') && this.state.recordByIdData.get('icon').length > 0 ?
                        <FormGroup className="form-group">
                          <Label htmlFor="street" className="mr-2">Current Logo: </Label>
                          <img src={this.state.prevlogofile} alt="logo" width="40" />
                        </FormGroup>
                        :
                        null
                    }

                    <FormGroup className="form-group">
                      <Label htmlFor="logofile">Icon *</Label>
                      <div className="custom-file">
                        <Input type="file" id="logofile" name="logofile" className="custom-file-input" onChange={this.fileChangedHandler} />
                        <label className="custom-file-label" htmlFor="logofile">Choose file</label>
                      </div>
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Label htmlFor="description">Description </Label>
                      <Input type="textarea" name="description" id="description" rows="5" placeholder="Add your comments here..." defaultValue={this.state.recordByIdData.get('description')} />
                    </FormGroup>



                    <FormGroup className="form-group">
                      <Label htmlFor="country">Status</Label>
                      <select name="status" id="status" className="form-control" defaultValue={String(this.state.recordByIdData.get('status'))}>
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                      </select>
                    </FormGroup>




                  </Form>
                </CardBody>
              </Card>

            </Col>
          </Row>
        </div>
        :
        <p></p>
      }

    </div>
  }
}

export default withRouter(EditAssetType);
