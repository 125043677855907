import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Alert,
  CardHeader,
} from 'reactstrap';
import Select, { components } from "react-select";
import jQuery from 'jquery'

import { getCurrentUser, authenticateLoggedIn, addHeaderTitle, addBreadcrumb } from '../../services/parse';
import { checkFieldValid, fetchRecordById, isAdmin, getALJCompanies, updateUsersCloud, getLawfirm } from '../../services/user';
import withRouter from '../../components/common/WithRouter';
var Parse = require('parse');

class editUser extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      collapse: true,
      fadeIn: true,
      timeout: 300,
      isSuper: '',
      currentRole: '',
      recordupdatedmessage: '',
      addedclass: 'd-none',
      userexist: 'd-none',
      emailexist: 'd-none',
      emailinvalid: 'd-none',
      recordaddedclass: 'd-none',
      recordupdatedclass: 'd-none',
      companyList: [],
      lawfirmList: [],
      recordByIdData: [],
      lawfirm: null,
      company: null,
      isEdit: false,
    };
  }


  componentWillMount = async () => {
    //auth start
    authenticateLoggedIn();
    addHeaderTitle('Edit User');
    addBreadcrumb('Home / Users / Edit User');

    var currentUser = Parse.User.current();
    if (currentUser) {
      isAdmin().then((value) => {
        var currentrole = value[0].get('role').get('name');
        this.setState({ currentRole: currentrole });
      });
    }

    getALJCompanies().then((value) => {
      this.setState({ companyList: value, });
    });
    getLawfirm().then((value) => {
      this.setState({ lawfirmList: value, });
    });

    var editobjectid = this.props.params.objectid;

    if (editobjectid) {



      var value = await fetchRecordById({ objectid: editobjectid });

      if (value.length > 0) {
        this.setState({ recordByIdData: value[0] });
        this.setState({ isEdit: true });

        if (value[0].get('ALJCompany')) {
          this.setState({ company: value[0].get('ALJCompany').id });
        }
        if (value[0].get('lawfirm')) {
          this.setState({ lawfirm: value[0].get('lawfirm').id });
        }

        if (value[0].get('role').id !== "gbwpXDwpm7") {
          jQuery('#companyformgroup').removeClass('d-none');
        } else {
          jQuery('#lawfirmformgroup').removeClass('d-none');
        }


      } else {
        this.setState({ isEdit: false });
      }






      // fetchRecordById({ objectid: editobjectid }).then((value) => {
      //   if (value.length > 0) {
      //     this.setState({ recordByIdData: value[0] });
      //     this.setState({ isEdit: true });

      //     console.log(value[0]);

      //     if (value[0].get('ALJCompany')) {
      //       this.setState({ company: value[0].get('ALJCompany').id });
      //     }
      //     if (value[0].get('lawfirm')) {
      //       this.setState({ lawfirm: value[0].get('lawfirm').id });
      //     }


      //   } else {
      //     this.setState({ isEdit: false });
      //   }
      // });
    }

  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  randomPassword = (length) => {
    var chars = "abcdefghijklmnopqrstuvwxyz!@#$%^&*()-+<>ABCDEFGHIJKLMNOP1234567890";
    var pass = "";
    for (var x = 0; x < length; x++) {
      var i = Math.floor(Math.random() * chars.length);
      pass += chars.charAt(i);
    }
    return pass;
  }

  generatePassword = () => {
    var randomPass = this.randomPassword(8);
    jQuery('#password').val(randomPass);
  }

  checkFirmGroup = () => {
    var role = jQuery('#role').val();

    if (role == "gbwpXDwpm7") {
      jQuery('#lawfirmformgroup').removeClass('d-none');
      jQuery('#companyformgroup').addClass('d-none');
      this.setState({ company: null });
    } else {
      jQuery('#companyformgroup').removeClass('d-none');
      jQuery('#lawfirmformgroup').addClass('d-none');
      this.setState({ lawfirm: null });
    }
  }

  updateRecord = () => {

    var valid = checkFieldValid('update');

    if (valid !== false) {
      jQuery('#addbutton').prop('disabled', true);
      // var recordhidID = document.getElementById('hiddenrecordid').value;

      var fname = jQuery('#cname').val();
      var lname = jQuery('#lname').val();
      var username = this.state.recordByIdData.get('username');
      var password = jQuery('#password').val();
      var email = this.state.recordByIdData.get('email');
      var role = jQuery('#role').val();
      var contact = jQuery('#contact').val();
      if (this.state.company) {
        var company = this.state.company;
      } else {
        var company = null;
      }
      if (this.state.lawfirm) {
        var lawfirm = this.state.lawfirm;
      } else {
        var lawfirm = null;
      }

      var tradenotify = jQuery('#tradenotify');
      if (tradenotify.is(":checked")) {
        var tradenotify = true;
      } else {
        var tradenotify = false;
      }


      var status = jQuery('#status').val();
      var fielddatas = {
        fname: fname,
        lname: lname,
        username: username,
        password: password,
        email: email,
        role: role,
        contact: contact,
        company: company,
        lawfirm: lawfirm,
        tradenotify: tradenotify,
        status: status,
      };

      updateUsersCloud(fielddatas).then((value) => {

        if (value.success === 1) {
          this.setState({ recordupdatedmessage: value.message });
          this.setState({ recordaddedclass: 'd-block' });

          jQuery('#addbutton').prop('disabled', false);

          setTimeout(
            function () {
              this.setState({ recordaddedclass: 'd-none' });
              this.setState({ userexist: 'd-none' });
              this.setState({ emailexist: 'd-none' });
              this.setState({ emailinvalid: 'd-none' });
            }
              .bind(this),
            3000
          );
        } else {
          this.setState({ recordupdatedmessage: value.message });
          this.setState({ recordaddedclass: 'd-none' });
        }


      });


    }
  }





  render() {

    var companystate = this.state.companyList;
    var companylist = [];
    if (companystate && companystate.length > 0) {
      for (let i = 0; i < companystate.length; i++) {
        companylist[i] = { value: companystate[i].id, label: companystate[i].attributes.name }
      }
    }

    var lawfirmstate = this.state.lawfirmList;
    var lawfirmList = [];
    if (lawfirmstate && lawfirmstate.length > 0) {
      for (let i = 0; i < lawfirmstate.length; i++) {
        lawfirmList[i] = { value: lawfirmstate[i].id, label: lawfirmstate[i].attributes.name }
      }
    }




    return <div>

      {this.state.isEdit === true && this.state.currentRole === "Admin" ?
        <div>
          <Row className="justify-content-end">
            <Col className="col-auto mb-4">
              <Link to="/user/user" className="btn btn-primary btncancle mr-3">
                <span className="border-left pl-2">Cancel</span>
              </Link>
              <Button type="button" color="primary" id="addbutton" onClick={this.updateRecord}>
                <span className="border-left pl-2"></span>
                Save
              </Button>
            </Col>
          </Row>



          <Row>
            <Col xs="12" sm="6">
              <Card>
                <CardTitle>
                  <i className="icon-circle icon-pencil"></i>
                  Edit User
              </CardTitle>

                <CardBody>
                  <Alert color="success" className={this.state.recordaddedclass}>
                    {this.state.recordupdatedmessage}
                  </Alert>


                  <form name="addform" id="addform">
                    <Input type="hidden" id="hiddenrecordid" defaultValue={this.state.recordByIdData.id} />

                    <FormGroup className="form-group">
                      <Label htmlFor="company">Username / Email</Label>
                      <Input type="text" defaultValue={this.state.recordByIdData.get('username')} disabled />
                    </FormGroup>
                    <FormGroup className="form-group"></FormGroup>

                    <FormGroup className="form-group">
                      <Label htmlFor="company">First Name *</Label>
                      <Input type="text" id="cname" defaultValue={this.state.recordByIdData.get('firstName')} placeholder="First Name" />
                    </FormGroup>
                    <FormGroup className="form-group">
                      <Label htmlFor="company">Last Name *</Label>
                      <Input type="text" id="lname" defaultValue={this.state.recordByIdData.get('lastName')} placeholder="Last Name" />
                    </FormGroup>

                    <FormGroup className="form-group position-relative">
                      <Label htmlFor="company">Password *</Label>
                      <Input type="text" id="password" placeholder="Password" />
                      <i className="fas fa-sync-alt icon-gpwd" onClick={this.generatePassword}></i>
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Label htmlFor="Role">Role *</Label>
                      <select name="role" id="role" className="form-control" onChange={this.checkFirmGroup} defaultValue={this.state.recordByIdData.get('role').id}>
                        <option value="">Please select</option>
                        <option value="mII3gxJlLE">Admin</option>
                        <option value="OIC4hHUVYp">Finance</option>
                        <option value="osSsE7BIUp">Commercial</option>
                        <option value="PYBlOPZkuu">Legal</option>
                        <option value="gbwpXDwpm7">External</option>
                        <option value="SY7sNL8DDp">Executive</option>
                      </select>
                    </FormGroup>


                    <FormGroup id="companyformgroup" className="form-group d-none">
                      <Label >Company</Label>
                      <Select
                        id="company"
                        className="reactSelect reactSelectClass required"
                        closeMenuOnSelect={true}
                        options={companylist}
                        defaultValue={
                          this.state.recordByIdData.get('ALJCompany') !== undefined ?
                            [{ value: this.state.recordByIdData.get('ALJCompany').id, label: this.state.recordByIdData.get('ALJCompany').get('name') }]
                            :
                            ""
                        }
                        onChange={(selectedOption) => {
                          this.setState({ company: selectedOption.value });
                        }}
                      />
                    </FormGroup>

                    <FormGroup  id="lawfirmformgroup" className="form-group d-none" >
                      <Label >Law Firm</Label>
                      <Select
                        id="lawfirm"
                        className="reactSelect reactSelectClass required"
                        closeMenuOnSelect={true}
                        options={lawfirmList}
                        defaultValue={
                          this.state.recordByIdData.get('lawfirm') !== undefined ?
                            [{ value: this.state.recordByIdData.get('lawfirm').id, label: this.state.recordByIdData.get('lawfirm').get('name') }]
                            :
                            ""
                        }
                        onChange={(selectedOption) => {
                          this.setState({ lawfirm: selectedOption.value });
                        }}
                      />

                    </FormGroup>

                    <FormGroup className="form-group">
                      <div className="customcheck greybgcheck yscroll form-inline">
                        <div className="form-check form-check-inline">
                          <Input type="checkbox" name="tradenotify" className="tradenotify" value="yes" id="tradenotify"
                            defaultChecked={this.state.recordByIdData.get('trademarkNotification') === true}
                          />
                          <Label for="tradenotify">Notifications</Label>
                        </div>
                      </div>
                    </FormGroup>


                    <FormGroup className="form-group">
                      <Label htmlFor="company">Contact</Label>
                      <Input type="text" id="contact" defaultValue={this.state.recordByIdData.get('contact')} placeholder="Contact" />
                    </FormGroup>



                    <FormGroup className="form-group">
                      <Label htmlFor="country">Status</Label>
                      <select name="status" id="status" className="form-control" defaultValue={String(this.state.recordByIdData.get('status'))}>
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                      </select>
                    </FormGroup>

                  </form>


                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        :
        <p></p>
      }

    </div>
  }
}

export default withRouter(editUser);
