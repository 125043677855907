var Parse = require('parse');

export const addUpdateRenewals = async (mainDataObj,trademark) =>{
    try{
        var maindataobj = mainDataObj;
    mainDataObj.map(async(mainData,index)=>{

        const TrademarkRenewals = Parse.Object.extend('TrademarkRenewals');
        let myNewObject = new TrademarkRenewals();
        if(mainData.id)
        {
            myNewObject.set('objectId', mainData.id);
        }

        myNewObject.set('notes', mainData.notes);
        myNewObject.set('processStatus', mainData.processStatus);
        myNewObject.set('user', Parse.User.current());
        myNewObject.set('assetFiles', mainData.assetsFileArray);
        myNewObject.set('statusNotes', mainData.statusNotes);
        myNewObject.set('status',true);
        
        myNewObject.set('registrationNumber', mainData.registrationNumber);
        if(mainData.registrationDate!='')
        {
            myNewObject.set('registrationDate', mainData.registrationDate);
        }
        if(mainData.nextRenewalDate!='')
        {
            myNewObject.set('nextRenewalDate', mainData.nextRenewalDate);
            updateTradeMarkRenewalDate(trademark,mainData.nextRenewalDate);
        }  

        if (trademark) {
            var extentObject = Parse.Object.extend("TrademarksMaster");
            var parseqry = new Parse.Query(extentObject);
            let parsepoint = await parseqry.get(trademark);
            myNewObject.set('trademark', parsepoint);
        }

        let tempobj = await myNewObject.save();
        maindataobj[index].id = tempobj.id
        maindataobj[index].chatPostEnabled = true
        
    })
    console.log(maindataobj);
    return maindataobj;
}
catch(ex)
{
    return false;
}
}

export const updateTradeMarkRenewalDate = async(trademark,rendate)=>{
    const TrademarksMaster = Parse.Object.extend('TrademarksMaster');
        let myNewObject = new TrademarksMaster();
        if(trademark)
        {
            myNewObject.set('objectId', trademark);
        }
        myNewObject.set('nextRenewalDate', rendate);
        let tempobj = await myNewObject.save();
        return tempobj
}

export const getTrademarkRenewalsById = async (trademarkId) => {
     var trademark = await getTrademarkByIdV1(trademarkId);
     var TrademarkRenewals = Parse.Object.extend("TrademarkRenewals");
     var query = new Parse.Query(TrademarkRenewals);
     query.equalTo("trademark", trademark);
     query.equalTo("status", true);
     // query.include("BusinessSectors");
     var result = await query.find();
    // console.log('getTrademarkProgressById',result)
     if(result)
     return result;
     else
     return null
     
 }

 export const deleteRenewalRecord = async (id) => {
    const TrademarkRenewals = Parse.Object.extend('TrademarkRenewals');
    let myNewObject = new TrademarkRenewals();
    myNewObject.set('objectId', id);
    myNewObject.set('status',false);  
    myNewObject.save(); 
}

 
 export const getTrademarkByIdV1 = async (trademarkId) => {
    var TrademarksMaster = Parse.Object.extend("TrademarksMaster");
    var query = new Parse.Query(TrademarksMaster);
    query.equalTo("objectId", trademarkId);
    // query.include("BusinessSectors");
    var result = await query.find();
    if(result)
    return result[0];    
}

export const getRenewalId = async (renewalId) => {
    var TrademarkRenewals = Parse.Object.extend("TrademarkRenewals");
    var query = new Parse.Query(TrademarkRenewals);
    query.equalTo("objectId", renewalId);
    // query.include("BusinessSectors");
    var result = await query.find();
    if(result)
    return result[0];    
}

export const postTrademarkRenewalChat=async(message,trademarkId,renewalId)=>{

    var trademark = await getTrademarkByIdV1(trademarkId);
    var renewal = await getRenewalId(renewalId);

    const TrademarkRenewalChat = Parse.Object.extend('TrademarkRenewalChat');
const myNewObject = new TrademarkRenewalChat();

myNewObject.set('user', Parse.User.current());
myNewObject.set('trademark', trademark);
myNewObject.set('renewal', renewal);
myNewObject.set('message', message);
myNewObject.save();
}
