import React, { Component } from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    Button,
    Popover,
    PopoverHeader,
    PopoverBody,
	ListGroup,
	ListGroupItem
} from 'reactstrap';
import jQuery from 'jquery';
import ProgressStatusForm from './progressStatusForm.jsx';
import { getTrademakProgresses, getTrademakProgressStatus } from '../../services/trademarkProgress';
import { getTrademarkObjectById } from '../../services/trademark';

class progressStatusContainer extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            popoverOpen: false
        };

        this.state = {
            email: '',
            title: '',
            savedToCloud: false,
            tradeMarkProgress: [],
            tradeMarkProgressStatus: [],
            currentProgressId: null,
            trademark: null,
            index:-1,
            popovers: [
                {
                    placement: 'top',
                    text: 'Top'
                },
                {
                    placement: 'bottom',
                    text: 'Bottom'
                },
                {
                    placement: 'left',
                    text: 'Left'
                },
                {
                    placement: 'right',
                    text: 'Right'
                }
            ]
        };
    }

    toggle() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }

    componentWillMount = async () => {
        var tradeMarkProgress = await getTrademakProgresses();
        var trademark = await getTrademarkObjectById(this.props.id)
        if (tradeMarkProgress) {
            this.setState({
                tradeMarkProgress: tradeMarkProgress,
                currentProgressId: tradeMarkProgress[0].id, title: tradeMarkProgress[0].attributes.name, trademark: trademark
            }, async () => {
                this.loadTradeMarkProgressStatuses(this.props.id);
                        })
        }
    }

    loadTradeMarkProgressStatuses = async (id) => {
        var tradeMarkProgressStatus = await getTrademakProgressStatus(id);
        var index = 0;
        this.setState({ tradeMarkProgressStatus: tradeMarkProgressStatus },async()=>{
           console.log('tradeMarkProgressStatus',tradeMarkProgressStatus);
            if(this.state.tradeMarkProgress.length > 0 && this.state.tradeMarkProgressStatus && this.state.trademark) 
            {
                for(let i=0;i<this.state.tradeMarkProgress.length;i++)
                {
                   // console.log('loadTradeMarkProgressStatuses');
                    var clrObj = this.state.tradeMarkProgressStatus.filter(obj => obj.trademarkStatus == this.state.tradeMarkProgress[i].id)[0];
                    if(clrObj)
                    {
                        console.log('loadTradeMarkProgressStatuses',i);
                        index = i;
                    }
                }
                this.setState({index:index})
            }
            else
            {
                this.setState({index:0})
            }

                                
        })
    }
    async componentDidMount(evt) {
        jQuery(".opendefault").next('.card-body').show();
        jQuery(".opendefault").addClass('active');
    }

    h5Click = (event) => {
        // jQuery(event.target).parent().siblings('.card').find('.card-body').slideUp('slow');
        // jQuery(event.target).parent().siblings('.card').find('h5').removeClass('active');
        jQuery(event.target).parent().find('.card-body').slideToggle('slow');
        jQuery(event.target).toggleClass('active');
    }

    render() {


        return (
            <Card>
                {/* <CardTitle><i className="icon-circle icon-pencil"></i>Trademark Registration</CardTitle> */}
                <CardBody>
                    <div className='example'>
                        <div className="legend">
                            <Button id="Popover1" onClick={this.toggle} className="btn-default">
                                Legend
                            </Button>
                            <Popover placement="left" isOpen={this.state.popoverOpen} target="Popover1" toggle={this.toggle}>
                                {/* <PopoverHeader>Popover Title</PopoverHeader> */}
                                <PopoverBody>
                                <ListGroup>
								<ListGroupItem><i className="fas fa-circle mr-1 abandoned"></i> Abandoned</ListGroupItem>
								<ListGroupItem><i className="fas fa-circle mr-1 completed"></i> Completed</ListGroupItem>
								<ListGroupItem><i className="fas fa-circle mr-1 notStarted"></i> Not Started</ListGroupItem>
								<ListGroupItem><i className="fas fa-circle mr-1 inprogress"></i> In Progress, <span>Awaiting Certificate Of Registration,</span> <span>Conditional Acceptance,</span> <span>Petition Under Examination,</span> <span>Registration Fees Paid</span></ListGroupItem>
								<ListGroupItem><i className="fas fa-circle mr-1 lapsed"></i> Lapsed</ListGroupItem>
								<ListGroupItem><i className="fas fa-circle mr-1 requestRecieved"></i> Request Received</ListGroupItem>
								<ListGroupItem><i className="fas fa-circle mr-1 rejected"></i> Rejected</ListGroupItem>
								<ListGroupItem><i className="fas fa-circle mr-1 statusUnknown"></i> Status Unknown</ListGroupItem>
								<ListGroupItem><i className="fas fa-circle mr-1 withdrawn"></i> Withdrawn</ListGroupItem>
							</ListGroup>
                                </PopoverBody>
                            </Popover>
                        </div>
                        <ul className='step-progress'>
                            {(this.state.tradeMarkProgress.length > 0 && this.state.tradeMarkProgressStatus && this.state.trademark && this.state.index>=0)?
                            
                                this.state.tradeMarkProgress.map((item, index) => {
                                    
                                    var clrObj = this.state.tradeMarkProgressStatus.filter(obj => obj.trademarkStatus == item.id)[0];
                                    var active = this.state.currentProgressId == item.id ? 'active' : '';
                                    return (
                                        <li className={clrObj ? clrObj.processStatus + " " + active : (index<this.state.index)?"noActivity " + active:"notStarted " + active} onClick={() => {

                                            console.log(item.id, this.props.id)
                                            this.setState({ currentProgressId: null }, () => {
                                                this.setState({ currentProgressId: item.id, title: item.attributes.name })
                                            })
                                        }} >
                                        {clrObj || index>this.state.index ?null:<i className="fas fa-circle iamchecked d-block"></i>}
                                             <span>{item.attributes.name}</span>
                                        </li>
                                    )
                                })


                                : null}
                        </ul>
                        {this.state.currentProgressId ?
                            <ProgressStatusForm trademarkId={this.props.id}
                                progressId={this.state.currentProgressId}
                                title={this.state.title}
                                updateStatus={async () => {
                                    this.loadTradeMarkProgressStatuses(this.props.id);
                                    this.props.onSetMarketingTab()
                                }}
                                trademark={this.state.trademark} />
                            : null}
                    </div>
                </CardBody>
            </Card>

        )
    }
}

export default progressStatusContainer;
