import React from 'react';
import { Link } from 'react-router-dom';
import ScrollArea from 'react-scrollbar';
import { Row, Col, Carousel, CarouselItem, CarouselControl, Button, Table, Input, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, ModalFooter, Label, Alert, UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem, TabContent, TabPane, CardBody, Nav, NavItem, NavLink, Card, CardTitle, CardText } from 'reactstrap';
import Select, { components } from "react-select";
import Datetime from "react-datetime";
import DatePicker from "react-datepicker";
import 'react-datetime/css/react-datetime.css';
import jQuery from 'jquery';
import classnames from 'classnames';
import { addBreadcrumb, addHeaderTitle } from '../../../services/parse';
import { isAdmin } from '../../../services/user';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import imageDummy from '../../../assets/images/dummy.jpg';
import noImage from '../../../assets/images/no-image.jpg';
import logoLemon from '../../../assets/images/icons/logo-lemon.png';
import iconCalender from '../../../assets/images/icons/icon-calendar.png';
import { getLicenseType, getAssetType, getMediaLicenseLibrary, getGeoRestriction, getAgency, getBrand, getTags, getAssetbyLicenseName, updateFavorite, undoRecord } from '../../../services/medialicenseassets';
// import iconCamera from '../../../assets/images/icons/icon-camera.png';
// import iconPencil from '../../../assets/images/icons/icon-pencil.png';
// import iconMedia from '../../../assets/images/icons/icon-media.png';
// import iconAudio from '../../../assets/images/icons/icon-audio.png';
import Loader from '../../../components/common/Loader';

import Addasset from "./Addasset";
import Editasset from "./Editasset";
import Viewassets from "./Viewassets";
import Assetimage from "./Assetimage";

import Calendarview from "./calendarview";
import { getFileUrl } from '../../../services/awsFileUpload';
import AssetImages from './AssetImages';

function openFilter(e) {
  var thisitem = jQuery(e.target);
  jQuery('.filtermenu').not(thisitem.next()).removeClass('active');
  jQuery('.dropbtn').not(thisitem).removeClass('active');

  if (thisitem.hasClass('active')) {
    thisitem.removeClass('active');
  } else {
    thisitem.addClass('active');
  }

  if (thisitem.next().hasClass('active')) {
    thisitem.next().removeClass('active');
  } else {
    thisitem.next().addClass('active');
  }
}

jQuery(document).click(function (e) {
  var container = jQuery("#assestfilter");
  if (!container.is(e.target) && container.has(e.target).length === 0) {
    jQuery('.dropbtn').removeClass('active');
    jQuery('.filtermenu').removeClass('active');
  }
});


var iconCamera = "";
var iconMedia = "";
var iconAudio = "";
var iconPencil = "";

class mediaAssets extends React.Component {

  constructor(props) {
    super(props);
    this.state = { activeIndex: 0, visible: true };
    this.addnewpopuptoggle = this.addnewpopuptoggle.bind(this);
    this.editpopuptoggle = this.editpopuptoggle.bind(this);
    this.viewpopuptoggle = this.viewpopuptoggle.bind(this);
    this.deletepopuptoggle = this.deletepopuptoggle.bind(this);
    this.onDismiss = this.onDismiss.bind(this);


    this.state = {
      mainDataLoad: false,
      fullDataLoad: false,

      archivefilter: false,
      favoritefilter: false,
      keywordfilter: "",
      expiringinfilter: "",
      assettypefilter: [],
      libraryfilter: [],
      agencyfilter: [],
      addedonfilter: "",
      addedonfilterstart: null,
      addedonfilterend: null,

      updateIDforImage: "",
      popupstatus: "",
      licenseItems: [],
      medialicenselibrary: [],
      assettypes: [],
      georestrictions: [],
      agencylist: [],
      brandlist: [],
      taglist: [],
      fullAssetData: [],

      activeTab: '1',
      editObjectID: null,
      loader: false,
      nonEditorialData: null,
      editorialData: null,
      royaltyData: null,
      standardData: null,
      assetsAWSURL: []
    };
  }


  componentWillMount = async () => {
    var isadminval = await isAdmin();
    if (isadminval) {
      var currentrole = isadminval[0].get('role').get('name');
      this.setState({ currentRole: currentrole });
      addBreadcrumb('Home / Media License');
      addHeaderTitle('Assets');
    }

    this.setState({ mainDataLoad: true });
    this.setState({ fullDataLoad: true });







    // URL Parameter 
    var keyword = "";
    var expiring = "";
    var assettype = "";
    var library = "";
    var agency = "";
    var addedon = "";
    var addedonstart = null;
    var addedonend = null;

    let params_arr = [];
    let urlString = window.location.href;
    let paramString = urlString.split('?')[1];
    if (paramString) {
      params_arr = paramString.split('&');
    }

    for (let i = 0; i < params_arr.length; i++) {
      let pair = params_arr[i].split('=');

      if (pair[0] === "objectid") { // this is for popup from activity
        this.setState({
          deleteClicked: false,
          popupstatus: "view",
          viewpopup: !this.state.viewpopup
        });
        this.setObjectID(pair[1]);
        window.history.pushState({}, null, '/#/medialicense/assets/');
      }

      if (pair[0] === "keyword") {
        keyword = pair[1];
      }
      if (pair[0] === "expiring") {
        expiring = pair[1];
      }
      if (pair[0] === "assettype") {
        assettype = pair[1];
        if (assettype) {
          assettype = assettype.split(',');
        }
      }
      if (pair[0] === "library") {
        library = pair[1];
        if (library) {
          library = library.split(',');
        }
      }
      if (pair[0] === "agency") {
        agency = pair[1];
        if (agency) {
          agency = agency.split(',');
        }
      }
      if (pair[0] === "addedon") {
        addedon = pair[1];
      }
      if (pair[0] === "addedonstart" && pair[1] !== null && pair[1] !== "null") {
        addedonstart = decodeURI(pair[1]);
        addedonstart = new Date(addedonstart);
      }
      if (pair[0] === "addedonend" && pair[1] !== null && pair[1] !== "null") {
        addedonend = decodeURI(pair[1]);
        addedonend = new Date(addedonend);
      }
    }

    var searchdatas = {
      keywordfilter: keyword,
      expiringinfilter: expiring,
      assettypefilter: assettype,
      libraryfilter: library,
      agencyfilter: agency,
      addedonfilter: addedon,
      addedonfilterstart: addedonstart !== "Invalid Date" ? addedonstart : null,
      addedonfilterend: addedonend !== "Invalid Date" ? addedonend : null,
    };
    // URL Parameter


    var fulldata = []

    var standard = await getAssetbyLicenseName("standard", searchdatas);
    this.setState({ standardData: standard });

    this.setState({ mainDataLoad: false });

    var noneditorial = await getAssetbyLicenseName("non-editorial", searchdatas);
    this.setState({ nonEditorialData: noneditorial });

    var editorial = await getAssetbyLicenseName("editorial", searchdatas);
    this.setState({ editorialData: editorial });

    var royalty = await getAssetbyLicenseName("royalty-free", searchdatas);
    this.setState({ royaltyData: royalty });



    fulldata = fulldata.concat(noneditorial, editorial, royalty, standard);
    this.getAwsImageUrl(fulldata);
    // fulldata.sort((a, b) => {
    //   return a.createdAt - b.createdAt;
    // });
    this.setState({ fullAssetData: fulldata });






    //Add Edit Datas
    var licensetypes = await getLicenseType();
    this.setState({ licenseItems: licensetypes, });

    var medialiclib = await getMediaLicenseLibrary();


    var libraryOptions = [];
    var otherlib = "";
    if (medialiclib && medialiclib.length > 0) {
      for (let i = 0; i < medialiclib.length; i++) {
        if (medialiclib[i].attributes.name !== "Other") {
          libraryOptions.push({ value: medialiclib[i].id, label: medialiclib[i].attributes.name })
        } else {
          otherlib = { value: medialiclib[i].id, label: medialiclib[i].attributes.name };
        }
      }

      libraryOptions.sort(function (a, b) {
        a = a.label.toLowerCase();
        b = b.label.toLowerCase();
        if (a == b) return 0;
        return a < b ? -1 : 1;
      });

      libraryOptions.push(otherlib);
    }
    this.setState({ medialicenselibrary: libraryOptions });


    var getassettype = await getAssetType();

    this.setState({ assettypes: getassettype });

    for (var i = 0; i < getassettype.length; i++) {
      if (getassettype[i].get('icon')) {
        var img = getassettype[i].get('icon')[0].awsFileName;
        var imgurl = await getFileUrl("contentmaster", img);

        if (getassettype[i].get('name') === "Image") {
          iconCamera = imgurl.url;
        } else if (getassettype[i].get('name') === "Video") {
          iconMedia = imgurl.url;
        } else if (getassettype[i].get('name') === "Audio") {
          iconAudio = imgurl.url;
        } else if (getassettype[i].get('name') === "Vector") {
          iconPencil = imgurl.url;
        }

      }
    }



    var georestrict = await getGeoRestriction();
    this.setState({ georestrictions: georestrict });


    var agencies = await getAgency();
    var agencyOptions = [];
    if (agencies && agencies.length > 0) {
      for (let i = 0; i < agencies.length; i++) {
        agencyOptions.push({ value: agencies[i].id, label: agencies[i].attributes.name })
      }
    }
    this.setState({ agencylist: agencyOptions });

    var brands = await getBrand();
    this.setState({ brandlist: brands });




    var alltags = await getTags();
    var tagOptions = [];
    if (alltags && alltags.length > 0) {
      for (let i = 0; i < alltags.length; i++) {
        tagOptions.push({ value: alltags[i].id, label: alltags[i].get('name') })
      }
    }
    this.setState({ taglist: tagOptions });




    this.setState({ fullDataLoad: false });
    //Add Edit Datas




    this.setFilterControls(searchdatas)


  }

  getAwsImageUrl = (assetsArray) => {
    assetsArray.map(async (dynamicData, key) => {

      let assetimage = dynamicData.get('assetimage');

      if (assetimage && assetimage.length && assetimage[0] != "") {
        let imageurl = assetimage[0];
        var awsurl = await this.getImageURL(imageurl);

        if (imageurl.awsFileName) {
          this.setState(prevState => ({
            assetsAWSURL: [
              ...prevState.assetsAWSURL,
              {
                fileName: imageurl.awsFileName,
                url: awsurl
              }
            ]
          }));

        }

      }

    });

    return assetsArray;
  }

  getImageURL = async (img) => {
    if (img.awsFileName) {
      var imgurl = await getFileUrl("contentmaster", img.awsFileName);
      return imgurl.url;
    } else {
      return "";
    }


  }

  getAssetUrl = (assetimage) => {

    if (assetimage && assetimage.length && assetimage[0] != "" && this.state.assetsAWSURL.length) {
      let imageurl = assetimage[0];

      let awsImageUrl = this.state.assetsAWSURL.find((obj) => obj.fileName === imageurl.awsFileName);

      if (awsImageUrl) {
        return awsImageUrl.url
      } else {
        return "";
      }
    }
    return "";

  }

  setFilterControls = async (searchdatas) => {
    if (searchdatas.keywordfilter !== "") {
      this.setState({ keywordfilter: searchdatas.keywordfilter });
    }

    if (searchdatas.expiringinfilter !== "") {
      this.setState({ expiringinfilter: searchdatas.expiringinfilter });
    }

    if (searchdatas.assettypefilter !== "") {
      this.setState({ assettypefilter: searchdatas.assettypefilter });
    }

    if (searchdatas.libraryfilter !== "") {
      this.setState({ libraryfilter: searchdatas.libraryfilter });
    }

    if (searchdatas.agencyfilter !== "") {
      this.setState({ agencyfilter: searchdatas.agencyfilter });
    }

    if (searchdatas.addedonfilter !== "") {
      this.setState({ addedonfilter: searchdatas.addedonfilter });
      if (searchdatas.addedonfilter === "range") {
        this.setState({ addedonfilterstart: searchdatas.addedonfilterstart, addedonfilterend: searchdatas.addedonfilterend });
      } else {
        this.setState({ addedonfilterstart: null, addedonfilterend: null });
      }
    }
  }





  getDataUpdate = async (licensename, asset = '') => {
    this.setState({ mainDataLoad: true });
    // this.setState({ fullDataLoad: true });

    var searchdatas = {
      archivefilter: this.state.archivefilter,
      favoritefilter: this.state.favoritefilter,
      keywordfilter: this.state.keywordfilter,
      expiringinfilter: this.state.expiringinfilter,
      assettypefilter: this.state.assettypefilter,
      libraryfilter: this.state.libraryfilter,
      agencyfilter: this.state.agencyfilter,
      addedonfilter: this.state.addedonfilter,
      addedonfilterstart: this.state.addedonfilterstart,
      addedonfilterend: this.state.addedonfilterend,
    };

    var fulldata = []

    if (licensename === "standard") {
      var standard = await getAssetbyLicenseName("standard", searchdatas);
      this.setState({ standardData: standard });
      if (standard) {
        fulldata = fulldata.concat(this.state.nonEditorialData, this.state.editorialData, this.state.royaltyData, standard);
      }
    } else if (licensename === "non-editorial") {
      var noneditorial = await getAssetbyLicenseName("non-editorial", searchdatas);
      this.setState({ nonEditorialData: noneditorial });
      if (noneditorial) {
        fulldata = fulldata.concat(noneditorial, this.state.editorialData, this.state.royaltyData, this.state.standardData);
      }
    } else if (licensename === "editorial") {
      var editorial = await getAssetbyLicenseName("editorial", searchdatas);
      this.setState({ editorialData: editorial });
      if (editorial) {
        fulldata = fulldata.concat(this.state.nonEditorialData, editorial, this.state.royaltyData, this.state.standardData);
      }
    } else if (licensename === "royalty-free") {
      var royalty = await getAssetbyLicenseName("royalty-free", searchdatas);
      this.setState({ royaltyData: royalty });
      if (royalty) {
        fulldata = fulldata.concat(this.state.nonEditorialData, this.state.editorialData, royalty, this.state.standardData);
      }
    }

    this.setState({ mainDataLoad: false });
    // fulldata.sort((a, b) => {
    //   return a.createdAt - b.createdAt;
    // });

    if (asset) {
      this.getAwsImageUrl([asset]);
    }

    this.setState({ fullAssetData: fulldata });

    // this.setState({ fullDataLoad: false });


    this.setState({ updateIDforImage: "" });
  }



  genericFilter = async () => {
    this.setState({
      popupstatus: "filter"
    });
    this.setState({ mainDataLoad: true });
    // this.setState({ fullDataLoad: true });



    var searchdatas = {
      archivefilter: this.state.archivefilter,
      favoritefilter: this.state.favoritefilter,
      keywordfilter: this.state.keywordfilter,
      expiringinfilter: this.state.expiringinfilter,
      assettypefilter: this.state.assettypefilter,
      libraryfilter: this.state.libraryfilter,
      agencyfilter: this.state.agencyfilter,
      addedonfilter: this.state.addedonfilter,
      addedonfilterstart: this.state.addedonfilterstart,
      addedonfilterend: this.state.addedonfilterend,
    };


    window.history.pushState(
      {},
      null,
      '/#/medialicense/assets/?keyword=' + this.state.keywordfilter
      + "&expiring=" + this.state.expiringinfilter + "&assettype=" + this.state.assettypefilter
      + "&library=" + this.state.libraryfilter + "&agency=" + this.state.agencyfilter
      + "&addedon=" + this.state.addedonfilter + "&addedonstart=" + this.state.addedonfilterstart + "&addedonend=" + this.state.addedonfilterend
    );


    var standard = await getAssetbyLicenseName("standard", searchdatas);
    this.setState({ standardData: standard });

    this.setState({ mainDataLoad: false });

    var noneditorial = await getAssetbyLicenseName("non-editorial", searchdatas);
    this.setState({ nonEditorialData: noneditorial });

    var editorial = await getAssetbyLicenseName("editorial", searchdatas);
    this.setState({ editorialData: editorial });

    var royalty = await getAssetbyLicenseName("royalty-free", searchdatas);
    this.setState({ royaltyData: royalty });



    //Extra added for filter part
    var fulldata = []
    fulldata = fulldata.concat(noneditorial, editorial, royalty, standard);
    this.setState({ fullAssetData: fulldata });
    //Extra added for filter part

    this.setState({
      popupstatus: ""
    });

    // this.setState({ fullDataLoad: false });
  }



  expiringFilterClick = async (event) => {
    this.setState({ expiringinfilter: event.target.value });
  }

  assettypeFilterClick = async (event) => {
    var check = event.target.checked;
    var val = jQuery(event.target).val();
    if (val === "all") {
      jQuery('input:checkbox[name="assettypefiltercheck"]').prop('checked', check);
    } else {
      jQuery('input:checkbox[name="assettypefiltercheck"][value="all"]').prop('checked', false);
    }

    var assetlist = [];
    jQuery.each(jQuery("input[name='assettypefiltercheck']:checked"), function () {
      assetlist.push(jQuery(this).val());
    });
    this.setState({ assettypefilter: assetlist });
  }

  libraryFilterClick = async (event) => {
    var check = event.target.checked;
    var val = jQuery(event.target).val();
    if (val === "all") {
      jQuery('input:checkbox[name="libraryfiltercheck"]').prop('checked', check);
    } else {
      jQuery('input:checkbox[name="libraryfiltercheck"][value="all"]').prop('checked', false);
    }

    var liblist = [];
    jQuery.each(jQuery("input[name='libraryfiltercheck']:checked"), function () {
      liblist.push(jQuery(this).val());
    });
    this.setState({ libraryfilter: liblist });
  }

  agencyFilterClick = async (event) => {
    var check = event.target.checked;
    var val = jQuery(event.target).val();
    if (val === "all") {
      jQuery('input:checkbox[name="agencyfiltercheck"]').prop('checked', check);
    } else {
      jQuery('input:checkbox[name="agencyfiltercheck"][value="all"]').prop('checked', false);
    }

    var agencylist = [];
    jQuery.each(jQuery("input[name='agencyfiltercheck']:checked"), function () {
      agencylist.push(jQuery(this).val());
    });
    this.setState({ agencyfilter: agencylist });
  }

  addedonFilterClick = async (event) => {
    var val = jQuery(event.target).val();
    this.setState({ addedonfilter: val });

    if (val !== "range") {
      this.setState({ addedonfilterstart: null, addedonfilterend: null });
    }

  }




  addnewpopuptoggle() {
    this.setState({
      addnewpopup: !this.state.addnewpopup,
      popupstatus: "add",
      viewpopup: false
    });
  }

  viewpopuptoggle(e) {

    this.setState({
      deleteClicked: false,
      popupstatus: "view",
      viewpopup: !this.state.viewpopup
    });
    this.setObjectID(e.target.getAttribute("data-objectid"));
  }

  editpopuptoggle(e) {
    this.setState({
      deleteClicked: false,
      popupstatus: "edit",
      editpopup: !this.state.editpopup,
      viewpopup: false,
    });
    this.setObjectID(e.target.getAttribute("data-objectid"));
  }

  deletepopuptoggle(e) {
    this.setState({
      viewpopup: !this.state.viewpopup,
      popupstatus: "view",
      deleteClicked: true,
    });
    this.setObjectID(e.target.getAttribute("data-objectid"));
  }


  onDismiss() {
    this.setState({
      visible: false
    });
  }
  toggleTabs(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  setObjectID(objectid) {
    this.setState({
      editObjectID: objectid !== null ? objectid : null
    });
  }


  setFavorite = async (e) => {


    var objid = e.target.value;
    var val = "";

    var thisitem = jQuery(e.target);

    if (thisitem.hasClass('active')) {
      val = false;
      thisitem.removeClass('active');
    } else {
      val = true;
      thisitem.addClass('active');
    }



    if (val !== "") {
      var fav = await updateFavorite(objid, val);
      console.log(fav)
    }

  }



  undoRecord = async () => {
    var undo = await undoRecord(this.state.deletedID);
    if (undo) {
      this.setState({
        deletedID: null,
        deletedName: null,
      });

      this.getDataUpdate(undo.get('licensetypename'));
    }
  }

  render() {
    return (
      <div className="animated fadeIn">

        <div className="pageheader">
          <Row className="justify-content-between">
            <Col className="col-md-auto">

              {/* {
                this.state.fullDataLoad === true ?
                  <div className="btn btn-default">
                    <Loader type="Oval" color="#414241" height={20} width={20} />
                  </div>
                  :
                  <Button className="btn btn-default btnaddmew pluswhite" onClick={this.addnewpopuptoggle}>Add new</Button>
              } */}

              <Button className="btn btn-default btnaddmew pluswhite" onClick={this.addnewpopuptoggle}>Add new</Button>


              <Link to="/medialicense/bulkimport" className="btn btn-default btnbulk ml-3">Bulk Upload</Link>
            </Col>

            <Col className="col-md">














              <div className="assetsfilterrow1">
                <Row>
                  <Col className="col-12">
                    <Form>
                      <Row className="align-items-center">
                        <Col>
                          <div className="assestfilter" id="assestfilter">
                            <Row className="no-gutters">
                              <Col>
                                <div className="filbx">
                                  <label htmlFor="keyword">Enter keyword</label>
                                  <div className="mydropdown">
                                    <input type="text" id="keyword" placeholder="i.e space"
                                      value={this.state.keywordfilter}
                                      onChange={
                                        async (event) => {
                                          this.setState({ keywordfilter: event.target.value });
                                        }
                                      }
                                    />
                                  </div>
                                </div>
                              </Col>
                              <Col>
                                <div className="filbx">
                                  <label htmlFor="keyword">Expiring in days
                                    {
                                      this.state.expiringinfilter ?
                                        <span>({this.state.expiringinfilter})</span>
                                        :
                                        null
                                    }
                                  </label>


                                  <div className="mydropdown">
                                    <a href="javascript:" className="dropbtn" onClick={openFilter}>Any</a>
                                    <div className="filtermenu" id="expiringbx">
                                      <div className="formcol">
                                        <Input className="form-check-input" name="expiring" type="radio" id="radio1"
                                          value="expired"
                                          onChange={this.expiringFilterClick}
                                          checked={this.state.expiringinfilter === "expired" ? true : false}
                                        />
                                        <Label htmlFor="radio1">Already Expired</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="expiring" type="radio" id="radio2"
                                          value="24h"
                                          onChange={this.expiringFilterClick}
                                          checked={this.state.expiringinfilter === "24h" ? true : false}
                                        />
                                        <Label htmlFor="radio2">Next 24 hrs</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="expiring" type="radio" id="radio3"
                                          value="3d"
                                          onChange={this.expiringFilterClick}
                                          checked={this.state.expiringinfilter === "3d" ? true : false}
                                        />
                                        <Label htmlFor="radio3">Next 3 days</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="expiring" type="radio" id="radio4"
                                          value="7d"
                                          onChange={this.expiringFilterClick}
                                          checked={this.state.expiringinfilter === "7d" ? true : false}
                                        />
                                        <Label htmlFor="radio4">Next 7 days</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="expiring" type="radio" id="radio5"
                                          value="15d"
                                          onChange={this.expiringFilterClick}
                                          checked={this.state.expiringinfilter === "15d" ? true : false}
                                        />
                                        <Label htmlFor="radio5">Next 15 days</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="expiring" type="radio" id="radio6"
                                          value="30d"
                                          onChange={this.expiringFilterClick}
                                          checked={this.state.expiringinfilter === "30d" ? true : false}
                                        />
                                        <Label htmlFor="radio6">Next 30 days</Label>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </Col>

                              <Col>
                                <div className="filbx">
                                  <label htmlFor="keyword">Asset Type
                                    {
                                      this.state.assettypefilter.length > 0 ?
                                        <span>({this.state.assettypefilter.filter(v => v !== "all").length})</span>
                                        :
                                        null
                                    }
                                  </label>

                                  <div className="mydropdown">
                                    <a href="javascript:" className="dropbtn" onClick={openFilter}>Any</a>
                                    <div className="filtermenu" id="assetsbx">

                                      <div className="formcol">
                                        <Input className="form-check-input" name="assettypefiltercheck" type="checkbox"
                                          id="assetsshowall"
                                          value="all"
                                          onClick={this.assettypeFilterClick}
                                        />
                                        <Label htmlFor="assetsshowall">Show all</Label>
                                      </div>

                                      {
                                        this.state.assettypes && this.state.assettypes.map((dynamicData, key) =>
                                          <div className="formcol" key={key}>
                                            <Input className="form-check-input" name="assettypefiltercheck" type="checkbox"
                                              id={`ass${dynamicData.id}`}
                                              value={dynamicData.id}
                                              onChange={this.assettypeFilterClick}
                                              checked={this.state.assettypefilter.includes(dynamicData.id)}
                                            />
                                            <Label htmlFor={`ass${dynamicData.id}`}>{dynamicData.get('name')}</Label>
                                          </div>
                                        )
                                      }

                                    </div>
                                  </div>
                                </div>
                              </Col>

                              <Col>
                                <div className="filbx">
                                  <label htmlFor="keyword">Library
                                    {
                                      this.state.libraryfilter.length > 0 ?
                                        <span>({this.state.libraryfilter.filter(v => v !== "all").length})</span>
                                        :
                                        null
                                    }
                                  </label>

                                  <div className="mydropdown">
                                    <a href="javascript:" className="dropbtn" onClick={openFilter}>Any</a>
                                    <div className="filtermenu" id="librarybx">

                                      <div className="formcol">
                                        <Input className="form-check-input" name="libraryfiltercheck" type="checkbox"
                                          id="libshowall"
                                          value="all"
                                          onClick={this.libraryFilterClick}
                                        />
                                        <Label htmlFor="libshowall">Show all</Label>
                                      </div>

                                      {
                                        this.state.medialicenselibrary && this.state.medialicenselibrary.map((dynamicData, key) =>
                                          <div className="formcol" key={key}>
                                            <Input className="form-check-input" name="libraryfiltercheck" type="checkbox"
                                              id={`lib${dynamicData.value}`}
                                              value={dynamicData.value}
                                              onChange={this.libraryFilterClick}
                                              checked={this.state.libraryfilter.includes(dynamicData.value)}
                                            />
                                            <Label htmlFor={`lib${dynamicData.value}`}>{dynamicData.label}</Label>
                                          </div>
                                        )
                                      }

                                    </div>
                                  </div>
                                </div>
                              </Col>

                              <Col>
                                <div className="filbx">
                                  <label htmlFor="keyword">Agency
                                    {
                                      this.state.agencyfilter.length > 0 ?
                                        <span>({this.state.agencyfilter.filter(v => v !== "all").length})</span>
                                        :
                                        null
                                    }
                                  </label>

                                  <div className="mydropdown">
                                    <a href="javascript:" className="dropbtn" onClick={openFilter}>Any</a>
                                    <div className="filtermenu" id="agencygbx">

                                      <div className="formcol">
                                        <Input className="form-check-input" name="agencyfiltercheck" type="checkbox"
                                          id="agenshowall"
                                          value="all"
                                          onClick={this.agencyFilterClick}
                                        />
                                        <Label htmlFor="agenshowall">Show all</Label>
                                      </div>

                                      {
                                        this.state.agencylist && this.state.agencylist.map((dynamicData, key) =>
                                          <div className="formcol" key={key}>
                                            <Input className="form-check-input" name="agencyfiltercheck" type="checkbox"
                                              id={`agen${dynamicData.value}`}
                                              value={dynamicData.value}
                                              onChange={this.agencyFilterClick}
                                              checked={this.state.agencyfilter.includes(dynamicData.value)}
                                            />
                                            <Label htmlFor={`agen${dynamicData.value}`}>{dynamicData.label}</Label>
                                          </div>
                                        )
                                      }

                                    </div>
                                  </div>
                                </div>
                              </Col>

                              <Col>
                                <div className="filbx noborder">
                                  <label htmlFor="keyword">Added on
                                    {
                                      this.state.addedonfilter ?
                                        <span>({this.state.addedonfilter})</span>
                                        :
                                        null
                                    }
                                  </label>

                                  <div className="mydropdown">
                                    <a href="javascript:" className="dropbtn" onClick={openFilter}>Any</a>
                                    <div className="filtermenu right" id="addedonbx">
                                      <div className="formcol">
                                        <Input className="form-check-input" name="addedon" type="radio" id="addedon1"
                                          value="all"
                                          onChange={this.addedonFilterClick}
                                          checked={this.state.addedonfilter === "all" ? true : false}
                                        />
                                        <Label htmlFor="addedon1">Added anytime</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="addedon" type="radio" id="addedon2"
                                          value="24h"
                                          onChange={this.addedonFilterClick}
                                          checked={this.state.addedonfilter === "24h" ? true : false}
                                        />
                                        <Label htmlFor="addedon2">Last 24 hrs</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="addedon" type="radio" id="addedon3"
                                          value="3d"
                                          onChange={this.addedonFilterClick}
                                          checked={this.state.addedonfilter === "3d" ? true : false}
                                        />
                                        <Label htmlFor="addedon3">Last 3 days</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="addedon" type="radio" id="addedon4"
                                          value="7d"
                                          onChange={this.addedonFilterClick}
                                          checked={this.state.addedonfilter === "7d" ? true : false}
                                        />
                                        <Label htmlFor="addedon4">Last 7 days</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="addedon" type="radio" id="addedon5"
                                          value="15d"
                                          onChange={this.addedonFilterClick}
                                          checked={this.state.addedonfilter === "15d" ? true : false}
                                        />
                                        <Label htmlFor="addedon5">Last 15 days</Label>
                                      </div>
                                      <div className="formcol">
                                        <Input className="form-check-input" name="addedon" type="radio" id="addedon6"
                                          value="30d"
                                          onChange={this.addedonFilterClick}
                                          checked={this.state.addedonfilter === "30d" ? true : false}
                                        />
                                        <Label htmlFor="addedon6">Last 30 days</Label>
                                      </div>
                                      <div className="formcol w100 mb-0 mt-3">
                                        <Input className="form-check-input" name="addedon" type="radio" id="addedon7"
                                          value="range"
                                          onChange={this.addedonFilterClick}
                                          checked={this.state.addedonfilter === "range" ? true : false}
                                        />
                                        <Label htmlFor="addedon7">
                                          <Row className="lowgap">
                                            <Col className="col-md-6">
                                              <InputGroup>
                                                {/* <InputGroupAddon addonType="prepend"> */}
                                                <InputGroupText className='prepend'><i className="fas fa-calendar-alt"></i></InputGroupText>
                                                {/* </InputGroupAddon> */}

                                                <DatePicker
                                                  onClickOutside={this.datePickerValue}
                                                  maxDate={new Date()}
                                                  dateFormat="dd-MM-yyyy"
                                                  yearDropdownItemNumber={100}
                                                  scrollableYearDropdown={true}
                                                  showYearDropdown
                                                  showMonthDropdown
                                                  placeholderText="Start Date"
                                                  // value={this.state.addedonfilterstart}
                                                  onChange={(selectedOption) => {
                                                    this.setState({ addedonfilterstart: selectedOption });
                                                  }}
                                                  selected={this.state.addedonfilterstart}
                                                />

                                                {/* <Datetime
                                                  locale="en-gb"
                                                  timeFormat={false}
                                                  dateFormat="DD/MM/YYYY"
                                                  inputProps={{ placeholder: "Start Date" }}
                                                  onChange={(selectedOption) => {
                                                    this.setState({ addedonfilterstart: selectedOption.toDate() });
                                                  }}
                                                  value={this.state.addedonfilterstart}
                                                /> */}

                                              </InputGroup>
                                            </Col>
                                            <Col className="col-md-6">
                                              <InputGroup>
                                                {/* <InputGroupAddon addonType="prepend"> */}
                                                <InputGroupText className='prepend'><i className="fas fa-calendar-alt"></i></InputGroupText>
                                                {/* </InputGroupAddon> */}

                                                <DatePicker
                                                  onClickOutside={this.datePickerValue}
                                                  maxDate={new Date()}
                                                  dateFormat="dd-MM-yyyy"
                                                  yearDropdownItemNumber={100}
                                                  scrollableYearDropdown={true}
                                                  showYearDropdown
                                                  showMonthDropdown
                                                  placeholderText="End Date"
                                                  onChange={(selectedOption) => {
                                                    this.setState({ addedonfilterend: selectedOption });
                                                  }}
                                                  selected={this.state.addedonfilterend}
                                                />

                                                {/* <Datetime
                                                  locale="en-gb"
                                                  timeFormat={false}
                                                  dateFormat="DD/MM/YYYY"
                                                  inputProps={{ placeholder: "End Date" }}
                                                  onChange={(selectedOption) => {
                                                    this.setState({ addedonfilterend: selectedOption.toDate() });
                                                  }}
                                                  value={this.state.addedonfilterend}
                                                /> */}

                                              </InputGroup>
                                            </Col>
                                          </Row>
                                        </Label>
                                      </div>



                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col className="col-auto">
                          <div className="filbx noborder">
                            <Button className="btn btn-default greensearch"
                              onClick={
                                async (event) => {
                                  this.genericFilter();
                                }
                              }
                            >Search</Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </div>



























              <div className="assetsfilterrow2 mt-4">
                <Row className="row justify-content-end align-items-center">
                  {/* <Col className="col-auto">
                    <div className="selectbx">
                      <Form>
                        <FormGroup className="form-group">
                          <Input type="select" className="custom-select">
                            <option value="">Most recent</option>
                            <option>Oldest</option>
                            <option>Newest</option>
                            <option>Expired</option>
                          </Input>
                        </FormGroup>
                      </Form>
                    </div>
                  </Col> */}


                  <Col className="col-auto">
                    <div className="favoritesbx">
                      <Form>
                        <FormGroup className="form-group" inline>
                          <div className="form-check form-check-inline">
                            <Input className="form-check-input" type="checkbox" id="inlineCheckboxarchive" value="true"
                              onClick={
                                async (event) => {
                                  this.setState({ archivefilter: event.target.checked }, function () {
                                    this.genericFilter()
                                  });
                                }
                              }
                            />
                            <Label htmlFor="inlineCheckboxarchive">Archived</Label>
                          </div>
                        </FormGroup>
                      </Form>
                    </div>
                  </Col>


                  <Col className="col-auto">
                    <div className="favoritesbx">
                      <Form>
                        <FormGroup className="form-group" inline>
                          <div className="form-check form-check-inline">
                            <Input className="form-check-input" type="checkbox" id="inlineCheckbox5" value="true"
                              onClick={
                                async (event) => {
                                  this.setState({ favoritefilter: event.target.checked }, function () {
                                    this.genericFilter()
                                  });
                                }
                              }
                            />
                            <Label htmlFor="inlineCheckbox5">Favorites</Label>
                          </div>
                        </FormGroup>
                      </Form>
                    </div>
                  </Col>


                  <Col className="col-auto">

                    <div className="viewbx">
                      <a href="javascript:" className="viewbtngreen active" id="gridview"
                        onClick={
                          async () => {
                            jQuery('#gridview').addClass("active");
                            jQuery('#calenderview').removeClass("active");
                            jQuery('#assettab').removeClass("d-none");
                            jQuery('#calendertab').addClass("d-none");
                          }
                        }
                      >Grid View</a>
                      <a href="javascript:" className="viewbtncalender ml-3" id="calenderview"
                        onClick={
                          async () => {
                            jQuery('#calenderview').addClass("active");
                            jQuery('#gridview').removeClass("active");
                            jQuery('#calendertab').removeClass("d-none");
                            jQuery('#assettab').addClass("d-none");
                          }
                        }
                      >Calender View</a>
                    </div>

                  </Col>
                </Row>
              </div>

            </Col>
          </Row>
        </div>
        {/* Header Div end */}

        <div className="assetslisting" id="assettab">



          {/* Loader button */}
          {
            this.state.mainDataLoad === true ?
              <div className="loaderwrap liblist"><div className="loader"></div></div>
              :
              null
          }
          {/* Loader button */}


          <Nav tabs>
            <NavItem>
              <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTabs('1'); }}>
                Standard
                <span>
                  ({
                    this.state.standardData ? this.state.standardData.length : 0
                  })
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTabs('2'); }}>
                Non-Editorial
                <span>
                  ({
                    this.state.nonEditorialData ? this.state.nonEditorialData.length : 0
                  })
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggleTabs('3'); }}>
                Editorial
                <span>
                  ({
                    this.state.editorialData ? this.state.editorialData.length : 0
                  })
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={classnames({ active: this.state.activeTab === '4' })} onClick={() => { this.toggleTabs('4'); }}>
                Royalty Free
                <span>
                  ({
                    this.state.royaltyData ? this.state.royaltyData.length : 0
                  })
                </span>
              </NavLink>
            </NavItem>
          </Nav>



          <TabContent activeTab={this.state.activeTab}>

            <TabPane tabId="1">
              <Row>
                {
                  this.state.standardData && this.state.standardData.length > 0 ?

                    this.state.standardData.map((dynamicData, key) => {

                      var date1 = new Date();
                      var date2 = new Date(dynamicData.createdAt);
                      var diffDays = parseInt((date1 - date2) / (1000 * 60 * 60 * 24), 10);
                      diffDays = Math.abs(diffDays);

                      var expiring = "";
                      var expiredstat = "";
                      if (dynamicData.get('expiry')) {
                        var edate1 = new Date();
                        var edate2 = new Date(dynamicData.get('expiry'));
                        var ediffDays = parseInt((edate2 - edate1) / (1000 * 60 * 60 * 24), 10);
                        ediffDays = ediffDays + 1;
                        if (ediffDays <= 30 && ediffDays > 0) {
                          expiring = ediffDays;
                        }
                        if (ediffDays <= 0) {
                          expiredstat = "Expired";
                        }
                      }

                      return (

                        <Col md="4" key={key}>
                          <div className="assetscard">
                            <Row className="justify-content-between mb-2">
                              <Col>
                                <h2>ID# {dynamicData.get('assetid') ? dynamicData.get('assetid') : ""}</h2>
                              </Col>
                              <Col className="col-auto  p-0">
                                <div className="favouritesassets">
                                  <Input name="tdType" type="checkbox" id={`stand${key}`}
                                    value={dynamicData.id}
                                    onClick={this.setFavorite}
                                    className={dynamicData.get('favorite') === true ? "active" : ""}
                                  />
                                  <Label htmlFor={`stand${key}`}>Favorites</Label>
                                </div>
                              </Col>
                              <Col className="col-auto">
                                {
                                  this.state.fullDataLoad === true ?
                                    <Loader type="Oval" color="#fff" height={20} width={20} />
                                    :
                                    <UncontrolledDropdown nav inNavbar className="dotsmenu">
                                      <DropdownToggle nav>
                                        <i className="fas fa-ellipsis-v"></i>
                                      </DropdownToggle>
                                      <DropdownMenu right>
                                        <DropdownItem data-objectid={dynamicData.id} onClick={this.viewpopuptoggle}>
                                          View
                                        </DropdownItem>
                                        <DropdownItem data-objectid={dynamicData.id} onClick={this.editpopuptoggle}>
                                          Edit
                                        </DropdownItem>
                                        <DropdownItem data-objectid={dynamicData.id} onClick={this.deletepopuptoggle}>
                                          Delete
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                }
                              </Col>
                            </Row>

                            <div className="imgbx">
                              <a href="javascript:" data-objectid={dynamicData.id} onClick={this.viewpopuptoggle}>
                                <AssetImages
                                  objid={dynamicData.id}
                                  imageurl={this.getAssetUrl(dynamicData.get('assetimage'))}
                                // imageurl={dynamicData.awsImageUrl}
                                />

                                {/* <Assetimage
                                  objid={dynamicData.id}
                                  imageurl={dynamicData.get('assetimage') ? dynamicData.get('assetimage')[0] : ""}
                                  updateIDforImage={this.state.updateIDforImage}
                                  popupstatus={this.state.popupstatus}
                                /> */}
                                {/* {dynamicData.awsImageUrl ?
                                  <img data-objectid={dynamicData.id} src={dynamicData.awsImageUrl} alt='img' />
                                  :
                                  <p>Loading...</p>} */}
                                {/* <Assetimage
                                  objid={dynamicData.id}
                                  imageurl={dynamicData.awsImageUrl ? dynamicData.awsImageUrl : ""}
                                  updateIDforImage={this.state.updateIDforImage}
                                  popupstatus={this.state.popupstatus}
                                /> */}
                              </a>
                            </div>

                            <Row>
                              <Col>
                                <ScrollArea smoothScrolling={0.8} className="thinsscroll" style={{ maxHeight: '80px', minHeight: '80px' }}>
                                  <p>{dynamicData.get('description')}</p>
                                </ScrollArea>
                              </Col>
                            </Row>

                            <Row className="align-items-center mt-3">
                              <Col>
                                <div className="librarydetail">
                                  {
                                    dynamicData.get('assettypetext') === "Image" && iconCamera ?
                                      <img src={iconCamera} alt="img" />
                                      :
                                      dynamicData.get('assettypetext') === "Video" && iconMedia ?
                                        <img src={iconMedia} alt="img" />
                                        :
                                        dynamicData.get('assettypetext') === "Audio" && iconAudio ?
                                          <img src={iconAudio} alt="img" />
                                          :
                                          dynamicData.get('assettypetext') === "Vector" && iconPencil ?
                                            <img src={iconPencil} alt="img" />
                                            :
                                            null
                                  }
                                  {dynamicData.get('medialicenselibraryid') ? dynamicData.get('medialicenselibraryid').get('name') : ""}
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col className="col-auto">
                                {
                                  diffDays === 0 ?
                                    <div className="timeago">Added today</div>
                                    :
                                    <div className="timeago">Added {diffDays} days ago</div>
                                }
                              </Col>
                              <Col className="pl-0">
                                {
                                  expiring !== "" ?
                                    <span className="expiringsoon">Expiring in {expiring} days</span>
                                    :
                                    null
                                }
                                {
                                  expiredstat !== "" ?
                                    <span className="expiringsoon">{expiredstat}</span>
                                    :
                                    null
                                }
                              </Col>
                            </Row>

                          </div>
                        </Col>
                      )
                    })
                    :
                    this.state.fullDataLoad !== true ?
                      <Col md="4">
                        <div className="assetscard">
                          <p className="whitetext">No record found</p>
                        </div>
                      </Col>
                      :
                      null
                }
              </Row>
            </TabPane>

            <TabPane tabId="2">
              <Row>
                {
                  this.state.nonEditorialData && this.state.nonEditorialData.length > 0 ?

                    this.state.nonEditorialData.map((dynamicData, key) => {

                      var date1 = new Date();
                      var date2 = new Date(dynamicData.createdAt);
                      var diffDays = parseInt((date1 - date2) / (1000 * 60 * 60 * 24), 10);
                      diffDays = Math.abs(diffDays);




                      var expiring = "";
                      var expiredstat = "";
                      if (dynamicData.get('expiry')) {
                        var edate1 = new Date();
                        var edate2 = new Date(dynamicData.get('expiry'));
                        var ediffDays = parseInt((edate2 - edate1) / (1000 * 60 * 60 * 24), 10);
                        ediffDays = ediffDays + 1;
                        if (ediffDays <= 30 && ediffDays > 0) {
                          expiring = ediffDays;
                        }
                        if (ediffDays <= 0) {
                          expiredstat = "Expired";
                        }
                      }

                      return (

                        <Col md="4">
                          <div className="assetscard">
                            <Row className="justify-content-between mb-2">
                              <Col>
                                <h2>ID# {dynamicData.get('assetid') ? dynamicData.get('assetid') : ""}</h2>
                              </Col>
                              <Col className="col-auto p-0">
                                <div className="favouritesassets">
                                  <Input name="tdType" type="checkbox" id={`nonedit${key}`}
                                    value={dynamicData.id}
                                    onClick={this.setFavorite}
                                    className={dynamicData.get('favorite') === true ? "active" : ""}
                                  />
                                  <Label htmlFor={`nonedit${key}`}>Favorites</Label>
                                </div>
                              </Col>
                              <Col className="col-auto">

                                {
                                  this.state.fullDataLoad === true ?
                                    <Loader type="Oval" color="#fff" height={20} width={20} />
                                    :
                                    <UncontrolledDropdown nav inNavbar className="dotsmenu">
                                      <DropdownToggle nav>
                                        <i className="fas fa-ellipsis-v"></i>
                                      </DropdownToggle>
                                      <DropdownMenu right>
                                        <DropdownItem data-objectid={dynamicData.id} onClick={this.viewpopuptoggle}>
                                          View
                                        </DropdownItem>
                                        <DropdownItem data-objectid={dynamicData.id} onClick={this.editpopuptoggle}>
                                          Edit
                                        </DropdownItem>
                                        <DropdownItem data-objectid={dynamicData.id} onClick={this.deletepopuptoggle}>
                                          Delete
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                }


                              </Col>
                            </Row>

                            <div className="imgbx">
                              <a href="javascript:" onClick={this.viewpopuptoggle}>
                                <AssetImages
                                  objid={dynamicData.id}
                                  imageurl={this.getAssetUrl(dynamicData.get('assetimage'))}
                                // imageurl={dynamicData.awsImageUrl}
                                />
                                {/* <Assetimage
                                  objid={dynamicData.id}
                                  imageurl={dynamicData.get('assetimage') ? dynamicData.get('assetimage')[0] : ""}
                                  updateIDforImage={this.state.updateIDforImage}
                                  popupstatus={this.state.popupstatus}
                                /> */}
                              </a>
                            </div>

                            <Row>
                              <Col>
                                <ScrollArea smoothScrolling={0.8} className="thinsscroll" style={{ maxHeight: '80px', minHeight: '80px' }}>
                                  <p>{dynamicData.get('description')}</p>
                                </ScrollArea>
                              </Col>
                            </Row>

                            <Row className="align-items-center mt-3">
                              <Col>
                                <div className="librarydetail">
                                  {
                                    dynamicData.get('assettypetext') === "Image" && iconCamera ?
                                      <img src={iconCamera} alt="img" />
                                      :
                                      dynamicData.get('assettypetext') === "Video" && iconMedia ?
                                        <img src={iconMedia} alt="img" />
                                        :
                                        dynamicData.get('assettypetext') === "Audio" && iconAudio ?
                                          <img src={iconAudio} alt="img" />
                                          :
                                          dynamicData.get('assettypetext') === "Vector" && iconPencil ?
                                            <img src={iconPencil} alt="img" />
                                            :
                                            null
                                  }
                                  {dynamicData.get('medialicenselibraryid') ? dynamicData.get('medialicenselibraryid').get('name') : ""}
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col className="col-auto">
                                {
                                  diffDays === 0 ?
                                    <div className="timeago">Added today</div>
                                    :
                                    <div className="timeago">Added {diffDays} days ago</div>
                                }
                              </Col>
                              <Col className="pl-0">
                                {
                                  expiring !== "" ?
                                    <span className="expiringsoon">Expiring in {expiring} days</span>
                                    :
                                    null
                                }
                                {
                                  expiredstat !== "" ?
                                    <span className="expiringsoon">{expiredstat}</span>
                                    :
                                    null
                                }
                              </Col>
                            </Row>

                          </div>
                        </Col>
                      )
                    })
                    :
                    this.state.fullDataLoad !== true ?
                      <Col md="4">
                        <div className="assetscard">
                          <p className="whitetext">No record found</p>
                        </div>
                      </Col>
                      :
                      null
                }
              </Row>
            </TabPane>

            <TabPane tabId="3">
              <Row>
                {
                  this.state.editorialData && this.state.editorialData.length > 0 ?

                    this.state.editorialData.map((dynamicData, key) => {

                      var date1 = new Date();
                      var date2 = new Date(dynamicData.createdAt);
                      var diffDays = parseInt((date1 - date2) / (1000 * 60 * 60 * 24), 10);
                      diffDays = Math.abs(diffDays);

                      var expiring = "";
                      var expiredstat = "";
                      if (dynamicData.get('expiry')) {
                        var edate1 = new Date();
                        var edate2 = new Date(dynamicData.get('expiry'));
                        var ediffDays = parseInt((edate2 - edate1) / (1000 * 60 * 60 * 24), 10);
                        ediffDays = ediffDays + 1;
                        if (ediffDays <= 30 && ediffDays > 0) {
                          expiring = ediffDays;
                        }
                        if (ediffDays <= 0) {
                          expiredstat = "Expired";
                        }
                      }

                      return (

                        <Col md="4">
                          <div className="assetscard">
                            <Row className="justify-content-between mb-2">
                              <Col>
                                <h2>ID# {dynamicData.get('assetid') ? dynamicData.get('assetid') : ""}</h2>
                              </Col>
                              <Col className="col-auto  p-0">
                                <div className="favouritesassets">
                                  <Input name="tdType" type="checkbox" id={`editorial${dynamicData.id}`}
                                    className={dynamicData.get('favorite') === true ? "active" : ""}
                                    value={dynamicData.id}
                                    onClick={this.setFavorite}
                                  />
                                  <Label htmlFor={`editorial${dynamicData.id}`}>Favorites</Label>
                                </div>
                              </Col>
                              <Col className="col-auto">
                                {
                                  this.state.fullDataLoad === true ?
                                    <Loader type="Oval" color="#fff" height={20} width={20} />
                                    :
                                    <UncontrolledDropdown nav inNavbar className="dotsmenu">
                                      <DropdownToggle nav>
                                        <i className="fas fa-ellipsis-v"></i>
                                      </DropdownToggle>
                                      <DropdownMenu right>
                                        <DropdownItem data-objectid={dynamicData.id} onClick={this.viewpopuptoggle}>
                                          View
                                        </DropdownItem>
                                        <DropdownItem data-objectid={dynamicData.id} onClick={this.editpopuptoggle}>
                                          Edit
                                        </DropdownItem>
                                        <DropdownItem data-objectid={dynamicData.id} onClick={this.deletepopuptoggle}>
                                          Delete
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                }
                              </Col>
                            </Row>

                            <div className="imgbx">
                              <a href="javascript:" data-objectid={dynamicData.id} onClick={this.viewpopuptoggle}>
                                <AssetImages
                                  objid={dynamicData.id}
                                  imageurl={this.getAssetUrl(dynamicData.get('assetimage'))}
                                // imageurl={dynamicData.awsImageUrl}
                                />
                                {/* <Assetimage
                                  objid={dynamicData.id}
                                  imageurl={dynamicData.get('assetimage') ? dynamicData.get('assetimage')[0] : ""}
                                  updateIDforImage={this.state.updateIDforImage}
                                  popupstatus={this.state.popupstatus}
                                /> */}
                              </a>
                            </div>

                            <Row>
                              <Col>
                                <ScrollArea smoothScrolling={0.8} className="thinsscroll" style={{ maxHeight: '80px', minHeight: '80px' }}>
                                  <p>{dynamicData.get('description')}</p>
                                </ScrollArea>
                              </Col>
                            </Row>

                            <Row className="align-items-center mt-3">
                              <Col>
                                <div className="librarydetail">
                                  {
                                    dynamicData.get('assettypetext') === "Image" && iconCamera ?
                                      <img src={iconCamera} alt="img" />
                                      :
                                      dynamicData.get('assettypetext') === "Video" && iconMedia ?
                                        <img src={iconMedia} alt="img" />
                                        :
                                        dynamicData.get('assettypetext') === "Audio" && iconAudio ?
                                          <img src={iconAudio} alt="img" />
                                          :
                                          dynamicData.get('assettypetext') === "Vector" && iconPencil ?
                                            <img src={iconPencil} alt="img" />
                                            :
                                            null
                                  }
                                  {dynamicData.get('medialicenselibraryid') ? dynamicData.get('medialicenselibraryid').get('name') : ""}
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col className="col-auto">
                                {
                                  diffDays === 0 ?
                                    <div className="timeago">Added today</div>
                                    :
                                    <div className="timeago">Added {diffDays} days ago</div>
                                }
                              </Col>
                              <Col className="pl-0">
                                {
                                  expiring !== "" ?
                                    <span className="expiringsoon">Expiring in {expiring} days</span>
                                    :
                                    null
                                }
                                {
                                  expiredstat !== "" ?
                                    <span className="expiringsoon">{expiredstat}</span>
                                    :
                                    null
                                }
                              </Col>
                            </Row>

                          </div>
                        </Col>

                      )
                    })
                    :
                    this.state.fullDataLoad !== true ?
                      <Col md="4">
                        <div className="assetscard">
                          <p className="whitetext">No record found</p>
                        </div>
                      </Col>
                      :
                      null
                }
              </Row>
            </TabPane>

            <TabPane tabId="4">
              <Row>
                {
                  this.state.royaltyData && this.state.royaltyData.length > 0 ?

                    this.state.royaltyData.map((dynamicData, key) => {

                      var date1 = new Date();
                      var date2 = new Date(dynamicData.createdAt);
                      var diffDays = parseInt((date1 - date2) / (1000 * 60 * 60 * 24), 10);
                      diffDays = Math.abs(diffDays);

                      var expiring = "";
                      var expiredstat = "";
                      if (dynamicData.get('expiry')) {
                        var edate1 = new Date();
                        var edate2 = new Date(dynamicData.get('expiry'));
                        var ediffDays = parseInt((edate2 - edate1) / (1000 * 60 * 60 * 24), 10);
                        ediffDays = ediffDays + 1;
                        if (ediffDays <= 30 && ediffDays > 0) {
                          expiring = ediffDays;
                        }
                        if (ediffDays <= 0) {
                          expiredstat = "Expired";
                        }
                      }

                      return (

                        <Col md="4">
                          <div className="assetscard">
                            <Row className="justify-content-between mb-2">
                              <Col>
                                <h2>ID# {dynamicData.get('assetid') ? dynamicData.get('assetid') : ""}</h2>
                              </Col>
                              <Col className="col-auto  p-0">
                                <div className="favouritesassets">
                                  <Input name="tdType" type="checkbox" id={`royalty${key}`}
                                    value={dynamicData.id}
                                    onClick={this.setFavorite}
                                    className={dynamicData.get('favorite') === true ? "active" : ""}
                                  />
                                  <Label htmlFor={`royalty${key}`}>Favorites</Label>
                                </div>
                              </Col>
                              <Col className="col-auto">
                                {
                                  this.state.fullDataLoad === true ?
                                    <Loader type="Oval" color="#fff" height={20} width={20} />
                                    :
                                    <UncontrolledDropdown nav inNavbar className="dotsmenu">
                                      <DropdownToggle nav>
                                        <i className="fas fa-ellipsis-v"></i>
                                      </DropdownToggle>
                                      <DropdownMenu right>
                                        <DropdownItem data-objectid={dynamicData.id} onClick={this.viewpopuptoggle}>
                                          View
                                        </DropdownItem>
                                        <DropdownItem data-objectid={dynamicData.id} onClick={this.editpopuptoggle}>
                                          Edit
                                        </DropdownItem>
                                        <DropdownItem data-objectid={dynamicData.id} onClick={this.deletepopuptoggle}>
                                          Delete
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                }
                              </Col>
                            </Row>

                            <div className="imgbx">
                              <a href="javascript:" data-objectid={dynamicData.id} onClick={this.viewpopuptoggle}>
                                <AssetImages
                                  objid={dynamicData.id}
                                  // imageurl={dynamicData.awsImageUrl}
                                  imageurl={this.getAssetUrl(dynamicData.get('assetimage'))}
                                />
                                {/* <Assetimage
                                  objid={dynamicData.id}
                                  imageurl={dynamicData.get('assetimage') ? dynamicData.get('assetimage')[0] : ""}
                                  updateIDforImage={this.state.updateIDforImage}
                                  popupstatus={this.state.popupstatus}
                                /> */}
                              </a>
                            </div>

                            <Row>
                              <Col>
                                <ScrollArea smoothScrolling={0.8} className="thinsscroll" style={{ maxHeight: '80px', minHeight: '80px' }}>
                                  <p>{dynamicData.get('description')}</p>
                                </ScrollArea>
                              </Col>
                            </Row>

                            <Row className="align-items-center mt-3">
                              <Col>
                                <div className="librarydetail">
                                  {
                                    dynamicData.get('assettypetext') === "Image" && iconCamera ?
                                      <img src={iconCamera} alt="img" />
                                      :
                                      dynamicData.get('assettypetext') === "Video" && iconMedia ?
                                        <img src={iconMedia} alt="img" />
                                        :
                                        dynamicData.get('assettypetext') === "Audio" && iconAudio ?
                                          <img src={iconAudio} alt="img" />
                                          :
                                          dynamicData.get('assettypetext') === "Vector" && iconPencil ?
                                            <img src={iconPencil} alt="img" />
                                            :
                                            null
                                  }
                                  {dynamicData.get('medialicenselibraryid') ? dynamicData.get('medialicenselibraryid').get('name') : ""}
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col className="col-auto">
                                {
                                  diffDays === 0 ?
                                    <div className="timeago">Added today</div>
                                    :
                                    <div className="timeago">Added {diffDays} days ago</div>
                                }
                              </Col>
                              <Col className="pl-0">
                                {
                                  expiring !== "" ?
                                    <span className="expiringsoon">Expiring in {expiring} days</span>
                                    :
                                    null
                                }
                                {
                                  expiredstat !== "" ?
                                    <span className="expiringsoon">{expiredstat}</span>
                                    :
                                    null
                                }
                              </Col>
                            </Row>

                          </div>
                        </Col>

                      )
                    })
                    :
                    this.state.fullDataLoad !== true ?
                      <Col md="4">
                        <div className="assetscard">
                          <p className="whitetext">No record found</p>
                        </div>
                      </Col>
                      :
                      null
                }
              </Row>
            </TabPane>

          </TabContent>

        </div>


        <div id="calendertab" className="d-none">
          <Calendarview
            viewListFull={this.state.fullAssetData ? this.state.fullAssetData : null}
            viewStatus={(viewid) => {
              this.setState({
                viewpopup: !this.state.viewpopup
              });
              this.setObjectID(viewid);
            }}
          />
        </div>


        {this.state.addnewpopup ?


          <Addasset
            isOpen={this.state.addnewpopup}
            toggle={this.addnewpopuptoggle}
            licenseItems={this.state.licenseItems}
            medialicenselibrary={this.state.medialicenselibrary}
            assettypes={this.state.assettypes}
            georestrictions={this.state.georestrictions}
            agencylist={this.state.agencylist}
            brandlist={this.state.brandlist}
            taglist={this.state.taglist}
            addStatus={async (addstatus) => {
              if (addstatus.id) {

                this.setObjectID(addstatus.id);
                this.setState({ updateIDforImage: addstatus.id });

                await this.getDataUpdate(addstatus.get('licensetypename'), addstatus);

                this.setState({
                  addnewpopup: !this.state.addnewpopup
                });
                this.setState({
                  viewpopup: !this.state.viewpopup
                });

              }
            }}
          /> : ""}


        {this.state.editpopup ?
          <Editasset
            isOpen={this.state.editpopup}
            toggle={this.editpopuptoggle}
            licenseItems={this.state.licenseItems}
            medialicenselibrary={this.state.medialicenselibrary}
            assettypes={this.state.assettypes}
            georestrictions={this.state.georestrictions}
            agencylist={this.state.agencylist}
            brandlist={this.state.brandlist}
            taglist={this.state.taglist}
            editObjectID={this.state.editObjectID}
            editRecordStatus={(editstatus, licensetypenameprev = null) => {
              if (editstatus.id) {
                this.setState({ updateIDforImage: editstatus.id });

                setTimeout(
                  function () {
                    this.setState({
                      deleteClicked: false,
                      popupstatus: "view",
                      editpopup: !this.state.editpopup,
                      viewpopup: !this.state.viewpopup,
                    });
                  }
                    .bind(this),
                  1000
                );

                if (licensetypenameprev === editstatus.get('licensetypename')) {
                  console.log('only one');
                  this.getDataUpdate(editstatus.get('licensetypename'), editstatus);
                } else {
                  console.log('two items')
                  this.getDataUpdate(licensetypenameprev);
                  this.getDataUpdate(editstatus.get('licensetypename'), editstatus);
                }




              }
            }}
          /> : ""

        }


        {
          this.state.viewpopup ?

            <Viewassets
              isOpen={this.state.viewpopup}
              toggle={this.viewpopuptoggle}
              viewObjectID={this.state.editObjectID}
              viewListFull={this.state.fullAssetData ? this.state.fullAssetData : null}
              deleteClicked={this.state.deleteClicked ? this.state.deleteClicked : false}
              editStatus={(editid) => {
                this.setState({
                  editpopup: !this.state.editpopup
                });
                this.setState({
                  viewpopup: false
                });
                this.setObjectID(editid);
              }}
              deletedStatus={(deleteobj) => {
                this.setState({
                  deletedID: deleteobj.id,
                  deletedName: deleteobj.get('title'),
                });
                this.setState({
                  deleteClicked: false
                });
                this.setState({
                  viewpopup: !this.state.viewpopup
                });

                this.getDataUpdate(deleteobj.get('licensetypename'));

                setTimeout(function () {
                  this.setState({
                    deletedID: null,
                  });
                }.bind(this), 10000);

              }}
              duplicateRecordStatus={(editstatus) => {
                if (editstatus) {
                  this.getDataUpdate(editstatus.get('licensetypename'));
                }
              }}
              assetsAWSURL={this.state.assetsAWSURL}
            /> : ""}


        {
          this.state.deletedID ?
            <Alert className="deleteundo" color="warning" isOpen={this.state.visible} toggle={this.onDismiss}>
              The Library '{this.state.deletedName}' has been deleted <a href="javacript:" onClick={this.undoRecord} className="alert-link"><i className="fas fa-undo"></i> Undo</a>
            </Alert>
            :
            null
        }



      </div>
    )
  }
}

export default mediaAssets;
