// import React from 'react';
import React, { Component, useState, useEffect } from "react";
import { getFileUrl } from '../../../services/awsFileUpload';

class Assetimagebulk extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      awsImageUrl: "",
    };

  }

  async componentDidMount(evt) {

    if (this.props.imageurl && this.props.imageurl !== "") {
      var awsurl = await this.getImageURL(this.props.imageurl);
      this.setState({ awsImageUrl: awsurl });
    }
  }

  async componentDidUpdate(prevProps, prevState) {

    // if (this.props.updateIDforImage !== "" && this.props.imageurl !== "" && this.props.action === "delete" && this.props.updateIDforImage === prevProps.objid) {
    //   var awsurl = await this.getImageURL(this.props.imageurl);
    //   this.setState({ awsImageUrl: awsurl });
    //   console.log('after delete');

    // }

    // if (this.props.imageurl !== "" && this.props.action === "bulkupload" && prevProps.objid !== this.props.objid) {
    //   var awsurl = await this.getImageURL(this.props.imageurl);
    //   this.setState({ awsImageUrl: awsurl });
    //   console.log('after delete');

    // }

    if (this.props.imageurl !== prevProps.imageurl && this.props.action === "delete") {  //VIEW
      var awsurl = await this.getImageURL(this.props.imageurl);
      this.setState({ awsImageUrl: awsurl });
      console.log('edit image refetch');
    }
    if (this.props.imageurl !== prevProps.imageurl && this.props.action === "bulkupload") {  //VIEW
      var awsurl = await this.getImageURL(this.props.imageurl);
      this.setState({ awsImageUrl: awsurl });
      console.log('edit image refetch');
    }


  }

  getImageURL = async (img) => {
    var imgurl = await getFileUrl("contentmaster", img.awsFileName);
    return imgurl.url;
  }

  render() {
    return (

      this.state.awsImageUrl ?
        <img data-objectid={this.props.objid} src={this.state.awsImageUrl} alt='img'  />
        :
        <p>Loading...</p>

    )
  }
}

export default Assetimagebulk;