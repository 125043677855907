import jQuery from 'jquery';
var Parse = require('parse');

export const getTrademarkStatus = async () => {
    var TrademarkStatuses = Parse.Object.extend("TrademarkStatuses");
    var query = new Parse.Query(TrademarkStatuses);
    query.equalTo("status", true);
    query.descending("createdAt");
    var result = query.find();

    return result;
}
export const getTrademarkProgress = async (tradestatus) => {
    var TrademarkProgress = Parse.Object.extend("TrademarkProgress");
    var query = new Parse.Query(TrademarkProgress);

    query.equalTo('trademarkStatus', {
        __type: 'Pointer',
        className: 'TrademarkStatuses',
        objectId: tradestatus
    });
    query.equalTo("processStatus", 'completed');

    query.descending("createdAt");
    const count = await query.count();
    if (tradestatus) {
        return count;
    } else {
        return 0;
    }
}

export const getTrademarkProcessStatus = async () => {
    var TrademarksMaster = Parse.Object.extend("TrademarksMaster");
    var query = new Parse.Query(TrademarksMaster);
    query.include("step");
    query.descending("createdAt");
    query.limit(1000);

    var result = query.find();
    return result;
}





export const getDomainStatuses = async () => {
    var DomainStatuses = Parse.Object.extend("DomainStatuses");
    var query = new Parse.Query(DomainStatuses);
    query.equalTo("status", true);
    query.descending("createdAt");
    var result = query.find();

    return result;
}
export const getDomains = async (domain_status) => {
    var Domains = Parse.Object.extend("Domains");
    var query = new Parse.Query(Domains);
    query.equalTo("status", true);

    if (domain_status === "undefined") {
        query.equalTo('domain_status',undefined);
    } else {
        query.equalTo('domain_status', {
            __type: 'Pointer',
            className: 'DomainStatuses',
            objectId: domain_status
        });
    }



    query.descending("createdAt");
    const count = await query.count();
    if (domain_status) {
        return count;
    } else {
        return 0;
    }
}

export const getDomainExpiry = async (days) => {
    var Domains = Parse.Object.extend("Domains");
    var query = new Parse.Query(Domains);
    query.equalTo("status", true);

    if (days === 30) {
        var currentdate = new Date();

        var future = new Date();
        future.setDate(future.getDate() + days);

        query.greaterThanOrEqualTo("expiry_date", currentdate);
        query.lessThanOrEqualTo("expiry_date", future);

    } else if (days === 60) {
        var future = new Date();
        future.setDate(future.getDate() + 30);

        var futureplus = new Date();
        futureplus.setDate(futureplus.getDate() + days);

        query.greaterThanOrEqualTo("expiry_date", future);
        query.lessThanOrEqualTo("expiry_date", futureplus);

    } else if (days === 90) {
        var future = new Date();
        future.setDate(future.getDate() + 60);

        var futureplus = new Date();
        futureplus.setDate(futureplus.getDate() + days);

        query.greaterThanOrEqualTo("expiry_date", future);
        query.lessThanOrEqualTo("expiry_date", futureplus);

    }


    query.descending("createdAt");
    const count = await query.count();


    return count;
}
export const getTrademarkExpiry = async (days) => {
    var TrademarkProgress = Parse.Object.extend("TrademarkProgress");
    var query = new Parse.Query(TrademarkProgress);

    if (days === 30) {
        var currentdate = new Date();

        var future = new Date();
        future.setDate(future.getDate() + days);

        query.greaterThanOrEqualTo("nextRenewalDate", currentdate);
        query.lessThanOrEqualTo("nextRenewalDate", future);

    } else if (days === 60) {
        var future = new Date();
        future.setDate(future.getDate() + 30);

        var futureplus = new Date();
        futureplus.setDate(futureplus.getDate() + days);

        query.greaterThanOrEqualTo("nextRenewalDate", future);
        query.lessThanOrEqualTo("nextRenewalDate", futureplus);

    } else if (days === 90) {
        var future = new Date();
        future.setDate(future.getDate() + 60);

        var futureplus = new Date();
        futureplus.setDate(futureplus.getDate() + days);

        query.greaterThanOrEqualTo("nextRenewalDate", future);
        query.lessThanOrEqualTo("nextRenewalDate", futureplus);

    }


    query.descending("createdAt");
    const count = await query.count();

    return count;
}
export const getTrademarkUsedExpiry = async (days) => {
    var TrademarkMaster = Parse.Object.extend("TrademarksMaster");
    var query = new Parse.Query(TrademarkMaster);

    if (days === 30) {
        var currentdate = new Date();

        var future = new Date();
        future.setDate(future.getDate() + days);

        query.greaterThanOrEqualTo("usedDate", currentdate);
        query.lessThanOrEqualTo("usedDate", future);
 
    } else if (days === 60) {
        var future = new Date();
        future.setDate(future.getDate() + 30);

        var futureplus = new Date();
        futureplus.setDate(futureplus.getDate() + days);

        query.greaterThanOrEqualTo("usedDate", future);
        query.lessThanOrEqualTo("usedDate", futureplus);

    } else if (days === 90) {
        var future = new Date();
        future.setDate(future.getDate() + 60);

        var futureplus = new Date();
        futureplus.setDate(futureplus.getDate() + days);

        query.greaterThanOrEqualTo("usedDate", future);
        query.lessThanOrEqualTo("usedDate", futureplus);

    }


    query.descending("createdAt");
    const count = await query.count();
    return count;
}


export const getDomainFull = async () => {
    var Domains = Parse.Object.extend("Domains");
    var query = new Parse.Query(Domains);
    query.equalTo("status", true);
    query.limit(1000);
    query.descending("createdAt");

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}
export const getTrademarkProgressFull = async () => {
    var TrademarkProgress = Parse.Object.extend("TrademarkProgress");
    var query = new Parse.Query(TrademarkProgress);

    query.include("trademark");
    query.descending("createdAt");
    query.limit(1000);

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}











export const getRecentActivityLog = async () => {
    var Logs = Parse.Object.extend("Logs");
    var query = new Parse.Query(Logs);

    query.include("user");
    query.include("domain");
    query.include("finance");
    query.include("trademark");
    query.descending("updatedAt");
    query.limit(100);

    var records = query.find();
    return records;
}

export const getRecentActivityMediaLog = async () => {
    var Logs = Parse.Object.extend("MediaLogs");
    var query = new Parse.Query(Logs);

    query.include("user");
    query.include("library");
    query.include("asset");
    query.include("agencyasset");
    query.include("assetversion");
    query.descending("updatedAt");
    query.limit(100);

    var records = query.find();
    return records;
}

export const getRecentDomainActivity = async () => {
    var Domains = Parse.Object.extend("Domains");
    var query = new Parse.Query(Domains);
    query.descending("createdAt");
    query.limit(2);

    var records = query.find();
    return records;
}
export const getRecentFinanceActivity = async () => {
    var Finance = Parse.Object.extend("Finance");
    var query = new Parse.Query(Finance);
    query.descending("createdAt");
    query.limit(1);

    var records = query.find();
    return records;
}
export const getRecentTrademarkActivity = async () => {
    var TrademarksMaster = Parse.Object.extend("TrademarksMaster");
    var query = new Parse.Query(TrademarksMaster);
    query.descending("createdAt");
    query.limit(2);

    var records = query.find();
    return records;
}













export const getCountries = async () => {
    var Countries = Parse.Object.extend("Countries");
    var query = new Parse.Query(Countries);
    query.equalTo("status", true);
    query.descending("createdAt");

    var records = query.find();
    return records;
}