import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Alert,
  InputGroup,
} from 'reactstrap';
import Select, { components } from "react-select";
import jQuery from 'jquery';
import Loader from '../../components/common/Loader';
import Autocomplete from 'react-autocomplete';
import Datetime from "react-datetime";
import 'react-datetime/css/react-datetime.css';
import FileUpload from '../../components/common/FileUpload';

import { getCurrentUser, authenticateLoggedIn, addHeaderTitle, getParameterByName, isSuperAdmin, authenticateLoginpage, addBreadcrumb } from '../../services/parse';
import { uploadFile, getFileUrl } from '../../services/awsFileUpload';
import {
  addRecord, checkFieldValid, checkValidBySection, getDomainById, getFinanceFull
} from '../../services/finance';
import withRouter from '../../components/common/WithRouter';

var Parse = require('parse');

class addFinance extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      collapse: true,
      fadeIn: true,
      timeout: 300,
      isSuper: '',
      addedclass: 'd-none',
      domainbyidData: [],
      logoFileArray: [],
      datafull: [],
      registrant_email: "",
      isEdit: false,
      logofile: null,
    };
  }


  async componentDidMount(evt) {
    //auth start
    authenticateLoggedIn();
    addHeaderTitle('Add Finance');
    addBreadcrumb('Home / Finance / Add Finance');

    jQuery(".opendefault").next('.card-body').show();
    jQuery(".opendefault").addClass('active');

    var domainId = this.props.params.domainid;
    getDomainById(domainId).then((value) => {
      this.setState({ domainbyidData: value[0], });
      if (value[0] && value[0].get('domain') && value[0].get('domain').get('name')) {
        addHeaderTitle('Add Finance (' + value[0].get('name') + value[0].get('domain').get('name') + ')');
      }
    });

    getFinanceFull().then((value) => {
      this.setState({ datafull: value, });
    });

  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  onLogoFileSelected = async (event) => {
    console.log(event.target.files);
    var files = event.target.files
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        var fileType = files[i].type.split("/")[1];
        var fileName = files[i].name;
        console.log(fileType);
        var uploadStatus = await uploadFile('contentmaster', files[i]);
        console.log(uploadStatus);
        if (uploadStatus.success) {

          this.setState({
            logoFileArray: [
              ...this.state.logoFileArray,
              { awsFileName: uploadStatus.filename, type: fileType, name: fileName }
            ]
          });
        }
      }
    }
    //var logoUpload = await uploadFile('trademark',event.target.value);
  }


  addRecord = () => {
    var valid = checkFieldValid('add');
    if (valid !== false) {
      jQuery('#addbutton').prop('disabled', true);
      jQuery('#addbutton').addClass('d-none');
      jQuery('#loadbutton').removeClass('d-none');


      var domainid = jQuery('#domainid').val();
      var currency = jQuery('#currency').val();
      var yearly_renew_cost = jQuery('#yearly_renew_cost').val();

      var cost_general = jQuery('#cost_general').val();
      var cost_directprivacy = jQuery('#cost_directprivacy').val();

      var icann_fee = jQuery('#icann_fee').val();
      var other_cost = jQuery('#other_cost').val();
      var remarks = jQuery('#remarks').val();
      var direct_privacy = jQuery('#direct_privacy').val();
      var totalcost = jQuery('#totalcost').html();
      var invoice_number = jQuery('#invoice_number').val();
      var account = jQuery('#account').val();
      var invoice_amount = jQuery('#invoice_amount').val();
      var registrant_email = jQuery('#registrant_email').val();

      if (jQuery('#cc_statement_date').val()) {

        var st = jQuery('#cc_statement_date').val();
        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var cc_statement_date = new Date(st.replace(pattern, '$3-$2-$1'));

        // var cc_statement_date = new Date(jQuery('#cc_statement_date').val());   // date
      } else {
        var cc_statement_date = "";
      }

      if (jQuery('#date_of_invoice').val()) {

        var st = jQuery('#date_of_invoice').val();
        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var date_of_invoice = new Date(st.replace(pattern, '$3-$2-$1'));

        // var date_of_invoice = new Date(jQuery('#date_of_invoice').val());  // date
      } else {
        var date_of_invoice = "";
      }

      var transaction_type = jQuery('#transaction_type').val();
      var finance_comments = jQuery('#finance_comments').val();
      var status = jQuery('#status').val();


      var fielddatas = {
        domainid: domainid,
        currency: currency,
        yearly_renew_cost: yearly_renew_cost,
        cost_general: cost_general,
        cost_directprivacy: cost_directprivacy,
        icann_fee: icann_fee,
        other_cost: other_cost,
        remarks: remarks,
        direct_privacy: direct_privacy,
        totalcost: totalcost,
        invoice_number: invoice_number,
        account: account,
        invoice_amount: invoice_amount,
        registrant_email: registrant_email,
        cc_statement_date: cc_statement_date,
        date_of_invoice: date_of_invoice,
        transaction_type: transaction_type,
        finance_comments: finance_comments,
        status: status,
        imagefiles: this.state.logoFileArray
      };

      addRecord(fielddatas).then((value) => {
        if (value !== false) {
          this.setState({ addedclass: 'd-block' });

          setTimeout(
            function () {
              this.setState({ addedclass: 'd-none' });
              window.location.assign('#/finance/editfinance/' + value.id + '/default');
            }
              .bind(this),
            2000
          );

        } else {
          this.setState({ addedclass: 'd-none' });
        }

        this.resetForm();
      });

    }
  }


  resetForm = () => {
    this.setState({ registrant_email: "" });
    this.setState({ cc_statement_date: "" });
    this.setState({ date_of_invoice: "" });

    jQuery('#selectedFiles').html('');
    jQuery('#loadingtag').addClass('d-none');
    jQuery('#loadingtag').removeClass('d-block');

    jQuery('#addform').trigger("reset");
    jQuery('#addbutton').prop('disabled', false);
    jQuery('#loadbutton').addClass('d-none');
    jQuery('#addbutton').removeClass('d-none');
  }


  slidingClick = (event) => {
    jQuery(event.target).parents(".card").find('.card-body').slideToggle('slow');
    jQuery(event.target).toggleClass('active');
  }

  toolTipshow = (event) => {
    jQuery(event.target).next('.tooltipshow').show();
  }
  toolTiphide = (event) => {
    jQuery(event.target).next('.tooltipshow').hide();
  }

  calculateTotalCost = () => {

    var yearly_renew_cost = jQuery('#yearly_renew_cost').val();
    var icann_fee = jQuery('#icann_fee').val();
    var cost_general = jQuery('#cost_general').val();
    var cost_directprivacy = jQuery('#cost_directprivacy').val();
    var other_cost = jQuery('#other_cost').val();
    var direct_privacy = jQuery('#direct_privacy').val();

    if (yearly_renew_cost === "") {
      yearly_renew_cost = 0;
    } else {
      yearly_renew_cost = parseFloat(yearly_renew_cost);
    }
    if (icann_fee === "") {
      icann_fee = 0;
    } else {
      icann_fee = parseFloat(icann_fee);
    }
    if (cost_general === "") {
      cost_general = 0;
    } else {
      cost_general = parseFloat(cost_general);
    }
    if (cost_directprivacy === "") {
      cost_directprivacy = 0;
    } else {
      cost_directprivacy = parseFloat(cost_directprivacy);
    }
    if (other_cost === "") {
      other_cost = 0;
    } else {
      other_cost = parseFloat(other_cost);
    }
    if (direct_privacy === "") {
      direct_privacy = 0;
    } else {
      direct_privacy = parseFloat(direct_privacy);
    }

    var tot = yearly_renew_cost + icann_fee + other_cost + direct_privacy + cost_general + cost_directprivacy;
    jQuery('#totalcost').html(tot.toFixed(2));

  }


  checkValidBySection = (event) => {
    var validatestates = {
      domain: this.state.domain,
      web_category: this.state.web_category,
      registrar: this.state.registrar,
      business_sector: this.state.business_sector,
      alj_entity: this.state.alj_entity,
      geography: this.state.geography,
      domain_status: this.state.domain_status,
    };

    var control = jQuery(event.target).parents(".customAcc.card");
    checkValidBySection(validatestates, control);

  }

  render() {
    var fulldatastate = this.state.datafull;
    var fulldatalist = [];
    if (fulldatastate.length > 0) {
      for (let i = 0; i < fulldatastate.length; i++) {
        fulldatalist.push({ label: fulldatastate[i].get('registrant_email') });
      }
    }



    return <div>
      {this.state.domainbyidData ?
        <Form id="addform">
          <Row className="justify-content-between">


            <Col className="col-md-8">
              <Alert color="success" className={this.state.addedclass + " mr-auto"}>
                Record Added
              </Alert>
            </Col>

            <Col className="col-auto mb-4">
              <Link to="/finance/finance/:domainid" className="btn btn-default  mr-3">
                <span>Back To List</span>
              </Link>
              <Button type="button" color="primary" id="addbutton" onClick={this.addRecord}>
                <span className="border-left pl-2"></span>
                Save
              </Button>

              {/* Loader button */}
              <div className="btn btn-primary d-none" id="loadbutton">
                <Loader type="Oval" color="#414241" height={20} width={20} />
              </div>
              {/* Loader button */}

            </Col>
          </Row>

          {/* <Row>
            <Col md="8">
              <Alert color="success" className={this.state.addedclass}>
                Finance Added
                    <Link to="/finance/finance/:domainid" className="float-right">
                  &nbsp; Finance List
                    </Link>
              </Alert>
            </Col>
          </Row> */}

          <Row>
            <Col md="8">
              <Card className="customAcc">
                <CardTitle onClick={this.slidingClick.bind(this)} className="fieldsouter opendefault">
                  <i className="icon-circle icon-pencil"></i>
                  Domain Costs
              </CardTitle>
                <CardBody>

                  <Row>

                    <Input type="hidden" id="domainid" value={this.state.domainbyidData.id} />

                    <Col md="6">
                      <FormGroup className="form-group">
                        <Label htmlFor="name">Currency
                        <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                          <span className="tooltipshow">Something about me</span>
                        </Label>
                        <select id="currency" className="custom-select" required>
                          <option value="USD">USD</option>
                        </select>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className="form-group">
                        <Label htmlFor="name">Yearly Renewal Cost
                        <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                          <span className="tooltipshow">Something about me</span>
                        </Label>
                        <Input type="number" name="yearly_renew_cost" id="yearly_renew_cost" className="dollarinput" onWheel={ event => event.currentTarget.blur() } onChange={this.calculateTotalCost} />
                        <span className="dollarclass">$</span>
                      </FormGroup>
                    </Col>
                  </Row>



                  <Row>
                    <Col md="6">
                      <FormGroup className="form-group">
                        <Label htmlFor="name">Cost *
                          <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                          <span className="tooltipshow">Something about me</span>
                        </Label>
                        <Input type="number" name="cost_general" id="cost_general" className="dollarinput" onWheel={ event => event.currentTarget.blur() } onChange={this.calculateTotalCost} required />
                        <span className="dollarclass">$</span>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className="form-group">
                        <Label htmlFor="name">Cost (Direct Privacy)
                          <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                          <span className="tooltipshow">Something about me</span>
                        </Label>
                        <Input type="number" name="cost_directprivacy" id="cost_directprivacy" className="dollarinput" onWheel={ event => event.currentTarget.blur() } onChange={this.calculateTotalCost} />
                        <span className="dollarclass">$</span>
                      </FormGroup>
                    </Col>
                  </Row>









                  <Row>
                    <Col md="6">
                      <FormGroup className="form-group">
                        <Label htmlFor="name">ICANN fee
                        <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                          <span className="tooltipshow">Something about me</span>
                        </Label>
                        <Input type="number" name="icann_fee" id="icann_fee" className="dollarinput" onWheel={ event => event.currentTarget.blur() } onChange={this.calculateTotalCost} />
                        <span className="dollarclass">$</span>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className="form-group">
                        <Label htmlFor="name">Other Cost
                        <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                          <span className="tooltipshow">Something about me</span>
                        </Label>
                        <Input type="number" name="other_cost" id="other_cost" className="dollarinput" onWheel={ event => event.currentTarget.blur() } onChange={this.calculateTotalCost} />
                        <span className="dollarclass">$</span>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <FormGroup className="form-group">
                        <Label htmlFor="name">Direct Privacy (yearly)
                        <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                          <span className="tooltipshow">Something about me</span>
                        </Label>
                        <Input type="number" name="direct_privacy" id="direct_privacy" className="dollarinput" onWheel={ event => event.currentTarget.blur() } onChange={this.calculateTotalCost} />
                        <span className="dollarclass">$</span>
                      </FormGroup>
                    </Col>
                    <Col md="6">

                      <FormGroup className="form-group">
                        <Label htmlFor="name">Other Cost Remarks
                        <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                          <span className="tooltipshow">Something about me</span>
                        </Label>
                        <Input type="textarea" name="remarks" id="remarks" rows="2" />
                      </FormGroup>



                      <FormGroup className="form-group">
                        <Label htmlFor="name">Total Costs: USD <span id="totalcost"></span></Label>
                      </FormGroup>
                    </Col>

                    <Col md="12">
                      <FormGroup className="form-group">
                        <Button type="button" color="primary" className="float-right" onClick={this.checkValidBySection.bind(this)}>Validate</Button>
                      </FormGroup>
                    </Col>

                  </Row>
                </CardBody>
              </Card>

              <Card className="customAcc">
                <CardTitle onClick={this.slidingClick.bind(this)} className="fieldsouter">
                  <i className="icon-circle icon-pencil"></i>
                  Finance Details
             </CardTitle>
                <CardBody>

                  <Row>
                    <Col md="6">
                      <FormGroup className="form-group">
                        <Label htmlFor="name">Invoice Number *
                        <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                          <span className="tooltipshow">Something about me</span>
                        </Label>
                        <Input type="text" name="invoice_number" id="invoice_number" required />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className="form-group">
                        <Label htmlFor="name">Account
                        <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                          <span className="tooltipshow">Something about me</span>
                        </Label>
                        <Input type="text" name="account" id="account" />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <FormGroup className="form-group">
                        <Label htmlFor="name">Invoice Amount *
                        <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                          <span className="tooltipshow">Something about me</span>
                        </Label>
                        <Input type="number" name="invoice_amount" id="invoice_amount" className="dollarinput" onWheel={ event => event.currentTarget.blur() } required />
                        <span className="dollarclass">$</span>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className="form-group">
                        <Label>Transaction Type *
                        <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                          <span className="tooltipshow">Something about me</span>
                        </Label>
                        <select id="transaction_type" className="form-control custom-select" required>
                          <option value="">Select</option>
                          <option value="acquisition">Acquisition</option>
                          <option value="new">New</option>
                          <option value="others">Others</option>
                          <option value="renewal">Renewal</option>
                          <option value="renewal: .com domain">Renewal: .COM Domain</option>
                          <option value="renewal: domain">Renewal: Domain</option>
                          <option value="renewal: hosting plan">Renewal: Hosting Plan</option>
                          <option value="subscription to email">Subscription to Email</option>
                        </select>
                      </FormGroup>
                    </Col>
                    {/* <Col md="6">
                      <FormGroup className="form-group">
                        <Label htmlFor="name">Registrant email
                        <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                          <span className="tooltipshow">Something about me</span>
                        </Label> 
                        <div className="autocomwrap">
                          <Autocomplete
                            inputProps={{ className: "form-control", id: "registrant_email" }}
                            getItemValue={(item) => item.label}
                            items={fulldatalist}
                            renderItem={(item, isHighlighted) =>
                              <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                                {item.label}
                              </div>
                            }
                            value={this.state.registrant_email}
                            onChange={(event) => {
                              this.setState({ registrant_email: event.target.value });
                            }}
                            onSelect={(val) => { this.setState({ registrant_email: val }); }}
                          />
                        </div>
                      </FormGroup>
                    </Col> */}
                  </Row>

                  <Row>
                    <Col md="6">
                      <FormGroup className="form-group">
                        <Label htmlFor="name">Corp. CC Statement *
                        <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                          <span className="tooltipshow">Something about me</span>
                        </Label>
                        <Datetime
                          inputProps={{ placeholder: "DD/MM/YYYY", id: "cc_statement_date", required: "required" }}
                          timeFormat={false}
                          dateFormat="DD/MM/YYYY"
                          onChange={(selectedOption) => {
                            this.setState({ cc_statement_date: selectedOption });
                          }}
                          value={this.state.cc_statement_date}
                          closeOnSelect={true}
                          />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className="form-group">
                        <Label htmlFor="name">Date of Invoice *
                        <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                          <span className="tooltipshow">Something about me</span>
                        </Label>
                        <Datetime
                          inputProps={{ placeholder: "DD/MM/YYYY", id: "date_of_invoice", required: "required" }}
                          timeFormat={false}
                          dateFormat="DD/MM/YYYY"
                          onChange={(selectedOption) => {
                            this.setState({ date_of_invoice: selectedOption });
                          }}
                          value={this.state.date_of_invoice}
                          closeOnSelect={true}
                          />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>

                    <Col md="6">
                      <FormGroup className="form-group">
                        <Label>Finance Comments
                        <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                          <span className="tooltipshow">Something about me</span>
                        </Label>
                        <Input type="textarea" name="finance_comments" id="finance_comments" rows="2" />
                      </FormGroup>
                    </Col>

                    <Col md="12">
                      <FormGroup className="form-group">
                        <Button type="button" color="primary" className="float-right" onClick={this.checkValidBySection.bind(this)}>Validate</Button>
                      </FormGroup>
                    </Col>
                  </Row>

                </CardBody>
              </Card>

            </Col>



            <Col md="4">
              <Card className="customAcc">
                <CardTitle onClick={this.slidingClick.bind(this)} className="fieldsouter">
                  <i className="icon-circle icon-pencil"></i>
                  Save Options
              </CardTitle>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <FormGroup className="form-group">
                        <Label>Status
                        <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                          <span className="tooltipshow">Something about me</span>
                        </Label>
                        <select id="status" className="custom-select">
                          <option value="true">Active</option>
                          <option value="false">Inactive</option>
                        </select>
                      </FormGroup>


                    </Col>
                  </Row>

                </CardBody>
              </Card>


              <Card className="customAcc">
                <CardTitle onClick={this.slidingClick.bind(this)} className="fieldsouter">
                  <i className="icon-circle icon-pencil"></i>
                  Attachments
                  </CardTitle>
                <CardBody>

                  <Row>
                    <Col md="12">


                      {/* Images area */}

                      <FileUpload labelText="Upload Files" tooltipText="Hello world!" folderName="contentmaster"
                        isValid={true} inputText="Upload your file here" items={this.state.logoFileArray}
                        name="inputGroupFile01"
                        onDelete={(id) => {
                          var logoArray = this.state.logoFileArray.filter(obj => obj.id != id).map((item, index) => { return { ...item, id: index } });
                          this.setState({ logoFileArray: logoArray }, () => {
                          });
                        }}
                        addItems={(items) => {
                          console.log('addItem', items);
                          this.setState({
                            logoFileArray: [...this.state.logoFileArray, ...items]
                          }, () => {
                            console.log(this.state.logoFileArray);
                          });
                        }}
                        updateItem={(id, filename) => {
                          var objToChange = this.state.logoFileArray.find(x => x.id == id);
                          if (objToChange) {
                            this.setState({
                              logoFileArray: [...this.state.logoFileArray.filter(x => x.id != id),
                              { ...this.state.logoFileArray.find(x => x.id == id), awsFileName: filename }
                              ]

                            }, () => { console.log(this.state.logoFileArray); });
                          }
                        }}
                        showError={(message) => {
                          this.setState({ showAlertMessage: true, alertMessage: message })
                        }}
                      />

                      {/* <InputGroup>
                        <div className="custom-file">
                          <Input type="file" className="custom-file-input" id="inputGroupFile01" onChange={(event) => { this.onLogoFileSelected(event) }} multiple />
                          <label className="custom-file-label" htmlFor="inputGroupFile01">Upload your file here</label>
                        </div>
                      </InputGroup> */}

                    </Col>
                  </Row>

                </CardBody>
              </Card>
            </Col>







          </Row>


        </Form>
        :
        <p>Domain Missing</p>
      }
    </div>
  }
}

export default withRouter(addFinance);
