import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormFeedback,
  CustomInput,
  Tooltip,
  UncontrolledTooltip
} from 'reactstrap';
import Select, { components } from "react-select";
import jQuery from 'jquery'

import { authenticateLoggedIn, addHeaderTitle, addBreadcrumb } from '../../../services/parse';
import { addRecord, checkFieldValid } from '../../../services/agency';

class AddAgency extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      collapse: true,
      fadeIn: true,
      timeout: 300,
      isSuper: '',
      addedclass: 'd-none',
      companyByIdData: [],
      isEdit: false,
      logofile: null,
      rewardsfile: null,
      loginlogofile: null,
    };
  }


  async componentDidMount(evt) {
    //auth start
    authenticateLoggedIn();
    addHeaderTitle('Add Agency');
    addBreadcrumb('Home / Agency / Add Agency');

  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  isEmail = (email) => {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  }

  checkEmail = () => {
    var email = jQuery('#contactemail').val();
    var value = this.isEmail(email);

    if (value == false) {
      jQuery('#notvalidemail').removeClass('d-none');
    } else {
      jQuery('#notvalidemail').addClass('d-none');
    }

  }


  addRecord = () => {

    var valid = checkFieldValid('add');
    if (valid !== false) {
      jQuery('#addbutton').prop('disabled', true);

      var name = jQuery('#name').val();
      var contactperson = jQuery('#contactperson').val();
      var contactemail = jQuery('#contactemail').val();
      var description = jQuery('#description').val();
      var status = jQuery('#status').val();

      addRecord({ name: name, contactperson: contactperson, contactemail: contactemail, description: description, status: status }).then((value) => {
        if (value !== false) {
          this.setState({ addedclass: 'd-block' });

          setTimeout(
            function () {
              this.setState({ addedclass: 'd-none' });
              window.location.assign('#/contentmaster/editagency/' + value.id);
            }
              .bind(this),
            1000
          );

        } else {
          this.setState({ addedclass: 'd-none' });
        }

        jQuery('#addform').trigger("reset");
        jQuery('#addbutton').prop('disabled', false);
      });

    }
  }





  render() {
    return <div>

      <Row className="justify-content-between topbuttonbar">
        <Col className="col-md-5">
          <Alert color="success" className={this.state.addedclass}>
            Record Added
            <Link to="/contentmaster/agency" className="float-right">
              &nbsp; Go to list
            </Link>
          </Alert>
        </Col>
        <Col className="col-auto mb-4">
          <Link to="/contentmaster/agency" className="btn btn-primary btncancle mr-3">
            <span className="border-left pl-2">Cancel</span>
          </Link>
          <Button type="button" color="primary" id="addbutton" onClick={this.addRecord}>
            <span className="border-left pl-2"></span>
            Save
          </Button>
        </Col>
      </Row>

      <Row>

        <Col xs="12" sm="6">


          <Card>
            <CardTitle>
              <i className="icon-circle icon-pencil"></i>
              Add Agency
            </CardTitle>

            <CardBody>


              <form name="addform" id="addform">

                <FormGroup className="form-group">
                  <Label htmlFor="name">Agency Name *</Label>
                  <Input type="text" id="name" placeholder="Agency Name" />
                </FormGroup>

                <FormGroup className="form-group">
                  <Label htmlFor="link">Contact Person *</Label>
                  <Input type="text" id="contactperson" placeholder="Contact Person" />
                </FormGroup>

                <FormGroup className="form-group">
                  <Label htmlFor="email">Contact Email *</Label>
                  <Input type="text" id="contactemail" placeholder="Contact Email" onBlur={this.checkEmail} />
                </FormGroup>
                <Alert color="danger" id="notvalidemail" className="d-none">
                  Not a valid email
                </Alert>

                <FormGroup className="form-group">
                  <Label htmlFor="status">Status *</Label>
                  <Input type="select" className="custom-select" id="status">
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                  </Input>
                </FormGroup>

                <FormGroup className="form-group">
                  <Label htmlFor="description">Description</Label>
                  <Input type="textarea" id="description" placeholder="Description" rows="3" />
                </FormGroup>


              </form>
            </CardBody>

          </Card>

        </Col>
      </Row>

    </div>
  }
}

export default AddAgency;
