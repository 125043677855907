
var Parse = require('parse');

export const getTrademakProgresses = async () => {
    var TrademarkStatuses = Parse.Object.extend("TrademarkStatuses");
    var query = new Parse.Query(TrademarkStatuses);
    query.equalTo("status", true);
    query.ascending("sortNumber");
    const results = await query.find();    
    return results;
    
}

export const getTrademakProgressStatus = async (id) => {
    
    try{
    
    var TrademarksMaster = Parse.Object.extend("TrademarksMaster");
    var query = new Parse.Query(TrademarksMaster);
    query.equalTo("objectId", id);
    var trademark = await query.find();

    var TrademarkProgress = Parse.Object.extend("TrademarkProgress");
    var querynew = new Parse.Query(TrademarkProgress);
    querynew.equalTo("trademark",trademark[0]);
    const results = await querynew.find();
    //console.log('getTrademakProgressStatus',results);
    if(results.length>0)
    {
        var optionArr=[]; 
        for(let i=0;i<results.length;i++)
        {
            optionArr[i]={ trademarkStatus: results[i].attributes.trademarkStatus.id, processStatus: results[i].attributes.processStatus }
        }
       // console.log('getTrademakProgressStatus',optionArr);
        return optionArr;
    }
    else
    {
        return [];
    }
}
catch(er)
{
    console.log('getTrademakProgressStatus',er)
}
}




export const getTrademarkProgressVersions = async (id) => {

    var TrademarkProgress = Parse.Object.extend("TrademarkProgress");
    var query = new Parse.Query(TrademarkProgress);
    query.equalTo("objectId", id);
    //query.include("BusinessSectors");
    var result = await query.find();

    var TrademarkProgressVersions = Parse.Object.extend("TrademarkProgressVersions");
    var querynew = new Parse.Query(TrademarkProgressVersions);
    querynew.equalTo("trademarkProgress", result[0]);
    querynew.descending("versionCount");
    querynew.skip(1);
    //query.include("BusinessSectors");
    var results = await querynew.find();
    if(results.length>0)
    {
        var optionArr=[]; 
        for(let i=0;i<results.length;i++)
        {
            optionArr[i]=results[i].attributes.versionCount
        }
        return optionArr;
    }
    else
    {
        return [];
    }
    
}

export const getTrademarkProgressVersionsObjectById = async (id,version) => {
    var TrademarkProgress = Parse.Object.extend("TrademarkProgress");
    var query = new Parse.Query(TrademarkProgress);
    query.equalTo("objectId", id);
    // query.include("BusinessSectors");
    var result = await query.find();

    var TrademarkProgressVersions = Parse.Object.extend("TrademarkProgressVersions");
    var querynew = new Parse.Query(TrademarkProgressVersions);
    querynew.equalTo("trademarkProgress", result[0]);
    querynew.equalTo("versionCount", parseInt(version));
    // query.include("BusinessSectors");
    var results = await querynew.find();
    if(results)
    {
        return results[0];
    }
    else{
        return [];
    }
    
}
export const saveTrademarkProgress=async(obj)=>{
    var saveObj = await obj.save();
    updateTrademarkProgressVersions(saveObj);
    return saveObj;
}

export const getTrademarkProgressById = async (trademarkId,progressId) => {
   // console.log('getTrademarkProgressById',trademarkId,progressId)
    var progress = await getProgressById(progressId);
    var trademark = await getTrademarkById(trademarkId);
    var TrademarkProgress = Parse.Object.extend("TrademarkProgress");
    var query = new Parse.Query(TrademarkProgress);
    query.equalTo("trademark", trademark);
    query.equalTo("trademarkStatus", progress);
    // query.include("BusinessSectors");
    var result = await query.find();
   // console.log('getTrademarkProgressById',result)
    if(result)
    return result[0];
    else
    return null
    
}

export const isCertificationCompleted = async (trademarkId) => {
    var progress = await getProgressById('wLJe98HUcP');
    var trademark = await getTrademarkById(trademarkId);
    var TrademarkProgress = Parse.Object.extend("TrademarkProgress");
    var query = new Parse.Query(TrademarkProgress);
    query.equalTo("trademark", trademark);
    query.equalTo("trademarkStatus", progress);
    query.equalTo("processStatus", 'completed');
    
    // query.include("BusinessSectors");
    var result = await query.find();
  //  console.log('isCertificationCompleted',result)
    if(result.length>0)
    return true;
    else
    return false
    
}

export const getProgressById = async (progressId) => {
    var TrademarkStatuses = Parse.Object.extend("TrademarkStatuses");
    var query = new Parse.Query(TrademarkStatuses);
    query.equalTo("objectId", progressId);
    // query.include("BusinessSectors");
    var result = await query.find();
    if(result)
    return result[0];        
}

export const getTrademarkById = async (trademarkId) => {
    var TrademarksMaster = Parse.Object.extend("TrademarksMaster");
    var query = new Parse.Query(TrademarksMaster);
    query.equalTo("objectId", trademarkId);
    // query.include("BusinessSectors");
    var result = await query.find();
    if(result)
    return result[0];    
}

export const updateTrademarkProgressVersions=async(obj)=>{
    try{
        const TrademarkProgressVersions = Parse.Object.extend('TrademarkProgressVersions');
        let myNewObject = new TrademarkProgressVersions();     
        
        myNewObject.set('notes', obj.attributes.notes);
        myNewObject.set('processStatus', obj.attributes.processStatus);
        myNewObject.set('user', obj.attributes.user);
        myNewObject.set('assetFiles', obj.attributes.assetFiles);
        myNewObject.set('versionCount', obj.attributes.versionCount);
        myNewObject.set('trademark', obj.attributes.trademark);
        myNewObject.set('trademarkStatus', obj.attributes.trademarkStatus);
        myNewObject.set('trademarkProgress',obj);
        myNewObject.set('applicationNumber', obj.attributes.applicationNumber);
        myNewObject.set('applicationDate', obj.attributes.applicationDate);
        myNewObject.set('registrationNumber', obj.attributes.registrationNumber);
        myNewObject.set('registrationDate', obj.attributes.registrationDate);
        myNewObject.set('nextRenewalDate', obj.attributes.nextRenewalDate);
        myNewObject.set('usedDate', obj.attributes.usedDate);
        myNewObject.set('statusNotes', obj.attributes.statusNotes);
        var saveObj = await myNewObject.save();
    }
    catch(ex)
    {
        console.log(ex);
    }
}

export const convertStateObjToParseObj = async (mainData)=>
{
   
    try{
    const TrademarkProgress = Parse.Object.extend('TrademarkProgress');
    let myNewObject = new TrademarkProgress();
    if(mainData.id)
    {
        //var query = new Parse.Query(TrademarksMaster);
        //myNewObject = await query.get(mainData.id);
        myNewObject.set('objectId', mainData.id);
    }

   
    console.log(Parse.User.current());
    myNewObject.set('notes', mainData.notes);
    myNewObject.set('processStatus', mainData.processStatus);
    myNewObject.set('user', Parse.User.current());
    myNewObject.set('assetFiles', mainData.assetsFileArray);
    myNewObject.set('versionCount', mainData.versionCount+1);
    myNewObject.set('applicationNumber', mainData.applicationNumber);
    myNewObject.set('statusNotes', mainData.statusNotes);
    if(mainData.applicationDate!='')
    {
        myNewObject.set('applicationDate', mainData.applicationDate);
    }
    myNewObject.set('registrationNumber', mainData.registrationNumber);
    if(mainData.registrationDate!='')
    {
        myNewObject.set('registrationDate', mainData.registrationDate);
    }
    if(mainData.nextRenewalDate!='')
    {
        myNewObject.set('nextRenewalDate', mainData.nextRenewalDate);
    }    
    if(mainData.usedDate!='')
    {
        myNewObject.set('usedDate', mainData.usedDate);
    }

    if (mainData.trademark) {
        var extentObject = Parse.Object.extend("TrademarksMaster");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(mainData.trademark);
        myNewObject.set('trademark', parsepoint);
    }
    if (mainData.trademarkStatus) {
        var extentObject = Parse.Object.extend("TrademarkStatuses");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(mainData.trademarkStatus);
        myNewObject.set('trademarkStatus', parsepoint);
    }
    return myNewObject;
}
catch(ex)
{
    console.log(ex);
}
}


export const convertParseObjTOStateObj = async (obj)=>
{   
    
    try{
        var mainData = {};
        mainData.id = obj.id;
        mainData.notes = obj.attributes.notes;
        mainData.assetsFileArray = obj.attributes.assetFiles;
        mainData.versionCount = obj.attributes.versionCount;
        mainData.processStatus = obj.attributes.processStatus;
        mainData.applicationNumber = obj.attributes.applicationNumber;
        mainData.applicationDate = obj.attributes.applicationDate;
        mainData.registrationNumber = obj.attributes.registrationNumber;
        mainData.registrationDate = obj.attributes.registrationDate;
        mainData.usedDate = obj.attributes.usedDate;
        mainData.nextRenewalDate = obj.attributes.nextRenewalDate;
        mainData.statusNotes = obj.attributes.statusNotes;
        
        if(obj.attributes.trademarkStatus)
        {
        mainData.trademarkStatus = obj.attributes.trademarkStatus.id;
        }
        if(obj.attributes.trademark)
        {
        mainData.trademark = obj.attributes.trademark.id;
        }
       
        //console.log(mainData);
    
    return mainData;
}
catch(ex)
{
    console.log(ex);
}
}

export const saveTrademarkProgressImport=async(processStatus,trademarkStatus,trademark,notes,applicationDate,applicationNumber,registrationNumber,registrationDate,nextRenewalDate)=>{
    var tempObj = {
        id:'',
        processStatus:'',
        trademarkStatus:'',
        user:'',
        trademark:'',
        notes:'',
        assetsFileArray:[],
        versionCount:0,
        applicationNumber:'',
        applicationDate:null,
        registrationNumber:'',
        registrationDate:null,
        nextRenewalDate:null
    };
    tempObj.processStatus = processStatus;
    tempObj.trademarkStatus=trademarkStatus;
    tempObj.trademark = trademark;
    if(notes)
    {
        tempObj.notes = notes;
    }
    tempObj.applicationDate = applicationDate;
    tempObj.applicationNumber = applicationNumber;
    tempObj.registrationDate = registrationDate;
    tempObj.registrationNumber = registrationNumber;
    tempObj.nextRenewalDate = nextRenewalDate;
    var convertedObj = await convertStateObjToParseObj(tempObj);
    if(convertedObj)
    {
        var subObj = await saveTrademarkProgress(convertedObj);
    }
}

export const updateTrademark=async(trademarkId,regDate,renDate,step,stepStatus,appNumber,regNumber,usedDate,appDate)=>
{
    var TrademarksMaster = Parse.Object.extend("TrademarksMaster");
    var query = new Parse.Query(TrademarksMaster);
    var trademark = await query.get(trademarkId);
    trademark.set('stepStatus',stepStatus);
    trademark.set('step',{ "__type": "Pointer", "className": "TrademarkStatuses", "objectId": step });
    if(regDate)
    {
        trademark.set('registrationDate',regDate);
    }
    if(renDate)
    {
        trademark.set('nextRenewalDate',renDate);
    }
    if(appNumber!='')
    {
        trademark.set('applicationNumber',appNumber);
    }
    if(regNumber!='')
    {
        trademark.set('registrationNumber',regNumber);
    }
    if(usedDate)
    {        
        trademark.set('usedDate',usedDate);
    }
    if(appDate)
    {        
        trademark.set('applicationDate',appDate);
    }
    trademark.save();
}