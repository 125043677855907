import React from 'react';
import {
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Input,
	CustomInput,
	Form,
	Row,
	Col,
	Label,
	Button,
	Alert,

} from 'reactstrap';
import { Link } from 'react-router-dom';
import { auth } from '../../firebase';
import validators from './validators';
import jQuery from 'jquery';

import loginBg from '../../assets/images/loginBg.jpg';
import backgroundVideo from '../../assets/videos/alj-our-locations-in-saudi-arabia.mp4';
import ALJintroVideo from '../../assets/videos/ALJ-intro.mp4';
import ALJintroVideoImg from '../../assets/images/video-bg-landing.jpg';
import mutebtn from '../../assets/images/icons/mute.png';
import unmutebtn from '../../assets/images/icons/unmute.png';
import { getCurrentUser, authenticateLoggedIn, login, isSuperAdmin, authenticateLoginpage } from '../../services/parse';


const sidebarBackground = {
	backgroundImage: "url(" + loginBg + ")",
	backgroundRepeat: "no-repeat",
	backgroundPosition: "center center",
	backgroundSize: "cover"
};

class SplashScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			username: '',
			password: '',
			userError: '',
			passError: '',
			invalidError: 'd-none',
		};

	}

	async componentDidMount(evt) {
		//auth start
		//authenticateLoggedIn();

		authenticateLoginpage();
 

		// isSuperAdmin().then((value) => {
		//     if (value === true) {
		//         this.setState({isSuper: 'yes'});

		//         var currentuserdet = currentUserDetails(); 
		//         this.setState({currentuserdetails: currentuserdet.attributes.username});

		//         if(currentuserdet.attributes.username=='superadmin'){
		//             menuActivation("#/employee/employeelist");
		//         }

		//     } else {
		//         this.setState({isSuper: 'no'});
		//     }
		// });
		//auth end

		var vid = document.getElementById("introvideo");
		vid.onended = function () {
			window.location.assign('#/authentication/login');
		};
 

	}


 


	volumeActive = () => {
		jQuery("#introvideo").prop('muted', false);
		jQuery(".mutebtn").hide();
		jQuery(".unmutebtn").css("display","inline-block");
	}

	handleUsernameChange = (evt) => {
		this.setState({ username: evt.target.value });
	}
	handlePassChange = (evt) => {
		this.setState({ password: evt.target.value });
	}

	handleSubmit = () => {
		const { username, password } = this.state;
		let status = false;

		if (username === '') {
			this.setState({ userError: 'is-invalid' });
			this.setState({ passError: '' });
			return
		} else if (password === '') {
			this.setState({ userError: '' });
			this.setState({ passError: 'is-invalid' });
		} else {
			login({ username: username, password: password }).then((value) => {
				if (value === true) {
					window.location.assign('#/dashboard');
				} else {
					this.setState({ invalidError: 'd-block' });
				}
			});
		}
	}



	handleClick() {
		var elem = document.getElementById('loginform');
		elem.style.transition = "all 2s ease-in-out";
		elem.style.display = "none";
		document.getElementById('recoverform').style.display = "block";
	}
	render() {
		return <div className="auth-wrapper align-items-center d-flex">
			{/*--------------------------------------------------------------------------------*/}
			{/*Login2 Cards*/}
			{/*--------------------------------------------------------------------------------*/}
			<div className="loader"></div>

			<div className="videobg">
				<video autoPlay muted id="introvideo">
					<source src={ALJintroVideo} type="video/mp4" />
				</video>
				 
				 

			</div>

			<div className="videobuttons">
				<Link to="/authentication/login" className="btn-default btn skiptologin btn-lg">
					Skip
				</Link>

				<a href="javascript:" className="volumebtn mutebtn" onClick={this.volumeActive}>
					<img src={mutebtn} alt={"volume"}/>
				</a>
				<a href="javascript:" className="volumebtn unmutebtn" onClick={this.volumeActive}>
					<img src={unmutebtn} alt={"volume"}/>
				</a>
			</div>
			{/* <img src={volumebtn} alt={"volume"}  onClick={this.volumeActive}/> */}
		</div>;
	}
}

export default SplashScreen;
