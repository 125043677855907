import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormFeedback,
  CustomInput,
  Tooltip,
  UncontrolledTooltip
} from 'reactstrap';
import Select, { components } from "react-select";
import jQuery from 'jquery';

import { getCurrentUser, authenticateLoggedIn, addHeaderTitle, isSuperAdmin, authenticateLoginpage, addBreadcrumb } from '../../services/parse';
import { addRecord, checkFieldValid } from '../../services/businessunit';

class addBusinessUnit extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      collapse: true,
      fadeIn: true,
      timeout: 300,
      isSuper: '',
      addedclass: 'd-none',
      companyByIdData: [], 
      isEdit: false,
      logofile: null,
      rewardsfile: null,
      loginlogofile: null,
    };
  }


  async componentDidMount(evt) {
    //auth start
    authenticateLoggedIn();
    addHeaderTitle('Add Business Unit');
    addBreadcrumb('Home / Business Units / Add Business Unit');
 
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }


  addRecord = () => {

    var valid = checkFieldValid('add');
    if (valid !== false) {
      jQuery('#addbutton').prop('disabled', true);

      var name = jQuery('#bname').val();
      var description = jQuery('#bdescription').val();
      var status = jQuery('#status').val();

      addRecord({ name: name, description: description, status: status }).then((value) => {
        if (value === true) {
          this.setState({ addedclass: 'd-block' });
        } else {
          this.setState({ addedclass: 'd-none' });
        }

        jQuery('#addform').trigger("reset");
        jQuery('#addbutton').prop('disabled', false);
      });

    }
  }





  render() {
    return <div>
      
        <Row className="justify-content-between topbuttonbar">
          <Col className="col-md-5">
          <Alert color="success" className={this.state.addedclass}>
            Record Added
              <Link to="/business-unit/business-unit" className="float-right">
              &nbsp; Go to list
              </Link>
          </Alert>
          </Col>
        <Col className="col-auto mb-4">
          <Link to="/business-unit/business-unit" className="btn btn-primary btncancle mr-3">
            <span className="border-left pl-2">Cancel</span>
          </Link>
          <Button type="button" color="primary" id="addbutton" onClick={this.addRecord}>Save</Button>
        </Col>
      </Row>

      <Row>

        <Col md="5">

          <Card>
            <CardTitle>
              <i className="icon-circle icon-pencil"></i>
              Add Business Unit
            </CardTitle>
            <CardBody>


              <Form id="addform">
                <FormGroup className="form-group">
                  <Label htmlFor="name">Name</Label>
                  <Input type="text" name="bname" id="bname" />
                </FormGroup>


                <FormGroup className="form-group">
                  <Label htmlFor="country">Status</Label> 
                  <select name="status" id="status" className="form-control">
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                  </select>
                </FormGroup>


                <FormGroup className="form-group">
                  <Label htmlFor="description">Description</Label>
                  <Input type="textarea" name="bdescription" id="bdescription" rows="5" placeholder="Add your comments here..." />
                </FormGroup>


              </Form>
            </CardBody>
          </Card>

        </Col>
      </Row>

    </div>
  }
}

export default addBusinessUnit;
