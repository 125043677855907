import React from 'react';
import { Routes , Route, Switch, Navigate } from 'react-router-dom';
import authRoutes from '../routes/authroutes.jsx';


class Blanklayout extends React.Component {
    render() {
      return (
        <div className="authentications">
        <Routes>
          {authRoutes.map((prop, key) => (
              <Route path={prop.path} element={prop.component} key={key}>
                  {prop.children && prop.children.map((child, childKey) => (
                      <Route path={child.path} element={child.component} key={childKey} />
                  ))}
              </Route>
          ))}
        </Routes>
      </div>
     
      
      )
    }
}
export default Blanklayout;    