import React from 'react';
// import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';
import { createBrowserHistory } from 'history';
import indexRoutes from './routes/index.jsx';
import { HashRouter as Router, Route, Switch, Routes } from 'react-router-dom';

import './assets/scss/style.css';
import './assets/css/default.css';
import './assets/css/media.css';

import { PARSE_APP_ID, PARSE_JAVASCRIPT_KEY, PARSE_API_URL } from './config/parse';
import { Offline, Online, Detector } from "react-detect-offline";
import { parseInitialize, parseInitializewithMaster, authenticateLoggedIn } from './services/parse';
parseInitialize({ app_id: PARSE_APP_ID, key: PARSE_JAVASCRIPT_KEY, url: PARSE_API_URL });

const hist = createBrowserHistory();

// React 18
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( <>
  <Router>
    <Routes>
      {indexRoutes.map((prop, key) => (
              <Route path={prop.path} element={prop.component} key={key}>
                  {prop.child && prop.child.map((prop1, key1) => (
                      <Route path={prop1.path} element={prop1.component} key={key1} >
                         {prop1.child && prop1.child.map((prop2, key2) => (
                              <Route path={prop2.path} element={prop2.component} key={key2} >
                                
                              </Route>
                          ))}
                      </Route>
                  ))}
              </Route>
        ))}
        {/* {indexRoutes.map((prop, key) => {
        return <Route path={prop.path} key={key} element={prop.component} />;
      })} */}
    </Routes>
  </Router>
  </>
);

// ReactDOM.render(
//   <>
//     <HashRouter>
//       <Routes>
//         {indexRoutes.map((prop, key) => {
//           return <Route path={prop.path} key={key} component={prop.component} />;
//         })}
//       </Routes>
//     </HashRouter>
    
//     {/* <Offline>You're offline right now. Check your connection.</Offline> */}
//     {/* <Detector
//   render={({ online }) => (
//    !online?
//    <div className={online ? "normal" : "warningoffline"}>
//       You are currently {online ? "online" : "offline"}
//     </div>
//     :null
//   )}
// /> */}
//   </>
//   , document.getElementById('root')); 
