// import React from 'react';
import React, { Component, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Row, Col, Carousel, CarouselItem, CarouselControl, Button, Table, Input, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, ModalFooter, Label, Alert, UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem, } from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
import jQuery from 'jquery';
import ScrollArea from 'react-scrollbar';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import { uploadFile, uploadFileToAWSCustom, getFileUrl } from '../../../services/awsFileUpload';
import { addRecord, getLicenseType, deleteRecords, duplicateRecord, checkFieldValid } from '../../../services/medialicenselibraries';
import { LOGO_UPLOAD_SIZE } from '../../../config/constant';
import Libraryimage from "./Libraryimage";

function deleteRecord() {
  document.getElementById("detelepopup").classList.add("active");
}
function closeDeleteRecord() {
  document.getElementById("detelepopup").classList.remove("active");
}

function setobjectid(num) {
  var elSlide = jQuery('.mediaslideitem[data-id=' + num + ']').data('obj');
  console.log(elSlide);
  jQuery('#hiddenobjectID').val(elSlide);
}

var popSlide = {
  autoplay: false,
  autoplaySpeed: 5000,
  dots: false,
  arrows: true,
  infinite: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  afterChange: (current) => setobjectid(current),
};


class Viewlibraries extends React.Component {
  constructor(props) {
    super(props);

    this.slideRef = React.createRef();

    this.state = {
      // licenseItems: null,
      tempImageView: null,
      imageFileArray: [],
      mainImagefile: [],

      name: "",
      libraryimage: [],
      allowedlicense: [],
      terms: "",
      website: "",
      description: "",
      status: true,
    };

  }


  async componentDidMount(evt) {
    // var licensetypes = await getLicenseType();
    // this.setState({ licenseItems: licensetypes, });
  }


  async componentDidUpdate(prevProps, prevState) {
    // if (this.props.isOpen && this.props.viewObjectID && prevProps.viewObjectID !== this.props.viewObjectID) {
    //   jQuery('#hiddenobjectID').val(this.props.viewObjectID);
    //   this.movetoSlide(this.props.viewObjectID);

    //   console.log(this.props.viewObjectID)
    
    // }

    setTimeout(() => {
      console.log(this.slideRef.current, "ref")
      if (this.props.isOpen && this.props.viewObjectID) {
        jQuery('#hiddenobjectID').val(this.props.viewObjectID);
        this.movetoSlide(this.props.viewObjectID);

      }
      if (this.props.deleteClicked === true && this.props.isOpen && this.props.viewObjectID && prevProps.viewObjectID !== this.props.viewObjectID) {
        deleteRecord()
      }
    }, 0);
    

  }



  movetoSlide = async (objid) => {
    var moveobjnumber = jQuery('.mediaslideitem[data-obj=' + objid + ']').data('id');
    this.slideRef.current?.slickGoTo(moveobjnumber)
  }


  editClick = async () => {
    var objid = jQuery('#hiddenobjectID').val();
    this.props.editStatus(objid);
  }


  deleteCurrentRecord = async () => {
    var objid = jQuery('#hiddenobjectID').val();
    var deletetext = jQuery('#deletefield');

    if (deletetext.val() === "delete") {
      deletetext.removeClass("is-invalid");

      var del = await deleteRecords(objid);
      if (del && del.id) {
        this.props.deletedStatus(del);
      }
    } else {
      deletetext.addClass("is-invalid");
    }

  }


  duplicateThis = async () => {

    var objid = jQuery('#hiddenobjectID').val();

    var dup = await duplicateRecord(objid);
    if (dup && dup.id) {
      this.props.editStatus(dup.id);
      this.props.duplicateRecordStatus("edited");
    }


  }



  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className='mediamodel viewpopup'>
        <input type="hidden" id="hiddenobjectID" />

        {/* <div className="loaderwrap"><div className="loader"></div></div> */}

        <a className="closebtn" onClick={this.props.toggle}>x</a>
        <div className="modal-header">
          <Row className="mt-3 justify-content-between">
            <Col className="col-9">
              <Button className="btn btn-default" onClick={this.editClick}><i className="fas fa-edit"></i> Edit</Button>
              <Button className="btn btn-default ml-3" onClick={this.duplicateThis}><i className="far fa-copy"></i> Duplicate</Button>
              <Button className="btn btn-danger ml-3" onClick={deleteRecord}><i className="fas fa-trash-alt"></i> Delete</Button>
            </Col>
          </Row>
        </div>

        <Slider ref={this.slideRef} className='ddd' {...popSlide}>




          {
            this.props.viewListFull && this.props.viewListFull.map((dynamicData, key) => {

              const assetarrays = dynamicData.get('allowedlicense');
              var assetids = [];
              if (assetarrays) {
                for (var i = 0; i < assetarrays.length; i++) {
                  assetids.push(assetarrays[i].id);
                }
              }

              return (
                <div className="item mediaslideitem" data-id={key} data-obj={dynamicData.id}>
                  <ModalBody>
                    <div className="addlibrary">

                      <Row className="mb-3 justify-content-between">
                        <Col><h5 className="modal-title">{dynamicData.get('name')}</h5></Col>
                        <Col className="col-auto">

                          <div className="activecheck float-right">
                            <Input id="viewstatus" type="checkbox" className="form-check-input form-check-input"
                              checked={dynamicData.get('recordstatus') === "active" ? true : false}
                            />
                            <label for="viewstatus" className="btn">
                              <i className="mr-1 fas fa-recycle"></i>
                              Active
                            </label>
                          </div>


                        </Col>
                      </Row>

                      <Row>
                        <Col className="col-md-5">
                          <div className="imgbx">

                            <Libraryimage
                              objid={dynamicData.id}
                              imageurl={dynamicData.get('libraryimage') ? dynamicData.get('libraryimage')[0] : ""}
                            />

                          </div>
                        </Col>
                        <Col className="col-md-7">
                          <div className='textbx'>
                            <Row className="justify-content-between">
                              {/* <Col className="col-md-10">
                                <div className='allowassets noclick d-flex flex-wrap'>

                                  {
                                    this.state.licenseItems && this.state.licenseItems.map((licData, key) => {
                                      return (
                                        <div className='checkbox' key={key}>
                                          <Input type="checkbox" id={`viewlice${key}`} value={licData.id}
                                            checked={assetids.indexOf(licData.id) !== -1}
                                          />
                                          <label htmlFor={`viewlice${key}`}>{licData.get('name')}</label>
                                        </div>
                                      )
                                    }
                                    )
                                  }

                                </div>

                              </Col> */}
                              <Col className="col-md-12 mt-4">
                                {
                                  dynamicData.get('terms') ?
                                    <a href={dynamicData.get('terms')} target="_blank" className='viewlink'>
                                      <i className="fas fa-external-link-alt"></i> {dynamicData.get('terms')}
                                    </a>
                                    :
                                    null
                                }

                                {
                                  dynamicData.get('website') ?
                                    <a href={dynamicData.get('website')} target="_blank" className='viewlink'>
                                      <i className="fas fa-external-link-alt"></i> {dynamicData.get('website')}
                                    </a>
                                    :
                                    null
                                }

                              </Col>
                              <Col className="col-md-12">
                                <div className='viewdescription'>
                                  <ScrollArea smoothScrolling={0.8} style={{ maxHeight: '195px' }}>
                                    <p>{dynamicData.get('description')}</p>
                                  </ScrollArea>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>

                    </div>
                  </ModalBody>
                </div>
              )
            }
            )
          }




        </Slider>

        <div id="detelepopup">
          <div className="modal-header">
            <Row className="mt-3 justify-content-between">
              <Col className="d-flex">
                <Input type="text" name="deletefield" id='deletefield' placeholder="Type 'delete' to confirm" />
                <Button className="btn btn-danger ml-3" onClick={this.deleteCurrentRecord}><i className="fas fa-trash-alt"></i> Delete</Button>
                <Button className="btn btn-default ml-3" onClick={closeDeleteRecord}><i className="fas fa-times"></i> Cancel</Button>
              </Col>
            </Row>
          </div>
        </div>

      </Modal>
    )
  }
}

export default Viewlibraries;