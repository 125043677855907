import jQuery from 'jquery';
var Parse = require('parse');

export const getFullPageCount = async () => {
    var Classifications = Parse.Object.extend("Classifications");
    var query = new Parse.Query(Classifications);
    query.equalTo("status", true);
    query.descending("createdAt");
    const count = await query.count();

    return count;
}

export const getSearchResults = async (searchdatas) => {
    var Classifications = Parse.Object.extend("Classifications");
    var query = new Parse.Query(Classifications);
    query.descending("createdAt");
    query.equalTo("status", true);

    if (searchdatas['searchbox']) {
        query.matches("name", searchdatas['searchbox'], 'i');
    }
    if (searchdatas['category']) {
        query.matches("category", searchdatas['category'], 'i');
    }

    if (searchdatas['statsrc'] == "true") {
        query.equalTo("status", true);
    } else if (searchdatas['statsrc'] == "false") {
        query.equalTo("status", false);
    }

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });

}

export const getClassifications = async (pagenum, dataperpage, sortitem, sortitemorder) => {
    var skipnum = pagenum - 1;
    var perpage = parseInt(dataperpage);

    var Jurisdictions = Parse.Object.extend("Classifications");
    var query = new Parse.Query(Jurisdictions);
    query.equalTo("status", true);

    if (sortitem !== "") {
        if (sortitemorder === "ascending") {
            query.ascending(sortitem);
        } else {
            query.descending(sortitem);
        }

    } else {
        query.descending('createdAt');
    }

    query.skip(skipnum * perpage);
    query.limit(perpage);

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}


export const getImagesList = async (objectid) => {
    var Classifications = new Parse.Object('Classifications');
    Classifications.id = objectid;

    var Classification_images = Parse.Object.extend("Classification_images");
    var query = new Parse.Query(Classification_images);
    query.equalTo("jurisdiction", Classifications);
    query.equalTo("status", true);

    var records = await query.find();
    return records;

    // return await query.find().then((records) => {
    //     return records;
    // }, (error) => {
    //     console.log(error);
    // });
}


export const hideRecords = async (objectids) => {
    var parseobject = Parse.Object.extend("Classifications");
    var query = new Parse.Query(parseobject);
    query.equalTo("objectId", objectids);

    return query.find().then((object) => {
        var realbject = object[0];
        realbject.set("status", false);
        return realbject.save().then((records) => {
            return true;
        }, (error) => {
            console.log(error);
        }).catch(function (error) {
        });

    }, (error) => {
    });

}


export const addRecord = async ({ name, status, category, reference_link, description, notes, imagefiles }) => {

    var classifications = new Parse.Object("Classifications");
    classifications.set("name", name);
    classifications.set("category", category);
    classifications.set("reference_link", reference_link);
    classifications.set("description", description);
    classifications.set("notes", notes);
    classifications.set("notes", notes);
    classifications.set("logoFiles", imagefiles);

    if (status === 'false') {
        classifications.set("status", false);
    } else {
        classifications.set("status", true);
    }



    return classifications.save().then(async (records) => {

        return records;
    }, (error) => {
        return false;
    }).catch(function (error) {
        return false;
    });
}


export const fetchRecordById = async ({ objectid }) => {
    var Classifications = Parse.Object.extend("Classifications");
    var query = new Parse.Query(Classifications);
    query.equalTo("objectId", objectid);
    return query.find().then((records) => {
        return records;
    }, (error) => {
        return false;
    }).catch(function (error) {
        return false;
    });
}


export const updateRecord = async ({ edithiddenid, name, category, reference_link, description, notes, status, imagefiles }) => {
    var Classifications = Parse.Object.extend("Classifications");
    var query = new Parse.Query(Classifications);
    query.equalTo("objectId", edithiddenid);

    return query.find().then(async (object) => {

        var realbject = object[0];

        realbject.set("name", name);
        realbject.set("category", category);
        realbject.set("reference_link", reference_link);
        realbject.set("description", description);
        realbject.set("notes", notes);
        realbject.set("logoFiles", imagefiles);

        if (status === 'false') {
            realbject.set("status", false);
        } else {
            realbject.set("status", true);
        }

        return realbject.save().then(async (records) => {
            return true;
        }, (error) => {
            return false;
        }).catch(function (error) {
            return false;
        });

    }, (error) => {
        return false;
    });

}

export const deleteImagesList = (objectid) => {
    var obj = new Parse.Object("Classification_images");
    obj.id = objectid;
    obj.destroy({});

    if (obj) {
        return true;
    }
}




export const checkFieldValid = (addoredit) => {

    var name = jQuery('#bname');
    var description = jQuery('#bdescription');
    var status = jQuery('#status');

    var errstat = true;
    if (name.val() === "") {
        name.addClass("is-invalid");
        errstat = false;
    } else {
        name.removeClass("is-invalid");
    }
    // if (description.val() === "") {
    //     description.addClass("is-invalid");
    //     errstat = false;
    // } else {
    //     description.removeClass("is-invalid");
    // }
    // if (status.val() === "") {
    //     status.addClass("is-invalid");
    //     errstat = false;
    // } else {
    //     status.removeClass("is-invalid");
    // }


    if (addoredit === "add") {

    }

    if (errstat === false) {
        return false;
    } else {
        return true;
    }

}

