import React from "react";

import {
	Row,
	Col,
	Card,
	CardBody,
	CardTitle,
	CardSubtitle,
	Input,
	ListGroup,
	ListGroupItem
} from 'reactstrap';

import Chart from 'react-c3-component';
import 'c3/c3.css';

import { getDomainStatuses, getDomains } from '../../../services/dashboard';
import imgglobe from '../../../assets/images/icons/icon-globe.png';


class DomainName extends React.Component {


	constructor(props) {
		super(props);
		this.state = {
			activestat: 0,
			availablestat: 0,
			inactivestat: 0,
			parkedstat: 0,
			otherstat: 0,
			unknownstat: 0,
			undefinedstat: 0,
		};
	}

	async componentDidMount(evt) {

		getDomainStatuses().then((value) => {
			for (var i = 0; i < value.length; i++) {
				if (value[i].get('name') == "Active") {
					getDomains(value[i].id).then((value) => {
						this.setState({ activestat: value });
					});

				} else if (value[i].get('name') == "Available") {
					getDomains(value[i].id).then((value) => {
						this.setState({ availablestat: value });
					});

				} else if (value[i].get('name') == "Inactive") {
					getDomains(value[i].id).then((value) => {
						this.setState({ inactivestat: value });
					});

				} else if (value[i].get('name') == "Parked") {
					getDomains(value[i].id).then((value) => {
						this.setState({ parkedstat: value });
					});

				} else if (value[i].get('name') == "Others") {
					getDomains(value[i].id).then((value) => {
						this.setState({ otherstat: value });
					});

				}
				else if (value[i].get('name') == "Unknown") {
					getDomains(value[i].id).then((value) => {
						this.setState({ unknownstat: value });
					});

				}
			}
		});


		getDomains('undefined').then((value) => { 
			this.setState({ undefinedstat: value });
		});

	}

	render() {
		return (
			/*--------------------------------------------------------------------------------*/
			/* Used In Dashboard-1 [Classic]                                                  */
			/*--------------------------------------------------------------------------------*/
			<Card className="card-hover domainname">
				<CardBody>
					<Row className="align-items-start">
						<Col  className="fixedwidth">
							<div>
								<Chart
									style={{ height: '200px', width: '100%', color: '#414241' }}
									config={{
										data: {
											columns: [
												['Active', this.state.activestat],
												['Available', this.state.availablestat],
												['Inactive', this.state.inactivestat],
												['Parked', this.state.parkedstat],
												['Others', this.state.otherstat],
												['Unknown ', this.state.unknownstat+this.state.undefinedstat],
											],

											type: 'donut',
											tooltip: {
												show: true
											}
										},
										donut: {
											label: {
												show: false
											},
											title: "",
											width: 35,
										},
										legend: {
											hide: true
										},
										color: {
											pattern: ['#40c4ff', '#2961ff', '#ff821c', '#7e74fb', '#ec5354','#fef16b']
										}
									}}
								/>
								<img src={imgglobe} alt={"imgglobe"} className="fixleft" />
							</div>
						</Col>
						<Col>
							<CardTitle>Domain Name Status</CardTitle>
							<ListGroup>
								<ListGroupItem><i className="fas fa-circle mr-1 text-cyan font-12"></i> Active <span>({this.state.activestat})</span></ListGroupItem>
								<ListGroupItem><i className="fas fa-circle mr-1 text-info font-12"></i> Available <span>({this.state.availablestat})</span></ListGroupItem>
								<ListGroupItem> <i className="fas fa-circle mr-1 text-orange font-12"></i> Inactive <span>({this.state.inactivestat})</span></ListGroupItem>
								<ListGroupItem><i className="fas fa-circle mr-1 text-primary font-12"></i> Parked <span>({this.state.parkedstat})</span></ListGroupItem>
								<ListGroupItem><i className="fas fa-circle mr-1 circle-red font-12"></i> Others <span>({this.state.otherstat})</span></ListGroupItem>
								{/* <ListGroupItem><i className="fas fa-circle mr-1 circle-yellow font-12"></i> Unknown <span>({this.state.unknownstat})</span></ListGroupItem> */}
								<ListGroupItem><i className="fas fa-circle mr-1 circle-yellow font-12"></i> Unknown <span>({this.state.unknownstat+this.state.undefinedstat})</span></ListGroupItem>

								{/* {
									this.state.undefinedstat > 0 ?
										<ListGroupItem><i className="fas fa-circle mr-1 circle-red font-12"></i> Undefined <span>({this.state.undefinedstat})</span></ListGroupItem>
										:
										""
								} */}

							</ListGroup>
						</Col>
					</Row>
				</CardBody>
			</Card>


		);
	}
}

export default DomainName;
