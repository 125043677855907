import jQuery from 'jquery';

var Parse = require('parse');
export const parseInitialize = ({ app_id, key, url }) => {
    Parse.initialize(app_id, key);
    Parse.serverURL = url;
}

export const authenticateLoggedIn = () => {

    var curruser = Parse.User.current();

    if (curruser === null) {
        window.location.assign('#/authentication/splashscreen');
    }
}
export const authenticateLoginpage = () => {

    var curruser = Parse.User.current();

    if (curruser !== null) {
        window.location.assign('#/dashboard');
    }
}
export const getCurrentUser = () => {
    return Parse.User.current();
}

function userHasRole(user, roleName) {
    var query = new Parse.Query(Parse.Role);
    query.equalTo("name", roleName);
    query.equalTo("users", user);
    return query.find().then(function (roles) {
        return roles.length > 0;
    });
}

export const isSuperAdmin = () => {
    var currentUser = Parse.User.current();
    return userHasRole(currentUser, "superAdmin").then(function (isSuperAdmin) {
        if (isSuperAdmin) {
            return true;
        } else {
            return false;
        }
    }).catch(function (error) {
        return false;
    });

}

export const currentUserDetails = () => {
    var currentUser = Parse.User.current();
    return currentUser;
}

export const login = async ({ username, password, remember }) => {
    return Parse.User.logIn(username, password).then((user) => {
        if (user) {

            if (user.attributes.status === true) {

                var expiry = new Date();
                // expiry.setTime(expiry.getTime() + (2 * 60 * 1000)); // 2 mnt
                expiry.setTime(expiry.getTime() + (4 * 60 * 60 * 1000)); // 4 hr
                document.cookie = "cookieLogin=yes; expires=" + expiry.toGMTString();

                // document.cookie = "cookieLogin=yes; max-age=7200; path=/;";
 
                //14 Days
                if (remember === true) {
                    var today = new Date();
                    var expire = new Date();
                    expire.setTime(today.getTime() + 3600000 * 24 * 14);
                    
                    document.cookie = "username=" + username + "; expires=" + expire.toGMTString();
                    document.cookie = "password=" + password + "; expires=" + expire.toGMTString();
                    document.cookie = "remember=" + true + "; expires=" + expire.toGMTString();
                     
                }

                return true;
            } else {
                logout('no');
                return false;
            }
        } else {
            return false;
        }
    }
    ).catch(function (error) {
        return false;
    });
}
export const logout = (redirect) => {
    Parse.User.logOut().then(
        (success) => {
            if (redirect === 'yes') {
                document.cookie = "cookieuser=no";
                window.location.assign('#/authentication/splashscreen');
            }
        }, (error) => {
            console.log('error logging out.');
        });
}


export const menuActivation = (currenturl) => {
    var els = document.querySelectorAll('.nav-link.active');
    for (var i = 0; i < els.length; i++) {
        els[i].classList.remove('active')
    }
    var links = document.querySelectorAll('a[href="' + currenturl + '"]');
    links[0].classList.add('active');
}

export const addHeaderTitle = (headertitle) => {
    jQuery('#headertitle').html(headertitle);
}
export const addBreadcrumb = (breadcrumb) => {
    jQuery('#topbreadcrumb').html(breadcrumb);
}

export const getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

// export const sendPasswordResetEmail = async (email) => {
//     var result = Parse.Cloud.run('sendPasswordResetEmail', {
//         email: email
//     });
//     return result;
// }

export const sendPasswordResetEmail = async (email,recoverurl) => {
    var result = Parse.Cloud.run('sendPasswordResetURLEmail', {
        email: email,
        recoverurl:recoverurl
    });
    return result;
}

export const ValidateUserPasscode = async (email, passcode) => {
    var result = Parse.Cloud.run('ValidateUserPasscode', {
        email: email,
        code: passcode
    });
    return result;
}
export const ResetUserPassword = async (email, emailpwdcode, password) => {
    var result = Parse.Cloud.run('ResetUserPassword', {
        email: email,
        code: emailpwdcode,
        password: password
    })
    return result;
}
