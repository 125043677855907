import React, { Component, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import ScrollArea from 'react-scrollbar';
import { Row, Col, Carousel, CarouselItem, CarouselControl, Button, Table, Input, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, ModalFooter, Label, Alert, UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem, } from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
import jQuery from 'jquery';
import { addBreadcrumb, addHeaderTitle } from '../../../services/parse';
import { isAdmin } from '../../../services/user';
import { assetData, getFullPageCount, getSearchResults, getMediaLicenseLibraryFull, getMediaLicenseLibrary, undoRecord, getAssetType } from '../../../services/medialicenselibraries';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import arrrowRight from '../../../assets/images/icons/arrow-right-v2.png';
import arrrowLeft from '../../../assets/images/icons/arrow-left-v2.png';
// import iconCamera from '../../../assets/images/icons/icon-camera.png';
// import iconPencil from '../../../assets/images/icons/icon-pencil.png';
// import iconMedia from '../../../assets/images/icons/icon-media.png';
// import iconAudio from '../../../assets/images/icons/icon-audio.png';
import iconClose from '../../../assets/images/icons/close.png';
import noImageSlider from '../../../assets/images/noimageslider.png';

import { getFileUrl } from '../../../services/awsFileUpload';


import Libraryimage from "./Libraryimage";
import Addlibraries from "./Addlibraries";
import Editlibraries from "./Editlibraries";
import Viewlibraries from "./Viewlibraries";
import Assetexpiry from "./Assetexpiry";
import LibraryImages from "./LibraryImages";



var postperpage = 9;

var heroSlide = {
  autoplay: true,
  autoplaySpeed: 5000,
  dots: false,
  arrows: true,
  infinite: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1
};


var iconCamera = "";
var iconMedia = "";
var iconAudio = "";
var iconPencil = "";


class mediaLibrary extends React.Component {

  constructor(props) {
    super(props);
    this.state = { visible: true };
    this.addnewpopuptoggle = this.addnewpopuptoggle.bind(this);
    this.editpopuptoggle = this.editpopuptoggle.bind(this);
    this.viewpopuptoggle = this.viewpopuptoggle.bind(this);
    this.deletepopuptoggle = this.deletepopuptoggle.bind(this);
    this.onDismiss = this.onDismiss.bind(this);

    this.deleteClicked = false;


    // this.editObjectID = null;
    this.libraryList = null;
    this.viewlibraryListFull = null;

    this.state = {
      pagenum: 1,
      editObjectID: null,
      assetData: null,
      loader: false,
      assetTypes: null,
      popupstatus: "",
      assetsAWSURL: []
    }
  }


  componentWillMount = async () => {
    var isadminval = await isAdmin();
    if (isadminval) {
      var currentrole = isadminval[0].get('role').get('name');
      this.setState({ currentRole: currentrole });
      addBreadcrumb('Home / Media Licenses');
      addHeaderTitle('Libraries');
    }

    this.setState({ loader: true });


    let params_arr = [];
    let urlString = window.location.href;
    let paramString = urlString.split('?')[1];
    if (paramString) {
      params_arr = paramString.split('&');
    }
    for (let i = 0; i < params_arr.length; i++) {
      let pair = params_arr[i].split('=');

      if (pair[0] === "objectid") { // this is for popup from activity
        this.setState({
          deleteClicked: false,
          popupstatus: "view",
          viewpopup: !this.state.viewpopup
        });
        this.setObjectID(pair[1]);
        window.history.pushState({}, null, '/#/medialicense/libraries/');
      }
    }




    var allassetdata = await assetData();
    this.setState({ assetData: allassetdata });

    console.log(allassetdata, "allassetdata")

    var assettypes = await getAssetType();
    this.setState({ assetTypes: assettypes });


    for (var i = 0; i < assettypes.length; i++) {
      if (assettypes[i].get('icon')) {
        var img = assettypes[i].get('icon')[0].awsFileName;
        var imgurl = await getFileUrl("contentmaster", img);

        if (assettypes[i].get('name') === "Image") {
          iconCamera = imgurl.url;
        } else if (assettypes[i].get('name') === "Video") {
          iconMedia = imgurl.url;
        } else if (assettypes[i].get('name') === "Audio") {
          iconAudio = imgurl.url;
        } else if (assettypes[i].get('name') === "Vector") {
          iconPencil = imgurl.url;
        }

      }
    }





    jQuery('.ddd').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
      console.log(nextSlide);
    });

    this.getData();

  }


  getData = async () => {
    this.setState({ pagenum: 1 });

    var licenselibrary = await getMediaLicenseLibrary(postperpage, 1, "", "");
    this.setState({ libraryList: licenselibrary });
    this.getAwsImageUrl(licenselibrary);


    // Total count for paginate
    var fullpagecount = await getFullPageCount();
    console.log(fullpagecount, "fullpagecount")
    this.setState({ datafullcount: fullpagecount });

    var totpagecount = Math.ceil(fullpagecount / postperpage);
    this.setState({ totalpagecount: totpagecount, });
    // Total count for paginate


    this.updatePagination(1, postperpage <= fullpagecount ? postperpage : fullpagecount, fullpagecount);

    this.setState({ loader: false });

    var licenselibraryfull = await getMediaLicenseLibraryFull();
    this.setState({ viewlibraryListFull: licenselibraryfull });


  }


  gotoPage = async (pagenum) => {
    this.setState({ pagenum: pagenum, popupstatus: "pagination" });
    var searchval = jQuery('#searchbox').val();
    var filterradio = jQuery('input[name="filterbyradio"]:checked').val();


    var licenselibrary = await getMediaLicenseLibrary(postperpage, pagenum, searchval, filterradio);
    this.getAwsImageUrl(licenselibrary);
    this.setState({ libraryList: licenselibrary });

    // Total count for paginate
    var fullpagecount = await getFullPageCount(searchval, filterradio);
    this.setState({ datafullcount: fullpagecount });
    var totpagecount = Math.ceil(fullpagecount / postperpage);
    this.setState({ totalpagecount: totpagecount, });
    // Total count for paginate


    var from = (pagenum - 1) * postperpage + 1;
    var to = from + postperpage - 1;
    if (to > this.state.datafullcount) {
      to = this.state.datafullcount;
    }

    this.updatePagination(from, to, fullpagecount);

    this.setState({ loader: false });
  }


  nextClick = (event) => {


    var pagenum = this.state.pagenum;
    var tot = this.state.totalpagecount;
    var nextpage = pagenum + 1;

    if (pagenum < tot) {
      this.setState({ loader: true });
      this.gotoPage(nextpage)
    }
  }

  prevClick = (event) => {


    var pagenum = this.state.pagenum;
    var prevpage = pagenum - 1;

    if (pagenum > 1) {
      this.setState({ loader: true });
      this.gotoPage(prevpage)
    }
  }

  updatePagination = (from, to, fullpagecount) => {
    var paginatetext = from + "-" + to + " / " + fullpagecount;
    this.setState({ paginatestring: paginatetext });
  }


  clearSearch = async () => {
    document.getElementById('searchbox').value = "";
    this.gotoPage(1);
  }


  addnewpopuptoggle() {
    this.setState({
      addnewpopup: !this.state.addnewpopup,
      popupstatus: "add",
      viewpopup: false,
    });
  }

  viewpopuptoggle(e) {
    this.setState({
      deleteClicked: false,
      popupstatus: "view",
      viewpopup: !this.state.viewpopup
    });
    this.setObjectID(e.target.getAttribute("data-objectid"));
  }

  editpopuptoggle(e) {
    this.setState({
      deleteClicked: false,
      popupstatus: "edit",
      editpopup: !this.state.editpopup,
      viewpopup: false
    });
    this.setObjectID(e.target.getAttribute("data-objectid"));
  }

  deletepopuptoggle(e) {
    this.setState({
      viewpopup: !this.state.viewpopup,
      popupstatus: "view",
      deleteClicked: true
    });

    this.setObjectID(e.target.getAttribute("data-objectid"));
  }

  onDismiss() {
    this.setState({
      visible: false
    });
  }

  setObjectID(objectid) {
    this.setState({
      editObjectID: objectid !== null ? objectid : null
    });
  }


  undoRecord = async () => {
    var undo = await undoRecord(this.state.deletedID);
    if (undo) {
      this.setState({
        deletedID: null,
        deletedName: null,
      });

      this.getData();
    }
  }


  getAwsImageUrl = (assetsArray) => {
    assetsArray.map(async (dynamicData, key) => {

      let assetimage = dynamicData.get('libraryimage');

      if (assetimage && assetimage.length && assetimage[0] != "") {
        let imageurl = assetimage[0];
        var awsurl = await this.getImageURL(imageurl);

        if (imageurl.awsFileName) {
          this.setState(prevState => ({
            assetsAWSURL: [
              ...prevState.assetsAWSURL,
              {
                fileName: imageurl.awsFileName,
                url: awsurl
              }
            ]
          }));

        }

      }

    });

    return assetsArray;
  }

  getImageURL = async (img) => {
    if (img.awsFileName) {
      var imgurl = await getFileUrl("contentmaster", img.awsFileName);
      return imgurl.url;
    } else {
      return "";
    }


  }

  getAssetUrl = (assetimage) => {

    if (assetimage && assetimage.length && assetimage[0] != "" && this.state.assetsAWSURL.length) {
      let imageurl = assetimage[0];

      let awsImageUrl = this.state.assetsAWSURL.find((obj) => obj.fileName === imageurl.awsFileName);

      if (awsImageUrl) {
        return awsImageUrl.url
      } else {
        return "";
      }
    }
    return "";

  }


  render() {

    return (
      <div className="animated fadeIn">

        <div className="pageheader">
          <Row className="justify-content-between">
            <Col md="4">
              <Button className="btn btn-default btnaddmew pluswhite" onClick={this.addnewpopuptoggle}>Add new</Button>
            </Col>
            <Col md="8">
              <Row className="align-items-end">
                <Col md="8">
                  <div className="tableserch">
                    <InputGroup>
                      <Input type="text" placeholder="Enter keyword here..." id="searchbox"
                        onChange={
                          async (event) => {
                            this.gotoPage(1);
                          }
                        }
                      />
                      {/* <InputGroupAddon addonType="append"> */}
                      <Button className="search" ><i className="ti-search" ></i></Button>
                      {/* </InputGroupAddon> */}
                      <a href="javascript:" className="searchclose" onClick={this.clearSearch}><img src={iconClose} alt="img" /></a>
                    </InputGroup>
                  </div>


                  <div className="libfilters mt-4">
                    <div className="libradio">
                      <div className='radiobx'>
                        <Input type="radio" name="filterbyradio" id="radioh11" value="recentlyadded"
                          onChange={
                            async (event) => {
                              this.gotoPage(1);
                            }
                          }
                        ></Input>
                        <label htmlFor="radioh11">Recently added</label>
                      </div>
                      <div className='radiobx'>
                        <Input type="radio" name="filterbyradio" id="radioh12" value="archive"
                          onChange={
                            async (event) => {
                              this.gotoPage(1);
                            }
                          }
                        ></Input>
                        <label htmlFor="radioh12">Archived</label>
                      </div>
                    </div>
                    <a href="javascript:" className="clearbtn"
                      onClick={
                        async (event) => {
                          jQuery('input[name="filterbyradio"]').prop('checked', false);
                          document.getElementById('searchbox').value = "";
                          this.gotoPage(1);
                        }
                      }
                    >Clear</a>
                  </div>

                </Col>

                <Col md="4">
                  <div className="paginationbx float-right">
                    {this.state.paginatestring ? this.state.paginatestring : null}
                    <a href="javascript:" onClick={this.prevClick}><img src={arrrowLeft} alt="img" /></a>
                    <a href="javascript:" onClick={this.nextClick}><img src={arrrowRight} alt="img" /></a>
                  </div>
                </Col>

              </Row>
            </Col>
          </Row>
        </div>

        <Row className="row">
          {
            this.state.libraryList && this.state.libraryList.length > 0 ?
              this.state.libraryList.map((dynamicData, key) => {

                var date1 = new Date();
                var date2 = new Date(dynamicData.createdAt);
                var diffDays = parseInt((date1 - date2) / (1000 * 60 * 60 * 24), 10);
                diffDays = Math.abs(diffDays);

                var imagefilterlink = `/medialicense/assets/?assettype=${this.state.assetTypes.filter(ass => ass.get('name') === 'Image')[0].id}&library=${dynamicData.id}`;
                var vectorfilterlink = `/medialicense/assets/?assettype=${this.state.assetTypes.filter(ass => ass.get('name') === 'Vector')[0].id}&library=${dynamicData.id}`;
                var videofilterlink = `/medialicense/assets/?assettype=${this.state.assetTypes.filter(ass => ass.get('name') === 'Video')[0].id}&library=${dynamicData.id}`;
                var audiofilterlink = `/medialicense/assets/?assettype=${this.state.assetTypes.filter(ass => ass.get('name') === 'Audio')[0].id}&library=${dynamicData.id}`;
                var viewlibrarylink = `/medialicense/assets/?library=${dynamicData.id}`;

                if (dynamicData.get('recordstatus') === "archive") {
                  imagefilterlink = '/medialicense/assets/';
                  vectorfilterlink = '/medialicense/assets/';
                  videofilterlink = '/medialicense/assets/';
                  audiofilterlink = '/medialicense/assets/';
                  viewlibrarylink = '/medialicense/assets/';
                }

                return (
                  <Col className="col-md-4">
                    <div className="librarycard">
                      <h2>{dynamicData.get('name')}</h2>
                      <UncontrolledDropdown nav inNavbar className="dotsmenu">
                        <DropdownToggle nav>
                          <i className="fas fa-ellipsis-v"></i>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem data-objectid={dynamicData.id} onClick={this.viewpopuptoggle}>
                            View
                          </DropdownItem>
                          <DropdownItem data-objectid={dynamicData.id} onClick={this.editpopuptoggle}>
                            Edit
                          </DropdownItem>
                          <DropdownItem data-objectid={dynamicData.id} onClick={this.deletepopuptoggle}>
                            Delete
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>


                      <div className="mt-2 expiringsoonwrap">
                        {
                          this.state.assetData && this.state.assetData.datacountArray && this.state.assetData.datacountArray[dynamicData.id] ?
                            <span className="expiringsoon">{this.state.assetData.datacountArray[dynamicData.id]} Expiring in 30 days</span>
                            :
                            null
                        }

                        {
                          diffDays <= 10 ?
                            <span className="expiringsoon">Recently Added</span>
                            :
                            null
                        }

                      </div>

                      <div className="sliderbx">
                        <Slider {...heroSlide}>

                          {
                            this.state.assetData &&
                              this.state.assetData.dataArray && this.state.assetData.dataArray.filter(item => item.get('medialicenselibraryid').id === dynamicData.id).length > 0 ?

                              this.state.assetData.dataArray.filter(item => item.get('medialicenselibraryid').id === dynamicData.id).slice(0, 3).map((assetdata, vkey) => {

                                var date1 = new Date();
                                var date2 = new Date(assetdata.createdAt);
                                var diffDaysasset = parseInt((date1 - date2) / (1000 * 60 * 60 * 24), 10);
                                diffDaysasset = Math.abs(diffDaysasset);

                                return (
                                  <div className="item">
                                    <div className="imgbx">

                                      <Libraryimage
                                        objid={assetdata.id}
                                        imageurl={assetdata.get('assetimage') ? assetdata.get('assetimage')[0] : ""}
                                        editObjectID={null}
                                        popupstatus={this.state.popupstatus}
                                      />

                                    </div>
                                    <div className="textbx objectimage">
                                      <Row className="mb-3">
                                        <Col>
                                          <div className="imgid">
                                            {
                                              assetdata.get('assettypetext') === "Image" && iconCamera ?
                                                <img src={iconCamera} alt="img" />
                                                :
                                                assetdata.get('assettypetext') === "Video" && iconMedia ?
                                                  <img src={iconMedia} alt="img" />
                                                  :
                                                  assetdata.get('assettypetext') === "Audio" && iconAudio ?
                                                    <img src={iconAudio} alt="img" />
                                                    :
                                                    assetdata.get('assettypetext') === "Vector" && iconPencil ?
                                                      <img src={iconPencil} alt="img" />
                                                      :
                                                      null
                                            }
                                            {
                                              assetdata.get('asseturl') ?
                                                <a href={assetdata.get('asseturl')} target="_blank"> {assetdata.get('assetid')} </a>
                                                :
                                                <a href="javascript:"> {assetdata.get('assetid')} </a>
                                            }

                                          </div>
                                        </Col>
                                        <Col className="col-auto pl-0">
                                          <div className="plandetail btngreen">{assetdata.get('licensetype') ? assetdata.get('licensetype').get('name') : ""}</div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <ScrollArea smoothScrolling={0.8} className="thinsscroll mb-3" style={{ maxHeight: '80px', minHeight: '80px' }}>
                                            <p className="shortdetail">{assetdata.get('description')}</p>
                                          </ScrollArea>
                                        </Col>
                                      </Row>
                                      <Row className="align-items-center">
                                        <Col>
                                          {/* <div className="timeago">Added {diffDays} days ago</div> */}
                                          {
                                            diffDaysasset === 0 ?
                                              <div className="timeago">Added today</div>
                                              :
                                              <div className="timeago">Added {diffDaysasset} days ago</div>
                                          }
                                        </Col>

                                      </Row>
                                    </div>
                                  </div>
                                )
                              })
                              :
                              <div className="item">
                                <div className="imgbx">
                                  {/* <Libraryimage
                                    objid={dynamicData.id}
                                    imageurl={dynamicData.get('libraryimage') ? dynamicData.get('libraryimage')[0] : ""}
                                    editObjectID={this.state.editObjectID}
                                    popupstatus={this.state.popupstatus}
                                  /> */}

                                  <LibraryImages
                                    objid={dynamicData.id}
                                    imageurl={this.getAssetUrl(dynamicData.get('libraryimage'))}
                                  />
                                </div>
                                <div className="textbx objectimage">
                                  <span>No assets</span>
                                </div>
                              </div>

                          }


                        </Slider>
                      </div>

                      <ul className="d-flex justify-content-between assetsdetails">
                        <li>
                          <Link to={imagefilterlink}
                            className={`d-flex align-items-center 
                              ${this.state.assetData && this.state.assetData.imagecountArray && this.state.assetData.imagecountArray[dynamicData.id] ? "" : "noclick"}
                            `}>
                            <img src={iconCamera} alt="img" />
                            {
                              this.state.assetData && this.state.assetData.imagecountArray && this.state.assetData.imagecountArray[dynamicData.id] ?
                                <span>{this.state.assetData.imagecountArray[dynamicData.id]}</span>
                                :
                                <span>0</span>
                            }
                          </Link>
                        </li>
                        <li>
                          <Link to={vectorfilterlink}
                            className={`d-flex align-items-center 
                            ${this.state.assetData && this.state.assetData.vectorcountArray && this.state.assetData.vectorcountArray[dynamicData.id] ? "" : "noclick"}
                          `}>
                            <img src={iconPencil} alt="img" />
                            {
                              this.state.assetData && this.state.assetData.vectorcountArray && this.state.assetData.vectorcountArray[dynamicData.id] ?
                                <span>{this.state.assetData.vectorcountArray[dynamicData.id]}</span>
                                :
                                <span>0</span>
                            }
                          </Link>
                        </li>
                        <li>
                          <Link to={videofilterlink}
                            className={`d-flex align-items-center 
                            ${this.state.assetData && this.state.assetData.videocountArray && this.state.assetData.videocountArray[dynamicData.id] ? "" : "noclick"}
                          `}>
                            <img src={iconMedia} alt="img" />
                            {
                              this.state.assetData && this.state.assetData.videocountArray && this.state.assetData.videocountArray[dynamicData.id] ?
                                <span>{this.state.assetData.videocountArray[dynamicData.id]}</span>
                                :
                                <span>0</span>
                            }
                          </Link>
                        </li>
                        <li>
                          <Link to={audiofilterlink}
                            className={`d-flex align-items-center 
                            ${this.state.assetData && this.state.assetData.audiocountArray && this.state.assetData.audiocountArray[dynamicData.id] ? "" : "noclick"}
                          `}>
                            <img src={iconAudio} alt="img" />
                            {
                              this.state.assetData && this.state.assetData.audiocountArray && this.state.assetData.audiocountArray[dynamicData.id] ?
                                <span>{this.state.assetData.audiocountArray[dynamicData.id]}</span>
                                :
                                <span>0</span>
                            }
                          </Link>
                        </li>
                      </ul>

                      <div className="mt-4 text-center">
                        <Link to={viewlibrarylink} className="btn btnblank">
                          View Library
                        </Link>
                      </div>
                    </div>
                  </Col>
                )
              }
              )
              :
              this.state.loader === false ?
                <p className="whitetext">No record found</p>
                :
                null
          }


          {
            this.state.loader ?
              <div className="loaderwrap liblist"><div className="loader"></div></div>
              :
              null
          }



        </Row>



        <Addlibraries
          isOpen={this.state.addnewpopup}
          toggle={this.addnewpopuptoggle}
          addStatus={async (addstatus) => {
            if (addstatus.id) {

              this.setObjectID(addstatus.id);
              await this.getData();

              this.setState({
                addnewpopup: !this.state.addnewpopup
              });
              this.setState({
                viewpopup: !this.state.viewpopup
              });

              // this.setState({
              //   popupstatus: "", 
              // }); 

            }
          }}
        />


        <Editlibraries
          isOpen={this.state.editpopup}
          toggle={this.editpopuptoggle}
          editObjectID={this.state.editObjectID}
          editRecordStatus={(editstatus) => {
            if (editstatus.id) {

              setTimeout(
                function () {
                  this.setState({
                    deleteClicked: false,
                    popupstatus: "view",
                    editpopup: !this.state.editpopup,
                    viewpopup: !this.state.viewpopup,
                  });
                }
                  .bind(this),
                1000
              );

              this.gotoPage(1);


            }
          }}
        />


        <Viewlibraries
          isOpen={this.state.viewpopup}
          toggle={this.viewpopuptoggle}
          viewObjectID={this.state.editObjectID}
          viewListFull={this.state.viewlibraryListFull}
          deleteClicked={this.state.deleteClicked ? this.state.deleteClicked : false}
          assetsAWSURL={this.state.assetsAWSURL}
          editStatus={(editid) => {
            this.setState({
              editpopup: !this.state.editpopup
            });
            this.setState({
              viewpopup: false
            });
            this.setObjectID(editid);
          }}
          deletedStatus={(deleteobj) => {
            this.setState({
              deletedID: deleteobj.id,
              deletedName: deleteobj.get('name'),
            });
            this.setState({
              deleteClicked: false
            });
            this.setState({
              viewpopup: !this.state.viewpopup
            });

            this.gotoPage(1);


            setTimeout(function () {
              this.setState({
                deletedID: null,
              });
            }.bind(this), 10000);

          }}
          duplicateRecordStatus={(editstatus) => {
            if (editstatus) {
              this.gotoPage(1);
            }
          }}
        />



        {
          this.state.deletedID ?
            <Alert className="deleteundo" color="warning" isOpen={this.state.visible} toggle={this.onDismiss}>
              The Library '{this.state.deletedName}' has been deleted <a href="javacript:" onClick={this.undoRecord} className="alert-link"><i className="fas fa-undo"></i> Undo</a>
            </Alert>
            :
            null
        }





      </div>
    )
  }
}

export default mediaLibrary;
