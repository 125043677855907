// import React from 'react';
import React, { Component, useState, useEffect } from "react";
import { getFileUrl } from '../../../services/awsFileUpload';

class Assetimage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      awsImageUrl: "",
    };

  }

  // async componentDidMount(evt) {

  //   console.log("mounting")

  //   if (this.props.imageurl && this.props.imageurl !== "") {
  //     var awsurl = await this.getImageURL(this.props.imageurl);
  //     this.setState({ awsImageUrl: awsurl });
  //   }
  // }

  // async componentDidUpdate(prevProps, prevState) {

  //   // if (this.props.updateIDforImage === this.props.objid && prevProps.updateIDforImage === "" && this.props.popupstatus === "edit") {  //EDIT
  //   //   var awsurl = await this.getImageURL(this.props.imageurl);
  //   //   this.setState({ awsImageUrl: awsurl });
  //   //   console.log('edit image refetch');
  //   // }

  //   // if (this.props.updateIDforImage === this.props.objid && prevProps.updateIDforImage === prevProps.objid && this.props.popupstatus === "add") {  //ADD
  //   //   var awsurl = await this.getImageURL(this.props.imageurl);
  //   //   this.setState({ awsImageUrl: awsurl });
  //   //   console.log('add image refetch');
  //   // }

  //   // if (prevProps.objid !== this.props.objid && this.props.imageurl !== "" && prevProps.updateIDforImage === "" && this.props.popupstatus === "filter" ) {
  //   //   var awsurl = await this.getImageURL(this.props.imageurl);
  //   //   this.setState({ awsImageUrl: awsurl });
  //   //   console.log('filter image refetch');
  //   // }

  //   // if (prevProps.objid !== this.props.objid && this.props.imageurl !== "" && prevProps.updateIDforImage === "" && this.props.popupstatus === "view" ) { // delete also same
  //   //   var awsurl = await this.getImageURL(this.props.imageurl);
  //   //   this.setState({ awsImageUrl: awsurl });
  //   //   console.log('View image refetch');
  //   // }



  //   // if (this.props.imageurl !== prevProps.imageurl && this.props.popupstatus === "edit") {
  //   //   var awsurl = await this.getImageURL(this.props.imageurl);
  //   //   this.setState({ awsImageUrl: awsurl });
  //   //   console.log('general image refetch');
  //   // }
  //   // if (this.props.imageurl !== prevProps.imageurl && this.props.popupstatus === "add") {
  //   //   var awsurl = await this.getImageURL(this.props.imageurl);
  //   //   this.setState({ awsImageUrl: awsurl });
  //   //   console.log('general image refetch');
  //   // }
  //   // if (this.props.imageurl !== prevProps.imageurl && this.props.popupstatus === "filter") {
  //   //   var awsurl = await this.getImageURL(this.props.imageurl);
  //   //   this.setState({ awsImageUrl: awsurl });
  //   //   console.log('general image refetch');
  //   // }
  //   // if (this.props.imageurl !== prevProps.imageurl && this.props.popupstatus === "view") {
  //   //   var awsurl = await this.getImageURL(this.props.imageurl);
  //   //   this.setState({ awsImageUrl: awsurl });
  //   //   console.log('general image refetch');
  //   // }


  //   // var awsurl = await this.getImageURL(this.props.imageurl);
  //   // this.setState({ awsImageUrl: awsurl });
  //   // console.log('general image refetch');

  // }

  // async componentDidUpdate(prevProps) {
  //   if (this.props.imageurl !== prevProps.imageurl) {
  //     if (this.props.imageurl && this.props.imageurl !== "") {
  //       var awsurl = await this.getImageURL(this.props.imageurl);
  //       this.setState({ awsImageUrl: awsurl });
  //     }
  //   }
  // }

  // getImageURL = async (img) => {
  //   console.log(img, "img")
  //   if (img.awsFileName) {
  //     var imgurl = await getFileUrl("contentmaster", img.awsFileName);
  //     return imgurl.url;
  //   } else {
  //     return "";
  //   }
    
    
  // }

  // componentDidMount(){
  //   console.log(this.props.imageurl,  "mount")
  // }
  

  render() {
    return (

      // this.state.awsImageUrl ?
      //   <img data-objectid={this.props.objid} src={this.state.awsImageUrl} alt='img' />
      //   :
      //   <p>Loading...</p>

      this.props.imageurl ?
      <img data-objectid={this.props.objid} src={this.props.imageurl} alt='img' />
      :
      <p>Loading...</p>

    )
  }
}

export default Assetimage;