import React from 'react';
import { Link } from 'react-router-dom';
import ScrollArea from 'react-scrollbar';
import { Row, Col, Carousel, CarouselItem, CarouselControl, Button, Table, Input, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, ModalFooter, Label, Alert, UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem, TabContent, TabPane, CardBody, Nav, NavItem, NavLink, Card, CardTitle, CardText } from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
import jQuery from 'jquery';
import classnames from 'classnames';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import imageDummy from '../../../assets/images/dummy.jpg';
import iconLink from '../../../assets/images/icons/icon-link.png';
import iconCamera from '../../../assets/images/icons/icon-camera.png';
import iconPencil from '../../../assets/images/icons/icon-pencil.png';
import iconMedia from '../../../assets/images/icons/icon-media.png';
import iconAudio from '../../../assets/images/icons/icon-audio.png';
import iconShare from '../../../assets/images/icons/icon-share.png';
import Moment from 'moment';

import Assetimage from "./Assetimage";

import { addRecord, getLicenseType, deleteRecords, duplicateRecord, checkFieldValid } from '../../../services/medialicenseassets';
import AssetImages from './AssetImages';

function deleteRecord() {
  document.getElementById("detelepopup").classList.add("active");
}
function closeDeleteRecord() {
  document.getElementById("detelepopup").classList.remove("active");
}

function setobjectid(num) {
  var elSlide = jQuery('.mediaslideitem[data-id=' + num + ']').data('obj');
  jQuery('#hiddenobjectID').val(elSlide);
}

var popSlide = {
  autoplay: false,
  autoplaySpeed: 5000,
  dots: false,
  arrows: true,
  infinite: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: true,
  afterChange: (current) => setobjectid(current),
};


class Viewassets extends React.Component {
  constructor(props) {
    super(props);

    this.slideRef = React.createRef();

    this.state = {
      activeTab: '1',
      assetsArray: [],
    };

  }


  async componentDidMount(evt) {
    // var licensetypes = await getLicenseType();
    // this.setState({ licenseItems: licensetypes, });


    if (this.props.viewListFull.length && this.props.viewObjectID != "") {
      let assets = this.moveObjectToFirstIndex(this.props.viewListFull, this.props.viewObjectID);
      this.setState({ assetsArray : assets })

    }


    setTimeout(() => {

      if (this.props.isOpen && this.props.viewObjectID) {
        jQuery('#hiddenobjectID').val(this.props.viewObjectID);

        this.movetoSlide(this.props.viewObjectID);
      }
      if (this.props.deleteClicked === true && this.props.isOpen && this.props.viewObjectID ) {
        deleteRecord()
      }
    }, 0);
  }


  async componentDidUpdate(prevProps, prevState) {

   


  }

  moveObjectToFirstIndex(arr, id) {
    const index = arr.findIndex(obj => obj.id === id);
    if (index !== -1) {
      const obj = arr.splice(index, 1)[0];
      arr.unshift(obj);
    }
    return arr;
  }


  toggleTabs(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  movetoSlide = async (objid) => {
    var moveobjnumber = jQuery('.mediaslideitem[data-obj=' + objid + ']').data('id');

    // this.slideRef.current.slickGoTo(moveobjnumber)
    if (this.slideRef.current) {
      this.slideRef.current.slickGoTo(moveobjnumber);
    }
  }


  editClick = async () => {
    var objid = jQuery('#hiddenobjectID').val();
    this.props.editStatus(objid);
  }


  deleteCurrentRecord = async () => {
    var objid = jQuery('#hiddenobjectID').val();
    var deletetext = jQuery('#deletefield');

    if (deletetext.val() === "delete") {
      deletetext.removeClass("is-invalid");

      var del = await deleteRecords(objid);
      if (del && del.id) {
        this.props.deletedStatus(del);
      }
    } else {
      deletetext.addClass("is-invalid");
    }

  }


  duplicateThis = async () => {

    var objid = jQuery('#hiddenobjectID').val();

    var dup = await duplicateRecord(objid, "assetview");
    if (dup && dup.id) {
      this.props.editStatus(dup.id);
      this.props.duplicateRecordStatus(dup);
    }


  }


  getAssetUrl =  (assetimage) => {

    if (assetimage && assetimage.length && assetimage[0] != "" && this.props.assetsAWSURL.length) {
      let imageurl = assetimage[0];

      let awsImageUrl = this.props.assetsAWSURL.find((obj) => obj.fileName === imageurl.awsFileName);

      if (awsImageUrl) {
        return awsImageUrl.url
      } else {
        return "";
        
      }
    }
    return "";

  }


  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className='mediamodel viewpopup'>
        <input type="hidden" id="hiddenobjectID" />

        <div className="modal-header">
          <a className="closebtn" onClick={this.props.toggle}>x</a>
          <Row className="mt-3 justify-content-between">
            <Col className="col-9">
              <Button className="btn btn-default" onClick={this.editClick}><i className="fas fa-edit"></i> Edit</Button>
              <Button className="btn btn-default ml-3" onClick={this.duplicateThis}><i className="far fa-copy"></i> Duplicate</Button>
              <Button className="btn btn-danger ml-3" onClick={deleteRecord}><i className="fas fa-trash-alt"></i> Delete</Button>
            </Col>
          </Row>
        </div>

        <Slider ref={this.slideRef} className='ddd' {...popSlide}>




          {
            this.state.assetsArray ?

              this.state.assetsArray.map((dynamicData, key) => {

                var expiring = "";
                if (dynamicData.get('expiry')) {
                  var date1 = new Date();
                  var date2 = new Date(dynamicData.get('expiry'));
                  var diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);

                  expiring = diffDays + 1;
                }

                return (
                  <div className="item mediaslideitem" data-id={key} data-obj={dynamicData.id}>
                    <ModalBody>
                      <div className="addlibrary addassets">

                        <Row className="mb-4 justify-content-between">
                          <Col className="col">

                            <div className="activecheck">
                              <Input id="viewstatus" type="checkbox" className="form-check-input form-check-input"
                                checked={dynamicData.get('recordstatus') === "active" ? true : false}
                              />
                              <label htmlFor="viewstatus" className="btn">
                                <i className="mr-1 fas fa-recycle"></i>
                                Active
                              </label>
                            </div>
                          </Col>
                          <Col className="col-md-auto">
                            <div className='allowassets noclick d-flex flex-wrap'>

                              <div className='radiobx'>
                                <Input type="radio" name="radiohi" id="radioh11"></Input>
                                <label htmlFor="radioh11">
                                  {
                                    dynamicData.get("licensetype") ? dynamicData.get("licensetype").get('name') : null
                                  }
                                </label>
                              </div>

                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col className="col-md-5">
                            <div className="imgbx">

                              <AssetImages
                                objid={dynamicData.id}
                                // imageurl={dynamicData.awsImageUrl}
                                imageurl={this.getAssetUrl(dynamicData.get('assetimage'))}

                              />

                              {/* <Assetimage
                                objid={dynamicData.id}
                                imageurl={dynamicData.get('assetimage') ? dynamicData.get('assetimage')[0] : ""}
                                // updateIDforImage={this.state.updateIDforImage}
                                popupstatus="view"
                              /> */}

                            </div>
                          </Col>
                          <Col className="col-md-7">
                            <div className='textbx'>
                              <h3 className="mb-4">{dynamicData.get('title')}</h3>

                              <FormGroup className="form-group d-flex align-items-center">
                                <Label>Library</Label>
                                <p>
                                  {
                                    dynamicData.get('medialicenselibraryid') ?
                                      dynamicData.get('medialicenselibraryid').get('name')
                                      :
                                      ""
                                  }

                                  {
                                    dynamicData.get('medialicenselibraryid') && dynamicData.get('medialicenselibraryid').get('name') === "Other" ?
                                      " (" + dynamicData.get('otherlibraryname') + ")"
                                      :
                                      ""
                                  }
                                </p>
                              </FormGroup>
                              <FormGroup className="form-group d-flex align-items-center">
                                <Label>Assets ID</Label>
                                <p><a href={dynamicData.get('asseturl')} target="_blank">{dynamicData.get('assetid')}</a></p>
                              </FormGroup>
                              <FormGroup className="form-group d-flex align-items-center">
                                <Label>Title</Label>
                                <p>{dynamicData.get('title')}</p>
                              </FormGroup>
                              <FormGroup className="form-group d-flex align-items-center">
                                <Label>Type</Label>
                                <p>
                                  {
                                    dynamicData.get('assettypeid') ?
                                      dynamicData.get('assettypeid').get('name')
                                      :
                                      ""
                                  }
                                </p>
                              </FormGroup>

                              {
                                expiring !== "" ?
                                  <FormGroup className="form-group d-flex align-items-center">
                                    <Label>Expiry</Label>
                                    {
                                      expiring > 0 ?
                                        <p>{expiring} days</p>
                                        :
                                        <p>Expired</p>
                                    }

                                  </FormGroup>
                                  :
                                  null
                              }


                              <FormGroup className="form-group d-flex align-items-center">
                                <Label>Known Restrictions</Label>
                                <div className='allowassets noclick d-flex flex-wrap'>
                                  {
                                    dynamicData.get('georestriction') ?
                                      dynamicData.get('georestriction').map((geoData, key) => {
                                        return (
                                          <div className='checkbox' key={key}>
                                            <Input type="checkbox" id={`viewgeo${key}`} disabled></Input>
                                            <label htmlFor={`viewgeo${key}`}>{geoData.get('name')}</label>
                                          </div>
                                        )
                                      }
                                      )
                                      :
                                      null
                                  }
                                </div>
                              </FormGroup>

                              <FormGroup className="form-group d-flex align-items-center">
                                <Label>Agency</Label>
                                <p>
                                  {
                                    dynamicData.get('agencyid') ?
                                      dynamicData.get('agencyid').get('name')
                                      :
                                      ""
                                  }
                                </p>
                              </FormGroup>


                              <FormGroup className="form-group d-flex align-items-center">
                                <Label>Tags</Label>
                                <div className='allowassets tagscheck noclick d-flex flex-wrap'>
                                  {
                                    dynamicData.get('tags') ?
                                      dynamicData.get('tags').map((tags, key) => {
                                        return (
                                          <div className='checkbox' key={key}>
                                            <Input type="checkbox" id={`viewgeo${key}`} disabled></Input>
                                            <label htmlFor={`viewgeo${key}`}>{tags.get('name')}</label>
                                          </div>
                                        )
                                      }
                                      )
                                      :
                                      null
                                  }
                                </div>
                              </FormGroup>



                            </div>
                          </Col>
                        </Row>


                        <div className="extrainfosec mt-2">
                          <Nav tabs>
                            <NavItem>
                              <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggleTabs('1'); }}
                              >
                                Description
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggleTabs('2'); }}
                              >
                                Extra Info
                              </NavLink>
                            </NavItem>
                          </Nav>
                          <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                              <div className="tabcontent p-3">
                                <Row>
                                  <Col sm="12">
                                    <ScrollArea smoothScrolling={0.8} style={{ maxHeight: '195px' }}>
                                      <p>{dynamicData.get('description')}</p>
                                    </ScrollArea>
                                  </Col>
                                </Row>
                              </div>
                            </TabPane>
                            <TabPane tabId="2">
                              <div className="tabcontent p-3">
                                <Row className="justify-content-between">
                                  <Col className="col-md-6">
                                    <FormGroup className="form-group d-flex align-items-center">
                                      <Label>Purchased on</Label>
                                      <p>
                                        {
                                          dynamicData.get('purchasedon') ?
                                            Moment(dynamicData.get('purchasedon')).format('YYYY-MM-DD')
                                            :
                                            ""
                                        }
                                      </p>
                                    </FormGroup>
                                    <FormGroup className="form-group d-flex align-items-center">
                                      <Label>Contributor</Label>
                                      <p>{dynamicData.get('contributer')}</p>
                                    </FormGroup>
                                  </Col>
                                  <Col className="col-md-6">
                                    <FormGroup className="form-group d-flex align-items-center">
                                      <Label>Cost (USD)</Label>
                                      <p>{dynamicData.get('cost')} USD</p>
                                    </FormGroup>
                                    <FormGroup className="form-group d-flex align-items-center">
                                      <Label>Account Name</Label>
                                      <p>{dynamicData.get('accountname')}</p>
                                    </FormGroup>
                                  </Col>
                                </Row>

                                <div className="extrainfobx">
                                  <FormGroup className="form-group d-flex align-items-center mb-0">
                                    <Label>ALJ brand purchased for</Label>
                                    <div className='allowassets d-flex flex-wrap'>
                                      {
                                        dynamicData.get('aljbrand') ?
                                          dynamicData.get('aljbrand').map((brandData, key) => {
                                            return (
                                              <div className='checkbox' key={key}>
                                                <Input type="checkbox" id={`viewbrand${key}`} disabled></Input>
                                                <label htmlFor={`viewbrand${key}`}>{brandData.get('name')}</label>
                                              </div>
                                            )
                                          }
                                          )
                                          :
                                          null
                                      }

                                    </div>
                                  </FormGroup>
                                </div>

                              </div>
                            </TabPane>
                          </TabContent>
                        </div>

                      </div>
                    </ModalBody>
                  </div>
                )

              })
              :
              null
          }




        </Slider>

        <div id="detelepopup">
          <div className="modal-header">
            {/* <h5 className="modal-title">Adobe Stock</h5> */}
            <Row className="mt-3 justify-content-between">
              <Col className="d-flex">
                <Input type="text" name="deletefield" id='deletefield' placeholder="Type 'delete' to confirm" />
                <Button className="btn btn-danger ml-3" onClick={this.deleteCurrentRecord}><i className="fas fa-trash-alt"></i> Delete</Button>
                <Button className="btn btn-default ml-3" onClick={closeDeleteRecord}><i className="fas fa-times"></i> Cancel</Button>
              </Col>
            </Row>
          </div>
        </div>

      </Modal>
    )
  }
}

export default Viewassets;