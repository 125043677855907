import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormFeedback,
  CustomInput,
  Tooltip,
  UncontrolledTooltip
} from 'reactstrap';
import Select, { components } from "react-select";
import jQuery from 'jquery';
import FileUpload from '../../components/common/FileUpload';


import { getCurrentUser, authenticateLoggedIn, addHeaderTitle, isSuperAdmin, authenticateLoginpage, addBreadcrumb } from '../../services/parse';
import { uploadFile, getFileUrl } from '../../services/awsFileUpload';
import { addRecord, checkFieldValid, getCountries } from '../../services/jurisdiction';

var Parse = require('parse');


class addJurisdiction extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      collapse: true,
      fadeIn: true,
      timeout: 300,
      isSuper: '',
      addedclass: 'd-none',
      logoFileArray: [],
      isEdit: false,
      logofile: null,
      rewardsfile: null,
      loginlogofile: null,
    };
  }


  async componentDidMount(evt) {
    //auth start
    authenticateLoggedIn();
    addHeaderTitle('Add Jurisdiction');
    addBreadcrumb('Home / Jurisdictions / Add Jurisdiction');

  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }


  onLogoFileSelected = async (event) => {
    console.log(event.target.files);
    var files = event.target.files
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        var fileType = files[i].type.split("/")[1];
        var fileName = files[i].name;
        console.log(fileType);
        var uploadStatus = await uploadFile('contentmaster', files[i]);
        console.log(uploadStatus);
        if (uploadStatus.success) {

          this.setState({
            logoFileArray: [
              ...this.state.logoFileArray,
              { awsFileName: uploadStatus.filename, type: fileType, name: fileName }
            ]
          });
        }
      }
    }
    //var logoUpload = await uploadFile('trademark',event.target.value);
  }


  addRecord = () => {
    var valid = checkFieldValid('add');
    if (valid !== false) {
      jQuery('#addbutton').prop('disabled', true);

      var name = jQuery('#bname').val();
      var description = jQuery('#bdescription').val();
      var status = jQuery('#status').val();

      addRecord({ name: name, description: description, status: status, jurisImages: this.state.logoFileArray }).then((value) => {
        if (value !== false) {
          this.setState({ addedclass: 'd-block' });

          setTimeout(
            function () {
              this.setState({ addedclass: 'd-none' });
              window.location.assign('#/jurisdiction/editjurisdiction/' + value.id);
            }
              .bind(this),
            1000
          );

        } else {
          this.setState({ addedclass: 'd-none' });
        }

        jQuery('#addform').trigger("reset");
        jQuery('#addbutton').prop('disabled', false);
      });

    }
  }





  render() {
    return <div>

      <Row className="justify-content-between topbuttonbar">
        <Col className="col-md-5"><Alert color="success" className={this.state.addedclass}>
          Record Added
                  <Link to="/jurisdiction/jurisdiction" className="float-right">
            &nbsp; Go to list
                  </Link>
        </Alert>
        </Col>
        <Col className="col-auto mb-4">
          <Link to="/jurisdiction/jurisdiction" className="btn btn-primary btncancle mr-3">
            <span className="border-left pl-2">Cancel</span>
          </Link>
          <Button type="button" color="primary" id="addbutton" onClick={this.addRecord}>
            <span className="border-left pl-2"></span>
            Save
          </Button>
        </Col>
      </Row>

      <Row>

        <Col md="5">


          <Card>
            <CardTitle>
              <i className="icon-circle icon-pencil"></i>
              Add Jurisdiction
            </CardTitle>

            <CardBody>





              <Form id="addform">

                <FormGroup className="form-group">
                  <Label htmlFor="name">Name </Label>
                  <Input type="text" name="bname" id="bname" />
                </FormGroup>

                <FormGroup className="form-group">
                  <Label htmlFor="exampleEmail12">Status</Label>
                  <select id="status" className="custom-select">
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                  </select>
                </FormGroup>

                <FormGroup className="form-group">
                  <Label htmlFor="description">Description</Label>
                  <Input type="textarea" name="bdescription" id="bdescription" rows="5" placeholder="Add your comments here..." />
                </FormGroup>



                {/* Images area */}


                <FileUpload labelText="Upload Files" tooltipText="Hello world!" folderName="contentmaster"
                  isValid={true} inputText="Upload your file here" items={this.state.logoFileArray}
                  name="inputGroupFile01"
                  onDelete={(id) => {
                    var logoArray = this.state.logoFileArray.filter(obj => obj.id != id).map((item, index) => { return { ...item, id: index } });
                    this.setState({ logoFileArray: logoArray }, () => {
                    });
                  }}
                  addItems={(items) => {
                    console.log('addItem', items);
                    this.setState({
                      logoFileArray: [...this.state.logoFileArray, ...items]
                    }, () => {
                      console.log(this.state.logoFileArray);
                    });
                  }}
                  updateItem={(id, filename) => {
                    var objToChange = this.state.logoFileArray.find(x => x.id == id);
                    if (objToChange) {
                      this.setState({
                        logoFileArray: [...this.state.logoFileArray.filter(x => x.id != id),
                        { ...this.state.logoFileArray.find(x => x.id == id), awsFileName: filename }
                        ]

                      }, () => { console.log(this.state.logoFileArray); });
                    }
                  }}
                  showError={(message) => {
                    this.setState({ showAlertMessage: true, alertMessage: message })
                  }}
                />












                {/* <InputGroup>
                  <div className="custom-file">
                    <Input type="file" className="custom-file-input" id="inputGroupFile01" onChange={(event) => { this.onLogoFileSelected(event) }} multiple />
                    <label className="custom-file-label" htmlFor="inputGroupFile01">Upload your file here</label>
                  </div>
                </InputGroup> */}



              </Form>
            </CardBody>
          </Card>

        </Col>
      </Row>

    </div>
  }
}

export default addJurisdiction;
