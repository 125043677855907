import React from 'react';
import { Link } from 'react-router-dom';
import ScrollArea from 'react-scrollbar';
import { Row, Col, Carousel, CarouselItem, CarouselControl, Button, Table, Input, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, ModalFooter, Label, Alert, UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem, TabContent, TabPane, CardBody, Nav, NavItem, NavLink, Card, CardTitle, CardText } from 'reactstrap';
import Select, { components } from "react-select";
import CreatableSelect from 'react-select/creatable';
import Datetime from "react-datetime";
import DatePicker from "react-datepicker";
import 'react-datetime/css/react-datetime.css';
import jQuery from 'jquery';
import classnames from 'classnames';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import imageDummy from '../../../assets/images/dummy.jpg';
import iconLink from '../../../assets/images/icons/icon-link.png';
import iconEdit from '../../../assets/images/icons/icon-edit.png';
import iconDelete from '../../../assets/images/icons/icon-delete.png';
import noImage from '../../../assets/images/upload-paste.jpg';
import iconCloseWhite from '../../../assets/images/icons/close-white.png';

import { uploadFile, uploadFileToAWSCustom, getFileUrl } from '../../../services/awsFileUpload';
import { getMediaLicenseLibrarybyName, getAssetTypebyName } from '../../../services/medialicenseassetsimport';
import { getLicenseType, getAssetType, getMediaLicenseLibrary, getGeoRestriction, getAgency, getBrand, addRecord, checkFieldValid } from '../../../services/medialicenseassets';

import { LOGO_UPLOAD_SIZE } from '../../../config/constant';

function showImageLink() {
  document.getElementById("linkinput").classList.toggle("active");
}
function clearEditImage() {
  document.getElementById('imagelink').value = "";
}


const tagOptions = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }
]



class Addasset extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loader: false,
      activeTab: '1',
      // licenseItems: [],
      // medialicenselibrary: [],
      // assettypes: [],
      // georestrictions: [],
      // agencylist: [],
      // brandlist: [],

      imageError: "",
      tempImageView: null,
      imageFileArray: [],
      mainImagefile: [],

      recordstatus: "active",
      allowedlicense: "",
      assetimage: [],
      libraryid: "",
      asseturl: "",
      assetid: "",
      title: "",
      typeid: "",
      prepetuity: false,
      expiry: null,
      knownrestrictions: [],
      aganecyid: "",
      tags: [],
      description: "",
      purchasedon: null,
      contributer: "",
      cost: null,
      accountname: "",
      brandpurchased: [],
    };

  }

  async componentDidMount(evt) {


  }

  togglePopupClear = async (event) => {
    this.setState({
      loader: false,
      activeTab: '1',

      imageError: "",
      tempImageView: null,
      imageFileArray: [],
      mainImagefile: [],

      recordstatus: "active",
      allowedlicense: "",
      assetimage: [],
      libraryid: "",
      libraryidvalue: "",
      asseturl: "",
      assetid: "",
      title: "",
      typeid: "",
      prepetuity: false,
      expiry: null,
      knownrestrictions: [],
      aganecyid: "",
      description: "",
      purchasedon: null,
      contributer: "",
      cost: null,
      accountname: "",
      brandpurchased: [],
    })

    this.props.toggle();
  }



  removeHttp = (url) => {
    return url.replace(/^https?:\/\//, '');
  }
  fetchFromURL = async (e) => {
    var url = this.state.asseturl;
    url = this.removeHttp(url);
    var splitUrl = url.toString().split('/');

    var assetidfromurl = "";
    var assettitlefromurl = "";
    var assettypetext = "";
    var libraryname = "";

    if (splitUrl[0].includes("shutterstock.com")) {
      libraryname = await getMediaLicenseLibrarybyName("Shutterstock");

      if (splitUrl[1] === "image-photo") {
        assettypetext = await getAssetTypebyName("Image");
      } else if (splitUrl[1] === "image-vector" || splitUrl[1] === "image-illustration") {
        assettypetext = await getAssetTypebyName("Vector");
      } else if (splitUrl[1] === "video") {
        assettypetext = await getAssetTypebyName("Video");
      } else if (splitUrl[1] === "music") {
        assettypetext = await getAssetTypebyName("Audio");
      }

      if (splitUrl[1] === "image-photo" || splitUrl[1] === "image-vector" || splitUrl[1] === "image-illustration") {
        assetidfromurl = splitUrl[2].split("-").pop();
        assettitlefromurl = splitUrl[2].replace('-' + assetidfromurl, '');
      } else if (splitUrl[1] === "video" || splitUrl[1] === "music") {
        var idsplit = splitUrl[2].split("-");
        assetidfromurl = idsplit[1];
        var titlesplit = splitUrl[2].split(idsplit[1] + "-");
        assettitlefromurl = titlesplit[1];
      }

    }

    if (splitUrl[0].includes("stock.adobe.com")) {

      if (splitUrl.length === 4) {
        libraryname = await getMediaLicenseLibrarybyName("Adobestock");
        assetidfromurl = splitUrl[3];
        assettitlefromurl = splitUrl[2];

        if (splitUrl[1] === "images") {
          assettypetext = await getAssetTypebyName("Image");
        } else if (splitUrl[1] === "video") {
          assettypetext = await getAssetTypebyName("Video");
        }
      } else if (splitUrl.length === 5) {
        libraryname = await getMediaLicenseLibrarybyName("Adobestock");
        assetidfromurl = splitUrl[4];
        assettitlefromurl = splitUrl[3];

        if (splitUrl[2] === "images") {
          assettypetext = await getAssetTypebyName("Image");
        } else if (splitUrl[2] === "video") {
          assettypetext = await getAssetTypebyName("Video");
        }
      }



    }

    if (splitUrl[0].includes("gettyimages")) {
      libraryname = await getMediaLicenseLibrarybyName("GettyImages");
      assetidfromurl = splitUrl[4];
      assettitlefromurl = splitUrl[3];

      if (splitUrl[2] === "photo") {
        assettypetext = await getAssetTypebyName("Image");
      } else if (splitUrl[2] === "video") {
        assettypetext = await getAssetTypebyName("Video");
      } else if (splitUrl[2] === "music") {
        assettypetext = await getAssetTypebyName("Video");
      }
    }

    if (splitUrl[0].includes("istockphoto.com")) {
      libraryname = await getMediaLicenseLibrarybyName("iStock");

      if (splitUrl[1] === "photo") {
        assettypetext = await getAssetTypebyName("Image");
      } else if (splitUrl[1] === "vector") {
        assettypetext = await getAssetTypebyName("Vector");
      } else if (splitUrl[1] === "video") {
        assettypetext = await getAssetTypebyName("Video");
      }

      assetidfromurl = splitUrl[2].split('-').slice(-2, -1)[0].replace(/\D/g,'');
      assettitlefromurl = splitUrl[2].replace('-' + assetidfromurl, '');

    }

    if (splitUrl[0].includes("123rf.com")) {
      libraryname = await getMediaLicenseLibrarybyName("123rf");

      var split123 = splitUrl[1].toString().split('_');

      if (split123[0] === "photo" || split123[0] === "free-photo") {
        assettypetext = await getAssetTypebyName("Image");
      } else if (split123[0]  === "free-vector") {
        assettypetext = await getAssetTypebyName("Vector");
      } else if (split123[0]  === "footage") {
        assettypetext = await getAssetTypebyName("Video");
      } else if (split123[0]  === "audio") {
        assettypetext = await getAssetTypebyName("Audio");
      }

      assetidfromurl = split123[1];
      assettitlefromurl = split123[2].split('.html')[0]; 
    }

    if (assettitlefromurl) {
      assettitlefromurl = assettitlefromurl.replace(/\-/g, ' ');
      assettitlefromurl = assettitlefromurl.charAt(0).toUpperCase() + assettitlefromurl.slice(1);
    }

    this.setState({
      libraryidvalue: libraryname && libraryname[0] ? libraryname[0].get('name') : "",
      libraryid: libraryname && libraryname[0] ? libraryname[0].id : "",
      assetid: assetidfromurl,
      title: assettitlefromurl,
      description: assettitlefromurl,
      typeid: assettypetext ? assettypetext[0].id : "",
    });


  }




  toggleTabs(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  uploadFileSelected = async (event) => {
    var files = event
    var itemCounts = files.length;
    console.log('itemCounts', itemCounts);
    var itemsToAdd = [];
    var itemsAdded = [];
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].size < LOGO_UPLOAD_SIZE) {
          var fileType = files[i].type.split("/")[1];
          var fileName = files[i].name;
          var fileSize = (files[i].size / 1000000).toFixed(2);

          var obj = { awsFileName: null, type: fileType, name: fileName, id: itemCounts, size: fileSize }
          itemsToAdd.push(obj);
          var uploadeditem = await uploadFileToAWSCustom(files[i], obj, "contentmaster");
          itemsAdded.push(uploadeditem);
          itemCounts++;
        }
        else {
          console.log('File ' + files[i].name + ' exceeds maximum file size of 30MB')
        }
      }
      if (itemsAdded.length > 0) {
        this.setState({
          imageFileArray: itemsAdded
        });
      }

      return itemsAdded;
    }
  }

  fileChangedHandler = (event) => {
    this.setUpImage(event.target)
  }
  handlePaste = (e) => {
    if (e.clipboardData.files.length) {
      this.setUpImage(e.clipboardData)
    }
  };


  setUpImage = (e) => {
    if (e.files.length) {
      const file = e.files[0];
      if (file) {
        const fileSize = file.size;
        if ((file.type === "image/png" || file.type === "image/gif" || file.type === "image/jpeg" || file.type === "image/webp") && fileSize <= 1000000) {
          this.setState({ mainImagefile: e.files, imageError: "" });
          this.readFileToImage(e)
        } else {
          this.setState({ imageError: "Please upload image less than 1 mb" });
        }
      }
    }
  };

  readFileToImage = (input) => {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        this.setState({ tempImageView: e.target.result });
      }.bind(this);
      reader.readAsDataURL(input.files[0]);
    }
  }



  restrictioncheck = async () => {
    var resdata = [];
    jQuery.each(jQuery("input[name='restrictioncheck']:checked"), function () {
      resdata.push(jQuery(this).val());
    });
    this.setState({ knownrestrictions: resdata });
  }
  brandlistcheck = async () => {
    var bdata = [];
    jQuery.each(jQuery("input[name='brandlistcheck']:checked"), function () {
      bdata.push(jQuery(this).val());
    });
    this.setState({ brandpurchased: bdata });
  }














  addRecords = async () => {



    var valid = checkFieldValid('add', this.state);
    if (valid !== false) {
      this.setState({ loader: true });

      jQuery('#savebutton').prop('disabled', true);

      let images = [];

      if (this.state.mainImagefile.length > 0) {
        images = await this.uploadFileSelected(this.state.mainImagefile)
      }

      console.log(this.state.imageFileArray,images, "imageFileArray")

      var fielddatas = {
        recordstatus: this.state.recordstatus,
        // assetimage: this.state.imageFileArray,
        assetimage: images,
        allowedlicense: this.state.allowedlicense,

        libraryid: this.state.libraryid,
        asseturl: this.state.asseturl,
        assetid: this.state.assetid,
        title: this.state.title,
        typeid: this.state.typeid,
        prepetuity: this.state.prepetuity,
        expiry: this.state.expiry,
        knownrestrictions: this.state.knownrestrictions,
        aganecyid: this.state.aganecyid,
        tags: this.state.tags,
        description: this.state.description,
        purchasedon: this.state.purchasedon,
        contributer: this.state.contributer,
        cost: this.state.cost,
        accountname: this.state.accountname,
        brandpurchased: this.state.brandpurchased,
      };

      addRecord(fielddatas).then((value) => {


        console.log(value);

        if (value !== false) {
          this.setState({ addedclass: 'd-block' });

          setTimeout(
            function () {
              this.setState({ addedclass: 'd-none' });
            }
              .bind(this),
            1000
          );

          this.props.addStatus(value);

        } else {
          this.setState({ addedclass: 'd-none' });
        }

        this.setState({ loader: false });
        jQuery('#savebutton').prop('disabled', false);
      });

    }
  }


  render() {
    var date25 = new Date();
    date25.setFullYear(date25.getFullYear() + 50);

    return (
      <Modal isOpen={this.props.isOpen} toggle={this.togglePopupClear} className='mediamodel addnewpopup'>
        <div className="modal-header">

          {
            this.state.loader === true ?
              <div className="loaderwrap"><div className="loader"></div></div>
              :
              null
          }


          <h5 className="modal-title">Add New Asset</h5>
          <a className="closebtn" onClick={this.togglePopupClear}>x</a>
          <Row className="mt-3 justify-content-between">
            <Col>
              <Button className="btn btn-default addbuttonwhite" id="savebutton" onClick={this.addRecords}>Save</Button>
              <Button className="btn btn-primary ml-3" onClick={this.togglePopupClear}>Discard</Button>
            </Col>
          </Row>
        </div>
        <ModalBody>
          <div className="addlibrary addassets">

            <Row className="mb-4 justify-content-between">
              <Col>

                <div className="activecheck float-left">
                  <Input id="status" type="checkbox" defaultChecked="true" className="form-check-input form-check-input"
                    onClick={
                      async (event) => {
                        if (event.target.checked === true) {
                          this.setState({ recordstatus: "active" });
                        } else {
                          this.setState({ recordstatus: "inactive" });
                        }
                      }
                    }
                  />
                  <label htmlFor="status" className="btn">
                    <i className="mr-1 fas fa-recycle"></i>
                    Active
                  </label>
                </div>

              </Col>
              <Col className="col-md-auto">
                <div className='allowassets licenseitems d-flex flex-wrap'>

                  {
                    this.props.licenseItems && this.props.licenseItems.map((dynamicData, key) =>
                      <div className='radiobx'>
                        <Input type="radio" name="licensetype" value={dynamicData.id} id={`lice${key}`}
                          onChange={
                            async (event) => {
                              this.setState({ allowedlicense: event.target.value });
                            }
                          }
                        ></Input>
                        <label htmlFor={`lice${key}`}>{dynamicData.get('name')}</label>
                      </div>
                    )
                  }

                </div>
              </Col>
            </Row>

            <Row className="justify-content-between">



              <Col className="col-md-5">
                {
                  this.state.imageError !== "" ?
                    <span className="error fixedtop">{this.state.imageError}</span>
                    :
                    null
                }

                <div className="imgbx libraryimgwrap">

                  {/* <div className="clipboard" onPaste={this.handlePaste}></div> */}
                  <div className="customupload" onPaste={this.handlePaste}>
                    <div className="dragndrop">
                      Choose or Drag and Drop image
                      <Input type="file" name="libraryimage" id='libraryimage' accept="image/png, image/gif, image/jpeg, image/webp" placeholder='upload your file' onChange={this.fileChangedHandler} />
                    </div>
                    Paste image ctrl + V
                  </div>

                  {
                    this.state.tempImageView ?
                      <img src={this.state.tempImageView} alt='img' />
                      :
                      null
                  }

                  <div className='optionbx'>
                    {/* <a href='#' id='openlinkbx' className='link' onClick={showImageLink}>
                      <img src={iconLink} alt='img' />
                    </a> */}


                    {/* <label className='edit' htmlFor="libraryimage">
                      <img src={iconEdit} alt='img' />
                    </label> */}
                    <a href='javascript:' className='delete'
                      onClick={
                        async (event) => {
                          this.setState({ tempImageView: null, mainImagefile: [], imageFileArray: [] });
                          jQuery("#libraryimage").val(null);
                        }
                      }>
                      <img src={iconDelete} alt='img' className='delete_icon' />
                    </a>
                    {/* <div id='linkinput' className='linkinput'>
                      <Input type="text" name="imageid" id="imagelink" placeholder='Enter image Id' />
                      <button className="submitbx" onClick={clearEditImage}><img src={iconCloseWhite} alt='img' /></button>
                    </div> */}


                    {/* <div id='linkupload' className='linkupload d-none'>
                      <Input type="file" name="libraryimage" id='libraryimage' accept="image/png, image/gif, image/jpeg, image/webp" placeholder='upload your file' onChange={this.fileChangedHandler} />
                    </div> */}


                  </div>
                </div>


              </Col>






              <Col className="col-md-6">
                <div className='textbx'>

                  <FormGroup  className="form-group position-relative d-flex align-items-center">
                    <Label>Assets URL</Label>
                    <Input type="text" name="asseturl"
                      onChange={
                        async (event) => {
                          this.setState({ asseturl: event.target.value });
                        }
                      }
                    />

                    <Button className="btn btn-default fetchbtn" onClick={this.fetchFromURL} >
                      Fetch <i className="mdi mdi-download"></i>
                    </Button>

                  </FormGroup>

                  <FormGroup className="form-group position-relative d-flex align-items-center">
                    <Label>Library *</Label>
                    <InputGroup>
                    {/* <InputGroupAddon addonType="prepend">
                        <InputGroupText><i className="fas fa-book"></i></InputGroupText>
                      </InputGroupAddon> */}
                      {/* <InputGroupAddon addonType="prepend"> */}
                        <InputGroupText className='prepend dark'><i className="fas fa-book"></i></InputGroupText>
                      {/* </InputGroupAddon> */}
                      <Select
                        value={
                          this.state.libraryidvalue ?
                            [{ value: this.state.libraryid, label: this.state.libraryidvalue }]
                            :
                            ""
                        }
                        id="libraryid"
                        className="libraryidselect"
                        isClearable
                        isSearchable
                        name="color"
                        options={this.props.medialicenselibrary ? this.props.medialicenselibrary.filter(x => x.label != "Other") : null}
                        onChange={
                          async (selectedOption) => {
                            this.setState({
                              libraryid: selectedOption ? selectedOption.value : "",
                              libraryidvalue: selectedOption ? selectedOption.label : "",
                            });
                          }
                        }
                      />
                    </InputGroup>
                  </FormGroup>

                  <FormGroup className="form-group position-relative d-flex align-items-center">
                    <Label>Assets ID</Label>
                    <Input type="text" name="assetid"
                      value={this.state.assetid ? this.state.assetid : ""}
                      onChange={
                        async (event) => {
                          this.setState({ assetid: event.target.value });
                        }
                      }
                    />
                  </FormGroup>

                  <FormGroup className="form-group position-relative d-flex align-items-center">
                    <Label>Title</Label>
                    <Input type="text" name="title" id="title"
                      value={this.state.title ? this.state.title : ""}
                      onChange={
                        async (event) => {
                          this.setState({ title: event.target.value });
                        }
                      }
                    />
                  </FormGroup>

                  <FormGroup  className="form-group position-relative d-flex align-items-center">
                    <Label>Type</Label>
                    <InputGroup>
                      {/* <InputGroupAddon addonType="prepend"> */}
                        <InputGroupText className='prepend dark'><i className="fas fa-book"></i></InputGroupText>
                      {/* </InputGroupAddon> */}
                      <Input type="select" className="custom-select" id="assettype"
                        value={this.state.typeid ? this.state.typeid : ""}
                        onChange={(event) => {
                          this.setState({ typeid: event.target.value });
                        }}
                      >
                        <option value="">Select</option>
                        {
                          this.props.assettypes && this.props.assettypes.map((dynamicData, key) =>
                            <option value={dynamicData.id} key={key}>{dynamicData.get('name')}</option>
                          )
                        }
                      </Input>
                    </InputGroup>
                  </FormGroup>

                  <FormGroup  className="form-group position-relative d-flex align-items-center">
                    <Label>In perpetuity</Label>
                    <div className="checkslide">
                      <Input name="tdType" id="prepetuity1" type="checkbox" className="form-check-input" value="prepetuity"
                        onClick={
                          async (event) => {
                            this.setState({ prepetuity: event.target.checked });
                            if (event.target.checked === true) {
                              jQuery('.expirydatewrap').addClass('d-none');
                              this.setState({ expiry: null });
                            } else {
                              jQuery('.expirydatewrap').removeClass('d-none');
                            }
                          }
                        }
                      />
                      <label htmlFor="prepetuity1" className="">prepetuity</label>
                    </div>
                  </FormGroup>

                  <div className='expirydatewrap'>
                    <FormGroup className="form-group position-relative d-flex align-items-center">
                      <Label>Expiry</Label>
                      <InputGroup>
                        {/* <InputGroupAddon addonType="prepend"> */}
                          <InputGroupText className='prepend dark'><i className="fas fa-calendar-alt"></i></InputGroupText>
                        {/* </InputGroupAddon> */}



                        <DatePicker
                          onClickOutside={this.datePickerValue}
                          maxDate={date25}
                          dateFormat="dd-MM-yyyy"
                          id="expiry_date"
                          yearDropdownItemNumber={100}
                          scrollableYearDropdown={true}
                          showYearDropdown
                          showMonthDropdown
                          placeholderText="DD-MM-YYYY"
                          onChange={(selectedOption) => {
                            this.setState({ expiry: selectedOption });
                          }}
                          selected={this.state.expiry}
                        />


                        {/* <Datetime
                          locale="en-gb"
                          timeFormat={false}
                          dateFormat="DD/MM/YYYY"
                          inputProps={{ placeholder: "DD/MM/YYYY", id: "expiry_date", autoComplete: "off" }}
                          onChange={(selectedOption) => {
                            this.setState({ expiry: selectedOption._isValid === true ? selectedOption.toDate() : null });
                          }}
                          value={this.state.expiry}
                        /> */}
                      </InputGroup>
                    </FormGroup>
                  </div>


                  <FormGroup className="form-group position-relative d-flex align-items-center">
                    <Label>Known Restrictions</Label>
                    <div className='allowassets d-flex flex-wrap'>

                      {
                        this.props.georestrictions && this.props.georestrictions.map((dynamicData, key) =>
                          <div className='checkbox'>
                            <Input type="checkbox" name="restrictioncheck" id={`restrict${key}`} value={dynamicData.id}
                              onChange={this.restrictioncheck}
                            ></Input>
                            <label htmlFor={`restrict${key}`}>{dynamicData.get('name')}</label>
                          </div>
                        )
                      }

                    </div>
                  </FormGroup>
                  <FormGroup className="form-group position-relative d-flex align-items-center">
                    <Label>Agency</Label>
                    <InputGroup>
                      {/* <InputGroupAddon addonType="prepend"> */}
                        <InputGroupText className='prepend dark'><i className="fas fa-book"></i></InputGroupText>
                      {/* </InputGroupAddon> */}
                      <Select
                        // defaultValue={this.props.agencylist ? this.props.agencylist[0] : ""}
                        isClearable
                        isSearchable
                        name="color"
                        options={this.props.agencylist ? this.props.agencylist : null}
                        onChange={
                          async (selectedOption) => {
                            this.setState({ aganecyid: selectedOption ? selectedOption.value : "" });
                          }
                        }
                      />
                    </InputGroup>
                  </FormGroup>



                  <FormGroup  className="form-group position-relative d-flex align-items-center">
                    <Label>Tags</Label>
                    <InputGroup>
                      {/* <InputGroupAddon addonType="prepend"> */}
                        <InputGroupText className='prepend dark'><i className="fas fa-book"></i></InputGroupText>
                      {/* </InputGroupAddon> */}
                      <CreatableSelect
                        isMulti
                        options={this.props.taglist}
                        onChange={
                          async (selectedOption) => {
                            this.setState({ tags: selectedOption ? selectedOption : "" });
                          }
                        }
                      />
                    </InputGroup>
                  </FormGroup>





                </div>
              </Col>
            </Row>

            <div className="extrainfosec mt-2">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '1' })}
                    onClick={() => { this.toggleTabs('1'); }}
                  >
                    Description
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '2' })}
                    onClick={() => { this.toggleTabs('2'); }}
                  >
                    Extra Info
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <div className="tabcontent">
                    <Row>
                      <Col sm="12">
                        <Input type="textarea" placeholder="Enter some text" rows="3"
                          value={this.state.description ? this.state.description : ""}
                          onChange={
                            async (event) => {
                              this.setState({ description: event.target.value });
                            }
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="tabcontent p-3">
                    <Row className="justify-content-between">
                      <Col className="col-md-6">
                        <FormGroup  className="form-group d-flex align-items-center">
                          <Label>Purchased on</Label>
                          <InputGroup>
                            {/* <InputGroupAddon addonType="prepend"> */}
                              <InputGroupText className='prepend dark'><i className="fas fa-calendar-alt"></i></InputGroupText>
                            {/* </InputGroupAddon> */}

                            <DatePicker
                              onClickOutside={this.datePickerValue}
                              maxDate={new Date()}
                              dateFormat="dd-MM-yyyy"
                              yearDropdownItemNumber={100}
                              scrollableYearDropdown={true}
                              showYearDropdown
                              showMonthDropdown
                              placeholderText="DD-MM-YYYY"
                              onChange={(selectedOption) => {
                                this.setState({ purchasedon: selectedOption });
                              }}
                              selected={this.state.purchasedon}
                            />


                            {/* <Datetime
                              locale="en-gb"
                              timeFormat={false}
                              dateFormat="DD/MM/YYYY"
                              inputProps={{ placeholder: "Purchased on" }}
                              onChange={(selectedOption) => {
                                this.setState({ purchasedon: selectedOption._isValid === true ? selectedOption.toDate() : null });
                              }}
                              value={this.state.purchasedon}
                            /> */}
                          </InputGroup>
                        </FormGroup>
                        <FormGroup  className="form-group d-flex align-items-center">
                          <Label>Contributor</Label>
                          <Input type="text" name="contributor"
                            onChange={
                              async (event) => {
                                this.setState({ contributer: event.target.value });
                              }
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col className="col-md-6">
                        <FormGroup className="form-group d-flex align-items-center">
                          <Label>Cost (USD)</Label>
                          <Input type="number" onWheel={ event => event.currentTarget.blur() } name="cost"
                            onChange={
                              async (event) => {
                                this.setState({ cost: event.target.value });
                              }
                            }
                          />
                        </FormGroup>
                        <FormGroup  className="form-group d-flex align-items-center">
                          <Label>Account Name</Label>
                          <Input type="text" name="accountname"
                            onChange={
                              async (event) => {
                                this.setState({ accountname: event.target.value });
                              }
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="extrainfobx">
                      <FormGroup className="form-group d-flex align-items-center mb-0">
                        <Label>ALJ brand purchased for</Label>
                        <div className='allowassets d-flex flex-wrap'>

                          {
                            this.props.brandlist && this.props.brandlist.map((dynamicData, key) =>
                              <div className='checkbox'>
                                <Input type="checkbox" name="brandlistcheck" id={`brandlist${key}`} value={dynamicData.id}
                                  onChange={this.brandlistcheck}
                                ></Input>
                                <label htmlFor={`brandlist${key}`}>{dynamicData.get('name')}</label>
                              </div>
                            )
                          }

                        </div>
                      </FormGroup>
                    </div>

                  </div>
                </TabPane>
              </TabContent>
            </div>

          </div>
        </ModalBody>
      </Modal>
    )
  }
}

export default Addasset;