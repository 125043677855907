import React from 'react';
import ScrollArea from 'react-scrollbar';
import {
  Card, CardHeader, CardBody, Alert, Table, Row, Col, Input, Form, FormGroup, CardTitle, InputGroup, InputGroupAddon, Button,
  Modal, ModalHeader, ModalBody, ModalFooter, ListGroup, ListGroupItem, Pagination, PaginationItem, PaginationLink, Label,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Select, { components } from "react-select";
import Datetime from "react-datetime";
import DatePicker from "react-datepicker";
import 'react-datetime/css/react-datetime.css';
import jQuery from 'jquery';
import Loader from '../../components/common/Loader';

import { authenticateLoggedIn, addHeaderTitle, addBreadcrumb, getCurrentUser } from '../../services/parse';
import {
  getFullPageCount, getSearchResults, getDomain, hideRecords, getDomainname, getCountries, getRegistrar, getWebcategory,
  getBusinessSectors, getALJCompanies, getMasterbrands, getCountriesByRegion, getHiddenColumns, saveHiddenColumns
} from '../../services/domain';


import imgview from '../../assets/images/icons/view-white.png';
import imgnoview from '../../assets/images/icons/no-view-white.png';
import imgedit from '../../assets/images/icons/edit-white.png';
import { isAdmin } from '../../services/user';

class domain extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      textClass: '',
      isSuper: '',
      datafull: '',
      totalpagecount: '',
      data: [],
      deleteIds: [],
      domainnamedata: [],
      geographyData: [],
      registrarData: [],
      webcatData: [],
      businessData: [],
      companyData: [],
      masterData: [],
      deletedstat: 'd-none',
      loaderstat: true,
      regionCountries: [],
      regiontitle: "",
      searchcount: "",
      hiddenColumnArray: [],
      editmode:false,
    };
    this.toggle = this.toggle.bind(this);
    this.toggle5 = this.toggle5.bind(this);

  }


  async componentDidMount(evt) {
    //auth start
    authenticateLoggedIn();
    addHeaderTitle('Domains');
    addBreadcrumb('Home / Domains');

    var isadminval = await isAdmin();
    if (isadminval) {
      var currentrole = isadminval[0].get('role').get('name');
      console.log(currentrole);
      this.setState({ currentRole: currentrole });
      if ( currentrole === "Finance") {
        this.setState({ editmode: false});
      }
      else
      {
        this.setState({ editmode: true});
      }
    }
    //auth end
    var dataperpage = jQuery('#dataperpage').val();

    getFullPageCount().then((value) => {
      this.setState({ datafull: value, });

      var totpagecount = Math.ceil(value / dataperpage);
      this.setState({ totalpagecount: totpagecount, });

      jQuery('.pagination .page-item:first-child').nextAll().slice(3, -1).addClass('d-none');
    });

    var sortitemval = jQuery('#sortitemval').val();
    var sortitemorder = jQuery('#sortitemorder').val();
    getDomain(1, dataperpage, sortitemval, sortitemorder).then((value) => {
      this.setState({ data: value, });
      this.setState({ loaderstat: false, });
    });

    getDomainname().then((value) => {
      this.setState({ domainnamedata: value, });
    });
    getCountries().then((value) => {
      this.setState({ geographyData: value, });
    });
    getRegistrar().then((value) => {
      this.setState({ registrarData: value, });
    });

    var webvalue = await getWebcategory();
    this.setState({ webcatData: webvalue, });

    var businessvalue = await getBusinessSectors();
    this.setState({ businessData: businessvalue, });

    var compvalue = await getALJCompanies();
    this.setState({ companyData: compvalue, });

    var mastervalue = await getMasterbrands();
    this.setState({ masterData: mastervalue, });

    var hiddenColumnArray = await getHiddenColumns();
    if (hiddenColumnArray && hiddenColumnArray[0]) {
      this.setState({ hiddenColumnArray: hiddenColumnArray[0].get('hiddenColumns'), });
    }


  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  toggle5 = async (event) => {
    var regionid = event.currentTarget.dataset.regionid;
    var regiontext = event.currentTarget.dataset.regiontext;

    if (regionid) {
      var regcon = await getCountriesByRegion(regionid);
      this.setState({ regionCountries: regcon, });
      this.setState({ regiontitle: regiontext, });
    }

    this.setState({
      modal5: !this.state.modal5
    });
  }


  toggleColumns = () => {
    jQuery('#hidecheckgroup').slideToggle();
  }

  saveHiddenColumns = () => {
    jQuery('#addbutton').addClass('d-none');
    jQuery('#loadbutton').removeClass('d-none');

    var hiddenitems = [];
    jQuery.each(jQuery("input[name='hiddenitems']:checked"), function () {
      hiddenitems.push(jQuery(this).val());
    });

    var currentUser = getCurrentUser();
    var userObjectID = currentUser.id;

    var fielddatas = {
      hiddenitems: hiddenitems,
      userObjectID: userObjectID,
    }
    saveHiddenColumns(fielddatas).then((value) => {
      if (value !== false) {
        jQuery('#addbutton').removeClass('d-none');
        jQuery('#loadbutton').addClass('d-none');
      }
    });
  }

  createColumns = () => {
    let columnToHide = [];
    var thcount = 1;
    var _ = this;

    jQuery(".table-responsive .table thead tr th").each(function () {
      var thval = jQuery(this).html();

      if (jQuery.inArray(thcount.toString(), _.state.hiddenColumnArray) !== -1) {
        columnToHide.push(
          <div className="form-check form-check-inline">
            <Input type="checkbox" value={thcount} onClick={_.hideColumn} id={thval} name="hiddenitems" defaultChecked />
            <Label for={thval}>{thval}</Label>
          </div>
        );
        jQuery('.table-responsive .table thead tr th:nth-child(' + thcount + ')').hide();
        jQuery('.table-responsive .table tbody tr td:nth-child(' + thcount + ')').hide();
      } else {
        columnToHide.push(
          <div className="form-check form-check-inline">
            <Input type="checkbox" value={thcount} onClick={_.hideColumn} id={thval} name="hiddenitems" />
            <Label for={thval}>{thval}</Label>
          </div>
        );
      }

      thcount++;
    });
    return columnToHide
  }

  hideColumn = async (event) => {
    var checkval = event.target.value;
    var checkstat = event.target.checked;

    if (checkstat === true) {
      jQuery('.table-responsive .table thead tr th:nth-child(' + checkval + ')').hide();
      jQuery('.table-responsive .table tbody tr td:nth-child(' + checkval + ')').hide();
    } else {
      jQuery('.table-responsive .table thead tr th:nth-child(' + checkval + ')').show();
      jQuery('.table-responsive .table tbody tr td:nth-child(' + checkval + ')').show();
    }

  }



  setSortItem = (item) => {

    jQuery('.cursor-pointer').not(item.target).removeClass('ascending');
    jQuery('.cursor-pointer').not(item.target).removeClass('descending');
    jQuery('.cursor-pointer').not(item.target).removeAttr('data-sortorder');

    if (item.target.dataset.sortorder === "ascending") {
      item.target.dataset.sortorder = "descending";
      item.target.classList.add("descending");
      item.target.classList.remove("ascending");
    } else {
      item.target.dataset.sortorder = "ascending";
      item.target.classList.add("ascending");
      item.target.classList.remove("descending");
    }

    var sortval = item.target.dataset.sort;
    jQuery('#sortitemval').val(sortval);

    var sortitemorder = item.target.dataset.sortorder;
    jQuery('#sortitemorder').val(sortitemorder);

    this.gotoPage();
  }

  setpageNum = (event) => {
    jQuery('.table-responsive').animate({scrollTop:0,scrollLeft:0},500);
    jQuery('.tablepagination .pagination > li').removeClass('active');
    jQuery('.tablepagination .pagination > li').removeClass('d-none');
    jQuery(event.target).parent().addClass('active');

    jQuery('.paginatecounts').removeClass('active');
    jQuery(event.target).addClass('active');

    var pagenum = jQuery(event.target).data('pagenum');
    jQuery('#pagenum').val(pagenum);

    this.gotoPage();

    jQuery(event.target).parent().prevAll().slice(3, -1).addClass('d-none');
    jQuery(event.target).parent().nextAll().slice(3, -1).addClass('d-none');
  }

  gotoPage = () => {
    var dataperpage = jQuery('#dataperpage').val();
    var pagenum = jQuery('#pagenum').val();
    var sortitemval = jQuery('#sortitemval').val();
    var sortitemorder = jQuery('#sortitemorder').val();

    getDomain(pagenum, dataperpage, sortitemval, sortitemorder).then((value) => {
      this.setState({ data: value, });

      this.createColumns();
    });
  }
  showPerPage = () => {
    var dataperpage = jQuery('#dataperpage').val();
    var pagenum = jQuery('#pagenum').val();
    var sortitemval = jQuery('#sortitemval').val();
    var sortitemorder = jQuery('#sortitemorder').val();

    var totpagecount = Math.ceil(this.state.datafull / dataperpage);
    this.setState({
      totalpagecount: totpagecount
    }, () => {
      jQuery('.paginatecounts[data-pagenum = ' + pagenum + ']').trigger("click");
    });

    var pagenum = jQuery('#pagenum').val();
    if (pagenum > totpagecount) {
      jQuery('#pagenum').val(totpagecount);
      var pagenum = jQuery('#pagenum').val();

      jQuery('#paginatecounts').removeClass('active');
      jQuery('.paginatecounts[data-pagenum = ' + pagenum + ']').addClass('active');
      jQuery('.paginatecounts[data-pagenum = ' + pagenum + ']').parent().addClass('active');
    }

    getDomain(pagenum, dataperpage, sortitemval, sortitemorder).then((value) => {
      this.setState({ data: value, });
    });

  }

  hitEnter = (e) => {
    var keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      this.searchRecords();
    }
  }

  setSearchFields = () => {
    jQuery('#searchbox').val('');
    var setsrcfield = jQuery('#setsrcfield').val();
    if (setsrcfield == "domain") {

      jQuery('#registrarsrc').addClass('d-none');
      jQuery('#geographysrc').addClass('d-none');
      jQuery('#searchbox').addClass('d-none');
      jQuery('#statsrc').addClass('d-none');
      jQuery('#expiryouter').addClass('d-none');
      jQuery('#domainsrc').removeClass('d-none');
      jQuery('#createddatesrcouter').addClass('d-none');
      jQuery('#webcatsrc').addClass('d-none');
      jQuery('#businesssrc').addClass('d-none');
      jQuery('#entitysrc').addClass('d-none');
      jQuery('#mastersrc').addClass('d-none');

    } else if (setsrcfield == "geography") {

      jQuery('#registrarsrc').addClass('d-none');
      jQuery('#searchbox').addClass('d-none');
      jQuery('#statsrc').addClass('d-none');
      jQuery('#domainsrc').addClass('d-none');
      jQuery('#expiryouter').addClass('d-none');
      jQuery('#geographysrc').removeClass('d-none');
      jQuery('#createddatesrcouter').addClass('d-none');
      jQuery('#webcatsrc').addClass('d-none');
      jQuery('#businesssrc').addClass('d-none');
      jQuery('#entitysrc').addClass('d-none');
      jQuery('#mastersrc').addClass('d-none');

    } else if (setsrcfield == "registrar") {

      jQuery('#searchbox').addClass('d-none');
      jQuery('#statsrc').addClass('d-none');
      jQuery('#domainsrc').addClass('d-none');
      jQuery('#geographysrc').addClass('d-none');
      jQuery('#expiryouter').addClass('d-none');
      jQuery('#registrarsrc').removeClass('d-none');
      jQuery('#createddatesrcouter').addClass('d-none');
      jQuery('#webcatsrc').addClass('d-none');
      jQuery('#businesssrc').addClass('d-none');
      jQuery('#entitysrc').addClass('d-none');
      jQuery('#mastersrc').addClass('d-none');

    } else if (setsrcfield == "business_sector") {

      jQuery('#searchbox').addClass('d-none');
      jQuery('#statsrc').addClass('d-none');
      jQuery('#domainsrc').addClass('d-none');
      jQuery('#geographysrc').addClass('d-none');
      jQuery('#expiryouter').addClass('d-none');
      jQuery('#registrarsrc').addClass('d-none');
      jQuery('#createddatesrcouter').addClass('d-none');
      jQuery('#webcatsrc').addClass('d-none');
      jQuery('#businesssrc').removeClass('d-none');
      jQuery('#entitysrc').addClass('d-none');
      jQuery('#mastersrc').addClass('d-none');

    } else if (setsrcfield == "aljentity") {

      jQuery('#searchbox').addClass('d-none');
      jQuery('#statsrc').addClass('d-none');
      jQuery('#domainsrc').addClass('d-none');
      jQuery('#geographysrc').addClass('d-none');
      jQuery('#expiryouter').addClass('d-none');
      jQuery('#registrarsrc').addClass('d-none');
      jQuery('#createddatesrcouter').addClass('d-none');
      jQuery('#webcatsrc').addClass('d-none');
      jQuery('#businesssrc').addClass('d-none');
      jQuery('#entitysrc').removeClass('d-none');
      jQuery('#mastersrc').addClass('d-none');

    } else if (setsrcfield == "masterbrand") {

      jQuery('#searchbox').addClass('d-none');
      jQuery('#statsrc').addClass('d-none');
      jQuery('#domainsrc').addClass('d-none');
      jQuery('#geographysrc').addClass('d-none');
      jQuery('#expiryouter').addClass('d-none');
      jQuery('#registrarsrc').addClass('d-none');
      jQuery('#createddatesrcouter').addClass('d-none');
      jQuery('#webcatsrc').addClass('d-none');
      jQuery('#businesssrc').addClass('d-none');
      jQuery('#entitysrc').addClass('d-none');
      jQuery('#mastersrc').removeClass('d-none');

    } else if (setsrcfield == "webcategory") {

      jQuery('#searchbox').addClass('d-none');
      jQuery('#statsrc').addClass('d-none');
      jQuery('#domainsrc').addClass('d-none');
      jQuery('#geographysrc').addClass('d-none');
      jQuery('#expiryouter').addClass('d-none');
      jQuery('#registrarsrc').addClass('d-none');
      jQuery('#createddatesrcouter').addClass('d-none');
      jQuery('#webcatsrc').removeClass('d-none');
      jQuery('#businesssrc').addClass('d-none');
      jQuery('#entitysrc').addClass('d-none');
      jQuery('#mastersrc').addClass('d-none');

    } else if (setsrcfield == "expirydate") {

      jQuery('#searchbox').addClass('d-none');
      jQuery('#statsrc').addClass('d-none');
      jQuery('#domainsrc').addClass('d-none');
      jQuery('#geographysrc').addClass('d-none');
      jQuery('#registrarsrc').addClass('d-none');
      jQuery('#expiryouter').removeClass('d-none');
      jQuery('#createddatesrcouter').addClass('d-none');
      jQuery('#webcatsrc').addClass('d-none');
      jQuery('#businesssrc').addClass('d-none');
      jQuery('#entitysrc').addClass('d-none');
      jQuery('#mastersrc').addClass('d-none');

    } else if (setsrcfield == "createddate" || setsrcfield == "updateddate") {

      jQuery('#searchbox').addClass('d-none');
      jQuery('#statsrc').addClass('d-none');
      jQuery('#domainsrc').addClass('d-none');
      jQuery('#geographysrc').addClass('d-none');
      jQuery('#registrarsrc').addClass('d-none');
      jQuery('#expiryouter').addClass('d-none');
      jQuery('#createddatesrcouter').removeClass('d-none');
      jQuery('#webcatsrc').addClass('d-none');
      jQuery('#businesssrc').addClass('d-none');
      jQuery('#entitysrc').addClass('d-none');
      jQuery('#mastersrc').addClass('d-none');

    } else if (setsrcfield == "status") {

      jQuery('#registrarsrc').addClass('d-none');
      jQuery('#geographysrc').addClass('d-none');
      jQuery('#domainsrc').addClass('d-none');
      jQuery('#searchbox').addClass('d-none');
      jQuery('#expiryouter').addClass('d-none');
      jQuery('#statsrc').removeClass('d-none');
      jQuery('#createddatesrcouter').addClass('d-none');
      jQuery('#webcatsrc').addClass('d-none');
      jQuery('#businesssrc').addClass('d-none');
      jQuery('#entitysrc').addClass('d-none');
      jQuery('#mastersrc').addClass('d-none');

    } else {

      jQuery('#registrarsrc').addClass('d-none');
      jQuery('#geographysrc').addClass('d-none');
      jQuery('#domainsrc').addClass('d-none');
      jQuery('#expiryouter').addClass('d-none');
      jQuery('#statsrc').addClass('d-none');
      jQuery('#searchbox').removeClass('d-none');
      jQuery('#createddatesrcouter').addClass('d-none');
      jQuery('#webcatsrc').addClass('d-none');
      jQuery('#businesssrc').addClass('d-none');
      jQuery('#entitysrc').addClass('d-none');
      jQuery('#mastersrc').addClass('d-none');

    }
  }
  searchRecords = () => {
    var searchbox = jQuery('#searchbox').val();
    var statsrc = jQuery('#statsrc').val();
    var expirydatesrc = jQuery('#expirydatesrc').val();
    var expirydateendsrc = jQuery('#expirydateendsrc').val();
    var createddatesrc = jQuery('#createddatesrc').val();
    var createddateendsrc = jQuery('#createddateendsrc').val();


    var st = expirydatesrc;
    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
    var expirydatevalid = new Date(st.replace(pattern, '$3-$2-$1'));

    if (expirydatevalid.toString() === 'Invalid Date' && expirydatesrc !== "") {
      jQuery('#expirydatesrc').addClass('is-invalid');
      return false;
    } else {
      jQuery('#expirydatesrc').removeClass('is-invalid');
    }

    var st = expirydateendsrc;
    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
    var expirydateendsrcvalid = new Date(st.replace(pattern, '$3-$2-$1'));

    if (expirydateendsrcvalid.toString() === 'Invalid Date' && expirydateendsrc !== "") {
      jQuery('#expirydateendsrc').addClass('is-invalid');
      return false;
    } else {
      jQuery('#expirydateendsrc').removeClass('is-invalid');
    }

    var st = createddatesrc;
    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
    var createddatesrcvalid = new Date(st.replace(pattern, '$3-$2-$1'));

    if (createddatesrcvalid.toString() === 'Invalid Date' && createddatesrc !== "") {
      jQuery('#createddatesrc').addClass('is-invalid');
      return false;
    } else {
      jQuery('#createddatesrc').removeClass('is-invalid');
    }

    var st = createddateendsrc;
    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
    var createddateendsrcvalid = new Date(st.replace(pattern, '$3-$2-$1'));

    if (createddateendsrcvalid.toString() === 'Invalid Date' && createddateendsrc !== "") {
      jQuery('#createddateendsrc').addClass('is-invalid');
      return false;
    } else {
      jQuery('#createddateendsrc').removeClass('is-invalid');
    }



    var setsrcfield = jQuery('#setsrcfield').val();
    if (setsrcfield == "name") {
      jQuery('#searchboxhidden').val(searchbox);

      jQuery('#namefilter label').html(searchbox);
      jQuery('#namefilter').removeClass('d-none');

    } else if (setsrcfield == "registrant") {
      jQuery('#registranthidden').val(searchbox);

      jQuery('#registrantfilter label').html(searchbox);
      jQuery('#registrantfilter').removeClass('d-none');

    } else if (setsrcfield == "contact_name") {
      jQuery('#contactnamehidden').val(searchbox);

      jQuery('#contactnamefilter label').html(searchbox);
      jQuery('#contactnamefilter').removeClass('d-none');

    } else if (setsrcfield == "contact_email") {
      jQuery('#contactemailhidden').val(searchbox);

      jQuery('#contactemailfilter label').html(searchbox);
      jQuery('#contactemailfilter').removeClass('d-none');

    } else if (setsrcfield == "domain") {

      if (this.state.domainsrc) {
        jQuery('#domainfilter label').html(this.state.domainsrc.label);
      } else {
        jQuery('#domainfilter label').html();
      }
      jQuery('#domainfilter').removeClass('d-none');

    } else if (setsrcfield == "geography") {
      if (this.state.geographysrc) {
        jQuery('#geographyfilter label').html(this.state.geographysrc.label);
      } else {
        jQuery('#geographyfilter label').html();
      }
      jQuery('#geographyfilter').removeClass('d-none');

    } else if (setsrcfield == "registrar") {
      if (this.state.registrarsrc) {
        jQuery('#registrarfilter label').html(this.state.registrarsrc.label);
      } else {
        jQuery('#registrarfilter label').html();
      }

      jQuery('#registrarfilter').removeClass('d-none');

    } else if (setsrcfield == "business_sector") {
      if (this.state.businesssrc) {
        jQuery('#businessfilter label').html(this.state.businesssrc.label);
      } else {
        jQuery('#businessfilter label').html();
      }

      jQuery('#businessfilter').removeClass('d-none');

    } else if (setsrcfield == "aljentity") {
      if (this.state.entitysrc) {
        jQuery('#entityfilter label').html(this.state.entitysrc.label);
      } else {
        jQuery('#entityfilter label').html();
      }

      jQuery('#entityfilter').removeClass('d-none');

    } else if (setsrcfield == "masterbrand") {
      if (this.state.mastersrc) {
        jQuery('#masterfilter label').html(this.state.mastersrc.label);
      } else {
        jQuery('#masterfilter label').html();
      }

      jQuery('#masterfilter').removeClass('d-none');

    } else if (setsrcfield == "webcategory") {
      if (this.state.webcatsrc) {
        jQuery('#webcatfilter label').html(this.state.webcatsrc.label);
      } else {
        jQuery('#webcatfilter label').html();
      }

      jQuery('#webcatfilter').removeClass('d-none');

    } else if (setsrcfield == "expirydate") {
      jQuery('#expirydatehidden').val(expirydatesrc);
      jQuery('#expirydateendhidden').val(expirydateendsrc);

      jQuery('#expirydatefilter label').html(expirydatesrc + " - " + expirydateendsrc);
      jQuery('#expirydatefilter').removeClass('d-none');

    } else if (setsrcfield == "createddate") {
      jQuery('#createddatehidden').val(createddatesrc);
      jQuery('#createddateendhidden').val(createddateendsrc);

      jQuery('#createddatefilter label').html(createddatesrc + " - " + createddateendsrc);
      jQuery('#createddatefilter').removeClass('d-none');

    } else if (setsrcfield == "updateddate") {
      jQuery('#updateddatehidden').val(createddatesrc);
      jQuery('#updateddateendhidden').val(createddateendsrc);

      jQuery('#updateddatefilter label').html(createddatesrc + " - " + createddateendsrc);
      jQuery('#updateddatefilter').removeClass('d-none');

    } else if (setsrcfield == "status") {
      jQuery('#statsrchidden').val(statsrc);

      jQuery('#statfilter label').html(statsrc);
      jQuery('#statfilter').removeClass('d-none');

    }

    this.searchInit();
  }

  searchInit = () => {
    var searchboxhidden = jQuery('#searchboxhidden').val();
    var registranthidden = jQuery('#registranthidden').val();
    var contactnamehidden = jQuery('#contactnamehidden').val();
    var contactemailhidden = jQuery('#contactemailhidden').val();
    var statsrchidden = jQuery('#statsrchidden').val();
    var domainhidden = jQuery('#domainhidden').val();
    var geographyhidden = jQuery('#geographyhidden').val();
    var registrarhidden = jQuery('#registrarhidden').val();
    var businesshidden = jQuery('#businesshidden').val();
    var entityhidden = jQuery('#entityhidden').val();
    var masterhidden = jQuery('#masterhidden').val();
    var webcathidden = jQuery('#webcathidden').val();
    var expirydatehidden = jQuery('#expirydatehidden').val();
    var expirydateendhidden = jQuery('#expirydateendhidden').val();
    var createddatehidden = jQuery('#createddatehidden').val();
    var createddateendhidden = jQuery('#createddateendhidden').val();
    var updateddatehidden = jQuery('#updateddatehidden').val();
    var updateddateendhidden = jQuery('#updateddateendhidden').val();

    var searchdatas = {
      searchbox: searchboxhidden,
      registrant: registranthidden,
      contactname: contactnamehidden,
      contactemail: contactemailhidden,
      domainname: domainhidden,
      geography: geographyhidden,
      registrar: registrarhidden,
      business_sector: businesshidden,
      entity: entityhidden,
      masterbrand: masterhidden,
      webcategory: webcathidden,
      expirydate: expirydatehidden,
      expirydateend: expirydateendhidden,
      createddate: createddatehidden,
      createddateend: createddateendhidden,
      updateddate: updateddatehidden,
      updateddateend: updateddateendhidden,
      statsrc: statsrchidden,
    };

    if (searchboxhidden !== "" || registranthidden !== "" || contactnamehidden !== "" || contactemailhidden !== "" || domainhidden !== "" || geographyhidden !== "" || registrarhidden !== "" || businesshidden !== "" || entityhidden !== "" || masterhidden !== "" || webcathidden !== "" || expirydatehidden !== ""
      || expirydateendhidden !== "" || createddatehidden !== "" || createddateendhidden !== "" || updateddatehidden !== "" || updateddateendhidden !== "" || statsrchidden !== "") {

      getSearchResults(searchdatas).then((value) => {
        this.setState({ data: value, });
        this.setState({ searchcount: value.length, });
      });
      jQuery('.tablepagination .col-auto').addClass('d-none');
    } else {
      var dataperpage = jQuery('#dataperpage').val();
      var sortitemval = jQuery('#sortitemval').val();
      var sortitemorder = jQuery('#sortitemorder').val();
      getDomain(1, dataperpage, sortitemval, sortitemorder).then((value) => {
        this.setState({ data: value, });
        this.setState({ searchcount: "", });
      });
      jQuery('.tablepagination .col-auto').removeClass('d-none');
    }

  }

  clearFilterItem = (event) => {
    var itemname = event.currentTarget.dataset.item;
    if (itemname === "name") {

      jQuery('#namefilter label').html('');
      jQuery('#namefilter').addClass('d-none');
      jQuery('#searchboxhidden').val('');

    } else if (itemname === "registrant") {

      jQuery('#registrantfilter label').html('');
      jQuery('#registrantfilter').addClass('d-none');
      jQuery('#registranthidden').val('');

    } else if (itemname === "contact_name") {

      jQuery('#contactnamefilter label').html('');
      jQuery('#contactnamefilter').addClass('d-none');
      jQuery('#contactnamehidden').val('');

    } else if (itemname === "contact_email") {

      jQuery('#contactemailfilter label').html('');
      jQuery('#contactemailfilter').addClass('d-none');
      jQuery('#contactemailhidden').val('');

    } else if (itemname === "domain") {

      jQuery('#domainfilter label').html('');
      jQuery('#domainfilter').addClass('d-none');
      jQuery('#domainhidden').val('');
      this.setState({ domainsrc: '' });

    } else if (itemname === "geography") {

      jQuery('#geographyfilter label').html('');
      jQuery('#geographyfilter').addClass('d-none');
      jQuery('#geographyhidden').val('');
      this.setState({ geographysrc: '' });

    } else if (itemname === "registrar") {

      jQuery('#registrarfilter label').html('');
      jQuery('#registrarfilter').addClass('d-none');
      jQuery('#registrarhidden').val('');
      this.setState({ registrarsrc: '' });

    } else if (itemname === "business_sector") {

      jQuery('#businessfilter label').html('');
      jQuery('#businessfilter').addClass('d-none');
      jQuery('#businesshidden').val('');
      this.setState({ businesssrc: '' });

    } else if (itemname === "aljentity") {

      jQuery('#entitysrc').val('');

      jQuery('#entityfilter label').html('');
      jQuery('#entityfilter').addClass('d-none');
      jQuery('#entityhidden').val('');
      this.setState({ entitysrc: '' });

    } else if (itemname === "masterbrand") {

      jQuery('#masterfilter label').html('');
      jQuery('#masterfilter').addClass('d-none');
      jQuery('#masterhidden').val('');
      this.setState({ mastersrc: '' });

    } else if (itemname === "webcategory") {

      jQuery('#webcatfilter label').html('');
      jQuery('#webcatfilter').addClass('d-none');
      jQuery('#webcathidden').val('');
      this.setState({ webcatsrc: '' });

    } else if (itemname === "expirydate") {

      jQuery('#expirydatefilter label').html('');
      jQuery('#expirydatefilter').addClass('d-none');
      jQuery('#expirydatehidden').val('');
      jQuery('#expirydateendhidden').val('');

    } else if (itemname === "createddate") {

      jQuery('#createddatefilter label').html('');
      jQuery('#createddatefilter').addClass('d-none');
      jQuery('#createddatehidden').val('');
      jQuery('#createddateendhidden').val('');

    } else if (itemname === "updateddate") {

      jQuery('#updateddatefilter label').html('');
      jQuery('#updateddatefilter').addClass('d-none');
      jQuery('#updateddatehidden').val('');

    } else if (itemname === "status") {

      jQuery('#statfilter label').html('');
      jQuery('#statfilter').addClass('d-none');
      jQuery('#statsrchidden').val('');

    }

    this.searchInit();
  }

  hideRecord = (event) => {
    var objectid = event.currentTarget.dataset.target;
    jQuery('#hiddenidstored').val(objectid);
    this.toggle();
  }
  pushhideRecord = () => {
    var objectid = jQuery('#hiddenidstored').val();
    hideRecords(objectid).then((value) => {
      if (value === true) {
        this.gotoPage();
        this.setState({ deletedstat: 'd-block' });
      }
    });
    this.toggle();
  }




  render() {
    var domainnamestate = this.state.domainnamedata;
    var domainnamelist = [];
    if (domainnamestate && domainnamestate.length > 0) {
      for (let i = 0; i < domainnamestate.length; i++) {
        domainnamelist.push({ value: domainnamestate[i].id, label: domainnamestate[i].attributes.name })
      }
    }

    var geographystate = this.state.geographyData;
    var geographylist = [];
    if (geographystate && geographystate.length > 0) {
      for (let i = 0; i < geographystate.length; i++) {
        geographylist.push({ value: geographystate[i].id, label: geographystate[i].attributes.name })
      }
    }

    var registrarstate = this.state.registrarData;
    var registrarlist = [];
    if (registrarstate && registrarstate.length > 0) {
      for (let i = 0; i < registrarstate.length; i++) {
        registrarlist.push({ value: registrarstate[i].id, label: registrarstate[i].attributes.name })
      }
    }

    var webcatstate = this.state.webcatData;
    var webcatlist = [];
    if (webcatstate && webcatstate.length > 0) {
      for (let i = 0; i < webcatstate.length; i++) {
        webcatlist.push({ value: webcatstate[i].id, label: webcatstate[i].attributes.name })
      }
    }

    var businessstate = this.state.businessData;
    var businesslist = [];
    if (businessstate && businessstate.length > 0) {
      for (let i = 0; i < businessstate.length; i++) {
        businesslist.push({ value: businessstate[i].id, label: businessstate[i].attributes.name })
      }
    }

    var compstate = this.state.companyData;
    var complist = [];
    if (compstate && compstate.length > 0) {
      for (let i = 0; i < compstate.length; i++) {
        complist.push({ value: compstate[i].id, label: compstate[i].attributes.name })
      }
    }

    var masterstate = this.state.masterData;
    var masterlist = [];
    if (masterstate && masterstate.length > 0) {
      for (let i = 0; i < masterstate.length; i++) {
        masterlist.push({ value: masterstate[i].id, label: masterstate[i].attributes.name })
      }
    }









    return <div className="animated fadeIn">

      <a onClick={this.toggleColumns} className="btn btn-default">Customize / Hide Columns</a>
      <div className="customcheck greybgcheck yscroll" id="hidecheckgroup">
        {this.createColumns()}
        <Button type="button" color="primary" id="addbutton" onClick={this.saveHiddenColumns}>
          <span className="border-left pl-2"></span>
          Save
        </Button>

        {/* Loader button */}
        <div className="btn btn-primary d-none" id="loadbutton">
          <Loader type="Oval" color="#414241" height={20} width={20} />
        </div>
        {/* Loader button */}

      </div>




      <Input type="hidden" id="sortitemval" />
      <Input type="hidden" id="sortitemorder" />

      <Row className="justify-content-end">
        <Col className="col-auto mb-4">
          <Link to="/domain/calendarview" className="btn btn-primary uppercase">
            <span><i className="fas fa-calendar-alt mr-2"></i> Calendar View</span>
          </Link>
        </Col>
        {this.state.editmode?
        <Col className="col-auto mb-4">
          <Link to="/domain/adddomain" className="btn btn-primary btnaddmew">
            <span className="border-left pl-2">Add new</span>
          </Link>
        </Col>:null}
        
      </Row>

      <Row>
        <Col className="col-12 mb-4">
          <Form className="tableserch">
            <InputGroup>
              <FormGroup className="form-group col-md-2 p-0 m-0">
                <Input type="select" className="custom-select form-control" id="setsrcfield" onChange={this.setSearchFields}>
                  <option value="name">Name</option>
                  <option value="aljentity">ALJ Entity</option>
                  <option value="business_sector">Business Sector</option>
                  <option value="contact_email">Contact Email</option>
                  <option value="contact_name">Contact Name</option>
                  <option value="geography">Country</option>
                  <option value="createddate">Created Date</option>
                  <option value="domain">Domain Extension</option>
                  <option value="expirydate">Expiry Date</option>
                  <option value="masterbrand">Masterbrand Division</option>
                  <option value="registrant">Registrant</option>
                  <option value="registrar">Registrar</option>
                  <option value="status">Status</option>
                  <option value="updateddate">Updated Date</option>
                  <option value="webcategory">Web Category</option>
                </Input>
              </FormGroup>

              <Input type="text" placeholder="Enter your search here" id="searchbox" onKeyDown={this.hitEnter} />
              <Input type="hidden" id="searchboxhidden" />
              <Input type="hidden" id="registranthidden" />
              <Input type="hidden" id="contactnamehidden" />
              <Input type="hidden" id="contactemailhidden" />


              <Select
                id="domainsrc"
                onKeyDown={this.hitEnter}
                className="reactSelect reactSelectClass filterselect d-none"
                closeMenuOnSelect={true}
                options={domainnamelist}
                onChange={(selectedOption) => {
                  this.setState({ domainsrc: selectedOption });
                }}
                value={this.state.domainsrc}
              />
              <Input type="hidden" id="domainhidden" value={
                this.state.domainsrc ?
                  this.state.domainsrc.value
                  :
                  ""
              } />


              <Select
                id="geographysrc"
                onKeyDown={this.hitEnter}
                className="reactSelect reactSelectClass filterselect d-none"
                closeMenuOnSelect={true}
                options={geographylist}
                onChange={(selectedOption) => {
                  this.setState({ geographysrc: selectedOption });
                }}
                value={this.state.geographysrc}
              />
              <Input type="hidden" id="geographyhidden" value={
                this.state.geographysrc ?
                  this.state.geographysrc.value
                  :
                  ""
              } />


              <Select
                id="registrarsrc"
                onKeyDown={this.hitEnter}
                className="reactSelect reactSelectClass filterselect d-none"
                closeMenuOnSelect={true}
                options={registrarlist}
                onChange={(selectedOption) => {
                  this.setState({ registrarsrc: selectedOption });
                }}
                value={this.state.registrarsrc}
              />
              <Input type="hidden" id="registrarhidden" value={
                this.state.registrarsrc ?
                  this.state.registrarsrc.value
                  :
                  ""
              } />



              <Select
                id="webcatsrc"
                onKeyDown={this.hitEnter}
                className="reactSelect reactSelectClass filterselect d-none"
                closeMenuOnSelect={true}
                options={webcatlist}
                onChange={(selectedOption) => {
                  this.setState({ webcatsrc: selectedOption });
                }}
                value={this.state.webcatsrc}
              />
              <Input type="hidden" id="webcathidden" value={
                this.state.webcatsrc ?
                  this.state.webcatsrc.value
                  :
                  ""
              } />


              <Select
                id="businesssrc"
                onKeyDown={this.hitEnter}
                className="reactSelect reactSelectClass filterselect d-none"
                closeMenuOnSelect={true}
                options={businesslist}
                onChange={(selectedOption) => {
                  this.setState({ businesssrc: selectedOption });
                }}
                value={this.state.businesssrc}
              />
              <Input type="hidden" id="businesshidden" value={
                this.state.businesssrc ?
                  this.state.businesssrc.value
                  :
                  ""
              } />


              <Select
                id="entitysrc"
                onKeyDown={this.hitEnter}
                className="reactSelect reactSelectClass filterselect d-none"
                closeMenuOnSelect={true}
                options={complist}
                onChange={(selectedOption) => {
                  this.setState({ entitysrc: selectedOption });
                }}
                value={this.state.entitysrc}
              />
              <Input type="hidden" id="entityhidden" value={
                this.state.entitysrc ?
                  this.state.entitysrc.value
                  :
                  ""
              } />


              <Select
                id="mastersrc"
                onKeyDown={this.hitEnter}
                className="reactSelect reactSelectClass filterselect d-none"
                closeMenuOnSelect={true}
                options={masterlist}
                onChange={(selectedOption) => {
                  this.setState({ mastersrc: selectedOption });
                }}
                value={this.state.mastersrc}
              />
              <Input type="hidden" id="masterhidden" value={
                this.state.mastersrc ?
                  this.state.mastersrc.value
                  :
                  ""
              } />











              {/* Expiry */}
              <div className="datehalf d-none" id="expiryouter">
                <Datetime
                  inputProps={{ placeholder: "Start date", id: "expirydatesrc", class: "form-control", autoComplete: "off" }}
                  timeFormat={false}
                  dateFormat="DD/MM/YYYY"
                  onKeyPress={this.hitEnter}
                  closeOnSelect={true}
                  />

                <Datetime
                  inputProps={{ placeholder: "End date", id: "expirydateendsrc", class: "form-control", autoComplete: "off" }}
                  timeFormat={false}
                  dateFormat="DD/MM/YYYY"
                  onKeyPress={this.hitEnter}
                  closeOnSelect={true}
                />
              </div>
              <Input type="hidden" id="expirydatehidden" />
              <Input type="hidden" id="expirydateendhidden" />

              {/* Expiry */}

              <div className="datehalf d-none" id="createddatesrcouter">
                <Datetime
                  inputProps={{ placeholder: "Start date", id: "createddatesrc", class: "form-control", autoComplete: "off" }}
                  timeFormat={false}
                  dateFormat="DD/MM/YYYY"
                  onKeyPress={this.hitEnter}
                  closeOnSelect={true}
                />
                <Datetime
                  inputProps={{ placeholder: "End date", id: "createddateendsrc", class: "form-control", autoComplete: "off" }}
                  timeFormat={false}
                  dateFormat="DD/MM/YYYY"
                  onKeyPress={this.hitEnter}
                  closeOnSelect={true}
                />
              </div>
              <Input type="hidden" id="createddatehidden" />
              <Input type="hidden" id="createddateendhidden" />




              <div className="datefull d-none" id="updateddatesrcouter">
                <Datetime
                  inputProps={{ placeholder: "Updated date", id: "updateddatesrc", class: "form-control", autoComplete: "off" }}
                  timeFormat={false}
                  dateFormat="DD/MM/YYYY"
                  onKeyPress={this.hitEnter}
                  closeOnSelect={true}
                />
              </div>
              <Input type="hidden" id="updateddatehidden" />
              <Input type="hidden" id="updateddateendhidden" />


              <select name="statsrc" id="statsrc" className="d-none" onKeyDown={this.hitEnter}>
                <option value="">Select</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
              <Input type="hidden" id="statsrchidden" />



              {/* <InputGroupAddon addonType="append"> */}
                <Button className="search" onClick={this.searchRecords}><i className="ti-search" ></i></Button>
              {/* </InputGroupAddon> */}
            </InputGroup>
          </Form>
          <div id="searchfilters">
            <div id="namefilter" className="d-none filters">Name: <label></label> <span className="fclose" data-item="name" onClick={this.clearFilterItem}>x</span></div>
            <div id="domainfilter" className="d-none filters">Domain extension: <label></label> <span className="fclose" data-item="domain" onClick={this.clearFilterItem}>x</span></div>
            <div id="geographyfilter" className="d-none filters">Country: <label></label> <span className="fclose" data-item="geography" onClick={this.clearFilterItem}>x</span></div>
            <div id="registrarfilter" className="d-none filters">Registrar: <label></label> <span className="fclose" data-item="registrar" onClick={this.clearFilterItem}>x</span></div>
            <div id="expirydatefilter" className="d-none filters">Expiry: <label></label> <span className="fclose" data-item="expirydate" onClick={this.clearFilterItem}>x</span></div>
            <div id="createddatefilter" className="d-none filters">Created date: <label></label> <span className="fclose" data-item="createddate" onClick={this.clearFilterItem}>x</span></div>
            <div id="updateddatefilter" className="d-none filters">Updated date: <label></label> <span className="fclose" data-item="updateddate" onClick={this.clearFilterItem}>x</span></div>
            <div id="statfilter" className="d-none filters">Status: <label></label> <span className="fclose" data-item="status" onClick={this.clearFilterItem}>x</span></div>
            <div id="webcatfilter" className="d-none filters">Status: <label></label> <span className="fclose" data-item="webcategory" onClick={this.clearFilterItem}>x</span></div>
            <div id="businessfilter" className="d-none filters">Business Sector: <label></label> <span className="fclose" data-item="business_sector" onClick={this.clearFilterItem}>x</span></div>
            <div id="entityfilter" className="d-none filters">Entity: <label></label> <span className="fclose" data-item="aljentity" onClick={this.clearFilterItem}>x</span></div>
            <div id="masterfilter" className="d-none filters">Master Brand: <label></label> <span className="fclose" data-item="masterbrand" onClick={this.clearFilterItem}>x</span></div>
            <div id="registrantfilter" className="d-none filters">Registrant: <label></label> <span className="fclose" data-item="registrant" onClick={this.clearFilterItem}>x</span></div>
            <div id="contactnamefilter" className="d-none filters">Contact Name: <label></label> <span className="fclose" data-item="contact_name" onClick={this.clearFilterItem}>x</span></div>
            <div id="contactemailfilter" className="d-none filters">Contact Email: <label></label> <span className="fclose" data-item="contact_email" onClick={this.clearFilterItem}>x</span></div>
          </div>
        </Col>
      </Row>


      <Row>

        <Col xs="12">
          <Card>

            {/* <CardTitle><i className="mdi mdi-border-all mr-2"></i>Domain</CardTitle> */}
            <CardBody>
              <Alert color="success" className={this.state.deletedstat}>
                Selected status have been changed!
              </Alert>

              <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                <ModalHeader toggle={this.toggle}>Confirm</ModalHeader>
                <ModalBody>
                  Do you want to change status?
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={this.pushhideRecord}>Yes</Button>
                  <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                </ModalFooter>
              </Modal>
              <Input type="hidden" id="hiddenidstored" />

              <Table responsive className="longtable text-center">
                <thead>
                  <tr>
                    <th className="cursor-pointer" data-sort="name" onClick={this.setSortItem}>Name </th>
                    <th className="cursor-pointer" data-sort="domain" onClick={this.setSortItem}>Domain </th>
                    <th className="cursor-pointer" data-sort="geography" onClick={this.setSortItem}>Country </th>
                    <th className="cursor-pointer" data-sort="region" onClick={this.setSortItem}>Region </th>
                    <th className="cursor-pointer" data-sort="registrar" onClick={this.setSortItem}>Registrar </th>
                    <th className="cursor-pointer" data-sort="expiry_date" onClick={this.setSortItem}>Expiry </th>
                    <th className="cursor-pointer" data-sort="domain_status" onClick={this.setSortItem}>Domain Status </th>
                    <th className="cursor-pointer" data-sort="web_category" onClick={this.setSortItem}>Web Category </th>
                    <th className="cursor-pointer" data-sort="website" onClick={this.setSortItem}>Website </th>
                    <th className="cursor-pointer" data-sort="created_on_date" onClick={this.setSortItem}>Created Date </th>
                    <th className="cursor-pointer" data-sort="updated_date" onClick={this.setSortItem}>Updated Date </th>
                    <th className="cursor-pointer" data-sort="business_sector" onClick={this.setSortItem}>Business Sector </th>
                    <th className="cursor-pointer" data-sort="alj_entity" onClick={this.setSortItem}>ALJ Entity </th>
                    <th className="cursor-pointer" data-sort="masterbrand" onClick={this.setSortItem}>Masterbrand</th>
                    <th className="cursor-pointer" data-sort="registrant_name" onClick={this.setSortItem}>Registrant Name</th>
                    <th className="cursor-pointer" data-sort="contact_email" onClick={this.setSortItem}>Contact Email</th>
                    <th className="cursor-pointer" data-sort="url" onClick={this.setSortItem}>URL</th>
                    <th className="cursor-pointer" data-sort="redirect" onClick={this.setSortItem}>Redirect</th>
                    <th className="cursor-pointer" data-sort="registrant_email" onClick={this.setSortItem}>Registrant Email</th>
                    <th className="cursor-pointer" data-sort="previous_registrant" onClick={this.setSortItem}>Previous Registrant</th>
                    <th className="cursor-pointer" data-sort="previous_registrant_email" onClick={this.setSortItem}>Previous Registrant Email</th>
                    <th className="cursor-pointer" data-sort="handled_by" onClick={this.setSortItem}>Handled By</th>
                    <th className="cursor-pointer" data-sort="contact_name" onClick={this.setSortItem}>Contact Name</th>
                    <th className="cursor-pointer" data-sort="action" onClick={this.setSortItem}>Action</th>
                    <th className="cursor-pointer" data-sort="notes" onClick={this.setSortItem}>Notes</th>
                    <th className="cursor-pointer" data-sort="ipr_date_transferred" onClick={this.setSortItem}>IPR Date Transferred</th>
                    <th className="cursor-pointer" data-sort="ipr_transfer_status" onClick={this.setSortItem}>IPR Transfer Status</th>
                    <th className="cursor-pointer" data-sort="register_alj_ipr" onClick={this.setSortItem}>Register ALJ IPR</th>
                    <th className="text-center" width="110">Actions</th>
                  </tr>
                </thead>
                <tbody>



                  {
                    this.state.data && this.state.data.length > 0 ?
                      this.state.data.map((dynamicData, key) =>
                        <tr key={key}>
                          <td>
                            <Link to={"/domain/editdomain/" + dynamicData.id + "/default"} target="_blank" className="tdwrap lowercase">
                              {dynamicData.get('name')}
                            </Link>
                          </td>
                          <td>
                            {
                              dynamicData.get('domain') ?
                                <Link to={"/domain/editdomain/" + dynamicData.id + "/default"} target="_blank" className="tdwrap lowercase">
                                  {dynamicData.get('domain').get('name')}
                                </Link>
                                :
                                ""
                            }
                          </td>
                          <td>
                            {
                              dynamicData.get('geography') ?
                                <Link to={"/domain/editdomain/" + dynamicData.id + "/default"} target="_blank" className="tdwrap">
                                  {dynamicData.get('geography').get('name')}
                                </Link>
                                :
                                ""
                            }
                          </td>
                          <td>
                            {
                              dynamicData.get('region') ?
                                // <Link to={"/domain/editdomain/" + dynamicData.id + "/default"} className="tdwrap">
                                // {dynamicData.get('region').get('name')}
                                <span className="cursor-pointer" onClick={this.toggle5} data-regionid={dynamicData.get('region').id} data-regiontext={dynamicData.get('region').get('name')}>{dynamicData.get('region').get('name')} </span>
                                // </Link>
                                :
                                ""
                            }
                          </td>
                          <td>
                            {
                              dynamicData.get('registrar') ?
                                <Link to={"/domain/editdomain/" + dynamicData.id + "/default"} target="_blank" className="tdwrap">
                                  {dynamicData.get('registrar').get('name')}
                                </Link>
                                :
                                ""
                            }
                          </td>
                          <td>
                            {
                              dynamicData.get('expiry_date') ?
                                <Link to={"/domain/editdomain/" + dynamicData.id + "/default"} target="_blank" className="tdwrap">
                                  {dynamicData.get('expiry_date').toLocaleDateString('en-GB')}
                                </Link>
                                :
                                ""
                            }
                          </td>

                          {/* <td className="status">
                          {
                            dynamicData.get('status').toString() === 'true' ?
                              <span className="active">Active</span>
                              :
                              <span className="inactive">Inactive</span>
                          }
                        </td> */}

                          <td>
                            {
                              dynamicData.get('domain_status') ?
                                <Link to={"/domain/editdomain/" + dynamicData.id + "/default"} target="_blank" className="tdwrap">
                                  {
                                    dynamicData.get('domain_status').get('name') === "Active" ?
                                      <span className="active">Active</span>
                                      :

                                      dynamicData.get('domain_status').get('name') === "Inactive" ?
                                        <span className="inactive">Inactive</span>
                                        :
                                        <span >{dynamicData.get('domain_status').get('name')}</span>

                                  }
                                </Link>
                                :
                                ""
                            }
                          </td>






                          <td>
                            {
                              dynamicData.get('web_category') ?
                                <Link to={"/domain/editdomain/" + dynamicData.id + "/default"} target="_blank" className="tdwrap">
                                  {dynamicData.get('web_category').get('name')}
                                </Link>
                                :
                                ""
                            }
                          </td>

                          <td>
                            <Link to={"/domain/editdomain/" + dynamicData.id + "/default"} target="_blank" className="tdwrap">
                              {dynamicData.get('website')}
                            </Link>
                          </td>

                          <td>
                            {
                              dynamicData.get('created_on_date') ?
                                <Link to={"/domain/editdomain/" + dynamicData.id + "/default"} target="_blank" className="tdwrap">
                                  {dynamicData.get('created_on_date').toLocaleDateString('en-GB')}
                                </Link>
                                :
                                ""
                            }
                          </td>
                          <td>
                            {
                              dynamicData.get('updated_date') ?
                                <Link to={"/domain/editdomain/" + dynamicData.id + "/default"} target="_blank" className="tdwrap">
                                  {dynamicData.get('updated_date').toLocaleDateString('en-GB')}
                                </Link>
                                :
                                ""
                            }
                          </td>
                          <td>
                            {
                              dynamicData.get('business_sector') ?
                                <Link to={"/domain/editdomain/" + dynamicData.id + "/default"} target="_blank" className="tdwrap">
                                  {dynamicData.get('business_sector').get('name')}
                                </Link>
                                :
                                ""
                            }
                          </td>
                          <td>
                            {
                              dynamicData.get('alj_entity') ?
                                <Link to={"/domain/editdomain/" + dynamicData.id + "/default"} target="_blank" className="tdwrap">
                                  {dynamicData.get('alj_entity').get('name')}
                                </Link>
                                :
                                ""
                            }
                          </td>
                          <td>
                            {
                              dynamicData.get('masterbrand') ?
                                <Link to={"/domain/editdomain/" + dynamicData.id + "/default"} target="_blank" className="tdwrap">
                                  {dynamicData.get('masterbrand').get('name')}
                                </Link>
                                :
                                ""
                            }
                          </td>
                          <td>
                            <Link to={"/domain/editdomain/" + dynamicData.id + "/default"} target="_blank" className="tdwrap">
                              {dynamicData.get('registrant_name')}
                            </Link>
                          </td>
                          <td>
                            <Link to={"/domain/editdomain/" + dynamicData.id + "/default"} target="_blank" className="tdwrap lowercase">
                              {dynamicData.get('contact_email')}
                            </Link>
                          </td>

                          <td>
                            <Link to={"/domain/editdomain/" + dynamicData.id + "/default"} target="_blank" className="tdwrap lowercase">
                              {dynamicData.get('url')}
                            </Link>
                          </td>
                          <td>
                            <Link to={"/domain/editdomain/" + dynamicData.id + "/default"} target="_blank" className="tdwrap">
                              {dynamicData.get('redirect')}
                            </Link>
                          </td>
                          <td>
                            <Link to={"/domain/editdomain/" + dynamicData.id + "/default"} target="_blank" className="tdwrap lowercase">
                              {dynamicData.get('registrant_email')}
                            </Link>
                          </td>
                          <td>
                            <Link to={"/domain/editdomain/" + dynamicData.id + "/default"} target="_blank" className="tdwrap ">
                              {dynamicData.get('previous_registrant')}
                            </Link>
                          </td>
                          <td>
                            <Link to={"/domain/editdomain/" + dynamicData.id + "/default"} target="_blank" className="tdwrap lowercase">
                              {dynamicData.get('previous_registrant_email')}
                            </Link>
                          </td>
                          <td>
                            <Link to={"/domain/editdomain/" + dynamicData.id + "/default"} target="_blank" className="tdwrap">
                              {dynamicData.get('handled_by')}
                            </Link>
                          </td>
                          <td>
                            <Link to={"/domain/editdomain/" + dynamicData.id + "/default"} target="_blank" className="tdwrap">
                              {dynamicData.get('contact_name')}
                            </Link>
                          </td>
                          <td>
                            <Link to={"/domain/editdomain/" + dynamicData.id + "/default"} target="_blank" className="tdwrap">
                              {dynamicData.get('action')}
                            </Link>
                          </td>
                          <td>
                            <Link to={"/domain/editdomain/" + dynamicData.id + "/default"} target="_blank" className="tdwrap">
                              {dynamicData.get('notes')}
                            </Link>
                          </td>
                          <td>
                            <Link to={"/domain/editdomain/" + dynamicData.id + "/default"} target="_blank" className="tdwrap">
                              {
                                dynamicData.get('ipr_date_transferred') ?
                                  dynamicData.get('ipr_date_transferred').toLocaleDateString('en-GB')
                                  :
                                  null
                              }
                            </Link>
                          </td>
                          <td>
                            <Link to={"/domain/editdomain/" + dynamicData.id + "/default"} target="_blank" className="tdwrap">
                              {dynamicData.get('ipr_transfer_status')}
                            </Link>
                          </td>
                          <td>
                            <Link to={"/domain/editdomain/" + dynamicData.id + "/default"} target="_blank" className="tdwrap">
                              {dynamicData.get('register_alj_ipr')}
                            </Link>
                          </td>


                          <td className="text-center">
                            {/* <a href="javascript:" className="btnicon hideit" onClick={this.hideRecord} data-target={dynamicData.id}>
                            {
                              dynamicData.get('status').toString() === 'true' ?
                                <img src={imgview} alt={"imgview"} width="26" />
                                :
                                <img src={imgnoview} alt={"imgnoview"} width="26" />
                            }
                          </a> */}

                            <Link to={"/domain/editdomain/" + dynamicData.id + "/default"} target="_blank" className="btnicon padding0">
                              <img src={imgedit} alt={"imgedit"} width="28" />
                            </Link>

                          </td>
                        </tr>

                      )
                      :
                      this.state.loaderstat === true ?
                        <tr>
                          <td colSpan="12">
                            <div className="recordnotfound">
                              <Loader type="Oval" color="#fff" height={20} width={20} />
                            </div>
                          </td>
                        </tr>

                        :
                        <tr>
                          <td colSpan="12">
                            <div className="recordnotfound">
                              <p>No record found </p>
                            </div>
                          </td>
                        </tr>
                  }


                </tbody>
              </Table>
            </CardBody>


          </Card>

          <div className="totalrecords">
            Total Records :&nbsp;
            {
              this.state.searchcount !== "" ?
                this.state.searchcount
                :
                this.state.datafull
            }
          </div>

          <div className="tablepagination d-flex justify-content-center">
            <div className="col-auto">
              <FormGroup className="form-group pageSizeOptions">
                <Input className='form-control' type="select" id="dataperpage" onChange={this.showPerPage}>
                  <option value="25">25 Rows</option>
                  <option value="50">50 Rows</option>
                  <option value="100">100 Rows</option>
                </Input>
              </FormGroup>
            </div>

            <div className="d-none">
              Page
              <Form className="pageJump ml-1 mr-1">
                <FormGroup className="form-group">
                  <Input type="number" onWheel={ event => event.currentTarget.blur() } defaultValue="1" id="pagenum" min="1" />
                </FormGroup>
              </Form> of {Math.ceil(this.state.totalpagecount)}
              <a href="javascript:" className="btn btn-primary btnarrowright" onClick={this.gotoPage}>Go</a>
            </div>

            <div className="col-auto">
              <Pagination aria-label="Page navigation example">
                {Array(Math.ceil(this.state.totalpagecount)).fill(1).map((key, i) =>
                  <PaginationItem key={i} className={i + 1 === 1 || i + 1 === this.state.totalpagecount ? "active" : (i < 4 ? "" : "d-none")}>
                    <PaginationLink onClick={this.setpageNum} data-pagenum={i + 1} className={i + 1 === 1 ? "paginatecounts active" : "paginatecounts"} >
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                )}
              </Pagination>
            </div>
          </div>

        </Col>
      </Row>









      <Modal isOpen={this.state.modal5} toggle={this.toggle5} className={this.props.className + " legendpopup"}>
        <ModalHeader toggle={this.toggle5}>Countries under {this.state.regiontitle}</ModalHeader>
        <ModalBody>
          <ScrollArea smoothScrolling={0.8} style={{ maxHeight: '250px' }}>
            <ListGroup>
              {
                this.state.regionCountries.map((countryData, ckey) =>
                  <ListGroupItem key={ckey}> {countryData.get('name')} </ListGroupItem>
                )
              }
            </ListGroup>
          </ScrollArea>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.toggle5}>Cancel</Button>
        </ModalFooter>
      </Modal>









    </div>
  }
}

export default domain;
