import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormFeedback,
  CustomInput,
  Tooltip,
  UncontrolledTooltip
} from 'reactstrap';
import Select, { components } from "react-select";
import jQuery from 'jquery'

import { authenticateLoggedIn, addHeaderTitle, addBreadcrumb } from '../../../services/parse';
import { addRecord, checkFieldValid } from '../../../services/licensetype';

class AddLicenseType extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      collapse: true,
      fadeIn: true,
      timeout: 300,
      isSuper: '',
      addedclass: 'd-none',
      companyByIdData: [],
      isEdit: false,
      logofile: null,
      rewardsfile: null,
      loginlogofile: null,
    };
  }


  async componentDidMount(evt) {
    //auth start
    authenticateLoggedIn();
    addHeaderTitle('Add License Type');
    addBreadcrumb('Home / License Type / Add License Type');

  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }


  addRecord = () => {

    var valid = checkFieldValid('add');
    if (valid !== false) {
      jQuery('#addbutton').prop('disabled', true);

      var name = jQuery('#name').val();
      var link = jQuery('#link').val();
      var description = jQuery('#description').val();
      var status = jQuery('#status').val();
      var slug = jQuery('#slug').val();

      addRecord({ name: name, link: link, description: description,slug:slug, status: status }).then((value) => {
        if (value !== false) {
          this.setState({ addedclass: 'd-block' });

          setTimeout(
            function () {
              this.setState({ addedclass: 'd-none' });
              window.location.assign('#/contentmaster/editlicensetype/' + value.id);
            }
              .bind(this),
            1000
          );

        } else {
          this.setState({ addedclass: 'd-none' });
        }

        jQuery('#addform').trigger("reset");
        jQuery('#addbutton').prop('disabled', false);
      });

    }
  }





  render() {
    return <div>

      <Row className="justify-content-between topbuttonbar">
        <Col className="col-md-5">
          <Alert color="success" className={this.state.addedclass}>
            Record Added
            <Link to="/contentmaster/licensetype" className="float-right">
              &nbsp; Go to list
            </Link>
          </Alert>
        </Col>
        <Col className="col-auto mb-4">
          <Link to="/contentmaster/licensetype" className="btn btn-primary btncancle mr-3">
            <span className="border-left pl-2">Cancel</span>
          </Link>
          <Button type="button" color="primary" id="addbutton" onClick={this.addRecord}>
            <span className="border-left pl-2"></span>
            Save
          </Button>
        </Col>
      </Row>

      <Row>

        <Col xs="12" sm="6">


          <Card>
            <CardTitle>
              <i className="icon-circle icon-pencil"></i>
              Add License Type
            </CardTitle>

            <CardBody>

              <form name="addform" id="addform">

                <FormGroup className="form-group">
                  <Label htmlFor="name">Name *</Label>
                  <Input type="text" id="name" placeholder="Name" />
                </FormGroup>
                <FormGroup className="form-group">
                  <Label htmlFor="link">Link *</Label>
                  <Input type="text" id="link" placeholder="Link" />
                </FormGroup>

                <FormGroup className="form-group">
                  <Label htmlFor="status">Status *</Label>
                  <Input type="select" className="custom-select" id="status">
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                  </Input>
                </FormGroup>

                <FormGroup className="form-group">
                  <Label htmlFor="description">Description</Label>
                  <Input type="textarea" id="description" placeholder="Description" rows="3" />
                </FormGroup>

                <FormGroup className="form-group">
                  <Label htmlFor="name">Slug *</Label>
                  <Input type="text" id="slug" placeholder="Slug" />
                </FormGroup>


              </form>
            </CardBody>

          </Card>

        </Col>
      </Row>

    </div>
  }
}

export default AddLicenseType;
