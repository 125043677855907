import React from 'react';
import { Input, Button, Label, } from 'reactstrap';
import Papa from 'papaparse';

import { authenticateLoggedIn, addHeaderTitle, addBreadcrumb } from '../../services/parse';
import { getFinanceForExport } from '../../services/finance';
import Loader from '../../components/common/Loader';


class exportFinance extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false,
            loader: true,
            showfieldItems: [],
            fieldItems: [],
            financeData: [],
        };
    }


    async componentDidMount(evt) {

        authenticateLoggedIn();
        addHeaderTitle('Export Finance');
        addBreadcrumb('Home / Finance / Export');

        var showfieldItems = {
            domain: true,
            currency: true,
            totalcost: true,
            invoice_number: true,
            registrant_email: true,
            account: true,
            invoice_amount: true,
            transaction_type: true,
            cc_statement_date: true,
            date_of_invoice: true,
            domainext: true,
            expiry_date: true,
            registrar: true,
            created_on_date: true,
            updated_date: true,
            status: true,
        }
        this.setState({ showfieldItems: showfieldItems });




        var fieldItems = [
            { label: "Domain", name: "domain" },
            { label: "Currency", name: "currency" },
            { label: "Total Cost", name: "totalcost" },
            { label: "Invoice Number", name: "invoice_number" },
            { label: "Registrant Email", name: "registrant_email" },
            { label: "Account", name: "account" },
            { label: "Invoice Amount", name: "invoice_amount" },
            { label: "Transaction Type", name: "transaction_type" },
            { label: "Corp. CC Statement", name: "cc_statement_date" },
            { label: "Date of Invoice", name: "date_of_invoice" },
            { label: "Domain Extension", name: "domainext" },
            { label: "URL Expiry", name: "expiry_date" },
            { label: "Registrar", name: "registrar" },
            { label: "Domain Created", name: "created_on_date" },
            { label: "Domain Updated", name: "updated_date" },
            { label: "Status", name: "status" }
        ];
        this.setState({ fieldItems: fieldItems });


        var allfinance = await getFinanceForExport();
        this.setState({ financeData: allfinance });

        this.setState({ loader: false });

    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }



    manageColumnsToShow = async (event) => {
        var checkval = event.target.value;
        var checkstat = event.target.checked;


        console.log(this.state.showfieldItems)

        this.setState({
            showfieldItems: {
                ...this.state.showfieldItems,
                [checkval]: checkstat,
            },
        });

    }


    exportCSVData = async (event) => {

        const fields = [
            this.state.showfieldItems.domain === true ? "Domain" : "***",
            this.state.showfieldItems.currency === true ? "Currency" : "***",
            this.state.showfieldItems.totalcost === true ? "Total Cost" : "***",
            this.state.showfieldItems.invoice_number === true ? "Invoice Number" : "***",
            this.state.showfieldItems.registrant_email === true ? "Registrant Email" : "***",
            this.state.showfieldItems.account === true ? "Account" : "***",
            this.state.showfieldItems.invoice_amount === true ? "Invoice Amount" : "***",
            this.state.showfieldItems.transaction_type === true ? "Transaction Type" : "***",
            this.state.showfieldItems.cc_statement_date === true ? "Corp. CC Statement" : "***",
            this.state.showfieldItems.date_of_invoice === true ? "Date of Invoice" : "***",
            this.state.showfieldItems.domainext === true ? "Domain Extension" : "***",
            this.state.showfieldItems.expiry_date === true ? "URL Expiry" : "***",
            this.state.showfieldItems.registrar === true ? "Registrar" : "***",
            this.state.showfieldItems.created_on_date === true ? "Domain Created" : "***",
            this.state.showfieldItems.updated_date === true ? "Domain Updated" : "***",
            this.state.showfieldItems.status === true ? "Status" : "***"
        ];


        const data = this.state.financeData.map(obj => [

            this.state.showfieldItems.domain === true ?
                obj.get('domain') && obj.get('domain').get('domain') ?
                    obj.get('domain').get('name') + obj.get('domain').get('domain').get('name')
                    :
                    obj.get('domain') ?
                        obj.get('domain').get('name')
                        :
                        ""
                :
                "***",
            this.state.showfieldItems.currency === true ?
                obj.get('currency')
                :
                "***",
            this.state.showfieldItems.totalcost === true ?
                parseFloat(obj.get('totalcost')).toFixed(2)
                :
                "***",
            this.state.showfieldItems.invoice_number === true ?
                obj.get('invoice_number')
                :
                "***",
            this.state.showfieldItems.registrant_email === true ?
                obj.get('registrant_email')
                :
                "***",
            this.state.showfieldItems.account === true ?
                obj.get('account')
                :
                "***",
            this.state.showfieldItems.invoice_amount === true ?
                parseFloat(obj.get('invoice_amount')).toFixed(2)
                :
                "***",
            this.state.showfieldItems.transaction_type === true ?
                obj.get('transaction_type')
                :
                "***",
            this.state.showfieldItems.cc_statement_date === true ?
                obj.get('cc_statement_date') ?
                    obj.get('cc_statement_date').toLocaleDateString('en-GB')
                    :
                    ""
                :
                "***",
            this.state.showfieldItems.date_of_invoice === true ?
                obj.get('date_of_invoice') ?
                    obj.get('date_of_invoice').toLocaleDateString('en-GB')
                    :
                    ""
                :
                "***",
            this.state.showfieldItems.domainext === true ?
                obj.get('domain') && obj.get('domain').get('domain') ?
                    obj.get('domain').get('domain').get('name')
                    :
                    ""
                :
                "***",
            this.state.showfieldItems.expiry_date === true ?
                obj.get('domain') && obj.get('domain').get('expiry_date') ?
                    obj.get('domain').get('expiry_date').toLocaleDateString('en-GB')
                    :
                    ""
                :
                "***",
            this.state.showfieldItems.registrar === true ?
                obj.get('domain') && obj.get('domain').get('registrar') ?
                    obj.get('domain').get('registrar').get('name')
                    :
                    ""
                :
                "***",
            this.state.showfieldItems.created_on_date === true ?
                obj.get('domain') && obj.get('domain').get('created_on_date') ?
                    obj.get('domain').get('created_on_date').toLocaleDateString('en-GB')
                    :
                    ""
                :
                "***",
            this.state.showfieldItems.updated_date === true ?
                obj.get('domain') && obj.get('domain').get('updated_date') ?
                    obj.get('domain').get('updated_date').toLocaleDateString('en-GB')
                    :
                    ""
                :
                "***",
            this.state.showfieldItems.status === true ?
                obj.get('status')
                :
                "***",


        ]);



        const filteredData = data.map(item => {
            return item.filter(val => val !== '***')
        });
        const filteredFields = fields.filter(field => field !== '***');

        const csv = Papa.unparse({
            data: filteredData,
            fields: filteredFields
        });

        const blob = new Blob([csv]);
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob, { type: 'text/csv' });
        a.download = 'exportdata.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }




    render() {

        return <div>

            <div className="customcheck greybgcheck" id="showexportcheckgroup">


                {
                    this.state.fieldItems ?
                        this.state.fieldItems.map((dynamicData, key) => {
                            return (
                                <div className="form-check form-check-inline">
                                    <Input type="checkbox" value={dynamicData.name} id={dynamicData.name} name="showitems" onClick={this.manageColumnsToShow} defaultChecked />
                                    <Label for={dynamicData.name}>{dynamicData.label}</Label>
                                </div>
                            )
                        })
                        :
                        null
                }

            </div>

            <div className="text-center">
                {
                    this.state.loader === true ?
                        <div className="btn btn-primary" id="loadbutton">
                            <Loader type="Oval" color="#414241" height={22} width={100} />
                        </div>
                        :
                        <Button type="button" color="primary" id="addbutton" onClick={this.exportCSVData}>
                            <span className="border-left pl-2"></span>
                            Export CSV
                        </Button>
                }
            </div>


        </div>
    }
}

export default exportFinance;
