import jQuery from 'jquery';
var Parse = require('parse');

export const getMasterbrands = async () => {
    var Masterbrands = Parse.Object.extend("Masterbrands");
    var query = new Parse.Query(Masterbrands);
    query.equalTo("status", true);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const getFullPageCount = async () => {
    var Brand = Parse.Object.extend("Brand");
    var query = new Parse.Query(Brand);
    query.equalTo("status", true);
    query.descending("createdAt");
    const count = await query.count();

    return count;
}

export const getSearchResults = async (searchdatas) => {
    var Brand = Parse.Object.extend("Brand");
    var query = new Parse.Query(Brand);
    query.descending("createdAt");
    query.equalTo("status", true);

    if (searchdatas['searchbox']) {
        query.matches("name", searchdatas['searchbox'], 'i');
    }

    if (searchdatas['statsrc'] == "true") {
        query.equalTo("status", true);
    } else if (searchdatas['statsrc'] == "false") {
        query.equalTo("status", false);
    }
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });

}

export const getBrand = async (pagenum, dataperpage, sortitem, sortitemorder) => {
    var skipnum = pagenum - 1;
    var perpage = parseInt(dataperpage);

    var Brand = Parse.Object.extend("Brand");
    var query = new Parse.Query(Brand);
    query.equalTo("status", true);

    if (sortitem !== "") {
        if (sortitemorder === "ascending") {
            query.ascending(sortitem);
        } else {
            query.descending(sortitem);
        }

    } else {
        query.descending('createdAt');
    }

    query.skip(skipnum * perpage);
    query.limit(perpage);

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const hideRecords = async (objectids) => {

    var parseobject = Parse.Object.extend("Brand");
    var query = new Parse.Query(parseobject);
    query.equalTo("objectId", objectids);
    return query.find().then((object) => {
        var realbject = object[0];
        realbject.set("status", false);
        return realbject.save().then((records) => {
            return true;
        }, (error) => {
            console.log(error);
        }).catch(function (error) {
        });

    }, (error) => {
    });


}


export const addRecord = async ({ name, logo, masterbrand, status, description }) => {
    var obj = new Parse.Object("Brand");
    obj.set("name", name);
    obj.set("logo", logo);
    obj.set("description", description);

    if (masterbrand) {
        var Masterbrands = Parse.Object.extend("Masterbrands");
        var masterbrandqry = new Parse.Query(Masterbrands);
        let masterbrandpoint = await masterbrandqry.get(masterbrand);
        obj.set("masterbrand", masterbrandpoint);
    }


    if (status === 'false') {
        obj.set("status", false);
    } else {
        obj.set("status", true);
    }


    return obj.save().then((obj) => {
        return obj;
    }, (error) => {
        return false;
    }).catch(function (error) {
        return false;
    });
}


export const fetchRecordById = async ({ objectid }) => {
    var Brand = Parse.Object.extend("Brand");
    var query = new Parse.Query(Brand);
    query.equalTo("objectId", objectid);
    return query.find().then((records) => {
        return records;
    }, (error) => {
        return false;
    }).catch(function (error) {
        return false;
    });
}


export const updateRecord = async ({ edithiddenid, name, logo, masterbrand, description, status }) => {
    var Brand = Parse.Object.extend("Brand");
    var query = new Parse.Query(Brand);
    query.equalTo("objectId", edithiddenid);

    return query.find().then(async (object) => {

        var realbject = object[0];

        realbject.set("name", name);
        if (logo.length > 0) {
            realbject.set("logo", logo);
        }
        realbject.set("description", description);

        if (masterbrand) {
            var Masterbrands = Parse.Object.extend("Masterbrands");
            var masterbrandqry = new Parse.Query(Masterbrands);
            let masterbrandpoint = await masterbrandqry.get(masterbrand);
            realbject.set("masterbrand", masterbrandpoint);
        }

        if (status === 'false') {
            realbject.set("status", false);
        } else {
            realbject.set("status", true);
        }

        return realbject.save().then((records) => {
            return true;
        }, (error) => {
            return false;
        }).catch(function (error) {
            return false;
        });

    }, (error) => {
        return false;
    });

}




export const checkFieldValid = (addoredit) => {

    var name = jQuery('#name');
    var logofile = jQuery('#logofile');
    var masterbrand = jQuery('#masterbrand');

    var errstat = true;
    if (name.val() === "") {
        name.addClass("is-invalid");
        errstat = false;
    } else {
        name.removeClass("is-invalid");
    }

    if (masterbrand.val() === "") {
        masterbrand.addClass("is-invalid");
        errstat = false;
    } else {
        masterbrand.removeClass("is-invalid");
    }




    if (addoredit === "add") {
        if (logofile.val() === "") {
            logofile.addClass("is-invalid");
            errstat = false;
        } else {
            logofile.removeClass("is-invalid");
        }
    }


    if (errstat === false) {
        return false;
    } else {
        return true;
    }

}

