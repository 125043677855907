// import React from 'react';
import React, { Component, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Row, Col, Carousel, CarouselItem, CarouselControl, Button, Table, Input, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, ModalFooter, Label, Alert, UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem, } from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
import jQuery from 'jquery';
import ScrollArea from 'react-scrollbar';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

 
import { assetExpiryCount } from '../../../services/medialicenselibraries'; 

class Assetexpiry extends React.Component {
  constructor(props) {
    super(props);
 

    this.state = {
      expirycount: 0,
    };

  }

  
  componentDidMount = async () => {
    
    console.log(this.props.count)


    var expcount = await assetExpiryCount();
    this.setState({ expirycount: expcount });
  }

  componentDidUpdate = async (prevProps, prevState) => {
    if (prevProps.objectid !== this.props.objectid  ) {
      console.log("**"+this.props.objectid)
      console.log("**"+this.props.name)
    }

  }


 

  render() {
    return (
      <span className="expiringsoon">8 Expiring in 30 days</span>
    )
  }
}

export default Assetexpiry;