import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormFeedback,
  CustomInput,
  Tooltip,
  UncontrolledTooltip
} from 'reactstrap';
import Select, { components } from "react-select";
import jQuery from 'jquery'

import { getCurrentUser, authenticateLoggedIn, addHeaderTitle, getParameterByName, authenticateLoginpage, addBreadcrumb } from '../../services/parse';
import { updateRecord, checkFieldValid, fetchRecordById, getJurisdictions, getRegions } from '../../services/countries';
import withRouter from '../../components/common/WithRouter';

class editCountry extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      collapse: true,
      fadeIn: true,
      timeout: 300,
      isSuper: '',
      addedclass: 'd-none',
      recordByIdData: [],
      jurisdictionData: [],
      regionData: [],
      regionrelatedData: [],
      relatedObjectsData: [],
      isEdit: false,
      logofile: null,
      rewardsfile: null,
      loginlogofile: null,
    };
  }


  async componentDidMount(evt) {
    //auth start
    authenticateLoggedIn();
    addHeaderTitle('Edit Country');
    addBreadcrumb('Home / Countries / Edit Country');

    // var editobjectid = getParameterByName('objectid');
    var editobjectid = this.props.params.objectid;

    if (editobjectid) {
      fetchRecordById({ objectid: editobjectid }).then((value) => {
        if (value.length > 0) {
          this.setState({ recordByIdData: value[0] });
          this.setState({ isEdit: true });


          var jurisarrays = value[0].attributes.jurisdiction;
          var jurisids = [];
          if (jurisarrays) {
            for (var i = 0; i < jurisarrays.length; i++) {
              jurisids.push(jurisarrays[i].id);
            }
            this.setState({ relatedObjectsData: jurisids });
          }

          var regionarrays = value[0].attributes.region;
          var regionids = [];
          if (regionarrays) {
            for (var i = 0; i < regionarrays.length; i++) {
              regionids.push(regionarrays[i].id);
            }
            this.setState({ regionrelatedData: regionids });
          }



          getJurisdictions().then((value) => {
            this.setState({ jurisdictionData: value, });
          });
          getRegions().then((value) => {
            this.setState({ regionData: value, });
          });

        } else {
          this.setState({ isEdit: false });
        }
      });

    }



  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }


  updateRecord = () => {

    var valid = checkFieldValid('add');
    if (valid !== false) {
      jQuery('#addbutton').prop('disabled', true);

      var hiddenrecordid = jQuery('#hiddenrecordid').val();

      var name = jQuery('#bname').val();
      var country_code = jQuery('#country_code').val();
      var description = jQuery('#bdescription').val();
      var status = jQuery('#status').val();

      var jurisdata = [];
      jQuery.each(jQuery("input[name='jurislist']:checked"), function () {
        jurisdata.push(jQuery(this).val());
      });

      var regiondata = [];
      jQuery.each(jQuery("input[name='regionlist']:checked"), function () {
        regiondata.push(jQuery(this).val());
      });

      updateRecord({ edithiddenid: hiddenrecordid, name: name, country_code: country_code, description: description, status: status, jurisdata: jurisdata, regiondata: regiondata }).then((value) => {
        if (value === true) {
          this.setState({ addedclass: 'd-block' });
        } else {
          this.setState({ addedclass: 'd-none' });
        }

        // jQuery('#addform').trigger("reset");
        jQuery('#addbutton').prop('disabled', false);
      });

    }
  }





  render() {
    return <div>

      {this.state.isEdit === true ?
        <div>
          <Row className="justify-content-between topbuttonbar">
            <Col className="col-md-5">
              <Alert color="success" className={this.state.addedclass}>
                Record Updated
                  <Link to="/countries/countries" className="float-right">
                  &nbsp; Go to list
                  </Link>
              </Alert>
            </Col>
            <Col className="col-auto mb-4">
              <Link to="/countries/countries" className="btn btn-primary btncancle mr-3">
                <span className="border-left pl-2">Cancel</span>
              </Link>
              <Button type="button" color="primary" id="addbutton" onClick={this.updateRecord}>
                <span className="border-left pl-2"></span>
                Save
              </Button>
            </Col>
          </Row>

          <Row>

            <Col md="5">
              <Card>
                <CardTitle>
                  <i className="icon-circle icon-pencil"></i>
                  Edit Country
                </CardTitle>
                <CardBody>


                  <Form id="addform">
                    <Input type="hidden" id="hiddenrecordid" defaultValue={this.state.recordByIdData.id} />
                    <FormGroup className="form-group">
                      <Label htmlFor="name">Name</Label>
                      <Input type="text" name="bname" id="bname" defaultValue={this.state.recordByIdData.get('name')} />
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Label htmlFor="name">Country Code</Label>
                      <Input type="text" name="country_code" id="country_code" defaultValue={this.state.recordByIdData.get('country_code')} />
                    </FormGroup>



                    <FormGroup className="form-group">
                      <Label htmlFor="country">Status</Label>
                      <select name="status" id="status" className="form-control" defaultValue={String(this.state.recordByIdData.get('status'))}>
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                      </select>
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Label htmlFor="description">Description </Label>
                      <Input type="textarea" name="bdescription" id="bdescription" rows="5" placeholder="Add your comments here..." defaultValue={this.state.recordByIdData.get('description')} />
                    </FormGroup>


                    {/* Jurisdiction area */}

                    <FormGroup className="form-group">
                      <Label htmlFor="name">Applicable to Jurisdiction</Label>
                      <div className="customcheck greybgcheck yscroll form-inline">
                        {
                          this.state.jurisdictionData.map((dynamicData, key) =>
                            <div className="form-check form-check-inline" key={key}>
                              <Input type="checkbox" name="jurislist" className="jurislist" value={dynamicData.id} id={dynamicData.id} defaultChecked={this.state.relatedObjectsData.indexOf(dynamicData.id) !== -1} />
                              <Label for={dynamicData.id}>{dynamicData.get('name')}</Label>
                            </div>
                          )}
                      </div>
                    </FormGroup>

                    {/* Region area */}

                    <FormGroup className="form-group">
                      <Label htmlFor="name">Applicable to Region</Label>
                      <div className="customcheck greybgcheck yscroll form-inline">
                        {
                          this.state.regionData.map((dynamicData, key) =>
                            <div className="form-check form-check-inline" key={key}>
                              <Input type="checkbox" name="regionlist" className="regionlist" value={dynamicData.id} id={dynamicData.id} defaultChecked={this.state.regionrelatedData.indexOf(dynamicData.id) !== -1} />
                              <Label for={dynamicData.id}>{dynamicData.get('name')}</Label>
                            </div>
                          )}
                      </div>
                    </FormGroup>


                  </Form>
                </CardBody>
              </Card>

            </Col>
          </Row>
        </div>
        :
        <p></p>
      }

    </div>
  }
}

export default withRouter(editCountry);
