import jQuery from 'jquery';
var Parse = require('parse');

export const getFullPageCount = async () => {
    var User = Parse.Object.extend("User");
    var query = new Parse.Query(User);
    query.descending("createdAt");
    query.equalTo("status", true);
    const count = await query.count();

    return count;
}
export const getUsers = async (pagenum, dataperpage, sortitem, sortitemorder) => {
    var skipnum = pagenum - 1;
    var perpage = parseInt(dataperpage);

    var User = Parse.Object.extend("User");
    var query = new Parse.Query(User);
    query.include("ALJCompany");
    query.include("role");
    query.include("lawfirm");
    query.equalTo("status", true);


    if (sortitem !== "") {
        if (sortitemorder === "ascending") {
            query.ascending(sortitem);
        } else {
            query.descending(sortitem);
        }

    } else {
        query.descending('createdAt');
    }

    query.skip(skipnum * perpage);
    query.limit(perpage);

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const getSearchResults = async (searchdatas) => {
    var User = Parse.Object.extend("User");
    var query = new Parse.Query(User);
    query.include("ALJCompany");
    query.include("role");
    query.include("lawfirm");
    query.descending("createdAt");
    query.equalTo("status", true);

    query.matches("firstName", searchdatas['searchbox'], 'i');
    query.matches("username", searchdatas['emailtext'], 'i');

    if (searchdatas['roletext'] !== "") {
        query.equalTo('role', {
            __type: 'Pointer',
            className: 'AppUserRoles',
            objectId: searchdatas['roletext']
        });
    }

    if (searchdatas['statsrc'] == "true") {
        query.equalTo("status", true);
    } else if (searchdatas['statsrc'] == "false") {
        query.equalTo("status", false);
    }

    return query.find().then((user) => {
        return user;
    }, (error) => {
    });
}

export const hideRecords = async (objectids) => {
    var user = Parse.Object("_User");
    user.id = objectids;

    return user.fetch().then((object) => {
        var realbject = object;
        realbject.set("status", false);
        return realbject.save().then((records) => {
            return true;
        }, (error) => {
            console.log(error);
        }).catch(function (error) {
        });

    }, (error) => {
    });
}

export const isAdmin = async () => {
    var currentUser = Parse.User.current();
    if (currentUser) {
        var User = Parse.Object.extend("User");
        var query = new Parse.Query(User);
        query.include("role");
        query.equalTo("objectId", currentUser.id);

        var userdet = query.find();
    } else {
        var userdet = "";
    }
    return userdet;
}

export const getALJCompanies = async () => {
    var ALJCompanies = Parse.Object.extend("ALJCompanies");
    var query = new Parse.Query(ALJCompanies);
    query.equalTo("status", true);
    query.ascending("name");

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}
export const getLawfirm = async () => {
    var LawFirms = Parse.Object.extend("LawFirms");
    var query = new Parse.Query(LawFirms);
    query.equalTo("status", true);
    query.descending("createdAt");

    var records = query.find();
    return records;
}

//-----------------------------------------------------------------------------------------------------------------
//-----------------------------------------------------------------------------------------------------------------

export const deleteRecord = (objectids) => {
    var user = new Parse.Object('_User');
    if (objectids.length) {
        for (var i = 0, n = objectids.length; i < n; i++) {
            user.id = objectids[i];
            user.fetch().then(function (data) {
                console.log(data);
                data.set("status", false);
                data.save();
            }, function (error) {
                console.log(error);
            });
        }
        return true;
    } else {
        return false;
    }
}

export const checkEmailExist = async (email) => {
    var User = Parse.Object.extend("User");
    var query = new Parse.Query(User);
    query.equalTo("username", email);
    query.descending("createdAt");

    var records = await query.find();

    if (records.length > 0) {
        return true;
    } else {
        return false;
    }
}

export const addRecord = async ({ fname, lname, username, password, email, role, contact, company, lawfirm, tradenotify }) => {

    let User = Parse.Object.extend("User");
    let newUser = new User();

    newUser.set("firstName", fname);
    newUser.set("lastName", lname);
    newUser.set("username", username);
    newUser.set("password", password);
    newUser.set("email", username);

    var rolepoint = new Parse.Object('AppUserRoles');
    rolepoint.id = role;
    newUser.set("role", rolepoint);

    newUser.set("contact", contact);

    if (company) {
        var companypoint = new Parse.Object('ALJCompanies');
        companypoint.id = company;
        newUser.set("ALJCompany", companypoint);
    }

    if (lawfirm) {
        var lawfirmpoint = new Parse.Object('LawFirms');
        lawfirmpoint.id = lawfirm;
        newUser.set("lawfirm", lawfirmpoint);
    }

    newUser.set("trademarkNotification", tradenotify);
    newUser.set("status", true);

    return await newUser.save().then(async (User) => {
        if (User) {



            //Email
            Parse.Cloud.run('sendNewUserWelcomeEmail', {
                toEmail: username,
                password: password,
                name: fname + " " + lname,
            }).then(function (result) {
                /* SUCCESS */
                console.log(result);

            });
            //Email



            return true;
        } else {
            return false;
        }

    }, (error) => {

        console.log(error);
        console.log(error.code);

        if (error.code === 202) {
            return 'userexist';
        }
        if (error.code === 203) {
            return 'emailexist';
        }
        if (error.code === 125) {
            return 'emailinvalid';
        }

    }).catch(function (error) {
        console.log(error);
        console.log(error.code);
        return false;
    });



}





export const getAllUsersCloud = async () => {
    return Parse.Cloud.run('getAllUsers', {
        object_type: "User", // REQUIRED - string: name of your Parse class
        update_at: null, // OPTIONAL - JS Date object: Only retrieve objects where update_at is higher than...
        only_objectId: false // OPTIONAL - boolean: the result will only be composed by objectId + date fields, otherwise all attributes are returned.
    }).then(function (objects) {

        return objects;

    });
}


export const updateUsersCloud = async ({ fname, lname, username,password, email, role, contact, company, lawfirm, tradenotify, status }) => {

    if (status === 'false') {
        var statval = false;
    } else {
        var statval = true;
    }

    return Parse.Cloud.run('saveUserDetails', {
        firstName: fname,
        lastName: lname,
        password: password ? password : null,  // Pass null for values you don't want to update
        email: null, // Cannot be updated as it has to be unique
        username: username,  // Cannot be updated as it has to be unique 
        roleId: role, // Pass null for values you don't want to update 
        contact: contact,
        companyId: company, // Pass null for values you don't want to update
        lawfirmId: lawfirm, // Pass null for values you don't want to update 
        tradenotify: tradenotify,
        status: statval

    }).then(function (result) {

        console.log(result);
        return result;

    });
}


export const addRecordBySheet = async ({ fname, lname, emiratesId, username, password, phone, profilePicture, companyID, company, email }) => {

    let User = Parse.Object.extend("User");
    let newUser = new User();

    newUser.set("firstName", fname);
    newUser.set("lastName", lname);
    newUser.set("emiratesId", emiratesId);
    newUser.set("username", username);
    newUser.set("password", password);
    newUser.set("phone", phone);
    newUser.set("profilePicture", profilePicture);
    newUser.set("companyID", companyID);

    var companypoint = new Parse.Object('Company');
    companypoint.id = company;
    newUser.set("company", companypoint);

    newUser.set("email", email);
    newUser.set("emailId", email);
    newUser.set("status", true);

    return await newUser.save().then((User) => {
        if (User) {
            setEmployeeRole(User.id);
            return 'added - ' + username;
        } else {
            return false;
        }

    }, (error) => {

        if (error.code === 202) {
            return 'userexist - ' + username;
        } else if (error.code === 203) {
            return 'emailexist - ' + email;
        } else {
            return error + ' - ' + username;
        }

    }).catch(function (error) {
        return false;
    });



}



export const setEmployeeRole = (userid) => {


    var user = new Parse.Object('_User');
    user.id = userid;
    user.fetch().then(function (data) {

        var Role = Parse.Object.extend("_Role");
        var query = new Parse.Query(Role);
        query.equalTo("name", "appUser");


        query.find().then((object) => {

            var roleObject = object[0];
            roleObject.getUsers().add(data);

            roleObject.save();

        }, (error) => {
            return false;
        });


    }, function (error) {
    });


}


export const fetchRecordById = async ({ objectid }) => {


    var Store = Parse.Object.extend("User");
    var query = new Parse.Query(Store);
    query.include("role");
    query.include("lawfirm");
    query.include("ALJCompany");
    query.equalTo("objectId", objectid);
    return query.find().then((record) => {
        return record;
    }, (error) => {
        return false;
    }).catch(function (error) {
        return false;
    });
}


// export const updateRecord = async ({ recordhiddenid, fname, lname, role, contact, company }) => {
//     var User = Parse.Object.extend("User");
//     var query = new Parse.Query(User);

//     query.equalTo("objectId", recordhiddenid);

//     return query.find().then(async (object) => {

//         var realbject = object[0];


//         realbject.set("firstName", fname);
//         realbject.set("lastName", lname);

//         var rolepoint = new Parse.Object('AppUserRoles');
//         rolepoint.id = role;
//         realbject.set("role", rolepoint);

//         realbject.set("contact", contact);

//         if (company) {
//             var companypoint = new Parse.Object('ALJCompanies');
//             companypoint.id = company;
//             realbject.set("company", companypoint);
//         }

//         realbject.set("status", true);


//         return realbject.save().then((Company) => {
//             return true;
//         }, (error) => {

//             console.log(error);

//             return false;
//         }).catch(function (error) {
//             return false;
//         });

//     }, (error) => {
//         return false;
//     });

// }

export const editrecordById = ({ objectid }) => {
    var Store = Parse.Object.extend("User");
    var query = new Parse.Query(Store);
    query.include("company");
    query.equalTo("objectId", objectid);
    return query.find().then((company) => {
        return company;
    }, (error) => {
        return false;
    }).catch(function (error) {
        return false;
    });
}


export const checkFieldValid = (addoredit) => {
    var fname = jQuery('#cname');
    var lname = jQuery('#lname');
    var username = jQuery('#username');
    var password = jQuery('#password');
    var email = jQuery('#email');
    var role = jQuery('#role');

    var errstat = true;
    if (fname.val() === "") {
        fname.addClass("is-invalid");
        errstat = false;
    } else {
        fname.removeClass("is-invalid");
    }
    if (lname.val() === "") {
        lname.addClass("is-invalid");
        errstat = false;
    } else {
        lname.removeClass("is-invalid");
    }

    if (username.val() === "") {
        username.addClass("is-invalid");
        errstat = false;
    } else {

        if (addoredit !== "update") {
            var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
            var validemail = pattern.test(username.val());
            if (validemail !== true) {
                username.addClass("is-invalid");
                jQuery('#notvalidemail').removeClass('d-none');
                errstat = false;
            } else {
                jQuery('#notvalidemail').addClass('d-none');
                username.removeClass("is-invalid");
            }
        } else {
            username.removeClass("is-invalid");
        }

    }

    if (addoredit !== "update") {
        if (password.val() === "") {
            password.addClass("is-invalid");
            errstat = false;
        } else {
            password.removeClass("is-invalid");
        }
    }
    if (email.val() === "") {
        email.addClass("is-invalid");
        errstat = false;
    } else {
        email.removeClass("is-invalid");
    }

    if (role.val() === "") {
        role.addClass("is-invalid");
        errstat = false;
    } else {
        role.removeClass("is-invalid");
    }

    if (errstat === false) {
        return false;
    } else {
        return true;
    }

}

