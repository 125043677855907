import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Alert,
  InputGroup,
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import Select, { components } from "react-select";
import fileIcon from '../../assets/images/icons/file-icon.png';
import imageIcon from '../../assets/images/icons/image-icon.png';
import jQuery from 'jquery'
import FileUpload from '../../components/common/FileUpload';

import { getCurrentUser, authenticateLoggedIn, addHeaderTitle, getParameterByName, authenticateLoginpage, addBreadcrumb } from '../../services/parse';
import { uploadFile, getFileUrl } from '../../services/awsFileUpload';
import { updateRecord, checkFieldValid, fetchRecordById, getImagesList, deleteImagesList } from '../../services/classifications';
import withRouter from '../../components/common/WithRouter';

var Parse = require('parse');

class editClassification extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      collapse: true,
      fadeIn: true,
      timeout: 300,
      isSuper: '',
      addedclass: 'd-none',
      recordByIdData: [],
      countryData: [],
      imagesById: [],
      logoFileArray: [],
      isEdit: false,
      logofile: null,
      rewardsfile: null,
      loginlogofile: null,
    };
  }


  async componentDidMount(evt) {
    //auth start
    authenticateLoggedIn();
    addHeaderTitle('Edit Classification');
    addBreadcrumb('Home / Classifications / Edit Classification');

    // var editobjectid = getParameterByName('objectid');
    var editobjectid = this.props.params.objectid;

    if (editobjectid) {
      fetchRecordById({ objectid: editobjectid }).then((value) => {
        if (value.length > 0) {
          this.setState({ recordByIdData: value[0] });
          this.setState({ isEdit: true });

          if (value[0].get('logoFiles') !== undefined) {
            this.setState({ logoFileArray: value[0].get('logoFiles') });
          }

        } else {
          this.setState({ isEdit: false });
        }
      });

    }

  }

  toggle() {
    // this.setState({
    //   tooltipOpen: !this.state.tooltipOpen
    // });
    this.setState({
      modal: !this.state.modal
    });
  }

  onLogoFileSelected = async (event) => {
    console.log(event.target.files);
    var files = event.target.files
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        var fileType = files[i].type.split("/")[1];
        var fileName = files[i].name;
        console.log(fileType);
        var uploadStatus = await uploadFile('contentmaster', files[i]);
        console.log(uploadStatus);
        if (uploadStatus.success) {
          this.setState({
            logoFileArray: [
              ...this.state.logoFileArray,
              { awsFileName: uploadStatus.filename, type: fileType, name: fileName }
            ]
          });
        }
      }
    }
    //var logoUpload = await uploadFile('trademark',event.target.value);
  }


  updateRecord = () => {

    var valid = checkFieldValid('add');
    if (valid !== false) {
      jQuery('#addbutton').prop('disabled', true);

      var hiddenrecordid = jQuery('#hiddenrecordid').val();

      var name = jQuery('#bname').val();
      var status = jQuery('#status').val();
      var category = jQuery('#category').val();
      var reference_link = jQuery('#reference_link').val();
      var description = jQuery('#bdescription').val();
      var notes = jQuery('#notes').val();
      var status = jQuery('#status').val();

      var fielddatas = {
        edithiddenid: hiddenrecordid,
        name: name,
        status: status,
        category: category,
        reference_link: reference_link,
        description: description,
        notes: notes,
        status: status,
        imagefiles: this.state.logoFileArray
      };
      updateRecord(fielddatas).then((value) => {
        if (value === true) {
          this.setState({ addedclass: 'd-block' });

        } else {
          this.setState({ addedclass: 'd-none' });
        }

        // jQuery('#addform').trigger("reset");
        jQuery('#addbutton').prop('disabled', false);
      });

    }
  }

  render() {

    return <div>

      {this.state.isEdit === true ?
        <div>
          <Row className="justify-content-between topbuttonbar">
            <Col className="col-md-5">
              <Alert color="success" className={this.state.addedclass}>
                Record Updated
                    <Link to="/classification/classification" className="float-right">
                  &nbsp; Go to list
                    </Link>
              </Alert>
            </Col>
            <Col className="col-auto mb-4">
              <Link to="/classification/classification" className="btn btn-primary btncancle mr-3">
                <span className="border-left pl-2">Cancel</span>
              </Link>
              <Button type="button" color="primary" id="addbutton" onClick={this.updateRecord}>
                <span className="border-left pl-2"></span>
                Save
              </Button>
            </Col>
          </Row>

          <Row>

            <Col md="5">
              <Card>
                <CardTitle>
                  <i className="icon-circle icon-pencil"></i>
                  Edit Classification
                </CardTitle>
                <CardBody>





                  <Form id="addform">
                    <Input type="hidden" id="hiddenrecordid" defaultValue={this.state.recordByIdData.id} />
                    <FormGroup className="form-group">
                      <Label htmlFor="name">Name</Label>
                      <Input type="text" name="bname" id="bname" defaultValue={this.state.recordByIdData.get('name')} />
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Label htmlFor="name">Category</Label>
                      <Input type="text" name="category" id="category" defaultValue={this.state.recordByIdData.get('category')} />
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Label htmlFor="name">Reference Link</Label>
                      <Input type="text" name="reference_link" id="reference_link" defaultValue={this.state.recordByIdData.get('reference_link')} />
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Label htmlFor="description">Description </Label>
                      <Input type="textarea" name="bdescription" id="bdescription" rows="5" placeholder="Add your comments here..." defaultValue={this.state.recordByIdData.get('description')} />
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Label htmlFor="description">Notes</Label>
                      <Input type="textarea" name="notes" id="notes" rows="5" placeholder="Add your notes" defaultValue={this.state.recordByIdData.get('notes')} />
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Label htmlFor="country">Status</Label>
                      <select name="status" id="status" className="form-control" defaultValue={String(this.state.recordByIdData.get('status'))}>
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                      </select>
                    </FormGroup>


                    {/* Images area */}

                    <FileUpload labelText="Upload Files" tooltipText="Hello world!" folderName="contentmaster"
                      isValid={true} inputText="Upload your file here" items={this.state.logoFileArray}
                      name="inputGroupFile01"
                      onDelete={(id) => {
                        var logoArray = this.state.logoFileArray.filter(obj => obj.id != id).map((item, index) => { return { ...item, id: index } });
                        this.setState({ logoFileArray: logoArray }, () => {
                        });
                      }}
                      addItems={(items) => {
                        console.log('addItem', items);
                        this.setState({
                          logoFileArray: [...this.state.logoFileArray, ...items]
                        }, () => {
                          console.log(this.state.logoFileArray);
                        });
                      }}
                      updateItem={(id, filename) => {
                        var objToChange = this.state.logoFileArray.find(x => x.id == id);
                        if (objToChange) {
                          this.setState({
                            logoFileArray: [...this.state.logoFileArray.filter(x => x.id != id),
                            { ...this.state.logoFileArray.find(x => x.id == id), awsFileName: filename }
                            ]

                          }, () => { console.log(this.state.logoFileArray); });
                        }
                      }}
                      showError={(message) => {
                        this.setState({ showAlertMessage: true, alertMessage: message })
                      }}
                    />

                    {/* <InputGroup>
                      <div className="custom-file">
                        <Input type="file" className="custom-file-input" id="inputGroupFile01" onChange={(event) => { this.onLogoFileSelected(event) }} multiple />
                        <label className="custom-file-label" htmlFor="inputGroupFile01">Upload your file here</label>
                      </div>

                    </InputGroup>

                    {
                      this.state.logoFileArray.length > 0 ?
                        this.state.logoFileArray.map((item, index) => {
                          console.log(item)
                          return (
                            <div className="galleryWrap">
                              <div className="galleryitem" key={item.awsFileName}>
                                <button type="button" className="deletebutton"
                                  onClick={() => {
                                    this.setState({
                                      logoFileArray: this.state.logoFileArray.filter(obj => obj.awsFileName != item.awsFileName)
                                    });
                                  }}>
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                                <div className="imgbx" >
                                  <div>
                                    <a href="javascript:" target="_blank" onClick={async () => {
                                      var res = await getFileUrl('contentmaster', item.awsFileName);
                                      if (res && res.success == 1) {
                                        window.open(res.url);
                                      }
                                      console.log(res);
                                    }}>
                                      <img src={(item.type === "jpg" || item.type === "png" || item.type === "jpeg") ? imageIcon : fileIcon} alt="Gallery Image" width="50" />
                                    </a>
                                  </div>
                                </div>
                                <div style={{ color: 'black' }}>{item.name}</div>
                              </div>
                            </div>
                          )
                        }
                        )
                        : null
                    } */}



                  </Form>
                </CardBody>
              </Card>

            </Col>
          </Row>
        </div>
        :
        <p></p>
      }

    </div>
  }
}

export default withRouter(editClassification);
