import React from "react";
import {
  Row,
  Col,
  Form,
  CustomInput,
  Input,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import ScrollArea from 'react-scrollbar';
import jQuery from 'jquery';
import { isAdmin } from '../../../services/user';


class Model extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.state = {
      modal: false,
      backdrop: true,
      showbutton: true,
      currentRole:null
    };

    this.toggle5 = this.toggle5.bind(this);
  }


  componentWillMount =async()=>{
    var isadminval = await isAdmin();
		if (isadminval) {
			var currentrole = isadminval[0].get('role').get('name');
			if (currentrole) {
				//this.setState({ currentRole: "Legal" });
				this.setState({ currentRole: currentrole });
			}
		} else {
			this.setState({ currentRole: "" });
		}
  }


  toggle5() {
    this.setState({
      modal5: !this.state.modal5
    });
  }

  showButton = (e) => {
    // var showcheck = document.getElementById("terms").checked;
    // if (showcheck === true) {
    //   this.setState({ showbutton: '' });
    // } else {
    //   this.setState({ showbutton: 'disabled' });
    // }

    var check = e.target.checked;
    if (check === true) {
      this.setState({ showbutton: false });
      jQuery(e.target).addClass('active');
    } else {
      this.setState({ showbutton: true });
      jQuery(e.target).removeClass('active');
    }

  }

  setCookie = () => {

    var today = new Date();
    var expire = new Date();
    expire.setTime(today.getTime() + 3600000 * 24 * 14);  // 14 days

    document.cookie = "cookieuser=yes; expires=" + expire.toGMTString();

    // document.cookie = "cookieuser=yes";

    document.getElementById("modeltop").style.display = "none";
    document.getElementById("modelbg").style.display = "none";

  }

  render() {
    return (
      this.state.currentRole  && this.state.currentRole!="Executive"?
      <div>

      <div className="custommodal fade show" id="modeltop">
        <div className="modaldialogbx">
          <div className="modalbx fadeInDown">
            <div className="modal-content">
              <ModalHeader>Please agree the terms to proceed</ModalHeader>
              <ModalBody>
                <ScrollArea smoothScrolling={0.8} style={{ maxHeight: '250px' }}>

                  <p><strong>PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING THIS APPLICATION (APP) FOR MOBILE DEVICES.  ACCESS TO AND USE OF THIS APP IS GOVERNED STRICTLY BY THESE TERMS OF USE.  IF YOU DO NOT AGREE WITH ANY OF THESE TERMS YOU MUST IMMEDIATELY DISCONTINUE USE OF THIS APP.  PLEASE NOTE IN PARTICULAR THE LIMITATION OF LIABILTY AND EXCLUSION OF WARRANTIES CLAUSES SET OUT BELOW.</strong></p>

                  <h5>Terms of Use</h5>
                  <p>This Application provides information and administrative management functions for the Trademarks and Domain Names of <strong>Abdul Latif Jameel</strong> and is intended for <strong>AUTHORIZED INTERNAL USE ONLY.</strong></p>
                  <p>The term “<strong>Abdul Latif Jameel</strong>” refers broadly to several distinct, separate and independent legal entities whose interests encompass car import and distribution, heavy equipment, consumer financing, consumer goods, real estate, environment and energy. Abdul Latif Jameel is not itself a corporate entity, association or conglomerate run by an overarching parent company but merely refers to a group of distinct and wholly separate legal entities that are collectively referred to as Abdul Latif Jameel. Abdul Latif Jameel is not a corporate group as defined in section 1161(5) of the Companies Act 2006.</p>
                  <p>&nbsp;</p>
                  <p>This page (together with the documents referred to in it) informs you of the Terms of Use on which you may make use of the <strong>Abdul Latif Jameel Intellectual Property Administration Web-based Application (“ALJIPMGTAPP”)</strong> and the Abdul Latif Jameel website at the domain &lt;<strong>alj.com</strong>&gt; (the <strong>“Abdul Latif Jameel Website”</strong>).  Use of the <strong>ALJIPMGTAPP</strong> includes accessing, browsing, linking to or registering to use the <strong>ALJIPMGTAPP</strong>.</p>
                  <p>The <strong>ALJIPMGTAPP</strong> is an web-based application owned and operated by Abdul Latif Jameel IPR Company Limited, a limited company registered in Jersey under company number 111739 and whose registered office is 15 Esplanade St Helier Jersey JE1 1RB (the “<strong>Company</strong>”).</p>
                  <p>The Company has sole responsibility for the content included on the <strong>ALJIPMGTAPP</strong>. </p>
                  <p>Please read these Terms of Use very carefully before you start to use the <strong>ALJIPMGTAPP</strong>, as these Terms of Use will apply to your use of and access to the <strong>ALJIPMGTAPP </strong>and the<strong> ALJ Website</strong>.  It is recommended you print a copy of these Terms of Use for future reference.</p>
                  <p>Each time you use the <strong>ALJIPMGTAPP</strong>, you confirm you accept these Terms of Use and you agree to comply fully with them.  If you do not agree to these Terms of Use, you must not use or access the <strong>ALJIPMGTAPP</strong> and must leave the <strong>ALJIPMGTAPP</strong> immediately.</p>
                  <p>These Terms of Use may be revised by us at any time by amending this page.  Please check this page from time to time to note any changes made as they are binding on you as soon as you access the <strong>ALJIPMGTAPP</strong>.</p>
                  <h5>Information about Abdul Latif Jameel</h5>
                  <p>For any enquiries related to any particular entity mentioned on the <strong>ALJIPMGTAPP</strong> or for any specific enquiries related to Abdul Latif Jameel’s interests in vehicle import and distribution, equipment, consumer financing, consumer goods, real estate, environment and energy please email <a href="mailto:info@alj.com">info@alj.com</a>.</p>
                  <p>The actual corporate arrangements of the companies permitted under licence to display the Abdul Latif Jameel name and logotype and those of Abdul Latif Jameel are complex and varied.  Such corporate arrangements are not intended to be, nor are dealt with in detail via the information on the <strong>ALJIPMGTAPP</strong>.  The information is provided as an approximate guide only. </p>
                  <h5>Other applicable terms</h5>
                  <p>These Terms of Use refer to the following additional terms, which also apply to your use of the <strong>ALJIPMGTAPP</strong> and which, by using the <strong>ALJIPMGTAPP</strong>, you agree to comply with:</p>
                  <ol>
                    <li><a href="https://www.alj.com/en/privacy-policy/" target="_blank">Privacy Policy</a>, which sets out the terms on which any personal data collected (if any) from you, or which you provide, via the <strong>ALJIPMGTAPP</strong> is processed.</li>
                    <li><a href="https://www.alj.com/en/cookie-policy/" target="_blank">Cookie Policy</a>, which sets out information about the use of cookies on the <strong>ALJIPMGTAPP</strong>.</li>
                  </ol>
                  <h5>Accessing and using the ALJIPMGTAPP</h5>
                  <p>It is not guaranteed that the <strong>ALJIPMGTAPP</strong>, or any content on it, will always be available or be uninterrupted.  Access to the <strong>ALJIPMGTAPP</strong> is permitted on a temporary “as is” basis.  All or any part of the <strong>ALJIPMGTAPP</strong>, without notice, may be suspended, withdrawn, discontinued or changed.  The Company will not be liable to you if for any reason the <strong>ALJIPMGTAPP</strong> is unavailable (temporarily or permanently) at any time or for any period.</p>
                  <p>You are responsible for making all arrangements necessary for you to have access to the <strong>ALJIPMGTAPP</strong>.  You are also responsible for ensuring that all persons who access the <strong>ALJIPMGTAPP</strong> through your Internet or cellular connection are made fully aware of these Terms of Use and other applicable terms and conditions, and that they comply fully with them.</p>
                  <p>You may use the <strong>ALJIPMGTAPP</strong> only for lawful purposes.  You acknowledge and agree you may not use the <strong>ALJIPMGTAPP</strong>:</p>
                  <ol>
                    <li>In any way that breaches any applicable local, national or international law or regulation.</li>
                    <li>In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect.</li>
                  </ol>
                  <ul>
                    <li>For the purpose of harming or attempting to harm minors in any way.</li>
                  </ul>
                  <ol>
                    <li>To send, knowingly receive, upload, download, use or re-use any material which does not comply with the Content Standards (see below).</li>
                    <li>To post advertisements or to promote third party websites.</li>
                    <li>To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam).</li>
                  </ol>
                  <ul>
                    <li>To transmit any data, send or upload any material that contains viruses, Trojans, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.</li>
                  </ul>
                  <p>You also acknowledge and agree:</p>
                  <ol>
                    <li>Not to reproduce, duplicate, copy or re-sell any part of the <strong>ALJIPMGTAPP</strong> in contravention of the provisions of these Terms of Use.</li>
                    <li>Not to direct traffic away from the <strong>ALJIPMGTAPP</strong>.</li>
                  </ol>
                  <ul>
                    <li>Not to access without authority, interfere with, damage or disrupt:

<ol>
                        <li>any part of the <strong>ALJIPMGTAPP</strong>;</li>
                        <li>any equipment (including any server) or network on which the <strong>ALJIPMGTAPP</strong> is stored;</li>
                        <li>any software used in the provision of the <strong>ALJIPMGTAPP</strong>;</li>
                        <li>any server, computer or database connected to the <strong>ALJIPMGTAPP</strong>; or</li>
                        <li>any equipment or network or software owned or used by any third party.</li>
                      </ol>
                    </li>
                  </ul>
                  <ol>
                    <li>Not to misuse the <strong>ALJIPMGTAPP</strong> by knowingly introducing viruses, Trojans, worms, logic bombs or other material which is malicious or technologically harmful.</li>
                    <li>Not to attack the <strong>ALJIPMGTAPP</strong> via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990.  The Company may report any such breach to the relevant law enforcement authorities and will co-operate with those authorities by disclosing your identity to them.  In the event of such a breach, your right to use the <strong>ALJIPMGTAPP</strong> may be ceased immediately.</li>
                    <li>Not to disguise or interfere in any way with the IP address of the computer you are using to access the <strong>ALJIPMGTAPP</strong> or otherwise take steps to prevent the identification of the actual IP address of the computer you are using whilst accessing the <strong>ALJIPMGTAPP</strong>.</li>
                  </ol>
                  <p>The Company does not guarantee that the <strong>ALJIPMGTAPP</strong> will be secure or free from bugs, viruses or other technologically harmful material.  You are responsible for configuring your information technology, computer programmes, software and platform in order to access the <strong>ALJIPMGTAPP</strong> in a manner you deem appropriate and secure.  You should use your own virus protection software.</p>
                  <h5>Security</h5>
                  <p>Internet or cellular transmissions are never completely private or secure and there is a risk, therefore, that any message or information sent via the ALJIPMGTAPP should be treated as non-confidential and may be intercepted and potentially read by others.  The Company will have no liability to you in respect of any transmissions you send to it and you do so entirely at your own risk.</p>
                  <h5>Intellectual property rights and using the content of the ALJIPMGTAPP</h5>
                  <p>The Company is the owner or the licensee of the <strong>ALJIPMGTAPP</strong>, all content of the <strong>ALJIPMGTAPP</strong>, all the material published on the <strong>ALJIPMGTAPP</strong>, any newsletter, updates, e-shots and emails and any social media or informational updates and/or postings (“<strong>Content</strong>”), and all intellectual property rights in the Content.  The Content is protected by copyright laws and treaties around the world.  All such rights are fully reserved by the Company.</p>
                  <p>Abdul Latif Jameel IPR Company Limited and Abdul Latif Jameel businesses own rights throughout the world in various trade marks (registered and unregistered), service marks, graphics, logos, designs, page headers, button icons, scripts and service names (“<strong>ALJ Trade Marks</strong>”). </p>
                  <p>Any unauthorised use of any of the ALJ Trade Marks will constitute a violation of Abdul Latif Jameel IPR Company Limited and Abdul Latif Jameel rights in these Trade Marks.  Your use of the <strong>ALJIPMGTAPP</strong> grants you no right or licence to reproduce or otherwise use any Abdul Latif Jameel IPR Company Limited, or Abdul Latif Jameel Trade Marks or third party trademarks.</p>
                  <p>Any copying or downloading of any Content will not transfer title to that Content to you nor grant you any licence to use that Content for non-personal purposes.  You must not use any part of the Content for non-personal purposes, such as commercial purposes, without obtaining a prior written licence to do so from the Company, which will be granted in the Company’s absolute discretion. </p>
                  <p>You must not exploit the whole or part of any Content without the Company’s prior written permission (to be determined in its absolute discretion) including (but not limited to):</p>
                  <ol>
                    <li>you must not modify copies of any Content you have printed off or downloaded in any way;</li>
                    <li>you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text;</li>
                  </ol>
                  <ul>
                    <li>you must not remove any copyright or other proprietary notices from copied or downloaded Content;</li>
                  </ul>
                  <ol>
                    <li>you must not use the Content in any way which is fraudulent or unlawful;</li>
                    <li>you must not rely on the Content as being entirely accurate, complete or up to date; and/or</li>
                    <li>you must not use the Content in a manner which does not comply fully with these terms of use.</li>
                  </ol>
                  <p>If you print off, copy, download, modify or link any part of the Content available via the <strong>ALJIPMGTAPP</strong> in breach of these terms of use, your right to use the <strong>ALJIPMGTAPP</strong> may be ceased immediately and you must, at the Company’s option, return or destroy any copies (electronic or otherwise) of the materials you have made.</p>
                  <h5>Links</h5>
                  <p>Where the <strong>ALJIPMGTAPP</strong> contains links to other sites and resources provided by third parties, these links are provided for your information only.  The Company has, unless explicitly indicated otherwise, no control over the content of those sites or resources and has no rights in the content of those sites or in the resources.  The Company assumes no responsibility for the content of websites linked on the <strong>ALJIPMGTAPP</strong>.  Such links should not be interpreted as endorsement by the Company or Abdul Latif Jameel of those linked websites.</p>
                  <p>You must not link to the <strong>ALJIPMGTAPP</strong> home page without the Company’s prior written permission to be determined in its absolute discretion.  The <strong>ALJIPMGTAPP</strong> must not be framed on any other site.</p>
                  <p>In the event you are granted prior written permission to link to the <strong>ALJIPMGTAPP</strong> home page, you must:</p>
                  <ol>
                    <li>only link to the <strong>ALJIPMGTAPP</strong> home page;</li>
                    <li>only link in a way that is fair and legal and does not damage the Company’s reputation nor takes advantage of that reputation;</li>
                  </ol>
                  <ul>
                    <li>not establish a link in such a way as to suggest any form of association, approval or endorsement on the part of the Company where none exists;</li>
                  </ul>
                  <ol>
                    <li>not establish a link to the <strong>ALJIPMGTAPP</strong> in any website not owned by you; and</li>
                    <li>ensure the website or application for mobile devices in which you are linking must comply in all respects with the Content Standards (see below).</li>
                  </ol>
                  <p>The Company reserves the right to withdraw linking permission without notice.</p>
                  <h5>Interactive Services</h5>
                  <p>From time to time, informational updates and other interactive services may be provided on the ALJIPMGTAPP (“Interactive Services” or “Platform”).  The Platform will provide information about the Company‘s goods and services.  The Platform is operated, controlled and moderated by the Abdul Latif Jameel IPR Company Limited’s marketing team.  The Company does not review and approve content included within the Platform prior to such content going live.  The Company does not actively monitor and will not censor content, except in circumstances detailed in the Content Standards (see below).</p>
                  <p>Where another Interactive Service is provided, clear information will be provided about the kind of service offered, if it is moderated and what form of moderation is used (including whether it is human or technical). </p>
                  <p>There is no obligation to oversee, monitor or moderate any Interactive Service and liability for any loss or damage arising from the use of any Interactive Service by a user in contravention of the Content Standards (see below) is expressly excluded, whether the Interactive Service is moderated or not.  However, attempts will be made to assess possible risks for users (and in particular, for children) from third parties when they use any Interactive Service.  Also, it will be decided in each case whether it is appropriate to use moderation of the relevant service (including what kind of moderation to use) in the light of those risks.</p>
                  <p>The use of any of the Interactive Services by a minor is subject to the consent of their parent or guardian.  Parents are advised that it is important they communicate with their children about their safety online, as moderation is not fool-proof.  Minors who are using any Interactive Service should be made aware of the potential risks to them.  Please refer to the terms below which address use of the ALJIPMGTAPP by children.</p>
                  <p>Where an Interactive Service is moderated, you can contact us via email at <a href="mailto:info@alj.com">info@alj.com</a> should a concern or difficulty arise with the Interactive Service or its content.</p>
                  <h5>Content Standards</h5>
                  <p>The content standards below (“Content Standards”) apply to any and all material which you contribute to the ALJIPMGTAPP (“Contribution”), and to any Interactive Services associated with it.  You must comply with the spirit and the letter of the Content Standards.  The Content Standards apply to each part of any Contribution as well as to its whole.</p>
                  <p>Contributions must:</p>
                  <ol>
                    <li>be accurate (where they state facts).</li>
                    <li>be genuinely held (where they state opinions).</li>
                  </ol>
                  <ul>
                    <li>comply with applicable law in the Saudi Arabia and in any country from which they are posted.</li>
                  </ul>
                  <p>Contributions must not:</p>
                  <ol>
                    <li>contain any material which is defamatory of any person.</li>
                    <li>contain any material which is obscene, offensive, hateful or inflammatory.</li>
                  </ol>
                  <ul>
                    <li>contain profanity or other material which would upset or offend others.</li>
                  </ul>
                  <ol>
                    <li>promote sexually explicit material.</li>
                    <li>promote violence.</li>
                    <li>promote discrimination based on race, sex, culture, religion, nationality, disability, sexual orientation or age.</li>
                  </ol>
                  <ul>
                    <li>infringe any copyright, database right, trade mark or other intellectual property right of any other person.</li>
                    <li>be likely to deceive any person.</li>
                  </ul>
                  <ol>
                    <li>be made in breach of any legal duty owed to a third party, such as a contractual duty or a duty of confidence.</li>
                    <li>promote any illegal activity.</li>
                    <li>be threatening, abuse or invade another’s privacy, or cause annoyance, inconvenience or needless anxiety.</li>
                  </ol>
                  <ul>
                    <li>be likely to harass, upset, embarrass, alarm or annoy any other person.</li>
                    <li>be used to impersonate any person, or to misrepresent your identity or affiliation with any person.</li>
                    <li>give the impression that they emanate from or are endorsed by Abdul Latif Jameel or any of its businesses, if this is not the case.</li>
                  </ul>
                  <ol>
                    <li>advocate, promote or assist any unlawful act such as (by way of example only) copyright infringement or computer misuse.</li>
                  </ol>
                  <ul>
                    <li>engage in spam, malware, phishing or what may constitute spamming based on the latest tricks or tactics of spammers.</li>
                  </ul>
                  <h5>Suspension and Termination</h5>
                  <p>The Company will determine, in its discretion, whether there has been a breach of these terms of use through your use of the ALJIPMGTAPP.  When a breach of these terms of use has occurred, the Company (or any other Abdul Latif Jameel business) may take such action as it deems appropriate.  Failure to comply with the Content Standards constitutes a material breach of these terms of use upon which you are permitted to use the ALJIPMGTAPP. </p>
                  <p>A breach of these terms of use may result in the Company (or any other Abdul Latif Jameel business), at its complete discretion, taking all or any of the following actions:</p>
                  <ol>
                    <li>immediate, temporary or permanent suspension and/or withdrawal of your right to use the ALJIPMGTAPP.</li>
                    <li>immediate, temporary or permanent removal of any posting or material uploaded by you to the ALJIPMGTAPP.</li>
                  </ol>
                  <ul>
                    <li>issue of a warning to you.</li>
                  </ul>
                  <ol>
                    <li>legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not limited to, reasonable administrative and legal costs) resulting from the breach.</li>
                    <li>further legal action against you.</li>
                    <li>disclosure of such information to law enforcement authorities.</li>
                  </ol>
                  <p>All liability is excluded for actions taken by the Company and/or any other Abdul Latif Jameel business in response to breaches of these terms of use.  The responses described in this policy are not limited, and other actions may be taken by the Company.</p>
                  <p>To report and alleged violation of the Content Standards, please email us at <a href="mailto:info@alj.com">info@alj.com</a>.</p>
                  <p>&nbsp;</p>
                  <h5>No reliance on information</h5>
                  <p>The <strong>ALJIPMGTAPP</strong> provides brief information and an overview about Abdul Latif Jameel.  The <strong>ALJIPMGTAPP</strong> provides no information which can be used for investment and/or transactional decisions.  The Content is provided for information only and is of a general and approximate nature and it is not intended to amount to, nor is, information or advice on which you should rely.  In particular the Content does not constitute professional, financial or investment advice.  Professional or specialist advice should be obtained before taking, or refraining from, any action on the basis of the Content.</p>
                  <p>In providing information about Abdul Latif Jameel, the Company is not acting, nor is it seeking to act, as a broker or dealer in respect of any securities of any companies or the information concerning such companies. </p>
                  <p>It is not guaranteed that the <strong>ALJIPMGTAPP</strong>, or any content on it, will be free from errors, inaccuracies or omissions.  It is your responsibility to check any Content is accurate and/or complete.</p>
                  <p>The <strong>ALJIPMGTAPP</strong> may be updated from time to time, and may change the Content at any time.  Although the Company makes reasonable efforts to update the information on the <strong>ALJIPMGTAPP</strong>, it makes no representations, warranties or guarantees, whether express or implied, that the Content is accurate, complete or up-to-date.  Please note the Company is under no obligation to update the Content.</p>
                  <p>No offer</p>
                  <p>Nothing on the ALJIPMGTAPP should be construed as a solicitation or offer, or recommendation, to acquire or dispose of any investment or to engage in any other transaction, or to provide any investment advice or service.</p>
                  <h5>Local regulatory restrictions</h5>
                  <p>The ALJIPMGTAPP is not directed at any person in any jurisdiction where (by reason of nationality, residence or otherwise) the access to or availability of the ALJIPMGTAPP is prohibited or which would be subject to any restriction, including registration or other requirements within such jurisdiction.  We reserve the right to limit access to the ALJIPMGTAPP to any such persons.  Persons who access the ALJIPMGTAPP do so on their own initiative and are responsible for compliance with applicable local laws and regulations.  If in doubt, you should seek independent legal advice.</p>
                  <h5>LIMITATION OF OUR LIABILITY</h5>
                  <p><strong>NOTHING IN THESE TERMS OF USE EXCLUDES OR LIMITS ANY ALJIB LIABILITY FOR DEATH OR PERSONAL INJURY ARISING FROM NEGLIGENCE, OR FRAUD OR FRAUDULENT MISREPRESENTATION, OR ANY OTHER LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED BY ENGLISH LAW.</strong></p>
                  <p><strong>THE COMPANY WILL NOT BE LIABLE TO ANY USER FOR ANY LOSS OR DAMAGE, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), BREACH OF STATUTORY DUTY, OR OTHERWISE, EVEN IF FORESEEABLE, ARISING UNDER OR IN CONNECTION WITH:</strong></p>
                  <ol>
                    <li><strong>USE OF, OR INABILITY TO USE, THE ALJIPMGTAPP; </strong></li>
                    <li><strong>USE OF OR RELIANCE ON ANY CONTENT DISPLAYED ON, OR MATERIAL PROVIDED VIA, THE ALJIPMGTAPP; </strong></li>
                  </ol>
                  <ul>
                    <li><strong>USE OF OR RELIANCE ON ANY CONTENT DISPLAYED ON, OR MATERIAL PROVIDED VIA, WEBSITES LINKED TO THE ALJIPMGTAPP INCLUDING WITHOUT LIMITATION THE CONTENT; </strong></li>
                  </ul>
                  <ol>
                    <li><strong>LOSS OR DAMAGE CAUSED BY A VIRUS, DISTRIBUTED DENIAL-OF-SERVICE ATTACK, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE ALJIPMGTAPP OR ANY WEBSITE LINKED TO IT, OR TO YOUR DOWNLOADING OF ANY CONTENT OR MATERIAL ON IT, OR ON ANY WEBSITE LINKED TO IT; OR </strong></li>
                    <li><strong>USE BY A PERSON IN A JURISDICTION WHERE THE ACCESS TO OR AVAILABILITY OF THE ALJIPMGTAPP IS PROHIBITED OR SUBJECT TO ANY RESTRICTION.</strong></li>
                  </ol>
                  <p><strong>IF YOU ARE A BUSINESS USER, PLEASE NOTE THE ALJIPMGTAPP IS PROVIDED FOR PERSONAL USE ONLY AND IN ADDITION THE COMPANY ALSO WILL NOT BE LIABLE FOR:</strong></p>
                  <ol>
                    <li><strong>LOSS OF PROFITS, SALES, BUSINESS, OR REVENUE;</strong></li>
                    <li><strong>BUSINESS INTERRUPTION;</strong></li>
                  </ol>
                  <ul>
                    <li><strong>LOSS OF DATA;</strong></li>
                  </ul>
                  <ol>
                    <li><strong>LOSS OF ANTICIPATED SAVINGS;</strong></li>
                    <li><strong>LOSS OF BUSINESS OPPORTUNITY, GOODWILL OR REPUTATION; OR</strong></li>
                    <li><strong>ANY INDIRECT OR CONSEQUENTIAL LOSS OR DAMAGE.</strong></li>
                  </ol>
                  <h5>NO WARRANTIES</h5>
                  <p><strong>THE ALJIPMGTAPP IS PROVIDED "AS IS" AND NO WARRANTIES OF ANY KIND ARE GIVEN EXPRESS OR IMPLIED IN RESPECT OF THE ALJIPMGTAPP, ALL CONTENT OF THE ALJIPMGTAPP AND IN THE MATERIAL PUBLISHED ON THE ALJIPMGTAPP, INCLUDING WITHOUT LIMITATION THE CONTENT. SPECIFICALLY, WITHOUT LIMITATION, THE COMPANY DOES NOT WARRANT THAT: </strong></p>
                  <ol>
                    <li><strong>YOU WILL BE ABLE TO USE THE ALJIPMGTAPP OR THAT IT WILL CONTINUE TO BE MADE AVAILABLE IN ITS CURRENT OR ANY OTHER FORM; </strong></li>
                    <li><strong>THE CONTENT ON THE ALJIPMGTAPP OR THE MATERIAL AVAILABLE VIA THE ALJIPMGTAPP, INCLUDING WITHOUT LIMITATION THE CONTENT, IS ACCURATE, COMPLETE OR UP-TO-DATE; NOR</strong></li>
                  </ol>
                  <ul>
                    <li><strong>THE ALJIPMGTAPP OR ANY SOFTWARE OR MATERIAL OF WHATSOEVER NATURE AVAILABLE ON OR DOWNLOADED FROM IT WILL BE FREE FROM VIRUSES OR DEFECTS, COMPATIBLE WITH YOUR EQUIPMENT OR FIT FOR ANY PURPOSE.</strong></li>
                  </ul>
                  <p><strong>EXCEPT AS EXPRESSLY SET OUT IN THESE TERMS OF USE, ALL WARRANTIES, CONDITIONS AND REPRESENTATIONS EXPRESSED OR IMPLIED BY STATUTE, COMMON LAW OR OTHERWISE (INCLUDING, WITHOUT LIMITATION, WARRANTIES AS TO SATISFACTORY QUALITY, FITNESS FOR PURPOSE OR SKILL AND CARE) ARE HEREBY EXCLUDED TO THE FULLEST EXTENT PERMITTED BY LAW.</strong></p>
                  <h5>INDEMNITY</h5>
                  <p>YOU AGREE TO INDEMNIFY THE COMPANY OR ANY OTHER ABDUL LATIF JAMEEL BUSINESSES’ OFFICERS, DIRECTORS AND EMPLOYEES, IMMEDIATELY ON DEMAND, AGAINST ALL CLAIMS, LIABILITY, DAMAGES, COSTS AND EXPENSES, INCLUDING LEGAL FEES, ARISING OUT OF ANY BREACH OF THESE TERMS OF USE BY YOU OR ANY OTHER LIABILITIES ARISING OUT OF YOUR USE OF THE ALJIPMGTAPP.</p>
                  <h5>Children aged under 16</h5>
                  <p>If you are under 16, you must ask your parent or a guardian before you:</p>
                  <ol>
                    <li>email the ALJIPMGTAPP or submit an enquiry</li>
                    <li>make a Contribution</li>
                  </ol>
                  <ul>
                    <li>ask the Company to email anything to you; or</li>
                  </ul>
                  <ol>
                    <li>send any information to the Company.</li>
                  </ol>
                  <p>By continuing to use the ALJIPMGTAPP and any of the services offered by it, you are confirming you have received the informed consent of your parent or a legal guardian.</p>
                  <h5>Variation</h5>
                  <p>No variation of these terms of use by you shall be effective unless it is in writing and signed by both the parties.</p>
                  <h5>Severability</h5>
                  <p>If any competent legal authority determines any part of these terms of use is illegal or unenforceable, then such part will be eliminated and the remaining terms of use will remain in force and effect and continue to apply to your use of the ALJIPMGTAPP.</p>
                  <h5>Applicable law</h5>
                  <p>These terms of use, its subject matter and its formation (and any non-contractual disputes or claims) are governed by English law. Abdul Latif Jameel and you both agree the courts of England and Wales have exclusive jurisdiction of to settle any dispute, controversy or claim arising from or connected with these terms of us</p>
                  <h5>Contact us</h5>
                  <p>To contact us, please email <a href="mailto:info@alj.com">info@alj.com</a> or use the enquiry form available on the <strong>ALJIPMGTAPP</strong>.</p>
                  <h5>Copyright notice and disclaimer: </h5>
                  <p>© Abdul Latif Jameel IPR Company Limited.  All intellectual property and other rights in this <strong>Abdul Latif Jameel Intellectual Property Administration Application </strong>are fully reserved.  Permission to use this application for mobile devices is granted strictly subject to the Terms of Use.</p>
                  <p>The Abdul Latif Jameel name, and the Abdul Latif Jameel logotype and pentagon-shaped graphic are trademarks, or registered trademarks of Abdul Latif Jameel IPR Company Limited.  Other trademarks are the property of their respective owners and reproduced by kind permission.</p>
                  <p>The term “Abdul Latif Jameel” refers broadly to several distinct, separate and independent legal entities. Abdul Latif Jameel is not itself a corporate entity, association or conglomerate run by an overarching parent company but merely refers to a group of distinct and wholly separate legal entities that are collectively referred to as Abdul Latif Jameel. Abdul Latif Jameel is not a corporate group as defined in section 1161(5) of the Companies Act 2006.</p>
                  <p>This <strong>ALJIPMGTAPP</strong> smart device application may contain forward-looking statements.  Forward-looking statements are statements regarding matters other than historical fact, such as future results, events, activities, developments or circumstances or the beliefs, plans or expectations of Abdul Latif Jameel entities or their  respective managements.  Forward-looking statements often can be identified by the use of words such as ‘expect’, ‘project’, ‘anticipate’, ‘plan’, ‘estimate’, ‘believe’, ‘predict’, ‘intend’, ‘potential’, ‘possible’, ‘probable’, ‘likely’, ‘forecast’, ‘guidance’, ‘outlook’, ‘goal’, ‘target’, ‘may’, ‘will’, ‘should’ or ‘could’ or other similar terms or phrases.  However, the absence of such words does not mean that a particular statement is not forward looking.</p>
                  <p>Forward-looking statements are based on expectations and assumptions at the time of such statements and are subject to numerous risks and uncertainties, many of which are outside the control of Abdul Latif Jameel entities.  Should any of such expectations or assumptions prove incorrect, or should any of such risks or uncertainties materialize, actual future results, events, activities, developments or circumstances may differ materially from those expressed in or implied by forward-looking statements.  Further, any forward-looking statement speaks only as of the date on which it is made, and Abdul Latif Jameel do not assume, and hereby disclaims, any obligation to correct or update any forward-looking statement, whether as a result of new information, future events or otherwise.</p>
                  <p>All forward-looking statements made by Abdul Latif Jameel, or by any person on behalf of any of them, whether communicated in writing, electronically or orally, are qualified in their entirety by the foregoing cautionary statements.</p>
                  <p>&nbsp;</p>
                  <p>Date: 17 March 2019</p>


                </ScrollArea>
                <div className="customcheck form-inline mt-3">
                  <div className="form-check form-check-inline">
                    <Input type="checkbox" name="terms" value="" id="terms" onChange={this.showButton} />
                    <Label for="terms">I agree all terms and conditions.</Label>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button disabled={this.state.showbutton} className="btn btn-blue" onClick={this.setCookie}>Continue</Button>
              </ModalFooter>
            </div>
          </div>
        </div>
        <div className="custommodal-bg" id="modelbg"></div>
      </div>

    </div>
      
      :null
     
    );
  }
}

export default Model;
