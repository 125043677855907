import jQuery from 'jquery';
var Parse = require('parse');


export const getFullPageCount = async () => {
    var BusinessSectors = Parse.Object.extend("BusinessSectors");
    var query = new Parse.Query(BusinessSectors);
    query.equalTo("status", true);
    query.descending("createdAt");
    const count = await query.count();

    return count;
}

export const getSearchResults = async (searchdatas) => {
    var BusinessSectors = Parse.Object.extend("BusinessSectors");
    var query = new Parse.Query(BusinessSectors);
    query.descending("createdAt");
    query.equalTo("status", true);

    if (searchdatas['searchbox']) {
        query.matches("name", searchdatas['searchbox'], 'i');
    }

    if (searchdatas['statsrc'] == "true") {
        query.equalTo("status", true);
    } else if (searchdatas['statsrc'] == "false") {
        query.equalTo("status", false);
    }
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });

}

export const getBusinessSectors = async (pagenum, dataperpage, sortitem, sortitemorder) => {
    var skipnum = pagenum - 1;
    var perpage = parseInt(dataperpage);

    var BusinessSectors = Parse.Object.extend("BusinessSectors");
    var query = new Parse.Query(BusinessSectors);
    query.equalTo("status", true);

    if (sortitem !== "") {
        if (sortitemorder === "ascending") {
            query.ascending(sortitem);
        } else {
            query.descending(sortitem);
        }

    } else {
        query.descending('createdAt');
    }

    query.skip(skipnum * perpage);
    query.limit(perpage);

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const hideRecords = async (objectids) => {

    var parseobject = Parse.Object.extend("BusinessSectors");
    var query = new Parse.Query(parseobject);
    query.equalTo("objectId", objectids);
    return query.find().then((object) => {
        var realbject = object[0];
        realbject.set("status", false);
        return realbject.save().then((records) => {
            return true;
        }, (error) => {
            console.log(error);
        }).catch(function (error) {
        });

    }, (error) => {
    });


}


export const addRecord = async ({ name, status, description }) => {
    var businessunit = new Parse.Object("BusinessSectors");
    businessunit.set("name", name);
    businessunit.set("description", description);
    if (status === 'false') {
        businessunit.set("status", false);
    } else {
        businessunit.set("status", true);
    }


    return businessunit.save().then((businessunit) => {
        return businessunit;
    }, (error) => {
        return false;
    }).catch(function (error) {
        return false;
    });
}


export const fetchRecordById = async ({ objectid }) => {
    var BusinessSectors = Parse.Object.extend("BusinessSectors");
    var query = new Parse.Query(BusinessSectors);
    query.equalTo("objectId", objectid);
    return query.find().then((records) => {
        return records;
    }, (error) => {
        return false;
    }).catch(function (error) {
        return false;
    });
}


export const updateRecord = async ({ edithiddenid, name, description, status }) => {
    var BusinessSectors = Parse.Object.extend("BusinessSectors");
    var query = new Parse.Query(BusinessSectors);
    query.equalTo("objectId", edithiddenid);

    return query.find().then((object) => {

        var realbject = object[0];

        realbject.set("name", name);
        realbject.set("description", description);
        if (status === 'false') {
            realbject.set("status", false);
        } else {
            realbject.set("status", true);
        }

        return realbject.save().then((records) => {
            return true;
        }, (error) => {
            return false;
        }).catch(function (error) {
            return false;
        });

    }, (error) => {
        return false;
    });

}




export const checkFieldValid = (addoredit) => {

    var name = jQuery('#bname');
    var business_status = jQuery('#business_status');

    var errstat = true;
    if (name.val() === "") {
        name.addClass("is-invalid");
        errstat = false;
    } else {
        name.removeClass("is-invalid");
    }
    if (business_status.val() === "") {
        business_status.addClass("is-invalid");
        errstat = false;
    } else {
        business_status.removeClass("is-invalid");
    }


    if (addoredit === "add") {

    }

    if (errstat === false) {
        return false;
    } else {
        return true;
    }

}

