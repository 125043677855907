import React from 'react';
import ScrollArea from 'react-scrollbar';
import {
  Card, CardHeader, CardBody, Alert, Table, Row, Col, Input, Form, FormGroup, CardTitle, InputGroup, InputGroupAddon, Button,
  Modal, ModalHeader, ModalBody, ModalFooter, ListGroup, ListGroupItem, Pagination, PaginationItem, PaginationLink, Label,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Datetime from "react-datetime";
import Select, { components } from "react-select";
import jQuery from 'jquery';
import FileUpload from '../../components/common/FileUpload';
import { getFileUrl } from '../../services/awsFileUpload';
import imageIcon from '../../assets/images/icons/image-icon.png';
import fileIcon from '../../assets/images/icons/file-icon.png';
import Loader from '../../components/common/Loader';

import { authenticateLoggedIn, addHeaderTitle, addBreadcrumb, getCurrentUser } from '../../services/parse';
import {
  getFullPageCount, getSearchResults, getTrademarksList, getJurisdictionsList, getTrademarkStatuses,
  hideRecords, getALJCompaniesList, getClasses, getCountriesList, getLawfirmList, getCountriesByJuris, getHiddenColumns, saveHiddenColumns
} from '../../services/trademark';


import imgview from '../../assets/images/icons/view-white.png';
import imgnoview from '../../assets/images/icons/no-view-white.png';
import imgedit from '../../assets/images/icons/edit-white.png';
import { isAdmin } from '../../services/user';

var Parse = require('parse');

class trademarks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      textClass: '',
      isSuper: '',
      datafull: '',
      totalpagecount: '',
      data: [],
      jurisdata: [],
      countrydata: [],
      lawfirmdata: [],
      TrademarkStatData: [],
      companiesListData: [],
      classListData: [],
      deleteIds: [],
      deletedstat: 'd-none',
      loaderstat: true,
      modal: false,
      modal5: false,
      jurisCountries: [],
      juristitle: "",
      generalHidden: "",
      searchcount: "",
      hiddenColumnArray: [],
      userRole:null,
    };
    this.toggle = this.toggle.bind(this);
    this.toggle5 = this.toggle5.bind(this);

  }


  async componentDidMount(evt) {
    //auth start
    authenticateLoggedIn();
    addHeaderTitle('Trademarks');
    addBreadcrumb('Home / Trademarks');

    var cuser = await Parse.User.current();
    if (cuser) {
      //console.log(cuser.get('role').get('name'));
      console.log(cuser.get('role').get('name'));
      if (cuser.get('role').get('name') === "External") {
        this.setState({ generalHidden: 'd-none',userRole: cuser.get('role').get('name')});
      }
    }

    var isadminval = await isAdmin();
		if (isadminval) {
      var currentrole = isadminval[0].get('role').get('name');
      console.log(currentrole);
      this.setState({ currentRole: currentrole });
      if (currentrole === "External" || currentrole === "Finance") {
        this.setState({ generalHidden: 'd-none',userRole: currentrole});
      }
    }
    

    //auth end
    var dataperpage = jQuery('#dataperpage').val();

    getFullPageCount().then((value) => {
      this.setState({ datafull: value, });
      var totpagecount = Math.ceil(value / dataperpage);
      this.setState({ totalpagecount: totpagecount, });
      jQuery('.pagination .page-item:first-child').nextAll().slice(3, -1).addClass('d-none');
    });

    var sortitemval = jQuery('#sortitemval').val();
    var sortitemorder = jQuery('#sortitemorder').val();
    getTrademarksList(1, dataperpage, sortitemval, sortitemorder).then((value) => {
      this.setState({ data: value, });
      this.setState({ loaderstat: false, });
    });


    var jurvalue = await getJurisdictionsList();
    this.setState({ jurisdata: jurvalue, });

    var convalue = await getCountriesList();
    this.setState({ countrydata: convalue, });

    var lawvalue = await getLawfirmList();
    this.setState({ lawfirmdata: lawvalue, });

    var tradevalue = await getTrademarkStatuses();
    this.setState({ TrademarkStatData: tradevalue, });

    var comvalue = await getALJCompaniesList();
    this.setState({ companiesListData: comvalue, });

    var clasvalue = await getClasses();
    this.setState({ classListData: clasvalue, });

    var hiddenColumnArray = await getHiddenColumns();
    if (hiddenColumnArray && hiddenColumnArray[0]) {
      this.setState({ hiddenColumnArray: hiddenColumnArray[0].get('hiddenColumns'), });
    }

    jQuery('#searchbox').on('keyup keypress', function (e) {
      var keyCode = e.keyCode || e.which;
      if (keyCode === 13) {
        e.preventDefault();
        return false;
      }
    });





  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  toggle5 = async (event) => {
    var jurisid = event.currentTarget.dataset.jurisid;
    var juristext = event.currentTarget.dataset.juristext;

    if (jurisid) {
      var jurcon = await getCountriesByJuris(jurisid);
      this.setState({ jurisCountries: jurcon, });
      this.setState({ juristitle: juristext, });
    }

    this.setState({
      modal5: !this.state.modal5
    });
  }

  toggleColumns = () => {
    jQuery('#hidecheckgroup').slideToggle();
  }

  saveHiddenColumns = () => {
    jQuery('#addbutton').addClass('d-none');
    jQuery('#loadbutton').removeClass('d-none');

    var hiddenitems = [];
    jQuery.each(jQuery("input[name='hiddenitems']:checked"), function () {
      hiddenitems.push(jQuery(this).val());
    });

    var currentUser = getCurrentUser();
    var userObjectID = currentUser.id;

    var fielddatas = {
      hiddenitems: hiddenitems,
      userObjectID: userObjectID,
    }
    saveHiddenColumns(fielddatas).then((value) => {
      if (value !== false) {
        jQuery('#addbutton').removeClass('d-none');
        jQuery('#loadbutton').addClass('d-none');
      }
    });
  }

  createColumns = () => {
    let columnToHide = [];
    var thcount = 1;
    var _ = this;

    jQuery(".table-responsive .table thead tr th").each(function () {
      var thval = jQuery(this).html();

      if (jQuery.inArray(thcount.toString(), _.state.hiddenColumnArray) !== -1) {
        columnToHide.push(
          <div className="form-check form-check-inline">
            <Input type="checkbox" value={thcount} onClick={_.hideColumn} id={thval} name="hiddenitems" defaultChecked />
            <Label for={thval}>{thval}</Label>
          </div>
        );
        jQuery('.table-responsive .table thead tr th:nth-child(' + thcount + ')').hide();
        jQuery('.table-responsive .table tbody tr td:nth-child(' + thcount + ')').hide();
      } else {
        columnToHide.push(
          <div className="form-check form-check-inline">
            <Input type="checkbox" value={thcount} onClick={_.hideColumn} id={thval} name="hiddenitems" />
            <Label for={thval}>{thval}</Label>
          </div>
        );
      }

      thcount++;
    });
    return columnToHide
  }

  hideColumn = async (event) => {
    var checkval = event.target.value;
    var checkstat = event.target.checked;

    if (checkstat === true) {
      jQuery('.table-responsive .table thead tr th:nth-child(' + checkval + ')').hide();
      jQuery('.table-responsive .table tbody tr td:nth-child(' + checkval + ')').hide();
    } else {
      jQuery('.table-responsive .table thead tr th:nth-child(' + checkval + ')').show();
      jQuery('.table-responsive .table tbody tr td:nth-child(' + checkval + ')').show();
    }

  }

  setSortItem = (item) => {
    
    jQuery('.cursor-pointer').not(item.target).removeClass('ascending');
    jQuery('.cursor-pointer').not(item.target).removeClass('descending');
    jQuery('.cursor-pointer').not(item.target).removeAttr('data-sortorder');

    if (item.target.dataset.sortorder === "ascending") {
      item.target.dataset.sortorder = "descending";
      item.target.classList.add("descending");
      item.target.classList.remove("ascending");
    } else {
      item.target.dataset.sortorder = "ascending";
      item.target.classList.add("ascending");
      item.target.classList.remove("descending");
    }

    var sortval = item.target.dataset.sort;
    jQuery('#sortitemval').val(sortval);

    var sortitemorder = item.target.dataset.sortorder;
    jQuery('#sortitemorder').val(sortitemorder);

    this.gotoPage();
  }

  setpageNum = (event) => {
    jQuery('.table-responsive').animate({scrollTop:0,scrollLeft:0},500);
    jQuery('.tablepagination .pagination > li').removeClass('active');
    jQuery('.tablepagination .pagination > li').removeClass('d-none');
    jQuery(event.target).parent().addClass('active');

    jQuery('.paginatecounts').removeClass('active');
    jQuery(event.target).addClass('active');

    var pagenum = jQuery(event.target).data('pagenum');
    jQuery('#pagenum').val(pagenum);

    this.gotoPage();


    jQuery(event.target).parent().prevAll().slice(3, -1).addClass('d-none');
    jQuery(event.target).parent().nextAll().slice(3, -1).addClass('d-none');
  }

  gotoPage = () => {
    var dataperpage = jQuery('#dataperpage').val();
    var pagenum = jQuery('#pagenum').val();
    var sortitemval = jQuery('#sortitemval').val();
    var sortitemorder = jQuery('#sortitemorder').val();

    getTrademarksList(pagenum, dataperpage, sortitemval, sortitemorder).then((value) => {
      this.setState({ data: value, });

      this.createColumns();
    });
  }
  showPerPage = () => {
    var dataperpage = jQuery('#dataperpage').val();
    var pagenum = jQuery('#pagenum').val();
    var sortitemval = jQuery('#sortitemval').val();
    var sortitemorder = jQuery('#sortitemorder').val();

    var totpagecount = Math.ceil(this.state.datafull / dataperpage);

    this.setState({
      totalpagecount: totpagecount
    }, () => {
      jQuery('.paginatecounts[data-pagenum = ' + pagenum + ']').trigger("click");
    });

    var pagenum = jQuery('#pagenum').val();
    if (pagenum > totpagecount) {
      jQuery('#pagenum').val(totpagecount);
      var pagenum = jQuery('#pagenum').val();

      jQuery('#paginatecounts').removeClass('active');
      jQuery('.paginatecounts[data-pagenum = ' + pagenum + ']').addClass('active');

      jQuery('.paginatecounts[data-pagenum = ' + pagenum + ']').parent().addClass('active');
    }

    getTrademarksList(pagenum, dataperpage, sortitemval, sortitemorder).then((value) => {
      this.setState({ data: value, });
    });

  }

  hitEnter = (e) => {
    var keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      this.searchRecords();
    }
  }

  setSearchFields = () => {
    jQuery('#searchbox').val('');
    var setsrcfield = jQuery('#setsrcfield').val();
    if (setsrcfield == "registrationDate" || setsrcfield == "renewalDate" || setsrcfield == "applicationDate") {

      jQuery('#searchbox').addClass('d-none');
      jQuery('#recordstatsrc').addClass('d-none');
      jQuery('#statsrc').addClass('d-none');
      jQuery('#ownersrc').addClass('d-none');
      jQuery('#classsrc').addClass('d-none');
      jQuery('#jurissrc').addClass('d-none');
      jQuery('#countrysrc').addClass('d-none');
      jQuery('#lawfirmsrc').addClass('d-none');
      jQuery('#typesrc').addClass('d-none');
      jQuery('#datesearchouter').removeClass('d-none');
      jQuery('#stepstatussrc').addClass('d-none');

    } else if (setsrcfield == "type") {

      jQuery('#searchbox').addClass('d-none');
      jQuery('#recordstatsrc').addClass('d-none');
      jQuery('#statsrc').addClass('d-none');
      jQuery('#ownersrc').addClass('d-none');
      jQuery('#classsrc').addClass('d-none');
      jQuery('#jurissrc').addClass('d-none');
      jQuery('#countrysrc').addClass('d-none');
      jQuery('#lawfirmsrc').addClass('d-none');
      jQuery('#typesrc').removeClass('d-none');
      jQuery('#datesearchouter').addClass('d-none');
      jQuery('#stepstatussrc').addClass('d-none');

    } else if (setsrcfield == "jurisdiction") {

      jQuery('#searchbox').addClass('d-none');
      jQuery('#recordstatsrc').addClass('d-none');
      jQuery('#statsrc').addClass('d-none');
      jQuery('#ownersrc').addClass('d-none');
      jQuery('#classsrc').addClass('d-none');
      jQuery('#jurissrc').removeClass('d-none');
      jQuery('#countrysrc').addClass('d-none');
      jQuery('#lawfirmsrc').addClass('d-none');
      jQuery('#typesrc').addClass('d-none');
      jQuery('#datesearchouter').addClass('d-none');
      jQuery('#stepstatussrc').addClass('d-none');

    } else if (setsrcfield == "country") {

      jQuery('#searchbox').addClass('d-none');
      jQuery('#recordstatsrc').addClass('d-none');
      jQuery('#statsrc').addClass('d-none');
      jQuery('#ownersrc').addClass('d-none');
      jQuery('#classsrc').addClass('d-none');
      jQuery('#jurissrc').addClass('d-none');
      jQuery('#countrysrc').removeClass('d-none');
      jQuery('#lawfirmsrc').addClass('d-none');
      jQuery('#typesrc').addClass('d-none');
      jQuery('#datesearchouter').addClass('d-none');
      jQuery('#stepstatussrc').addClass('d-none');

    } else if (setsrcfield == "lawfirm") {

      jQuery('#searchbox').addClass('d-none');
      jQuery('#recordstatsrc').addClass('d-none');
      jQuery('#statsrc').addClass('d-none');
      jQuery('#ownersrc').addClass('d-none');
      jQuery('#classsrc').addClass('d-none');
      jQuery('#jurissrc').addClass('d-none');
      jQuery('#countrysrc').addClass('d-none');
      jQuery('#lawfirmsrc').removeClass('d-none');
      jQuery('#typesrc').addClass('d-none');
      jQuery('#datesearchouter').addClass('d-none');
      jQuery('#stepstatussrc').addClass('d-none');

    } else if (setsrcfield == "status") {

      jQuery('#searchbox').addClass('d-none');
      jQuery('#recordstatsrc').addClass('d-none');
      jQuery('#statsrc').removeClass('d-none');
      jQuery('#ownersrc').addClass('d-none');
      jQuery('#classsrc').addClass('d-none');
      jQuery('#jurissrc').addClass('d-none');
      jQuery('#countrysrc').addClass('d-none');
      jQuery('#lawfirmsrc').addClass('d-none');
      jQuery('#typesrc').addClass('d-none');
      jQuery('#datesearchouter').addClass('d-none');
      jQuery('#stepstatussrc').addClass('d-none');

    } else if (setsrcfield == "stepStatus") {

      jQuery('#searchbox').addClass('d-none');
      jQuery('#recordstatsrc').addClass('d-none');
      jQuery('#statsrc').addClass('d-none');
      jQuery('#ownersrc').addClass('d-none');
      jQuery('#classsrc').addClass('d-none');
      jQuery('#jurissrc').addClass('d-none');
      jQuery('#countrysrc').addClass('d-none');
      jQuery('#lawfirmsrc').addClass('d-none');
      jQuery('#typesrc').addClass('d-none');
      jQuery('#datesearchouter').addClass('d-none');
      jQuery('#stepstatussrc').removeClass('d-none');

    } else if (setsrcfield == "owner") {

      jQuery('#searchbox').addClass('d-none');
      jQuery('#recordstatsrc').addClass('d-none');
      jQuery('#statsrc').addClass('d-none');
      jQuery('#ownersrc').removeClass('d-none');
      jQuery('#classsrc').addClass('d-none');
      jQuery('#jurissrc').addClass('d-none');
      jQuery('#countrysrc').addClass('d-none');
      jQuery('#lawfirmsrc').addClass('d-none');
      jQuery('#typesrc').addClass('d-none');
      jQuery('#datesearchouter').addClass('d-none');
      jQuery('#stepstatussrc').addClass('d-none');

    } else if (setsrcfield == "trademarkClasses") {

      jQuery('#searchbox').addClass('d-none');
      jQuery('#recordstatsrc').addClass('d-none');
      jQuery('#statsrc').addClass('d-none');
      jQuery('#ownersrc').addClass('d-none');
      jQuery('#classsrc').removeClass('d-none');
      jQuery('#jurissrc').addClass('d-none');
      jQuery('#countrysrc').addClass('d-none');
      jQuery('#lawfirmsrc').addClass('d-none');
      jQuery('#typesrc').addClass('d-none');
      jQuery('#datesearchouter').addClass('d-none');
      jQuery('#stepstatussrc').addClass('d-none');

    } else if (setsrcfield == "recordstatus") {

      jQuery('#searchbox').addClass('d-none');
      jQuery('#statsrc').addClass('d-none');
      jQuery('#ownersrc').addClass('d-none');
      jQuery('#classsrc').addClass('d-none');
      jQuery('#jurissrc').addClass('d-none');
      jQuery('#countrysrc').addClass('d-none');
      jQuery('#lawfirmsrc').addClass('d-none');
      jQuery('#typesrc').addClass('d-none');
      jQuery('#datesearchouter').addClass('d-none');
      jQuery('#stepstatussrc').addClass('d-none');
      jQuery('#recordstatsrc').removeClass('d-none');

    } else {

      jQuery('#searchbox').removeClass('d-none');
      jQuery('#recordstatsrc').addClass('d-none');
      jQuery('#statsrc').addClass('d-none');
      jQuery('#ownersrc').addClass('d-none');
      jQuery('#classsrc').addClass('d-none');
      jQuery('#jurissrc').addClass('d-none');
      jQuery('#countrysrc').addClass('d-none');
      jQuery('#lawfirmsrc').addClass('d-none');
      jQuery('#typesrc').addClass('d-none');
      jQuery('#datesearchouter').addClass('d-none');
      jQuery('#stepstatussrc').addClass('d-none');

    }
  }
  searchRecords = () => {
    var searchbox = jQuery('#searchbox').val();
    var setsrcfield = jQuery('#setsrcfield').val();
    var datesearch = jQuery('#datesearch').val();
    var datesearchend = jQuery('#datesearchend').val();
    var recordstatsrc = jQuery('#recordstatsrc').val();


    var st = datesearch;
    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
    var datesearchvalid = new Date(st.replace(pattern, '$3-$2-$1'));

    if (datesearchvalid.toString() === 'Invalid Date' && datesearch !== "") {
      jQuery('#datesearch').addClass('is-invalid');
      return false;
    } else {
      jQuery('#datesearch').removeClass('is-invalid');
    }

    var st = datesearchend;
    var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
    var datesearchendvalid = new Date(st.replace(pattern, '$3-$2-$1'));

    if (datesearchendvalid.toString() === 'Invalid Date' && datesearchend !== "") {
      jQuery('#datesearchend').addClass('is-invalid');
      return false;
    } else {
      jQuery('#datesearchend').removeClass('is-invalid');
    }


    if (setsrcfield == "name") {
      jQuery('#namehidden').val(searchbox);

      jQuery('#namefilter label').html(searchbox);
      jQuery('#namefilter').removeClass('d-none');

    } else if (setsrcfield == "objectid") {
      jQuery('#objectidhidden').val(searchbox);

      jQuery('#objectidfilter label').html(searchbox);
      jQuery('#objectidfilter').removeClass('d-none');

    } else if (setsrcfield == "applicationNumber") {
      jQuery('#appnumhidden').val(searchbox);

      jQuery('#appnumberfilter label').html(searchbox);
      jQuery('#appnumberfilter').removeClass('d-none');

    } else if (setsrcfield == "applicationDate") {      
      jQuery('#appdatehidden').val(datesearch);
      jQuery('#appdateendhidden').val(datesearchend);

      jQuery('#appdatefilter label').html(datesearch + ' - ' + datesearchend);
      jQuery('#appdatefilter').removeClass('d-none');

    } else if (setsrcfield == "registrationNumber") {
      jQuery('#regnumhidden').val(searchbox);

      jQuery('#regnumberfilter label').html(searchbox);
      jQuery('#regnumberfilter').removeClass('d-none');

    } else if (setsrcfield == "registrationDate") {
      jQuery('#registrationhidden').val(datesearch);
      jQuery('#registrationendhidden').val(datesearchend);

      jQuery('#registrationfilter label').html(datesearch + ' - ' + datesearchend);
      jQuery('#registrationfilter').removeClass('d-none');

    } else if (setsrcfield == "renewalDate") {
      jQuery('#renewalhidden').val(datesearch);
      jQuery('#renewalendhidden').val(datesearchend);

      jQuery('#renewalfilter label').html(datesearch + ' - ' + datesearchend);
      jQuery('#renewalfilter').removeClass('d-none');

    } else if (setsrcfield == "type") {
      if (this.state.typesrc) {
        jQuery('#typefilter label').html(this.state.typesrc.label);
      } else {
        jQuery('#typefilter label').html();
      }
      jQuery('#typefilter').removeClass('d-none');

    } else if (setsrcfield == "language") {
      jQuery('#languagehidden').val(searchbox);

      jQuery('#languagefilter label').html(searchbox);
      jQuery('#languagefilter').removeClass('d-none');

    } else if (setsrcfield == "jurisdiction") {
      if (this.state.jurissrc) {
        jQuery('#jurisdictionfilter label').html(this.state.jurissrc.label);
      } else {
        jQuery('#jurisdictionfilter label').html();
      }
      jQuery('#jurisdictionfilter').removeClass('d-none');

    } else if (setsrcfield == "country") {
      if (this.state.countrysrc) {
        jQuery('#countryfilter label').html(this.state.countrysrc.map(x => x.label + ','));
      } else {
        jQuery('#countryfilter label').html();
      }
      jQuery('#countryfilter').removeClass('d-none');

    } else if (setsrcfield == "lawfirm") {
      if (this.state.lawfirmsrc) {
        jQuery('#lawfirmfilter label').html(this.state.lawfirmsrc.map(x => x.label + ','));
      } else {
        jQuery('#lawfirmfilter label').html();
      }
      jQuery('#lawfirmfilter').removeClass('d-none');

    } else if (setsrcfield == "twrefnumber") {
      jQuery('#searchboxhidden').val(searchbox);

      jQuery('#twreffilter label').html(searchbox);
      jQuery('#twreffilter').removeClass('d-none');

    } else if (setsrcfield == "AGIPRefNumber") {
      jQuery('#agiphidden').val(searchbox);

      jQuery('#agipfilter label').html(searchbox);
      jQuery('#agipfilter').removeClass('d-none');

    } else if (setsrcfield == "status") {

      if (this.state.statsrc) {
        jQuery('#statfilter label').html(this.state.statsrc.map(x => x.label + ','));
      } else {
        jQuery('#statfilter label').html();
      }
      jQuery('#statfilter').removeClass('d-none');

    } else if (setsrcfield == "stepStatus") {

      if (this.state.stepstatussrc) {
        jQuery('#stepstatfilter label').html(this.state.stepstatussrc.label);
      } else {
        jQuery('#stepstatfilter label').html();
      }
      jQuery('#stepstatfilter').removeClass('d-none');

    } else if (setsrcfield == "owner") {

      if (this.state.owner) {
        jQuery('#ownerfilter label').html(this.state.owner.label);
      } else {
        jQuery('#ownerfilter label').html();
      }
      jQuery('#ownerfilter').removeClass('d-none');

    } else if (setsrcfield == "trademarkClasses") {

      if (this.state.classsrc) {
        jQuery('#classfilter label').html(this.state.classsrc.map(x => x.label + ','));
      } else {
        jQuery('#classfilter label').html();
      }
      jQuery('#classfilter').removeClass('d-none');

    }else if (setsrcfield == "recordstatus") {
      jQuery('#recordstatsrchidden').val(recordstatsrc);

      jQuery('#recordstatfilter label').html(recordstatsrc);
      jQuery('#recordstatfilter').removeClass('d-none');

    }

    this.searchInit();
  }

  searchInit = () => {
    var namehidden = jQuery('#namehidden').val();
    var objectidhidden = jQuery('#objectidhidden').val();
    var appnumhidden = jQuery('#appnumhidden').val();
    var appdatehidden = jQuery('#appdatehidden').val();
    var appdateendhidden = jQuery('#appdateendhidden').val();
    var regnumhidden = jQuery('#regnumhidden').val();
    var typehidden = jQuery('#typehidden').val();
    var languagehidden = jQuery('#languagehidden').val();
    var jurissrchidden = jQuery('#jurissrchidden').val();
    var countrysrchidden = jQuery('#countrysrchidden').val();
    var lawfirmsrchidden = jQuery('#lawfirmsrchidden').val();
    var searchboxhidden = jQuery('#searchboxhidden').val();
    var statsrchidden = jQuery('#statsrchidden').val();
    var stepstatsrchidden = jQuery('#stepstatsrchidden').val();
    var agiphidden = jQuery('#agiphidden').val();
    var ownerhidden = jQuery('#ownerhidden').val();
    var classhidden = jQuery('#classhidden').val();
    var registrationhidden = jQuery('#registrationhidden').val();
    var registrationendhidden = jQuery('#registrationendhidden').val();
    var renewalhidden = jQuery('#renewalhidden').val();
    var renewalendhidden = jQuery('#renewalendhidden').val();
    var recordstatsrchidden = jQuery('#recordstatsrchidden').val();


    var searchdatas = {
      name: namehidden,
      objectid: objectidhidden,
      applicationNumber: appnumhidden,
      applicationDate: appdatehidden,
      applicationDateend: appdateendhidden,
      registrationNumber: regnumhidden,
      type: typehidden,
      language: languagehidden,
      jurisdiction: jurissrchidden,
      country: countrysrchidden,
      lawfirm: lawfirmsrchidden,
      searchbox: searchboxhidden,
      agipref: agiphidden,
      statsrc: statsrchidden,
      stepstatsrc: stepstatsrchidden,
      owner: ownerhidden,
      classsrc: classhidden,
      registrationdate: registrationhidden,
      registrationdateend: registrationendhidden,
      renewaldate: renewalhidden,
      renewaldateend: renewalendhidden,
      recordstatsrc: recordstatsrchidden,
    };

    if (namehidden !== "" || objectidhidden !== "" || appnumhidden !== "" || appdatehidden !== "" || appdateendhidden !== "" || regnumhidden !== "" || typehidden !== "" || languagehidden !== "" || jurissrchidden !== "" || countrysrchidden !== "" || lawfirmsrchidden !== ""
      || searchboxhidden !== "" || agiphidden !== "" || statsrchidden !== "" || stepstatsrchidden !== "" || ownerhidden !== "" || classhidden !== "" || registrationhidden !== "" || registrationendhidden !== "" || renewalhidden !== "" || renewalendhidden !== "" || recordstatsrchidden !== "") {
      getSearchResults(searchdatas).then((value) => {
        this.setState({ data: value, });
        this.setState({ searchcount: value.length, });
      });
      jQuery('.tablepagination .col-auto').addClass('d-none');
    } else {
      var dataperpage = jQuery('#dataperpage').val();
      var sortitemval = jQuery('#sortitemval').val();
      var sortitemorder = jQuery('#sortitemorder').val();
      getTrademarksList(1, dataperpage, sortitemval, sortitemorder).then((value) => {
        this.setState({ data: value, });
        this.setState({ searchcount: "", });
      });
      jQuery('.tablepagination .col-auto').removeClass('d-none');
    }

  }

  clearFilterItem = (event) => {
    var itemname = event.currentTarget.dataset.item;
    if (itemname === "name") {
      jQuery('#namefilter label').html('');
      jQuery('#namefilter').addClass('d-none');
      jQuery('#namehidden').val('');
    } else if (itemname === "objectid") {
      jQuery('#objectidfilter label').html('');
      jQuery('#objectidfilter').addClass('d-none');
      jQuery('#objectidhidden').val('');
    } else if (itemname === "applicationNumber") {
      jQuery('#appnumberfilter label').html('');
      jQuery('#appnumberfilter').addClass('d-none');
      jQuery('#appnumhidden').val('');
    } else if (itemname === "applicationDate") {
      jQuery('#appdatefilter label').html('');
      jQuery('#appdatefilter').addClass('d-none');
      jQuery('#appdatehidden').val('');
      jQuery('#appdateendhidden').val('');
    } else if (itemname === "registrationNumber") {
      jQuery('#regnumberfilter label').html('');
      jQuery('#regnumberfilter').addClass('d-none');
      jQuery('#regnumhidden').val('');
    } else if (itemname === "registrationDate") {
      jQuery('#registrationfilter label').html('');
      jQuery('#registrationfilter').addClass('d-none');
      jQuery('#registrationhidden').val('');
      jQuery('#registrationendhidden').val('');
    } else if (itemname === "renewalDate") {
      jQuery('#renewalfilter label').html('');
      jQuery('#renewalfilter').addClass('d-none');
      jQuery('#renewalhidden').val('');
      jQuery('#renewalendhidden').val('');
    } else if (itemname === "type") {
      jQuery('#typefilter label').html('');
      jQuery('#typefilter').addClass('d-none');
      jQuery('#typehidden').val('');
      this.setState({ typesrc: '' });
    } else if (itemname === "language") {
      jQuery('#languagefilter label').html('');
      jQuery('#languagefilter').addClass('d-none');
      jQuery('#languagehidden').val('');
    } else if (itemname === "jurisdiction") {
      jQuery('#jurisdictionfilter label').html('');
      jQuery('#jurisdictionfilter').addClass('d-none');
      jQuery('#jurissrchidden').val('');
      this.setState({ jurissrc: '' });
    } else if (itemname === "country") {
      jQuery('#countryfilter label').html('');
      jQuery('#countryfilter').addClass('d-none');
      jQuery('#countrysrchidden').val('');
      this.setState({ countrysrc: '' });
    } else if (itemname === "lawfirm") {
      jQuery('#lawfirmfilter label').html('');
      jQuery('#lawfirmfilter').addClass('d-none');
      jQuery('#lawfirmsrchidden').val('');
      this.setState({ lawfirmsrc: '' });
    } else if (itemname === "twrefnumber") {
      jQuery('#twreffilter label').html('');
      jQuery('#twreffilter').addClass('d-none');
      jQuery('#searchboxhidden').val('');
    } else if (itemname === "AGIPRefNumber") {
      jQuery('#agipfilter label').html('');
      jQuery('#agipfilter').addClass('d-none');
      jQuery('#agiphidden').val('');
    } else if (itemname === "status") {
      jQuery('#statfilter label').html('');
      jQuery('#statfilter').addClass('d-none');
      jQuery('#statsrchidden').val('');
      this.setState({ statsrc: '' });
    } else if (itemname === "stepStatus") {
      jQuery('#stepstatfilter label').html('');
      jQuery('#stepstatfilter').addClass('d-none');
      jQuery('#stepstatsrchidden').val('');
      this.setState({ statsrc: '' });
    } else if (itemname === "owner") {
      jQuery('#ownerfilter label').html('');
      jQuery('#ownerfilter').addClass('d-none');
      jQuery('#ownerhidden').val('');
      this.setState({ owner: '' });
    } else if (itemname === "trademarkClasses") {
      jQuery('#classfilter label').html('');
      jQuery('#classfilter').addClass('d-none');
      jQuery('#classhidden').val('');
      this.setState({ classsrc: '' });
    } else if (itemname === "recordstatus") {

      jQuery('#recordstatfilter label').html('');
      jQuery('#recordstatfilter').addClass('d-none');
      jQuery('#recordstatsrchidden').val('');

    }

    this.searchInit();
  }



  hideRecord = (event) => {
    var objectid = event.currentTarget.dataset.target;
    jQuery('#hiddenidstored').val(objectid);
    this.toggle();
  }
  pushhideRecord = () => {
    var objectid = jQuery('#hiddenidstored').val();
    hideRecords(objectid).then((value) => {
      if (value === true) {
        this.gotoPage();
        this.setState({ deletedstat: 'd-block' });
      }
    });
    this.toggle();
  }




  render() {
    var jurisstate = this.state.jurisdata;
    var jurislist = [];
    if (jurisstate.length > 0) {
      for (let i = 0; i < jurisstate.length; i++) {
        jurislist.push({ value: jurisstate[i].id, label: jurisstate[i].attributes.name })
      }
    }

    var countrystate = this.state.countrydata;
    var countrylist = [];
    if (countrystate.length > 0) {
      for (let i = 0; i < countrystate.length; i++) {
        countrylist.push({ value: countrystate[i].id, label: countrystate[i].attributes.name })
      }
    }

    var lawstate = this.state.lawfirmdata;
    var lawlist = [];
    if (lawstate.length > 0) {
      for (let i = 0; i < lawstate.length; i++) {
        lawlist.push({ value: lawstate[i].id, label: lawstate[i].attributes.name })
      }
    }

    var trademarkstatstate = this.state.TrademarkStatData;
    var tradestatlist = [];
    if (trademarkstatstate.length > 0) {
      for (let i = 0; i < trademarkstatstate.length; i++) {
        tradestatlist.push({ value: trademarkstatstate[i].id, label: trademarkstatstate[i].attributes.name })
        // tradestatlist.push({ value: trademarkstatstate[i].attributes.name, label: trademarkstatstate[i].attributes.name })
      }
    }

    var companyliststate = this.state.companiesListData;
    var companystatelist = [];
    if (companyliststate.length > 0) {
      for (let i = 0; i < companyliststate.length; i++) {
        companystatelist.push({ value: companyliststate[i].id, label: companyliststate[i].attributes.name })
      }
    }

    var classListstate = this.state.classListData;
    var classlist = [];
    if (classListstate.length > 0) {
      for (let i = 0; i < classListstate.length; i++) {
        classlist.push({ value: classListstate[i].id, label: classListstate[i].attributes.name + ' - ' + classListstate[i].attributes.category })
      }
    }





    return <div className="animated fadeIn">

      <a onClick={this.toggleColumns} className="btn btn-default">Customize / Hide Columns</a>
      <div className="customcheck greybgcheck yscroll" id="hidecheckgroup">
        {this.createColumns()}
        <Button type="button" color="primary" id="addbutton" onClick={this.saveHiddenColumns}>
          <span className="border-left pl-2"></span>
          Save
        </Button>

        {/* Loader button */}
        <div className="btn btn-primary d-none" id="loadbutton">
          <Loader type="Oval" color="#414241" height={20} width={20} />
        </div>
        {/* Loader button */}

      </div>



      <Input type="hidden" id="sortitemval" />
      <Input type="hidden" id="sortitemorder" />

      <Row className={this.state.generalHidden + " justify-content-end"}>
        <Col className="col-auto mb-4">
          <Link to="/trademark/addtrademark" className="btn btn-primary btnaddmew">
            <span className="border-left pl-2">Add new</span>
          </Link>
        </Col>
      </Row>

      <Row>
        <Col className="col-12 mb-4">
          <Form className="tableserch">
            <InputGroup>
              <FormGroup  className="col-md-2 p-0 m-0 form-group">
                <Input type="select" className="custom-select form-control" id="setsrcfield" onChange={this.setSearchFields}>
                  <option value="name">Name</option>
                  <option value="objectid">Object ID</option>
                  <option value="AGIPRefNumber">AGIP Ref #</option>
                  <option value="applicationNumber">Application Number</option>
                  <option value="applicationDate">Application Date</option>
                  <option value="registrationNumber">Registration Number</option>
                  <option value="trademarkClasses">Class</option>
                  <option value="country">Country</option>
                  <option value="jurisdiction">Jurisdiction</option>
                  <option value="language">Language</option>
                  <option value="lawfirm">Law Firm</option>
                  <option value="owner">owner</option>
                  <option value="registrationDate">Registration Date</option>
                  <option value="renewalDate">Expiry Date</option>
                  <option value="status">Step</option>
                  <option value="stepStatus">Step Status</option>
                  <option value="twrefnumber">TW Ref #</option>
                  <option value="type">Type</option>
                  <option value="recordstatus">Status</option>
                </Input>
              </FormGroup>

              <Input type="text" placeholder="Enter your search here" id="searchbox" onKeyDown={this.hitEnter} />

              <Input type="hidden" id="namehidden" />
              <Input type="hidden" id="objectidhidden" />
              <Input type="hidden" id="appnumhidden" />
              <Input type="hidden" id="appdatehidden" />
              <Input type="hidden" id="appdateendhidden" />
              <Input type="hidden" id="regnumhidden" />

              <div className="datehalf d-none" id="datesearchouter">
                <Datetime
                  inputProps={{ placeholder: "Start Date", id: "datesearch", className: "form-control", autoComplete: "off" }}
                  timeFormat={false}
                  dateFormat="DD/MM/YYYY"
                  onKeyPress={this.hitEnter}
                  closeOnSelect={true}
                />
                <Datetime
                  inputProps={{ placeholder: "End date", id: "datesearchend", className: "form-control", autoComplete: "off" }}
                  timeFormat={false}
                  dateFormat="DD/MM/YYYY"
                  onKeyPress={this.hitEnter}
                  closeOnSelect={true}
                />
              </div>
              <Input type="hidden" id="registrationhidden" />
              <Input type="hidden" id="registrationendhidden" />

              <Input type="hidden" id="renewalhidden" />
              <Input type="hidden" id="renewalendhidden" />







              <Select
                id="typesrc"
                onKeyDown={this.hitEnter}
                className="reactSelect reactSelectClass filterselect d-none"
                closeMenuOnSelect={true}
                options={[
                  { value: 'Wordmark', label: 'Wordmark' },
                  { value: 'Black and White', label: 'Black and White' },
                  { value: 'Graphic Device', label: 'Graphic Device' },
                  { value: 'Color', label: 'Color' },
                ]}
                onChange={(selectedOption) => {
                  this.setState({ typesrc: selectedOption });
                }}
                value={this.state.typesrc}
              />
              <Input type="hidden" id="typehidden" value={
                this.state.typesrc ?
                  this.state.typesrc.value
                  :
                  ""
              } />
              {/* <Input type="hidden" id="typehidden" /> */}

              <Input type="hidden" id="languagehidden" />
              <Input type="hidden" id="searchboxhidden" />  {/* TW Ref #*/}
              <Input type="hidden" id="agiphidden" />


              <Select
                id="jurissrc"
                onKeyDown={this.hitEnter}
                className="reactSelect reactSelectClass filterselect d-none"
                closeMenuOnSelect={true}
                options={jurislist}
                onChange={(selectedOption) => {
                  this.setState({ jurissrc: selectedOption });
                }}
                value={this.state.jurissrc}
              />
              <Input type="hidden" id="jurissrchidden" value={
                this.state.jurissrc ?
                  this.state.jurissrc.value
                  :
                  ""
              } />





              <Select
                id="countrysrc"
                onKeyDown={this.hitEnter}
                className="reactSelect reactSelectClass filterselect d-none"
                closeMenuOnSelect={true}
                options={countrylist}
                onChange={(selectedOption) => {
                  this.setState({ countrysrc: selectedOption.map(x => x) });
                }}
                value={this.state.countrysrc}
                isMulti
              />
              <Input type="hidden" id="countrysrchidden" value={
                this.state.countrysrc ?
                  this.state.countrysrc.map(x => x.value)
                  :
                  ""
              } />

              <Select
                id="lawfirmsrc"
                onKeyDown={this.hitEnter}
                className="reactSelect reactSelectClass filterselect d-none"
                closeMenuOnSelect={true}
                options={lawlist}
                onChange={(selectedOption) => {
                  this.setState({ lawfirmsrc: selectedOption.map(x => x) });
                }}
                value={this.state.lawfirmsrc}
                isMulti
              />
              <Input type="hidden" id="lawfirmsrchidden" value={
                this.state.lawfirmsrc ?
                  this.state.lawfirmsrc.map(x => x.value)
                  :
                  ""
              } />



              <Select
                id="statsrc"
                onKeyDown={this.hitEnter}
                className="reactSelect reactSelectClass filterselect d-none"
                closeMenuOnSelect={true}
                options={tradestatlist}
                onChange={(selectedOption) => {
                  this.setState({ statsrc: selectedOption.map(x => x) });
                }}
                value={this.state.statsrc}
                isMulti
              />
              <Input type="hidden" id="statsrchidden" value={
                this.state.statsrc ?
                  this.state.statsrc.map(x => x.value)
                  :
                  ""
              } />


              <Select
                id="stepstatussrc"
                onKeyDown={this.hitEnter}
                className="reactSelect reactSelectClass filterselect d-none"
                closeMenuOnSelect={true}
                options={[
                  { value: 'notStarted', label: 'Not Started' },
                  { value: 'requestRecieved', label: 'Request Recieved' },
                  { value: 'inProgress', label: 'In Progress' },
                  { value: 'completed', label: 'Completed' },
                  { value: 'abandoned', label: 'Abandoned' },
                  { value: 'rejected', label: 'Rejected' },
                  { value: 'withdrawn', label: 'withdrawn' },
                ]}
                onChange={(selectedOption) => {
                  this.setState({ stepstatussrc: selectedOption });
                }}
                value={this.state.stepstatussrc}
              />
              <Input type="hidden" id="stepstatsrchidden" value={
                this.state.stepstatussrc ?
                  this.state.stepstatussrc.value
                  :
                  ""
              } />


              <Select
                id="ownersrc"
                onKeyDown={this.hitEnter}
                className="reactSelect reactSelectClass filterselect d-none"
                closeMenuOnSelect={true}
                options={companystatelist}
                onChange={(selectedOption) => {
                  this.setState({ owner: selectedOption });
                }}
                value={this.state.owner}
              />
              <Input type="hidden" id="ownerhidden" value={
                this.state.owner ?
                  this.state.owner.value
                  :
                  ""
              } />

              <Select
                id="classsrc"
                onKeyDown={this.hitEnter}
                className="reactSelect reactSelectClass filterselect d-none"
                closeMenuOnSelect={true}
                options={classlist}
                onChange={(selectedOption) => {
                  this.setState({ classsrc: selectedOption.map(x => x) });
                }}
                value={this.state.classsrc}
                isMulti
              />
              <Input type="hidden" id="classhidden" value={
                this.state.classsrc ?
                  // this.state.classsrc.value
                  this.state.classsrc.map(x => x.value)
                  :
                  ""
              } />



              <select name="recordstatsrc" id="recordstatsrc" className="d-none" onKeyDown={this.hitEnter}>
                <option value="">Select</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
              <Input type="hidden" id="recordstatsrchidden" />



              {/* <InputGroupAddon addonType="append"> */}
                <Button className="search" onClick={this.searchRecords}><i className="ti-search" ></i></Button>
              {/* </InputGroupAddon> */}
            </InputGroup>
          </Form>
          <div id="searchfilters">
            <div id="namefilter" className="d-none filters">Name: <label></label> <span className="fclose" data-item="name" onClick={this.clearFilterItem}>x</span></div>
            <div id="objectidfilter" className="d-none filters">Object ID: <label></label> <span className="fclose" data-item="objectid" onClick={this.clearFilterItem}>x</span></div>
            <div id="typefilter" className="d-none filters">Type: <label></label> <span className="fclose" data-item="type" onClick={this.clearFilterItem}>x</span></div>
            <div id="languagefilter" className="d-none filters">Language: <label></label> <span className="fclose" data-item="language" onClick={this.clearFilterItem}>x</span></div>
            <div id="jurisdictionfilter" className="d-none filters">Jurisdiction: <label></label> <span className="fclose" data-item="jurisdiction" onClick={this.clearFilterItem}>x</span></div>
            <div id="countryfilter" className="d-none filters">Country: <label></label> <span className="fclose" data-item="country" onClick={this.clearFilterItem}>x</span></div>
            <div id="lawfirmfilter" className="d-none filters">Law Firm: <label></label> <span className="fclose" data-item="lawfirm" onClick={this.clearFilterItem}>x</span></div>
            <div id="twreffilter" className="d-none filters">Tw Ref: <label></label> <span className="fclose" data-item="twrefnumber" onClick={this.clearFilterItem}>x</span></div>
            <div id="agipfilter" className="d-none filters">AGIP Ref: <label></label> <span className="fclose" data-item="AGIPRefNumber" onClick={this.clearFilterItem}>x</span></div>
            <div id="statfilter" className="d-none filters">Step: <label></label> <span className="fclose" data-item="status" onClick={this.clearFilterItem}>x</span></div>
            <div id="stepstatfilter" className="d-none filters">Step Status: <label></label> <span className="fclose" data-item="stepStatus" onClick={this.clearFilterItem}>x</span></div>
            <div id="ownerfilter" className="d-none filters">Owner: <label></label> <span className="fclose" data-item="owner" onClick={this.clearFilterItem}>x</span></div>
            <div id="classfilter" className="d-none filters">Class: <label></label> <span className="fclose" data-item="trademarkClasses" onClick={this.clearFilterItem}>x</span></div>
            <div id="registrationfilter" className="d-none filters">Reg Date : <label></label> <span className="fclose" data-item="registrationDate" onClick={this.clearFilterItem}>x</span></div>
            <div id="renewalfilter" className="d-none filters">Expiry Date : <label></label> <span className="fclose" data-item="renewalDate" onClick={this.clearFilterItem}>x</span></div>
            <div id="appnumberfilter" className="d-none filters">Application Number : <label></label> <span className="fclose" data-item="applicationNumber" onClick={this.clearFilterItem}>x</span></div>
            <div id="appdatefilter" className="d-none filters">Application Date : <label></label> <span className="fclose" data-item="applicationDate" onClick={this.clearFilterItem}>x</span></div>
            <div id="regnumberfilter" className="d-none filters">Registration Number : <label></label> <span className="fclose" data-item="registrationNumber" onClick={this.clearFilterItem}>x</span></div>
            <div id="recordstatfilter" className="d-none filters">Status: <label></label> <span className="fclose" data-item="recordstatus" onClick={this.clearFilterItem}>x</span></div>
           
          </div>
        </Col>
      </Row>


      <Row>
        <Col xs="12">
          <Card>

            {/* <CardTitle><i className="mdi mdi-border-all mr-2"></i>Trademarks</CardTitle> */}
            <CardBody>
              <Alert color="success" className={this.state.deletedstat}>
                Selected status have been changed!
              </Alert>

              <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                <ModalHeader toggle={this.toggle}>Confirm</ModalHeader>
                <ModalBody>
                  Do you want to change status?
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={this.pushhideRecord}>Yes</Button>
                  <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                </ModalFooter>
              </Modal>
              <Input type="hidden" id="hiddenidstored" />

              <Table responsive className="longtable text-center tdheight70">
                <thead>
                  <tr>
                    <th className="cursor-pointer" data-sort="objectId" onClick={this.setSortItem}>Object ID</th>
                    <th className="cursor-pointer" data-sort="name" onClick={this.setSortItem}>Name</th>
                    <th className="cursor-pointer" data-sort="type" onClick={this.setSortItem}>Type</th>
                    <th className="cursor-pointer" data-sort="language" onClick={this.setSortItem}>Language</th>
                    <th className="cursor-pointer" data-sort="jurisdictions" onClick={this.setSortItem}>Jurisdictions</th>
                    <th className="cursor-pointer" data-sort="countries" onClick={this.setSortItem}>Countries</th>
                    <th className="cursor-pointer text-center" data-sort="lawFirm" onClick={this.setSortItem}>Law Firm</th>
                    <th className="cursor-pointer text-center" data-sort="transferToAGIP" onClick={this.setSortItem}>Transfer to AGIP</th>
                    <th className="cursor-pointer" data-sort="twRefNumber" onClick={this.setSortItem}>TW Ref #</th>
                    <th className="cursor-pointer" data-sort="AGIPRefNumber" onClick={this.setSortItem}>AGIP Ref # </th>
                    <th className="cursor-pointer" data-sort="step" onClick={this.setSortItem}>Step</th>
                    <th className="cursor-pointer" data-sort="stepStatus" onClick={this.setSortItem}>Step Status</th>
                    <th className="cursor-pointer" data-sort="owner" onClick={this.setSortItem}>Owner </th>
                    <th className="cursor-pointer" data-sort="trademarkClasses" onClick={this.setSortItem}>Classes </th>
                    <th className="cursor-pointer" data-sort="applicationNumber" onClick={this.setSortItem}>Application Number </th>
                    <th className="cursor-pointer" data-sort="applicationDate" onClick={this.setSortItem}>Application Date </th>
                    <th className="cursor-pointer" data-sort="registrationNumber" onClick={this.setSortItem}>Registration Number </th>
                    <th className="cursor-pointer" data-sort="registrationDate" onClick={this.setSortItem}>Registration Date </th>
                    <th className="cursor-pointer" data-sort="nextRenewalDate" onClick={this.setSortItem}>Expiry Date </th>
                    <th className="cursor-pointer" data-sort="usedDate" onClick={this.setSortItem}>Used Date </th>
                    <th className="cursor-pointer text-center" data-sort="masterbrand" onClick={this.setSortItem}>Masterbrand </th>
                    <th className="cursor-pointer text-center" data-sort="logoFiles" onClick={this.setSortItem}>Has Attachments ? </th>
                    <th className="cursor-pointer" data-sort="contact" onClick={this.setSortItem}>Contact </th>
                    <th className="cursor-pointer" data-sort="notes" onClick={this.setSortItem}>Notes </th>
                    <th className="cursor-pointer text-center" width="110">Actions</th>
                  </tr>
                </thead>
                <tbody>


                  {
                    this.state.data.length > 0 ?
                      this.state.data.map((dynamicData, key) =>

                        <tr key={key}>
                          <td>
                            <Link to={"/trademark/edittrademark/" + dynamicData.id} target="_blank" className="tdwrap">
                              {dynamicData.id}
                            </Link>
                          </td>
                          <td>
                            <Link to={"/trademark/edittrademark/" + dynamicData.id} target="_blank" className="tdwrap texttrasformnone">
                              {dynamicData.get('name')}
                            </Link>
                          </td>

                          <td>


                            {
                              dynamicData.get('type') ?
                                dynamicData.get('type').map((typeData, tkey) =>
                                  <span key={tkey} className="spancomma">
                                    {
                                      typeData
                                    }
                                  </span>
                                )
                                :
                                null
                            }



                            {/* {
                              dynamicData.get('type') === "Wordmark" ?
                                <Link to={"/trademark/edittrademark/" + dynamicData.id} target="_blank" className="tdwrap">
                                  {dynamicData.get('wordmarkText')}
                                </Link>
                                :
                                dynamicData.get('logoFiles') && dynamicData.get('logoFiles').length > 0 ?
                                  dynamicData.get('logoFiles').map((item, index) => {
                                    return (
                                      item.awsFileName ?
                                        <a target="_blank" >
                                          {
                                            item.url ?
                                              <img src={item.url} alt="Gallery Image" width="50" />
                                              :
                                              null
                                          }

                                        </a>

                                        :
                                        <div className="imgbx" >
                                          <Loader type="Oval" color="#fff" height={20} width={20} />
                                        </div>

                                    )
                                  }
                                  )
                                  :
                                  null
                            } */}
                          </td>





                          <td>
                            <Link to={"/trademark/edittrademark/" + dynamicData.id} target="_blank" className="tdwrap">
                              {dynamicData.get('language')}
                            </Link>
                          </td>
                          <td>
                            {
                              dynamicData.get('jurisdictions') ?

                                dynamicData.get('jurisdictions').map((classData, classkey) =>
                                  <span key={classkey} className="spancomma cursor-pointer" onClick={this.toggle5} data-jurisid={classData.id} data-juristext={classData.get('name')}>{classData.get('name')} </span>
                                )
                                :
                                null
                            }
                            {
                              dynamicData.get('countries') ?

                                dynamicData.get('countries').map((classData, classkey) =>
                                  <span key={classkey} className="spancomma">{classData.get('name')} </span>
                                )

                                :
                                null
                            }

                          </td>

                          <td>
                            {
                              dynamicData.get('countries') ?
                                <Link to={"/trademark/edittrademark/" + dynamicData.id} target="_blank" className="tdwrap">
                                  {
                                    dynamicData.get('countries').map((classData, classkey) =>
                                      <span key={classkey} className="spancomma">{classData.get('name')} </span>
                                    )
                                  }
                                </Link>
                                :
                                null
                            }
                          </td>

                          <td className="text-center">
                            {
                              dynamicData.get('lawFirm') ?
                                <Link to={"/trademark/edittrademark/" + dynamicData.id} target="_blank">
                                  {
                                    dynamicData.get('lawFirm').get('logo') ?
                                      <img src={dynamicData.get('lawFirm').get('logo').url()} className="trademarkLogo" />
                                      :
                                      dynamicData.get('lawFirm').get('name')
                                  }
                                </Link>
                                :
                                null
                            }
                          </td>

                          <td className="text-center">
                            <Link to={"/trademark/edittrademark/" + dynamicData.id} target="_blank" >
                              {
                                dynamicData.get('transferToAGIP') === "true" ?
                                  <span className="truegreen">{dynamicData.get('transferToAGIP')}</span>
                                  :
                                  <span className="falsered">{dynamicData.get('transferToAGIP')}</span>
                              }
                            </Link>
                          </td>



                          <td>
                            <Link to={"/trademark/edittrademark/" + dynamicData.id} target="_blank" className="tdwrap">
                              {dynamicData.get('twRefNumber')}
                            </Link>
                          </td>
                          <td>
                            <Link to={"/trademark/edittrademark/" + dynamicData.id} target="_blank" className="tdwrap">
                              {dynamicData.get('AGIPRefNumber')}
                            </Link>
                          </td>
                          <td>
                            <Link to={"/trademark/edittrademark/" + dynamicData.id} target="_blank" className="tdwrap">
                              {
                                dynamicData.get('step') ?
                                  dynamicData.get('step').get('name')
                                  :
                                  null
                              }
                            </Link>
                          </td>

                          <td>
                            <Link to={"/trademark/edittrademark/" + dynamicData.id} target="_blank" className="tdwrap">
                              {
                                dynamicData.get('stepStatus') === "notStarted" ?
                                  <p>Not Started</p>
                                  :
                                  ""
                              }
                              {
                                dynamicData.get('stepStatus') === "requestRecieved" ?
                                  <p>Request Recieved</p>
                                  :
                                  ""
                              }
                              {
                                dynamicData.get('stepStatus') === "inProgress" ?
                                  <p>In Progress</p>
                                  :
                                  ""
                              }
                              {
                                dynamicData.get('stepStatus') === "completed" ?
                                  <p>Completed</p>
                                  :
                                  ""
                              }
                              {
                                dynamicData.get('stepStatus') === "abandoned" ?
                                  <p>Abandoned</p>
                                  :
                                  ""
                              }
                              {
                                dynamicData.get('stepStatus') === "rejected" ?
                                  <p>Rejected</p>
                                  :
                                  ""
                              }
                              {
                                dynamicData.get('stepStatus') === "withdrawn" ?
                                  <p>Withdrawn</p>
                                  :
                                  ""
                              }
                            </Link>
                          </td>
                          <td>
                            <Link to={"/trademark/edittrademark/" + dynamicData.id} target="_blank" className="tdwrap">
                              {
                                dynamicData.get('owner') ?
                                  dynamicData.get('owner').get('name')
                                  :
                                  null
                              }
                            </Link>
                          </td>


                          <td>
                            {
                              dynamicData.get('trademarkClasses') ?
                                <Link to={"/trademark/edittrademark/" + dynamicData.id} target="_blank" className="tdwrap">
                                  {
                                    dynamicData.get('trademarkClasses').map((classData, classkey) =>
                                      <span key={classkey} className="spancomma">{classData.get('name')} </span>
                                    )
                                  }
                                </Link>
                                :
                                null
                            }

                          </td>

                          <td>
                            <Link to={"/trademark/edittrademark/" + dynamicData.id} target="_blank" className="tdwrap">
                              {
                                dynamicData.get('applicationNumber')
                              }
                            </Link>
                          </td>

                          <td>

                            {
                              dynamicData.get('applicationDate') ?
                                <Link to={"/trademark/edittrademark/" + dynamicData.id} target="_blank" className="tdwrap">
                                  {dynamicData.get('applicationDate').toLocaleDateString("en-GB",{year: "numeric",month:"2-digit",day:"2-digit"})}
                                </Link>
                                :
                                ""
                            }

                            </td>

                          <td>
                            <Link to={"/trademark/edittrademark/" + dynamicData.id} target="_blank" className="tdwrap">
                              {
                                dynamicData.get('registrationNumber')
                              }
                            </Link>
                          </td>

                          <td>

                            {
                              dynamicData.get('registrationDate') ?
                                <Link to={"/trademark/edittrademark/" + dynamicData.id} target="_blank" className="tdwrap">
                                  {dynamicData.get('registrationDate').toLocaleDateString("en-GB",{year: "numeric",month:"2-digit",day:"2-digit"})}
                                </Link>
                                :
                                ""
                            }

                          </td>
                          <td>
                            {
                              dynamicData.get('nextRenewalDate') ?
                                <Link to={"/trademark/edittrademark/" + dynamicData.id} target="_blank" className="tdwrap">
                                  {dynamicData.get('nextRenewalDate').toLocaleDateString("en-GB",{year: "numeric",month:"2-digit",day:"2-digit"})}
                                </Link>
                                :
                                ""
                            }

                          </td>
                          <td>
                            {
                              dynamicData.get('usedDate') ?
                                <Link to={"/trademark/edittrademark/" + dynamicData.id} target="_blank" className="tdwrap">
                                  {dynamicData.get('usedDate').toLocaleDateString("en-GB",{year: "numeric",month:"2-digit",day:"2-digit"})}
                                </Link>
                                :
                                ""
                            }

                          </td>
                          <td className="text-center">
                            <Link to={"/trademark/edittrademark/" + dynamicData.id} target="_blank" >
                              {
                                dynamicData.get('masterbrand') && dynamicData.get('masterbrand') === true ?

                                  <span className="truegreen">{dynamicData.get('masterbrand').toString()}</span>
                                  :
                                  <span className="falsered">{dynamicData.get('masterbrand').toString()}</span>

                              }
                            </Link>
                          </td>

                          <td className="text-center">
                            <Link to={"/trademark/edittrademark/" + dynamicData.id} target="_blank" >
                              {
                                dynamicData.get('logoFiles') && dynamicData.get('logoFiles').length > 0 ?
                                  <span className="truegreen">{dynamicData.get('transferToAGIP')}</span>
                                  :
                                  <span className="falsered">{dynamicData.get('transferToAGIP')}</span>
                              }
                            </Link>
                          </td>



                          <td>
                            <Link to={"/trademark/edittrademark/" + dynamicData.id} target="_blank" className="tdwrap">
                              {dynamicData.get('contact')}
                            </Link>
                          </td>
                          <td>
                            <Link to={"/trademark/edittrademark/" + dynamicData.id} target="_blank" className="tdwrap">
                              {dynamicData.get('notes')}
                            </Link>
                          </td>


                          <td className="text-center">
                            <Link to={"/trademark/edittrademark/" + dynamicData.id} target="_blank" className="btnicon padding0">
                              <img src={imgedit} alt={"imgedit"} width="28" />
                            </Link>

                          </td>
                        </tr>
                      )
                      :

                      this.state.loaderstat === true ?
                        <tr>
                          <td colSpan="12">
                            <div className="recordnotfound">
                              <Loader type="Oval" color="#fff" height={20} width={20} />
                            </div>
                          </td>
                        </tr>

                        :
                        <tr>
                          <td colSpan="12">
                            <div className="recordnotfound">
                              <p>No record found </p>
                            </div>
                          </td>
                        </tr>


                  }


                </tbody>
              </Table>
            </CardBody>

          </Card>

          <div className="totalrecords">
            Total Records :&nbsp;
            {
              this.state.searchcount !== "" ?
                this.state.searchcount
                :
                this.state.datafull
            }
          </div>

          <div className="tablepagination d-flex justify-content-center">
            <div className="col-auto">
              <FormGroup className="form-group pageSizeOptions">
                <Input className='form-control' type="select" id="dataperpage" onChange={this.showPerPage}>
                  <option value="25">25 Rows</option>
                  <option value="50">50 Rows</option>
                  <option value="100">100 Rows</option>
                </Input>
              </FormGroup>
            </div>

            <div className="d-none">
              Page
              <Form className="pageJump ml-1 mr-1">
                <FormGroup className="form-group">
                  <Input type="number" onWheel={ event => event.currentTarget.blur() } defaultValue="1" id="pagenum" min="1" />
                </FormGroup>
              </Form> of {Math.ceil(this.state.totalpagecount)}
              <a href="javascript:" className="btn btn-primary btnarrowright" onClick={this.gotoPage}>Go</a>
            </div>

            <div className="col-auto">
              <Pagination aria-label="Page navigation example">
                {Array(Math.ceil(this.state.totalpagecount)).fill(1).map((key, i) => (
                  <PaginationItem key={i} className={i + 1 === 1 || i + 1 === this.state.totalpagecount ? "active" : (i < 4 ? "" : "d-none")}>
                    <PaginationLink onClick={this.setpageNum} data-pagenum={i + 1} className={i + 1 === 1 ? "paginatecounts active" : "paginatecounts"}>
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}


                {/* {Array(Math.ceil(this.state.totalpagecount)).fill(1).map((key, i) =>
                  <PaginationItem key={i} className={i + 1 === 1 || i + 1 === this.state.totalpagecount ? "active" : (i < 4 ? "" : "d-none")}>
                    <PaginationLink onClick={this.setpageNum} data-pagenum={i + 1} className={i + 1 === 1 ? "paginatecounts active" : "paginatecounts"} >
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                )} */}
              </Pagination>
              
            </div>
          </div>

        </Col>
      </Row>


      <Modal isOpen={this.state.modal5} toggle={this.toggle5} className={this.props.className + " legendpopup"}>
        <ModalHeader toggle={this.toggle5}>Countries under {this.state.juristitle}</ModalHeader>
        <ModalBody>
          <ScrollArea smoothScrolling={0.8} style={{ maxHeight: '250px' }}>
            <ListGroup>
              {
                this.state.jurisCountries.map((countryData, ckey) =>
                  <ListGroupItem key={ckey}> {countryData.get('name')} </ListGroupItem>
                )
              }
            </ListGroup>
          </ScrollArea>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.toggle5}>Cancel</Button>
        </ModalFooter>
      </Modal>



    </div>
  }
}

export default trademarks;
