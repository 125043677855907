import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormFeedback,
  CustomInput,
  Tooltip,
  UncontrolledTooltip
} from 'reactstrap';
import Select, { components } from "react-select";
import jQuery from 'jquery';
import Loader from '../../components/common/Loader';
import Autocomplete from 'react-autocomplete';
import Datetime from "react-datetime";
import 'react-datetime/css/react-datetime.css';

import { getCurrentUser, authenticateLoggedIn, addHeaderTitle, isSuperAdmin, authenticateLoginpage, addBreadcrumb } from '../../services/parse';
import {
  addRecord, checkFieldValid, checkValidBySection, getBusinessSectors, getMasterbrands, getDomainStatus, getCountries, getRegions, getALJCompanies,
  getDomainname, getWebcategory, getRegistrar, getDomainFull
} from '../../services/domain';

var Parse = require('parse');


/*------------------------------------*/
/* For Select Plugin - (3)            */
/*------------------------------------*/
const CustomClearText = () => 'clear all';
const ClearIndicator = (props) => {
  const { children = <CustomClearText />, getStyles, innerProps: { ref, ...restInnerProps } } = props;
  return (
    <div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props)}>
      <div style={{ padding: '0px 5px' }}>
        {children}
      </div>
    </div>
  );
};

const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: 'pointer',
  color: state.isFocused ? 'blue' : 'black',
});


class addDomain extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      collapse: true,
      fadeIn: true,
      timeout: 300,
      isSuper: '',
      addedclass: 'd-none',
      datafull: [],
      businessSectorData: [],
      masterbrandData: [],
      domainstatusData: [],
      countriesData: [],
      regionData: [],
      companyData: [],
      domainnameData: [],
      webcategoryData: [],
      registrarData: [],
      isEdit: false,
      logofile: null,
    };
  }


  async componentDidMount(evt) {
    //auth start
    authenticateLoggedIn();
    addHeaderTitle('Add Domain');
    addBreadcrumb('Home / Domains / Add Domain');

    jQuery(".opendefault").next('.card-body').show();
    jQuery(".opendefault").addClass('active');

    getDomainFull().then((value) => {
      this.setState({ datafull: value, });
    });

    getCountries().then((value) => {
      this.setState({ countriesData: value, });
    });
    getRegions().then((value) => {
      this.setState({ regionData: value, });
    });
    getBusinessSectors().then((value) => {
      this.setState({ businessSectorData: value, });
    });
    getMasterbrands().then((value) => {
      this.setState({ masterbrandData: value, });
    });
    getDomainStatus().then((value) => {
      this.setState({ domainstatusData: value, });
    });
    getALJCompanies().then((value) => {
      this.setState({ companyData: value, });
    });

    getDomainname().then((value) => {
      this.setState({ domainnameData: value, });
    });
    getWebcategory().then((value) => {
      this.setState({ webcategoryData: value, });
    });
    getRegistrar().then((value) => {
      this.setState({ registrarData: value, });
    });

  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  fileChangedHandler = (event) => {
    const file = event.target.files[0];
    var parseFile = new Parse.File("logo.png", file);
    this.setState({ logofile: parseFile });

  }


  addRecord = () => {

    var validatestates = {
      domain: this.state.domain,
      web_category: this.state.web_category,
      registrar: this.state.registrar,
      business_sector: this.state.business_sector,
      alj_entity: this.state.alj_entity,
      geography: this.state.geography,
      domain_status: this.state.domain_status,
    };

    var valid = checkFieldValid(validatestates);
    if (valid !== false) {
      jQuery('#addbutton').prop('disabled', true);
      jQuery('#addbutton').addClass('d-none');
      jQuery('#loadbutton').removeClass('d-none');


      var name = jQuery('#bname').val();
      var domain = this.state.domain.value; //jQuery('#domain').val();
      var web_category = this.state.web_category.value; //jQuery('#web_category').val();
      if (jQuery('#expiry_date').val()) {

        var st = jQuery('#expiry_date').val();
        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var expiry_date = new Date(st.replace(pattern, '$3-$2-$1'));

        // var expiry_date = new Date(jQuery('#expiry_date').val());  // date
      } else {
        var expiry_date = "";
      }
      var registrar = this.state.registrar.value;// jQuery('#registrar').val();
      var url = jQuery('#url').val();
      var website = jQuery('#website').val();
      if (jQuery('#created_on_date').val()) {

        var st = jQuery('#created_on_date').val();
        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var created_on_date = new Date(st.replace(pattern, '$3-$2-$1'));

        // var created_on_date = new Date(jQuery('#created_on_date').val());   // date
      } else {
        var created_on_date = "";
      }
      var redirect = jQuery('#redirect').val();
      if (jQuery('#updated_date').val()) {

        var st = jQuery('#updated_date').val();
        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var updated_date = new Date(st.replace(pattern, '$3-$2-$1'));

        // var updated_date = new Date(jQuery('#updated_date').val());   // date
      } else {
        var updated_date = "";
      }
      var business_sector = this.state.business_sector.value; //jQuery('#business_sector').val();  //pointer
      var alj_entity = this.state.alj_entity.value; //jQuery('#alj_entity').val();
      if (this.state.masterbrand) {
        var masterbrand = this.state.masterbrand.value; //jQuery('#masterbrand').val();   //pointer
      } else {
        var masterbrand = '';
      }

      var registrant_name = jQuery('#registrant_name').val();
      var registrant_email = jQuery('#registrant_email').val();
      var previous_registrant = jQuery('#previous_registrant').val();
      var previous_registrant_email = jQuery('#previous_registrant_email').val();
      var handled_by = jQuery('#handled_by').val();
      var contact_name = jQuery('#contact_name').val();
      var contact_email = jQuery('#contact_email').val();
      if (this.state.geography) {
        var geography = this.state.geography.value;
      } else {
        var geography = "";
      }
      if (this.state.region) {
        var region = this.state.region.value;
      } else {
        var region = "";
      }


      var domain_status = this.state.domain_status.value; //jQuery('#domain_status').val();   //pointer
      var action = jQuery('#action').val();
      var notes = jQuery('#notes').val();
      var status = jQuery('#status').val();

      if (jQuery('#ipr_date_transferred').val()) {
        var st = jQuery('#ipr_date_transferred').val();
        var pattern = /(\d{2})\/(\d{2})\/(\d{4})/;
        var ipr_date_transferred = new Date(st.replace(pattern, '$3-$2-$1'));

        // var ipr_date_transferred = new Date(jQuery('#ipr_date_transferred').val());  // date
      } else {
        var ipr_date_transferred = "";
      }
      var ipr_transfer_status = jQuery('#ipr_transfer_status').val();
      var register_alj_ipr = jQuery('#register_alj_ipr').val();
      var transfer_alj_ipr = jQuery('#transfer_alj_ipr').val();

      var fielddatas = {
        name: name,
        domain: domain,
        web_category: web_category,
        expiry_date: expiry_date,
        registrar: registrar,
        url: url,
        website: website,
        created_on_date: created_on_date,
        redirect: redirect,
        updated_date: updated_date,
        business_sector: business_sector,
        alj_entity: alj_entity,
        masterbrand: masterbrand,
        registrant_name: registrant_name,
        registrant_email: registrant_email,
        previous_registrant: previous_registrant,
        previous_reg_email: previous_registrant_email,
        handled_by: handled_by,
        contact_name: contact_name,
        contact_email: contact_email,
        geography: geography,
        region: region,
        domain_status: domain_status,
        action: action,
        notes: notes,
        ipr_date_transferred: ipr_date_transferred,
        ipr_transfer_status: ipr_transfer_status,
        register_alj_ipr: register_alj_ipr,
        transfer_alj_ipr: transfer_alj_ipr,
        status: status,
      };

      addRecord(fielddatas).then((value) => {
        if (value !== false) {
          this.setState({ addedclass: 'd-block' });

          setTimeout(
            function () {
              this.setState({ addedclass: 'd-none' });
              window.location.assign('#/domain/editdomain/' + value.id + '/default');
            }
              .bind(this),
            2000
          );

        } else {
          this.setState({ addedclass: 'd-none' });
        }


        this.resetForm();

      });

    }
  }



  slidingClick = (event) => {
    jQuery(event.target).parents(".card").find('.card-body').slideToggle('slow');
    jQuery(event.target).toggleClass('active');
  }

  toolTipshow = (event) => {
    jQuery(event.target).next('.tooltipshow').show();
  }
  toolTiphide = (event) => {
    jQuery(event.target).next('.tooltipshow').hide();
  }
  resetForm = () => {
    this.setState({ domain: [] });
    this.setState({ web_category: [] });
    this.setState({ registrar: [] });
    this.setState({ business_sector: [] });
    this.setState({ alj_entity: [] });
    this.setState({ geography: [] });
    this.setState({ region: [] });
    this.setState({ domain_status: [] });
    this.setState({ masterbrand: [] });
    this.setState({ registrant_email: "" });

    this.setState({ expiry_date: "" });
    this.setState({ created_on_date: "" });
    this.setState({ updated_date: "" });
    this.setState({ ipr_date_transferred: "" });


    jQuery('#addform').trigger("reset");
    jQuery('#addbutton').prop('disabled', false);
    jQuery('#addbutton').removeClass('d-none');
    jQuery('#loadbutton').addClass('d-none');
  }


  checkValidBySection = (event) => {
    var validatestates = {
      domain: this.state.domain,
      web_category: this.state.web_category,
      registrar: this.state.registrar,
      business_sector: this.state.business_sector,
      alj_entity: this.state.alj_entity,
      geography: this.state.geography,
      domain_status: this.state.domain_status,
    };

    var control = jQuery(event.target).parents(".customAcc.card");
    checkValidBySection(validatestates, control);

  }

  render() {
    var countrystate = this.state.countriesData;
    var countrylist = [];
    if (countrystate.length > 0) {
      for (let i = 0; i < countrystate.length; i++) {
        countrylist[i] = { value: countrystate[i].id, label: countrystate[i].attributes.name }
      }
    }

    var regionstate = this.state.regionData;
    var regionlist = [];
    if (regionstate.length > 0) {
      for (let i = 0; i < regionstate.length; i++) {
        regionlist[i] = { value: regionstate[i].id, label: regionstate[i].attributes.name }
      }
    }

    var sectorstate = this.state.businessSectorData;
    var businesslist = [];
    if (sectorstate.length > 0) {
      for (let i = 0; i < sectorstate.length; i++) {
        businesslist[i] = { value: sectorstate[i].id, label: sectorstate[i].attributes.name }
      }
    }

    var masterbrandstate = this.state.masterbrandData;
    var masterbrandlist = [];
    if (masterbrandstate.length > 0) {
      for (let i = 0; i < masterbrandstate.length; i++) {
        masterbrandlist[i] = { value: masterbrandstate[i].id, label: masterbrandstate[i].attributes.name }
      }
    }

    var domainstatusstate = this.state.domainstatusData;
    var domainstatuslist = [];
    if (domainstatusstate.length > 0) {
      for (let i = 0; i < domainstatusstate.length; i++) {
        domainstatuslist[i] = { value: domainstatusstate[i].id, label: domainstatusstate[i].attributes.name }
      }
    }

    var companystate = this.state.companyData;
    var companylist = [];
    if (companystate.length > 0) {
      for (let i = 0; i < companystate.length; i++) {
        companylist[i] = { value: companystate[i].id, label: companystate[i].attributes.name }
      }
    }

    var domainnamestate = this.state.domainnameData;
    var domainnamelist = [];
    if (domainnamestate.length > 0) {
      for (let i = 0; i < domainnamestate.length; i++) {
        domainnamelist[i] = { value: domainnamestate[i].id, label: domainnamestate[i].attributes.name }
      }
    }

    var webcategorystate = this.state.webcategoryData;
    var webcategorylist = [];
    if (webcategorystate.length > 0) {
      for (let i = 0; i < webcategorystate.length; i++) {
        webcategorylist[i] = { value: webcategorystate[i].id, label: webcategorystate[i].attributes.name }
      }
    }

    var registrarstate = this.state.registrarData;
    var registrarlist = [];
    if (registrarstate.length > 0) {
      for (let i = 0; i < registrarstate.length; i++) {
        registrarlist[i] = { value: registrarstate[i].id, label: registrarstate[i].attributes.name }
      }
    }


    var fulldatastate = this.state.datafull;
    var fulldatalist = [];
    if (fulldatastate.length > 0) {
      for (let i = 0; i < fulldatastate.length; i++) {
        fulldatalist.push({ label: fulldatastate[i].get('registrant_email') });
      }
    }


    return <div>

      <Form id="addform">
        <Row className="justify-content-between">

          <Col className="col-md-8">
            <Alert color="success" className={this.state.addedclass + " mr-auto"}>
              Record Added
            </Alert>
          </Col>

          <Col className="col-auto mb-4">
            <Link to="/domain/domain" className="btn btn-default  mr-3">
              <span>Back To List</span>
            </Link>


            <Button type="button" color="primary" id="addbutton" onClick={this.addRecord}>
              <span className="border-left pl-2"></span>
              Save
            </Button>

            {/* Loader button */}
            <div className="btn btn-primary d-none" id="loadbutton">
              <Loader type="Oval" color="#414241" height={20} width={20} />
            </div>
            {/* Loader button */}

          </Col>
        </Row>

        <Row>
          <Col md="8">
            <Card className="customAcc">
              <CardTitle onClick={this.slidingClick.bind(this)} className="fieldsouter opendefault">
                <i className="icon-circle icon-pencil"></i>
                Domain
            </CardTitle>
              <CardBody>


                <Row>
                  <Col md="6">
                    <FormGroup className="form-group">
                      <Label htmlFor="name"> Name *
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                        <span className="tooltipshow">Something about me</span>
                      </Label>
                      <Input type="text" name="bname" id="bname" required />

                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="form-group">
                      <Label htmlFor="name">Domain *
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                        <span className="tooltipshow">Something about me</span>
                      </Label>

                      <Select
                        id="domain"
                        className="reactSelect reactSelectClass required"
                        closeMenuOnSelect={true}
                        options={domainnamelist}
                        onChange={(selectedOption) => {
                          this.setState({ domain: selectedOption });
                        }}
                        value={(this.state.domain) ?
                          this.state.domain
                          : []
                        }

                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FormGroup className="form-group">
                      <Label>Web Category *
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                        <span className="tooltipshow">Something about me</span>
                      </Label>

                      <Select
                        id="web_category"
                        className="reactSelect reactSelectClass required z-index-10"
                        closeMenuOnSelect={true}
                        options={webcategorylist}
                        onChange={(selectedOption) => {
                          this.setState({ web_category: selectedOption });
                        }}
                        value={(this.state.web_category) ?
                          this.state.web_category
                          : []
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="form-group">
                      <Label htmlFor="name">Expiry Date *
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                        <span className="tooltipshow">Something about me</span>
                      </Label>
                      <Datetime
                        inputProps={{ placeholder: "DD/MM/YYYY", id: "expiry_date", required: "required", autoComplete: "off" }}
                        timeFormat={false}
                        dateFormat="DD/MM/YYYY"
                        onChange={(selectedOption) => {
                          this.setState({ expiry_date: selectedOption });
                        }}
                        value={this.state.expiry_date}
                        closeOnSelect={true}

                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FormGroup className="form-group">
                      <Label>Registrar *
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                        <span className="tooltipshow">Something about me</span>
                      </Label>

                      <Select
                        id="registrar"
                        className="reactSelect reactSelectClass required z-index-9"
                        closeMenuOnSelect={true}
                        options={registrarlist}
                        onChange={(selectedOption) => {
                          this.setState({ registrar: selectedOption });
                        }}
                        value={(this.state.registrar) ?
                          this.state.registrar
                          : []
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="form-group">
                      <Label htmlFor="name">URL*
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                        <span className="tooltipshow">Something about me</span>
                      </Label>
                      <Input type="text" name="url" id="url" required />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FormGroup className="form-group">
                      <Label htmlFor="name">Website *
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                        <span className="tooltipshow">Something about me</span>
                      </Label>
                      {/* <Input type="text" name="website" id="website" required /> */}

                      <select name="website" id="website" className="form-control z-index-8" required>
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>

                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="form-group">
                      <Label htmlFor="name">Created On
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                        <span className="tooltipshow">Something about me</span>
                      </Label>
                      <Datetime
                        inputProps={{ placeholder: "DD/MM/YYYY", id: "created_on_date", autocomplete: "off" }}
                        timeFormat={false}
                        dateFormat="DD/MM/YYYY"
                        onChange={(selectedOption) => {
                          this.setState({ created_on_date: selectedOption });
                        }}
                        value={this.state.created_on_date}
                        closeOnSelect={true}

                      />
                    </FormGroup>
                  </Col>









                  <Col md="6">
                    <FormGroup className="form-group">
                      <Label htmlFor="name">Redirect
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                        <span className="tooltipshow">Something about me</span>
                      </Label>
                      <Input type="text" name="redirect" id="redirect" />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="form-group">
                      <Label htmlFor="name">Updated Date
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                        <span className="tooltipshow">Something about me</span>
                      </Label>
                      <Datetime
                        inputProps={{ placeholder: "DD/MM/YYYY", id: "updated_date", autocomplete: "off" }}
                        timeFormat={false}
                        dateFormat="DD/MM/YYYY"
                        onChange={(selectedOption) => {
                          this.setState({ updated_date: selectedOption });
                        }}
                        value={this.state.updated_date}
                        closeOnSelect={true}
                        />
                    </FormGroup>
                  </Col>









                  <Col md="12">
                    <FormGroup className="form-group">
                      <Button type="button" color="primary" className="float-right" onClick={this.checkValidBySection.bind(this)}>Validate</Button>
                    </FormGroup>
                  </Col>


                </Row>

              </CardBody>
            </Card>

            <Card className="customAcc">
              <CardTitle onClick={this.slidingClick.bind(this)} className="fieldsouter">
                <i className="icon-circle icon-pencil"></i>
                Organizational Details
             </CardTitle>
              <CardBody>

                <Row>
                  <Col md="6">
                    <FormGroup className="form-group">
                      <Label>Business Sector *
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                        <span className="tooltipshow">Something about me</span>
                      </Label>

                      <Select
                        id="business_sector"
                        className="reactSelect reactSelectClass required z-index-10"
                        closeMenuOnSelect={true}
                        options={businesslist}
                        onChange={(selectedOption) => {
                          this.setState({ business_sector: selectedOption });
                        }}
                        value={(this.state.business_sector) ?
                          this.state.business_sector
                          : []
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="form-group">
                      <Label htmlFor="name">ALJ Entity *
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                        <span className="tooltipshow">Something about me</span>
                      </Label>

                      <Select
                        id="alj_entity"
                        className="reactSelect reactSelectClass required"
                        closeMenuOnSelect={true}
                        options={companylist}
                        onChange={(selectedOption) => {
                          this.setState({ alj_entity: selectedOption });
                        }}
                        value={(this.state.alj_entity) ?
                          this.state.alj_entity
                          : []
                        }
                      />


                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FormGroup className="form-group">
                      <Label>Masterbrand division
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                        <span className="tooltipshow">Something about me</span>
                      </Label>

                      <Select
                        id="masterbrand"
                        className="reactSelect reactSelectClass"
                        closeMenuOnSelect={true}
                        options={masterbrandlist}
                        onChange={(selectedOption) => {
                          this.setState({ masterbrand: selectedOption });
                        }}
                        value={(this.state.masterbrand) ?
                          this.state.masterbrand
                          : []
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">

                  </Col>

                  <Col md="12">
                    <FormGroup className="form-group">
                      <Button type="button" color="primary" className="float-right" onClick={this.checkValidBySection.bind(this)}>Validate</Button>
                    </FormGroup>
                  </Col>
                </Row>

              </CardBody>
            </Card>


            <Card className="customAcc">
              <CardTitle onClick={this.slidingClick.bind(this)} className="fieldsouter">
                <i className="icon-circle icon-pencil"></i>
                Contact Details
             </CardTitle>
              <CardBody>

                <Row>
                  <Col md="6">
                    <FormGroup className="form-group">
                      <Label>Registrant
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                        <span className="tooltipshow">Something about me</span>
                      </Label>
                      <Input type="text" name="registrant_name" id="registrant_name" />

                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="form-group">
                      <Label>Previous Registrant
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                        <span className="tooltipshow">Something about me</span>
                      </Label>
                      <Input type="text" name="previous_registrant" id="previous_registrant" />

                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FormGroup className="form-group">
                      <Label htmlFor="name">Registrant Email
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                        <span className="tooltipshow">Something about me</span>
                      </Label>
                      {/* <Input type="text" name="registrant_email" id="registrant_email" /> */}
                      <div className="autocomwrap">
                        <Autocomplete
                          inputProps={{ className: "form-control", id: "registrant_email" }}
                          getItemValue={(item) => item.label}
                          items={fulldatalist}
                          renderItem={(item, isHighlighted) =>
                            <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                              {item.label}
                            </div>
                          }
                          value={this.state.registrant_email}
                          onChange={(event) => {
                            this.setState({ registrant_email: event.target.value });
                          }}
                          onSelect={(val) => { this.setState({ registrant_email: val }); }}
                        />
                      </div>


                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="form-group">
                      <Label htmlFor="name">Handled By
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                        <span className="tooltipshow">Something about me</span>
                      </Label>
                      <Input type="text" name="handled_by" id="handled_by" />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FormGroup className="form-group">
                      <Label htmlFor="name">Contact Name
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                        <span className="tooltipshow">Something about me</span>
                      </Label>
                      <Input type="text" name="contact_name" id="contact_name" />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="form-group">
                      <Label htmlFor="name">Contact Email
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                        <span className="tooltipshow">Something about me</span>
                      </Label>
                      <Input type="text" name="contact_email" id="contact_email" />
                    </FormGroup>
                  </Col>
                </Row>


                <Row>
                  <Col md="6">
                    <FormGroup className="form-group">
                      <Label htmlFor="name">Previous Registrant Email
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                        <span className="tooltipshow">Something about me</span>
                      </Label>
                      <Input type="text" name="previous_registrant_email" id="previous_registrant_email" />
                    </FormGroup>
                  </Col>
                </Row>

              </CardBody>
            </Card>

          </Col>



          <Col md="4">
            <Card className="customAcc">
              <CardTitle onClick={this.slidingClick.bind(this)} className="fieldsouter">
                <i className="icon-circle icon-pencil"></i>
                Save Options
              </CardTitle>
              <CardBody>
                <Row>
                  <Col md="12">
                    <FormGroup className="form-group">
                      <div className="greybx">
                        <Label>Geography
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                          <span className="tooltipshow">Something about me</span>
                        </Label>

                        <Select
                          id="geography"
                          placeholder="Select Country"
                          className="reactSelect reactSelectClass z-index-10"
                          closeMenuOnSelect={true}
                          options={countrylist}
                          onChange={(selectedOption) => {
                            this.setState({ geography: selectedOption });
                          }}
                          value={(this.state.geography) ?
                            this.state.geography
                            : []
                          }
                        />

                        <Select
                          id="geography"
                          placeholder="Select Region"
                          className="reactSelect reactSelectClass mt-2 z-index-9"
                          closeMenuOnSelect={true}
                          options={regionlist}
                          onChange={(selectedOption) => {
                            this.setState({ region: selectedOption });
                          }}
                          value={(this.state.region) ?
                            this.state.region
                            : []
                          }
                        />
                      </div>
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Label>Domain Status *
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                        <span className="tooltipshow">Something about me</span>
                      </Label>

                      <Select
                        id="domain_status"
                        className="reactSelect reactSelectClass required "
                        closeMenuOnSelect={true}
                        options={domainstatuslist}
                        onChange={(selectedOption) => {
                          this.setState({ domain_status: selectedOption });
                        }}
                        value={(this.state.domain_status) ?
                          this.state.domain_status
                          : []
                        }
                      />

                    </FormGroup>

                    <FormGroup className="form-group">
                      <Label htmlFor="description">Action
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                        <span className="tooltipshow">Something about me</span>
                      </Label>
                      <Input type="textarea" name="action" id="action" rows="2" />
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Label htmlFor="description">Notes
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                        <span className="tooltipshow">Something about me</span>
                      </Label>
                      <Input type="textarea" name="notes" id="notes" rows="2" />
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Label htmlFor="statusfield">Status</Label>
                      <select id="status" className="custom-select">
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                      </select>
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Button type="button" color="primary" className="float-right" onClick={this.checkValidBySection.bind(this)}>Validate</Button>
                    </FormGroup>


                  </Col>
                </Row>

              </CardBody>
            </Card>


            <Card className="customAcc">
              <CardTitle onClick={this.slidingClick.bind(this)} className="fieldsouter">
                <i className="icon-circle icon-pencil"></i>
                IPR Transfer Status
                  </CardTitle>
              <CardBody>

                <Row>
                  <Col md="12">
                    <FormGroup className="form-group">
                      <Label htmlFor="name">Date Transferred
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                        <span className="tooltipshow">Something about me</span>
                      </Label>
                      <Datetime
                        inputProps={{ placeholder: "DD/MM/YYYY", id: "ipr_date_transferred", autocomplete: "off" }}
                        timeFormat={false}
                        dateFormat="DD/MM/YYYY"
                        onChange={(selectedOption) => {
                          this.setState({ ipr_date_transferred: selectedOption });
                        }}
                        value={this.state.ipr_date_transferred}
                        closeOnSelect={true}
                        />
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Label>IPR Transfer Status
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                        <span className="tooltipshow">Something about me</span>
                      </Label>
                      <select id="ipr_transfer_status" className="custom-select">
                        <option value="">Select</option>
                        <option value="done">Done</option>
                        <option value="email sent">Email Sent</option>
                        <option value="n/a">N/A</option>
                        <option value="new domain registered in ipr">New domain registered in IPR</option>
                        <option value="pending">Pending</option>
                        <option value="still with jamil pasha">Still with Jamil Pasha</option>
                        <option value="transferred to alj ipr co.">Transferred to ALJ IPR Co.</option>
                      </select>
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Label>Register to ALJ IPR Co.
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                        <span className="tooltipshow">Something about me</span>
                      </Label>
                      <select id="register_alj_ipr" className="custom-select">
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                        <option value="not applicable">Not Applicable</option>
                      </select>
                    </FormGroup>

                    <FormGroup className="form-group">
                      <Label>Transfer to ALJ IPR Co.
                      <i className="far fa-question-circle ml-1 showtooltip" href="javascript:" onMouseOver={this.toolTipshow.bind(this)} onMouseOut={this.toolTiphide.bind(this)}></i>
                        <span className="tooltipshow">Something about me</span>
                      </Label>
                      <select id="transfer_alj_ipr" className="custom-select">
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                        <option value="not applicable">Not Applicable</option>
                      </select>
                    </FormGroup>
                  </Col>
                </Row>

              </CardBody>
            </Card>
          </Col>

        </Row>
      </Form>
    </div>
  }
}

export default addDomain;
