import React from 'react';
import {
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Input,
	CustomInput,
	Form,
	Row,
	Col,
	Label,
	Button,
	Alert
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { auth } from '../../firebase';
import validators from './validators';
import jQuery from 'jquery';

import loginBg from '../../assets/images/loginBg.jpg';
import imgloginheader from '../../assets/images/login-header.jpg';
import imglogo from '../../assets/images/logo.png';
import imgview from '../../assets/images/icons/view.png';
import imgnoview from '../../assets/images/icons/no-view.png';
import iconPassword from '../../assets/images/icons/password.png';
import iconEmail from '../../assets/images/icons/email.png';
import backgroundVideo from '../../assets/videos/alj-our-locations-in-saudi-arabia.mp4';
import { sendPasswordResetEmail, ValidateUserPasscode, ResetUserPassword, isSuperAdmin, authenticateLoginpage, getParameterByName } from '../../services/parse';


const sidebarBackground = {
	backgroundImage: "url(" + loginBg + ")",
	backgroundRepeat: "no-repeat",
	backgroundPosition: "center center",
	backgroundSize: "cover"
};

class Recoverpwd extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			username: '',
			password: '',
			emailvalue: '',
			codevalue: '',
			alertState: 'd-none',
			alertMessage: '',
			alertColor: '',
			getcodeblock: '',
			confirmcodeblock: 'd-none',
			newpasswordblock: 'd-none',
			gotologinblock: 'd-none',
			passimg: imgnoview,
			passimg2: imgnoview,
		};
		this.sendPasswordResetEmail = this.sendPasswordResetEmail.bind(this);
		this.confirmCode = this.confirmCode.bind(this);
		this.ResetUserPassword = this.ResetUserPassword.bind(this);

	}

	async componentDidMount(evt) {
		authenticateLoginpage();
 

		var emailparam = getParameterByName('email');
		var codeparam = getParameterByName('code');
  
		if(emailparam && codeparam){
			this.confirmCodeByURL(emailparam, codeparam)
			this.setState({ emailvalue: emailparam });
			this.setState({ codevalue: codeparam });
		}

	}

	showPassword = () => {
		var x = document.getElementById("password");
		if (x.type === "password") {
			x.type = "text";
			this.setState({ passimg: imgview });
		} else {
			x.type = "password";
			this.setState({ passimg: imgnoview });
		}
	}
	showConfirmPassword = () => {
		var x = document.getElementById("confirmpassword");
		if (x.type === "password") {
			x.type = "text";
			this.setState({ passimg2: imgview });
		} else {
			x.type = "password";
			this.setState({ passimg2: imgnoview });
		}
	}

	handlePasswordResetEmail = (e) => {
		var keyCode = e.keyCode || e.which;
		if (keyCode === 13) {
			this.sendPasswordResetEmail();
		}
	}
	handleConfirmCode = (e) => {
		var keyCode = e.keyCode || e.which;
		if (keyCode === 13) {
			this.confirmCode();
		}
	}
	handleResetPassword = (e) => {
		var keyCode = e.keyCode || e.which;
		if (keyCode === 13) {
			this.ResetUserPassword();
		}
	}


	sendPasswordResetEmail() {
		var recoverurl =  window.location.href.split('?')[0];
		var email = jQuery('#email').val();

		sendPasswordResetEmail(email,recoverurl).then((value) => {
			console.log(value);
			if (value.success === 0) { 
				if (typeof value.message === 'string' || value.message instanceof String){
					this.setState({ alertMessage: value.message });
				}else{
					this.setState({ alertMessage: "Bad email request" });
				}
				this.setState({ alertState: 'd-block' });
				this.setState({ alertColor: 'danger' });
			}
			if (value.success === 1) {
				this.setState({ emailvalue: email });

				this.setState({ alertMessage: value.message + " Please check your email" });
				this.setState({ alertState: 'd-block' });
				this.setState({ alertColor: 'success' });


				this.setState({ getcodeblock: 'd-none' });
				this.setState({ confirmcodeblock: 'd-block' });
			}
		});
	}


	confirmCode() {
		// var emailcblock = jQuery('#emailconfirmblock').val();
		var emailcblock = this.state.emailvalue;
		var emailcode = jQuery('#emailcode').val();
		ValidateUserPasscode(emailcblock, emailcode).then((value) => {
			console.log(value);
			if (value.success === 0) {
				this.setState({ alertMessage: "Invalid code! Please enter the 4 digit code sent in your email" });
				this.setState({ alertState: 'd-block' });
				this.setState({ alertColor: 'danger' });
			}
			if (value.success === 1) {
				this.setState({ codevalue: emailcode });

				this.setState({ alertMessage: "Please enter your new password" });
				this.setState({ alertState: 'd-block' });
				this.setState({ alertColor: 'success' });

				this.setState({ confirmcodeblock: 'd-none' });
				this.setState({ newpasswordblock: 'd-block' });
			}
		});
	}

	confirmCodeByURL(email, code) {
		var emailcblock = email;
		var emailcode = code;

		ValidateUserPasscode(emailcblock, emailcode).then((value) => {
			console.log(value);
			if (value.success === 0) {
				// this.setState({ alertMessage: "Invalid code! Please enter the 4 digit code sent in your email" });
				this.setState({ alertMessage: "Invalid code! The code may be expired or incorrect" });
				this.setState({ alertState: 'd-block' });
				this.setState({ alertColor: 'danger' });
			}
			if (value.success === 1) {
				this.setState({ codevalue: emailcode });

				this.setState({ alertMessage: "Please enter your new password" });
				this.setState({ alertState: 'd-block' });
				this.setState({ alertColor: 'success' });

				this.setState({ getcodeblock: 'd-none' });
				this.setState({ confirmcodeblock: 'd-none' });
				this.setState({ newpasswordblock: 'd-block' });
			}
		});
	}

	ResetUserPassword() {
		var emailpwdblock = this.state.emailvalue;
		var emailpwdcode = this.state.codevalue;
		var password = jQuery('#password').val();
		var confirmpassword = jQuery('#confirmpassword').val();

		if (password !== confirmpassword) {
			this.setState({ alertMessage: "Password mismatch" });
			this.setState({ alertState: 'd-block' });
			this.setState({ alertColor: 'danger' });
		} else {
			ResetUserPassword(emailpwdblock, emailpwdcode, password).then((value) => {
				console.log(value);
				if (value.success === 0) {
					this.setState({ alertMessage: value.message });
					this.setState({ alertState: 'd-block' });
					this.setState({ alertColor: 'danger' });
				}
				if (value.success === 1) {
					this.setState({ alertMessage: "Your password has been updated successfully" });
					this.setState({ alertState: 'd-block' });
					this.setState({ alertColor: 'success' });

					this.setState({ newpasswordblock: 'd-none' });
					this.setState({ gotologinblock: 'd-block' });
				}
			});
		}
	}


	gotoLogin() {
		window.location.assign('#/authentication/login');
	}

	render() {
		return <div className="auth-wrapper align-items-center d-flex">

			<div className="videobg">
				<video autoPlay loop muted id="myVideo">
					<source src={backgroundVideo} type="video/mp4" />
				</video>
			</div>

			<div className="container-fluid  p-0">

				<div className="loginheader">
					<a href="https://www.alj.com/" className="logo" target="_blank"><img src={imglogo} alt={"imglogo"} /></a>
				</div>

				<Row className="no-gutters justify-content-center">
					<div className="loginbx">
						<div className="p-5">
							<h1 className="title mt-2 mb-5">Intellectual Property Management</h1>

							<Alert color={this.state.alertColor} className={this.state.alertState}>
								{this.state.alertMessage}
							</Alert>
							<Form className="mt-4" id="loginform" action="/dashbaord">


								<div className={this.state.getcodeblock}>
									<InputGroup className="mb-2" size="lg">
										{/* <InputGroupAddon addonType="prepend"> */}
											<InputGroupText className='prepend'>
												<img src={iconEmail} alt="iconEmail" width="20" />
											</InputGroupText>
										{/* </InputGroupAddon> */}
										<Input type="email" id="email" placeholder="Your Email" tabIndex="1" autoComplete="false" required onKeyPress={this.handlePasswordResetEmail}/>
									</InputGroup>

									<Row className="mb-3 mt-3">
										<Col xs="12">
											<Button size="lg" className="btn-grey" onClick={this.sendPasswordResetEmail} block>Reset Password</Button>
										</Col>
									</Row>
								</div>


								{/* <div className={this.state.confirmcodeblock}> 
									<InputGroup className="mb-2" size="lg">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<img src={iconEmail} alt="iconEmail" width="20" />
											</InputGroupText>
										</InputGroupAddon>
										<Input type="text" id="emailcode" placeholder="Enter your code here" tabIndex="2" autoComplete="false" required onKeyPress={this.handleConfirmCode}/>
									</InputGroup>
									<Row className="mb-3 mt-3">
										<Col xs="12">
											<Button size="lg" className="btn-grey" onClick={this.confirmCode} block>Confirm your code</Button>
										</Col>
									</Row>
								</div> */}

								<div className={this.state.confirmcodeblock}> 
									 
								</div>


								<div className={this.state.newpasswordblock}> 
									<InputGroup className="mb-3" size="lg">
										{/* <InputGroupAddon addonType="prepend"> */}
											<InputGroupText className='prepend'>
												<img src={iconPassword} alt="iconPassword" width="30" />
											</InputGroupText>
										{/* </InputGroupAddon> */}
										<Input type="password" id="password" placeholder="New password" tabIndex="3" required onKeyPress={this.handleResetPassword}/>
										{/* <InputGroupAddon addonType="append"> */}
											<InputGroupText className='append'>
												<a href="javascript:" onClick={this.showPassword}>
													<img id="showpasswordimg" src={this.state.passimg} alt="view" width="25" />
												</a>
											</InputGroupText>
										{/* </InputGroupAddon> */}
									</InputGroup>
									<InputGroup className="mb-3" size="lg">
										{/* <InputGroupAddon addonType="prepend"> */}
											<InputGroupText className='prepend'>
												<img src={iconPassword} alt="iconPassword" width="30" />
											</InputGroupText>
										{/* </InputGroupAddon> */}
										<Input type="password" id="confirmpassword" placeholder="Confirm password" tabIndex="4" required onKeyPress={this.handleResetPassword}/>
										{/* <InputGroupAddon addonType="append"> */}
											<InputGroupText className='append'>
												<a href="javascript:" onClick={this.showConfirmPassword}>
													<img id="showpasswordimg" src={this.state.passimg2} alt="view" width="25" />
												</a>
											</InputGroupText>
										{/* </InputGroupAddon> */}
									</InputGroup>

									<Row className="mb-3 mt-3">
										<Col xs="12">
											<Button size="lg" className="btn-grey" onClick={this.ResetUserPassword} block>Submit</Button>
										</Col>
									</Row>
								</div>


								<div className={this.state.gotologinblock}>
									<Row className="mb-3 mt-3">
										<Col xs="12">
											<Button size="lg" className="btn-grey" onClick={this.gotoLogin} block>Click here to login</Button>
										</Col>
									</Row>
								</div>




								<div className="forgotpwd">
									<Link to="/authentication/login">
										Back to Login
          							</Link>
								</div>
							</Form>
						</div>

					</div>
				</Row>
				<footer className="footer">
					<Row className="justify-content-between align-items-center">
						<Col>
							<ul>
								<li><a href="http://brand.alj.com/" target="_blank">brand.alj.com</a></li>
							</ul>
						</Col>
						<Col md="auto">
							<ul>
								<li><a href="https://www.alj.com/" target="_blank">alj.com</a></li>
							</ul>
						</Col>
					</Row>
				</footer>
			</div>
		</div>;
	}
}

export default Recoverpwd;
