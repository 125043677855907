import React from 'react';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Alert,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormFeedback,
  CustomInput,
  Tooltip,
  UncontrolledTooltip
} from 'reactstrap';
import Select, { components } from "react-select";
import jQuery from 'jquery';

import { getCurrentUser, authenticateLoggedIn, addHeaderTitle, isSuperAdmin, authenticateLoginpage, addBreadcrumb } from '../../services/parse';
import { addRecord, checkFieldValid, getJurisdictions, getRegions } from '../../services/countries';

class addCountry extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
      collapse: true,
      fadeIn: true,
      timeout: 300,
      isSuper: '',
      addedclass: 'd-none',
      jurisdictionData: [],
      regionData: [],
      isEdit: false,
      logofile: null,
      rewardsfile: null,
      loginlogofile: null,
    };
  }


  async componentDidMount(evt) {
    //auth start
    authenticateLoggedIn();
    addHeaderTitle('Add Country');
    addBreadcrumb('Home / Countries / Add Country');

    getJurisdictions().then((value) => {
      this.setState({ jurisdictionData: value, });
    });
    getRegions().then((value) => {
      this.setState({ regionData: value, });
    });
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }


  addRecord = () => {

    var valid = checkFieldValid('add');
    if (valid !== false) {
      jQuery('#addbutton').prop('disabled', true);

      var name = jQuery('#bname').val();
      var country_code = jQuery('#country_code').val();
      var description = jQuery('#bdescription').val();
      var status = jQuery('#status').val();

      var jurisdata = [];
      jQuery.each(jQuery("input[name='jurislist']:checked"), function () {
        jurisdata.push(jQuery(this).val());
      });

      var regiondata = [];
      jQuery.each(jQuery("input[name='regionlist']:checked"), function () {
        regiondata.push(jQuery(this).val());
      });

      addRecord({ name: name, country_code: country_code, description: description, status: status, jurisdata: jurisdata, regiondata: regiondata }).then((value) => {
        if (value !== false) {
          this.setState({ addedclass: 'd-block' });

          setTimeout(
            function () {
              this.setState({ addedclass: 'd-none' });
              window.location.assign('#/countries/editcountry/' + value.id);
            }
              .bind(this),
            1000
          );

        } else {
          this.setState({ addedclass: 'd-none' });
        }

        jQuery('#addform').trigger("reset");
        jQuery('#addbutton').prop('disabled', false);
      });

    }
  }





  render() {
    return <div>

      <Row className="justify-content-between topbuttonbar">
        <Col className="col-md-5">
          <Alert color="success" className={this.state.addedclass}>
            Record Added
                  <Link to="/countries/countries" className="float-right">
              &nbsp; Go to list
                  </Link>
          </Alert>
        </Col>
        <Col className="col-auto mb-4">
          <Link to="/countries/countries" className="btn btn-primary btncancle mr-3">
            <span className="border-left pl-2">Cancel</span>
          </Link>
          <Button type="button" color="primary" id="addbutton" onClick={this.addRecord}>
            <span className="border-left pl-2"></span>
            Save
          </Button>
        </Col>
      </Row>

      <Row>

        <Col md="5">


          <Card>
            <CardTitle>
              <i className="icon-circle icon-pencil"></i>
              Add Country
            </CardTitle>

            <CardBody>





              <Form id="addform">
                <FormGroup className="form-group">
                  <Label htmlFor="name">Name</Label>
                  <Input type="text" name="bname" id="bname" />
                </FormGroup>


                <FormGroup className="form-group">
                  <Label htmlFor="name">Country Code</Label>
                  <Input type="text" name="country_code" id="country_code" />
                </FormGroup>


                <FormGroup className="form-group">
                  <Label htmlFor="exampleEmail12">Status</Label>

                  <select id="status" className="custom-select">
                    <option value="true">Active</option>
                    <option value="false">Inactive</option>
                  </select>


                </FormGroup>
                <FormGroup className="form-group">
                  <Label htmlFor="description">Description</Label>

                  <Input type="textarea" name="bdescription" id="bdescription" rows="5" placeholder="Add your comments here..." />
                </FormGroup>



                {/* Jurisdiction area */}

                <FormGroup className="form-group">
                  <Label htmlFor="name">Applicable to Jurisdiction</Label>
                  <div className="customcheck greybgcheck yscroll form-inline">
                    {
                      this.state.jurisdictionData.map((dynamicData, key) =>
                        <div className="form-check form-check-inline" key={key}>
                          <Input type="checkbox" name="jurislist" className="jurislist" value={dynamicData.id} id={dynamicData.id} />
                          <Label for={dynamicData.id}>{dynamicData.get('name')}</Label>
                        </div>
                      )}
                  </div>
                </FormGroup>


                {/* Region area */}

                <FormGroup className="form-group">
                  <Label htmlFor="name">Applicable to Region</Label>
                  <div className="customcheck greybgcheck yscroll form-inline">
                    {
                      this.state.regionData.map((dynamicData, key) =>
                        <div className="form-check form-check-inline" key={key}>
                          <Input type="checkbox" name="regionlist" className="regionlist" value={dynamicData.id} id={dynamicData.id} />
                          <Label for={dynamicData.id}>{dynamicData.get('name')}</Label>
                        </div>
                      )}
                  </div>
                </FormGroup>








              </Form>
            </CardBody>
          </Card>

        </Col>
      </Row>

    </div>
  }
}

export default addCountry;
