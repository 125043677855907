import jQuery from 'jquery';
var Parse = require('parse');



 
 


export const getAllMediaLicenseAssetAgency = async (searchdatas = null) => {
    const libQuery = new Parse.Query('MediaLicenseLibrary');
    libQuery.equalTo('recordstatus', 'active');


    var MediaLicenseAssetAgency = Parse.Object.extend("MediaLicenseAssetAgency");
    var query = new Parse.Query(MediaLicenseAssetAgency);
    query.equalTo("recordstatus", "active");
    query.descending("createdAt");
    query.include("licensetype");
    query.include("georestriction");
    query.include("medialicenselibraryid");
    query.include("agencyid");
    query.include("aljbrand");
    query.include("tags");

    if ((searchdatas === null) || (searchdatas.libraryfilter==="" || searchdatas.libraryfilter.length===0)) {
        query.matchesQuery('medialicenselibraryid', libQuery);
    }

    if (searchdatas !== null) {
        console.log(searchdatas)

        if (searchdatas.keywordfilter !== "") {
            // query.matches("title", searchdatas.keywordfilter, 'i');
            let srcquery1 = new Parse.Query('MediaLicenseAssetAgency');
            srcquery1.matches("title", searchdatas.keywordfilter, 'i');
            let srcquery2 = new Parse.Query('MediaLicenseAssetAgency');
            srcquery2.matches("description", searchdatas.keywordfilter, 'i');
            let srcquery3 = new Parse.Query('MediaLicenseAssetAgency');
            srcquery3.matches("assetid", searchdatas.keywordfilter, 'i');
            
            query._orQuery([srcquery1, srcquery2, srcquery3]);
        }

        if (searchdatas.expiringinfilter !== "") {
            if (searchdatas.expiringinfilter === "expired") {
                var myDate = new Date();
                console.log(myDate)
                query.lessThan("expiry", myDate);
            } else if (searchdatas.expiringinfilter === "24h") {
                var myDate = new Date(new Date().getTime() + (1 * 24 * 60 * 60 * 1000));
                console.log(myDate)
                query.lessThan("expiry", myDate);
            } else if (searchdatas.expiringinfilter === "3d") {
                var myDate = new Date(new Date().getTime() + (3 * 24 * 60 * 60 * 1000));
                console.log(myDate)
                query.lessThan("expiry", myDate);
            } else if (searchdatas.expiringinfilter === "7d") {
                var myDate = new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000));
                console.log(myDate)
                query.lessThan("expiry", myDate);
            } else if (searchdatas.expiringinfilter === "15d") {
                var myDate = new Date(new Date().getTime() + (15 * 24 * 60 * 60 * 1000));
                console.log(myDate)
                query.lessThan("expiry", myDate);
            } else if (searchdatas.expiringinfilter === "30d") {
                var myDate = new Date(new Date().getTime() + (30 * 24 * 60 * 60 * 1000));
                console.log(myDate)
                query.lessThan("expiry", myDate);
            }
        }


        if (searchdatas.assettypefilter.length > 0) {
            var assettypeidarr = [];
            for (var i = 0; i < searchdatas.assettypefilter.length; i++) {
                assettypeidarr.push({
                    __type: 'Pointer',
                    className: 'AssetType',
                    objectId: searchdatas.assettypefilter[i]
                });
            }
            query.containedIn('assettypeid', assettypeidarr);
        }

        if (searchdatas.libraryfilter.length > 0) {
            var libarr = [];
            for (var i = 0; i < searchdatas.libraryfilter.length; i++) {
                libarr.push({
                    __type: 'Pointer',
                    className: 'MediaLicenseLibrary',
                    objectId: searchdatas.libraryfilter[i]
                });
            }
            query.containedIn('medialicenselibraryid', libarr);
        }

        if (searchdatas.agencyfilter.length > 0) {
            var agencarr = [];
            for (var i = 0; i < searchdatas.agencyfilter.length; i++) {
                agencarr.push({
                    __type: 'Pointer',
                    className: 'Agency',
                    objectId: searchdatas.agencyfilter[i]
                });
            }
            query.containedIn('agencyid', agencarr);
        }




        if (searchdatas.addedonfilter !== "" && searchdatas.addedonfilter !== "all") {
            if (searchdatas.addedonfilter === "24h") {
                var myDate = new Date(new Date().getTime() - (1 * 24 * 60 * 60 * 1000));
                console.log(myDate)
                query.greaterThanOrEqualTo("createdAt", myDate);
            } else if (searchdatas.addedonfilter === "3d") {
                var myDate = new Date(new Date().getTime() - (3 * 24 * 60 * 60 * 1000));
                console.log(myDate)
                query.greaterThanOrEqualTo("createdAt", myDate);
            } else if (searchdatas.addedonfilter === "7d") {
                var myDate = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000));
                console.log(myDate)
                query.greaterThanOrEqualTo("createdAt", myDate);
            } else if (searchdatas.addedonfilter === "15d") {
                var myDate = new Date(new Date().getTime() - (15 * 24 * 60 * 60 * 1000));
                console.log(myDate)
                query.greaterThanOrEqualTo("createdAt", myDate);
            } else if (searchdatas.addedonfilter === "30d") {
                var myDate = new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000));
                console.log(myDate)
                query.greaterThanOrEqualTo("createdAt", myDate);
            }
            else if (searchdatas.addedonfilter === "range") {
                if (searchdatas.addedonfilterstart) {
                    query.greaterThanOrEqualTo("createdAt", searchdatas.addedonfilterstart);
                }
                if (searchdatas.addedonfilterend) {
                    query.lessThanOrEqualTo("createdAt", searchdatas.addedonfilterend);
                }

            }
        }



    }

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

 
export const deleteRecords = async (objectid) => {
    var obj = new Parse.Object("MediaLicenseAssetAgency");
    obj.id = objectid;
    const response = await obj.destroy({});
 
    console.log(response)

    if (response) {
        return true;
    }
}

export const archiveRecords = async (objectid) => { 
    var parseobject = Parse.Object.extend("MediaLicenseAssetAgency");
    var query = new Parse.Query(parseobject);
    query.equalTo("objectId", objectid);
    return query.find().then((object) => {
        var realbject = object[0];
        realbject.set("recordstatus", "archive");
        return realbject.save().then(async (records) => {
            await addMediaLogs(objectid, "archive");
            return true;
        }, (error) => {
            console.log(error);
        }).catch(function (error) {
        });

    }, (error) => {
    });
}


export const addMediaLogs = async (agencyasset, action) => {
    try {
        var curruser = Parse.User.current();
        var fullip = await fetch('https://geolocation-db.com/json/', {
            method: "GET",
            mode: 'cors',
        });
        var ip = await fullip.json();

        Parse.Cloud.run('AddMediaLogs', {
            user: curruser.id,
            ipaddress: ip ? ip.IPv4 : "",
            agencyasset: agencyasset,
            action: action,
        }).then(function (result) {
            console.log(result);
        });
    } catch (ex) {
        console.log('log', ex);
    }
}




// export const getAssetAgencybyFilter = async (searchdatas = null) => {


//     var MediaLicenseAsset = Parse.Object.extend("MediaLicenseAsset");
//     var query = new Parse.Query(MediaLicenseAsset);
//     query.equalTo("recordstatus", "active");
   
//     query.descending('createdAt');



//     if (searchdatas !== null) {
//         console.log(searchdatas)



//         if (searchdatas.favoritefilter === true) {
//             query.equalTo("favorite", true);
//         }

//         if (searchdatas.keywordfilter !== "") {
//             query.matches("title", searchdatas.keywordfilter, 'i');
//         }

//         if (searchdatas.expiringinfilter !== "") {
//             if (searchdatas.expiringinfilter === "expired") {
//                 var myDate = new Date();
//                 console.log(myDate)
//                 query.lessThan("expiry", myDate);
//             } else if (searchdatas.expiringinfilter === "24h") {
//                 var myDate = new Date(new Date().getTime() + (1 * 24 * 60 * 60 * 1000));
//                 console.log(myDate)
//                 query.lessThan("expiry", myDate);
//             } else if (searchdatas.expiringinfilter === "3d") {
//                 var myDate = new Date(new Date().getTime() + (3 * 24 * 60 * 60 * 1000));
//                 console.log(myDate)
//                 query.lessThan("expiry", myDate);
//             } else if (searchdatas.expiringinfilter === "7d") {
//                 var myDate = new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000));
//                 console.log(myDate)
//                 query.lessThan("expiry", myDate);
//             } else if (searchdatas.expiringinfilter === "15d") {
//                 var myDate = new Date(new Date().getTime() + (15 * 24 * 60 * 60 * 1000));
//                 console.log(myDate)
//                 query.lessThan("expiry", myDate);
//             } else if (searchdatas.expiringinfilter === "30d") {
//                 var myDate = new Date(new Date().getTime() + (30 * 24 * 60 * 60 * 1000));
//                 console.log(myDate)
//                 query.lessThan("expiry", myDate);
//             }
//         }


//         if (searchdatas.assettypefilter.length > 0) {
//             var assettypeidarr = [];
//             for (var i = 0; i < searchdatas.assettypefilter.length; i++) {
//                 assettypeidarr.push({
//                     __type: 'Pointer',
//                     className: 'AssetType',
//                     objectId: searchdatas.assettypefilter[i]
//                 });
//             }
//             query.containedIn('assettypeid', assettypeidarr);
//         }

//         if (searchdatas.libraryfilter.length > 0) {
//             var libarr = [];
//             for (var i = 0; i < searchdatas.libraryfilter.length; i++) {
//                 libarr.push({
//                     __type: 'Pointer',
//                     className: 'MediaLicenseLibrary',
//                     objectId: searchdatas.libraryfilter[i]
//                 });
//             }
//             query.containedIn('medialicenselibraryid', libarr);
//         }

//         if (searchdatas.agencyfilter.length > 0) {
//             var agencarr = [];
//             for (var i = 0; i < searchdatas.agencyfilter.length; i++) {
//                 agencarr.push({
//                     __type: 'Pointer',
//                     className: 'Agency',
//                     objectId: searchdatas.agencyfilter[i]
//                 });
//             }
//             query.containedIn('agencyid', agencarr);
//         }




//         if (searchdatas.addedonfilter !== "" && searchdatas.addedonfilter !== "all") {
//             if (searchdatas.addedonfilter === "24h") {
//                 var myDate = new Date(new Date().getTime() - (1 * 24 * 60 * 60 * 1000));
//                 console.log(myDate)
//                 query.greaterThanOrEqualTo("createdAt", myDate);
//             } else if (searchdatas.addedonfilter === "3d") {
//                 var myDate = new Date(new Date().getTime() - (3 * 24 * 60 * 60 * 1000));
//                 console.log(myDate)
//                 query.greaterThanOrEqualTo("createdAt", myDate);
//             } else if (searchdatas.addedonfilter === "7d") {
//                 var myDate = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000));
//                 console.log(myDate)
//                 query.greaterThanOrEqualTo("createdAt", myDate);
//             } else if (searchdatas.addedonfilter === "15d") {
//                 var myDate = new Date(new Date().getTime() - (15 * 24 * 60 * 60 * 1000));
//                 console.log(myDate)
//                 query.greaterThanOrEqualTo("createdAt", myDate);
//             } else if (searchdatas.addedonfilter === "30d") {
//                 var myDate = new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000));
//                 console.log(myDate)
//                 query.greaterThanOrEqualTo("createdAt", myDate);
//             }
//             else if (searchdatas.addedonfilter === "range") {
//                 if (searchdatas.addedonfilterstart) {
//                     query.greaterThanOrEqualTo("createdAt", searchdatas.addedonfilterstart);
//                 }
//                 if (searchdatas.addedonfilterend) {
//                     query.lessThanOrEqualTo("createdAt", searchdatas.addedonfilterend);
//                 }

//             }
//         }



//     }







//     var records = await query.find();
//     return records;
// }
